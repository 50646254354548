import React, {useState} from 'react'
import {InitialInfo} from "./steps/initial_info";
import footerImg from '../../../../../assets/images/./leadPages/footer-img.png'
import {BookConsultation} from "./steps/book_consultation";
import { Helmet } from 'react-helmet-async';

const INITIAL_INFO = 'INITIAL_INFO'
const CONSULTATION_FORM = 'CONSULTATION_FORM'

export const ScheduleConsultationWithDiscountPage = () => {
  const [step, setStep] = useState(INITIAL_INFO)
  const [data, setData] = useState({})

  const onCompleted = (stepData) => {
    setData({...data, [step]: stepData})
    if (step === INITIAL_INFO) {
      setStep(CONSULTATION_FORM)
      window.scrollTo({top: 0})
    }
  }

  return <div className="apply-for-discount">
    <Helmet>
      <title>Book a Real Estate Photographer Near You | Modern Angles</title>
      <link rel="canonical" href={window.location.href} />
      <meta
        name="description"
        content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
      />
    </Helmet>
    {step === INITIAL_INFO ?
      <InitialInfo onCompleted={onCompleted}/> :
      <BookConsultation userData={data[INITIAL_INFO]}
                        alert="Success! Your 20% discount code will arrive in your inbox in about 5 minutes." />}

    <div className="footer-container">
      <div className="apply-for-discount-footer-title">Instagram</div>
      <div className="apply-for-discount-footer-hashtag">@modern.angles</div>
      <div className="apply-for-discount-footer-img">
        <img src={footerImg} />
      </div>
    </div>
  </div>
}