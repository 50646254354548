import logoAvatar from "../../../../assets/images/logoAvatar.svg"
import { withRouter } from "react-router-dom"
import { showToast } from "../../actions/toast_actions";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import moment from 'moment';

const NotificationItem = (props) => {

  const notificationTime = moment(props.notification.created_at).fromNow()

  const sendToLink = () => {
    props.handleClose()

    let link = props.notification.link;
    if (!/^https?:\/\//i.test(link)) {
      link = `http://${link}`;
    }

    const linkWithoutProtocol = link.replace(/(^\w+:|^)\/\//, '');
    const originWithoutProtocol = window.location.origin.replace(/(^\w+:|^)\/\//, '');

    const isExternal = linkWithoutProtocol && !linkWithoutProtocol.startsWith(originWithoutProtocol);

    if (isExternal) {
      window.open(link, '_blank');
    } else {
      window.location.href = link;
    }
  }


  return <div className="notificationItem" onClick={sendToLink}>
    <div className="d-flex">
      <img className="notificationAvatar" src={props.notification?.icon_image?.url || logoAvatar}/>
      <div>
        <div className="d-flex">
          <div className="notificationTitle">{props.notification.title}</div>
        </div>
        <div className="notificationMessage">{props.notification.message}</div>
        {props.notification.link_text && <div className="notificationLink" onClick={sendToLink}>{props.notification.link_text}</div>}
      </div>
    </div>
    <div className="notificationTime">{notificationTime}</div>
  </div>
}

const msp = (state) => ({})

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(NotificationItem));