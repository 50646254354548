import styled from "styled-components";

export const Wrapper = styled.div`
  background: #1B264F;
`

export const InnerContainer = styled.div`
  border-top: 1px solid #324489;
  padding-top: 50px;
  width: 1100px;
  margin: auto;
  position: relative;
  
  @media only screen and (max-width: 1100px) {
    width: 900px;
  }
  
  @media only screen and (max-width: 769px) {
    width: calc(100vw - 80px);
    margin: auto;
  }
  
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding-bottom: 80px;

  @media screen and (max-width: 1280px){
    max-width: 700px;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
  }
  
  @media screen and (max-width: 767px){
    max-width: 320px;
    flex-wrap: wrap;
  }
`;
