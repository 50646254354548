import { connect } from "react-redux";
import CreateProjectLoginForm from "./create_project_login_form";
import React from "react";
import { openModal, closeModal } from "../../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { login, receiveSessionErrors } from "../../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
});

const mdp = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  login: (user) => dispatch(login(user)),
  clearErrors: () => dispatch(receiveSessionErrors([]))
});

export default withRouter(connect(msp, mdp)(CreateProjectLoginForm));