export const fetchAds = () => (
  $.ajax({
    type: "GET",
    url: "/ads"
  })
);

export const createAd = (params) => (
  $.ajax({
    type: "POST",
    url: "/ads",
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const updateAd = (id, params) => (
  $.ajax({
    type: "PATCH",
    url: `/ads/${id}`,
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const deleteAd = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/ads/${id}`
  })
)

export const subscribeToAd = (email, ad_id) => (
  $.ajax({
    type: "POST",
    url: "/subscribe",
    data: {email, ad_id}
  })
)

export const subscribeToList = (email, list_id) => (
  $.ajax({
    type: "POST",
    url: "/subscribe_list",
    data: { email, list_id }
  })
)

export const removeFromList = (email, list_id) => (
  $.ajax({
    type: "DELETE",
    url: "/remove_from_list",
    data: { email, list_id }
  })
)

export const checkFromList = (email, list_id) => (
  $.ajax({
    type: "GET",
    url: "/check_from_list",
    data: { email, list_id }
  })
)