import React from "react"
import {withRouter} from "react-router-dom"
import cameraIcon from "../../../../assets/images/cameraIcon.png"
import GalleryModal from "../gallery/gallery_modal"

class PhotographerPhotos extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      streamCount: 1,
      currentPhoto: 0,
      galleryModal: false
    }
    this.handler = this.handler.bind(this)
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    let startX;
    let scrollLeft;
    let moved;

    const onMouseMove = (e) => {
      const x = e.pageX - this.containerRef.current.offsetLeft;
      const walk = (x - startX) * 1.5; // faster
      moved = Math.abs(walk) > 5
      this.containerRef.current.scrollLeft = scrollLeft - walk;
    }

    this.containerRef.current.addEventListener('mousedown', (e) => {
      e.preventDefault()
      e.stopPropagation()
      moved = false
      startX = e.pageX - this.containerRef.current.offsetLeft;
      scrollLeft = this.containerRef.current.scrollLeft;
      this.containerRef.current.addEventListener('mousemove', onMouseMove)
    })

    this.containerRef.current.addEventListener('click', (e) => {
      if (moved) {
        e.preventDefault()
        e.stopPropagation()
      }
    })

    this.containerRef.current.addEventListener('mouseleave', () => {
      this.containerRef.current.removeEventListener('mousemove', onMouseMove)
    })

    this.containerRef.current.addEventListener('mouseup', () => {
      this.containerRef.current.removeEventListener('mousemove', onMouseMove)
    })
  }

  handler(newState) {
    this.setState(newState)
  }

  clickPhoto(i) {
    this.setState({
      currentPhoto: i,
      galleryModal: true
    })
  }

  render() {
    let {hideScrollbar, photographer, layout} = this.props
    let images = (photographer.images ? photographer.images.slice(0, this.state.streamCount * 20) : []).sort((i1, i2) => {
      return i1.sort_order > i2.sort_order ? 1 : -1
    })
    let modal = ""

    if (this.state.galleryModal) {
      modal = <GalleryModal photos={images.map(image => image.url)}
                            changeParentState={this.handler} currentPhoto={this.state.currentPhoto}/>
    }

    return (
      <div>
        {modal}
        {images.length > 0 && <div className={`photographerPhotos ${layout} ${hideScrollbar ? 'hiddenScrollbar' : ''}`}
                                   ref={this.containerRef}>
          <>
            {images.map((image, i) => {
              return <div className={`photographerPhoto${layout}`} key={`photo-${image.id}`}
                          onClick={() => this.clickPhoto(i)}>
                <img src={image.url}/>
              </div>
            })}

            {layout === 'grid' && <div className="photographerPhoto photographerPhotoPlaceholder"/>}
          </>
        </div>}

        {images.length === 0 && photographer && <div className="emptyPlaceholder">
          <img src={cameraIcon}/>
          <div className="emptyPlaceholderText">No photos yet</div>
        </div>}
      </div>
    )
  }
}

export default withRouter(PhotographerPhotos)