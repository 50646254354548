import React from "react";
import { Route, Link} from "react-router-dom";
import {Redirect} from "react-router";

export default class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: []
    };

    this.handleInput = this.handleInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.sendToLogin = this.sendToLogin.bind(this)
  }


  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  sendToLogin() {
    this.props.clearErrors()
    this.props.history.push("/login")
  }

  submitForm(e) {
    e.preventDefault();

    this.setState({ errors: [] })

    let errors = []

    if (this.state.email === "") {
      errors.push([1, "Email field can't be blank."])
    }

    if (errors.length > 0) {
      this.setState({ errors: errors })
    } else {
      let user = { email: this.state.email }
      this.props.submitForgotPassword(user).then(() => {
        if (this.props.errors.length === 0) {
          this.props.successError(["Password Reset email has been sent."])
          this.props.history.push("/login")
        }
      })
    }
  }

  render() {

    let emailErrorClass = "";

    let emailErrors = [];
    let backendErrors = [];
    let successErrors = [];


    this.state.errors.forEach((error) => {

      if (error[0] === 1) {
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }

    })

    this.props.errors.forEach((error) => {
      if (error === "Password Reset email has been sent.") {
        successErrors.push(<li>{error}</li>)
      } else {
        backendErrors.push(<li>{error}</li>)
      }
    })

    return (
      <div className="loginForm">
        <div className="loginFormContent">
          <h3 className="h3React">Forgot Password</h3>
          <div className="forgotPasswordSentence">Enter your email to be sent a reset password link. </div>
          <ul className="backendErrorUl">{backendErrors}</ul>
          <ul className="successErrorUl">{successErrors}</ul>
          <label id="formLabel"> Email </label>
          <input className="formInput" id={emailErrorClass} onChange={this.handleInput("email")} type="email" placeholder="Email address" value={this.state.email} />
          <ul className="errorUl">{emailErrors}</ul>
          <button className="formSubmitButton" onClick={this.submitForm}>Submit</button>
          <button className="createAccountButton forgotPasswordCreateAccountButton" onClick={this.sendToLogin}>Sign In</button>
        </div>
      </div>
    )
  }
}