import styled from 'styled-components';
import { ARROW_ICON } from '../../../../../assets/images/accordion';

export const AccordionSectionWrapper = styled.div`
  width: 80%;
  max-width: 898px;
  margin: 0px auto 140px;
  .accordion__heading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #353844;
  }
  .accordion__button {
    padding-top: 50px;
    padding-bottom: 42px;
  }
  .accordion__button[aria-expanded='true'] {
    padding-bottom: 0px;
  }
  .accordion__button::after {
    content: '';
    display: inline-block;
    width: 11px;
    height: 19px;
    margin-left: 20px;
    background-image: url(${ARROW_ICON});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .accordion__button[aria-expanded='true']::after {
    display: none;
  }
  .accordion__panel {
    padding: 25px;
    margin: 7px 0px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #18191F;
  }

  .accordion__item {
    border-bottom: 3px solid #F2F7FA;
  }

  @media only screen and (max-width: 1080px) {
    .accordion__heading {
      font-size: 26px;
      line-height: 33px;
    }
  }
  @media only screen and (max-width: 769px) {
    margin-bottom: 100px;

    .accordion__heading {
      font-size: 18px;
      line-height: 26px;
    }
    .accordion__button {
      padding-top: 35px;
      padding-bottom: 30px;
    }
    .accordion__panel {
      padding: 20px;
      font-size: 16px;
      line-height: 22px;
    }
    .accordion__button::after {
      width: 9px;
      height: 15px;
      margin-left: 13px;
    }
  }
`;
