export const fetchBlogCategories = () => (
  $.ajax({
    type: "GET",
    url: "/blog_categories"
  })
);

export const createBlogCategory = (params) => (
  $.ajax({
    type: "POST",
    url: "/blog_categories",
    data: {blog_category: params},
  })
)

export const updateBlogCategory = (id, params) => (
  $.ajax({
    type: "PATCH",
    url: `/blog_categories/${id}`,
    data: {blog_category: params},
  })
)

export const deleteBlogCategory = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/blog_categories/${id}`
  })
)

export const fetchBlogs = (search = null, popular = false) => (
  $.ajax({
    type: "GET",
    url: "/blogs",
    data: {search: search, popular: popular}
  })
);

export const fetchBlog = (id) => (
  $.ajax({
    type: "GET",
    url: `/blogs/${id}`
  })
);

export const createBlog = (params) => (
  $.ajax({
    type: "POST",
    url: "/blogs",
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const updateBlog = (id, params) => (
  $.ajax({
    type: "PATCH",
    url: `/blogs/${id}`,
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const deleteBlog = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/blogs/${id}`
  })
)