import React from "react"
import {withRouter} from "react-router-dom"
import {fetchBlog, updateBlog} from '../../util/blog_api_utils';
import BlogForm from "./blog_form";


class UpdateBlog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      blog: null
    }
  }

  componentDidMount() {
    if (!this.props.currentUser.roles.admin) {
      this.props.history.push("/")
      return ""
    }

    const blogId = this.props.match.params.id

    fetchBlog(blogId).then(blog => {
      this.setState({blog})
    })
  }

  render() {
    const {blog} = this.state

    if (!blog) {
      return <></>
    }

    return <BlogForm blog={blog} onSubmit={(data) => updateBlog(blog.id, data)} title={"Update Blog"} />
  }
}

export default withRouter(UpdateBlog)