import React from "react";
import {activateSubBroker, deactivateSubBroker, fetchAllSubBrokers} from "../../../util/sub_brokers_api_util";
import { updateFirm } from "../../../util/settings_api_util";


export default class SettingsSubBrokers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      broker_charge: this.props.currentUser.firm?.broker_charge,
      broker_charge_virtual_staging: this.props.currentUser.firm?.broker_charge_virtual_staging
    }
    this.updateUser = this.updateUser.bind(this)
    this.createUser = this.createUser.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.updateFirm = this.updateFirm.bind(this)
  }

  componentDidMount() {
    fetchAllSubBrokers().then(users => {
      this.setState({users: Object.values(users)})
    })
  }

  updateUser(user) {
    this.props.openModal("updateSubBroker", {user, onUserUpdate: this.onUserUpdate.bind(this)})
  }

  createUser() {
    this.props.openModal("createSubBroker", {onUserCreate: this.onUserCreate.bind(this)})
  }

  onUserUpdate(updatedUser) {
    this.setState({users: this.state.users.map(user => user.id === updatedUser.id ? updatedUser : user)})
  }

  onUserCreate(user) {
    this.setState({users: [...this.state.users, user]})
  }

  handleCheckbox(field) {
    return e => this.setState({ [field]: !this.state[field] })
  }

  activate(user) {
    activateSubBroker(user).then(user => {
      this.onUserUpdate(user)
    })
  }

  deactivate(user) {
    deactivateSubBroker(user).then(user => {
      this.onUserUpdate(user)
    })
  }

  onActivationToggle(user) {
    user.deactivated ? this.activate(user) : this.deactivate(user)
  }

  updateFirm(){
    let firm_info = {broker_charge: this.state.broker_charge, broker_charge_virtual_staging: this.state.broker_charge_virtual_staging}
    updateFirm(firm_info, this.props.currentUser.firm.id).then((firm) => {
      if (this.props.errors.length === 0) {
        this.props.showToast("Broker settings updated")
      }
    })
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="row justify-content-between mx-0 mb-4">
            <div className="settingsComponentHeading">Sub-brokers</div>
            <button onClick={this.createUser} className="settingsButton createUserButton m-0">
              Add sub-broker
            </button>
          </div>
       {/* <div>
          <div className="settingsCheckboxGroup">
            <label><input type="checkbox" className="formCheckBox" checked={this.state.broker_charge} onChange={this.handleCheckbox("broker_charge")} /> <span className="rememberMe">Broker Charge</span></label>
            <label><input type="checkbox" className="formCheckBox" checked={this.state.broker_charge_virtual_staging} onChange={this.handleCheckbox("broker_charge_virtual_staging")} /> <span className="rememberMe">Broker Charge (VS)</span></label>
          </div>
          <button onClick={this.updateFirm} className="settingsButton firmUpdateButton">Update Settings</button>
        </div> */}

          <table className="table table-borderless table-sm table-striped">
            <thead>
            <tr>
              <th/>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Firm</th>
              <th>Booked Projects</th>
              <th>Completed Projects</th>
              <th>Activated</th>
            </tr>
            </thead>
            <tbody>
            {this.state.users.map(user => (
              <tr key={user.id}>
                <td className="itemIcon" onClick={() => this.updateUser(user)}>
                  <i className="far fa-edit settingsServiceIcon"/>
                </td>

                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.firm?.name}</td>
                <td>{user.bookedProjects}</td>
                <td>{user.completedProjects}</td>
                <td>
                  <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input sub-broker-active-checkbox" id={`userActivation-${user.id}`}
                           checked={!user.deactivated} onChange={() => this.onActivationToggle(user)}/>
                    <label className="custom-control-label" htmlFor={`userActivation-${user.id}`}>
                      {user.deactivated ? 'Deactivated' : 'Active'}
                    </label>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}