import styled from 'styled-components';

export const VirtualStagingWrapper = styled.div`
  background-color: #ffffff;
  padding: 0px;
  margin: 0px;
  @media screen and (min-width: 1280px){
    .contractMediaWrapper .container {
      width: 100%;
      max-width: 1090px;
    }
  }
`;

export const VirtualStagingBorder = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e1ecf3;
  @media screen and (max-width: 768px){
    display: none;
  }
`;
