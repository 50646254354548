import { connect } from "react-redux";
import PropertyTourEditMain from "./property_tour_edit_main";
import React from "react";
import { withRouter } from "react-router-dom"
import { fetchProject, deleteMedia, updatePropertyTour, sortMedia, toggleHiddenMedia } from "../../actions/project_actions";
import { updateProfileWithoutPhoto} from "../../actions/settings_actions";
import {showToast} from "../../actions/toast_actions";

const msp = (state, ownProps) => (
  {
    errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
    currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
    project: state.entities.projects[ownProps.match.params.id],
    services: state.entities.services
  });

const mdp = (dispatch) => ({
  fetchProject: (project_id, service_id = null) => dispatch(fetchProject(project_id, service_id)),
  deleteMedia: (medias) => dispatch(deleteMedia(medias)),
  updatePropertyTour: (property_tour_info) => dispatch(updatePropertyTour(property_tour_info)),
  sortMedia: (medias) => dispatch(sortMedia(medias)),
  updateProfile: (user, user_id) => dispatch(updateProfileWithoutPhoto(user, user_id)),
  toggleHiddenMedia: (medias) => dispatch(toggleHiddenMedia(medias)),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(PropertyTourEditMain));