import React from "react";
import copyIcon from "../../../../assets/images/referrals/copy.svg"
import PieChart from "./pie_chart";
import {fetchReferrals} from "../../util/discount_code_api_utils";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { showToast } from "../../actions/toast_actions";
import InviteModal from "../referrals/invite_modal";

class ReferralsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referrals: [],
      inviteModal: false,
    }
    this.setInviteModal = this.setInviteModal.bind(this)
  }

  componentDidMount() {
    fetchReferrals().then(referrals => {
      this.setState({referrals})
    })
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    this.props.showToast("Code Copied")
  }

  chartData(countByStatus) {
    const noReferrals = Object.keys(countByStatus).length === 0;

    if (noReferrals) {
      return {
        labels: ['None'],
        datasets: [{
          label: 'Referrals',
          data: [1],
          backgroundColor: [
            'rgba(0, 0, 0, 0.12)'
          ],
          hoverOffset: 4
        }]
      };
    }

    const labels = [
      'Pending',
      'Completed'
    ];

    return {
      datasets: [{
        label: 'Referrals',
        data: labels.map(label => countByStatus[label] || 0),
        backgroundColor: [
          '#1B264F',
          '#2C5BFF',
        ],
        hoverOffset: 4
      }]
    };
  }

  setInviteModal(){
    this.setState({inviteModal: !this.state.inviteModal})
  }

  render() {
    const {referrals} = this.state
    const code = this.props.currentUser.referral_code

    const totalAmount = referrals.filter(referral => referral.status === "Completed")
      .reduce((res, referral) => res + referral.amount, 0);

    const countByStatus = referrals.reduce((res, referral) => {
      res[referral.status] = (res[referral.status] || 0) + 1
      return res
    }, {})

    let modal = ""

    if (this.state.inviteModal) {
      modal = <InviteModal setInviteModal={this.setInviteModal} />
    }

    return <div className="dashboardReferrals">
      {modal}
      <div className="dashboardCategoryHeading">Referrals</div>
      <div className="dashboardCategorySubheading">Easily track your active referrals and rewards</div>

      <div className="dashboardReferralsSectionGroup">
        <div className="dashboardReferralsSection dashboardReferralsContainer">
          <div className="dashboardReferralsChartContainer">
            <div className="dashboardReferralsChart">
              <PieChart data={this.chartData(countByStatus)}/>
            </div>
            <div className="dashboardReferralsChartOverview">
              <div className="dashboardReferralsTitle">Referred Total: {referrals.length}</div>
              <ul className="dashboardReferralsChartLegend">
                <li className="dashboardReferralsChartLegendPending">Pending: {countByStatus['Pending'] || 0}</li>
                <li className="dashboardReferralsChartLegendCompleted">Completed: {countByStatus['Completed'] || 0}</li>
                {/* <li className="dashboardReferralsChartLegendInvited">Invited: {countByStatus['Invited'] || 0}</li> */}
              </ul>
            </div>
          </div>

          <div className="dashboardReferralsDivider"/>
          <table className="dashboardReferralsTable">
            <thead>
            <tr>
              <th className="bold">Name</th>
              <th className="right">Reward</th>
              <th>Status</th>
              <th className="grey right">Date</th>
            </tr>
            </thead>
            <tbody>
            {referrals.map(referral => (
              <tr key={`referral-${referral.id}`}>
                <td className="bold">{referral.client_name}</td>
                <td className="right">${referral.amount}</td>
                <td>{referral.status}</td>
                <td className="grey right">{new Date(referral.created_at).toLocaleDateString("en-US")}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        <div className="dashboardReferralsSection">
          <div className="dashboardReferralsContainer dashboardReferralsTotalContainer">
            <div>Your Total Rewards:</div>
            <div className="dashboardReferralsTotal">${totalAmount}</div>
          </div>

          <div className="referralDashboardCodeInputWrapper">
            <div className="referralDashboardCodeInput">
              <div className="code">{code}</div>
              <div className="icon" onClick={() => this.copyToClipboard(code)}><img src={copyIcon}/>
              </div>
            </div>
            <button onClick={this.setInviteModal} className="projectEditButton dashboardReferralButton">Invite & Earn</button>
          </div>

          <div className="dashboardReferralInviteText">
            <p>Invite professionals and earn from their projects</p>
            <ul>
              <li>Share your unique code</li>
              <li>Overview of rewards</li>
              <li>Track your progress</li>
            </ul>
          </div>

          <Link className="dashboardReferralManageButton" to="/referral">
            Manage all Referrals
          </Link>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralsDashboard);