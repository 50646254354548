import JSZip from "jszip"
import FileSaver from 'file-saver'

export const downloadMediaFolder = (medias, folder, photo = null, virtual_staging_purchase = null) => {
  const zip = new JSZip()

  const img = zip.folder(folder)

  Object.values(medias).forEach((media, i) => {
    let file_extention = media[1].split("/")[1]
    if (file_extention === "x-nikon-nef") file_extention = "NEF"

    if (virtual_staging_purchase) {
      img.file(`${virtual_staging_purchase.category?.name}-${virtual_staging_purchase.product?.name}-${photo.file_name}`, media[0], { base64: true })
    } else {
      img.file(`${media[2]}`, media[0], {base64: true})
    }
  })

  return zip.generateAsync({type: "blob"}).then((content) => {
    FileSaver.saveAs(content, "modern_angles_media.zip")
  })
}