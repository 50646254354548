import React from "react";
import { deleteMilestone, fetchMilestones } from "../../../util/settings_api_util";


export default class SettingsMilestones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      milestones: []
    }
  }

  componentDidMount() {
    fetchMilestones().then(milestones => {
      this.setState({ milestones: milestones })
    })
  }

  onCreate() {
    this.props.openModal("milestoneForm", { onSave: this.onCreated.bind(this) })
  }

  onUpdate(milestone) {
    this.props.openModal("milestoneForm", { onSave: this.onUpdated.bind(this), milestone })
  }

  onDelete(milestone) {
    if (window.confirm(`Are you sure you want to delete "${milestone.name}"?`)) {
      deleteMilestone(milestone.id).then(() => {
        this.setState({ milestones: this.state.milestones.filter(f => f.id !== milestone.id) })
        this.props.showToast("Milestone has been removed")
      }).catch(() => {
        alert('Could not delete this milestone')
      })
    }
  }

  onCreated(milestone) {
    this.setState({ milestones: [...this.state.milestones, milestone] })
    this.props.showToast("Milestone has been created")
  }

  onUpdated(updatedMilestone) {
    this.setState({ milestones: this.state.milestones.map(milestone => milestone.id === updatedMilestone.id ? updatedMilestone : milestone) })
    this.props.showToast("Milestone has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Milestones</div>
          <div className="settingsPreferencesGroup1">
            {this.state.milestones.map(milestone => (
              <div className="settingsServicesItem" key={milestone.id}>
                <div className="itemTitle">{milestone.name}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdate(milestone)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDelete(milestone)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>

              </div>
            ))}
          </div>
          <button onClick={() => this.onCreate()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}