import React from "react"
import FadeInAnimation from "../home_page/homeComponents/FadeInAnimation";
import {useInView} from "react-intersection-observer";

const FadeIn = ({children}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.01
  });

  return <div ref={ref}>
    <FadeInAnimation
      duration={0.5}
      yOffset={20}
      inView={inView}>
      {children}
    </FadeInAnimation>
  </div>
}

export default FadeIn