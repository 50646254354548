import React from "react"
import {withRouter} from "react-router-dom"
import ContractorItem from "./contractor_item"
import ContractorCalendarModal from "./contractor_calendar_component_container"
import _ from "lodash";

class ContractorsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: -1,
      modal: null,
      contractors: []
    }
    this.handler = this.handler.bind(this)
    this.onContractorSelect = this.onContractorSelect.bind(this)
  }

  componentDidMount(){
    let contractors = this.shuffle(Object.values(this.props.contractors))
    this.setState({contractors: contractors})
  }

  componentDidUpdate(prevProps, prevState){
    if (_.isEqual(prevProps.contractors, this.props.contractors) === false){
      let contractors = this.shuffle(Object.values(this.props.contractors))
      this.setState({ contractors: contractors })
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  shuffle = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array
  }

  onContractorSelect(contractorId, photographer, appliedTime = null) {
    this.setState({selected: contractorId})
    const {onContractorSelect} = this.props

    if (onContractorSelect) {
      onContractorSelect(contractorId, photographer)
      return
    }
    
    const modal = contractorId ?
      <ContractorCalendarModal photographer={photographer}
                               setAppointmentTime={this.props.setAppointmentTime}
                               detected_timezone={this.props.detected_timezone}
                               appointments={this.props.appointments}
                               selectedService={this.props.selectedService}
                               changeSelected={this.onContractorSelect}
                               handleNext={this.props.handleNext}
                               updating={this.props.updating}
                               street={this.props.street}
                               client_timezone={this.props.client_timezone}
                               project={this.props.project}
                               reschedulingContractorCancel={this.props.reschedulingContractorCancel}
                               isLargeScreen={this.props.isLargeScreen}/> :
      null
    this.setState({modal})

    if (this.props.reschedulingContractorCancel && !contractorId && appliedTime){
      this.props.changeGrandparentState({modal: ""})
    }
  }

  render() {
    let contractorsList = []

    if (this.props.services && this.props.services[this.props.selectedService] && this.props.services[this.props.selectedService]["package_sub_services_attributes"] && Object.values(this.props.services[this.props.selectedService]["package_sub_services_attributes"]).length > 0) {
      contractorsList = this.state.contractors.map((contractor) => {
        return <ContractorItem key={contractor.id}
                               changeParentState={this.handler}
                               detected_timezone={this.props.detected_timezone}
                               client_timezone={this.props.client_timezone}
                               project={this.props.project}
                               changeGreatGrandparentState={this.props.changeGrandparentState}
                               contractorDateSearch={this.props.contractorDateSearch}
                               services={this.props.services}
                               setAppointmentTime={this.props.setAppointmentTime}
                               contractor={contractor}
                               selected={this.state.selected === contractor.id}
                               selectedService={this.props.selectedService}
                               appointments={this.props.appointments}
                               isLargeScreen={this.props.isLargeScreen}
                               reschedulingContractorCancel={this.props.reschedulingContractorCancel}
                               onSelect={id => this.onContractorSelect(id, contractor)}/>
      })
    } else if (this.props.reschedulingContractorCancel){
      contractorsList = this.state.contractors.map((contractor) => {
        return <ContractorItem key={contractor.id}
          changeParentState={this.handler}
          detected_timezone={this.props.detected_timezone}
          client_timezone={this.props.client_timezone}
          project={this.props.project}
          changeGreatGrandparentState={this.props.changeGrandparentState}
          contractorDateSearch={this.props.contractorDateSearch}
          services={this.props.services}
          setAppointmentTime={this.props.setAppointmentTime}
          contractor={contractor}
          selected_contractor={this.props.contractor}
          appointment_time={this.props.appointment_time}
          selected={this.state.selected === contractor.id}
          selectedService={this.props.selectedService}
          appointments={this.props.appointments}
          reschedulingContractorCancel={this.props.reschedulingContractorCancel}
          isLargeScreen={this.props.isLargeScreen}
          onSelect={id => this.onContractorSelect(id, contractor)} />
      })
    }

    return (
      <div className="contractorCalendarContainer">
        {this.state.modal}
        {contractorsList}
      </div>
    )
  }
}

export default withRouter(ContractorsList)