import { connect } from "react-redux";
import HomePage from './homepage';
import React from "react";
import { openModal, closeModal } from "../../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { fetchServices } from "../../actions/service_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
  modalOpen: state.entities.landingPage.modalOpen,
  projectModalOpen: state.entities.landingPage.projectModalOpen,
  VSModalOpen: state.entities.landingPage.VSModalOpen,
});

const mdp = (dispatch) => ({
  closeModal: () => { dispatch(closeModal()) },
  fetchServices: () => { dispatch(fetchServices()) },
});

export default withRouter(connect(msp, mdp)(HomePage));