import React from "react";
import { deleteLocation, fetchLocations } from "../../../util/location_api_util";
import { deleteNotification, fetchNotificationsSettings } from "../../../util/notification_utils";


export default class SettingNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: []
    }
  }

  componentDidMount() {
    fetchNotificationsSettings().then(notifications => {
      this.setState({ notifications })
    })
  }

  onCreate() {
    this.props.openModal("notificationForm", { onSave: this.onCreated.bind(this) })
  }

  onUpdate(notification) {
    this.props.openModal("notificationForm", { onSave: this.onUpdated.bind(this), notification })
  }

  onDelete(notification) {
    if (window.confirm(`Are you sure you want to delete this notification?`)) {
      deleteNotification(notification.id).then(() => {
        this.setState({ notifications: this.state.notifications.filter(f => f.id !== notification.id) })
        this.props.showToast("Notification has been removed")
      }).catch(() => {
        alert('Could not delete this notification')
      })
    }
  }

  onCreated(notification) {
    this.setState({ notifications: [...this.state.notifications, notification] })
    this.props.showToast("Notification has been created")
  }

  onUpdated(updatedNotification) {
    this.setState({ notifications: this.state.notifications.map(notification => notification.id === updatedNotification.id ? updatedNotification : notification) })
    this.props.showToast("Notification has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Notifications</div>
          <div className="settingsPreferencesGroup1">
            {this.state.notifications.map(notification => (
              <div className="settingsServicesItem" key={notification.id}>
                <div className="itemTitle">{notification.title}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdate(notification)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDelete(notification)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>

              </div>
            ))}
          </div>
          <button onClick={() => this.onCreate()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}