import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { closeModal } from "../../../actions/modal_actions";
import DiscountCodeFormModal from "./discount_code_form_modal";

const msp = () => ({});

const mdp = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal())
  },
});

export default withRouter(connect(msp, mdp)(DiscountCodeFormModal));