import React from "react"
import cameraIcon from "../../../../assets/images/cameraIcon.png"
import GalleryModal from "./gallery_modal"
import Masonry from "react-masonry-css"

class PhotosMasonry extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      perPage: 12,
      page: 1,
      currentPhoto: 0,
      galleryModal: false
    }
    this.handler = this.handler.bind(this)
  }

  handler(newState) {
    this.setState(newState)
  }

  clickPhoto(i) {
    this.setState({
      currentPhoto: i,
      galleryModal: true
    })
  }

  onLoadMore(e) {
    e.preventDefault()
    this.setState({page: this.state.page + 1})
  }

  render() {
    const canLoadMore = this.props.images.length > this.state.perPage * this.state.page
    const images = this.props.images.slice(0, this.state.perPage * this.state.page).sort((i1, i2) => {
      return i1.sort_order > i2.sort_order ? 1 : -1
    })

    const breakpointColumns = {
      default: 4,
      1100: 3,
      900: 2
    };

    return (
      <div>
        {this.state.galleryModal && <GalleryModal photos={images.map(image => image.highend_url || image.url)}
                                                  changeParentState={this.handler}
                                                  currentPhoto={this.state.currentPhoto}/>}

        <Masonry breakpointCols={breakpointColumns} className="photographerPhotosMasonry"
                 columnClassName="photographerPhotosMasonryColumn">
          {images.map((image, i) => {
            return <div className="photographerPhoto" key={`photo-${image.id}`} onClick={() => this.clickPhoto(i)}>
              <img src={image.url} />
            </div>
          })}
        </Masonry>

        {canLoadMore && <div className="text-center">
          <div className="masonryLoadMore projectEditButton projectEditButtonWhite mt-2" onClick={e => this.onLoadMore(e)}>
              Load more
          </div>
        </div>}

        {images.length === 0 && <div className="emptyPlaceholder">
          <img src={cameraIcon}/>
          <div className="emptyPlaceholderText">No photos yet</div>
        </div>}
      </div>
    )
  }
}

export default PhotosMasonry