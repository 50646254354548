import { connect } from "react-redux";
import UpdateVirtualSuiteType from "./update_virtual_suite_type";
import React from "react";
import { withRouter } from "react-router-dom";
import { closeModal } from "../../../actions/modal_actions";
import { showToast } from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  closeModal: () => { dispatch(closeModal()) },
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(UpdateVirtualSuiteType));