import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import LoginBackgroundDesign from "../../../assets/images/background_design_login";
import Logo from "../../../assets/images/logo";
import LoginFormContainer from "./forms/login_form_container";
import { Helmet } from 'react-helmet-async';

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   this.props.loginModal()
  // }

  render() {

    return (
      <div className="loginContainer">
        <Helmet>
          <title>Login | Modern Angles</title>
          <meta
            name="description"
            content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <img className="loginBackgroundDesign" src={LoginBackgroundDesign} />
        <div className="modernAnglesLoginAndLogo">
          <Link to="/">
            <img className="modernAnglesLoginLogo" src={Logo} onClick={() => this.props.history.push("/")}/>
          </Link>
          <LoginFormContainer />
        </div>
      </div>
    )
  }
}

export default withRouter(Login);