import React, { useState } from 'react';
import {Link, useHistory, withRouter} from 'react-router-dom';
import { NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

import { NAV_IMAGES } from '../../../../assets/images/landing';
import { NAVBAR_MOBILE } from '../../../../assets/images/navbar';
import {
  NavBarContainer,
  UpperNavRow,
  NavRow,
  NavColFirst,
  NavColSecond,
  NavBarImage,
  NavbarMenuImageWrapper,
  NavMenuImage,
  NavMenu,
  NavbarMenuCloseImageWrapper,
  MenuOptions,
  ArrowIcon,
  BottomRow,
  LoginButton,
  SignUpButton,
  ServicesOptions,
  ServicesOptionsList,
  ServicesOptionsListItem,
  TopRightWave
} from './styles/MobileLoggedOutNavbarStyles';
import { ROUTES } from '../../routes';

const NavbarLoggedOutMobile = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const history = useHistory()

  const [isServiceDropDownOpen, setServiceDropDownOpen] = useState(false);

  const updateMenu = () => {
    setMenuOpen(true);
    document.body.classList.add('hideAllOverFlow');
    document.getElementById('root').classList.add('hideAllOverFlow');
  };

  const closeMenu = () => {
    setMenuOpen(false);
    document.body.classList.remove('hideAllOverFlow');
    document.getElementById('root').classList.remove('hideAllOverFlow');
  };

  let bottomRow = <BottomRow className='bottomRow'>
    <Link to={'/login'}>
      <LoginButton className='loginButton'>Log In</LoginButton>
    </Link>
    <div
      onClick={() => { props.setModal(true); closeMenu() }}>
      <SignUpButton>Schedule Shoot</SignUpButton>
    </div>
  </BottomRow>

  if (props.currentUser){
    bottomRow = <BottomRow className='bottomRow'>
      <Link to={'/dashboard'}>
        <SignUpButton>Dashboard</SignUpButton>
      </Link>
    </BottomRow>
  }

  return (
    <NavBarContainer>
      {props.nonAppPage && !props.disableWaveBackground && <TopRightWave />}
      <UpperNavRow>
        <NavColFirst>
          <NavbarBrand href='/'>
            <NavBarImage src={NAV_IMAGES.WebLogo} alt='' />
          </NavbarBrand>
        </NavColFirst>
        <NavColSecond>
          <NavbarMenuImageWrapper onClick={updateMenu} className="navbarMenuImageWrapper">
            <NavMenuImage src={NAV_IMAGES.HeaderMenu} alt='' />
          </NavbarMenuImageWrapper>
        </NavColSecond>
      </UpperNavRow>
      <NavMenu className={isMenuOpen ? 'showNavMenu' : ''}>
        <NavRow>
          <NavColFirst>
            <NavbarBrand href='/'>
              <NavBarImage src={NAV_IMAGES.MOBILE_LOGOUT_LOGO} alt='' />
            </NavbarBrand>
          </NavColFirst>
          <NavColSecond>
            <NavbarMenuCloseImageWrapper onClick={closeMenu}>
              <NavMenuImage src={NAV_IMAGES.MOBILE_LOGOUT_CROSS} alt='' />
            </NavbarMenuCloseImageWrapper>
          </NavColSecond>
        </NavRow>
        <MenuOptions className={`${isServiceDropDownOpen ? 'serviceOpen' : ''}`}>
          <Nav className='mr-auto' navbar>
            <NavItem
              // onMouseOver={() => setServiceDropDownOpen(true)}
              // onFocus={() => setServiceDropDownOpen(true)}
              onClick={() => setServiceDropDownOpen(!isServiceDropDownOpen)}
              // onMouseLeave={() => setServiceDropDownOpen(false)}
              // onBlur={() => setServiceDropDownOpen(false)}
            >
              <NavLink href='#' className='rightArrowWrapper'>
                Services
                <ArrowIcon
                  className={`${isServiceDropDownOpen ? 'arrowIcon' : ''}`}
                  src={NAVBAR_MOBILE.NavBarMobileArrow}
                  alt=''
                />
              </NavLink>
              <ServicesOptions
                className={`${isServiceDropDownOpen ? 'showDropDown' : ''}`}
              >
                <ServicesOptionsList className='servicesOptionsList'>
                  <ServicesOptionsListItem className='servicesOptionsListItem'>
                    <Link to={ROUTES.PHOTOGRAPHY}>Photography</Link>
                  </ServicesOptionsListItem>
                  <ServicesOptionsListItem>
                    <Link to={ROUTES.VIRTUAL_STAGING}>Virtual Staging</Link>
                  </ServicesOptionsListItem>
                  <ServicesOptionsListItem>
                    <Link to={ROUTES.DRONE}>Drone Photography</Link>
                  </ServicesOptionsListItem>
                  <ServicesOptionsListItem>
                    <Link to={ROUTES.INTERACTIVE_TOURS}>Interactive Tours</Link>
                  </ServicesOptionsListItem>
                  <ServicesOptionsListItem>
                    <Link to={ROUTES.VIDEO}>Videos</Link>
                  </ServicesOptionsListItem>
                  <ServicesOptionsListItem>
                    <Link to={ROUTES.FLOOR_PLANS}>Floor Plans</Link>
                  </ServicesOptionsListItem>
                </ServicesOptionsList>
              </ServicesOptions>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={ROUTES.ABOUT}>About Us</NavLink>
              {/* <NavLink href='/about'>About Us</NavLink> */}
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={ROUTES.BLOG_INDEX}>Blog</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={ROUTES.LOCATIONS}>Coverage</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={ROUTES.CAREERS}>Careers</NavLink>
              {/* <NavLink href='/about'>About Us</NavLink> */}
            </NavItem>
            {/* <NavItem>
              <NavLink href='#'>Case Studies</NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink tag={Link} to={ROUTES.CONTACTS}>Contact</NavLink>
              {/* <NavLink href='/contacts'>Contact</NavLink> */}
            </NavItem>
          </Nav>
          {bottomRow}
        </MenuOptions>
      </NavMenu>
    </NavBarContainer>
  );
};

export default withRouter(NavbarLoggedOutMobile);
