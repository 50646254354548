export default class Conclusion {
  static get DEFAULT_PLACEHOLDER() {
    return 'Type conclusion...';
  }

  constructor({data, config, api, readOnly}) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-conclusion'
    };

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
    }

    this._placeholder = config.placeholder ? config.placeholder : Conclusion.DEFAULT_PLACEHOLDER;
    this._data = {};
    this._element = this.drawView();
    this._content = this._element.querySelector('[contenteditable]');
    this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

    this.data = data;
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const {textContent} = this._content;

    if (textContent === '') {
      this._content.innerHTML = '';
    }
  }

  drawView() {
    let wrapper = document.createElement('DIV');
    let div = document.createElement('DIV');

    let h3 = document.createElement('h3')
    h3.innerHTML = 'Conclusion'

    wrapper.classList.add(this._CSS.wrapper, this._CSS.block);
    div.contentEditable = false;
    div.dataset.placeholder = this.api.i18n.t(this._placeholder);

    if (!this.readOnly) {
      div.contentEditable = true;

      div.addEventListener('keyup', this.onKeyUp);
    }

    wrapper.append(h3, div);

    return wrapper;
  }

  render() {
    return this._element;
  }

  merge(data) {
    let newData = {
      text: this.data.text + data.text
    };

    this.data = newData;
  }

  validate(savedData) {
    if (savedData.text.trim() === '' && !this._preserveBlank) {
      return false;
    }

    return true;
  }

  save(toolsContent) {
    return {
      text: toolsContent.querySelector('[contenteditable]').innerHTML
    };
  }

  onPaste(event) {
    const data = {
      text: event.detail.data.innerHTML
    };

    this.data = data;
  }

  static get conversionConfig() {
    return {
      export: 'text',
      import: 'text'
    };
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      }
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  get data() {
    let text = this._content.innerHTML;

    this._data.text = text;

    return this._data;
  }

  set data(data) {
    this._data = data || {};

    this._content.innerHTML = this._data.text || '';
  }

  static get pasteConfig() {
    return {
      tags: ['P']
    };
  }

  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-letter-i" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="12" y1="4" x2="12" y2="20" />
</svg>`,
      title: 'Conclusion'
    };
  }
}