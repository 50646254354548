import React, {useEffect, useRef, useState} from 'react'
import headerImg from '../../../../../../assets/images/bookPage/header.jpg'
import logoWhiteImg from '../../../../../../assets/images/bookPage/logo-white.svg'
import checkImg from '../../../../../../assets/images/bookPage/check.svg'
import firstImg from '../../../../../../assets/images/bookPage/1.jpeg'
import secondImg from '../../../../../../assets/images/bookPage/2.jpeg'
import thirdImg from '../../../../../../assets/images/bookPage/3.jpeg'
import forthImg from '../../../../../../assets/images/bookPage/4.jpeg'
import authorImg from '../../../../../../assets/images/bookPage/author.png'
import howItWorksImg from '../../../../../../assets/images/bookPage/how-it-works.png'

import {ScheduleShootButton} from "../../../home_page/homeStyle";
import {Link, withRouter} from "react-router-dom";
import {ROUTES} from "../../../../routes";
import ServiceLogoListComponent from "../../../home_page/homeComponents/ServiceLogoListComponent";
import {Difference} from "../../../pageComponents/Difference";
import {TestimonialCard} from "./TestimonialCard";
import {Footer} from "./Footer";
import {closeProjectModal, openProjectModal} from "../../../../actions/landing_page_actions";
import {connect} from "react-redux";
import Modals from "../../../../containers/PublicRouteContainer/modals";
import AliceCarousel from "react-alice-carousel";
import { Helmet } from 'react-helmet-async';
import { TESTIMONIALS } from '../../../../../../assets/images/landing';
import Property1 from "../../../../../../assets/images/landing/testimonials/property-1.jpg"
import Property2 from "../../../../../../assets/images/landing/testimonials/property-2.jpg"
import Property3 from "../../../../../../assets/images/landing/testimonials/property-3.jpg"
import Property4 from "../../../../../../assets/images/landing/testimonials/property-4.jpg"
import Property5 from "../../../../../../assets/images/landing/testimonials/property-5.jpg"
import Property6 from "../../../../../../assets/images/landing/testimonials/property-6.jpg"
import Property7 from "../../../../../../assets/images/landing/testimonials/property-7.jpg"
import Property8 from "../../../../../../assets/images/landing/testimonials/property-8.jpg"
import {HowItWorks} from "../../../pageComponents/HowItWorks";
import {WhiteContainer} from "../../../location_page_components/TopPhotographer/styles";
import {FeaturesList} from "../../../pageComponents/FeaturesList";
import { TestimonialsComponent2 } from '../../../home_page/homeComponents'

const BookPage = (props) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 750);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 750) {
        setIsLargeScreen(true);
      } else {
        setIsLargeScreen(false);
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const testimonials = [
    {
      coverImg: Property1,
      heading: 'My go to real estate media company',
      description:
        'Modern Angles is my go to real estate media company. They have a great eye, are extremely professional and yield outstanding results. Extremely easy to work with and my clients are always impressed. I highly recommend Modern Angles.',
      customerName: 'Beth Silfen',
      occupation: 'Compass',
      image: TESTIMONIALS.Customer1,
    },
    {
      coverImg: Property2,
      heading: 'The best of the best',
      description:
        'Modern Angles is literally the best of the best! GREAT guys to work with - very responsive and accommodating!',
      customerName: 'Justin Pieragostini',
      occupation: 'Keller Williams',
      image: TESTIMONIALS.Customer2,
    },
    {
      coverImg: Property3,
      heading: 'It was the photos that sold me',
      description:
        'Modern Angles has raised the bar for professional real estate photography. I am an agent with Coldwell Banker and my clients rave about how beautiful their homes look in my listing reports, and that’s all thanks to Modern Angles. Recently a buyer of a luxury home commented; "it was the photos that sold me on the house!".',
      customerName: 'Anne-Marie Nordgren',
      occupation: 'Coldwell Banker',
      image: TESTIMONIALS.Customer3,
    },
    {
      coverImg: Property4,
      heading: 'Easy to work with',
      description:
        'Modern Angles is easy to work with, accommodating when dates get changed and have a quick turnaround. My clients are very pleased with their work, especially when compared to many others.',
      customerName: 'Judy Karten',
      occupation: 'Coldwell Banker',
      image: TESTIMONIALS.Customer4,
    },
    {
      coverImg: Property5,
      heading: 'A top notch photography company',
      description:
        'Modern Angles is a top notch photography company. I’ve used many before finding them and always felt a bit disappointed - either with the lighting, angles or just the general vibe of the photos. Not the case with Modern Angles. Highly recommended!',
      customerName: 'Doug Pollak',
      occupation: 'Coldwell Banker',
      image: TESTIMONIALS.Customer5,
    },
    {
      coverImg: Property6,
      heading: 'Highly recommended',
      description:
        'Always a pleasure working with Modern Angles. High quality work and very easy to schedule. Highly recommended.',
      customerName: 'Mike Trinchitella',
      occupation: 'Re/Max Realty',
      image: TESTIMONIALS.Customer6,
    },
    {
      coverImg: Property7,
      heading: 'Their work is exceptional',
      description:
        'The Modern Angles team is amazing. Listings they photograph for me sell right away! Their work is exceptional and they turn around the finished product within 24 hours! Thanks Modern Angles!',
      customerName: 'Lisa Cozzi',
      occupation: '',
      image: TESTIMONIALS.Customer7,
    },
    {
      coverImg: Property8,
      heading: 'Captured the beauty of every home',
      description:
        'Modern Angles never disappoints! I have successfully used them for over 30 of my listings. They have always captured the natural essence and beauty of every home.',
      customerName: 'Carol Court',
      occupation: 'Coldwell Banker',
      image: TESTIMONIALS.Customer8,
    },
  ];

  const onScheduleShoot = () => {
    if (props.currentUser){
      props.history.push("/create-project")
    } else {
    props.openNewModal()
    }
  }

  return <>
    <Helmet>
      <title>Book a Real Estate Photographer Near You | Modern Angles</title>
      <link rel="canonical" href={window.location.href} />
      <meta
        name="description"
        content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
      />
    </Helmet>
    <Modals isLargeScreen={isLargeScreen}/>
    <div className="book-page">
      <div className="book-page-header">
        <Link to="/" className="book-page-header-logo">
          <img src={logoWhiteImg} />
        </Link>

        <img src={headerImg} className="book-page-header-bg"/>
      </div>

      <div className="book-page-header-container">
        <div className="book-page-header-container-inner">
          <div className="book-page-header-subtitle">Real Estate:</div>
          <div className="book-page-header-title">
            <div>
              <div>HDR Photography</div>
              <div>{isLargeScreen ? "Video Walkthrough" : "Video Tours"}</div>
              <div>{isLargeScreen ? "Drone Photography" : "Drone Photos"}</div>
              <div>Floor Plans</div>
              <div>Interactive Tours</div>
              <div aria-hidden={true}>HDR Photography</div>
            </div>
          </div>

          <div className="book-page-header-description">We create listing media that attracts more buyers.
          </div>

          <div className="text-center">
            <ScheduleShootButton onClick={onScheduleShoot} className="mt-0">
              Schedule Shoot / Pricing
            </ScheduleShootButton>

            <Link to={ROUTES.CONTACTS} className="speak-to-our-team-link">
              Contact Our Team
            </Link>
          </div>
        </div>

        <div className="lead-page-divider mb-0"/>

        <div className="book-page-header-container-inner">
          <FeaturesList />
        </div>

        <div className="lead-page-divider mb-0"/>

        {/* <div className="book-page-header-container-inner">
          <ServiceLogoListComponent hideDouglas={isLargeScreen ? false : true} title="Trusted by leading real estate companies:"/>
        </div> */}

        <TestimonialsComponent2 bookPage={true}/>

      </div>
      <div className="lead-page-divider mb-0"/>

      <Difference noTopBorder={true}/>

      <div className="book-page-get-started-container">
        <div className="book-page-get-started-title">
          <span className="accent"> Transform Your Listing </span><br/>with Real Estate Photography
        </div>

        <div className="book-page-get-started-images">
          <img src={firstImg}/>
          <img src={secondImg}/>
          <img src={thirdImg}/>
          <img src={forthImg}/>
        </div>
      </div>

      <div className="scheduleButtonDiv">
        <ScheduleShootButton onClick={onScheduleShoot} className="mt-0">
          Schedule Shoot
        </ScheduleShootButton>
      </div>

      <WhiteContainer>
        <HowItWorks />
      </WhiteContainer>


      {/* <div className="book-page-testimonials-container">
        <div className="book-page-container-inner book-page-testimonials-container-inner">
          <div className="book-page-testimonials-title">Testimonials</div>

          <div className="book-page-testimonials-cards">
            <AliceCarousel
              responsive={{
                0: { items: 1 },
                800: { items: 2 },
              }}
              animationDuration={1000}
              autoPlayInterval={5000}
              autoPlayStrategy={'all'}
              autoPlay={false}
              infinite={true}
              items={testimonials.map((testimonial, i) => (
                <TestimonialCard testimonial={testimonial} key={`testimonial-${i}`}/>
              ))}
              autoPlayControls={false}
              disableButtonsControls={true}
              mouseTracking={true}
            />
          </div>
        </div>
      </div> */}

      <Footer/>
    </div>
  </>
}

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(BookPage));