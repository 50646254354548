import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ContactsWrapper } from './ContactsStyles';

import { FormSection } from '../../contacts_page_components';
import { ContactFormGallery } from '../../pageComponents';

const ContactsPage = () => {
  return (
    <ContactsWrapper>
      <Helmet>
        <title>Real Estate Photography | Modern Angles Contact</title>
        <meta
          name="description"
          content="More agents trust Modern Angles to deliver outstanding images than any other full-service photography agency."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <FormSection />
      <ContactFormGallery isImageMarkerLight />
    </ContactsWrapper>
  );
};

export default withRouter(ContactsPage);
