import { connect } from "react-redux";
import createCategoryComponent from "./create_category";
import React from "react";
import { withRouter } from "react-router-dom";
import { createCategory } from "../../../actions/virtual_staging_actions";
import { closeModal } from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  createCategory: (category_data) => dispatch(createCategory(category_data)),
  closeModal: () => { dispatch(closeModal()) },
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(createCategoryComponent));