import React from "react";
import { Route, Link, withRouter } from "react-router-dom";

class ChooseTimeslotItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    let time = this.props.time;

    let id = "";
    let checkmark = "";
    let timeSelected = "";

    if (this.props.selected) {
      id = "timeslotSelected"
      timeSelected = "contractorTimeSelected"
      checkmark = <div className="timeslotCheckmark"><i className="fas fa-check-square"></i></div>
    }

    return (
      <div className="contractorTimeSlotDiv" id={id} onClick={() => this.props.handleTimeSelect(this.props.slot)}>
        <div className="contractorTime" id={timeSelected}>{time}</div>
        {checkmark}
      </div>
    )
  }
}

export default withRouter(ChooseTimeslotItem);