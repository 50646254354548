import styled from 'styled-components';

export const VirtualStaggingGalleryWrapper = styled.div`
  margin-top: 60px;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const VirtualStaggingGalleryContent = styled.div`
  padding: 1% 25%;
  @media screen and (max-width: 1850px) {
    padding: 1% 23%;
  }
  @media screen and (max-width: 1720px) {
    padding: 1% 21%;
  }
  @media screen and (max-width: 1599px) {
    padding: 1% 19%;
  }
  @media screen and (max-width: 1499px) {
    padding: 1% 17%;
  }
  @media screen and (max-width: 1400px) {
    padding: 1% 14%;
  }
  @media screen and (max-width: 1290px) {
    padding: 1% 10%;
  }
  @media screen and (max-width: 1150px) {
    padding: 1% 6%;
  }
  @media screen and (max-width: 1055px) {
    padding: 1% 3%;
  }
  @media screen and (max-width: 991px) {
    padding: 20px 58px;
  }
  @media screen and (max-width: 825px) {
    padding: 20px 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 32px;
  }
`;

export const VirtualStaggingGalleryHeading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1b264f;
  text-transform: initial;
  @media screen and (max-width: 991px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media screen and (max-width: 767px) {
    width: 94%;
    max-width: 350px;
    margin: 0 auto;
    font-size: 22px;
    line-height: 24px;
  }
`;

export const VirtualStaggingGallerySubHeading = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #1b264f;
  padding: 5px 0px 20px;
  @media screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 24px;
    padding: 20px 21%;
  }
  @media screen and (max-width: 825px) {
    padding: 20px 18%;
  }
  @media screen and (max-width: 767px) {
    width: 94%;
    max-width: 350px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    padding: 20px 0px;
  }
`;

export const VirtualStaggingGalleryHeadingEnd = styled.span`
  color: #d2ad81;
`;

export const GalleryVirtualStagging = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GalleryVirtualStaggingRowOne = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
  height: 468px;
  .rowOneGalleryWrapper1 {
    margin-right: 28px;
    flex: 2;
    img {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    @media screen and (max-width: 991px) {
      margin-right: 15px;
    }
    @media screen and (max-width: 767px) {
      margin-right: 10px;
      flex: 1;
      background-size: cover;
    }
  }
  .rowOneGalleryWrapper2 {
    margin-right: 28px;
    flex: 3;
    img {
      border-radius: 8px;
    }
    @media screen and (max-width: 991px) {
      margin-right: 15px;
    }
    @media screen and (max-width: 767px) {
      margin-right: 10px;
      flex: 2;
    }
  }
  .rowOneGalleryWrapper3 {
    margin-right: 28px;
    flex: 2;
    img {
      border-radius: 8px;
    }
    @media screen and (max-width: 991px) {
      margin-right: 15px;
    }
    @media screen and (max-width: 767px) {
      margin-right: -150px;
    }
  }
  .rowOneGalleryWrapper4 {
    img {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    height: 270px;
  }
  @media screen and (max-width: 767px) {
    height: 180px;
  }
`;

export const GalleryVirtualStaggingRowTwo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
  margin-top: 30px;
  height: 468px;
  .rowOneGalleryWrapper5 {
    margin-right: 28px;
    img {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  .rowOneGalleryWrapper6 {
    margin-right: 28px;
    flex: 12;
    img {
      border-radius: 8px;
    }
    @media screen and (max-width: 991px) {
      margin-right: 15px;
    }
    @media screen and (max-width: 767px) {
      margin-right: 10px;
    }
  }
  .rowOneGalleryWrapper7 {
    margin-right: 28px;
    flex: 12;
    img {
      border-radius: 8px;
    }
    @media screen and (max-width: 991px) {
      margin-right: 15px;
    }
    @media screen and (max-width: 767px) {
      margin-right: -50px;
    }
  }
  .rowOneGalleryWrapper8 {
    flex: 7;
    img {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    @media screen and (max-width: 991px) {
      margin-right: 0px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    height: 270px;
    margin-top: 18px;
  }
  @media screen and (max-width: 767px) {
    height: 180px;
    margin-top: 12px;
  }
`;

export const GalleryImageWrapper = styled.div`
  border-radius: 8px;
  flex: 1;
  position: relative;
  background-image: url(${(props) => props.sliderImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const ImageMarker = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  @media screen and (max-width: 991px) {
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;
