import React from "react";
import {fetchServices} from "../../util/service_api_util";
import {serviceIcon} from "../../util/service_util";
import UploadPhotosModal from "../edit_project/upload_photos_modal_container";
import {removeExternalPhotos, uploadExternalPhotos} from "../../util/project_api_util";
import {withRouter} from "react-router-dom";

class PropertyTourEditExternalPhotos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      photos: props.project.external_photos,
      selectedService: null,
      selectionEnabled: false,
      selectedPhotos: [],
      startUpload: false
    }

    this.submitPhotosFromUploadModal = this.submitPhotosFromUploadModal.bind(this)
  }

  displayedPhotos() {
    return this.state.photos.filter(p => p.service_id === this.state.selectedService?.id)
  }

  componentDidMount() {
    fetchServices().then(result => {
      const services = Object.values(result).filter(s => {
        return s.media_types === "image" && !s.reshoot
      })
      this.setState({services, selectedService: services[0]})
    })
  }

  selectService(e, service) {
    e.preventDefault()
    this.setState({selectedService: service})
  }

  startSelection(e) {
    e.preventDefault()
    this.setState({selectionEnabled: true})
  }

  cancelSelectionMode(e) {
    e.preventDefault()
    this.setState({selectionEnabled: false, selectedPhotos: []})
  }

  selectAll(e) {
    e.preventDefault()
    this.setState({selectedPhotos: this.displayedPhotos()})
  }

  clearSelection(e) {
    e.preventDefault()
    this.setState({selectedPhotos: []})
  }

  deleteSelected(e) {
    e.preventDefault()
    if (!window.confirm('Are you sure you want to delete these photos?')) return

    console.log("Deleting photos", this.state.selectedPhotos)

    const selectedPhotosIds = this.state.selectedPhotos.map(p => p.id)
    removeExternalPhotos(this.props.project.id, selectedPhotosIds).then(result => {
      this.props.showToast("Photos have been deleted")
      const project = result[this.props.project.id];
      this.setState({photos: project.external_photos})
      this.cancelSelectionMode(e)
      this.props.onReload(project)
    })
  }

  togglePhotoSelection(e, photo) {
    e.preventDefault()

    const {selectionEnabled, selectedPhotos} = this.state

    if (!selectionEnabled) return

    if (selectedPhotos.includes(photo)) {
      this.setState({selectedPhotos: selectedPhotos.filter(p => p !== photo)})
    } else {
      this.setState({selectedPhotos: [...selectedPhotos, photo]})
    }
  }

  actionButtons() {
    if (this.state.selectionEnabled) {
      return [
        {
          label: `${this.state.selectedPhotos.length} selected`,
          className: 'numberSelectedPhotos'
        },
        {
          label: this.props.isLargeScreen ? "Select All" : "All",
          onClick: e => this.selectAll(e),
          className: 'submitPhotosButton'
        },
        {
          label: 'Clear',
          onClick: e => this.clearSelection(e),
          className: 'submitPhotosButton'
        },
        {
          label: 'Delete',
          onClick: e => this.deleteSelected(e),
          className: 'submitPhotosButton'
        },
        {
          label: 'Cancel',
          onClick: e => this.cancelSelectionMode(e),
          className: 'submitPhotosButton'
        }
      ]
    } else {
      return [{
        label: 'Select',
        onClick: e => this.startSelection(e),
        className: 'submitPhotosButton'
      }]
    }
  }

  openUploadModal(e) {
    e.preventDefault()
    this.setState({startUpload: true})
  }

  canDisplayImage(filename) {
    const nonDisplayableExtensions = [".CR2", ".NEF", ".DNG", ".ARW"]
    return !nonDisplayableExtensions.some(ext => filename.endsWith(ext))
  }

  submitPhotosFromUploadModal(project, service, _, photos) {
    const access_code = this.props.match.params?.accessCode
    const formData = new FormData()

    for (let i = 0; i < photos.length; i++) {
      formData.append("photos[][service_id]", service.id)
      formData.append("photos[][image]", photos[i])
    }

    formData.append("access_code", access_code)

    return uploadExternalPhotos(project.id, formData).then(result => {
        this.props.showToast("Photos have been uploaded")
        const updatedProject = result[project.id];
        this.setState({photos: updatedProject.external_photos})
        this.props.onReload(updatedProject)
      }
    ).catch(() => {
      this.props.showToast("Unexpected error occurred")
    })
  }

  render() {
    const {services, selectedService, selectedPhotos, startUpload} = this.state
    const {isLargeScreen, project} = this.props

    return <>
      {startUpload && <UploadPhotosModal submitPhotosFromUploadModal={this.submitPhotosFromUploadModal}
                                         current_service={selectedService}
                                         project={project}
                                         onClose={() => this.setState({startUpload: false})}
                                         isLargeScreen={isLargeScreen}/>}

      <div className="propertyTourEditPhotos">
        <div className="propertyTourExternalServices">
          {services.map((service, i) => {
            const ServiceIcon = serviceIcon(service.slug)

            return <div
              className={`propertyTourEditButton ${service === selectedService ? '' : 'propertyTourEditButtonInactive'} ${i > 0 && 'ms-2'}`}
              onClick={e => this.selectService(e, service)}
              key={`service-${i}`}>
              {isLargeScreen ?
                <div>{service.title}</div> :
                <div><ServiceIcon className="editProjectMediaServiceIcon"/></div>}
            </div>
          })}
        </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap align-items-center mb-3">
        <div>
          <button
            className="projectEditButton projectEditButtonWhite projectEditMarginBottomButton"
            onClick={e => this.openUploadModal(e)}>
            Upload
          </button>
        </div>

        <div className="projectEditPhotosButtons">
          {this.actionButtons().map(button => {
            return <div className={button.className} {...(button.onClick ? {onClick: button.onClick} : {})}
                        key={`action-button-${button.label}`}>
              {button.label}
            </div>
          })}
        </div>
      </div>

      <div className="editProjectPhotos propertyTourPhotos mb-4">
        {this.displayedPhotos().map(photo => {
          return <div onClick={(e) => this.togglePhotoSelection(e, photo)}
                      key={`photo-${photo.id}`}
                      className={`editProjectPhotoWithFilename ${selectedPhotos.includes(photo) ? 'selected' : ''}`}>
            {this.canDisplayImage(photo.file_name) ?
              <>
                <img src={photo.url} className="editProjectPhoto"/>
                <div className="filename">{photo.file_name}</div>
              </> :
              <div className="editProjectPhotoWithFilename">
                <div className="projectEditTextImage">{photo.file_name}</div>
              </div>}
          </div>
        })}
      </div>
    </>
  }
}

export default withRouter(PropertyTourEditExternalPhotos);