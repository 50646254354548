import React from 'react'
import {BlueContainer, Heading, ImageWrapper, Paragraph, Paragraphs, ParagraphTitle, Wrapper} from "./styles";
import img from '../../../../../assets/images/location/topPhotographers-withBg.png'

export const TopPhotographer = (props) => {
  return <BlueContainer>
    <Wrapper>
      <ImageWrapper>
        <img src={img}/>
      </ImageWrapper>
      <div>
        <Heading>{props.city}'s Top Rated Real Estate Photographers</Heading>
        <Paragraphs>
          <div>
            <ParagraphTitle>Photographers in {props.city}</ParagraphTitle>
            <Paragraph>Our {props.city} real estate photographers are vetted and trained to ensure every listing is
              captured accurately, producing stunning real estate visuals that showcases each property at its
              best.
            </Paragraph>
          </div>
          <div>
            <ParagraphTitle>Lightning-Fast Turnaround</ParagraphTitle>
            <Paragraph>In the fast-paced real estate market, timing is crucial. We offer next-day media delivery, so you
              can stay ahead of the competition by integrating high-quality content into your marketing without
              delay.
            </Paragraph>
          </div>
          <div>
            <ParagraphTitle>Project Management Tools</ParagraphTitle>
            <Paragraph>Optimize your experience with our specialized project management platform designed
              for {props.city} Realtors. Simplify scheduling, project management, and media access, allowing easy
              control over your real estate marketing.
            </Paragraph>
          </div>
        </Paragraphs>
      </div>
    </Wrapper>
  </BlueContainer>
}