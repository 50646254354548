import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UpdateBlog from "./update_blog";
import { openModal } from "../../actions/modal_actions";
import { showToast } from "../../actions/toast_actions";

const msp = (state, ownProps) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message)),
  openModal: (modal, props) => {
    dispatch(openModal(modal, props));
  },
});

export default withRouter(connect(msp, mdp)(UpdateBlog));