import React from 'react'

export const ServicesList = ({items}) => {
  return <div className="apply-for-discount-services-list">
    {items.map(item => (
      <div className="apply-for-discount-services-list-item" key={`services-list-item-${item.title}`}>
        <div className="apply-for-discount-services-list-item-title">
          <div className="apply-for-discount-services-list-item-title-img">
            <img src={item.icon}/>
          </div>
          <span>{item.title}</span>
        </div>

        <div className="apply-for-discount-services-list-item-description">
          {item.description}
        </div>
      </div>
    ))}
  </div>
}