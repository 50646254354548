import React from 'react'
import {DIFFERENCES} from "../../../../../assets/images/landing";

const data = [
  {
    title: 'Seamless Booking',
    description: 'Effortlessly schedule your photo shoot with our user-friendly online platform.',
    icon: DIFFERENCES.ReceiptIcon
  },
  {
    title: 'Expert Photographers',
    description: 'Capture your listing through the eye of our experienced local photographers.',
    icon: DIFFERENCES.PhotoIcon
  },
  {
    title: 'Competitive Pricing',
    description: 'Modern Angles provides competitive pricing without compromising on quality.',
    icon: DIFFERENCES.BadgeIcon
  }
]

export const Difference = ({noTopBorder}) => {
  return <div className={`differences-wrapper ${noTopBorder ? '' : 'top-border'}`}>
    <div className="lead-page-title">The Modern Angles Difference</div>
    <div className="lead-page-wrapper-inner">
      <div className="differences-wrapper-list">
        {data.map((item, i) => (<div className="differences-wrapper-list-item" key={`difference-${i}`}>
          <div className="differences-wrapper-list-item-icon-and-title">
            <img className="differences-wrapper-list-item-icon" src={item.icon} />
            <div className="differences-wrapper-list-item-title">{item.title}</div>
          </div>
          <div className="differences-wrapper-list-item-description">
            {item.description}
          </div>
        </div>))}
      </div>
    </div>
  </div>
}