import React from "react";
import { Route, Link } from "react-router-dom";


export default class createFirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      broker_charge: true,
      broker_charge_virtual_staging: true
    }
    this.handleInput = this.handleInput.bind(this)
    this.createFirm = this.createFirm.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handleCheckbox(field) {
    return e => this.setState({ [field]: !this.state[field] })
  }


  createFirm() {
    let firm_info = { name: this.state.name, broker_charge: this.state.broker_charge, broker_charge_virtual_staging: this.state.broker_charge_virtual_staging}

    this.props.createFirm(firm_info).then((firm) => {
      if (this.props.errors.length === 0) {
        this.props.closeModal();
        this.props.onFirmCreate(firm)
      }
    })
  }


  render() {
    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading"> Add Firm</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>
          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel"> Firm Name </label>
              <input className="formInput" onChange={this.handleInput("name")} type="string" placeholder="Name" value={this.state.name} />
            </div>
          </div>
          <div className="settingsCheckboxGroup">
            <label><input type="checkbox" className="formCheckBox" checked={this.state.broker_charge} onChange={this.handleCheckbox("broker_charge")} /> <span className="rememberMe">Broker Charge</span></label>
            <label><input type="checkbox" className="formCheckBox" checked={this.state.broker_charge_virtual_staging} onChange={this.handleCheckbox("broker_charge_virtual_staging")} /> <span className="rememberMe">Broker Charge (VS)</span></label>
          </div>
          <button onClick={this.createFirm} className="settingsButton companyButton">Create Firm</button>
        </div>
      </div>
    );
  }
}