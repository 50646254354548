import React from "react";
import {createTermsOfService} from "../../../util/terms_of_service_util";
import Editor from 'react-medium-editor';

import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';


export default class CreateTermsOfService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: "",
      content: "",
      user_role: "",
      errors: {}
    }

    this.onCreate = this.onCreate.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onContentChange = this.onContentChange.bind(this)
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onContentChange(text) {
    this.setState({content: text});
  }

  onCreate() {
    const terms = {
      version: this.state.version,
      content: this.state.content,
      user_role: this.state.user_role
    }

    createTermsOfService(terms).then(result => {
      if (!result.errors) {
        this.props.closeModal()
        this.props.onTermsVersionAdded(result)
      } else {
        this.setState({errors: result.errors})
      }
    })
  }

  render() {
    return (
      <div className="createTermsServiceModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">Add Terms of Service</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel"> Version </label>
              <select className="formInput" onChange={this.onChange} name="user_role" value={this.state.user_role}>
                <option value="">No user role selected</option>
                <option value="admin">Admin</option>
                <option value="broker">Broker</option>
                <option value="client">Client</option>
                <option value="contractor">Contractor</option>
                <option value="post_production">Post Production</option>
                <option value="sales_rep">Sales Rep</option>
                <option value="sub_broker">Sub Broker</option>
              </select>
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel"> Version </label>
              <input className="formInput" onChange={this.onChange}
                     placeholder="Version" name="version" value={this.state.version}/>
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel"> Content </label>
              <div className="termsOfServiceContent editorContent">
                <Editor text={this.state.content} onChange={this.onContentChange} />
              </div>
            </div>
          </div>
          <button onClick={this.onCreate} className="settingsButton companyButton">Create</button>
        </div>
      </div>
    );
  }
}