import styled from "styled-components";
import {GetStartedBtnLight} from "../../../util/color_constant_util";

export const ButtonWrapper = styled.div`
  margin-top: 50px;
  text-align: center;
  
  @media screen and (max-width: 799px) {
    margin-top: 30px;
  }
`;

export const ApplyButton = styled.button`
  width: 202px;
  height: 60px;
  background-color: #1B264F;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  
  &:hover {
    background: #0d1533;
  }
  
  @media only screen and (max-width: 769px) {
    width: 144px;
    height: 43px;
    font-size: 14px;
    line-height: 14px;
  }
`;