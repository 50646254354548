import styled from 'styled-components';

export const LocationsHeaderWrapper = styled.div`
  @media only screen and (max-width: 769px) {
    display: flex;
    padding: 0 24px;
    border-top: 1px solid #E6E8EF;
    background: #fff;
  }
`;

export const LocationsHeaderInnerWrapper = styled.div`
  width: 997px;
  margin: 98px auto 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    width: 760px;
  }
  @media only screen and (max-width: 769px) {
    width: 98%;
    max-width: 350px;
  }
`;

export const LocationsTitle = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 54px;
  letter-spacing: -0.01em;
  color: #1b264f;
  width: 70%;
  text-align: center;
  @media screen and (max-width: 769px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const LocationsDescription = styled.p`
  margin: 36px 0;
  width: 70%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  text-align: center;
  
  @media screen and (max-width: 769px) {
    width: 100%;
    max-width: 350px;
    font-size: 16px;
    line-height: 22px;
    margin: 8px 0px 28px 0px;
  }
`;