import { connect } from "react-redux";
import SettingsPromos from "./settings_promos";
import React from "react";
import { withRouter } from "react-router-dom";
import { updatePromo, createPromo, deletePromo, fetchPromos } from "../../../actions/settings_actions";
import { openModal, addInfo } from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";
import { fetchServicesSettings } from "../../../actions/service_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  fetchServicesSettings: () => { dispatch(fetchServicesSettings()) },
  fetchPromos: () => dispatch(fetchPromos()),
  updatePromo: (promo, promo_id) => dispatch(updatePromo(promo, promo_id)),
  deletePromo: (promo_id) => dispatch(deletePromo(promo_id)),
  createPromo: (promo) => dispatch(createPromo(promo)),
  showToast: (message) => dispatch(showToast(message)),
  openModal: (modal, props) => {
    dispatch(openModal(modal, props));
  }
});

export default withRouter(connect(msp, mdp)(SettingsPromos));