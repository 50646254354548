import React from "react";
import {createSubBroker} from "../../../util/sub_brokers_api_util";
import TimeZoneSelect from "../profile/time_zone_select";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";


export default class CreateSubBrokerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      phoneNumber: "",
      agentId: "",
      errors: [],
      timezone: 'Eastern Time (US & Canada)',
      loading: false
    }

    this.handleInput = this.handleInput.bind(this)
    this.createNewUser = this.createNewUser.bind(this)
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value});
  }

  createNewUser() {
    const user = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password,
      phone_number: this.state.phoneNumber,
      agent_mls_id: this.state.agentId,
      timezone: this.state.timezone
    }

    this.setState({loading: true})

    createSubBroker(user).then(user => {
      this.props.closeModal();
      this.props.onUserCreate(user)
    }).catch(result => {
      this.setState({loading: false, errors: [result.responseJSON] || []})
    })
  }

  render() {
    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">Create Sub-Broker</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel">First name</label>
              <input className="formInput" onChange={this.handleInput("firstName")}
                     placeholder="First Name" value={this.state.firstName}/>
            </div>
            <div className="signupRightInput">
              <label id="formLabel">Last name</label>
              <input className="formInput" onChange={this.handleInput("lastName")} placeholder="Last Name"
                     value={this.state.lastName}/>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel">Email</label>
              <input className="formInput" onChange={this.handleInput("email")} type="email" placeholder="Email address"
                     value={this.state.email}/>
            </div>
            <div className="signupRightInput">
              <label id="formLabel">Phone number</label>
              <input className="formInput" onChange={this.handleInput("phoneNumber")} type="tel"
                     placeholder="Phone Number" value={this.state.phoneNumber}/>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel">Password</label>
              <input className="formInput" onChange={this.handleInput("password")}
                     type="password" placeholder="Password" value={this.state.password}/>
            </div>
            <div className="signupRightInput">
              <label id="formLabel">Agent MLS ID (optional)</label>
              <input className="formInput" onChange={this.handleInput("agentId")}
                     placeholder="Agent MLS ID" value={this.state.agentId}/>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="w-100">
              <label id="formLabel"> Time Zone </label>
              <TimeZoneSelect value={this.state.timezone} onChange={this.handleInput("timezone")} />
            </div>
          </div>

          {this.state.loading ? <div className="">
            <img className="logoImageSinnerGifPayout" src={LogoSpinner}></img>
          </div> : <button onClick={this.createNewUser} className="settingsButton">Add sub-broker</button>}
        </div>
      </div>
    );
  }
}