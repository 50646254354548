import React from "react";

const Location = ({location, setLocation}) => {

  const onTravelDurationChange = (e) => {
    e.preventDefault()
    setLocation({...location, travelDuration: e.target.value})
  }

  const onInputChange = (field) => {
    return (e) => {
      e.preventDefault()
      setLocation({...location, [field]: e.target.value})
    }
  }

  return <>
    <h3 className="h3React">Where can you work?</h3>
    <div className="description">
      Modern Angles works with real estate professionals to book photography projects on your schedule.
    </div>

    <div className="inputs-groups">
      <div className="input-fields">
        <div className="input-field">
          <label className="formLabel required">City</label>
          <input type="text" className="formInput" required={true} placeholder="Content" value={location.city || ''}
                 onChange={onInputChange('city')}/>
        </div>
        <div className="input-field">
          <label className="formLabel required">State</label>
          <input type="text" className="formInput" required={true} placeholder="Content" value={location.state || ''}
            onChange={onInputChange('state')} />
        </div>
      </div>
    </div>

    <div className="inputs-groups">
      <div className="input-fields">
        <div className="input-field">
          <label className="formLabel required">Are you willing to travel?</label>
          <select value={location.travelDuration} onChange={onTravelDurationChange} className="formInput">
            <option value="10 Miles">10 Miles</option>
            <option value="30 Miles">30 Miles</option>
            <option value="50 Miles">50 Miles</option>
          </select>
        </div>
      </div>
    </div>
  </>
}

export default Location
