import React from "react";
import { fetchBlogCategories, deleteBlogCategory, fetchBlogs, deleteBlog } from '../../../util/blog_api_utils';


export default class BlogCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blog_categories: [],
      blogs: []
    }
  }

  componentDidMount() {
    fetchBlogCategories().then(blog_categories => {
      this.setState({ blog_categories })
    }).then(fetchBlogs().then(blogs => {
      this.setState({blogs: blogs})
    }))
  }

  onCreate() {
    this.props.openModal("blogCategoryForm", { onSave: this.onCreated.bind(this) })
  }

  onCreateBlog() {
    this.props.history.push(`/create-blog`)
  }

  onUpdate(blog_category) {
    this.props.openModal("blogCategoryForm", { onSave: this.onUpdated.bind(this), blog_category })
  }

  onUpdateBlog(blog) {
    this.props.history.push(`/blog/${blog.slug}/update`)
  }

  onDelete(blog_category) {
    if (window.confirm(`Are you sure you want to delete "${blog_category.title}"?`)) {
      deleteBlogCategory(blog_category.id).then(() => {
        this.setState({ blog_categories: this.state.blog_categories.filter(f => f.id !== blog_category.id) })
        this.props.showToast("Category has been removed")
      }).catch(() => {
        alert('Could not delete the blog category')
      })
    }
  }

  onDeleteBlog(blog) {
    if (window.confirm(`Are you sure you want to delete "${blog.title}"?`)) {
      deleteBlog(blog.id).then(() => {
        this.setState({ blogs: this.state.blogs.filter(f => f.id !== blog.id) })
        this.props.showToast("Blog has been removed")
      }).catch(() => {
        alert('Could not delete the blog')
      })
    }
  }

  onCreated(blog_category) {
    this.setState({ blog_categories: [...this.state.blog_categories, blog_category] })
    this.props.showToast("Category has been created")
  }

  onUpdated(updatedBlogCategory) {
    this.setState({ blog_categories: this.state.blog_categories.map(blog_category => blog_category.id === updatedBlogCategory.id ? updatedBlogCategory : blog_category) })
    this.props.showToast("Category has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Blog Categories</div>
          <div className="settingsPreferencesGroup1">
            {this.state.blog_categories.map(blog_category => (
              <div className="settingsServicesItem" key={blog_category.id}>
                <div className="itemTitle">{blog_category.title}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdate(blog_category)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDelete(blog_category)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>

              </div>
            ))}
          </div>
          <button onClick={() => this.onCreate()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Blogs</div>
          <div className="settingsPreferencesGroup1">
            {this.state.blogs.map(blog => (
              <div className="settingsServicesItem" key={blog.id}>
                <div className="itemTitle">{blog.title}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdateBlog(blog)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDeleteBlog(blog)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>

              </div>
            ))}
          </div>
          <button onClick={() => this.onCreateBlog()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}