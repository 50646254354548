import styled from 'styled-components';

export const ContractMediaSlide1Part1 = styled.img`
  position: absolute;
  width: 75%;
  top: 0%;
  left: 0%;
`;

export const ContractMediaSlide1Part2 = styled.img`
  position: absolute;
  width: 83%;
  top: 2%;
  left: 2%;
  z-index: 2;
`;

export const ContractMediaSlide1Part3 = styled.img`
  position: absolute;
  width: 25%;
  right: 2%;
  top: 21%;
  z-index: 1;
`;
