import React from "react";
import {deleteFAQ, fetchFAQs} from "../../../util/faq_api_utils";


export default class SettingsFaqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: []
    }
  }

  componentDidMount() {
    fetchFAQs().then(faqs => {
      this.setState({faqs})
    })
  }

  onCreate() {
    this.props.openModal("faqForm", {onSave: this.onCreated.bind(this)})
  }

  onUpdate(faq) {
    this.props.openModal("faqForm", {onSave: this.onUpdated.bind(this), faq})
  }

  onDelete(faq) {
    if (window.confirm(`Are you sure you want to delete "${faq.title}"?`)) {
      deleteFAQ(faq.id).then(() => {
        this.setState({faqs: this.state.faqs.filter(f => f.id !== faq.id)})
        this.props.showToast("FAQ article has been removed")
      }).catch(() => {
        alert('Count not delete FAQ article')
      })
    }
  }

  onCreated(faq) {
    this.setState({faqs: [...this.state.faqs, faq]})
    this.props.showToast("FAQ article has been created")
  }

  onUpdated(updatedFaq) {
    this.setState({faqs: this.state.faqs.map(faq => faq.id === updatedFaq.id ? updatedFaq : faq)})
    this.props.showToast("FAQ article has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">FAQ</div>
          <div className="settingsPreferencesGroup1">
            {this.state.faqs.map(faq => (
              <div className="settingsServicesItem" key={faq.id}>
                <div className="mr-auto">{faq.title}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdate(faq)}>
                  <i className="far fa-edit settingsServiceIcon m-2"/>
                </div>
                <div className="itemIcon" onClick={() => {this.onDelete(faq)}}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2"/>
                </div>

              </div>
            ))}
          </div>
          <button onClick={() => this.onCreate()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}