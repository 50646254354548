import styled from 'styled-components';

import { TOP_SECTION, CIRCLE } from '../../../../../assets/images/aboutPage';

export const TopSectionWrapper = styled.div`
  width: 100vw;
  position: relative;
  &::before {
    content: '';
    width: 512px;
    height: 497px;
    position: absolute;
    bottom: -47%;
    right: -6rem;
    background-image: url(${CIRCLE});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
    @media screen and (max-width: 1050px) {
      bottom: -62%;
    }
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
  @media screen and (max-width: 769px) {
    padding-top: 23px;
  }
`;

export const TopSectionInnerWrapper = styled.div`
  width: 988px;
  margin: 0px auto 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1050px) {
    width: 752px;
  }
  @media screen and (max-width: 769px) {
    width: 372px;
    margin: 0px auto 0px;
  }
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 28px;
  @media screen and (max-width: 769px) {
    margin-right: 10px;
  }
`;

export const SecondColumn = styled.div``;

export const Image1 = styled.div`
  width: 383px;
  height: 409px;
  position: relative;
  margin-bottom: 28px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${TOP_SECTION.TopSectionImage1});
  @media screen and (max-width: 1050px) {
    width: 292px;
    height: 312px;
    margin-bottom: 18px;
  }
  @media screen and (max-width: 769px) {
    width: 144px;
    height: 154px;
    margin-bottom: 10px;
  }
`;

export const Image2 = styled.div`
  width: 480px;
  height: 389px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${TOP_SECTION.TopSectionImage2});
  @media screen and (max-width: 1050px) {
    width: 365px;
    height: 296px;
  }
  @media screen and (max-width: 769px) {
    width: 180px;
    height: 146px;
  }
`;

export const Image3 = styled.div`
  width: 480px;
  height: 600px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${TOP_SECTION.TopSectionImage3});
  @media screen and (max-width: 1050px) {
    width: 365px;
    height: 456px;
  }
  @media screen and (max-width: 769px) {
    width: 180px;
    height: 226px;
  }
`;

export const ImageMarker = styled.img`
  width: 83px;
  height: 83px;
  position: absolute;
  top: -65px;
  left: 190px;
  transform: translate(-50%);
  @media screen and (max-width: 1050px) {
    width: 52px;
    height: 52px;
    top: -40px;
    left: 146px;
  }
  @media screen and (max-width: 769px) {
    width: 32px;
    height: 32px;
    top: -26px;
    left: 72px;
  }
`;
