export const START_MEDIA_UPLOADING = "START_MEDIA_UPLOADING";
export const PROGRESS_MEDIA_UPLOADING = "PROGRESS_MEDIA_UPLOADING";
export const ABORT_MEDIA_UPLOADING = "ABORT_MEDIA_UPLOADING";
export const CLEAR_MEDIA_UPLOADING = "CLEAR_MEDIA_UPLOADING";

export const startMediaUploading = (uploader) => ({
  type: START_MEDIA_UPLOADING,
  uploader
})

export const progressMediaUploading = (uploader) => ({
  type: PROGRESS_MEDIA_UPLOADING,
  uploader
});

export const abortMediaUploading = (uploader) => ({
  type: ABORT_MEDIA_UPLOADING,
  uploader
});

export const clearMediaUploading = () => ({
  type: CLEAR_MEDIA_UPLOADING
});