import React from "react";

const PersonalDetails = ({personalDetails, setPersonalDetails}) => {

  const onInputChange = (field) => {
    return (e) => {
      e.preventDefault()
      setPersonalDetails({...personalDetails, [field]: e.target.value})
    }
  }

  return <>
    <h3 className="h3React">Welcome to Modern Angles</h3>
    <div className="description">
      Modern Angles works with real estate professionals to book photography projects on your schedule.
    </div>
    <div className="description">
      Enter your information to begin your application:
    </div>

    <div className="inputs-groups">
      <div className="input-fields">
        <div className="input-field">
          <label className="formLabel required">First name</label>
          <input type="text" className="formInput" required={true} placeholder="Content" value={personalDetails.firstName || ''}
                 onChange={onInputChange('firstName')}/>
        </div>
        <div className="input-field">
          <label className="formLabel required">Last name</label>
          <input type="text" className="formInput" required={true} placeholder="Content" value={personalDetails.lastName || ''}
                 onChange={onInputChange('lastName')}/>
        </div>
      </div>
    </div>

    <div className="inputs-groups">
      <div className="input-fields">
        <div className="input-field">
          <label className="formLabel required">Email</label>
          <input type="email" className="formInput" required={true} placeholder="Content" value={personalDetails.email || ''}
                 onChange={onInputChange('email')}/>
        </div>
        <div className="input-field">
          <label className="formLabel">Phone number</label>
          <input type="text" className="formInput" placeholder="Content" value={personalDetails.phoneNumber || ''}
                 onChange={onInputChange('phoneNumber')}/>
        </div>
      </div>
    </div>
  </>
}

export default PersonalDetails
