import React from "react";

import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import { createLocation, updateLocation } from "../../../util/location_api_util";


export default class LocationFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.city?.id || "",
      city: this.props.city?.city || "",
      region: this.props.city?.state || "",
      image: "",
      image_src: this.props.city?.image?.url || "",
      header_image: "",
      header_image_src: this.props.city?.header_image?.url || "",
      loading: false,
      errors: []
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onPictureChange = this.onPictureChange.bind(this)
    this.onHeaderPictureChange = this.onHeaderPictureChange.bind(this)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onPictureChange(event) {
    const image = event.target.files[0];
    const image_src = URL.createObjectURL(image);

    this.setState({
      image: image,
      image_src: image_src
    });
  }

  onHeaderPictureChange(event) {
    const header_image = event.target.files[0];
    const header_image_src = URL.createObjectURL(header_image);

    this.setState({
      header_image: header_image,
      header_image_src: header_image_src
    });
  }

  onSave() {
    const { city, image, region, header_image } = this.state
    const locationParams = new FormData();
    locationParams.append("location[city]", city);
    locationParams.append("location[state]", region);
    
    if (image) locationParams.append("location[image]", image);
    if (header_image) locationParams.append("location[header_image]", header_image);

    const save = this.state.id ?
      updateLocation(this.state.id, locationParams) :
      createLocation(locationParams)

    this.setState({ loading: true })

    save.then(location => {
      this.props.closeModal()
      this.props.onSave(location)
      this.setState({ loading: false })
    }).catch(response => {
      this.setState({ loading: false })
      this.setState({ errors: response.responseJSON || [] })
    })
  }

  render() {
    const stateOptions = [
      <option key="Alabama" value="Alabama">Alabama</option>,
      <option key="Alaska" value="Alaska">Alaska</option>,
      <option key="Arizona" value="Arizona">Arizona</option>,
      <option key="Arkansas" value="Arkansas">Arkansas</option>,
      <option key="California" value="California">California</option>,
      <option key="Colorado" value="Colorado">Colorado</option>,
      <option key="Connecticut" value="Connecticut">Connecticut</option>,
      <option key="Delaware" value="Delaware">Delaware</option>,
      <option key="Florida" value="Florida">Florida</option>,
      <option key="Georgia" value="Georgia">Georgia</option>,
      <option key="Hawaii" value="Hawaii">Hawaii</option>,
      <option key="Idaho" value="Idaho">Idaho</option>,
      <option key="Illinois" value="Illinois">Illinois</option>,
      <option key="Indiana" value="Indiana">Indiana</option>,
      <option key="Iowa" value="Iowa">Iowa</option>,
      <option key="Kansas" value="Kansas">Kansas</option>,
      <option key="Kentucky" value="Kentucky">Kentucky</option>,
      <option key="Louisiana" value="Louisiana">Louisiana</option>,
      <option key="Maine" value="Maine">Maine</option>,
      <option key="Maryland" value="Maryland">Maryland</option>,
      <option key="Massachusetts" value="Massachusetts">Massachusetts</option>,
      <option key="Michigan" value="Michigan">Michigan</option>,
      <option key="Minnesota" value="Minnesota">Minnesota</option>,
      <option key="Mississippi" value="Mississippi">Mississippi</option>,
      <option key="Missouri" value="Missouri">Missouri</option>,
      <option key="Montana" value="Montana">Montana</option>,
      <option key="Nebraska" value="Nebraska">Nebraska</option>,
      <option key="Nevada" value="Nevada">Nevada</option>,
      <option key="New Hampshire" value="New Hampshire">New Hampshire</option>,
      <option key="New Jersey" value="New Jersey">New Jersey</option>,
      <option key="New Mexico" value="New Mexico">New Mexico</option>,
      <option key="New York" value="New York">New York</option>,
      <option key="North Carolina" value="North Carolina">North Carolina</option>,
      <option key="North Dakota" value="North Dakota">North Dakota</option>,
      <option key="Ohio" value="Ohio">Ohio</option>,
      <option key="Oklahoma" value="Oklahoma">Oklahoma</option>,
      <option key="Oregon" value="Oregon">Oregon</option>,
      <option key="Pennsylvania" value="Pennsylvania">Pennsylvania</option>,
      <option key="Rhode Island" value="Rhode Island">Rhode Island</option>,
      <option key="South Carolina" value="South Carolina">South Carolina</option>,
      <option key="South Dakota" value="South Dakota">South Dakota</option>,
      <option key="Tennessee" value="Tennessee">Tennessee</option>,
      <option key="Texas" value="Texas">Texas</option>,
      <option key="Utah" value="Utah">Utah</option>,
      <option key="Vermont" value="Vermont">Vermont</option>,
      <option key="Virginia" value="Virginia">Virginia</option>,
      <option key="Washington" value="Washington">Washington</option>,
      <option key="West Virginia" value="West Virginia">West Virginia</option>,
      <option key="Wisconsin" value="Wisconsin">Wisconsin</option>,
      <option key="Wyoming" value="Wyoming">Wyoming</option>
    ];

    return (
      <div className="addServiceModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Location' : 'Create Location'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel">City</label>
              <input className="formInput" onChange={this.onChange} name="city" value={this.state.city} />
            </div>

            {/* <div className="settingsServicesInput">
              <label id="formLabel">State</label>
              <input className="formInput" onChange={this.onChange} name="region" value={this.state.region} />
            </div> */}

            <div className="settingsCompanyInput">
              <label id="formLabel"> State </label>
              <select className="formInput" onChange={this.onChange} name="region" value={this.state.region} >
                <option value="" disabled={true}>State</option>
                {stateOptions}
              </select>
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Image</label>
              <div className="galleryPhotoDiv">
                {this.state.image_src &&
                  <img className="settingsAvatar settingsGalleryCover" src={this.state.image_src} />}
                <label className="settingsChangePhotoButton coverUploadButton">
                  <input onChange={this.onPictureChange} type="file" accept="image/png, image/jpeg" />
                  {this.state.image_src ? 'Change' : 'Add image'}
                </label>
              </div>
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Header Image</label>
              <div className="galleryPhotoDiv">
                {this.state.header_image_src &&
                  <img className="settingsAvatar settingsGalleryCover" src={this.state.header_image_src} />}
                <label className="settingsChangePhotoButton coverUploadButton">
                  <input onChange={this.onHeaderPictureChange} type="file" accept="image/png, image/jpeg" />
                  {this.state.header_image_src ? 'Change' : 'Add image'}
                </label>
              </div>
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">Save</button>
        </div>
      </div>
    );
  }
}