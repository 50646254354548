import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {fetchReviews} from "../../../actions/review_actions";
import { stars2 } from "../../../util/rating_util";

const TestimonialsComponent2 = (props) => {

  const [maxReviews, setMaxReviews] = useState(window.innerWidth <= 1000 ? 3 : props.reviews.length);

  useEffect(() => {
    if (props.reviews.length === 0){
      props.fetchReviews()
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setMaxReviews(window.innerWidth <= 1000 ? 3 : 4);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  
  let review_divs = props.reviews.slice(0, maxReviews).map((review => {

    let star_rating = stars2(review.rating)
    
    const differenceInDays = 10000

    return <div className="modernAnglesReview">
      <div>
        <div>{star_rating}</div>
        <div className="modernAnglesReviewContent">{review.content}</div>
      </div>
      <div className="modernAnglesReviewAuthorSection">
        <div className="modernAnglesReviewAvatarAndName">
          <img className="modernAnglesReviewAvatar" src={review.author_avatar}/>
          <div className="modernAnglesReviewAuthorName">{review.author_name}</div>
        </div>
        <div className="modernAnglesReviewTimeAndGoogle">
          <div className="modernAnglesReviewTime">{differenceInDays > 30 ? review.relative_time : `${differenceInDays} days ago`}</div>
          <div className="modernAnglesReviewGoogleSymbol">Google</div>
        </div>
      </div>
      </div>
   }))


  return (<div className="modernAnglesReviewContainer" id={props.needsMarginBottom ? "modernAnglesReviewMarginBottom" : props.needsMarginTopAndBottom ? "modernAnglesReviewMarginBottomAndTop" : props.bookPage ? "modernAnglesReviewBookPage" : ""}>
    <div className="modernAnglesReviewGroup">
      {review_divs}
    </div>
    <a href="https://www.google.com/search?q=modern+angles+&sca_esv=592356204&sxsrf=AM9HkKmIrBgR0ixi5wR1NxokFiQsPa41CA%3A1703030697748&ei=qS-CZeehLdPaptQP_PuxqAI&ved=0ahUKEwinmp_e25yDAxVTrYkEHfx9DCUQ4dUDCBA&uact=5&oq=modern+angles+&gs_lp=Egxnd3Mtd2l6LXNlcnAiDm1vZGVybiBhbmdsZXMgMgQQIxgnMgUQABiABDIGEAAYFhgeMggQABgWGB4YDzIGEAAYFhgeMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMgYQABgWGB4yCBAAGBYYHhgPSK4OUABYzglwAHgBkAEBmAHZAqABnBGqAQcyLjYuNC4xuAEDyAEA-AEBwgIKECMYgAQYigUYJ8ICEBAAGIAEGIoFGEMYsQMYgwHCAgoQABiABBiKBRhDwgIREC4YgAQYsQMYgwEYxwEY0QPCAgsQLhiDARixAxiABMICCxAAGIAEGLEDGIMBwgILEAAYgAQYigUYkQLCAgsQLhiABBixAxiDAcICDhAAGIAEGIoFGJECGLEDwgIUEC4YgAQYigUYkQIYsQMYxwEY0QPCAggQLhiABBixA8ICCBAAGIAEGLEDwgILEC4YgAQYsQMY1ALCAg4QLhiABBjHARivARiOBcICChAAGIAEGBQYhwLCAhEQLhiABBixAxjHARivARiOBcICCxAuGIAEGMcBGNED4gMEGAAgQYgGAQ&sclient=gws-wiz-serp#lrd=0x89c2b73c4b9f947f:0xdd128d4ccaddc9cb,1,,,," target="_blank" className="viewAllButton">View all</a>
    </div>)

}


const msp = (state) => ({
  reviews: state.entities.reviews
});

const mdp = (dispatch) => ({
  fetchReviews: () => dispatch(fetchReviews())
});

export default withRouter(connect(msp, mdp)(TestimonialsComponent2));