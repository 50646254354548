import React from "react";
import { Route, Link } from "react-router-dom";
import SettingsFirmsItem from "../firms/settings_firms_item";
import {fetchEnablePayoutLink} from "../../../util/settings_api_util";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";

export default class SettingsPayouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payouts: [],
      account_holder_name: this.props.currentUser.external_account_name ? this.props.currentUser.external_account_name : "",
      account_number: "",
      routing_number: "",
      external_account_info_url: "",
      enabled: false,
      loading: false
    }
    this.handleInput = this.handleInput.bind(this)
    this.createExternalAccount = this.createExternalAccount.bind(this)
    this.enablePayouts = this.enablePayouts.bind(this)
  }

  componentDidMount() {
    // this.props.fetchPayouts(this.props.currentUser.id).then(payouts => this.setState({ payouts: payouts })).then(() => this.props.fetchBankInfo)
    // this.props.fetchBankInfo
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  createExternalAccount(){
    let external_account = {user_id: this.props.currentUser.id, currency: "usd", country: "US", account_holder_name: this.state.account_holder_name, account_number: this.state.account_number, routing_number: this.state.routing_number}
    this.setState({loading: true})
    this.props.createExternalAccount(external_account).then(external_account => {
      if (external_account.success){
        this.props.showToast("Bank account successfully linked.")
        this.setState({enabled: true, loading: false})
      } else {
        this.props.showToast(external_account.errors)
        this.setState({loading: false})
      }
    })
  }

  enablePayouts(){
    fetchEnablePayoutLink().then(info => {
      const newWindow = window.open(info.url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    })
  }

  render() {
    // let settingsPayoutItems = this.state.payouts.map((payout, i) => 
    // <div className="settingsPayoutItem" key={i}>
    //   <div className="payoutItemColumn">{payout.amount}</div>
    //   <div className="payoutItemColumn">{payout.project_ids}</div>
    //   {/* <div className="payoutItemColumn">{payout.created_at}</div> */}
    // </div>)

    // if (settingsPayoutItems.length === 0){
    //   settingsPayoutItems = <div className="noPayoutText">No payouts have occured yet</div>
    // }

    let cardHolderNameErrorClass = "";
    let cardNumberErrorClass = "";


    let cardNumberErrors = [];
    let cardHolderNameErrors = [];


    return (
      <div className="settingsPayoutGroup">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">{this.props.currentUser.external_account_name ? "Change Payment Info" : "Create Payment Info"}</div>
          <div className="mt-2">For your security current bank account info will not show up here. Only fill out this form if you want to insert new bank account information.</div>
          <div className="settingsPreferencesGroup1">
            <div className="inputGrouping">
              <div className="settingsCompanyInput">
                <label id="formLabel"> Account Holder Name </label>
                <input className="formInput" id={cardHolderNameErrorClass} onChange={this.handleInput("account_holder_name")} type="string" placeholder="Account Holder Name" value={this.state.account_holder_name} />
                <ul className="errorUl">{cardHolderNameErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
                <label id="formLabel"> Account Number </label>
                <input className="formInput" id={cardNumberErrorClass} onChange={this.handleInput("account_number")} type="string" placeholder={this.props.currentUser.external_account_name ? "XXXXXXXXXXXX": "Account Number"} value={this.state.account_number} />
                <ul className="errorUl">{cardNumberErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
                <label id="formLabel"> Routing Number </label>
                <input className="formInput" id={cardNumberErrorClass} onChange={this.handleInput("routing_number")} type="string" placeholder={this.props.currentUser.external_account_name ? "XXXXXXXXX" : "Routing Number"} value={this.state.routing_number} />
                <ul className="errorUl">{cardNumberErrors}</ul>
              </div>
            </div>
            <div className="payoutButtons">
              {this.state.loading ? <div className="">
              <img className="logoImageSinnerGifPayout" src={LogoSpinner}></img>
            </div>: <button onClick={this.createExternalAccount} className="settingsButton paymentMethodButton">{this.props.currentUser.external_account_name ? "Save Payment Method" : "Save Payment Method"}</button>}
              {this.props.currentUser.external_account_name || this.state.enabled ? <button onClick={this.enablePayouts} className="settingsButton paymentMethodButton payout">{this.props.currentUser.account_link_url ? "Payouts Enabled" : "Enable Payouts"}</button> : ""}
            </div>
          </div>
        </div>
        {/* <div className="settingsPreferences settingsUsersList settingsPayouts">
          <div className="settingsComponentHeading">Payouts</div>
          <div className="settingsPreferencesGroup1">
            {settingsPayoutItems}
          </div>
        </div> */}
      </div>
    );
  }
}