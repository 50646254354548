import {connect} from "react-redux";
import SettingsUsers from "./settings_users";
import {withRouter} from "react-router-dom";
import {openModal} from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
});

const mdp = (dispatch) => ({
  openModal: (modal, props) => {
    dispatch(openModal(modal, props));
  },
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsUsers));