import React from "react";
import CategoryItem from "./category_item"
import { fetchCategories } from "../../util/virtual_staging_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";


export default class CategoriesSelectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: false,
      minimize: this.props.minimize,
    }
    this.toggleSelected = this.toggleSelected.bind(this);
  }

  componentDidMount() {
    if (!this.props.minimize){
      this.setState({ loading: true })
      fetchCategories(this.props.virtual_suite_type?.id).then(categories => this.setState({ categories: Object.values(categories).sort(function (a, b) { return a.sort_order - b.sort_order }), loading: false }))
    }
  }

  toggleSelected(category) {
    if (this.props.selected !== category) {
      this.props.changeParentState({ category: category })
    } else {
      this.props.changeParentState({ category: null })
    }
    this.props.changeParentState({ product: null })
  }

  render() {
    let categoryCards = this.state.categories.map((category, i) => <CategoryItem key={i} category={category} homepage={this.props.homepage} selected={this.props.selected?.id === category.id} toggleSelected={this.toggleSelected} index={i} />)
    if (categoryCards.length === 0) {
      categoryCards = <div style={{ fontWeight: "bold" }}>
        Sorry, virtual staging is not available at the moment. Please contact support for more information.
      </div>
    }

    if (this.state.loading) {
      categoryCards = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }

    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""

    if (this.state.minimize) {
      icon = <div key="2"><i className="fas fa-plus"></i></div>
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 1) {
        checkmark = "createProjectCheckmarkShow"
      }

    }

    return (
      <div className="projectLocationComponent">
        <div className="projectLocationHeadingGrouping" id={minimized}>
          <div className="projectLocationHeadingMinimizedGrouping">
            <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
            <div className="settingsComponentHeading chooseYourServiceText">Choose Category</div>
          </div>
          {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
        </div>
        <div id={minimizeId} className="row mt-3 mx-0 virtualStagingCategoryGrid">
          {categoryCards}
        </div>
      </div>
    );
  }
}