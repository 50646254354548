import styled from "styled-components";

export const ButtonWrapper = styled.div`
  text-align: center;
`;

export const ApplyButton = styled.button`
  min-width: 202px;
  height: 60px;
  background-color: #1B264F;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  padding: 22px 50px;
  
  &:hover {
    background: #0d1533;
  }
  
  @media only screen and (max-width: 769px) {
    width: 144px;
    padding: 15px 30px;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const LocationsIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const LocationsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  
  @media only screen and (max-width: 769px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const LocationIconText = styled.div`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 17px;
  line-height: 160%;
  color: #1A1D25;
  margin-left: 10px;
  
  @media only screen and (max-width: 769px) {
    font-size: 13px;
    margin-left: 5px;
  }
`