import React from "react"
import { withRouter } from "react-router-dom"
import Cloud from '../../../../../assets/images/cloud'
import Dropzone from "../../dropzone"
import trashIconSrc from '../../../../../assets/images/icons/fi_trash.svg'
import { DirectUploadProvider } from 'react-activestorage-provider'

class UploadGalleryPhotosModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      images: [],
      images_src: [],
      photoGroup: 0,
      disabled: false,
      uploading: false,
      modelLink: ""
      // streamAmount: this.props.isLargeScreen ? 9 : 8
    }

    this.handlePicturesSelected = this.handlePicturesSelected.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.submitPhotos = this.submitPhotos.bind(this)
  }


  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  deletePhoto(index) {
    // let images_src = this.state.images_src
    let images = this.state.images
    // images_src.splice(index, 1)
    images.splice(index, 1)

    // this.setState({ images: images, images_src: images_src })

    this.setState({ images: images })
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value })
  }

  submitPhotos(medias) {
    if (this.props.photographer?.gallery?.id){
      let formData = new FormData();
      formData.append("gallery[title]", "Best Photos");

      for (let i = 0; i < medias.length; i++) {
        formData.append("gallery[images][]", medias[i])
      }

      this.props.updateGallery(formData, this.props.photographer?.gallery?.id).then((data) => {
        this.props.reloadGalleryMedias()
        this.props.changeParentState({ modalOpen: false, upload_loading: false })
        this.props.showToast("Gallery has been updated")
      })
    } else {
    let formData = new FormData()

    formData.append("gallery[galleriable_id]", this.props.photographer?.id);
    formData.append("gallery[galleriable_type]", "User");

    for (let i = 0; i < medias.length; i++) {
      formData.append("gallery[images][]", medias[i])
    }

    this.props.createGallery(formData).then(() => {
      this.props.reloadGalleryMedias()
      this.props.changeParentState({ modalOpen: false, upload_loading: false })
      this.props.showToast("Gallery has been created")
    }
    ).catch(() => {
      this.props.changeParentState({ modalOpen: false, upload_loading: false })
    })

    }
  }

  handlePicturesSelected(event) {
    let images = Object.values(event?.target?.files || event)

    this.setState({ images: [...this.state.images, ...images] })
  }

  render() {
      let images = []
      if (!this.state.uploading) {
        images = this.state.images.map((image, i) => <div className="uploadImageDiv" key={i}>
          <div className="uploadFileName">{image.name}</div>
          <div className="cursor-pointer" onClick={() => this.deletePhoto(i + this.state.photoGroup)}>
            <img src={trashIconSrc} className="m-0 ms-2 d-block" />
          </div>
        </div>)
      }
      let cloudIcon = ""

      if (this.state.images.length === 0) {
        cloudIcon = <img className="uploadPhotoCloud mb-2" src={Cloud} />
      }

      if (this.state.images.length % 2 === 1 && !this.props.isLargeScreen) {
        images.push(<div key="a" className="uploadImageDivDummy" />)
      }

      return (
        <div className="modal-background" onClick={() => this.props.changeParentState({ modalOpen: false })}>
          <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
            <DirectUploadProvider
              multiple
              headers={{ "ContentDisposition": "inline" }}
              onSuccess={medias => this.submitPhotos(medias)}
              render={({ handleUpload, uploads, ready, uploadProgress = 0 }) => (
                <>
                  <div className="modalContainer modalContainerUpload">
                    <div className="uploadPhotoModalHeadingGroup">
                      <div className="uploadPhotoModalHeading"> Upload media</div>
                      <div className="closeIcon" onClick={() => this.props.changeParentState({ modalOpen: false })}><i
                        className="fas fa-times"></i></div>
                    </div>
                    <div className="importantInstructions">
                      <div>1. The progress bar can take some time to start loading for larger media uploads. </div>
                      <div>2. Wait for this modal to close on its own when the upload is completed.</div>
                    </div>
                    <div>
                      <div className="uploadPhotoGrouping">
                        <Dropzone handleDrop={this.handlePicturesSelected}>
                          {this.state.uploading ? "" :
                            <div className="uploadPhotoMainComponent">
                              {cloudIcon}
                              <div className="addPhotosButton">
                                <label className="projectEditButton projectEditButtonPrimary">
                                    <input onChange={this.handlePicturesSelected} type="file" multiple="multiple"
                                      accept="image/*, .NEF, .dng, .arw" />
                                  Add Media
                                </label>
                              </div>
                            </div>}

                          <div className="">
                            <div className="">
                              {images}

                              {uploads.map(upload => {
                                switch (upload.state) {
                                  case 'waiting':
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Waiting to upload {upload.file.name}</div>
                                    </div>
                                  case 'uploading':
                                    uploadProgress += Math.round(upload.progress)
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Uploading {upload.file.name}: {Math.round(upload.progress)}%
                                      </div>
                                    </div>
                                  case 'error':
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Error uploading {upload.file.name}: {upload.error}
                                      </div>
                                    </div>
                                  case 'finished':
                                    uploadProgress += 100
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Finished uploading {upload.file.name}
                                      </div>
                                    </div>
                                }
                              })}
                            </div>
                          </div>
                        </Dropzone>
                      </div>
                      <div className="uploadDiv">
                        <div className="uploadHeading">Upload Progress</div>
                        <div className="progress mb-3">
                          <div className="uploadProgress" style={{ width: `${uploadProgress / uploads.length}%` }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modalFooter justify-content-end">
                    <div className="d-flex">
                      {!this.state.uploading && <button className="projectEditButton projectEditButtonWhite"
                        onClick={() => this.props.changeParentState({ modalOpen: false })}>Cancel
                      </button>}
                      <button className="projectEditButton projectEditButtonPrimary ms-2"
                        disabled={this.state.disabled}
                        onClick={this.state.disabled ? () => {
                        } : () => {
                          this.setState({ uploading: true, disabled: true })
                          this.props.changeParentState({ upload_loading: true })
                          handleUpload(this.state.images)
                        }}>
                        Submit Media
                      </button>
                    </div>
                  </div>
                </>
              )} />
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    }
}

export default withRouter(UploadGalleryPhotosModal)