import React from "react";
import { deleteDiscountCode, fetchDiscountCodes } from "../../../util/discount_code_api_utils";


export default class DiscountCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discount_codes: [],
    }
  }

  componentDidMount() {
    fetchDiscountCodes().then(discount_codes => {
      this.setState({ discount_codes: discount_codes })
    })
  }

  onCreateDiscountCode() {
    this.props.openModal("discountCodeForm", { onSave: this.onCreatedDiscountCode.bind(this) })
  }

  onUpdateDiscountCode(discount_code) {
    this.props.openModal("discountCodeForm", { onSave: this.onUpdatedDiscountCode.bind(this), discount_code })
  }

  onDeleteDiscountCode(discount_code) {
    if (window.confirm(`Are you sure you want to delete "${discount_code.code}"?`)) {
      deleteDiscountCode(discount_code.id).then(() => {
        this.setState({ discount_codes: this.state.discount_codes.filter(f => f.id !== discount_code.id) })
        this.props.showToast("Coupon has been removed")
      }).catch(() => {
        alert('Could not delete the coupon')
      })
    }
  }

  onCreatedDiscountCode(discount_code) {
    this.setState({ discount_codes: [...this.state.discount_codes, discount_code] })
    this.props.showToast("Coupon has been created")
  }

  onUpdatedDiscountCode(updatedDiscountCode) {
    this.setState({ discount_codes: this.state.discount_codes.map(discount_code => discount_code.id === updatedDiscountCode.id ? updatedDiscountCode : discount_code) })
    this.props.showToast("Coupon has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Coupons</div>
          <div className="settingsPreferencesGroup1">
            {this.state.discount_codes.map(discount_code => (
              <div className="settingsServicesItem" key={discount_code.id}>
                <div className="itemTitle">{discount_code.code}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdateDiscountCode(discount_code)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDeleteDiscountCode(discount_code)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>
              </div>
            ))}
          </div>
          <button onClick={() => this.onCreateDiscountCode()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}