import React from "react";
import { Route, Link } from "react-router-dom";
import { fetchVirtualSuiteTypes } from "../../../util/virtual_staging_api_util";


export default class updateCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.category.name,
      price: this.props.category.price,
      reshoot: this.props.category.reshoot,
      sortOrder: this.props.category.sort_order,
      types: [],
      virtual_suite_type_id: this.props.category.virtual_suite_type_id,
      cover: "",
      cover_src: this.props.category.cover
    }
    this.handleInput = this.handleInput.bind(this)
    this.updateCategory = this.updateCategory.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
  }

  componentDidMount() {
    fetchVirtualSuiteTypes().then(virtual_suite_types => this.setState({ types: virtual_suite_types }))
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handleCheckbox(field) {
    return e => this.setState({ [field]: !this.state[field] })
  }

  handlePictureSelected(event) {
    let cover = event.target.files[0];
    let cover_src = URL.createObjectURL(cover);

    this.setState({
      cover: cover,
      cover_src: cover_src
    });
  }

  onChange(field) {
    return (e) => {
      e.preventDefault()
      this.setState({ [field]: e.target.value })
    }
  }


  updateCategory() {
    let formData = new FormData();

    formData.append("virtual_staging_category[name]", this.state.name)
    formData.append("virtual_staging_category[sort_order]", this.state.sortOrder)
    formData.append("virtual_staging_category[price]", this.state.price)
    formData.append("virtual_staging_category[reshoot]", this.state.reshoot)
    formData.append("virtual_staging_category[virtual_suite_type_id]", this.state.virtual_suite_type_id)
    if (this.state.cover != "") {
      formData.append("virtual_staging_category[cover]", this.state.cover)
    }

    this.props.updateCategory(formData, this.props.category.id).then(() => {
      if (this.props.errors.length === 0) {
        this.props.closeModal()
        this.props.showToast("Virtual staging category has been updated")
      }
    })
  }


  render() {
    let type_options = this.state.types.map((type) => <option key={type.id} value={type.id}>{type.name}</option>)
    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading"> Update Category</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>
          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel"> Category Name </label>
              <input className="formInput" onChange={this.handleInput("name")} type="string" placeholder="Name" value={this.state.name} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Category Price </label>
              <input className="formInput" onChange={this.handleInput("price")} type="string" placeholder="Price" value={this.state.price} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Sort Order </label>
              <input className="formInput" onChange={this.handleInput("sortOrder")} type="number" placeholder="Sort Order" value={this.state.sortOrder} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Type </label>
              <select className="formInput" onChange={this.onChange('virtual_suite_type_id')} name="virtual_suite_type" value={this.state.virtual_suite_type_id}>
                <option value="">No Type Selected</option>
                {type_options}
              </select>
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Cover </label>
              <div className="galleryPhotoDiv">
                <img className="settingsAvatar settingsGalleryCover" src={this.state.cover_src} />
                <label className="settingsChangePhotoButton coverUploadButton">
                  <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg" />
                  Add Photo
                </label>
              </div>
            </div>
          </div>
          <div className="settingsCheckboxGroupCategories">
            <label><input type="checkbox" className="formCheckBox" checked={this.state.reshoot} onChange={this.handleCheckbox("reshoot")} /> <span className="rememberMe">Revision</span></label>
          </div>
          <button onClick={this.updateCategory} className="settingsButton companyButton">Update Category</button>
        </div>
      </div>
    );
  }
}