import React from 'react'
import {useEffect, useState} from "react";

const Polygon = ({coords, map}) => {
  const [polygon, setPolygon] = useState(() =>
    new google.maps.Polygon({
      path: coords,
      strokeColor: "#1B264F",
      mapTypeId: "terrain",
      strokeOpacity: 1,
      strokeWeight: 3,
      map: map,
      fillColor: "#1B264F",
      fillOpacity: 0.35,
    })
  )

  useEffect(() => {
    polygon.setPath(coords)
  }, [coords])

  useEffect(() => {
    return () => {
      polygon.setMap(null)
      setPolygon(null)
    }
  }, [])

  return <></>
}

export default Polygon