import { connect } from "react-redux";
import SettingsService from "./settings_service";
import React from "react";
import { withRouter } from "react-router-dom";
import { fetchServicesSettings, deleteService } from "../../../actions/service_actions";
import { openModal, closeModal, addInfo } from "../../../actions/modal_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  services: Object.values(state.entities.services)
});

const mdp = (dispatch) => ({
  fetchServicesSettings: () => dispatch(fetchServicesSettings()),
  openModal: (modal) => { dispatch(openModal(modal)); },
  deleteService: (id) => {dispatch(deleteService(id))},
  addInfo: (info) => dispatch(addInfo(info))
});

export default withRouter(connect(msp, mdp)(SettingsService));