export const fetchAllSubBrokers = () => (
  $.ajax({
    type: 'GET',
    url: '/sub_brokers'
  })
);

export const createSubBroker = (subBroker) => (
  $.ajax({
    type: "POST",
    url: "/sub_brokers",
    data: { user: subBroker }
  })
);

export const updateSubBroker = (id, data) => (
  $.ajax({
    type: "PATCH",
    url: `/sub_brokers/${id}`,
    data,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const activateSubBroker = (subBroker) => (
  $.ajax({
    type: "PATCH",
    url: `/sub_brokers/${subBroker.id}/activate`
  })
);

export const deactivateSubBroker = (subBroker) => (
  $.ajax({
    type: "PATCH",
    url: `/sub_brokers/${subBroker.id}/deactivate`
  })
);
