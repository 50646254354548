import React from "react";
import { ReactComponent as SearchIcon } from "../../../../assets/images/icons/search.svg";
import { ReactComponent as CheckMark } from "../../../../assets/images/icons/blue-checkmark-icon.svg";



export default class PhotoItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.onClickZoom = this.onClickZoom.bind(this)
  }

  onClickZoom = (e) => {
    e.stopPropagation()
    if (this.props.homepage){
      this.props.changeGrandParentState({galleryModalOpen: true, currentPhoto: this.props.index})
    } else {
    this.props.changeParentState({ galleryModalOpen: true, currentPhoto: this.props.index })
    }
  }

  render() {
    let photo = this.props.photo;
    let id = this.props.selected ? "virtualStagingPhotoSelected" : "";
    let icon = this.props.selected ? <div className="checkIcon"><CheckMark /></div> : <div className="zoomIcon" onClick={this.onClickZoom}><SearchIcon /></div>
    let src = photo.url

    let virtualStagingClass = "virtualStagingPhotoItem"

    if (this.props.homepage){
      virtualStagingClass = "virtualStagingPhotoItem virtualStagingPhotoItemHomepage"
    }

    const isPDF = src.toLowerCase().endsWith(".pdf");

    return (
      <div onClick={() => this.props.toggleSelected(photo)} className={virtualStagingClass}>
        {isPDF ? (
          <object data={src} type="application/pdf" width="100%" height="200px">
            {/* Render a message or fallback content for non-PDF files */}
            This browser does not support viewing PDFs. <a href={src}>Download PDF</a> instead.
          </object>
        ) : (
          <div>
            <img className="virtualStagingPhotoImage virtualStagingPhotoUploadImage" id={id} src={src} alt="Preview" />
            <div className="uploadFileNameVirtualStaging">{photo.filename}</div>
          </div>
        )}
        {icon}
      </div>
    )
  }
}