import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
  SectionContainerColor,
  SectionContainer,
  SectionWrapper,
  FirstColumn,
  SecondColumn,
  Heading,
  Content,
  ImageMarker,
  Button,
} from './styles';

import { ImageSliderMarkerLight } from '../../../../../assets/images/aboutPage';

import { ROUTES } from '../../../routes';


const SectionWithContactUsLink = () => {
  return (
    <SectionContainerColor>
      <SectionContainer>
        <SectionWrapper>
          <FirstColumn>
            <Heading>
              Don’t see your question?
            </Heading>
            <Content>
              We enjoy turning strangers into loyal customers. Contact us to book one of our talented photographers or just to get some more info.
            </Content>
            <Link to={ROUTES.CONTACTS}>
              <Button>Contact Us</Button>
            </Link>
          </FirstColumn>
          <SecondColumn>
            <ImageMarker
              id={'Tooltip-1'}
              src={ImageSliderMarkerLight}
              alt={''}
            />
          </SecondColumn>
        </SectionWrapper>
      </SectionContainer>
    </SectionContainerColor>
  );
};

export default SectionWithContactUsLink;
