import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FAQWrapper } from './FAQStyles';
import { FAQHeader, AccordionSection } from '../../faq_page_components';
import { SectionWithContactUsLink } from '../../pageComponents';

const ACCORDION_DATA = [
  {
    id: 1,
    title: 'Is Modern Angles a photography company?',
    body: 'I don’t want to overload the client viewer with too many photos. I don’t think they are spending more than 60 seconds on a page so we probably don’t need 50 photos for each service. Just a few of the best to represent the quality we can achieve for their listing. We can have a separate “portfolio” page that has more examples broken down by service.',
  },
  {
    id: 2,
    title: 'Are there any upfront costs?',
    body: 'Content',
  },
  {
    id: 3,
    title: 'What types of properties are eligible?',
    body: 'Content',
  },
  {
    id: 4,
    title: 'Do I own the rights to  the  media that you guys make?',
    body: 'Content',
  },
  {
    id: 5,
    title: 'Do I have to live in the subject property?',
    body: 'Content',
  },
  {
    id: 6,
    title: 'How is the value of my home determined?',
    body: 'Content',
  },
  {
    id: 7,
    title: 'How is the value of my home determined?',
    body: 'Content',
  },
  {
    id: 8,
    title: 'How is the value of my home determined?',
    body: 'Content',
  },
];

const FAQPage = (props) => {
  const { fetchFAQs, faqs, loggedIn } = props;

  useEffect(() => {
    fetchFAQs();
  }, []);

  return (
    <FAQWrapper>
      <Helmet>
        <title>FAQ | Modern Angles</title>
        <meta
          name="description"
          content="Modern Angles is a full service creative partner with media solutions for every project. Browse our FAQ to learn about working with our team."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <FAQHeader />
      <AccordionSection data={faqs || []} />
      {loggedIn ? "" : <SectionWithContactUsLink />}
    </FAQWrapper>
  );
};

export default withRouter(FAQPage);
