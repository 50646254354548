import { connect } from "react-redux";
import PhotographersIndex from "./photographers_index";
import React from "react";
import { openModal, closeModal } from "../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { fetchContractors} from "../actions/service_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
  photographers: Object.values(state.entities.contractors)
});

const mdp = (dispatch) => ({
  closeModal: () => { dispatch(closeModal()) },
  fetchContractors: (service_id, zipcode, search, photographer_index) => dispatch(fetchContractors(service_id, zipcode, search, null, null, null, null, null, photographer_index))
});

export default withRouter(connect(msp, mdp)(PhotographersIndex));