import React from "react"
import Autocomplete from "react-google-autocomplete";
import { fullAddress } from "../../util/project_utils";

class EditProjectAddressModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address: {...props.project.address},
      input_text: "",
      submitting: false,
      address_id: props.project.address.id
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.editPlace = this.editPlace.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  onChange(field) {
    return (e) => {
      e.preventDefault()
      if (field === "input_text") {
        if (this.state.address.city) {
          this.setState({ address: {street1:"", city: "", region: "", zipcode: "", latitude: "", longitude: "", formatted_address: "" }})
          this.setState({ [field]: "" })
        } else {
          this.setState({ [field]: e.currentTarget.value })
        }
      } else {
        const change = {apartment_number: e.currentTarget.value}
        this.setState({ address: { ...this.state.address, ...change } })
      }
    }
  }

  editPlace(place) {
    let street_number = "";
    let address = "";
    let city3 = "";
    let city = "";
    let city2 = "";
    let city4 = "";
    let state = "";
    let zip_code = "";
    let latitude = place.geometry.location.lat();
    let longitude = place.geometry.location.lng();
    let formatted_address = place.formatted_address.split(",").slice(0, place.formatted_address.split(",").length - 1).join(",")

    place.address_components.forEach(component => {
      if (component.types[0] === "street_number") {
        street_number = component.long_name
      } else if (component.types[0] === "route") {
        address = component.long_name
      } else if (component.types[0] === "neighborhood") {
        city3 = component.long_name
      } else if (component.types[0] === "locality") {
        city = component.long_name
      } else if (component.types[0] === "administrative_area_level_3") {
        city2 = component.long_name
      } else if (component.types[0] === "sublocality_level_1") {
        city4 = component.long_name
      } else if (component.types[0] === "administrative_area_level_1") {
        state = component.short_name
      } else if (component.types[0] === "postal_code") {
        zip_code = component.long_name
      }
    })

    if (street_number === "") {
      street_number = this.state.input_text.split(" ")[0]
    }

    this.setState({ address: { id: this.state.address_id, formatted_address: formatted_address, street1: `${street_number} ${address}`, apartment: "", city: city4 || city3 || city || city2, region: state, zip_code: zip_code, latitude: latitude, longitude: longitude }})
  }

  onSubmit(e) {
    e.preventDefault()
    const {onSubmit} = this.props
    this.setState({submitting: true})
    onSubmit({address: this.state.address})
  }

  render() {
    const {onClose} = this.props
    const {address, submitting} = this.state

    return <div className="modal-background" onClick={() => onClose()}>
      <div className="orderpreviewModal editProjectAddress" onClick={e => e.stopPropagation()}>
        <div>
          <div className="orderPreviewModalComponent">
            <div className="modalTitle">Property Location</div>
            <div className="modalSubTitle">Confirm the address for your additional services.</div>

            <div className="row">
              <div className="col-12 col-md-10">
                <div className="inputGroup">
                  <label id="formLabel">Address</label>
                  <Autocomplete className="formInput"
                    onPlaceSelected={(place) => {
                      this.editPlace(place)
                    }}
                    id=""
                    options={{ types: "address" }}
                    value={this.state.address.formatted_address ? this.state.address.formatted_address : this.state.address.city ? fullAddress(this.state.address) : this.state.input_text}
                    onChange={this.onChange("input_text")}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="inputGroup">
                  <label id="formLabel">Apartment</label>
                  <input className="formInput" placeholder=""
                         onChange={this.onChange("apartment_number")}
                         value={address.apartment_number}/>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12 col-md-5">
                <div className="inputGroup">
                  <label id="formLabel">City</label>
                  <input className="formInput" placeholder="City"
                         onChange={this.onChange("city")}
                         value={address.city}/>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="inputGroup">
                  <label id="formLabel">State</label>
                  <input className="formInput" placeholder="State"
                         onChange={this.onChange("region")}
                         value={address.region}/>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="inputGroup">
                  <label id="formLabel">Zip</label>
                  <input className="formInput" placeholder="00000"
                         onChange={this.onChange("zip_code")}
                         value={address.zip_code}/>
                </div>
              </div>
            </div> */}
          </div>
          <div className="modalFooter d-flex justify-content-end">
            <div className="d-flex justify-content-between">
              <button className="projectEditButton projectEditButtonWhite" onClick={onClose} disabled={submitting}>
                Cancel
              </button>

              <button className="projectEditButton projectEditButtonPrimary ms-2" onClick={this.onSubmit}
                      disabled={submitting}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="modalMobilePushup"></div>
      </div>
    </div>
  }
}

export default EditProjectAddressModal