import React from 'react'
import {formatDatetime} from "../../../util/date_util";
import authorProfileImg from "../../../../../assets/images/MA_Logo_New_2.svg";
import defaultPostImg from "../../../../../assets/images/dashboardImage1.jpg";

export const BlogPost = ({blogPost}) => {
  return <div className="blog-post-card">
    <div className="blog-post-card-image">
      <a href={`/blog/${blogPost.slug}`}>
        <img src={blogPost.header_image || defaultPostImg} />
      </a>
    </div>

    <div className="blog-post-card-category-and-read-time">
      <div className="blog-post-card-category">{blogPost.category}</div>
      <div className="blog-post-card-read-time">{blogPost.time_to_read} minutes</div>
    </div>

    <a className="blog-post-card-title" href={`/blog/${blogPost.slug}`}>{blogPost.title}</a>
    <div className="blog-post-card-description">{blogPost.description}</div>
    <div className="blog-post-card-author">
      <img src={authorProfileImg} className="blog-post-card-author-img" />
      <div className="blog-post-card-author-name-and-date">
        <div className="blog-post-card-author-name">Modern Angles</div>
        <div className="blog-post-card-author-date">{formatDatetime(blogPost.created_at, "MMMM D, YYYY")}</div>
      </div>
    </div>
  </div>
}