import React from "react";
import TimeZoneSelect from "./time_zone_select";
import {setDefaultTimezone} from "../../../util/timezone_utils";

export default class SettingsProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      original_email: this.props.currentUser.email,
      email: this.props.currentUser.email,
      firstName: this.props.currentUser.firstName,
      lastName: this.props.currentUser.lastName,
      phoneNumber: this.props.currentUser.phoneNumber,
      password: "",
      newPassword: "",
      bio: this.props.currentUser.bio || "",
      newPasswordConfirm: "",
      avatar: "",
      src: this.props.currentUser.avatar,
      timezone: this.props.currentUser.timezone
    }
    this.handleInput = this.handleInput.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.updateProfile = this.updateProfile.bind(this)
    this.checkForErrors = this.checkForErrors.bind(this)
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.src)
  }

  validateEmail(email) {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handlePictureSelected(event) {
    let avatar = event.target.files[0];
    let src = URL.createObjectURL(avatar);

    this.setState({
      avatar: avatar,
      src: src
    });
  }

  updatePassword(){
    let formData = new FormData();
    formData.append("user[password]", this.state.newPassword);
    formData.append("user[confirm_password]", this.state.newPasswordConfirm);
    formData.append("user[current_password]", this.state.password);

    this.props.updateProfile(formData, this.props.currentUser.id).then(() => {
      if (this.props.errors.length === 0) {
        this.props.removeCurrentUser()
        this.props.history.push("/login")
        this.props.showToast("Password successfully updated")
      }
    })
  }

  checkForErrors(){
    this.setState({ errors: [] })

    let errors = []

    if (this.state.firstName === "") {
      errors.push([1, "First name can't be blank."])
    }

    if (this.state.lastName === "") {
      errors.push([2, "Last name can't be blank"])
    }

    if (this.state.email === "") {
      errors.push([3, "Email can't be blank"])
    }

    if (!this.validateEmail(this.state.email)) {
      errors.push([3, "Invalid Email"])
    }

    const phoneNumberPattern = /^\d{10}$/;
    if (!phoneNumberPattern.test(this.state.phoneNumber)) {
      errors.push([11, 'Please enter a valid 10-digit phone number.']);
    } 

    if (errors.length > 0) {
      this.setState({ errors: errors })
    } else {
      this.updateProfile()
    }
  }

  updateProfile() {
    let formData = new FormData();
    formData.append("user[first_name]", this.state.firstName);
    formData.append("user[last_name]", this.state.lastName);
    formData.append("user[email]", this.state.email);
    formData.append("user[bio]", this.state.bio);
    formData.append("user[phone_number]", this.state.phoneNumber);
    formData.append("user[timezone]", this.state.timezone);

    if (this.state.avatar != ""){
      formData.append("user[avatar]", this.state.avatar);
    }
    this.props.updateProfile(formData, this.props.currentUser.id).then(() => {
      if (this.props.errors.length === 0) {
        this.props.showToast("Account successfully updated")

        if (this.props.currentUser.email != this.state.email){
          this.props.logout()
        } else {
          setDefaultTimezone(this.state.timezone)
        }
      }
    })
  }



  render() {
    let passwordErrorClass = "";
    let newPasswordErrorClass = "";
    let newPasswordConfirmErrorClass=""
    let emailErrorClass = "";
    let firstNameErrorClass = "";
    let lastNameErrorClass = "";
    let phoneNumberErrorClass = "";
    let zipcodeErrorClass = "";
    let passwordErrors = [];
    let firstNameErrors = [];
    let lastNameErrors = [];
    let newPasswordErrors = [];
    let newPasswordConfirmErrors = [];
    let phoneNumberErrors = [];
    let emailErrors = [];
    let otherErrors = [];
    let zipcodeErrors = [];

    this.state.errors.forEach((error) => {
      if (error[0] === 1) {
        firstNameErrors.push(<li>{error[1]}</li>)
        firstNameErrorClass = "formInputError"
      }
      else if (error[0] === 2) {
        lastNameErrors.push(<li>{error[1]}</li>)
        lastNameErrorClass = "formInputError"
      }
      else if (error[0] === 3) {
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }
      else if (error[0] === 6) {
        otherErrors.push(<li>{error[1]}</li>)
      }
      else if (error[0] === 11) {
        phoneNumberErrors.push(<li>{error[1]}</li>)
        phoneNumberErrorClass = "formInputError"
      }
    })

    // let contractorZipcode = ""

    // if (this.props.currentUser.roles.contractor){
    //   contractorZipcode = 
    //   <div className="inputGrouping">
    //     <div className="signupLeftInput">
    //       <label id="formLabel"> Primary Zipcode</label>
    //       <input className="formInput" id={zipcodeErrorClass} onChange={this.handleInput("primary_zip_code")} type="string" placeholder="Primary Zipcode" value={this.state.primary_zip_code} />
    //       <ul className="errorUl">{zipcodeErrors}</ul>
    //     </div>
    //     <div className="signupRightInput">
    //       <label id="formLabel"> Servicable Radius</label>
    //       <div className="updateUserDropdownAndButton">
    //         <input type="range" min="1" max="100" className="formInput" id={firstNameErrorClass} onChange={this.handleInput("servicable_radius")} value={this.state.servicable_radius} />
    //       </div>
    //       <div>Radius: {this.state.servicable_radius}</div>
    //     </div>
    //   </div>
    // }
    return (
      <div className="settingsComponentGroupingDiv">
          <div className="settingsProfile">
            <div className="settingsComponentHeading">Profile settings</div>
            <div className="settingsCategoryGroup1">
              <div className="inputGrouping">
                <div className="signupLeftInput">
                  <label id="formLabel"> First name </label>
                  <input className="formInput" id={firstNameErrorClass} onChange={this.handleInput("firstName")}  type="string" placeholder="First name" value={this.state.firstName} />
                  <ul className="errorUl">{firstNameErrors}</ul>
                </div>
                <div className="signupRightInput">
                  <label id="formLabel"> Last name </label>
                  <input className="formInput" id={lastNameErrorClass} onChange={this.handleInput("lastName")} type="string" placeholder="Last name" value={this.state.lastName} />
                  <ul className="errorUl">{lastNameErrors}</ul>
                </div>
              </div>
              <div className="inputGrouping">
                <div className="signupLeftInput">
                  <label id="formLabel"> Email</label>
                  <input className="formInput" id={emailErrorClass} onChange={this.handleInput("email")} type="email" placeholder="Email address" value={this.state.email} />
                  <ul className="errorUl">{emailErrors}</ul>
                </div>
                <div className="signupRightInput">
                  <label id="formLabel"> Phone number </label>
                  <input className="formInput" id={phoneNumberErrorClass} onChange={this.handleInput("phoneNumber")} type="tel" placeholder="Phone number" value={this.state.phoneNumber} />
                  <ul className="errorUl">{phoneNumberErrors}</ul>
                </div>
             </div>

              <div className="inputGrouping">
                <div className="w-100">
                  <label id="formLabel"> Time zone </label>
                  <TimeZoneSelect value={this.state.timezone} onChange={this.handleInput("timezone")} />
                </div>
              </div>

              <div className="bioGroup">
                <label id="formLabel">Bio</label>
                <textarea className="formTextArea bioTextArea" onChange={this.handleInput("bio")} type="string" placeholder="Short description about yourself" value={this.state.bio} />
              </div>
            </div>
            <div className="settingsCategoryGroup2">
              <div className="settingsComponentHeading">Change photo</div>
              <div className="settingsComponentSubHeading">Allow others to see who they are working with. This enables a profile picture in the directory, as well as showing a small icon within workflow for other users to easily identify you.</div>
              <div className="settingsPhotoDiv">
                <img className="settingsAvatar" src={this.state.src} />
                <label className="settingsChangePhotoButton">
                <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg" />
                  Change Photo
                </label>
              </div>
            <button onClick={this.checkForErrors} className="settingsButton">Save changes</button>
            </div>
          </div>
          <div>
            <div className="settingsPasswordResetDiv">
              <div className="settingsComponentHeading">Account Actions</div>
              <button onClick={() => this.props.logout()} className="settingsButton logoutSettingsButton">Log Out</button>
            </div>
            <div className="settingsPasswordResetDiv">
              <div className="settingsComponentHeading">Change your password</div>
              <div className="settingsComponentSubHeading">Must have at least 6 characters.</div>
              <div className="settingsPasswordInput">
                <label id="formLabel">Password</label>
                <input className="formInput" id={passwordErrorClass} onChange={this.handleInput("password")} type="password" placeholder="Password" value={this.state.password} />
                <ul className="errorUl">{passwordErrors}</ul>
              </div>
              <div className="">
                <label id="formLabel">New password</label>
                <input className="formInput" id={newPasswordErrorClass} onChange={this.handleInput("newPassword")} type="password" placeholder="New password" value={this.state.newPassword} />
                <ul className="errorUl">{newPasswordErrors}</ul>
              </div>
              <div className="">
                <label id="formLabel">Confirm password</label>
              <input className="formInput" id={newPasswordConfirmErrorClass} onChange={this.handleInput("newPasswordConfirm")} type="password" placeholder="Confirm password" value={this.state.newPasswordConfirm} />
                <ul className="errorUl">{newPasswordConfirmErrors}</ul>
              </div>
              <button onClick={this.updatePassword} className="settingsButton changePasswordSettingsButton">Change password</button>
            </div>

          </div>
        </div>
      );
    }
}