import React from "react";
import debounce from 'debounce'
import {Portal} from "../portal";

class AddMoreServicesHomepageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: []
    }
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
    this.addMoreServices = this.addMoreServices.bind(this)
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  addMoreServices() {
    this.props.changeParentState({
      page: 2,
      errors: [],
      selected_service: -1,
      currentlySelectedService: null,
      addMoreModal: null
    })
  }

  render() {
    return (<Portal>
      <div className="modal-background"
           onClick={() => this.props.changeParentState({page: 2})}>
        <div className="orderpreviewModal addMoreServicesModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            {/* <div className="settingsComponentHeading orderPreviewModalHeading"> Continue</div> */}
            {/* <div className="continueMessage">Please feel free to add more services or continue to photographer
              selection.
            </div> */}
            <div className="modalButtonsAddService">
              <div className="signupFormPrevious modalButtonSpecial" onClick={(e) => {this.props.handleNext(e);}}>Continue to Checkout</div>
              <div className="signupFormNext modalButtonSpecial" onClick={this.addMoreServices}>Add More Services</div>
            </div>
          </div>
        </div>
      </div>
    </Portal>)
  }
}

export default AddMoreServicesHomepageModal;