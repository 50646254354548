import React from "react";
import checkImg from "../../../../../assets/images/bookPage/check.svg";

export const FeaturesList = () => {
  const items = [
    '24 Hour Turnaround',
    'Blue Sky Always Included',
    'Clear Windows Guaranteed'
  ]

  return <div className="features-list">
    {items.map((item, i) => (
      <div className="features-list-item" key={`header-item-${i}`}>
        <img src={checkImg}/>
        {item}
      </div>))}
  </div>
}