import React from 'react';
import { Link } from 'react-router-dom';

import {
  AboutHeaderWrapper,
  AboutHeaderInnerWrapper,
  HeaderTitle,
  HeaderDescription,
  GetStartedButton,
} from './styles';


const AboutHeader = (props) => {
  return (
    <AboutHeaderWrapper>
      <AboutHeaderInnerWrapper>
        <HeaderTitle>About Modern Angles</HeaderTitle>
        <HeaderDescription>
          Modern Angles is on a mission to set a higher standard for real estate marketing services - from photography and video production to floorplans, aerial capture, virtual staging, 3D renderings, and website development.
        </HeaderDescription>
        <div
          onClick={() => props.setModal(true)}>
          <GetStartedButton>Order Now</GetStartedButton>
        </div>
      </AboutHeaderInnerWrapper>
    </AboutHeaderWrapper>
  );
};

export default AboutHeader;
