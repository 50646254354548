import React from "react";
import {updateUserPassword} from "../../../util/settings_api_util";

export default class UpdateUserPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      errors: [],
      changed: false
    }
    this.onReset = this.onReset.bind(this)
  }

  onChange(field) {
    return (e) => {
      e.preventDefault();
      this.setState({[field]: e.target.value})
    }
  }

  onReset() {
    const {user, onChange} = this.props

    updateUserPassword(user.id, this.state.password, this.state.passwordConfirmation).then(user => {
      onChange(user)
      this.setState({changed: true, errors: []})
    }).catch(response => {
      this.setState({errors: response.responseJSON})
    })
  }

  render() {
    return <div className="mt-5">
      <div className="settingsComponentHeading">
        Reset password
      </div>
      <div className="inputGrouping">
        <div className="signupLeftInput">
          <label id="formLabel">
            New password
          </label>
          <input className="formInput" onChange={this.onChange('password')} type="password"
                 value={this.state.password} autocomplete="off"/>
        </div>
        <div className="signupRightInput">
          <label id="formLabel">
            Password confirmation
          </label>
          <input className="formInput" onChange={this.onChange('passwordConfirmation')} type="password"
                 value={this.state.passwordConfirmation} autocomplete="off"/>
        </div>
      </div>

      {this.state.errors.length > 0 && (
        <div className="alert alert-danger mt-2">
          {this.state.errors.map(error => <div className="mt-1">{error}</div>)}
        </div>
      )}

      <div className="d-flex mt-4 align-items-center">
        <button className="settingsButton m-0" onClick={this.onReset}
                disabled={!this.state.password || this.state.password !== this.state.passwordConfirmation}>
          Reset
        </button>
        {this.state.changed && <span className="text-success ms-3">Password has been reset</span>}
      </div>
    </div>
  }
}
