import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import LoginBackgroundDesign from "../../../assets/images/background_design_login";
import Logo from "../../../assets/images/logo"
import ForgotPasswordFormContainer from "./forms/forgot_password_form_container";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div className="loginContainer">
        <img className="loginBackgroundDesign" src={LoginBackgroundDesign} />
        <div className="modernAnglesLoginAndLogo">
          <img className="modernAnglesLoginLogo" src={Logo} onClick={() => this.props.history.push("/")}/>
          <ForgotPasswordFormContainer />
        </div>
      </div>
    )
  }
}

export default withRouter(ForgotPassword);