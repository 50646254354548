import React from "react";
import {CareersThirdSectionWrapper, DescriptionText, Heading, Image, Images} from "./styles";
import {THIRD_SECTION} from "../../../../../assets/images/careersPage";
import { ROUTES } from '../../../routes';
import { Link, withRouter } from 'react-router-dom';

const CareersThirdSection = (props) => {
  return (
    <CareersThirdSectionWrapper>
      <Heading>
        How it Works
      </Heading>
      <DescriptionText>
        Modern Angles has advanced systems in place that make your job efficient and get you paid on time.
      </DescriptionText>

      <div>
        <Images>
            <Image>
            <img className="cursor-pointer" onClick={() => props.history.push(`${ROUTES.CAREERS_APPLY}`)} src={THIRD_SECTION.ThirdSectionImage1} />
            </Image>
          <Image>
            <img src={THIRD_SECTION.ThirdSectionImage2} />
          </Image>
        </Images>
        <Images>
          <Image>
            <img src={THIRD_SECTION.ThirdSectionImage3} />
          </Image>
          <Image>
            <img src={THIRD_SECTION.ThirdSectionImage4} />
          </Image>
        </Images>
        <Images>
          <Image>
            <img src={THIRD_SECTION.ThirdSectionImage5} />
          </Image>
        </Images>
      </div>
    </CareersThirdSectionWrapper>
  );
};

export default withRouter(CareersThirdSection);