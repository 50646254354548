import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AboutUsPage from './AboutUsPage';

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({});

export default withRouter(connect(msp, mdp)(AboutUsPage));
