import React from "react"
import {ReactComponent as DownloadIcon} from '../../../../assets/images/icons/download.svg'
import {downloadInvoice} from "../../util/invoices_api_utils"
import {NavLink, withRouter} from "react-router-dom"

class InvoiceItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      downloading: false,
      invoiceBlobUrl: null
    }
    this.downloadLink = React.createRef()
    this.downloadInvoice = this.downloadInvoice.bind(this)
  }

  componentWillUnmount() {
    if (this.state.invoiceBlobUrl) {
      URL.revokeObjectURL(this.state.invoiceBlobUrl)
    }
  }

  downloadInvoice(e) {
    if (this.state.invoiceBlobUrl) return

    e.preventDefault()

    this.setState({downloading: true})

    downloadInvoice(this.props.invoice.id).then(blob => {
      const url = URL.createObjectURL(blob)
      this.setState({invoiceBlobUrl: url})
      setTimeout(() => this.downloadLink.current.click())
    }).always(() => {
      this.setState({downloading: false})
    })
  }

  render() {
    const {invoice, currentUser} = this.props
    return <tr>
      {currentUser.roles.admin ? <td>{invoice.client_full_name}</td> : ""}
      {invoice.project_id ? <td><NavLink className="lookLikeLink" to={`/projects_index/${invoice.project_id}`}>{invoice.fullAddress}</NavLink></td> : <td className="text-muted">{invoice.fullAddress}</td> }
      <td className="text-muted">${invoice.total}</td>
      {invoice.sent ? <td className="text-muted">Sent</td> : 
      <td className="text-muted">{invoice.paid ? 'Paid' : 'Not paid'}</td>
      }
      <td className="text-muted">{new Date(invoice.created_at).toLocaleDateString()}</td>
      <td>
        <a href={this.state.invoiceBlobUrl || '#'} onClick={this.downloadInvoice}
           className={this.state.downloading ? 'disabled' : ''}
           ref={this.downloadLink}
           download={`invoice-${invoice.created_at}.pdf`} target="_blank">

          {this.state.downloading ?
            <div className="spinner-border spinner-border-sm text-muted" /> :
            <DownloadIcon className="downloadInvoiceIcon"/>
          }
        </a>
      </td>
    </tr>
  }
}

export default withRouter(InvoiceItem)