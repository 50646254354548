import React from 'react'
import completedImg from "../../../../../../assets/images/photographerOnboarding/completed.svg"
import { LINKS } from "../../../../../../assets/images/contactForm";

export const CompletedCallback = () => {
  const onClick = (e) => {
    e.preventDefault()
    location.href = LINKS.INSTAGRAM
  }

  return (
    <div>
    <div className="initial-info-container title-container">
      <h1>Transform Your Listing with Real Estate Photography</h1>
    </div>
    <div className="content-wrapper">
    <div className="initial-info-container content-container">
    <div className="apply-for-discount-center-image-wrapper">
      <img src={completedImg} />
    </div>

    <h1 className="apply-for-discount-completed-title">
      We'll get back to you!
    </h1>

    <div className="apply-for-discount-completed-description">
      Thank you for providing this information. One of our photography specialists will contact you shortly.
    </div>

    <div className="lead-page-divider" />

      <div className="mar-bottom-30"><button className="projectEditButton projectEditButtonPrimary projectEditButtonLarger w-100" onClick={onClick}>Explore Our Instagram</button></div>
    </div>
   </div>
  </div>)
}