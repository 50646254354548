import {connect} from "react-redux"
import EditProjectMain from "./edit_project_main"
import React from "react"
import {withRouter} from "react-router-dom"
import { updatePropertyTour } from "../../actions/project_actions";
import { fetchContractors } from "../../actions/service_actions";
import { fetchCurrentUser } from "../../actions/session_actions";
import { showToast } from "../../actions/toast_actions";

const msp = (state) => (
  {
    currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
    contractors: state.entities.contractors,
    services: state.entities.services
  }
)

const mdp = (dispatch) => ({
  updatePropertyTour: (property_tour_info) => dispatch(updatePropertyTour(property_tour_info)),
  fetchContractors: (service, zip, search, date, time, services, appointments, timezone) => dispatch(fetchContractors(service, zip, search, date, time, services, appointments, timezone)),
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id)),
  showToast: (message) => dispatch(showToast(message))
})

export default withRouter(connect(msp, mdp)(EditProjectMain))