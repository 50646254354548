import React from "react"
import {withRouter} from "react-router-dom"
import {SortableContainer, SortableElement} from 'react-sortable-hoc'


const SortableItem = SortableElement(({value}) => {
  return <div className="editProjectPhoto">
    <img className="galleryPhotoPropertyTourSortable" src={value.url}/>
  </div>
})

const SortableList = SortableContainer(({items, hidden_medias}) => {
  return (<div className="editProjectPhotos propertyTourPhotos">
    {items.filter(item => !hidden_medias[item.id]).map((value, index) => (
      <SortableItem key={`item-${value.id}`} index={index} value={value}/>
    ))}
  </div>)
})

class SortableComponent extends React.Component {
  render() {
    return <SortableList items={this.props.data} onSortEnd={this.props.onSortEnd} axis="xy"
                         hidden_medias={this.props.hidden_medias}/>
  }
}

class PropertyTourEditPhotos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navActive: 0,
      sorting: false
    }

    this.updateNavActive = this.updateNavActive.bind(this)
    this.handler = this.handler.bind(this)
    this.hiddenMedia = this.hiddenMedia.bind(this)
    this.reorderPhotos = this.reorderPhotos.bind(this)
  }

  handler(newState) {
    this.setState(newState)
  }

  updateNavActive(num) {
    this.props.fetchMedias(this.props.project.services[num].id).then(() => {
      let sorted_medias = Object.assign({}, this.props.sorted_medias)
      let hidden_medias = Object.assign({}, this.props.hidden_medias)

      if (!sorted_medias[num]) {
        sorted_medias[num] = this.props.project.sorted_medias
      }

      if (!hidden_medias[num]){
        hidden_medias[num] = this.props.project.hidden_medias
      }
      this.setState({navActive: num})
      this.props.changeParentState({sorted_medias: sorted_medias, hidden_medias: hidden_medias})
    })
  }

  hiddenMedia(media) {
    let medias = Object.assign({}, this.props.hidden_medias) 
    let changed_medias = this.props.changed_medias

    if (changed_medias[media.id]) {
      delete changed_medias[media.id]
    } else {
      changed_medias[media.id] = media.id
    }

    if (medias[this.state.navActive][media.id]) {
      delete medias[this.state.navActive][media.id]
    } else {
      medias[this.state.navActive][media.id] = media
    }

    this.props.changeParentState({hidden_media: medias, changed_medias: changed_medias})
  }

  reorderPhotos({oldIndex, newIndex}) {
    const photos = Object.assign({}, this.props.sorted_medias)
    const [reorderedItem] = photos[this.state.navActive].splice(oldIndex, 1)
    photos[this.state.navActive].splice(newIndex, 0, reorderedItem)
    this.props.changeParentState({sorted_medias: photos})
  }

  render() {
    const {project} = this.props
    if (!project) return <div/>

    const visibleMedia = (this.props.sorted_medias[this.state.navActive] || []).filter(media => {
      return !this.props.hidden_medias[this.state.navActive][media.id]
    })

    let serviceGallery

    if (this.state.sorting) {
      serviceGallery = <SortableComponent data={this.props.sorted_medias[this.state.navActive] || []}
                                          onSortEnd={this.reorderPhotos} hidden_medias={this.props.hidden_medias}/>
    } else {
      serviceGallery = <div className="editProjectPhotos propertyTourPhotos">
        {visibleMedia.map((media) => {
          return <div className="editProjectPhoto">
            <img src={media.url}/>
            <div className="propertyTourEditHideIcon" onClick={() => this.hiddenMedia(media)}>
              <i className="fas fa-eye"/>
            </div>
          </div>
        })}
      </div>
    }

    let hiddenMedia = Object.values(this.props.hidden_medias[this.state.navActive] || []).map(media =>
      <div className="editProjectPhoto">
        <img src={media.url}/>
        <div className="propertyTourEditHideIcon" onClick={() => this.hiddenMedia(media)}>
          <i className="fas fa-eye-slash"/>
        </div>
      </div>)

    if (this.state.sorting) {
      hiddenMedia = []
    }

    return <div className="propertyTourEditPhotos">
      <div className="d-flex">
        {project.services.map((service, i) => {
            const className = i === this.state.navActive ?
              'propertyTourEditButton' :
              'propertyTourEditButton propertyTourEditButtonInactive'

            return <div onClick={() => this.updateNavActive(i)} className={`${className} ${i > 0 ? 'ms-2' : ''}`}>
              {service.title}
            </div>
          }
        )}
      </div>
      {!this.state.sorting && visibleMedia.length > 0 && <div onClick={() => this.setState({sorting: true})}
                                                              className="submitPhotosButton sortPhotosButton">
        Sort Photos
      </div>}

      {this.state.sorting && <div onClick={() => this.setState({sorting: false})}
                                  className="submitPhotosButton sortPhotosButton">
        Done
      </div>}

      {visibleMedia.length > 0 && serviceGallery}

      {hiddenMedia.length > 0 && <div>
        <div className="hiddenMediaText">Hidden Media</div>
        <div className="editProjectPhotos propertyTourPhotos">
          {hiddenMedia}
        </div>
      </div>}
    </div>
  }
}

export default withRouter(PropertyTourEditPhotos)