import {useInView} from "react-intersection-observer";
import FadeInAnimation from "../../home_page/homeComponents/FadeInAnimation";
import {ServiceLogo, ServiceLogoWrapper} from "../../home_page/homeStyle";
import React from "react";
import {InnerContainer, Wrapper} from "./style";
import {LOGOS} from "../../../../../assets/images/location";

export const LocationLogos = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return <Wrapper ref={ref}>
    <FadeInAnimation
      duration={0.4}
      delayOrder={0}
      yOffset={-20}
      inView={inView}>

      <InnerContainer>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.RemaxLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.DouglasLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.SothebyLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.CompassLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.Goldwell} alt=''/>
        </ServiceLogoWrapper>
      </InnerContainer>
    </FadeInAnimation>
  </Wrapper>
}