import { connect } from "react-redux";
import ForgotPasswordUpdateForm from "./forgot_password_update_form";
import React from "react";
import { openModal, closeModal } from "../../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { submitUpdatedPassword, receiveSessionErrors } from "../../actions/session_actions";
import { showToast } from "../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
});

const mdp = (dispatch) => ({
  clearErrors: () => dispatch(receiveSessionErrors([])),
  submitUpdatedPassword: (user) => dispatch(submitUpdatedPassword(user)),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(ForgotPasswordUpdateForm));