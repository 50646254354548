import React from 'react';

import MapChart from '../MapChart';

import {
  MapSectionWrapper,
  Heading,
  DescriptionText,
} from './styles';

const MapSection = (props) => {
  return (
    <MapSectionWrapper>
      <Heading>
        We meet you where you are.
      </Heading>
      <DescriptionText>
        Modern Angles has clients all of over the United States ready to work with you.
      </DescriptionText>
      {props.children ? props.children : ''}
      <MapChart />
    </MapSectionWrapper>
  );
};

export default MapSection;
