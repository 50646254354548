import React from "react";
import {Link} from "react-router-dom";

const Congratulations = () => {

  return <div className="d-flex justify-content-center align-content-center">
    <div className="congratulations-wrapper">
      <h3 className="h3React">Thank you for applying.</h3>
      <div className="description">
        We are reviewing your application and we’ll get in touch as soon as possible.
      </div>
      <Link className="projectEditButton projectEditButtonPrimary mt-4" to={'/'}>
        Go to home page
      </Link>
    </div>
  </div>
}

export default Congratulations
