import React, { useState, useRef, useEffect } from 'react';
import {
  SliderContainer,
  SliderImageContainer,
  SliderImage,
  ImageMarker,
} from '../homeStyle';
import { TOP_SLIDER, LANDING_PAGE } from '../../../../../assets/images/landing';
import Slider from "react-slick";

const HomeTopSlider = () => {
  const slides = TOP_SLIDER.map((item) => {
    return <ImageSlide key={item.id} item={item} />;
  });

  return (
    <>
      <SliderContainer className='sliderContainer'>
        <Slider
          slidesToShow={4}
          slidesToScroll={1}
          dots={false}
          arrows={false}
          centerMode={true}
          focusOnSelect={true}
          infinite={true}
          variableWidth={true}
          autoplay={true}
          speed={1000}
          draggable={true}
          pauseOnHover={false}
          responsive={[
            {
              breakpoint: 1280,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {slides}
        </Slider>
      </SliderContainer>
    </>
  );
};

const ImageSlide = (props) => {
  const { item } = props;
  return (
    <SliderImageContainer className={'sliderImageWrapper sliderIcon' + item.id}>
      <ImageMarker
        id={'Tooltip-' + item.id}
        src={LANDING_PAGE.ImageSliderMarker}
        alt={''}
      />
      <SliderImage
        className='slideImage'
        imageSource={item.src}
        alt={''}
        width={item.width}
        height={item.height}
        onLoad={props.onLoad}
      />
    </SliderImageContainer>
  );
};

export default HomeTopSlider;