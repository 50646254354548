import React from 'react';
import { Container } from 'reactstrap';

import { SectionSlideItemWrapperOdd, OddSectionLine } from './commonStyles';
import {
  ContractMediaSlide3Part1,
  ContractMediaSlide3Part2,
  ContractMediaSlide3Part3,
} from './slideThreeStyles';
import { CONTRACT } from '../../../../../../assets/images/landing';

const SlideThree = (props) => {
  const { item, activeIndex, handleDragStart } = props;

  return (
    <SectionSlideItemWrapperOdd onDragStart={handleDragStart}>
      <OddSectionLine
        src={CONTRACT.ContractBackground}
        className={`${
          item.id == activeIndex ? 'fadeInZoomInRightAnimation' : ''
        }`}
      />
      <Container>
        <ContractMediaSlide3Part1
          className={`${
            item.id === activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide3Part1}
        />
        <ContractMediaSlide3Part2
          className={`${
            item.id === activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide3Part2}
        />
        <ContractMediaSlide3Part3
          className={`${
            item.id === activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide3Part3}
        />
      </Container>
    </SectionSlideItemWrapperOdd>
  );
};

export default SlideThree;
