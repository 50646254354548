export const INITIAL_INFO = 'INITIAL_INFO'
export const PORTFOLIO = 'PORTFOLIO'
export const SERVICES = 'SERVICES'
export const AREA = 'AREA'
export const GOOGLE_CALENDAR = 'GOOGLE_CALENDAR'
export const AVAILABILITY = 'AVAILABILITY'
export const BANK_ACCOUNT = 'BANK_ACCOUNT'
export const COMPLETED = 'COMPLETED'

export const STEPS = [
  INITIAL_INFO, PORTFOLIO, SERVICES, AREA, GOOGLE_CALENDAR, AVAILABILITY, BANK_ACCOUNT, COMPLETED
]

export const DATA_KEY = "photographerOnboarding"
export const STEP_KEY = "photographerOnboardingStep"

export const getInitialData = () => {
  const cachedData = sessionStorage.getItem(DATA_KEY)
  return cachedData ? JSON.parse(cachedData) : {}
}

export const getInitialStep = () => {
  const cachedStep = sessionStorage.getItem(STEP_KEY)
  return (cachedStep && STEPS.includes(cachedStep)) ? cachedStep : INITIAL_INFO
}

export const cacheData = (data) => {
  sessionStorage.setItem(DATA_KEY, JSON.stringify(data))
}

export const cacheStep = (step) => {
  sessionStorage.setItem(STEP_KEY, step)
}

export const removeCache = () => {
  sessionStorage.removeItem(DATA_KEY)
  sessionStorage.removeItem(STEP_KEY)
}