import React from "react";
import {withRouter} from "react-router-dom";
import ProjectDashboardItem from "./project_dashboard_item";
import folderIcon from "../../../../assets/images/folder_1"
import ProjectDashboardItemUpcoming from "./project_dashboard_item_upcoming";


class UpcomingProjectsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upcomingLimited: true,
      previousLimited: true
    }

    this.seemorePrevious = this.seemorePrevious.bind(this)
    this.seemoreUpcoming = this.seemoreUpcoming.bind(this)
  }

  seemorePrevious() {
    this.setState({previousLimited: false})
  }

  seemoreUpcoming() {
    this.setState({upcomingLimited: false})
  }

  render() {
    let upcomingProjectsUl = "";
    let previousProjectsUl = "";
    let upcomingSeeMore = "";
    let previousSeeMore = "";

    if (this.state.upcomingLimited) {
      if (this.props.upcomingProjects.length > 3) {
        upcomingSeeMore = <div onClick={this.seemoreUpcoming} className="dashboardSeeMore">View more photoshoots</div>
      }
      upcomingProjectsUl = this.props.upcomingProjects.slice(0, 3).map((project, i) => <ProjectDashboardItemUpcoming
        key={i} project={project} currentUser={this.props.currentUser}/>)
    } else {
      upcomingProjectsUl = this.props.upcomingProjects.map((project, i) => <ProjectDashboardItemUpcoming key={i}
                                                                                                         project={project}
                                                                                                         currentUser={this.props.currentUser}/>)
    }

    if (this.state.previousLimited) {
      if (this.props.previousProjects.length > 3) {
        previousSeeMore = <div onClick={this.seemorePrevious} className="dashboardSeeMore">View more photoshoots</div>
      }
      previousProjectsUl = this.props.previousProjects.slice(0, 5).map((project, i) => <ProjectDashboardItem key={i}
                                                                                                             project={project}/>)
    } else {
      previousProjectsUl = this.props.previousProjects.map((project, i) => <ProjectDashboardItem key={i}
                                                                                                 project={project}/>)
    }

    let dashboardProjectListNone = ""

    if (previousProjectsUl.length === 0) {
      previousProjectsUl = <div className="emptyProjectList"><img className="emptyFolderImage" src={folderIcon}/>
        <div style={{color: "#BCC6CE", marginTop: "10px"}}>No photoshoots yet</div>
      </div>
      dashboardProjectListNone = "dashboardProjectsListNone"
    }

    if (upcomingProjectsUl.length === 0) {
      upcomingProjectsUl = <div className="emptyProjectList"><img className="emptyFolderImage" src={folderIcon}/>
        <div style={{color: "#BCC6CE", marginTop: "10px"}}>No photoshoots yet</div>
      </div>
      dashboardProjectListNone = "dashboardProjectsListNone"
    }

    return (
      <div className="dashboardUpdateProjects">
        <div className="dashboardUpdateProjectsContents"
             id={this.props.currentUser.roles.contractor ? "removeTopMargin" : ""}>
          <div className="dashboardUpcomingProjectsGroup leftProjectGroup">
            <div className="dashboardUpcomingProjectsTopGroup">
              <div className="dashboardCategoryHeading">
                Upcoming Photoshoots</div>
              <div className="viewAllUpcomingProjectsDashboard"
                   onClick={() => this.props.history.push("/projects_index")}>View All
              </div>
            </div>
            <div className="dashboardUpcomingProjectsDivider" />

            <div className="dashboardProjectList" id={dashboardProjectListNone}>
              {upcomingProjectsUl}
            </div>
          </div>

          <div className="dashboardUpcomingProjectsGroup">
            <div className="dashboardUpcomingProjectsTopGroup">
              <div className="dashboardCategoryHeading">
                Completed Photoshoots</div>
              <div className="viewAllUpcomingProjectsDashboard"
                   onClick={() => this.props.history.push("/projects_index")}>View All
              </div>
            </div>

            <div className="dashboardUpcomingProjectsDivider" />
            <div className="dashboardProjectList" id={dashboardProjectListNone}>
              {previousProjectsUl}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpcomingProjectsDashboard);