import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {closeModal} from "../../../actions/modal_actions";
import CreateTermsOfService from "./create_terms_of_service";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal())
  },
});

export default withRouter(connect(msp, mdp)(CreateTermsOfService));