import React from "react";
import {ReactComponent as SearchIcon} from "../../../../assets/images/icons/search.svg";
import {ReactComponent as CheckMark} from "../../../../assets/images/icons/blue-checkmark-icon.svg";



export default class VirtualStagingPhotoItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const onClickZoom = (e) => {
      e.stopPropagation()
      this.props.changeParentState({ galleryModalOpen: true, currentPhoto: this.props.index })
    }

    let photo = this.props.photo;
    let id = this.props.selected ? "virtualStagingPhotoSelected" : "";
    let icon = this.props.selected ? <div className="checkIcon"><CheckMark /></div> : <div className="zoomIcon" onClick={onClickZoom}><SearchIcon /></div>

    return (
      <div onClick={() => this.props.toggleSelected(photo)} className="virtualStagingPhotoItem">
        <img className="virtualStagingPhotoImage" id={id} src={photo.url} />
        {icon}
        <div className="uploadFileNameVirtualStaging">{photo.filename}</div>
      </div>
    )
  }
}