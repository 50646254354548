import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import React from "react";


const Auth = ({ component: Component, path, loggedIn, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={props =>
      !loggedIn ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

const Protected = ({ component: Component, path, loggedIn, exact, location }) => (
  <Route
    path={path}
    exact={exact}
    render={props =>
      !loggedIn ? <Redirect to={{pathname: "/login",
        state: { referrer: location }}}/> : <Component {...props} />
    }
  />
);

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: Boolean(state.session.currentUser),
    location: ownProps.location.pathname
  };
};

export const AuthRoute = withRouter(
  connect(
    mapStateToProps,
    null
  )(Auth)
);

export const ProtectedRoute = withRouter(
  connect(
    mapStateToProps,
    null
  )(Protected)
);