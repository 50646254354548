import React, {useEffect, useState} from "react";
import {largeServiceIconSrc} from "../../../util/service_util";
import {Footer} from "../footer";
import {updatePhotographerServices} from "../../../util/photographer_onboarding_util";
import {useDispatch, useSelector} from "react-redux";
import {fetchServices} from "../../../actions/service_actions";

export const Services = (props) => {
  const dispatch = useDispatch()
  const services = useSelector((state) => {
    return Object.values(state.entities.services)
  })

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [selectedServices, setSelectedServices] = useState(props.data || [])

  useEffect(() => {
    dispatch(fetchServices())
  }, []);

  const onSubmit = (e) => {
    e.preventDefault()

    const service_ids = selectedServices.map(s => s.id)

    setLoading(true)

    updatePhotographerServices({service_ids}).then(() => {
      setLoading(false)
      props.onNext(selectedServices)
    }).catch(response => {
      setLoading(false)
      setErrors(response.responseJSON || [])
    })
  }

  const onToggleSelect = (e, service) => {
    e.preventDefault()

    if (isSelected(service)) {
      setSelectedServices(selectedServices.filter(s => s.id !== service.id))
    } else {
      setSelectedServices([...selectedServices, service])
    }
  }

  const isSelected = (service) => {
    return selectedServices.map(s => s.id).includes(service.id)
  }

  return <form onSubmit={onSubmit}>
    <div className="photographer-onboarding-form-title">
      Which <span className="blue">services</span> can you provide?
    </div>

    {errors.length > 0 && <div className="alert alert-danger mb-4">
      {errors.map((err, i) => (<div key={`error-${i}`}>{err}</div>))}
    </div>}

    <div className="servicesList">
      {services.length > 0 && services.map(service => {
        return <div className="serviceItem serviceItemOnboarding" key={`service-${service.id}`} onClick={e => onToggleSelect(e, service)}
                    id={isSelected(service) ? 'serviceItemSelected' : ''}>
          <div className="iconCreateProjectDiv">
            <img className="iconImageCreateProject" src={largeServiceIconSrc(service.slug)}/>
          </div>

          <div className="serviceItemTitle">
            {service.title}
          </div>
        </div>
      })}
    </div>

    <Footer onBack={props.onBack} nextDisabled={loading || selectedServices.length === 0}/>
  </form>
}