import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import bundleImage from "../../../../assets/images/bundleImage2";
import alacartImage from "../../../../assets/images/alacartImage2";
import debounce from 'debounce'
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";

class ServiceChoiceComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      minimize: this.props.minimize
    }

    this.handler = this.handler.bind(this)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
    this.selectAlaCart = this.selectAlaCart.bind(this)
    this.selectBundle = this.selectBundle.bind(this)
  }

  handler(newState) {
    if (newState.errors) {
      this.setState(newState)
    } else {
      this.setState({ errors: [] })
        this.props.changeParentState(newState)
    }
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  async selectAlaCart(){
    await this.props.changeParentState(
      {
        bundleSelected: false,
        alacartSelected: true,
        package_attributes: {},
        selected_bundle: {},
        main_service: {},
        selected_service: -1,
        appointments: {},
        selectedSubservices: {},
        promo_price: "",
        photographer: ""
      }
    )
   this.props.handleNext()
  }

  async selectBundle(){
    await this.props.changeParentState({ bundleSelected: true, alacartSelected: false, package_attributes: {}, selected_bundle: {}, main_service: {}, selected_service: -1, appointments: {}, selectedSubservices: {}, promo_price: "", photographer: "" })
    this.props.handleNext()
  }

  render() {
    let servicesLis = []
    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""

    if (this.state.minimize) {
      icon = this.props.page >= 0 ? <div key="2"><i className="fas fa-plus"></i></div> : ""
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 0) {
        checkmark = "createProjectCheckmarkShow"
      }
    }

    const allErrors = [...this.state.errors, ...this.props.summaryErrors]

    let descriptionBundle = <div></div>
    let descriptionAlacart = <div></div>

    descriptionBundle =
      <ul className="serviceChoiceUl">
        <li style={{"fontWeight": "700"}}>Best Value! Save 20%+</li>
        <li>Combine multiple services</li>
        <li>Fast and easy booking</li>
      </ul>

    descriptionAlacart =
      <ul className="serviceChoiceUl">
        <li>Customize your order</li>
        <li>Get exactly what you want</li>
        <li>Select all of your preferred services</li>
      </ul>

    let serviceChoiceDivs = 
      <div className="serviceChoiceGroup" id={minimizeId}>
      <div className="serviceChoiceDiv" id={this.props.alacartSelected ? "homepageSelected" : ""} onClick={this.selectAlaCart}>
              <img className="serviceChoiceImg" src={alacartImage} />
              {/* <div className="serviceChoiceImageGroup">
                <div className="newProjectImage virtualStaging serviceChoiceImg" />
              </div> */}
              <div className="serviceChoiceDivHeading">Individual Services</div>
              <div>
                {/* <div className="newProjectDashboardServices newProjectDashboardServicesHomepage">Virtual Staging, Sky & View Replacement, Object Removal</div> */}
                {descriptionAlacart}
              </div>
            </div>
            <div className="serviceChoiceDiv" id={this.props.bundleSelected ? "homepageSelected" : ""} onClick={this.selectBundle}>
              <img className="serviceChoiceImg" src={bundleImage} />
              {/* <div className="serviceChoiceImageGroup">
                <div className="newProjectImage photography serviceChoiceImg" />
              </div> */}
              <div className="serviceChoiceDivHeading">Package Deals</div>
              <div>
                {/* <div className="newProjectDashboardServices newProjectDashboardServicesHomepage">Photography, Videos, Drones, Floor Plans, 3D Renderings, Twilight </div> */}
                {descriptionBundle}
              </div>
            </div>
        </div>

    return (
      <div className="projectLocationComponent">
        <div className="projectLocationHeadingGrouping" id={minimized}>
          <div className="projectLocationHeadingMinimizedGrouping">
            <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
            <div className="settingsComponentHeading serviceChoiceHeading">Explore our real estate photography services</div>
          </div>
        </div>

        {/* <div className="subServicesHeadingBottomDivider" id={minimizeId} /> */}

        {allErrors.length > 0 && <div className="errorDiv mt-3 serviceSelectionErrors" id={minimizeId}>{allErrors}</div>}
        {serviceChoiceDivs}
          {/* <div className="homePageCreateModalSelection" id={this.state.projectSelected ? "homepageSelected" : ""} onClick={() => this.setState({projectSelected: true, vsSelected: false})}><img className="homePageCreateModalImage" src={NewPhotoProject}></img></div> */}
          {/* <div className="homePageCreateModalSelection" id={this.state.vsSelected ? "homepageSelected" : ""} onClick={() => this.setState({vsSelected: true, projectSelected: false})}><img className="homePageCreateModalImage" src={NewVSProject}></img></div> */}
      </div>
    )
  }
}

export default withRouter(ServiceChoiceComponent);