import React from "react";

import { AccordionSectionWrapper } from "./styles";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const AccordionSection = ({ data }) => {
  return (
    <AccordionSectionWrapper>
      <Accordion allowZeroExpanded>
        {data.map((item) => (
          <AccordionItem key={item.id}>
            <AccordionItemHeading>
              <AccordionItemButton>{item.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              dangerouslySetInnerHTML={{ __html: item.body }}
            ></AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </AccordionSectionWrapper>
  );
};

export default AccordionSection;
