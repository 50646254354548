import React from 'react';
import {withRouter} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {CareersWrapper} from './CareersStyles';

import CareersHeader from "../../careers_page_components/CareersHeader";
import CareersTopSection from "../../careers_page_components/CareersTopSection";
import CareersSecondSection from "../../careers_page_components/CareersSecondSection";
import CareersThirdSection from "../../careers_page_components/CareersThirdSection";
import CareersFaqSection from "../../careers_page_components/CareersFaqSection";
import {ContactFormGallery} from "../../pageComponents";
import MapSection from "../../careers_page_components/MapSection";

const CareersPage = (props) => {
  return (
    <CareersWrapper>
      <Helmet>
        <title>Real Estate Photography Jobs | Fastest Growing Team</title>
        <meta
          name="description"
          content="Grow your career by becoming an approved Modern Angles Real Estate Photographer. If you’re looking to expand your income, be part of a team and learn unique skills along the way, you’re in the right place."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <CareersHeader/>
      <CareersTopSection/>
      <CareersSecondSection/>
      <CareersThirdSection/>
      <CareersFaqSection/>

      <MapSection/>

      <ContactFormGallery isImageMarkerLight={true}/>
    </CareersWrapper>
  );
};

export default withRouter(CareersPage);
