import styled from 'styled-components';

export const ContactFormContainer = styled.div`
  width: 100%;
`;

export const ContactFormContent = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #353844;
  width: 835px;

  @media screen and (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
    width: 725px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ContactFormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 40px 0px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin: 35px 0px;
  }
`;

export const ContactFormFields = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ContactFormSubmitButton = styled.button`
  width: 145px;
  height: 60px;
  margin: 20px 0px;
  background-color: #1b264f;
  border: solid 1px #1b264f;
  border-radius: 8px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;

  @media screen and (max-width: 767px) {
    margin: 10px 0px;
  }
`;

export const ContactFormInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

export const ContactFormInnerContent = styled.div`
  padding-left: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 767px) {
    padding-left: 0px;
  }
`;

export const FormInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`;

export const FormInfoRowImage = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 15px;
  margin-top: -16px;
`;

export const FormInfoRowValue = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #1b264f;
`;

export const ContactFormDivider = styled.div`
  margin-top: 40px;
  margin-bottom: 50px;
  border: 1px solid #353844;
  opacity: 0.1;
  @media screen and (max-width: 767px) {
    margin: 30px 0px;
  }
`;

export const ContactFormFindHereHeading = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  color: #1b264f;
  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin: 40px 0px;
  }
`;

export const ContactFormSocialBlock = styled.div`
  width: 390px;
  display: flex;
  flex-direction: column;
`;

export const ContactFormSocialBlockRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
`;

export const ContactFormSocialItem = styled.a`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const ContactFormSocialItemIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 18px;
`;

export const ContactFormSocialItemLabel = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #353844;
`;
