import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FAQPage from './FAQPage';
import { fetchFAQs } from '../../../actions/faq_actions';

const msp = (state) => {
  return {
    faqs: state.entities.faqsQuestions.faqs,
  };
};

const mdp = (dispatch) => ({
  fetchFAQs: () => {
    dispatch(fetchFAQs());
  },
});

export default withRouter(connect(msp, mdp)(FAQPage));
