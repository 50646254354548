import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { AboutUsWrapper } from './AboutUsStyles';

import {
  AboutHeader,
  AboutTopSection,
  AboutSecondSection,
  AboutContactSection,
  MapSection,
} from '../../about_page_components';
import { ContactFormGallery } from '../../pageComponents';

const AboutUsPage = (props) => {
  const { location } = props;
  return (
    <AboutUsWrapper>
      <Helmet>
        <title>About Modern Angles Home & Commercial Real Estate Photography</title>
        <meta
          name="description"
          content="Modern Angles sets a higher standard for realty marketing services - from home & commercial real estate photography and videos to floorplans, staging, and more."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <AboutHeader setModal={props.setModal}/>
      <AboutTopSection />
      <AboutSecondSection />
      <MapSection />
      <AboutContactSection />
      <ContactFormGallery isImageMarkerLight={true} />
    </AboutUsWrapper>
  );
};

export default withRouter(AboutUsPage);
