import React from "react"
import { withRouter } from "react-router-dom"
import ChooseTimeslotItem from "./choose_time_slot_item";
import { railsToJsTimezoneMap } from "../../util/timezone_utils";
import { formatDatetime } from "../../util/date_util";

class ChooseTimeModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: new Date(),
      selectedTime: "",
      dates: [],
      month: new Date(),
      currentDay: new Date(),
      confirmModal: false,
      isLargeScreen: true,
    }
    this.handler = this.handler.bind(this)
    this.applyTime = this.applyTime.bind(this)
    this.addMonth = this.addMonth.bind(this)
    this.subtractMonth = this.subtractMonth.bind(this)
    this.addDays = this.addDays.bind(this)
    this.subtractDays = this.subtractDays.bind(this)
    this.handleTimeSelect = this.handleTimeSelect.bind(this)
    this.updatePredicate = this.updatePredicate.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    let today_date = new Date()
    today_date.setDate(today_date.getDate() + 1)
    
    let previous_time_selected = ""

    if (this.props.contractorTimeSearch){
      previous_time_selected = parseInt(this.props.contractorTimeSearch)
    }

    if (this.props.contractorDateSearch) {
      today_date = this.props.contractorDateSearch
  
    }

    let dates = this.getDaysInMonth(today_date)

    this.setState({
      dates: dates,
      currentDay: today_date,
      selected: today_date,
      selectedTime: previous_time_selected,
      month: today_date
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    document.body.style.overflow = 'unset';
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 550 });
  }

  addDays() {
    let currentDay = new Date(this.state.currentDay)

    if (this.state.isLargeScreen) {
      currentDay.setDate(currentDay.getDate() + 10)
    } else {
      currentDay.setDate(currentDay.getDate() + 5)
    }

    if (currentDay.getMonth() !== this.state.currentDay.getMonth()) {
      currentDay.setDate(1)
    }

    let dates = this.getDaysInMonth(currentDay)
    this.setState({ dates: dates, currentDay: currentDay, month: currentDay, selected: dates[0], selectedTime: "" })
  }

  subtractDays() {
    let currentDay = new Date(this.state.currentDay)

    if (this.state.isLargeScreen) {
      currentDay.setDate(currentDay.getDate() - 10)
    } else {
      currentDay.setDate(currentDay.getDate() - 5)
    }

    let todayDate = new Date()

    todayDate.setDate(todayDate.getDate() + 1)

    if (currentDay < todayDate && !this.props.currentUser?.roles?.admin) {
      let dates = this.getDaysInMonth(todayDate)
      this.setState({ dates: dates, currentDay: todayDate, month: todayDate, selected: dates[0], selectedTime: "" })
    } else {
      let dates = this.getDaysInMonth(currentDay)
      this.setState({ dates: dates, currentDay: currentDay, month: currentDay, selected: dates[0], selectedTime: "" })
    }
  }

  applyTime() {
    if (this.state.selectedTime !== "") {
      if (this.props.rescheduleModal){
        this.props.changeParentState({ chooseTimeModalOpen: false, contractorDateSearch: new Date(this.state.selected.toLocaleString('en-US', { timeZone: railsToJsTimezoneMap[this.props.detected_timezone] })), contractorTimeSearch: this.state.selectedTime })
      } else {
      let newStateAppointments = this.props.appointments
      let newState = this.props.package_attributes
      delete newStateAppointments[this.props.selectedService]
      if (newState[this.props.selectedService] && newState[this.props.selectedService]["appointment_time"]){
        delete newState[this.props.selectedService]["appointment_time"]
      }
      if (newState[this.props.selectedService] && newState[this.props.selectedService]["contractor_id"]){
        delete newState[this.props.selectedService]["contractor_id"]
      }

        this.props.changeParentState({ appointments: newStateAppointments, package_attributes: newState, chooseTimeModalOpen: false, contractorDateSearch: new Date(this.state.selected.toLocaleString('en-US', { timeZone: railsToJsTimezoneMap[this.props.detected_timezone] })), contractorTimeSearch: this.state.selectedTime})
      }
    }
  }

  addMonth() {
    let date = new Date(this.state.month.getTime())
    date.setMonth(this.state.month.getMonth() + 1)
    date.setDate(1)

    let dates = this.getDaysInMonth(date)

    this.setState({ dates: dates, month: date, currentDay: date })
  }

  subtractMonth() {
    let today_date = new Date()
    let date = new Date(this.state.month.getTime())

    if (date.getMonth() != today_date.getMonth()) {
      date.setMonth(this.state.month.getMonth() - 1)
    }

    if (date.getMonth() === today_date.getMonth()) {
      date.setDate(today_date.getDate() + 1)
    } else {
      date.setDate(1)
    }

    let dates = this.getDaysInMonth(date)

    this.setState({ dates: dates, month: date, currentDay: date })
  }

  getDaysInMonth(date) {
    let month = date.getMonth()
    let new_date = new Date(date.getTime())

    let days = []
    while (new_date.getMonth() === month) {
      days.push(new Date(new_date))
      new_date.setDate(new_date.getDate() + 1)
    }
    return days
  }

  changeSelected(index) {
    this.setState({ selected: this.state.dates[index], selectedTime: "" })
  }

  handler(newState) {
    this.setState(newState)
  }

  handleTimeSelect(time) {
    if (this.state.selectedTime === time) {
      this.setState({ selectedTime: "" })
    } else {
      this.setState({ selectedTime: time })
    }
  }


  render() {
    let dates = this.state.dates.slice(0, this.state.isLargeScreen ? 10 : 5).map((date, index) =>
      <div className="createProjectDateOptions" key={index}
        id={this.state.selected.toString() === date.toString() ? "createProjectDateOptionSelected" : ""}
        onClick={() => this.changeSelected(index)}>
        <div className="createProjectDate"
          id={this.state.selected.toString() === date.toString() ? "createProjectDateSelected" : ""}>{date.getDate()}</div>
        <div className="createProjectDateDay"
          id={this.state.selected.toString() === date.toString() ? "createProjectDateDaySelected" : ""}>{date.toLocaleString('default', { weekday: 'short' })}</div>
      </div>
    )

    let selectedTime = this.state.selectedTime

    let timeSlotOptions = []

    let slots = [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5]
    let times = ["8:00 am", "8:30 am", "9:00 am", "9:30 am", "10:00 am", "10:30 am", "11:00 am", "11:30 am", "12:00 pm", "12:30 pm", "1:00 pm", "1:30 pm", "2:00 pm", "2:30 pm", "3:00 pm", "3:30 pm", "4:00 pm", "4:30 pm", "5:00 pm", "5:30pm", "6:00 pm", "6:30 pm", "7:00 pm", "7:30pm", "8:00", "8:30"];

    slots.forEach((slot, i) => {
      if (this.props.time_slots[this.state.selected ? this.state.selected.toLocaleString('en-us', { weekday: 'long' }) : new Date().toLocaleString('en-us', { weekday: 'long' })][slot]) {
        timeSlotOptions.push(<ChooseTimeslotItem key={i} currentUser={this.props.currentUser} time={times[i]} slot={slot} selected={selectedTime === slot}
          handleTimeSelect={this.handleTimeSelect} changeParentState={this.handler} />)
      }
    })

    let modalClass = "orderpreviewModal contractorSchedulingModal"

    if (this.props.homepageModal) {
      modalClass = "orderpreviewModal addServiceModalContent"
    }

    return <div className="modal-background" onClick={() => this.props.changeParentState({ modalOpen: false })}>
      <div className={modalClass} onClick={e => e.stopPropagation()}>
        <div className="orderPreviewModalComponent">
          {this.state.isLargeScreen ?
            <div className="contractorModalHeading">
              <div className="contractorModalDescription">Choose your date and time</div>
              {/* <div className="contractorModalButton" onClick={() => this.props.changeSelected()}>Cancel</div> */}
            </div> : ""}
          <div className="photographerSubheading photographerSubheadingTop">Below you will find the available dates and
            times.
          </div>
          <div className="mainSchedulingComponentGroup">
            <div className="dateSelectionGrouping" id="chooseTimeModalSelectionGrouping">
              <div className="contractorTimeSlotDateHeadingGroup">
                <div className="contractorTimeSlotsHeading">
                  <div className="selectedWeekday">
                    {this.state.selected ? formatDatetime(this.state.selected, "dddd", this.props.detected_timezone) : ''},
                  </div>
                  <div
                    className="selectedMonth">{this.state.selected ? formatDatetime(this.state.selected, "MMMM", this.props.detected_timezone) : ''} </div>
                  <div className="selectedDate">{this.state.selected ? formatDatetime(this.state.selected, "DD", this.props.detected_timezone) + ', ' : ''}</div>
                  <div className="selectedDate">{this.state.selected ? formatDatetime(this.state.selected, "YYYY", this.props.detected_timezone) : ''}</div>
                </div>
                <div className="selectedArrows">
                  <div onClick={this.subtractDays} className="selectedArrowLeft"><i className="fas fa-chevron-left"></i>
                  </div>
                  <div className="selectedArrowMonth">{this.state.month.toLocaleString('default', { month: 'long' })}</div>
                  <div onClick={this.addDays} className="selectedArrowRight"><i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
              <div className="daysGroupDiv">
                <div className="contractorTimeSlotsDates">
                  {dates}
                </div>
              </div>
            </div>
            <div className="timeSlotGrouping">
              <div className="selectTime">Select time</div>
              {this.props.detected_timezone && <div className="small mb-3">
                Times are shown in {this.props.detected_timezone}
              </div>}
              <div className="timesDiv">
                {timeSlotOptions.length > 0 ? timeSlotOptions : <div className="noTimeslotsDiv">No timeslots for this date</div>}
              </div>
            </div>
          </div>
          <div className="contractorTimeSlotsButtons">
            <div onClick={() => this.props.changeParentState({chooseTimeModalOpen: false})} className="signupFormPrevious">Cancel</div>
            <div onClick={this.applyTime} className="signupFormNext" id={this.state.selectedTime === "" ? 'disabled' : ''}>Apply</div>
          </div>
        </div>
        <div className="modalMobilePushup"></div>
      </div>
    </div>
  }
}

export default withRouter(ChooseTimeModal)