import React from 'react'
import howItWorksImg from "../../../../../assets/images/bookPage/how-it-works.png";

export const HowItWorks = (props) => {
  const defaultItems = [
    {
      title: 'Online Scheduling',
      description: 'Book with our easy-to-use online scheduling platform, putting you back in control of your listing.'
    },
    {
      title: 'At The Shoot',
      description: 'Photographer arrives, prepared to create a winning game plan to capture your property at its best.'
    },
    {
      title: 'Media Delivery',
      description: 'Get immediate notification emails for your top-notch media, available for download within 24 hours.'
    }
  ]

  const subtitle = props.subtitle || 'Get more listing inquiries with Modern Angles premium HDR photography.'
  const items = props.items || defaultItems

  return <div className="how-it-works-container">
    <div className="how-it-works-container-inner book-page-container-inner">
      <div className="how-it-works-content">
        <div className="how-it-works-subtitle">Our process</div>
        <div className="how-it-works-title">How it works..</div>
        <div className="how-it-works-description">{subtitle}</div>

        <div className="how-it-works-list">
          {items.map((item, i) => (
            <div className="how-it-works-list-item" key={`how-it-works-${i}`}>
              <div className="how-it-works-list-item-count">{i + 1}</div>
              <div className="how-it-works-list-item-text">
                <div className="how-it-works-list-item-title">{item.title}</div>
                <div className="how-it-works-list-item-description">{item.description}</div>
              </div>
            </div>))}
        </div>
      </div>

      <div className="how-it-works-image">
        <img src={howItWorksImg}/>
      </div>
    </div>
  </div>
}