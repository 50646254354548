import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import NavbarContainer from "./navbar/navbar_container";
import SettingsMainContainer from "./settings/settings_main";


class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLargeScreen: true
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    document.body.style.overflow = 'unset';
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    // this.props.fetchServices();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  render() {

    let icon = <div key="1"><i className="fas fa-cog mainIcon"></i></div>
     

    if (!this.state.isLargeScreen) {
      icon = <div key="2" onClick={() => this.props.history.push("/dashboard")}><i className="fas fa-arrow-left mobileIcon"></i></div>
    }

    return (
      <div className="settingsFullPageDiv">
        <div className="settingsPageGroupings">
          <NavbarContainer />
          <div className="SettingsMainDiv">
            <div className="settingsPageHeading">
              <span className="dashboardSubHeading settingsSubHeading">Settings</span>
            </div>
            <div className="SettingsBody">
              <SettingsMainContainer isLargeScreen={this.state.isLargeScreen} logout={this.props.logout} currentUser={this.props.currentUser}/>
            </div>
          </div>
        </div>
        <div className="mobilePushMapAboveNavbar"></div>
      </div>
    )
  }
}

export default withRouter(Settings);