import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import ProjectCreationLoginFormContainer from "./create_project_login_form_container";
import ProjectCreationSignupFormContainer from "./create_project_signup_form_container";

class AccountRequiredModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      errors: []
    }

    this.handleInput = this.handleInput.bind(this)
    this.handler = this.handler.bind(this)
  }



  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  handler(newState) {
    this.setState(newState)
  }

  handleInput(field) {
    return e => {
      let paymentInfo = this.state.paymentInfo

      if ((field === "cvc" || field === "last_four") && paymentInfo[field].length > 0 && paymentInfo[field][0] === "X") {
        if (e.currentTarget.value.length === 2 || e.currentTarget.value.length === 18) {
          paymentInfo[field] = ""
        } else {
          paymentInfo[field] = e.currentTarget.value[e.currentTarget.value.length - 1]
        }
      }
      else {
        if ((field === "cvc" && e.currentTarget.value.length > 4) || (field === "last_four" && e.currentTarget.value.length > 16)) {

        } else {
          paymentInfo[field] = e.currentTarget.value
        }
      }
      this.setState({ paymentInfo: paymentInfo });
    }
  }


  render() {

    if (this.state.page === 1) {

      return (
        <div className="modal-background" onClick={() => this.props.changeParentState({ modalOpen: false })}>
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="settingsComponentHeading orderPreviewModalHeading"> Account Required!</div>
              <div>An account is required in order to track your order progress and access your photos</div>
              <div className="accountRequiredButtons">
                <button className="submitOrderButton loginAccountButton" onClick={() => this.setState({ page: 2 })}>Log in</button>
                <button className="submitOrderButton" onClick={() => this.setState({ page: 3 })}>Create Account</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else if(this.state.page === 2){
      return (
        <div className="modal-background">
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="uploadPhotoModalHeading">Sign In</div>
                <div className="closeIcon" onClick={() => this.props.changeParentState({ modalOpen: false })}><i className="fas fa-times"></i></div>
              </div>
              <ProjectCreationLoginFormContainer changeParentState={this.handler}/>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="modal-background">
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <ProjectCreationSignupFormContainer changeParentState={this.handler} />
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(AccountRequiredModal);