import React from 'react'

const Image = ({block}) => {
  const classes = [
    block.data.withBorder ? 'with-border' : '',
    block.data.stretched ? 'stretched' : '',
    block.data.withBackground ? 'with-background' : '',
  ].join(' ')

  return <div className={`blog-image ${classes}`}>
    <img src={block.data.file?.url}/>
    <div className="blog-image-caption" dangerouslySetInnerHTML={{__html: block.data.caption}}/>
  </div>
}

export default Image
