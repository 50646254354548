import React from "react";
import { Route, Link } from "react-router-dom";


export default class SignupFormPage1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    let passwordErrorClass = "";
    let emailErrorClass = "";
    let firstNameErrorClass = "";
    let lastNameErrorClass = "";
    let phoneNumberErrorClass = "";
    let agentIdErrorClass = "";

    let passwordErrors = [];
    let firstNameErrors = [];
    let lastNameErrors = [];
    let phoneNumberErrors = [];
    let emailErrors = [];
    let otherErrors = [];
    let agentIdErrors = [];

    this.props.errors.forEach((error) => {

      if (error[0] === 1) {
        firstNameErrors.push(<li>{error[1]}</li>)
        firstNameErrorClass = "formInputError"
      }
      else if (error[0] === 2) {
        lastNameErrors.push(<li>{error[1]}</li>)
        lastNameErrorClass = "formInputError"
      }
      else if (error[0] === 3) {
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }
      else if (error[0] === 4) {
        passwordErrors.push(<li>{error[1]}</li>)
        passwordErrorClass = "formInputError"
      }
      else if (error[0] === 6) {
        otherErrors.push(<li>{error[1]}</li>)
      }
    })

    return (
      <div>
        <ul className="errorUl">{otherErrors}</ul>
        <div className="signupInputGroup">
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> First name </label>
              <input className="formInput" id={firstNameErrorClass} onChange={(e) => this.props.changeParentState({ firstName: e.currentTarget.value })} type="string" placeholder="First Name" value={this.props.info.firstName} />
              <ul className="errorUl">{firstNameErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel"> Last name </label>
              <input className="formInput" id={lastNameErrorClass} onChange={(e) => this.props.changeParentState({ lastName: e.currentTarget.value })} type="string" placeholder="Last Name" value={this.props.info.lastName} />
              <ul className="errorUl">{lastNameErrors}</ul>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> Email</label>
              <input className="formInput" id={emailErrorClass} onChange={(e) => this.props.changeParentState({ email: e.currentTarget.value })} type="email" placeholder="Email address" value={this.props.info.email} />
              <ul className="errorUl">{emailErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel"> Phone number </label>
              <input className="formInput" id={phoneNumberErrorClass} onChange={(e) => this.props.changeParentState({ phoneNumber: e.currentTarget.value })} type="tel" placeholder="Phone Number" value={this.props.info.phoneNumber} />
              <ul className="errorUl">{phoneNumberErrors}</ul>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> Password </label>
              <input className="formInput" id={passwordErrorClass} onChange={(e) => this.props.changeParentState({ password: e.currentTarget.value })} type="password" placeholder="Password" value={this.props.info.password} />
              <ul className="errorUl">{passwordErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel">MLS ID (optional)</label>
              <input className="formInput" id={agentIdErrorClass} onChange={(e) => this.props.changeParentState({ agentId: e.currentTarget.value })} type="string" placeholder="Agent MLS ID" value={this.props.info.agentId} />
              <ul className="errorUl">{agentIdErrors}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}