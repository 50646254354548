import React from "react";
import {withRouter} from "react-router-dom";


class NewProjectsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    if (this.props.currentUser.roles.contractor || this.props.currentUser.roles.post_production || this.props.currentUser.roles.sales_rep) {
      return "";
    } else {
      return (
        <div className="dashboardNewProject">
          <div className="newProjectGroup1">
            <div className="dashboardCategoryHeading">Start a New Project</div>
            <div className="dashboardCategorySubheading">You can completely customize and review associated costs before
              confirming your project.
            </div>
          </div>
          <div className="newProjectGrouping">
            <div className="newProjectDashboardDiv" onClick={() => this.props.history.push("/create-project")}>
              <div className="newProjectImage photography"/>
              <div className="newProjectDashboardHeading">New Photography Project</div>
              <div className="newProjectDashboardHeadingDescription">Photography, Videos, Drones, Floor Plans</div>
            </div>
            <div className="newProjectDashboardDiv virtualSuiteDashboardImage"
                 onClick={() => this.props.history.push("/create-virtual-suite")}>
              <div className="newProjectImage virtualStaging"/>
              <div className="newProjectDashboardHeading">New Virtual Staging Order</div>
              <div className="newProjectDashboardHeadingDescription">Virtual Staging, Sky & View Replacement, Object
                Removal
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(NewProjectsDashboard)