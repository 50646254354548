import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TermsPage from './TermsPage';

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({});

export default withRouter(connect(msp, mdp)(TermsPage));
