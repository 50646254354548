import dayjs from "dayjs";

export const simple_timezones = [
  { label: 'Eastern Time (US & Canada)', value: 'America/New_York' },
  { label: 'Central Time (US & Canada)', value: 'America/Chicago' },
  { label: 'Mountain Time (US & Canada)', value: 'America/Denver' },
  { label: 'Arizona', value: 'America/Phoenix' },
  { label: 'Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
  { label: 'Alaska', value: 'America/Anchorage' },
  { label: 'Hawaii', value: 'Pacific/Honolulu' },
];

export const timeZones = {
  "America/Adak": {"offset": -36000, "name": "-10:00 America/Adak"},
  "Hawaii": {"offset": -36000, "name": "-10:00 Hawaii"},
  "Alaska": {"offset": -32400, "name": "-09:00 Alaska"},
  "America/Anchorage": {"offset": -32400, "name": "-09:00 America/Anchorage"},
  "America/Metlakatla": {"offset": -32400, "name": "-09:00 America/Metlakatla"},
  "America/Nome": {"offset": -32400, "name": "-09:00 America/Nome"},
  "America/Sitka": {"offset": -32400, "name": "-09:00 America/Sitka"},
  "America/Yakutat": {"offset": -32400, "name": "-09:00 America/Yakutat"},
  "Pacific Time (US & Canada)": {"offset": -28800, "name": "-08:00 Pacific Time (US & Canada)"},
  "Mountain Time (US & Canada)": {"offset": -25200, "name": "-07:00 Mountain Time (US & Canada)"},
  "America/Phoenix": { "offset": -25200, "name": "-07:00 America/Phoenix" },
  "America/Denver": { "offset": -25200, "name": "-07:00 America/Denver" },
  "America/Indiana/Knox": {"offset": -21600, "name": "-06:00 America/Indiana/Knox"},
  "America/Indiana/Tell_City": {"offset": -21600, "name": "-06:00 America/Indiana/Tell_City"},
  "America/Menominee": {"offset": -21600, "name": "-06:00 America/Menominee"},
  "America/North_Dakota/Beulah": {"offset": -21600, "name": "-06:00 America/North_Dakota/Beulah"},
  "America/North_Dakota/Center": {"offset": -21600, "name": "-06:00 America/North_Dakota/Center"},
  "America/North_Dakota/New_Salem": {"offset": -21600, "name": "-06:00 America/North_Dakota/New_Salem"},
  "Central Time (US & Canada)": {"offset": -21600, "name": "-06:00 Central Time (US & Canada)"},
  "America/Chicago": { "offset": -21600, "name": "-06:00 America/Chicago" },
  "America/Detroit": {"offset": -18000, "name": "-05:00 America/Detroit"},
  "America/Indianapolis": { "offset": -18000, "name": "-05:00 America/Indianapolis" },
  "America/Indiana/Marengo": {"offset": -18000, "name": "-05:00 America/Indiana/Marengo"},
  "America/Indiana/Petersburg": {"offset": -18000, "name": "-05:00 America/Indiana/Petersburg"},
  "America/Indiana/Vevay": {"offset": -18000, "name": "-05:00 America/Indiana/Vevay"},
  "America/Indiana/Vincennes": {"offset": -18000, "name": "-05:00 America/Indiana/Vincennes"},
  "America/Indiana/Winamac": {"offset": -18000, "name": "-05:00 America/Indiana/Winamac"},
  "America/Kentucky/Louisville": {"offset": -18000, "name": "-05:00 America/Kentucky/Louisville"},
  "America/Kentucky/Monticello": {"offset": -18000, "name": "-05:00 America/Kentucky/Monticello"},
  "Eastern Time (US & Canada)": {"offset": -18000, "name": "-05:00 Eastern Time (US & Canada)"},
}

const majorUsTimeZones = {
  "Eastern Time (US & Canada)": ["America/New_York", "America/Detroit", "America/Indiana/Petersburg", "America/Indiana/Vincennes", "America/Indiana/Winamac", "America/Kentucky/Monticello", "America/Louisville"],
  "Central Time (US & Canada)": ["America/Chicago", "America/Indiana/Knox", "America/Indiana/Tell_City", "America/Menominee", "America/North_Dakota/Center", "America/North_Dakota/New_Salem", "America/North_Dakota/Beulah"],
  "Mountain Time (US & Canada)": ["America/Denver", "America/Boise"],
  "Arizona": ["America/Phoenix"],
  "Pacific Time (US & Canada)": ["America/Los_Angeles", "America/Juneau", "America/Metlakatla", "America/Nome", "America/Sitka", "America/Yakutat"],
  "Hawaii": ["Pacific/Honolulu"],
  "Alaska": ["America/Nome", "America/Anchorage"],
};

const defaultTimezoneByOffset = {
  [-36000]: "America/Adak",
  [-32400]: "Alaska",
  [-28800]: "Pacific Time (US & Canada)",
  [-25200]: "Mountain Time (US & Canada)",
  [-21600]: "Central Time (US & Canada)",
  [-18000]: "Eastern Time (US & Canada)"
}

export const railsToJsTimezoneMap = {
  "Hawaii": "Pacific/Honolulu",
  "Alaska": "America/Nome",
  "Arizona": "America/Phoenix",
  "Indiana": "America/Indianapolis",
  "Pacific Time (US & Canada)": "America/Los_Angeles",
  "Mountain Time (US & Canada)": "America/Boise",
  "Central Time (US & Canada)": "America/Chicago",
  "Eastern Time (US & Canada)": "America/New_York"
}

export const detectTimezone = async (latitude, longitude) => {
  try {
    // const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return getTimezone(latitude, longitude)
  } catch (e) {
    console.error('Error detecting timezone:', e);
    return "Eastern Time (US & Canada)"; // Default fallback
  }
}

export const getTimezone = async (latitude, longitude) => {
  try {
    const response = await fetch(`/timezone?latitude=${latitude}&longitude=${longitude}`);
    const data = await response.json();
    const fetchedTimeZoneId = data.timeZoneId;

    for (const [zoneName, identifiers] of Object.entries(majorUsTimeZones)) {
      if (identifiers.includes(fetchedTimeZoneId)) {
        return zoneName; // Returns the major timezone name if matched
      }
    }

    return fetchedTimeZoneId; 
  } catch (error) {
    console.error('Error fetching timezone:', error);
    return null;
  }
};

export const setDefaultTimezone = (timezone) => {
  localStorage.timezone = railsToJsTimezoneMap[timezone] || timezone
}

export const resetDefaultTimezone = () => {
  localStorage.removeItem('timezone')
}

window.addEventListener('storage', () => {
  const timezone = localStorage.getItem('timezone')

  if (timezone) {
    dayjs.tz.setDefault(timezone)
  } else {
    dayjs.tz.setDefault()
  }
});