import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import {
  FullServiceWrapper,
  FullServiceTitle,
  ServiceImage,
  FullServiceContainer,
  ServiceWrapper,
  ServiceTitleWrapper,
  ServiceIcon,
  ServiceTitle,
  ServiceContent,
  FullServiceTitleGold, FullServiceSubTitle,
} from '../../pageComponents/FullService/styles';
import {PHOTOGRAPHY} from "../../../../../assets/images/servicesPages";
import {FULL_SERVICE_PHOTOGRAPHY_DATA, FULL_SERVICE_DRONE_DATA} from "./data";

const FullService = () => {
  return (
    <FullServiceWrapper className='fullServiceWrapper'>
      <Container>
        <Row>
          <FullServiceTitle>
            We Use <FullServiceTitleGold>HDR Real Estate Photography</FullServiceTitleGold>
          </FullServiceTitle>
        </Row>
        <Row>
          <Col
            xl='6'
            lg='6'
            md='6'
            sm='12'
            xs='12'
            className='serviceImageWrapper'
          >
            <ServiceImage src={PHOTOGRAPHY.PhotographyFullService} />
          </Col>
          <Col
            xl='6'
            lg='6'
            md='6'
            sm='12'
            xs='12'
            className='serviceContentWrapper'
          >
            <FullServiceContainer>
              <FullServiceSubTitle>HDR Photography</FullServiceSubTitle>
              {FULL_SERVICE_PHOTOGRAPHY_DATA.map((service, index) => {
                return (
                  <ServiceWrapper key={index}>
                    <ServiceTitleWrapper>
                      <ServiceIcon src={service.icon} />
                      <ServiceTitle>{service.title}</ServiceTitle>
                    </ServiceTitleWrapper>
                    <ServiceContent>{service.content}</ServiceContent>
                  </ServiceWrapper>
                );
              })}
            </FullServiceContainer>
          </Col>
        </Row>

        <Row className="flex-column-reverse flex-md-row">
          <Col
            xl='6'
            lg='6'
            md='6'
            sm='12'
            xs='12'
            className='serviceContentWrapper'
          >
            <FullServiceContainer>
              <FullServiceSubTitle>Drone Photography</FullServiceSubTitle>
              {FULL_SERVICE_DRONE_DATA.map((service, index) => {
                return (
                  <ServiceWrapper key={index}>
                    <ServiceTitleWrapper>
                      <ServiceIcon src={service.icon} />
                      <ServiceTitle>{service.title}</ServiceTitle>
                    </ServiceTitleWrapper>
                    <ServiceContent>{service.content}</ServiceContent>
                  </ServiceWrapper>
                );
              })}
            </FullServiceContainer>
          </Col>
          <Col
            xl='6'
            lg='6'
            md='6'
            sm='12'
            xs='12'
            className='serviceImageWrapper'
          >
            <ServiceImage src={PHOTOGRAPHY.PhotographyFullService2} />
          </Col>
        </Row>
      </Container>
    </FullServiceWrapper>
  );
};

export default FullService;
