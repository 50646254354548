import { connect } from "react-redux";
import ContractorCalendarComponent from "./contractor_calendar_component";
import React from "react";
import { withRouter } from "react-router-dom"
import { fetchTimes } from "../../actions/project_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
  times: Object.values(state.entities.calendar)
});

const mdp = (dispatch) => ({
  fetchTimes: (time_info) => dispatch(fetchTimes(time_info)),
});

export default withRouter(connect(msp, mdp)(ContractorCalendarComponent));