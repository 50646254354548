import React from "react";
import { Route, Link } from "react-router-dom";
import { createVirtualSuiteType } from "../../../util/virtual_staging_api_util";


export default class CreateVirtualSuiteType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      sortOrder: "",
      description: "",
      cover: "",
      cover_src: null
    }
    this.handleInput = this.handleInput.bind(this)
    this.createVirtualSuiteType = this.createVirtualSuiteType.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
  }

  componentDidMount() {
    
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handleCheckbox(field) {
    return e => this.setState({ [field]: !this.state[field] })
  }

  handlePictureSelected(event) {
    let cover = event.target.files[0];
    let cover_src = URL.createObjectURL(cover);

    this.setState({
      cover: cover,
      cover_src: cover_src
    });
  }


  createVirtualSuiteType() {
    let formData = new FormData();

    formData.append("virtual_suite_type_info[name]", this.state.name)
    formData.append("virtual_suite_type_info[sort_order]", this.state.sortOrder)
    formData.append("virtual_suite_type_info[description]", this.state.description)
    if (this.state.cover != "") {
      formData.append("virtual_suite_type_info[cover]", this.state.cover)
    }

    createVirtualSuiteType(formData).then((virtual_suite_type) => {
      if (virtual_suite_type != {}) {
        this.props.onTypeCreate(virtual_suite_type)
        this.props.closeModal()
        this.props.showToast("Virtual Suite Type has been created")
      }
    })
  }


  render() {
    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading"> Add Virtual Suite Type</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>
          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel"> Name </label>
              <input className="formInput" onChange={this.handleInput("name")} type="string" placeholder="Name" value={this.state.name} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Description </label>
              <textarea className="formTextArea" onChange={this.handleInput("description")} placeholder="Description" value={this.state.description} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Sort Order </label>
              <input className="formInput" onChange={this.handleInput("sortOrder")} type="number" placeholder="Sort Order" value={this.state.sortOrder} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Cover </label>
              <div className="galleryPhotoDiv">
                <img className="settingsAvatar settingsGalleryCover" src={this.state.cover_src} />
                <label className="settingsChangePhotoButton coverUploadButton">
                  <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg" />
                  Add Photo
                </label>
              </div>
            </div>
          </div>
          <button onClick={this.createVirtualSuiteType} className="settingsButton companyButton">Create Type</button>
        </div>
      </div>
    );
  }
}