import React, { useState, useRef, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import Slider from "react-slick";

import {
  ContractMediaWrapper,
  SliderUpperContainer,
  SliderWrapper,
  FeatureWrapper,
  FeatureItemsRow,
  FeatureItem,
  Dot,
  FeatureImage,
  FeatureHeading,
  FeatureDescription,
} from './styles';
import { SlideOne, SlideTwo, SlideThree, SlideFour } from './slides';
import { SlideOneVS, SlideTwoVS, SlideThreeVS, SlideFourVS  } from './virtual_staging_slides';
import ContractMediaHeader from '../ContractMediaHeader';

const handleDragStart = (e) => e.preventDefault();

const ContractMediaComponent = (props) => {
  const { imagesList, data, inViewport, forwardedRef, ...headerProps } = props;
  const {isVirtualStaging} = headerProps

  const [activeIndex, setActiveIndex] = useState(0);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  let slider1 = [];
  let slider2 = [];

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const onSlideChange = (prev, next) => {
    setActiveIndex(next);
  };

  const listItems = imagesList.map((item) => {
    return (
      <SectionSlideItem
        inViewport={inViewport}
        key={item.id}
        item={item}
        isVirtualStaging={isVirtualStaging}
        activeIndex={activeIndex}
      />
    );
  });

  const bottomListItems = data.map((item, index) => {
    return (
      <BottomFeatureWrapper
        key={index}
        index={index + 1}
        activeItem={activeIndex}
        item={item}
      />
    );
  });

  return (
    <ContractMediaWrapper ref={forwardedRef} className="contractMediaWrapper">
      <ContractMediaHeader {...headerProps} />
      <SliderUpperContainer>
        <SliderWrapper>
          <Slider
            ref={slider => (slider1 = slider)}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            dots={true}
            pauseOnDotsHover={true}
            pauseOnHover={true}
            autoplaySpeed={4000}
            speed={500}
            autoplay={true}
            infinite={true}
            asNavFor={nav2}
            lazyLoad={"progressive"}
            beforeChange={onSlideChange}
          >
            {listItems}
          </Slider>
        </SliderWrapper>
        <FeatureWrapper>
          <FeatureItemsRow
            onMouseEnter={() => slider1.slickPause()}
            onMouseLeave={() => slider1.slickPlay()}
          >
            <Slider
              ref={slider => (slider2 = slider)}
              slidesToShow={4}
              slidesToScroll={1}
              asNavFor={nav1}
              pauseOnHover={true}
              dots={false}
              arrows={false}
              focusOnSelect={true}
              infinite={true}
              autoplaySpeed={4000}
              speed={500}
              autoplay={false}
              centerMode={false}
              variableWidth={true}
              lazyLoad={'progressive'}
              // beforeChange={onSlideChange}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                  }
                },
              ]}
            >
              {bottomListItems}
            </Slider>
          </FeatureItemsRow>
        </FeatureWrapper>
      </SliderUpperContainer>
    </ContractMediaWrapper>
  );
};

const SectionSlideItem = (props) => {
  const { item, isVirtualStaging } = props;
  

  const renderSlide = () => {
    if (isVirtualStaging) {
      if (item.id === 0) {
        return <SlideOneVS {...props} handleDragStart={handleDragStart} />;
      }
      if (item.id === 1) {
        return <SlideTwoVS {...props} handleDragStart={handleDragStart} />;
      }
      if (item.id === 2) {
        return <SlideThreeVS {...props} handleDragStart={handleDragStart} />;
      }
      if (item.id === 3) {
        return <SlideFourVS {...props} handleDragStart={handleDragStart} />;
      }
    } else {
      if (item.id === 0) {
        return <SlideOne {...props} handleDragStart={handleDragStart} />;
      }
      if (item.id === 1) {
        return <SlideTwo {...props} handleDragStart={handleDragStart} />;
      }
      if (item.id === 2) {
        return <SlideThree {...props} handleDragStart={handleDragStart} />;
      }
      if (item.id === 3) {
        return <SlideFour {...props} handleDragStart={handleDragStart} />;
      }
    }
  };

  return renderSlide();
};

const BottomFeatureWrapper = (props) => {
  const { index, activeItem, item } = props;

  return (
    <FeatureItem
      showBorder={activeItem === index - 1}
      className={`dottedLine${index}`}
    >
      <FeatureImage src={item.icon} alt={''} />
      <FeatureHeading>{item.heading}</FeatureHeading>
      <FeatureDescription>{item.content}</FeatureDescription>
    </FeatureItem>
  );
};

const ContractMedia = handleViewport(ContractMediaComponent);

export default ContractMedia;

/* 
import React, { useState, useRef, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import {
  ContractMediaWrapper,
  SliderUpperContainer,
  SliderWrapper,
  FeatureWrapper,
  FeatureItemsRow,
  FeatureItem,
  Dot,
  FeatureImage,
  FeatureHeading,
  FeatureDescription,
} from './styles';
import { SlideOne, SlideTwo, SlideThree, SlideFour } from './slides';
import ContractMediaHeader from '../ContractMediaHeader';

const handleDragStart = (e) => e.preventDefault();

const ContractMediaComponent = (props) => {
  const { imagesList, data, inViewport, forwardedRef } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (inViewport) {
      setTimeout(() => {
        sliderRef.current.slideTo(1);
        setActiveIndex(1);
      }, 5000);
    }
  }, [inViewport]);

  const onSlideChange = (event) => {
    if (event.item < 3) {
      setActiveIndex(event.item + 1);
    }
  };

  const onSlideChanged = (event) => {
    if (event.item === 3) {
      setTimeout(() => {
        setActiveIndex(0);
      }, 5000);
    }
  };

  const listItems = imagesList.map((item) => {
    return (
      <SectionSlideItem
        inViewport={inViewport}
        key={item.id}
        item={item}
        activeIndex={activeIndex}
      />
    );
  });

  const bottomListItems = data.map((item, index) => {
    return (
      <BottomFeatureWrapper
        key={index}
        index={index + 1}
        activeItem={activeIndex}
        item={item}
      />
    );
  });

  const customDots = Array.from({ length: 4 }).map((dot, index) => {
    return (
      <Dot
        key={index}
        isActive={index === activeIndex}
      />
    );
  });

  return (
    <ContractMediaWrapper ref={forwardedRef} className="contractMediaWrapper">
      <ContractMediaHeader />
      <SliderUpperContainer>
        <SliderWrapper>
          <AliceCarousel
            ref={sliderRef}
            activeIndex={activeIndex}
            autoPlayStrategy='none'
            touchTracking={false}
            mouseTracking={false}
            animationDuration={1000}
            autoPlayInterval={5000}
            autoPlay={inViewport}
            items={listItems}
            autoPlayControls={false}
            disableButtonsControls={true}
            disableDotsControls={true}
            onSlideChange={onSlideChange}
            onSlideChanged={onSlideChanged}
          />
          <ul className="alice-carousel__dots">
            {customDots}
          </ul>
        </SliderWrapper>
        <FeatureWrapper>
          <FeatureItemsRow>
            <AliceCarousel
              activeIndex={activeIndex}
              autoWidth={true}
              disableDotsControls={true}
              disableButtonsControls={true}
              mouseTracking={false}
              touchTracking={false}
              items={bottomListItems}
              paddingLeft={20}
            />
          </FeatureItemsRow>
        </FeatureWrapper>
      </SliderUpperContainer>
    </ContractMediaWrapper>
  );
};

const SectionSlideItem = (props) => {
  const { item } = props;

  const renderSlide = () => {
    if (item.id === 0) {
      return <SlideOne {...props} handleDragStart={handleDragStart} />;
    }
    if (item.id === 1) {
      return <SlideTwo {...props} handleDragStart={handleDragStart} />;
    }
    if (item.id === 2) {
      return <SlideThree {...props} handleDragStart={handleDragStart} />;
    }
    if (item.id === 3) {
      return <SlideFour {...props} handleDragStart={handleDragStart} />;
    }
  };

  return renderSlide();
};

const BottomFeatureWrapper = (props) => {
  const { index, activeItem, item } = props;

  return (
    <FeatureItem
      showBorder={activeItem === index - 1}
      className={`dottedLine${index}`}
    >
      <FeatureImage src={item.icon} alt={''} />
      <FeatureHeading>{item.heading}</FeatureHeading>
      <FeatureDescription>{item.content}</FeatureDescription>
    </FeatureItem>
  );
};

const ContractMedia = handleViewport(ContractMediaComponent);

export default ContractMedia;
*/
