import { connect } from "react-redux";
import ProjectsIndex from "./projects_index";
import React from "react";
import { openModal, closeModal } from "../actions/modal_actions";
import { withRouter } from "react-router-dom"

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  closeModal: () => { dispatch(closeModal()) }
});

export default withRouter(connect(msp, mdp)(ProjectsIndex));