import React from "react"
import {withRouter} from "react-router-dom"
import {createBlog} from '../../util/blog_api_utils';
import BlogForm from "./blog_form";

class CreateBlog extends React.Component {

  componentDidMount() {
    if (!this.props.currentUser.roles.admin) {
      this.props.history.push("/")
      return ""
    }
  }

  render() {
    return <BlogForm onSubmit={createBlog} title={"Create Blog"} />
  }
}

export default withRouter(CreateBlog)