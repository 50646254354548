import { connect } from "react-redux";
import CreatePromoModal from "./create_promo_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { closeModal } from "../../../actions/modal_actions";
import { fetchFirms, createPromo } from "../../../actions/settings_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  services: state.entities.services,
});

const mdp = (dispatch) => ({
  createPromo: (promo) => dispatch(createPromo(promo)),
  fetchFirms: () => dispatch(fetchFirms()),
  closeModal: () => { dispatch(closeModal()) },
});

export default withRouter(connect(msp, mdp)(CreatePromoModal));