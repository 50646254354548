import React from "react";
import {largeServiceIconSrc} from "../../../util/service_util";

const Services = ({selectedServices, setSelectedServices, services}) => {

  const isSelected = (service) => {
    return selectedServices.includes(service)
  }

  const onToggleSelect = (e, service) => {
    e.preventDefault()

    if (isSelected(service)) {
      setSelectedServices(selectedServices.filter(s => s.id !== service.id))
    } else {
      setSelectedServices([...selectedServices, service])
    }
  }

  return <>
    <h3 className="h3React">Welcome to Modern Angles</h3>
    <div className="description">Modern Angles works with real estate professionals to book photography projects on
      your schedule.
    </div>
    <div className="description">Which services would you like to apply for?</div>

    <div className="servicesList">
      {services.length > 0 && services.map(service => {
        return <div className="serviceItem serviceItemOnboarding" key={`service-${service.id}`} onClick={e => onToggleSelect(e, service)}
                    id={isSelected(service) ? 'serviceItemSelected' : ''}>
          <div className="iconCreateProjectDiv">
            <img className="iconImageCreateProject" src={largeServiceIconSrc(service.slug)}/>
          </div>

          <div className="serviceItemTitle">
            {service.title}
          </div>
        </div>
      })}

      {services.length === 0 && <div className="d-flex justify-content-center w-100"><div className="spinner-border spinner"/></div>}
    </div>
  </>
}

export default Services