import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import Map from "../map";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner-transparent.gif";


class MapDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="mapDashboardDiv">
        <div className="mapDashboardcontents">
          <div className="dashboardCategoryHeading">Properties Map</div>
          <div className="dashboardCategorySubheading">This is an overview map of your recent photoshoots</div>
          {!this.props.full_map_results ? <div className="viewAllProjectsMap"
            onClick={this.props.getFullResults}>View All Photoshoots
          </div> : this.props.map_loading ? <div className="">
            <img className="mapLogoSpinner" src={LogoSpinner}></img>
          </div> : <div></div>}
          <Map projects={this.props.projects} full_map_results={this.props.full_map_results}/>
        </div>
      </div>
    );
  }
}

export default withRouter(MapDashboard)