import React from "react";
import { Route, Link } from "react-router-dom";
import PhotoItem from "./photo_item";
import VirtualStagingGalleryModal from "../virtual_staging/virtual_staging_gallery_modal";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { ReactComponent as TrashIcon } from "../../../../assets/images/icons/fi_trash.svg"


export default class PhotoSelectionComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      photosStream: 12,
      galleryModalOpen: false,
      currentPhoto: 0,
    }
    this.toggleSelected = this.toggleSelected.bind(this)
    this.streamMorePhotos = this.streamMorePhotos.bind(this)
    this.handler = this.handler.bind(this)
  }

  toggleSelected(photo) {
    let newState = {}
    let newSelectedState = {}
    if (this.props.selectedPhotos[photo.id]) {
      newState = Object.assign({}, this.props.selectedPhotos)
      newSelectedState = Object.assign({}, this.props.selectedPhotosWithInfo)
      delete newState[photo.id]
      delete newSelectedState[photo.id]
      this.props.changeParentState({ selectedPhotos: newState, selectedPhotosWithInfo: newSelectedState })
    } else {
      newState = Object.assign({}, this.props.selectedPhotos)
      newSelectedState = Object.assign({}, this.props.selectedPhotosWithInfo)
      newState[photo.id] = { media_id: photo.id }
      newSelectedState[photo.id] = photo
      this.props.changeParentState({ selectedPhotos: newState, selectedPhotosWithInfo: newSelectedState })
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  streamMorePhotos() {
    this.setState({ photosStream: this.state.photosStream + 12 })
  }

  render() {
    let streamButton = ""

    if (this.state.photosStream < Object.values(this.props.photos).length) {
      streamButton = <div onClick={this.streamMorePhotos} className="streamButton">
        <i className="fas fa-chevron-down" />
      </div>
    }

    let photoCards = Object.values(this.props.photos).slice(0, this.state.photosStream).map((photo, i) => {
      return <PhotoItem key={i} changeParentState={this.handler}
        changeGrandParentState={this.props.changeParentState}
        selected={this.props.selectedPhotos[photo.id] != null}
        toggleSelected={this.toggleSelected} photo={photo} upload={this.props.upload}
        homepage={this.props.homepage}
        index={i} />
    })

    if (this.props.loading){
      photoCards = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }
    let modal = ""

    if (this.state.galleryModalOpen) {
      modal = <VirtualStagingGalleryModal photos={this.props.photos} changeParentState={this.handler}
        currentPhoto={this.state.currentPhoto} />
    }

    let ordersInCart = ""
    let virtualStagingPhotoClass = "virtualStagingPhotoItem virtualStagingPhotoItemPrevious"
    let virtualSuitePhotoGridClass = "virtualStagingPhotosGrid virtualSuitePhotosGrid"

    if (this.props.homepage){
      virtualStagingPhotoClass = "virtualStagingPhotoItem virtualStagingPhotoItemPrevious virtualStagingPhotoItemHomepage"
      virtualSuitePhotoGridClass = "virtualStagingPhotosGrid"
    }
    

    if (this.props.detailed_orders.length > 0){
      ordersInCart = this.props.detailed_orders.map((order,i) => {
        return (
          <div>
            <div className="modalTitle">{order.category?.name} - {order.product?.name}</div>
            <div className={virtualSuitePhotoGridClass}>
              {Object.values(order.selected_photos).map((photo,j) => { 
                return (
                  <div className={virtualStagingPhotoClass}>
                    <img className="virtualStagingPhotoImage virtualStagingPhotoUploadImage" src={photo.url} />
                    <div className="zoomIcon" onClick={() => this.props.deleteOrderPhoto(i,j)}><TrashIcon /></div>
                  </div>
                )})
              }
           </div>
          </div>
        )})
    }
     

    return (
      <div id={this.props.upload === null ? "hidePropertyInputs" : "photoSelectionDiv"}>
        {modal}
        <div className="modalTitle">Category: {this.props.category?.name}</div>
        <div className="modalSubTitle2">Only choose photos for the selected category.</div>
        <div className="modalSubTitle2">You will have the option to add more categories before checkout.</div>
        <div className="modalDivider mt-4 mb-3" />

        <div className={virtualSuitePhotoGridClass}>
          {photoCards}
          {streamButton}
        </div>
       
        {ordersInCart ? <div className="headingTitle">Orders in cart</div> : ""}
        {ordersInCart}
      </div>
    )
  }
}