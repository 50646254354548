import { connect } from "react-redux";
import ForgotPasswordUpdate from "./forgot_password_update";
import React from "react";
import { withRouter } from "react-router-dom"

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
});

const mdp = (dispatch) => ({

});

export default withRouter(connect(msp, mdp)(ForgotPasswordUpdate));