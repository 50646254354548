import React from "react";
import { Route, Link } from "react-router-dom";
import {showToast} from "../../../actions/toast_actions";
import {connect} from "react-redux";


class ProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.product.name,
      cover: "",
      cover_src: this.props.product.cover
    }
    this.handleInput = this.handleInput.bind(this)
    this.updateProduct = this.updateProduct.bind(this)
    this.deleteProduct = this.deleteProduct.bind(this)
    this.changePhotoBack = this.changePhotoBack.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  updateProduct() {

    let formData = new FormData();

    formData.append("virtual_staging_category[virtual_staging_products_attributes][0][name]", this.state.name)
    if (this.state.cover != ""){
    formData.append("virtual_staging_category[virtual_staging_products_attributes][0][cover]", this.state.cover)
    }
    formData.append("virtual_staging_category[virtual_staging_products_attributes][0][id]", this.props.product.id)

    this.props.updateProducts(formData, this.props.category.id).then(() => {
      this.props.showToast("Product Successfully Updated")
    })
  }

  deleteProduct(){
    let formData = new FormData();

    formData.append("virtual_staging_category[virtual_staging_products_attributes][0][_destroy]", true)
    formData.append("virtual_staging_category[virtual_staging_products_attributes][0][id]", this.props.product.id)

    this.props.updateProducts(formData, this.props.category.id).then(() => {
      this.props.showToast("Product Successfully Deleted")
    })
  }

  changePhotoBack() {
    if (this.props.product.cover_src) {
      this.setState({
        cover: "",
        cover_src: this.props.product.cover_src
      })
    } else {
      this.setState({
        cover: "",
        cover_src: ""
      })
    }
  }

  handlePictureSelected(event) {
    let cover = event.target.files[0];
    let cover_src = URL.createObjectURL(cover);

    this.setState({
      cover: cover,
      cover_src: cover_src
    });
  }

  render() {

    let companyNameErrorClass = "";
    let agentMlsIdErrorClass = "";
    let companyAddressErrorClass = "";
    let companyCityErrorClass = "";
    let companyStateErrorClass = "";
    let companyZipErrorClass = "";


    let companyNameErrors = [];
    let agentMlsIdErrors = [];
    let companyAddressErrors = [];
    let companyCityErrors = [];
    let companyStateErrors = [];
    let companyZipErrors = [];

    return (
      <div className="settingsSubServicesItem">
        <div className="settingsServicesInput">
          <label id="formLabel"> Name </label>
          <input className="formInput" id={agentMlsIdErrorClass} onChange={this.handleInput("name")} type="string" placeholder="Name" value={this.state.name} />
          <ul className="errorUl">{agentMlsIdErrors}</ul>
        </div>
        <div className="settingsServicesInput">
          <label id="formLabel"> Cover </label>
          <div className="galleryPhotoDiv">
            <img className="settingsAvatar settingsGalleryCover" src={this.state.cover_src} />
            <label className="settingsChangePhotoButton coverUploadButton">
              <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg" />
                  Update Photo
              </label>
          </div>
        </div>
        <button onClick={this.updateProduct} className="settingsButton subServicesButton">Update Product</button>
        <button onClick={this.deleteProduct} className="settingsButton subServicesButton deleteButton">Delete Product</button>
      </div>
    );
  }
}

const msp = () => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(ProductItem);