import React, {useEffect, useRef, useState} from "react"

export const DownloadReferralsImagesZipButton = ({className, children}) => {
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState("")
  const linkRef = useRef()

  const onClick = async (e) => {
    if (url) return
    e.preventDefault()

    if (loading) return
    setLoading(true)

    const blob = await fetch('/referral-code-images-download').then(response => {
      return response.ok ? response.blob() : Promise.reject(response.status)
    })

    setLoading(false)
    setUrl(URL.createObjectURL(blob))
    setTimeout(() => linkRef.current.click())
  }

  useEffect(() => {
    return () => {
      if (url) URL.revokeObjectURL(url);
    }
  }, [])

  return <a className={`${className} ${loading ? 'disabled' : ''}`} href={url || '#'}
            onClick={onClick} download="referral-code-images.zip" ref={linkRef}>
    {children}
  </a>
}
