import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import facebookImg from '../../../../assets/images/blog/facebook.png'
import twitterImg from '../../../../assets/images/blog/twitter.png'
import linkedinImg from '../../../../assets/images/blog/linkedin.png'
import instagramImg from '../../../../assets/images/blog/instagram.png'
import copyImg from '../../../../assets/images/blog/copy.png'
import {blogTemplate} from "./blog_template";
import {BlogPostsSlider} from "./blog_posts_slider";
import BlogPostCard from "./blog_post_card";
import {fetchBlog, fetchBlogs} from "../../util/blog_api_utils";
import authorProfileImg from "../../../../assets/images/MA_Logo_New_2.svg";
import {Paragraph} from "./blocks/Paragraph";
import {Header} from "./blocks/Header";
import List from "./blocks/List";
import {Introduction} from "./blocks/introduction";
import {Conclusion} from "./blocks/Conclusion";
import {Quote} from "./blocks/Quote";
import Image from "./blocks/Image";
import {Raw} from "./blocks/Raw";
import RawWithChart from "./blocks/RawWithChart";
import {EmailForm} from "./blocks/EmailForm";
import {showToast} from "../../actions/toast_actions";
import {connect} from "react-redux";
import { LINKS } from "../../../../assets/images/contactForm";
import { Helmet } from 'react-helmet-async';

const BlogShow = (props) => {
  const [blog, setBlog] = useState()
  const [titles, setTitles] = useState([])
  const contentDiv = useRef()
  const {showToast} = props
  const [popularBlogs, setPopularBlogs] = useState([])

  useLayoutEffect(() => {
    if (blog) {
      const doc = contentDiv.current
      const htmlTitles = Array.from(doc.querySelectorAll('h1, h2, h3'))
        .filter(htmlTitle => !!htmlTitle.id)
        .map(htmlTitle => ({title: htmlTitle.textContent.trim(), id: htmlTitle.id}))

      setTitles(htmlTitles)
    }
  }, [blog])

  useEffect(() => {
    fetchBlogs(null, true).then(blogs => setPopularBlogs(blogs))
  }, [])

  const social = <div className="blog-author-social">
    <a href={LINKS.INSTAGRAM} target="_blank"><img src={instagramImg} /></a>
    <a href={LINKS.LINKEDIN} target="_blank"><img src={linkedinImg}/></a>
    <a href={LINKS.TWITTER} target="_blank"><img src={twitterImg}/></a>
    <a href={LINKS.FACEBOOK} target="_blank"><img src={facebookImg}/></a>
      </div>

  const onTitleClick = (e) => {
    document.querySelector(`[id="${e.target.value}"]`).scrollIntoView();
  }

  const onLinkTitleClick = (e) => {
    e.preventDefault()
    const hash = e.target.getAttribute("href") || "#"
    document.querySelector(`[id="${hash.substring(1)}"]`).scrollIntoView();
  }

  const copyLink = () => {
    navigator.clipboard.writeText(location.href).then(() => {
      setCopiedText('URL has been copied successfully');
    }, () => {
      setCopiedText('Could not copy URL');
    })
  }

  const setCopiedText = (text) => {
    props.showToast(text);
  }
  
  const popularBlogsFiltered = popularBlogs.filter(popular_blog => popular_blog.id != blog?.id)
  
  const blogPostCards = popularBlogsFiltered.map(popular_blog => (
    <BlogPostCard blog={popular_blog} key={`post-card-${popular_blog.id}`} />
  ));


  useEffect(() => {
    const blog_id = props.match.params.id

    if (blog_id !== "sample") {
      fetchBlog(blog_id).then(blog => {
        if (blog.errors){
          props.history.push("/blog")
        }else{
          setBlog(blog)
        }
      })
    } else {
      setBlog(blogTemplate)
    }
  }, [])

  if (blog) {
    let blog_date = new Date(blog.created_at)
    const author = {
      name: 'Modern Angles',
      description: `Published in ${blog.category}, ${ blog_date.toLocaleString('default', { month: 'short' })} ${ blog_date.getDate() }, ${ blog_date.getFullYear() }`,
      avatar: authorProfileImg
    }

    return <div className="blog">
      <Helmet>
        <title>{blog.title} | Modern Angles Real Estate Photography</title>
        <meta
          name="description"
          content={blog.description}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="blog-header blog-border-bottom">
        <div className="blog-header-image">
          <img src={blog.header_image}/>
        </div>
        <div className="blog-header-title-container blog-border-bottom">
          <div className="blog-header-title">{blog.title}</div>
          <div className="blog-header-headline">{blog.description}</div>
          <div className="blog-author">
            <div className="blog-author-avatar">
              <img src={author.avatar}/>
            </div>
            <div className="blog-author-name-container">
              <div className="blog-author-name">{author.name}</div>
              <div className="blog-author-description">{author.description}</div>
            </div>
          </div>
          {social}
        </div>
        <div className="blog-mobile-navigation">
          <select className="formInput" onChange={onTitleClick}>
            {titles.map((title, i) => {
              return <option key={`select-title-${i}`} value={title.id}>{title.title}</option>
            })}
          </select>
        </div>
      </div>
      <div className="blog-border-bottom">
        <div className="blog-content-container">
          <div className="blog-content-navigation-container">
            <div className="blog-content-navigation blog-border-bottom blog-border-top">
              <div className="blog-content-navigation-title">Table of contents</div>
              {titles.map((title, i) => {
                return <a href={`#${title.id}`} className="blog-content-navigation-item"
                          onClick={onLinkTitleClick}
                          key={`title-${i}`}>{title.title}</a>
              })}
            </div>
            {social}
          </div>
          <div className="blog-content">
            <div className="blog-content-body" ref={contentDiv}>
              {blog.content.map(block => {
                const Component = {
                  paragraph: Paragraph,
                  header: Header,
                  list: List,
                  introduction: Introduction,
                  conclusion: Conclusion,
                  quote: Quote,
                  image: Image,
                  raw: Raw,
                  rawChart: RawWithChart,
                  email: EmailForm
                }[block.type] || Paragraph

                return <Component block={block} key={block.id} showToast={showToast}/>
              })}
            </div>

            <div className="blog-content-share">
              <div className="blog-content-share-icons">
                <div onClick={copyLink} className="blog-content-share-copy-link">
                  <img src={copyImg}/>
                  Share
                </div>
                <a href={LINKS.INSTAGRAM} target="_blank"><img src={instagramImg} /></a>
                <a href={LINKS.LINKEDIN} target="_blank"><img src={linkedinImg}/></a>
                <a href={LINKS.TWITTER} target="_blank"><img src={twitterImg}/></a>
                <a href={LINKS.FACEBOOK} target="_blank"><img src={facebookImg}/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="popular-posts">
        <div className="popular-posts-container">
          <div className="popular-posts-container-title-container">
            <div>
              <div className="popular-posts-container-title-top">Latest posts</div>
              <div className="popular-posts-container-title">Popular Posts</div>
              <div className="popular-posts-container-title-sub">Interviews, tips, guides, industry best practices, and
                news.
              </div>
            </div>
            <div>
              <button className="popular-posts-container-view-all" onClick={() => props.history.push("/blog")}>View all posts</button>
            </div>
          </div>
        </div>
        <BlogPostsSlider slides={blogPostCards}/>
      </div>
    </div>
  } else {
    return <div></div>
  }
}

const msp = (state) => ({})

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(BlogShow));