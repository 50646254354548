import * as FAQAPIUtil from "../util/faq_api_utils";

export const RECEIVE_ALL_FAQS = 'RECEIVE_ALL_FAQS';

export const receiveFaqs = (faqs) => ({
  type: RECEIVE_ALL_FAQS,
  faqs,
});

export const fetchFAQs = () => (dispatch) => {
  return FAQAPIUtil.fetchFAQs().then((faqs) => dispatch(receiveFaqs(faqs)));
};
