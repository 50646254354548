import React from "react";
import { withRouter } from "react-router-dom";
import { fetchAllUsers } from "../../../util/users_api_util";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import SettingsGalleryContainer from "../gallery/settings_gallery_container";
import SettingsTimeSlotsContainer from "../times/settings_time_slots_container";
import SettingsServicePayoutsContainer from "../service_payouts/settings_service_payouts_container";
import SettingsContractorInfoContainer from "./settings_contractor_info_container";
import Select from 'react-select'



class SettingsContractors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contractors: [],
      user_id: null,
      loading: false,
      loading_contractors: false
    }

    this.handleInput = this.handleInput.bind(this)
    this.handler = this.handler.bind(this)
    this.changeContractor = this.changeContractor.bind(this)
    this.changeComponent = this.changeComponent.bind(this)
  }

  componentDidMount() {
      this.setState({loading_contractors: true})
      fetchAllUsers("contractor", null, null, null, 100000).then(contractors => {
        this.setState({ contractors: contractors, loading_contractors: false })
      })
   }

  changeContractor(e) {
    if (e.value === "") {
      this.setState({ user_id: null})
    } else {
      this.setState({loading: true, user_id: e.value })
      setTimeout(() => {
      this.setState({loading: false })
      }, 100)
    }
  }

  changeComponent(e){
    e.preventDefault()
    if (e.target.value === "") {
      this.setState({ selected_component: "" })
    } else {
      this.setState({ selected_component: e.target.value })
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  components(key){
    const components = {
      "gallery": < SettingsGalleryContainer user_id={this.state.user_id}/>,
      "generalInfo": <SettingsContractorInfoContainer user_id={this.state.user_id} />,
      "timeslots": <SettingsTimeSlotsContainer user_id={this.state.user_id} />,
      "service_payouts": <SettingsServicePayoutsContainer user_id={this.state.user_id} />
    }

    return components[key]
  }

  render() {

     const possible_components = [{name: "Gallery", value: "gallery"}, {name: "General Info", value: "generalInfo"}, {name: "Timeslots", value: "timeslots"}, {name: "Service Payouts", value: "service_payouts"}]

     let component = ""

     if (this.state.user_id && this.state.selected_component && !this.state.loading){
       component = this.components(this.state.selected_component)
     }

     const contractorOptions = [{value: "", label: "Choose Contractor"}]

    Object.values(this.state.contractors).forEach(contractor => {
      contractorOptions.push({ value: contractor.id, label: `${contractor.firstName} ${contractor.lastName}`})
    })


    return (
      <div className="settingsComponentGroupingDiv">
        <div className="settingsCompany">
          <div className="settingsContractorComponentDiv">
            {this.props.currentUser.roles.admin ? <Select className="formInput contractorSelect contractorSettingsAdmin" isLoading={this.state.loading_contractors} defaultValue={{ value: "", label: "Choose Contractor" }} options={contractorOptions} onChange={this.changeContractor} /> : ""}

            {this.props.currentUser.roles.admin ? <select className="formInput contractorSelect" value={this.state.selected_component} onChange={this.changeComponent}>
              <option value="">Component</option>
              {
                possible_components.map(component => {
                  return <option value={component.value}>{component.name}</option>
                })
              }
            </select> : ""}
          </div>
          {component}
        </div>
      </div>
    );
  }
  }

export default withRouter(SettingsContractors);