import { read } from "@popperjs/core";
import {formatDateIso} from "./date_util";

export const fetchProject = (project_id, service_id = null, media_code = null, access_code = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_project",
    data: { id: project_id, service_id: service_id, media_code: media_code, access_code: access_code }
  })
);

export const fetchProjectMedias = (project_id, service_id = null, media_code = null, access_code = null, ignore_variants = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_project_medias",
    data: { id: project_id, service_id: service_id, media_code: media_code, access_code: access_code, ignore_variants: ignore_variants}
  })
);

export const fetchProjectVideo = (media_id = null, slug = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_project_video",
    data: { media_id: media_id, slug: slug }
  })
);

export const fetchPropertyTourProjectMedias = (project_id, service_id = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_property_tour_project_medias",
    data: { id: project_id, service_id: service_id}
  })
);

export const fetchPropertyTourProject = (project_id, service_id = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_property_tour_project",
    data: { id: project_id, service_id: service_id }
  })
);

export const fetchUserProjects = (user_id, search = null, projectType = null, full_results = false, completed_stream_amount = 1, upcoming_stream_amount = 1, ready_stream_amount = 1) => (
  $.ajax({
    type: "GET",
    url: "/fetch_user_projects",
    data: { user_id: user_id, project_type: projectType, search: search, full_results: full_results, completed_stream_amount: completed_stream_amount, upcoming_stream_amount: upcoming_stream_amount, ready_stream_amount: ready_stream_amount }
  })
);

export const fetchCompletedProjects = (user_id, search = null, completed_stream_amount = 1) => (
  $.ajax({
    type: "GET",
    url: "/fetch_user_completed_projects",
    data: { user_id: user_id, search: search, completed_stream_amount: completed_stream_amount }
  })
);

export const fetchUpcomingProjects = (user_id, search = null, upcoming_stream_amount = 1) => (
  $.ajax({
    type: "GET",
    url: "/fetch_user_upcoming_projects",
    data: { user_id: user_id, search: search, upcoming_stream_amount: upcoming_stream_amount }
  })
);

export const fetchReadyProjects = (user_id, search = null, ready_stream_amount = 1, in_production = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_user_ready_projects",
    data: { user_id: user_id, search: search, ready_stream_amount: ready_stream_amount, in_production: in_production }
  })
);



// export const fetchUserProjectsDashboard = (user_id) => (
//   $.ajax({
//     type: "GET",
//     url: "/fetch_user_projects_dashboard",
//     data: {user_id: user_id}
//   })
// );

export const fetchUserProjectsDashboard = (user_id, { signal }) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    signal, // Pass the AbortController's signal to the request
  };

  const headers = new Headers(requestOptions.headers);

  if (signal.aborted) {
    console.log('Signal is aborted. Appending custom header.');
    headers.append('Abort-Control-Signal', 'aborted');
  }

  requestOptions.headers = headers;


  const url = `/fetch_user_projects_dashboard?user_id=${user_id}`;

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      return data;
    });
};
export const fetchUserProjectsMap = (user_id, full_results = null, { signal }) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    signal, // Pass the AbortController's signal to the request
  };

  const headers = new Headers(requestOptions.headers);
  if (signal.aborted) {
    headers.append('Abort-Control-Signal', 'aborted');
  }

  requestOptions.headers = headers;

  const url = `/fetch_user_projects_map?user_id=${user_id}`;

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      return data;
    });
};

export const fetchUserProjectsMapNonAbort = (user_id, full_results = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_user_projects_map",
    data: { user_id: user_id, full_results: full_results}
  })
);


export const fetchBinaryData = (media_ids, project_id = null, access_code = null) => (
  $.ajax({
    type: "GET",
    url: "/media_uploads/binary_data",
    data: { media_ids: media_ids, project_id: project_id, access_code: access_code }
  })
);

// export const fetchAllProjects = () => (
//   $.ajax({
//     type: "GET",
//     url: "/fetch_all_projects",
//   })
// );

export const fetchTimes = (time_info) => {
  let date = time_info.date
  if (date) date = formatDateIso(date)

  return $.ajax({
    type: "GET",
    url: "/contractor_availability",
    data: {...time_info, date}
  })
}

export const submitOrder = (project_info) => (
  $.ajax({type: "POST",
  beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
  url: "/projects",
  data: {project: project_info}
  })
);

export const updateProject = (project_info, project_id, destroy = false) => (
  $.ajax({
    type: "PATCH",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: `/projects/${project_id}`,
    data: { project: project_info, destroy: destroy}
  })
);

export const uploadPhotos = (formData) => (
  $.ajax({
    type: "POST",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: "/media_uploads",
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const deleteMedia = (medias, access_code = null, project_id = null) => (
  $.ajax({
    type: "DELETE",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: '/batch_destroy',
    data: {media_ids: medias, access_code: access_code, media_upload: {project_id: project_id} }
  })
);

export const deleteProject = (project_id, refund_type) => (
  $.ajax({
    type: "GET",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: '/cancel_project',
    data: { id: project_id, canceled_for: refund_type}
  })
);

export const cancelAppointments = (project_id, cancel_info) => (
  $.ajax({
    type: "POST",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: '/cancel_contractor',
    data: { id: project_id, cancellations: cancel_info }
  })
);

export const updatePropertyTour = (project_info) => (
  $.ajax({
    type: "PATCH",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: `/update_project_info`,
    data: project_info,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const setReviewShown = (project_info) => (
  $.ajax({
    type: "PATCH",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: `/update_single_property`,
    data: project_info
  })
);


export const updateProjectDetails = (project_info, project_id) => (
  $.ajax({
    type: "PATCH",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: `/update_project_info`,
    data: project_info
  })
);

export const sortMedia = (medias) => (
  $.ajax({
    type: "POST",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: '/media_uploads/batch_sort',
    data: { sort_order: medias }
  })
);


export const toggleHiddenMedia = (medias) => (
  $.ajax({
    type: "POST",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: '/media_uploads/toggle_hidden',
    data: { media_ids: medias }
  })
);

export const submitReview = (review) => (
  $.ajax({
    type: "POST",
    url: '/ratings',
    data: { rating: review }
  })
);

export const updateReview = (review, review_id) => (
  $.ajax({
    type: "PATCH",
    url: `/ratings/${review_id}`,
    data: { rating: review }
  })
);

export const removeReview = (review_id) => (
  $.ajax({
    type: "DELETE",
    url: `/ratings/${review_id}`,
  })
);

export const notifyProjectUpdated = (project_id) => {
  $.ajax({
    url: '/notify_project_update',
    data: { id: project_id}
  })
};

export const completeProject = (project_id) => (
  $.ajax({
    type: "POST",
    url: '/complete_project',
    data: { id: project_id }
  })
);

export const completeProjectIfCompleted = (project_id) => (
  $.ajax({
    type: "POST",
    url: '/complete_project',
    data: { id: project_id }
  })
);

export const fetchProjectPhotos = (project_id) => (
  $.ajax({
    type: "GET",
    url: "/fetch_project_photos",
    data: { id: project_id }
  })
);

export const fetchUnavailability = (appointments, timezone, street) => (
  $.ajax({
    type: "POST",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: "/contractor_unavailable",
    data: { appointments: appointments, timezone: timezone, street: street }
  })
);

export const uploadExternalPhotos = (projectId, formData) => (
  $.ajax({
    type: "POST",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: `/projects/${projectId}/external_photos`,
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const removeExternalPhotos = (projectId, externalPhotosIds) => (
  $.ajax({
    type: "PATCH",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: `/projects/${projectId}/external_photos/remove`,
    data: {external_photos_ids: externalPhotosIds},
  })
);