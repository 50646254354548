import * as APIUtil from "../util/settings_api_util"

export const RECEIVE_SESSION_ERRORS = "RECEIVE_SESSION_ERRORS";
export const RECEIVE_USER = "RECEIVE_USER";

export const receiveUser = (user) => ({
  type: RECEIVE_USER,
  user
});

export const receiveSessionErrors = (errors) => ({
  type: RECEIVE_SESSION_ERRORS,
  errors
});

export const updateBlockedTimes = (blockedTimes, user_id) => dispatch => {
  return APIUtil.updateBlockedTimes(blockedTimes, user_id)
}

export const updateProfile = (user, user_id) => dispatch => {
  return APIUtil.updateProfile(user, user_id).then(
    user => dispatch(receiveUser(user)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  );
};

export const updateProfileWithoutPhoto = (user, user_id) => dispatch => {
  return APIUtil.updateProfileWithoutPhoto(user, user_id).then(
    user => dispatch(receiveUser(user)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  );
};

export const fetchBlockedTimeSlots = (user_id) => dispatch => {
  return APIUtil.fetchBlockedTimeSlots(user_id)
}

export const googleCalendarIntegration = () => dispatch => {
  return APIUtil.googleCalendarIntegration()
}

export const updatePayment = (paymentInfo, paymentId) => dispatch => {
  return APIUtil.updatePayment(paymentInfo, paymentId)
}

export const createPayment = (paymentInfo) => dispatch => {
  return APIUtil.createPayment(paymentInfo)
}

export const fetchPaymentInfo = (user_id) => dispatch => {
  return APIUtil.fetchPaymentInfo(user_id)
}

export const fetchFirms = () => dispatch => {
  return APIUtil.fetchFirms()
}

export const createFirm = (firm) => dispatch => {
  return APIUtil.createFirm(firm)
}

export const updateFirm = (firm, firm_id) => dispatch => {
  return APIUtil.updateFirm(firm, firm_id)
}

export const deleteFirm = (firm_id) => dispatch => {
  return APIUtil.deleteFirm(firm_id)
}

export const fetchPayouts = (user_id) => dispatch => {
  return APIUtil.fetchPayouts(user_id)
}


export const createExternalAccount = (external_account) => dispatch => {
  return APIUtil.createExternalAccount(external_account)
}

export const fetchPromos = () => dispatch => {
  return APIUtil.fetchPromos()
}

export const createPromo = (promo) => dispatch => {
  return APIUtil.createPromo(promo)
}

export const updatePromo = (promo, promo_id) => dispatch => {
  return APIUtil.updatePromo(promo, promo_id)
}

export const deletePromo = (promo_id) => dispatch => {
  return APIUtil.deletePromo(promo_id)
}

export const fetchTimeSlots = (twilight) => dispatch => {
  return APIUtil.fetchTimeSlots(twilight)
}

export const updateTimeSlots = (time_slots, twilight) => dispatch => {
  return APIUtil.updateTimeSlots(time_slots, twilight)
}
