import React from "react";
import { Link, withRouter } from "react-router-dom";
import newProjectIcon from '../../../../assets/images/new-project-icon'
import homeIcon from '../../../../assets/images/homeIcon'
import projectIcon from '../../../../assets/images/Foldericon'
import invoiceIcon from '../../../../assets/images/invoiceIcon'
import photoIcon from '../../../../assets/images/photoIcon'
import referralsIcon from '../../../../assets/images/referralsIcon.svg'
import settingsIcon from '../../../../assets/images/settingsIcon'
import supportIcon from '../../../../assets/images/supportIcon'
import hamNavbarOpenIcon from '../../../../assets/images/Ham'
import closeIcon from "../../../../assets/images/closeIconBlue"
import UserAvatar from "../photographers/photograher_avatar"

class NavbarLoggedInMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown(){
    this.setState({dropdownOpen: !this.state.dropdownOpen})
  }


  render() {
    const { currentUser } = this.props;

    let homeClass = "";
    let projectClass = "";
    let photographerClass = "";
    let invoicesClass = "";
    let referralsClass = "";
    let navbarHomeIcon = "normalNavbarMobile";
    let navbarSettingsIcon = "normalNavbarMobile";
    let navbarProjectIcon = "normalNavbarMobile";
    let navbarInvoiceIcon = "normalNavbarMobile";
    let navbarPhotographerIcon = "normalNavbarMobile";
    let navbarReferralsIcon = "normalNavbarMobile";

    if (this.props.location.pathname === "/dashboard") {
      homeClass = "navActiveMobile"
      navbarHomeIcon = "navActiveIconMobile"
    } else if (this.props.location.pathname.split("/")[1] === "projects_index") {
      projectClass = "navActiveMobile"
      navbarProjectIcon = "navActiveIconMobile"
    } else if (this.props.location.pathname.split("/")[1] === "settings") {
      navbarSettingsIcon = "navActiveIconMobile"
    } else if (this.props.location.pathname.split("/")[1] === "photographers") {
      photographerClass = "navActiveMobile"
      navbarPhotographerIcon = "navActiveIconMobile"
    } else if (this.props.location.pathname.split("/")[1] === "invoices_index" || (this.props.location.pathname.split("/")[1] === "payouts" && currentUser.roles.contractor)) {
      invoicesClass = "navActiveMobile"
      navbarInvoiceIcon = "navActiveIconMobile"
    } else if (this.props.location.pathname.split("/")[1] === "referral") {
      referralsClass = "navActiveMobile"
      navbarReferralsIcon = "navActiveIconMobile"
    }

    let modal = <div className={`modal-background ${this.state.modalOpen ? '' : 'hidden'}`} onClick={() => this.setState({ modalOpen: false })}>
      <div className={`navMobileModalDiv ${this.state.modalOpen ? 'open' : ''}`}>
        <div className="closeIconMobileNavModal"><img className="closeIconMobileNavModalImage" onClick={() => this.setState({ modalOpen: false })} src={closeIcon} /></div>
        <Link className="avatarNavbarMobile" to="/settings">
          <UserAvatar user={currentUser} />
          <div className="navbarAvatarName">{currentUser.firstName} {currentUser.lastName}</div>
          <div className="navbarAvatarRole">{currentUser.account_credit?.amount ? `Account Credit: $${currentUser?.account_credit?.amount}` : ""}</div>
        </Link>
        <div className="navMobileModalGroup">
          <Link className="navLinkModalMobile" to="/projects_index">
            <div className="navLinkModalMobileText">Photoshoots</div>
          </Link>

          {currentUser.roles.admin && <Link className="navLinkModalMobile" to="/photographers">
            <div className="navLinkModalMobileText">Photographers</div>
          </Link>}

          {(currentUser.roles.client || currentUser.roles.broker || currentUser.roles.sub_broker) && <Link className="navLinkModalMobile" to="/invoices_index">
            <div className="navLinkModalMobileText">Invoices</div>
          </Link>}

          {(currentUser.roles.admin) && <Link className="navLinkModalMobile" to="/payouts">
            <div className="navLinkModalMobileText">Payouts</div>
          </Link>}
          
          {currentUser.roles.contractor && <Link className="navLinkModalMobile" to={`/photographers/${currentUser.id}`}>
            <div className="navLinkModalMobileText">My Profile</div>
          </Link>}

          {!currentUser.roles.admin && <Link className="navLinkModalMobile" to="/support">
            <div className="navLinkModalMobileText">Support</div>
          </Link>}
          <Link className="navLinkModalMobile" to="/settings">
            <div className="navLinkModalMobileText">Settings</div>
          </Link>
          <button onClick={() => this.props.logout()} className="settingsButton logoutSettingsButton logoutSettingsButtonMobile">Log Out</button>
        </div>
      </div>
    </div>

    let homeLink = <Link className="navLinkMobile" to="/dashboard">
      <div className={homeClass}><img className={navbarHomeIcon} src={homeIcon} /></div>
    </Link>

    let photographerLink = <Link className="navLinkMobile" to="/photographers">
      <div className={photographerClass}><img className={navbarPhotographerIcon} src={photoIcon} /></div>
    </Link>

    let referralLink = <Link className="navLinkMobile" to="/referral">
      <div className={referralsClass}><img className={navbarReferralsIcon} src={referralsIcon} /></div>
    </Link>

    let invoiceLink = this.props.currentUser.roles.contractor || this.props.currentUser.roles.sales_rep ? <Link className="navLinkMobile" to="/payouts">
        <div className={invoicesClass}><img className={navbarInvoiceIcon} src={invoiceIcon} /></div>
      </Link> : <Link className="navLinkMobile" to="/invoices_index">
        <div className={invoicesClass}><img className={navbarInvoiceIcon} src={invoiceIcon} /></div>
      </Link>

    let projectLink = <Link className="navLinkMobile" to="/projects_index">
      <div className={projectClass}><img className={navbarProjectIcon} src={projectIcon} /></div>
    </Link>


    if (this.props.currentUser.roles.post_production){
      homeLink = ""
      photographerLink = ""
      invoiceLink = ""
      referralLink = ""
    }

    if (this.props.currentUser.roles.sales_rep){
      homeLink = ""
      photographerLink = ""
      projectLink = ""
    }

    if (this.props.currentUser.roles.contractor){
      // referralLink = ""
    }

    return (
    <div>
      {modal}
      <div className="react-navbar-mobile-container">
        <nav className="react-navbar-mobile">
          <div className="navIconGroupMobile">
            {homeLink}
            {projectLink}
            {referralLink}
            {(currentUser.roles.client || currentUser.roles.broker || currentUser.roles.sub_broker) && photographerLink}
            {(currentUser.roles.admin || currentUser.roles.contractor || currentUser.roles.sales_rep) && invoiceLink}
            <div className="navLinkMobile" onClick={() => this.setState({modalOpen: true})}>
                <div className=""><img className={navbarSettingsIcon} src={hamNavbarOpenIcon} /></div>
            </div>
        </div>
        </nav>
      </div>
    </div>
    )
  }
}

export default withRouter(NavbarLoggedInMobile);