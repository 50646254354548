import React from 'react'
import photographyImg from "../../../../../assets/images/landing/exploreServices/photography.png"
import videoImg from "../../../../../assets/images/landing/exploreServices/video.png"
import floorPlansImg from "../../../../../assets/images/landing/exploreServices/floor_plans.png"
import {Link} from "react-router-dom";
import {useInView} from "react-intersection-observer";
import FadeInAnimation from "../../home_page/homeComponents/FadeInAnimation";

const services = [
  {
    title: 'Photography',
    description: 'We’ve developed a method that ensures all shots have balanced lighting and crystal-clear window views.',
    list: [
      'Interior & Exterior',
      'Twilight',
      'Drone'
    ],
    img: photographyImg,
    url: '/photography'
  },
  {
    title: 'Video',
    description: 'Did you know listings with Video content increase online views & interest by nearly 400%?',
    list: [
      'Cinematic Walkthrough',
      'Instagram Vertical Reels',
      'Drone/Aerial Footage'
    ],
    img: videoImg,
    url: '/video'
  },
  {
    title: 'Floor Plan & Staging',
    description: 'Floor Plans & Virtual Staging helps potential buyers envision a space with various design possibilities.',
    list: [
      '2D Floor Plans',
      '3D Floor Plans',
      'Interactive Tours',
      'Virtual Staging',
      '3D Rendering'
    ],
    img: floorPlansImg,
    url: '/floor-plans'
  }
]

export const ExploreServices = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const groupByCount = (items, count) => {
    const res = []

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const group = Math.floor(i / count)
      if (!res[group]) res[group] = []
      res[group].push(item)
    }

    return res
  }

  return <div className="professional-bundles-wrapper" ref={ref}>
    <FadeInAnimation
      duration={0.4}
      delayOrder={0}
      yOffset={-20}
      inView={inView}>
      <div className="professional-bundles-wrapper-list">
        {services.map((service, i) => (
          <div className="professional-bundles-wrapper-card" key={`explore-service-${i}`}>
            <img src={service.img} className="explore-service-img"/>

            <div className="professional-bundles-wrapper-card-container">
              <div className="professional-bundles-wrapper-card-title">
                {service.title}
              </div>

              <div className="professional-bundles-wrapper-card-subtitle">
                {service.description}
              </div>
            </div>

            <div className="professional-bundles-wrapper-card-divider"/>

            <div
              className="professional-bundles-wrapper-card-container justify-content-between d-flex flex-column flex-fill">

              <div className="professional-bundles-wrapper-card-list-container">
                {groupByCount(service.list, 3).map((items, j) => (
                  <ul className="professional-bundles-wrapper-card-list orange-icons"
                      key={`explore-services-${i}-list-${j}`}>
                    {items.map((item, k) => (
                      <li key={`explore-services-${i}-sublist-${j}-${k}`} className="bold">
                        {item}
                      </li>
                    ))}
                  </ul>
                ))}
              </div>

              <Link className="projectEditButton professional-bundles-wrapper-card-button" to={service.url}>
                Learn More
              </Link>
            </div>

          </div>
        ))}
      </div>
    </FadeInAnimation>
  </div>
}