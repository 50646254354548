import React from "react";
import { Route, Link } from "react-router-dom";


export default class SettingsCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: this.props.currentUser.companyName || "",
      agentMlsId: this.props.currentUser.agentMlsId || "",
      companyAddress: this.props.currentUser.companyAddress?.street1 || "",
      companyCity: this.props.currentUser.companyAddress?.city || "",
      companyState: this.props.currentUser.companyAddress?.region || "",
      companyZip: this.props.currentUser.companyAddress?.zip_code || ""
    }
    this.handleInput = this.handleInput.bind(this)
    this.updateCompany = this.updateCompany.bind(this)
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }


  updateCompany() {
      let formData = new FormData();
      formData.append("user[agent_mls_id]", this.state.agentMlsId);
      formData.append("user[company_attributes][company_name]", this.state.companyName);
      formData.append("user[company_attributes][id]", this.props.currentUser.companyId)
      formData.append("user[company_attributes][address_attributes][id]", this.props.currentUser.companyAddress.id);
      formData.append("user[company_attributes][address_attributes][street1]", this.state.companyAddress);
      formData.append("user[company_attributes][address_attributes][city]", this.state.companyCity);
      formData.append("user[company_attributes][address_attributes][region]", this.state.companyState);
      formData.append("user[company_attributes][address_attributes][zip_code]", this.state.companyZip);

      this.props.updateProfile(formData, this.props.currentUser.id).then(() => {
        if (this.props.errors.length === 0) {
          this.props.showToast("Account successfully updated")
        }
      })
  }



  render() {


    let companyNameErrorClass = "";
    let agentMlsIdErrorClass = "";
    let companyAddressErrorClass = "";
    let companyCityErrorClass = "";
    let companyStateErrorClass = "";
    let companyZipErrorClass = "";


    let companyNameErrors = [];
    let agentMlsIdErrors = [];
    let companyAddressErrors = [];
    let companyCityErrors = [];
    let companyStateErrors = [];
    let companyZipErrors = [];



    return (
      <div className="settingsComponentGroupingDiv">
        <div className="settingsCompany">
          <div className="settingsComponentHeading">Company settings</div>
          <div className="settingsCategoryGroup1">
            <div className="inputGrouping">
              <div className="settingsCompanyInput">
                <label id="formLabel"> Company name </label>
                <input className="formInput" id={companyNameErrorClass} onChange={this.handleInput("companyName")} type="string" placeholder="Company Name" value={this.state.companyName} />
                <ul className="errorUl">{companyNameErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
                <label id="formLabel"> Agent MLS ID </label>
                <input className="formInput" id={agentMlsIdErrorClass} onChange={this.handleInput("agentMlsId")} type="string" placeholder="Agent MLS Id" value={this.state.agentMlsId} />
                <ul className="errorUl">{agentMlsIdErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
              <label id="formLabel"> Company address</label>
              <input className="formInput" id={companyAddressErrorClass} onChange={this.handleInput("companyAddress")} type="string" placeholder="Street Address" value={this.state.companyAddress} />
              <ul className="errorUl">{companyAddressErrors}</ul>
          </div>
          </div>
          <div className="inputGrouping">
              <div className="settingsCompanyInput">
              <label id="formLabel"> City </label>
              <input className="formInput" id={companyCityErrorClass} onChange={this.handleInput("companyCity")} type="string" placeholder="City" value={this.state.companyCity} />
              <ul className="errorUl">{companyCityErrors}</ul>
            </div>
              <div className="settingsCompanyInput">
              <label id="formLabel"> State </label>
              <input className="formInput" id={companyStateErrorClass} onChange={this.handleInput("companyState")} type="string" placeholder="State" value={this.state.companyState} />
              <ul className="errorUl">{companyStateErrors}</ul>
            </div>
              <div className="settingsCompanyInput">
              <label id="formLabel"> Zipcode </label>
              <input className="formInput" id={companyZipErrorClass} onChange={this.handleInput("companyZip")} type="zipcode" placeholder="Zipcode" value={this.state.companyZip} />
              <ul className="errorUl">{companyZipErrors}</ul>
            </div>
          </div>
          </div>
          <button onClick={this.updateCompany} className="settingsButton companyButton">Save changes</button>
          </div>
      </div>
    );
  }
}