import React, {useState} from "react";
import {ReactComponent as ChevronDown} from '../../../../assets/images/icons/chevron-down.svg'
import {ReactComponent as ChevronUp} from '../../../../assets/images/icons/chevron-up.svg'
import {ReactComponent as TrashIcon} from '../../../../assets/images/icons/trash-icon.svg'
import {ABORTED, BACKEND_PROCESSING, FAILED, UPLOADING} from "../../util/media_uploader_util";
import {Spinner} from "reactstrap";

export const MediaUploadModalProjectRow = ({project, mediaUploads, onAbort}) => {
  const [show, setShow] = useState(true)

  const total = mediaUploads.reduce((res, u) => res + u.filesCount, 0)
  const uploadedTotal = mediaUploads.flatMap(u => u.uploadedFilesCount).reduce((res, c) => res + c, 0)
  const projectProgressPct = (mediaUploads.reduce((res, u) => res + u.totalProgress, 0) / mediaUploads.length).toFixed(1);

  const projectName = `${project.client.first_name} ${project.client.last_name[0]}`
  const toggleRow = (e) => {
    e.preventDefault()
    setShow(!show)
  }

  const onAbortClick = (e, mediaUpload) => {
    e.preventDefault()
    onAbort(mediaUpload.uploader)
  }

  const statusMessage = (upload) => {
    if (upload.status === ABORTED) return 'Upload has been aborted'
    if (upload.status === FAILED) return 'Upload has failed'
  }

  const progressClassName = (upload) => {
    if (upload.status === ABORTED || upload.status === FAILED) return 'media-upload-progress-bar-error'
  }

  const hasFailed = (upload) => {
    return [ABORTED, FAILED].includes(upload.status)
  }

  return <>
    <tr className="project-row">
      <td>
        <div className="media-upload-icon" onClick={toggleRow}>
          {show ? <ChevronDown/> : <ChevronUp/>}
        </div>
      </td>

      <td>
        <div className="media-upload-modal-row-project-info">
          <div className="media-upload-modal-row-project-name">
            <span>{projectName}</span>
            <span>({uploadedTotal}/{total})</span>
          </div>

          <div className="media-upload-modal-row-project-address">
            {project.address.formatted_address}
          </div>
        </div>
      </td>

      <td>
        <div className="media-upload-progress-bar">
          <div className="media-upload-progress-bar-progress" style={{width: `${projectProgressPct}%`}}/>
        </div>
      </td>

      <td className="media-upload-progress-pct">
        {projectProgressPct}%
      </td>

      <td/>
    </tr>

    {show && mediaUploads.map((upload, i) => (
      <tr className={`service-row ${hasFailed(upload) ? 'failed' : ''}`}
          key={`sub-upload-${upload.project.id}-${upload.service.id}-${i}`}>
        <td/>
        <td className="media-upload-modal-service-name">
          {upload.service.title} ({upload.filesCount})
        </td>

        <td>
          <div className={`media-upload-progress-bar ${progressClassName(upload)}`}>
            <div className="media-upload-progress-bar-progress"
                 style={{"width": `${upload.totalProgress.toFixed(1)}%`}}/>
          </div>

          {hasFailed(upload) ? <small className="mt-1 d-block text-danger">
            {statusMessage(upload)}
          </small> : ''}
        </td>

        <td className="media-upload-progress-pct">
          {upload.totalProgress.toFixed(1)}%
        </td>

        <td>
          {upload.status === UPLOADING && <a href="#" className="media-upload-icon"
                                             onClick={e => onAbortClick(e, upload)}>
            <TrashIcon/>
          </a>}

          {upload.status === BACKEND_PROCESSING && <Spinner size="sm"/>}
        </td>
      </tr>
    ))}
  </>
}