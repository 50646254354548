import React from "react"
import Map from "../map"
import UserAvatar from "../photographers/photograher_avatar"
import {formatDatetime} from "../../util/date_util";

class EditProjectContractorDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {modal: null}
  }

  navigate(address) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address.street1.split(" ").join("+")}%2C${address.city.split(" ").join("+")}%2C${address.region}`)
  }

  render() {
    const {project, currentUser, onCancel} = this.props

    const photographerPackages = Object.values(project?.packages || []).filter(pack => {
      return pack.contractor?.id === currentUser.id
    })

    const projectAddress = [
      project?.address?.street1,
      project?.address?.apartment_number,
      project?.address?.city,
      project?.address?.region,
      project?.address?.zip_code
    ].filter(v => !!v).join(', ')

    let total = 0
    let latest_appointment_time = ""
    let today = new Date();

    photographerPackages.forEach(pack => {
      if (latest_appointment_time === "" || new Date(pack.appointment_time) > latest_appointment_time){
        latest_appointment_time = new Date(pack.appointment_time)
      }
    })

    return <div>
      {this.state.modal}
      <div className="editProjectEditTitle">Photoshoot Details</div>
      <div className="editProjectEditSubtitle">
        Please give your client ample notice for all changes.
      </div>

      <div className="editProjectPhotographer">
        <div className="photographerCardHeader">
          <div className="photographerCardContainer">
            <div className="d-flex me-2">
              <UserAvatar user={project?.client}/>
              <div className="photographerCardInfo py-1">
                <div className="photographerCardName">{project?.client?.first_name} {project?.client?.last_name}</div>
                <div className="photographerServices">Client</div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button className="projectEditButton projectEditButtonPrimary me-2" onClick={() => window.location = `mailto:${project?.client?.email}`}>Message</button>
              <button className="projectEditButton projectEditButtonLight" onClick={onCancel}>
                Decline Job
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="editProjectJobInformation">
        <div className="editProjectJobInformationTitle">Job Information</div>
        <table>
          <tbody>
          <tr>
            <td className="editProjectJobInformationLabel">Address</td>
              <td className="editProjectJobInformationValue"><div className="addressLink" onClick={() => this.navigate(project?.address)}>{project?.address?.formatted_address ? `${project?.address?.apartment_number || ""} ${project?.address?.formatted_address}` : projectAddress}</div></td>
          </tr>
          <tr>
            <td className="editProjectJobInformationLabel">Time</td>
            <td className="editProjectJobInformationValue">
              {formatDatetime(photographerPackages[0]?.appointment_time, "MM/DD/YYYY, hh:mm A z", project?.client?.timezone)}
            </td>
          </tr>
          {latest_appointment_time > today.setHours(today.getHours() - 1) && 
          <tr>
            <td className="editProjectJobInformationLabel">Client Phone</td>
            <td className="editProjectJobInformationValue">{project?.client?.phone_number}</td>
          </tr>
          }
          {project?.notes ?
            <tr>
              <td className="editProjectJobInformationLabel">Project Notes</td>
              <td className="editProjectJobInformationValue">{project?.notes}</td>
            </tr>
            : ""}
          </tbody>
        </table>
      </div>

      <div className="divider" />

      <div className="editProjectMap"><Map projects={[project]}/></div>
      <div className="editProjectServices">
        <div className="editProjectServicesHeader">
          <div className="editProjectServicesTitle">Project Services</div>
        </div>

        <table className="editProjectServicesTable">
          <thead>
            <tr>
              <th />
              <th>Service</th>
              <th>Package</th>
              <th>Payout</th>
            </tr>
          </thead>
          <tbody>
          {photographerPackages.map((pack, i) => {
            return pack.sub_services.map((subService, j) => {
              const contractorSubServicesHash = currentUser.contractor_sub_services.reduce((acc, contractorSubService) => {
                acc[contractorSubService.sub_service_id] = contractorSubService;
                return acc;
              }, {});
              total += parseInt(contractorSubServicesHash[subService.id]?.payout || subService.contractor_payout)
              return <tr key={`sub-service-${i}-${subService.id}`}
                         className={i === photographerPackages.length - 1 && j === pack.sub_services.length - 1 ? 'border-bottom' : ''}>
                <td/>
                <td>{pack.service_title}</td>
                <td>{subService.title}</td>
                <td>${parseInt(contractorSubServicesHash[subService.id]?.payout || subService.contractor_payout).toFixed(2)}</td>
                <td className="row-filler"/>
              </tr>
            })
          })}
          <tr className="">
            <td />
            <td />
            <td className="font-bold">Total:</td>
            <td className="font-bold">${total.toFixed(2)}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  }
}

export default EditProjectContractorDetails