export const fetchDiscountCodes = () => (
  $.ajax({
    type: "GET",
    url: "/discount_codes",
  })
);

export const fetchDiscountCode = (id) => (
  $.ajax({
    type: "GET",
    url: `/discount_codes/${id}`
  })
);

export const createDiscountCode = (params) => (
  $.ajax({
    type: "POST",
    url: "/discount_codes",
    data: { discount_code: params }
  })
)

export const updateDiscountCode = (id, params) => (
  $.ajax({
    type: "PATCH",
    url: `/discount_codes/${id}`,
    data: { discount_code: params }
  })
)

export const deleteDiscountCode = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/discount_codes/${id}`
  })
)

export const applyCoupon = (code, user_id) => (
  $.ajax({
    type: "GET",
    url: `/apply_coupon`,
    data: {code: code, user_id: user_id}
  })
)

export const fetchReferrals = () => (
  $.ajax({
    type: "GET",
    url: `/referrals`,
  })
)

export const fetchReferralsAdmin = (full_results, searchClient = null, searchUserType = null) => (
  $.ajax({
    type: "GET",
    url: `/referrals_admin`,
    data: { full_results: full_results, search_client: searchClient, search_user_type: searchUserType }
  })
)