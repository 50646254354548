import styled from 'styled-components';

import { CONTRACT } from '../../../../../assets/images/landing';

export const ContractMediaWrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
  .container {
    @media only screen and (max-width: 991px) {
      max-width: 100%;
      margin: 0px;
      padding: 0px;
    }
    @media only screen and (max-width: 767px) {
      max-width: 95%;
      margin-left: 5%;
    }
  }
  @media only screen and (max-width: 991px) {
    padding-top: 75px;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 70px;
  }
`;

export const SliderUpperContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  .slick-dots {
    bottom: 23px;
    @media only screen and (max-width: 767px) {
      bottom: 560px;
    }
    @media only screen and (max-width: 600px) {
      bottom: 464px;
    }
    @media only screen and (max-width: 500px) {
      bottom: 390px;
    }
  }
  .slick-dots li.slick-active button:before {
    background: #839CCE;
    border-color: #839CCE;
    opacity: 1;
  }
  .slick-dots li button:before {
    content: '';
    width: 15px;
    height: 15px;
    background: #EAF1F6;
    border: 2px solid #EAF1F6;
    box-sizing: border-box;
    border-radius: 50%;
    opacity: 1;
  }
  /* .alice-carousel__dots {
    position: absolute;
    top: 1000px;
    left: 47%;
    z-index: 2000;
  } */

  /* @media only screen and (max-width: 1599px) {
    .alice-carousel__dots {
      top: 925px;
    }
  } */

  @media only screen and (max-width: 1399px) {
    width: 100%;
    margin-left: 0%;
    /* .alice-carousel__dots {
      top: 940px;
    } */
  }
  /* @media only screen and (max-width: 1100px) {
    .alice-carousel__dots {
      top: 882px;
    }
  }
  @media only screen and (max-width: 980px) {
    .alice-carousel__dots {
      top: 852px;
    }
  }
  @media only screen and (max-width: 767px) {
    .alice-carousel__dots {
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media only screen and (max-width: 500px) {
    .alice-carousel__dots {
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }
  } */
`;

export const SliderWrapper = styled.div`
  width: 100%;
  padding-top: 70px;
  /* .alice-carousel {
    position: relative;
    .alice-carousel__wrapper {
      .alice-carousel__stage {
        .alice-carousel__stage-item {
          background-color: white;
        }
      }
    }
  } */
  @media only screen and (max-width: 767px) {
    padding-top: 100px;
  }
`;

export const FeatureWrapper = styled.div`
  /* width: 80%; */
  max-width: 1140px;
  margin: auto;
  padding: 60px 0px 90px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* .alice-carousel__stage {
    display: flex;
    justify-content: space-between;
  } */
  @media only screen and (max-width: 1280px) {
    padding-right: 20px;
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0px;
  }
  .slick-track {
    width: 30000px !important;
  }
`;

export const FeatureItemsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .dottedLine1 {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${CONTRACT.DottedLine1});
      height: 90%;
      width: 90%;
      top: 18%;
      right: -32%;
    }
  }
  .dottedLine2 {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${CONTRACT.DottedLine2});
      height: 90%;
      width: 90%;
      top: 3%;
      right: -32%;
    }
  }
  .dottedLine3 {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${CONTRACT.DottedLine3});
      height: 90%;
      width: 90%;
      top: 20%;
      right: -32%;
    }
  }
`;

export const FeatureItem = styled.div`
  width: 260px;
  height: 250px;
  margin-right: 20px;
  cursor: pointer;
  background: ${(props) => (props.showBorder ? '#ffffff' : 'transparent')};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 20px;
  border: ${(props) => (props.showBorder ? '2px' : '0px')} solid #e1ecf3;
  .activeFeatureItem {
    border: 2px solid #e1ecf3;
  }
  @media only screen and (max-width: 767px) {
    width: 163px;
    height: 200px;
`;

export const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 25px;
  background-color: ${(props) => (props.isActive ? '#839cce' : '#eaf1f6')};
  border: ${(props) => (props.isActive ? '2px solid #839cce' : 'none')};
`;

export const FeatureImage = styled.img`
  margin-bottom: 20px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 13px;
    width: 46px;
  }
`;

export const FeatureHeading = styled.h6`
  margin-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media only screen and (max-width: 767px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

export const FeatureDescription = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media only screen and (max-width: 767px) {
    font-size: 10px;
    line-height: 15px;
  }
`;
