import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import CameraLogo from "../../../../assets/images/photograph"
import greenDot from "../../../../assets/images/green-dot";
import yellowDot from "../../../../assets/images/yellow-dot";
import blueDot from "../../../../assets/images/blue-dot";
import ProjectIndexArrow from "../../../../assets/images/ProjectIndexArrow"
import ProjectIndexArrowGray from "../../../../assets/images/projectIndexArrowGray"
import { updateProjectDetails } from "../../util/project_api_util"
import {formatDatetime} from "../../util/date_util";


class ProjectIndexProjectsNonGridItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swapStatus: ""
    }
    this.sendToProject = this.sendToProject.bind(this)
  }

  sendToProject() {
    this.props.history.push(`/projects_index/${this.props.project.slug}`)
  }

  render() {
    let project = this.props.project;
    let address = project.address
    let services = []
    let upcoming_appointment = ""

    if (this.props.currentUser.roles.contractor) {
      let contractor_services = project?.packages?.filter(current_package => current_package.contractor_id === this.props.currentUser.id) || []


      contractor_services.forEach(current_package => {
        project.services.forEach(service => {
          if (service.id === current_package.service_id) {
            let id = ""
            let dot = yellowDot
            if (current_package.complete){
              // id = "completedService"
              dot = greenDot
            } else if (current_package.ready_for_download) {
              dot = blueDot
            }

            services.push(<div className="flexDisplay"><img className="projectIndexDot" src={dot} /><p className="serviceIconProjectIndex" id={id}>{service.title}</p></div>)
          }
        })
        if (current_package.appointment_time && upcoming_appointment === "" && new Date(current_package.appointment_time) > new Date()) {
          upcoming_appointment = new Date(current_package.appointment_time)
        }
        else if (current_package.appointment_time && new Date(current_package.appointment_time) > new Date() && new Date(current_package.appointment_time) < upcoming_appointment) {
          upcoming_appointment = new Date(current_package.appointment_time)
        }
      })
    } else {
      project.packages.forEach(current_package => {
        project.services.forEach(service => {
          if (service.id === current_package.service_id && (!this.props.currentUser.roles.post_production || !current_package.cubicasa || (service.title != "Floor Plans" && service.media_types != "matterport"))) {
            let id = ""
            let dot = yellowDot
            if (current_package.complete) {
              // id = "completedService"
              dot = greenDot
            } else if (current_package.ready_for_download) {
              dot = blueDot
            }

            services.push(<div className="flexDisplay"><img className="projectIndexDot" src={dot} /><p className="serviceIconProjectIndex" id={id}>{service.title}</p></div>)
          }
          })
      })

      Object.values(project.packages).forEach(current_package => {
        if (current_package.appointment_time && upcoming_appointment === "" && new Date(current_package.appointment_time) > new Date()) {
          upcoming_appointment = new Date(current_package.appointment_time)
        }
        else if (current_package.appointment_time && new Date(current_package.appointment_time) > new Date() && new Date(current_package.appointment_time) < upcoming_appointment) {
          upcoming_appointment = new Date(current_package.appointment_time)
        }
      })
    }

    let date = formatDatetime(project.completed_at || project.created_at, "MM/DD/YYYY", project?.timezone)

    if (upcoming_appointment !== ""){
      date = formatDatetime(upcoming_appointment, "MM/DD/YYYY [at] h:mma z", project?.timezone)
    }

    return (
      <Link className="projectsIndexProjectNonGridItemDiv" id={project.needs_attention && !this.props.currentUser.roles.contractor && !this.props.currentUser.roles.post_production ? "needsAttention" : ""} to={`/projects_index/${this.props.project.slug}`}>
        {/* <img className="projectsIndexProjectNonGridItemPicture" src={"." + project.cover}></img> */}
        <div className="projectsIndexProjectNonGridItemText">
          <div className="projectsIndexItemHeading nonGridProjectsIndexItemHeading">{address?.apartment_number ? `${address.apartment_number} ` : ""}{address?.formatted_address ? address.formatted_address : `${address.street1}, ${address.city}, ${address.region}`}</div>
          {/* <div className="projectsIndexItemSubheading"> {address.city}, {address.region} {address.zip_code}</div> */}
        </div>
        <div className="projectIndexProjectNonGridItemListDetails">
          <div className="projectIndexGridServices projectIndexRowServices">{services}</div>
        </div>
        <div className="projectIndexGridDateAndArrow">
          <div className="projectIndexDate">{date}</div>
          {/* <div className="projectIndexNonGridArrow"><img src={ProjectIndexArrow}></img></div> */}
          <div className="projectIndexNonGridArrowGray"><img src={ProjectIndexArrowGray}></img></div>
        </div>
      </Link>

    )
  }
}

export default withRouter(ProjectIndexProjectsNonGridItem)