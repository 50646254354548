import EmailIcon from './emailIcon.svg';
import FacebookIcon from './facebookIcon.svg';
import InstagramIcon from './instagramIcon.svg';
import LinkedInIcon from './linkedInIcon.svg';
import PhoneIcon from './phoneIcon.svg';
import VimeoIcon from './vimeoIcon.svg';

const CONTACT_FORM = {
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PhoneIcon,
  VimeoIcon,
};

const LINKS = {
  INSTAGRAM: 'https://www.instagram.com/modern.angles',
  LINKEDIN: 'https://www.linkedin.com/company/modern-angles',
  FACEBOOK: 'https://www.facebook.com/modernanglesny',
  VIMEO: 'https://vimeo.com/user110708264',
  TWITTER: 'https://www.twitter.com/modernangles'
}

export { CONTACT_FORM, LINKS };
