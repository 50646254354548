import { RECEIVE_USER } from "../actions/settings_actions";
import { RECEIVE_CURRENT_USER, UPDATE_NOTIFICATION_COUNT } from "../actions/session_actions";

const usersReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      if (action.user){
      return Object.assign({}, state, { [action.user.id]: action.user });
      }
      else {
        return state;
      }
    case UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        [action.user.user_id]: {
          ...state[action.user.user_id],
          unread_notifications: action.user.count,
        },
      };
    case RECEIVE_USER:
      return Object.assign({}, state, { [action.user.id]: action.user})
    default:
      return state;
  }
};

export default usersReducer;