import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import {
  ServicesButtonWrapper,
} from './styles';
import {ROUTES} from "../../../routes";
import {ScheduleShootButton} from "../../home_page/homeStyle";

const ServicesButton = (props) => {

  const onScheduleShoot = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  return (
    <ServicesButtonWrapper>
        <ScheduleShootButton onClick={onScheduleShoot} className="mt-0">
          {props.buttonText ? props.buttonText : "Schedule Shoot"}
        </ScheduleShootButton>
    </ServicesButtonWrapper>
  );
};

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(ServicesButton));
