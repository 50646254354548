import React from "react";
import { withRouter } from "react-router-dom";
import {formatDatetime} from "../../util/date_util";

class CancelContractorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      cancelledAppointments: {},
      confirmModal: false,
      user_id: this.props.contractor_id ? this.props.contractor_id : this.props.currentUser.id
    }

    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.cancelAppointments = this.cancelAppointments.bind(this)
  }

  cancelAppointments(){
    let cancelledAppointments = Object.values(this.state.cancelledAppointments)

    if (cancelledAppointments.length > 0){
      this.props.cancelAppointments(this.props.project.id, cancelledAppointments).then(this.props.history.push("/projects_index"))
    }
  }

  handleCheckbox(appointment_time) {
    let cancelledAppointments = Object.assign({}, this.state.cancelledAppointments)

    let appointments = Object.values(this.props.project?.packages || []).filter(current_package => current_package.contractor_id === this.state.user_id && current_package.appointment_time && new Date(current_package.appointment_time).getTime() === new Date(appointment_time).getTime())

    appointments.forEach(appointment => {
      if (cancelledAppointments[appointment.id]) {
        delete cancelledAppointments[appointment.id]
      } else {
        cancelledAppointments[appointment.id] = appointment.id
      }
    })

    this.setState({cancelledAppointments: cancelledAppointments})
  }


  render() {
    const {onClose} = this.props

    const appointmentsHash = {}

    let appointments = Object.values(this.props.project?.packages || []).filter(current_package => current_package.contractor_id === this.state.user_id && current_package.appointment_time && new Date(current_package.appointment_time) > Date.now())


    if (this.props.currentUser.roles.admin){
      appointments = Object.values(this.props.project?.packages || []).filter(current_package => current_package.contractor_id === this.state.user_id && current_package.appointment_time)
    }

    let appointmentList = appointments.map((appointment) => { 

    if (appointmentsHash[appointment.appointment_time]){
      return ""
    } else {
        appointmentsHash[appointment.appointment_time] = true
        let appointment_time = new Date(appointment.appointment_time)
        return <div className="mb-2">
          <label>
            <input type="checkbox" className="formCheckBox" checked={this.state.cancelledAppointments[appointment.id]} onChange={() => this.handleCheckbox(appointment.appointment_time)} />
            <span className="rememberMe">{formatDatetime(appointment_time, null, this.props.project?.client?.timezone)}</span>
          </label>
        </div>
    }
    })
      

    let submitButtonDisabled = false;

    if (appointmentList.length === 0){
      appointmentList = <div> You have no upcoming appointments for this project. </div>
    }

    if (Object.values(this.state.cancelledAppointments).length === 0){
      submitButtonDisabled = true;
    }

    let modal = ""

    if (this.state.confirmModal) {
      modal = <div className="modal-background sub-modal-background" onClick={() => this.setState({ confirmModal: false })}>
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="contractorModalHeading">
              <div className="confirmationModalDescription">Are you sure you want to make this change?</div>
            </div>
            <div className="confirmationTimeSlotsButtons">
              <div onClick={() => this.setState({ confirmModal: false })} className="signupFormPrevious">Cancel</div>
              <div onClick={this.cancelAppointments} className="signupFormNext" id={this.state.selectedTime === "" ? 'disabled' : ''}>Apply</div>
            </div>
          </div>
        </div>
      </div>
    }

    return (
      <div className="modal-background" onClick={() => onClose()}>
        {modal}
        <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading">Reject Appointments</div>
              <div className="closeIcon" onClick={() => onClose()}><i className="fas fa-times" /></div>
            </div>
            <div className="contracotrCancelModalMainContainer">
              <div className="my-4 font-semibold">Please note Modern Angles tracks cancellations and repeated cancellations can adversely impact your rating.</div>
              {appointmentList}
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button className={`projectEditButton projectEditButtonPrimary ${submitButtonDisabled ? 'grayedoutButton' : ''}`} onClick={() => submitButtonDisabled ? "" : this.setState({confirmModal: true})}>Reject Appointments</button>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(CancelContractorModal);