import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// import { ContractMediaComponent } from '../../home_page/homeComponents';
import {
  ServiceDropdown,
  FullService,
  ServicesGallery,
  BestProperty,
  VirtualStagingTopSlider,
  ContractMedia,
} from '../../pageComponents';
import {
  VirtualStagingWrapper,
  VirtualStagingBorder,
} from './VirtualStagingStyles';
import {
  VIRTUAL_STAGING,
  SERVICES,
} from '../../../../../assets/images/servicesPages';
import { CONTRACT } from '../../../../../assets/images/landing';
import {FullServiceTitle, FullServiceTitleGold} from "../../pageComponents/FullService/styles";
import {PropertyComponent, ServiceLogoListComponent, TestimonialsComponent2} from "../../home_page/homeComponents";
import BundlesAndServices from "../../pageComponents/BundlesAndServices";

const { ContractMediaSlidesVS } = CONTRACT;

const { ROW_ONE, ROW_TWO, VIRTUAL_STAGING_CONTRACT_MEDIA } = VIRTUAL_STAGING;

const FULL_SERVICE_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'Adding Furniture & Decorations',
    content:
      'Virtual staging can include digitally adding, removing, or changing the decoration and furniture in any space. It is way cheaper than moving real furniture around!',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Sky Beautification',
    content:
      'Getting the right sky for an image can make all of the difference. We do everything we can to maximise the potential appeal of your property.',
  },
  {
    icon: SERVICES.ListIcon3,
    title: 'Object, Wire, and Artifact Removal',
    content:
      'Most of the time the perfect photo has some slight imperfections. We can remove wires, glare, or shadows that are holding back the best assets.',
  },
];

const CONTRACT_MEDIA_DATA = [
  {
    icon: VIRTUAL_STAGING.NewProjectIcon,
    heading: 'New Project!',
    content:
      'Our app guides you through creating a new virtual staging project.',
  },
  {
    icon: VIRTUAL_STAGING.CustomizationIcon,
    heading: 'Customization',
    content:
      'Fully customize your project. Sky replacements, object removal, etc.',
  },
  {
    icon: VIRTUAL_STAGING.VirtualStudioIcon,
    heading: 'Virtual Studio',
    content:
      'Our artist’s work on your media and we notify you when the new and improved assets are ready. ',
  },
  {
    icon: VIRTUAL_STAGING.MarketReadyIcon,
    heading: 'Market Ready',
    content:
      'Easily view and download the newly edited and beautified photos from your shoot. ',
  },
];

const VirtualStagingPage = (props) => {
  const { location } = props;
  return (
    <VirtualStagingWrapper>
      <Helmet>
        <title>Real Estate Virtual Staging Services | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ServiceDropdown
        pathName={location.pathname}
        content={
          'Our virtual staging and beautification services are the most cost effective way to bring your space to life.'
        }
        contentLink={'See what we can do.'}
        isExtraPadding={true}
      />
      <VirtualStagingTopSlider setModal={props.setVSModal} buttonText={"Order Now"}/>
      <ServicesGallery
        heading={
          'Virtual staging is quick, affordable and it helps real estate sell for'
        }
        span={'higher prices.'}
        subHeading={
          'We have helped hundreds of real estate projects out-perform the market.'
        }
        ROW_ONE={ROW_ONE}
        ROW_TWO={ROW_TWO}
      />

      <FullService
        image={VIRTUAL_STAGING.VirtualStagingFullService}
        data={FULL_SERVICE_DATA}>
        <FullServiceTitle>
          Full service <FullServiceTitleGold>virtual staging & image editing</FullServiceTitleGold>
        </FullServiceTitle>
      </FullService>

      <div className="lead-page-container">
        <ServiceLogoListComponent />
      </div>

      <BundlesAndServices setModal={props.setModal} />

      <ContractMedia 
        imagesList={ContractMediaSlidesVS}
        data={CONTRACT_MEDIA_DATA}
        isVirtualStaging={true}
      />

      <TestimonialsComponent2 />

      <PropertyComponent />
      <BestProperty />
    </VirtualStagingWrapper>
  );
};

export default withRouter(VirtualStagingPage);
