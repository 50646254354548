import React from "react"

class UserAvatar extends React.Component {
  initials() {
    const {user} = this.props

    return [
      (user?.first_name || user?.firstName || "").charAt(0),
      (user?.last_name || user?.lastName || "").charAt(0)
    ].join('')
  }

  render() {
    const {user} = this.props

    if (user?.avatar) {
      return <img className="cardAvatar" src={user?.avatar?.url || user.avatar}/>
    } else {
      return <div className="cardAvatar initials">{this.initials()}</div>
    }
  }
}

export default UserAvatar