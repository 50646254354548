import React from 'react';
import ReactDom from 'react-dom';
import configureStore from '../frontend/store/store';
import Root from '../frontend/components/root';
import {setDefaultTimezone} from "../frontend/util/timezone_utils";

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('root');

  let store = configureStore();

  if (window.currentUser) {
    const preloadedState = {
      entities: {
        users: { [window.currentUser.id]: window.currentUser },
      },
      session: { currentUser: window.currentUser },
    };

    setDefaultTimezone(window.currentUser.timezone)
    store = configureStore(preloadedState);
    delete window.currentUser;
  } else {
    store = configureStore();
  }
  ReactDom.render(<Root store={store} />, root);
});
