export const fetchInvoices = (regular = true, search_address = null, search_client = null, search_from = null, search_to = null, search_paid_status = null, page = 0, pageAmount = 10) => (
  $.ajax({
    type: "GET",
    url: "/invoices",
    data: {regular: regular, search_address, search_client, search_from, search_to, search_paid_status, page: page, pageAmount: pageAmount}
  })
)

export const downloadInvoice = (id) => (
  $.ajax({
    type: "GET",
    xhrFields: {
      responseType: 'blob'
    },
    url: `/invoices/${id}.pdf`
  })
)