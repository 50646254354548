import { connect } from "react-redux";
import UpdateService from "./update_service";
import React from "react";
import { withRouter } from "react-router-dom";
import { updateService } from "../../../actions/service_actions";
import { closeModal } from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
});

const mdp = (dispatch) => ({
  updateService: (service_data, service_id) => dispatch(updateService(service_data, service_id)),
  closeModal: () => { dispatch(closeModal()) },
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(UpdateService));