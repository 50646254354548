import React from "react";
import {showToast} from "../../../actions/toast_actions";
import {connect} from "react-redux";
import {Features} from "./features";


class SubServiceItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.subService.title,
      description: this.props.subService.description,
      price: this.props.subService.price,
      contractor_payout: this.props.subService.contractor_payout,
      hidden: this.props.subService.hidden,
      addon: this.props.subService.addon,
      always_show_addon: this.props.subService.always_show_addon,
      promo: this.props.subService.promo,
      reshoot: this.props.subService.reshoot,
      bundle_description: this.props.subService.bundle_description,
      features: this.props.subService.content?.features || ['']
    }
    this.handleInput = this.handleInput.bind(this)
    this.updateSubService = this.updateSubService.bind(this)
  }

  handleInput(field) {
    return e => {
      let value = e.currentTarget.value;

      this.setState({ [field]: value });
    };
  }

  handleCheckbox(field) {
    return e => this.setState({ [field]: !this.state[field] })
  }

  updateSubService(){
    let sub_service_info = {
      always_show_addon: this.state.always_show_addon,
      promo: this.state.promo,
      addon: this.state.addon,
      hidden: this.state.hidden,
      title: this.state.title,
      description: this.state.description,
      price: this.state.price,
      contractor_payout: this.state.contractor_payout,
      bundle_description: this.state.bundle_description,
      reshoot: this.state.reshoot,
      features: this.state.features.filter(feature => !!feature)
    }
    this.props.updateSubService(sub_service_info, this.props.subService.id).then(() => {
      this.props.showToast("Sub Service Successfully Updated")
    })
  }

  render() {
    let companyNameErrorClass = "";
    let agentMlsIdErrorClass = "";
    let companyAddressErrorClass = "";
    let companyCityErrorClass = "";
    let companyStateErrorClass = "";
    let companyZipErrorClass = "";


    let companyNameErrors = [];
    let agentMlsIdErrors = [];
    let companyAddressErrors = [];
    let companyCityErrors = [];
    let companyStateErrors = [];
    let companyZipErrors = [];

    return (
      <div className="settingsSubServicesItem">
        <div className="settingsServicesInput">
          <label id="formLabel"> Title </label>
          <input className="formInput" id={agentMlsIdErrorClass} onChange={this.handleInput("title")} type="string" placeholder="Title" value={this.state.title} />
          <ul className="errorUl">{agentMlsIdErrors}</ul>
        </div>
        <div className="settingsServicesInput">
          <label id="formLabel"> Description</label>
          <textarea className="formTextArea" id={companyAddressErrorClass} onChange={this.handleInput("description")} type="string" placeholder="Description" value={this.state.description} />
          <ul className="errorUl">{companyAddressErrors}</ul>
        </div>
        <div className="settingsServicesInput">
          <label id="formLabel">Bundle Description</label>
          <textarea className="formTextArea" id={companyAddressErrorClass} onChange={this.handleInput("bundle_description")} type="string" placeholder="Bundle Description" value={this.state.bundle_description} />
          <ul className="errorUl">{companyAddressErrors}</ul>
        </div>

        <Features features={this.state.features} onChange={features => this.setState({features})}/>

        <div className="settingsServicesInput">
          <label id="formLabel"> Price </label>
          <input className="formInput" id={companyCityErrorClass} onChange={this.handleInput("price")} type="number" placeholder="Price" value={this.state.price} />
          <ul className="errorUl">{companyCityErrors}</ul>
        </div>
        <div className="settingsServicesInput">
          <label id="formLabel"> Contractor Payout </label>
          <input className="formInput" id={companyAddressErrorClass} onChange={this.handleInput("contractor_payout")} type="number" placeholder="Payout" value={this.state.contractor_payout} />
          <ul className="errorUl">{companyAddressErrors}</ul>
        </div>
        <div className="settingsCheckboxGroup">
          <label><input type="checkbox" className="formCheckBox" checked={this.state.hidden} onChange={this.handleCheckbox("hidden")} /> <span className="rememberMe">Hidden?</span></label>
          <label><input type="checkbox" className="formCheckBox" checked={this.state.reshoot} onChange={this.handleCheckbox("reshoot")} /> <span className="rememberMe">Reshoot?</span></label>
          <label><input type="checkbox" className="formCheckBox" checked={this.state.addon} onChange={this.handleCheckbox("addon")} /> <span className="rememberMe">Addon?</span></label>
          <label><input type="checkbox" className="formCheckBox" checked={this.state.always_show_addon} onChange={this.handleCheckbox("always_show_addon")} /> <span className="rememberMe">Always Show Addon?</span></label>
          <label><input type="checkbox" className="formCheckBox" checked={this.state.promo} onChange={this.handleCheckbox("promo")} /> <span className="rememberMe">Promo?</span></label>
        </div>
        <button onClick={this.updateSubService} className="settingsButton subServicesButton">Update Sub Service</button>
        {/* <button onClick={() => this.props.deleteSubService(this.props.subService.id)} className="settingsButton subServicesButton deleteButton">Delete Service</button> */}
      </div>
    );
  }
}

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(SubServiceItem);