import React from "react";
import { Route, Link } from "react-router-dom";


export default class SettingsTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time_slots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} },
      twilight: false
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.updateTimeSlots = this.updateTimeSlots.bind(this)
    this.updateNavActive = this.updateNavActive.bind(this)
  }

  componentDidMount() {
    this.props.fetchTimeSlots(this.state.twilight).then(time_slots =>
      this.setState({ time_slots: time_slots }))
  }

  handleCheckbox(day, slot) {
    let time_slots = this.state.time_slots
    if (this.state.time_slots[day][slot]) {
      time_slots[day][slot] = !this.state.time_slots[day][slot]
    } else {
      time_slots[day][slot] = true
    }
    this.setState({ time_slots: time_slots })
  }

  updateNavActive(boolean) {
    if (boolean != this.state.boolean){
      this.props.fetchTimeSlots(boolean).then(time_slots =>
        this.setState({ time_slots: time_slots, twilight: boolean }))
    }
  }

  handleInput(field) {
    return e => this.setState({ [field]: !this.state[field] });
  }


  updateTimeSlots() {
    this.props.updateTimeSlots(this.state.time_slots, this.state.twilight).then(() =>
      this.props.showToast("Time slots successfully updated"))
  }

  render() {

    let navActive = ["", "", ""]
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    let slots = [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5]
    let times = ["8:00 am", "8:30 am", "9:00 am", "9:30 am", "10:00 am", "10:30 am", "11:00 am", "11:30 am", "12:00 pm", "12:30 pm", "1:00 pm", "1:30 pm", "2:00 pm", "2:30 pm", "3:00 pm", "3:30 pm", "4:00 pm", "4:30 pm", "5:00 pm", "5:30pm", "6:00 pm", "6:30 pm"];

    if (this.state.twilight){
      slots = [16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20]
      times = ["4:00 pm", "4:30 pm", "5:00 pm", "5:30 pm", "6:00 pm", "6:30 pm", "7:00 pm", "7:30 pm", "8:00 pm"]
    }

    let timeSlotsDiv = [];
    let timeSlots = [];
    let j = days.length;

    days.forEach((day, i) => {
      timeSlotsDiv.push(<div className="settingsTimeSlotDay" key={i}>{day}</div>)
      timeSlots = []
      times.forEach((time, i) => {
        timeSlots.push(<label className="timeSlotLabel timeSlotLabel2" key={i}><input type="checkbox" className="formCheckBox timeSlotCheckbox" checked={this.state.time_slots[day][slots[i]] || false} onChange={() => this.handleCheckbox(day, slots[i])} /> <span className="timeSlotText">{time}</span></label>)
      })
      timeSlotsDiv.push(<div className="timeSlotDiv" key={j}>{timeSlots}</div>)
      j += 1;
    })

    let projectsNavClass = "settingsTwilightNavMobile"
    let projectsNavItemClass = "projectsIndexProjectsNavItemMobile"

    if (this.state.twilight){
      navActive[1] = "projectsIndexProjectsNavActiveMobile"
    }
    else {
      navActive[0] = "projectsIndexProjectsNavActiveMobile"
    }

    if (this.props.isLargeScreen) {
      if (this.state.twilight) {
        navActive[1] = "projectsIndexProjectsNavActive"
      }
      else {
        navActive[0] = "projectsIndexProjectsNavActive"
      }
      projectsNavClass = "projectsIndexProjectsNav timeSlotNav"
      projectsNavItemClass = "projectsIndexProjectsNavItem"
    }

    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences">
          <div className={projectsNavClass}>
            <div onClick={() => this.updateNavActive(false)} className={projectsNavItemClass} id={navActive[0]}>General</div>
            <div onClick={() => this.updateNavActive(true)} className={projectsNavItemClass} id={navActive[1]}>Twilight</div>
          </div>
          <div className="settingsComponentHeading">Time Slots</div>
          <div className="settingsComponentSubHeading2">Select all time slots for each day.</div>
          <div className="settingsPreferencesGroup1">
            {timeSlotsDiv}
          </div>

          <div className="notice mt-4">
            Times are mentioned in {this.props.currentUser.timezone} timezone
          </div>

          <button onClick={this.updateTimeSlots} className="settingsButton preferencesButton settingsTimeSlotsButton">Save changes</button>
        </div>
      </div>
    );
  }
}