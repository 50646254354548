import React from "react";
import {closeToast} from "../../actions/toast_actions";
import {connect} from "react-redux";

class Toast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isClosing: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.startClosing()
    }, 4000 - 100)
  }

  startClosing(e) {
    if (e) e.preventDefault()
    const {toast, closeToast} = this.props
    this.setState({isClosing: true})

    setTimeout(() => {
      closeToast(toast)
    }, 100)
  }

  render() {
    const {toast} = this.props
    const {isClosing} = this.state
    const {message} = toast

    return <div className={`toast show transition-slide-in-down ${isClosing ? 'slide-out-blurred-top' : ''}`}>
      <div className="toast-body">
        {message}
      </div>
      <button type="button" data-dismiss="toast" aria-label="Close" onClick={e => this.startClosing(e)}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  }
}

const msp = () => ({})

const mdp = (dispatch) => ({
  closeToast: (toast) => dispatch(closeToast(toast))
});

export default connect(msp, mdp)(Toast);