import React from 'react';
import {Col, Container, Row} from 'reactstrap';

import {
  PropertyContainer,
  PropertyCount,
  PropertyCountDescr,
  PropertyDescr,
  PropertyIcon,
  PropertySimpleIcon,
  PropertyWrapper,
  SmallTitle,
} from './styles/PropertyStyles';
import {PROPERTY} from '../../../../../assets/images/landing';

const PropertyComponent = () => {
  return (
    <PropertyContainer className='propertyContainer'>
      <Container className="text-center">
        <SmallTitle>The right content will do the job.</SmallTitle>
        <PropertyDescr>
          We help properties outperform their markets.
        </PropertyDescr>

        <Row>
          <Col xs='12' md='6' lg='3' className='text-center'>
            <PropertyIcon>
              <PropertySimpleIcon src={PROPERTY.PropertyHome} alt=''/>
            </PropertyIcon>
            <PropertyWrapper>
              <PropertyCount>10,000+</PropertyCount>
              <br/>
              <PropertyCountDescr>Properties</PropertyCountDescr>
            </PropertyWrapper>
          </Col>
          <Col xs='12' md='6' lg='3' className='text-center'>
            <PropertyIcon>
              <PropertySimpleIcon src={PROPERTY.Rectangle} alt=''/>
            </PropertyIcon>
            <PropertyWrapper>
              <PropertyCount>300,000+</PropertyCount>
              <br/>
              <PropertyCountDescr>
                Photos, Videos, and Renderings
              </PropertyCountDescr>
            </PropertyWrapper>
          </Col>
          <Col xs='12' md='6' lg='3' className='text-center'>
            <PropertyIcon>
              <PropertySimpleIcon src={PROPERTY.Happy} alt=''/>
            </PropertyIcon>
            <PropertyWrapper>
              <PropertyCount>1000+</PropertyCount>
              <br/>
              <PropertyCountDescr>Happy Clients</PropertyCountDescr>
            </PropertyWrapper>
          </Col>
          <Col xs='12' md='6' lg='3' className='text-center'>
            <PropertyIcon>
              <PropertySimpleIcon src={PROPERTY.Stock} alt=''/>
            </PropertyIcon>
            <PropertyWrapper>
              <PropertyCount>+$5 Billion</PropertyCount>
              <br/>
              <PropertyCountDescr>in Estimated Property Value</PropertyCountDescr>
            </PropertyWrapper>
          </Col>
        </Row>
      </Container>
    </PropertyContainer>
  );
};

export default PropertyComponent;
