import styled from 'styled-components';

export const FullServiceWrapper = styled.div`
  padding: 80px 0 10px;
  @media screen and (max-width: 768px) {
    padding: 20px 0 0;
  }

  .container {
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  .serviceImageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      position: relative;
      margin-top: 20px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 0px;
      align-items: center;
      padding: 30px 0px;
    }
  }

  .serviceContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 0px;
      align-items: center;
    }
  }
`;

export const FullServiceTitle = styled.h2`
  text-transform: initial;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1b264f;
  padding: 30px 0px;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 991px) {
    font-size: 36px;
    line-height: 44px;
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 305px;
    margin: 0 auto;
    font-size: 22px;
    line-height: 24px;
  }
`;

export const FullServiceTitleGold = styled.span`
  color: #D2AD81;
`

export const ServiceImage = styled.img`
  width: 100%;
  
  @media screen and (max-width: 991px) {
    width: 100%;
  }
  
  @media screen and (max-width: 767px) {
    width: 90%;
    margin-left: 0px;
  }
`;

export const FullServiceContainer = styled.div`
  @media screen and (max-width: 991px) {
    padding-right: 25px;
  }
`;

export const FullServiceSubTitle = styled.h2`
  color: #1B264F;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.5px;
  letter-spacing: -0.4px;
  margin-bottom: 40px;
  margin-top: 20px;
  text-transform: none;

  @media screen and (max-width: 991px) {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
`

export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  @media screen and (max-width: 991px) {
    margin: 20px 0px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ServiceTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
`;

export const ServiceIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-top: -15px;
  margin-right: 16px;
  @media screen and (max-width: 991px) {
    width: 16px;
    height: 18px;
  }
`;

export const ServiceTitle = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const ServiceContent = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media screen and (max-width: 991px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
