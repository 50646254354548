import styled from "styled-components";

export const TermsUserRoleSelectorWrapper = styled.div`
  margin: 0px auto;
  padding: 20px 30px 20px;
  width: 80%;
  max-width: 420px;
  
  @media only screen and (max-width: 769px) {
    width: 82%;
    padding: 0px 0px 30px;
  }
  
  font-family: Nunito Sans;
  font-style: normal;
  color: #5B6B78;
`

export const TermsUserRoleSelect = styled.select`
  width: 100%;
  border: 2px solid #ebeaed;
  border-radius: 12px;
  margin: 20px 0px;
  padding: 17px 32px 17px 20px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #353844;
  
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  background-position: calc(100% - 12px) center !important;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    
  position: relative;
  
  &::-ms-expand {
     display: none;
  }

  @media screen and (max-width: 769px) {
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
    margin: 0;
  }
`