import { connect } from "react-redux"
import MediaAccess from "./media_access"
import React from "react"
import { withRouter } from "react-router-dom"
import { updatePropertyTour } from "../../actions/project_actions";
import { fetchContractors } from "../../actions/service_actions";

const msp = (state) => (
  {
    currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
  }
)

const mdp = (dispatch) => ({
  updatePropertyTour: (property_tour_info) => dispatch(updatePropertyTour(property_tour_info)),
  fetchContractors: (service_id, zipcode, search) => dispatch(fetchContractors(service_id, zipcode, search))
})

export default withRouter(connect(msp, mdp)(MediaAccess))