import styled from "styled-components";
import {SECOND_SECTION, SMALL_CIRCLE} from "../../../../../assets/images/careersPage";

export const SecondSectionWrapper = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  
  @media screen and (max-width: 1050px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const SecondSectionContainer = styled.div`
  width: 997px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  position: relative;
  
  @media only screen and (max-width: 1280px) {
    width: 760px;
  }
  
  @media only screen and (max-width: 769px) {
    width: 98%;
    max-width: 350px;
    flex-direction: column;
  }
  
  &::before {
    content: '';
    width: 185px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: -100px;
    top: -60px;
    z-index: -1;
    background-image: url(${SMALL_CIRCLE});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    
    @media screen and (max-width: 769px) {
      top: -60px;
      left: -90px;
    }
  }
`;

export const Card = styled.div`
  padding: 30px;
  
  &:nth-child(1) {
    padding-left: 0;
  }
  
  &:nth-child(3) {
    padding-right: 0;
  }
  
  @media only screen and (max-width: 769px) {
    padding: 30px !important;
  }
`;

export const CardTitle = styled.div`
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #353844;
  padding-left: 33px;
  position: relative;
  
  &:before {
    position: absolute;
    content: '';
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  &[data-order="1"]:before {
    background-image: url(${SECOND_SECTION.CyanCircle});
    width: 17px;
  }
   
  &[data-order="2"]:before {
    background-image: url(${SECOND_SECTION.PinkCircle});
    width: 17px;
  }
  
  &[data-order="3"]:before {
    background-image: url(${SECOND_SECTION.GreenCircle});
    width: 18px;
  }
`;

export const CardDescription = styled.div`
  font-family: Nunito Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #353844;
  
  margin-top: 20px;
`;