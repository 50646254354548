import React from "react"
import {ReactComponent as BackIcon} from "../../../assets/images/icons/back.svg"
import NotificationBellContainer from "./notification_bell_container"

class PageHeader extends React.Component {
  render() {
    const {title, subtitle, back, backLink} = this.props

    return <div className="pageHeading">
      <div>
        {back && backLink && <a className="pageHeadingBack" href={backLink}>
          <BackIcon className="me-3" />
          {back}
        </a>}
        <div>
          <h3 className="dashboardSubHeading m-0" id={this.props.loggedOut ? "pageHeaderLoggedOut" : ""}>{title}</h3>
          {subtitle && !back && <div className="welcomeBackSubHeading">{subtitle}</div>}
        </div>
      </div>
      {this.props.showNotifications && <NotificationBellContainer />}
    </div>
  }
}

export default PageHeader