import { connect } from "react-redux";
import VirtualStagingEdit from "./virtual_staging_edit";
import React from "react";
import { withRouter } from "react-router-dom";
import { fetchCategoriesSettings, deleteCategory } from "../../../actions/virtual_staging_actions";
import { openModal, closeModal, addInfo } from "../../../actions/modal_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  categories: Object.values(state.entities.virtualStaging.categories)
});

const mdp = (dispatch) => ({
  fetchCategoriesSettings: () => dispatch(fetchCategoriesSettings()),
  openModal: (modal, props = null) => {
    dispatch(openModal(modal, props));
  },
  deleteCategory: (id) => { dispatch(deleteCategory(id)) },
  addInfo: (info) => dispatch(addInfo(info))
});

export default withRouter(connect(msp, mdp)(VirtualStagingEdit));