import React from "react";
import debounce from 'debounce'
import SettingsUsersItem from "./settings_users_item";
import {deleteUser, fetchAllUsers} from "../../../util/users_api_util";
import {fetchFirms} from '../../../util/settings_api_util';
import {setDefaultTimezone} from "../../../util/timezone_utils";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { v4 as uuidv4 } from 'uuid';


export default class SettingsUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      firms: [],
      role: "",
      search: "",
      firm: "",
      page: 0,
      pageSize: 10,
      total_users: 0,
      loading: false,
      requestId: null
    }
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.debounceFetchUsers = debounce(() => this.fetchUsers(), 200)
    this.createUser = this.createUser.bind(this)
  }

  componentDidMount() {
    fetchFirms().then(firms => {
      this.setState({firms: firms})
    })
    this.fetchUsers()
  }

  fetchUsers() {
    const {role, search, firm, page, pageSize} = this.state

    this.setState({loading: true})

    const requestId = uuidv4(); // Generate a unique ID for this request
    this.setState({ requestId }); // Store it in the state

    fetchAllUsers(role, search, firm, page, pageSize).then(users => {
      if (this.state.requestId === requestId) {
      this.setState({ loading: false, users: Object.values(users), total_users: Object.values(users).length > 0 ? Object.values(users)[0].total_users : 0})
      }
    })
  }

  onSearchChange(event) {
    event.preventDefault()
    const { name, value } = event.target
    this.setState({ [name]: value, page: 0}, () => {
      if (name != "search" || (name === "search" && (value.length > 2 || value.length === 0))){
        this.debounceFetchUsers()
      }
    })
  }

  componentDidUpdate(_, prevState) {
    if (prevState.page !== this.state.page) {
      this.debounceFetchUsers()
    }
  }

  changePage(page) {
    this.setState({ page: page })
  }

  humanize(str) {
    return str.replace(/_/g, ' ').split(' ').map(word => {
      return word.replace(/(?:^|\s|-)\S/g, s => s.toUpperCase());
    }).join(' ')
  }

  updateUser(user) {
    this.props.openModal("updateUser", {user, onUserUpdate: this.onUserUpdate.bind(this)})
  }

  createUser() {
    this.props.openModal("createUser", {onUserCreate: this.onUserCreate.bind(this)})
  }

  onUserUpdate(updatedUser) {
    this.showMessage("User successfully updated")
    setDefaultTimezone(updatedUser.timezone)
    this.setState({users: this.state.users.map(user => user.id === updatedUser.id ? updatedUser : user)})
  }

  onUserCreate(user) {
    this.showMessage("User successfully created")
    this.setState({users: [...this.state.users, user]})
  }

  showMessage(message) {
    this.props.showToast(message)
  }

  deleteUser(user) {
    deleteUser(user.id).then(() => {
      if (user.deactivated){
        this.showMessage("User successfully reactivated")
        this.setState({
          users: this.state.users?.filter(u => u.id !== user.id) || []
        })
      } else {
        this.showMessage("User successfully deactivated")
        this.setState({
          users: this.state.users?.filter(u => u.id !== user.id) || []
        })
     }
    })
  }

  render() {
    const roles = ['admin', 'broker', 'client', 'contractor', 'post_production', 'sales_rep', 'sub_broker']

    const { page } = this.state

    let pageLast = 1

    if (this.state.total_users > this.state.pageSize) {
      pageLast = parseInt(this.state.total_users % this.state.pageSize > 0 ? this.state.total_users / this.state.pageSize + 1 : this.state.total_users / this.state.pageSize)
    }

    let pages = [];
    const startPage = Math.max(page - 5, 0);
    const endPage = Math.min(startPage + 9, pageLast - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    let pagesDiv = pages.map((new_page) => (
      <div
        className={
          page === new_page ? "pageNumberDivSelected" : "pageNumberDiv"
        }
        onClick={() => this.changePage(new_page)}
        key={new_page}
      >
        {new_page + 1}
      </div>
    ))

    return (
      <div className="">
        <div className="settingsPreferences settingsUsersList">
          <button onClick={this.createUser} className="settingsButton createUserButton">Create User</button>
          <div className="settingsUsersSearch">
            <div className="inputGrouping">
              <div className="signupLeftInput">
                <select className="formInput" name="role" value={this.state.role} onChange={this.onSearchChange}>
                  <option value="">Select role</option>

                  {roles.map(role => (
                    <option key={role} value={role}>{this.humanize(role)}</option>
                  ))}
                </select>
              </div>
              <div className="signupLeftInput firmDropdown">
                <select className="formInput" name="firm" value={this.state.firm} onChange={this.onSearchChange}>
                  <option value="">Select Firm</option>

                  {this.state.firms.map(firm => (
                    <option key={firm.id} value={firm.id}>{firm.name}</option>
                  ))}
                </select>
              </div>
              <div className="signupRightInput">
                <input className="formInput" name="search" placeholder="Search" value={this.state.search}
                       onChange={this.onSearchChange}/>
              </div>
              <div className="signupRightInput">
                <select
                  className="formInput"
                  onChange={this.onSearchChange}
                  value={this.state.pageSize}
                  name="pageSize"
                >
                  <option value={10}>10 per page</option>
                  <option value={20}>20 per page</option>
                  <option value={50}>50 per page</option>
                </select>
              </div>
            </div>
          </div>
          <div className="settingsComponentHeading">Users</div>
          <div className="settingsServicesItem usersHeading">
            <div className="usersColumn nameColumn">First Name</div>
            <div className="usersColumn nameColumn">Last Name</div>
            <div className="usersColumn">Email</div>
            <div className="usersColumn">Role</div>
            <div className="usersColumn">Firm</div>
            <div className="usersColumn">Account Credit</div>
            <div className="usersColumn">Booked Projects</div>
            <div className="usersColumn">Completed Projects</div>
            <div className="usersColumn">Confirmed?</div>
          </div>
          {this.state.loading ? <div className="loaderDiv">
            <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
          </div> : <div className="settingsPreferencesGroup1">
            {this.state.users.length > 0 ? this.state.users.map(user => (
              <SettingsUsersItem key={user.id} user={user} deleteUser={this.deleteUser} updateUser={this.updateUser}/>
            )) : <></>}
          </div>}
        </div>
        <div className="pagesDiv">
          <div className="pages-container">
            <div className={page > 0 ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={() => page > 0 ? this.setState({ page: this.state.page - 1 }) : ""}>Previous</div>
            {pagesDiv}
            <div className={page + 1 != pageLast ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={() => page + 1 != pageLast ? this.setState({ page: this.state.page + 1 }) : ""}>Next</div>
          </div>
        </div>
      </div>
    );
  }
}