import React, {useEffect, useState} from "react";
import uploadImg from "../../../../../assets/images/photographerOnboarding/upload.svg"
import removeImg from "../../../../../assets/images/photographerOnboarding/remove-photo.svg"
import Dropzone from "../../dropzone";
import avatarPlaceholderSrc from "../../../../../assets/images/avatar.png"
import {Footer} from "../footer";
import {
  addPhotographerPortfolioImages,
  removePhotographerPortfolioImage, updatePhotographerPortfolio
} from "../../../util/photographer_onboarding_util";

export const Portfolio = (props) => {
  const [photos, setPhotos] = useState(props.data?.photos || [])
  const [bio, setBio] = useState(props.data?.bio || '')
  const [newAvatar, setNewAvatar] = useState(null)
  const [loading, setLoading] = useState(false)
  const [experience, setExperience] = useState(props.data?.experience || '')
  const [portfolioUrl, setPortfolioUrl] = useState(props.data?.portfolioUrl || '')

  const avatar = props.data?.avatar

  useEffect(() => {
    props.saveData({...props.data, photos})
  }, [photos]);

  useEffect(() => {
    return () => {
      if (newAvatar) URL.revokeObjectURL(newAvatar.src);
    }
  }, [])

  const onFileSelected = (e) => {
    submitPhotos(e.target.files)
  }

  const onFileUploadDropzone = (files) => {
    submitPhotos(files)
  }

  const onChangeBio = (e) => {
    e.preventDefault()
    setBio(e.target.value)
  }

  const onChangeExperience = (e) => {
    e.preventDefault()
    setExperience(e.target.value)
  }

  const onChangePortfolioUrl = (e) => {
    e.preventDefault()
    setPortfolioUrl(e.target.value)
  }

  const onUploadAvatar = (e) => {
    e.preventDefault()
    let avatar = e.target.files[0];
    setNewAvatar({
      src: URL.createObjectURL(avatar),
      file: avatar
    })
  }

  const onDeletePhoto = (photo) => {
    return (e) => {
      e.preventDefault()
      removePhotographerPortfolioImage(photo.id).then(() => {
        setPhotos(photos.filter(p => p !== photo))
      })
    }
  }

  const submitPhotos = (files) => {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("gallery[images][]", files[i])
    }

    setLoading(true)
    addPhotographerPortfolioImages(formData).then(resultPhotos => {
      setPhotos(resultPhotos)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    let formData = new FormData();
    if (newAvatar) formData.append("avatar", newAvatar.file)
    formData.append("bio", bio)

    setLoading(true)
    updatePhotographerPortfolio(formData).then(({avatar}) => {
      props.onNext({bio, avatar, photos, experience, portfolioUrl})
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const avatarSrc = newAvatar?.src || avatar

  return <form onSubmit={onSubmit}>
    <div className="photographer-onboarding-form-title">
      Add your <span className="blue">avatar and bio.</span>
    </div>

    <div className="photographer-onboarding-form-fields">
      <div className="photographer-onboarding-form-field">
        <div className="photographer-onboarding-portfolio-upload-button">
          <div className="photographer-onboarding-portfolio-avatar">
            <img src={avatarSrc || avatarPlaceholderSrc} />
          </div>

          <label htmlFor="avatar" className="projectEditButton projectEditButtonPrimary">
            {avatarSrc ? 'Change avatar' : 'Upload avatar'}
            <input type="file" id="avatar" onChange={onUploadAvatar} accept="image/*"/>
          </label>
        </div>
      </div>

      <div className="photographer-onboarding-form-field full-width">
        <label id="formLabel" className="formLabel">Biography</label>
        <textarea className="formTextArea" placeholder="Content" value={bio}
                  onInput={onChangeBio} />
      </div>

      <div className="photographer-onboarding-form-field full-width">
        <label id="formLabel" className="formLabel">Tell us about your real estate photographer experience</label>
        <textarea className="formTextArea" placeholder="Content" value={experience}
                  onInput={onChangeExperience} />
      </div>

      <div className="photographer-onboarding-form-field full-width">
        <label id="formLabel" className="formLabel">Add a link to portfolio website</label>
        <input className="formInput" placeholder="Content" value={portfolioUrl} onInput={onChangePortfolioUrl} />
      </div>
    </div>

    <div className="photographer-onboarding-form-title">
      Add photos to your <span className="blue">portfolio.</span>
    </div>

    <Dropzone handleDrop={onFileUploadDropzone}>
      <div className={`photographer-onboarding-portfolio-dropzone ${loading ? 'disabled' : ''}`}>
        <label htmlFor="files" className="photographer-onboarding-portfolio-dropzone-inner">
          <div>
            <div className="photographer-onboarding-portfolio-dropzone-logo">
              <img src={uploadImg}/>
            </div>

            <div className="photographer-onboarding-portfolio-dropzone-logo-text">
              Upload
            </div>

            <input onChange={onFileSelected} type="file" multiple="multiple" id="files"
                   accept="image/*, .NEF, .dng, .arw"/>
          </div>
        </label>
      </div>
    </Dropzone>

    <div className="photographer-onboarding-portfolio-photos">
      {photos.map(photo => (
        <div className="photographer-onboarding-portfolio-photo" key={photo.url}>
          <img src={photo.url}/>
          <div className="photographer-onboarding-portfolio-photo-delete">
            <img src={removeImg} onClick={onDeletePhoto(photo)}/>
          </div>
        </div>
      ))}
    </div>

    <Footer onBack={props.onBack} nextDisabled={loading} onSkip={() => props.onNext(props.data)}/>
  </form>
}