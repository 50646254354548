import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import {
  FullServiceWrapper,
  FullServiceTitle,
  ServiceImage,
  FullServiceContainer,
  ServiceWrapper,
  ServiceTitleWrapper,
  ServiceIcon,
  ServiceTitle,
  ServiceContent, FullServiceTitleGold,
} from './styles';

const FullService = (props) => {
  const { image, data } = props;
  return (
    <FullServiceWrapper className='fullServiceWrapper'>
      <Container>
        <Row>
          {props.children}
        </Row>
        <Row>
          <Col
            xl='6'
            lg='6'
            md='6'
            sm='12'
            xs='12'
            className='serviceImageWrapper'
          >
            <ServiceImage src={image} />
          </Col>
          <Col
            xl='6'
            lg='6'
            md='6'
            sm='12'
            xs='12'
            className='serviceContentWrapper'
          >
            <FullServiceContainer>
              {data.map((service, index) => {
                return (
                  <ServiceWrapper key={index}>
                    <ServiceTitleWrapper>
                      <ServiceIcon src={service.icon} />
                      <ServiceTitle>{service.title}</ServiceTitle>
                    </ServiceTitleWrapper>
                    <ServiceContent>{service.content}</ServiceContent>
                  </ServiceWrapper>
                );
              })}
            </FullServiceContainer>
          </Col>
        </Row>
      </Container>
    </FullServiceWrapper>
  );
};

export default FullService;
