import React from "react"
import {withRouter} from "react-router-dom"
import {formatDatetime} from "../../util/date_util";

class ProjectUpdatedModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: []
    }
  }

  render() {
    const project = this.props.project
    const old_services = {}

    project.services.forEach(service => {
      old_services[service.id] = true
    })

    const new_project = this.props.new_project
    const servicesByAppointment = Object.values(new_project.packages).reduce((res, pack) => {
      if (res[pack.appointment_time]) {
        res[pack.appointment_time].packages.push(pack)
      } else if (!old_services[pack.service_id]) {
          res[pack.appointment_time] = { packages: [pack] }
      }
      return res
    }, {})

    return (<div className="orderPreviewModalComponent congratsModalComponent">
      <div className="congratsModalHeading"> Congrats your project is updated!</div>
      {/* <Fireworks /> */}
      <div className="congratsProjectInfo">
        <div className="congratsSubInfo">{this.props.project.address?.formatted_address}</div>
        <div className="congratsSubInfo">
          {
            Object.values(servicesByAppointment).map(({ packages }) => {
              return (
                <div className="congratsSubInfoHighMargin">
                  <div className="congratsSubInfo">{packages.map(p => p.service_title).join(', ')}</div>
                  <div className="d-flex flex-column justify-content-between position-rel">
                    <div className="ms-2">
                      {formatDatetime(packages[0].appointment_time, "MM/DD/YYYY, hh:mm A z", this.props.project?.client?.timezone)}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="congratsButtons">
        <button className="congratsViewListingButton" onClick={() => location.reload()}>
          View Listing
        </button>
        <button className="congratsViewDashboardButton" onClick={() => this.props.history.push("/dashboard")}>
          Visit Dashboard
        </button>
      </div>
    </div>)
  }
}

export default withRouter(ProjectUpdatedModal)