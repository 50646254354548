import React from "react";
import debounce from 'debounce'
import { Route, Link, withRouter } from "react-router-dom";
import NewPhotoProject from "../../../../assets/images/New_Photography_Project"
import NewVSProject from "../../../../assets/images/New_VS_Project"
import { connect } from "react-redux";
import { showToast } from "../../actions/toast_actions";
import newProject1 from "../../../../assets/images/newProjectDashboardImage";
import newVSProject1 from "../../../../assets/images/Virtual_Studio_Banner";

class HomePageCreateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      vsSelected: false,
      projectSelected: false,
    }

    this.onClose = this.onClose.bind(this)
    this.onProjectSelect = this.onProjectSelect.bind(this)
    this.onVSSelect = this.onVSSelect.bind(this)
    this.handleNext = this.handleNext.bind(this)
  }

  componentDidMount(){
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  onClose() {
    this.props.onClose()
  }

  onProjectSelect(){
    this.props.openProjectModal()
    this.props.onClose()
  }

  onVSSelect(){
    this.props.openVSModal()
    this.props.onClose()
  }

  handleNext(){
    if (this.state.vsSelected){
      this.onVSSelect()
    } else if (this.state.projectSelected){
      this.onProjectSelect()
    }else {
      this.props.showToast("You must select an option to continue.")
    }
  }

  progress() {
    const { page } = this.state
    const step = page

    return [0, 1, 2, 3, 4, 5].map(i => {
      return <div className={`modalFooterProgressItem ${step === i ? 'completed' : ''}`} key={`step-${i}`}>
        {i+1}
      </div>
    })
  }


  render() {

    let buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
      {/* <div className="signupFormNext" onClick={this.handleNext}>Start</div> */}
    </div>

    let descriptions = <div></div>
    let descriptionsVS = <div></div>

    descriptions =
    <ul className="homepageCreateModalUl">
      <li>HDR Photos (High-Dynamic-Range)</li>
      <li>Green Grass, Blue Sky, Fireplace</li>
      <li>Crystal Clear Window Views</li>
    </ul>

    descriptionsVS =
      <ul className="homepageCreateModalUl">
        <li>Super Realistic Staging</li>
        <li>Virtual Daytime to Twilight</li>
        <li>Upload Your Own Photos</li>
      </ul>

      if (!this.props.isLargeScreen){
        descriptions = <div></div>
        descriptionsVS = <div></div>
      }

    return (
      <div className="modal-background">
        <div className={`orderpreviewModal addServiceModal`}>
          <div className="orderPreviewModalComponent">
            <div className="addServiceModalContent">
              {this.state.page === 6 ? "" : <button className="projectEditButton projectEditButtonLight modalCloseButton modalCloseButtonHomepage" onClick={() => this.onClose()}>
                Close
              </button>}
              {this.state.page === 6 ? "" : <div className="closeIcon modalCloseButtonHomepageMobile" onClick={() => this.onClose()}><i className="fas fa-times" /></div>}
              <div className="projectLocationComponent">
                <div className="projectLocationHeadingGrouping">
                  <div className="projectLocationHeadingMinimizedGrouping">
                    <div className="settingsComponentHeading chooseYourServiceText">What type of project would you like to create?</div>
                  </div>
               </div>
              <div className="homePageCreateModalGroup">
                  <div className="newProjectDashboardDivHomepage" id={this.state.projectSelected ? "homepageSelected" : ""} onClick={this.onProjectSelect}>
                    <img className="newProjectImageHomepage" src={newProject1} />
                    <div className="newProjectDashboardHeadingHomepage">New Photography Project</div>
                    <div>
                      <div className="newProjectDashboardServices newProjectDashboardServicesHomepage">Photography, Videos, Drones, Floor Plans, 3D Renderings, Twilight </div>
                      {descriptions}
                    </div>
                  </div>
                  <div className="newProjectDashboardDivHomepage" id={this.state.vsSelected ? "homepageSelected" : ""} onClick={this.onVSSelect}>
                    <img className="newProjectImageHomepage" src={newVSProject1} />
                    <div className="newProjectDashboardHeadingHomepage">New Virtual Staging Project </div>
                    <div>
                      <div className="newProjectDashboardServices newProjectDashboardServicesHomepage">Virtual Staging, Sky & View Replacement, Object Removal</div>
                      {descriptionsVS}
                    </div>
                  </div>
                {/* <div className="homePageCreateModalSelection" id={this.state.projectSelected ? "homepageSelected" : ""} onClick={() => this.setState({projectSelected: true, vsSelected: false})}><img className="homePageCreateModalImage" src={NewPhotoProject}></img></div> */}
                {/* <div className="homePageCreateModalSelection" id={this.state.vsSelected ? "homepageSelected" : ""} onClick={() => this.setState({vsSelected: true, projectSelected: false})}><img className="homePageCreateModalImage" src={NewVSProject}></img></div> */}
              </div>
              </div>
            </div>
            {/* <div className="modalFooter">
              <div className="modalFooterProgress">
                {this.progress()}
              </div>
              {buttons}
            </div> */}
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(HomePageCreateModal));