export const fetchVirtualStagingInfo = (project_id) => (
  $.ajax({
    type: "GET",
    url: "/client_index",
    data: {project_id: project_id}
  })
);

export const fetchVirtualStagingProducts = (category_id) => (
  $.ajax({
    type: "GET",
    url: "/products_index",
    data: { id: category_id }
  })
);

export const fetchVirtualSuiteTypes = () => (
  $.ajax({
    type: "GET",
    url: "/virtual_suite_types",
  })
);

export const getProjectVirtualStagingBatches = (project_id) => (
  $.ajax({
    url: "/virtual_staging_batches/project_batches",
    data: { project_id }
  })
);

export const createVirtualStagingPurchase = (orders) => (
  $.ajax({
    type: "POST",
    url: "/virtual_staging_batches",
    data: { virtual_staging_batch: orders}
  })
);

export const UpdateVirtualStagingBatch = (virtual_staging_batch_id, data) => (
  $.ajax({
    type: "PATCH",
    url: `/virtual_staging_batches/${virtual_staging_batch_id}`,
    data: data
  })
);

export const destroyVirtualStagingBatch = (data) => (
  $.ajax({
    type: "DELETE",
    url: `/destroy_batch`,
    data: data
  })
);

export const destroyVirtualStagingPurchase = (data) => (
  $.ajax({
    type: "DELETE",
    url: `/destroy_purchase`,
    data: data
  })
);

export const destroyAllBatches = (data) => (
  $.ajax({
    type: "DELETE",
    url: `/destroy_all_batches`,
    data: data
  })
);

export const fetchCategories = (virtual_suite_type_id = null) => (
  $.ajax({
    type: "GET",
    url: "/fetch_categories",
    data: { virtual_suite_type_id: virtual_suite_type_id }
  })
);

export const fetchCategoriesSettings = () => (
  $.ajax({
    type: "GET",
    url: "/fetch_categories_settings",
  })
);

export const createCategory = (category_info) => (
  $.ajax({
    type: "POST",
    url: "/virtual_staging_categories",
    data: category_info,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const updateCategory = (category_info, category_id) => (
  $.ajax({
    type: "PATCH",
    url: `/virtual_staging_categories/${category_id}`,
    data: category_info,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const deleteCategory = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/virtual_staging_categories/${id}`,
  })
)

export const createVirtualSuiteType = (virtual_suite_type_info) => (
  $.ajax({
    type: "POST",
    url: "/virtual_suite_types",
    data: virtual_suite_type_info,
    cache: false,
    contentType: false,
    processData: false,
  })
);


export const updateVirtualSuiteType= (virtual_suite_type_info, virtual_suite_type_id) => (
  $.ajax({
    type: "PATCH",
    url: `/virtual_suite_types/${virtual_suite_type_id}`,
    data: virtual_suite_type_info,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const deleteVirtualSuiteType = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/virtual_suite_types/${id}`,
  })
)


// export const createProduct = (product_info) => (
//   $.ajax({
//     type: "POST",
//     beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
//     url: "/virtual_staging_categories",
//     data: { virtual_staging_category: category_info }
//   })
// );

export const updateProducts = (product_data, category_id) => (
  $.ajax({
    type: "PATCH",
    url: `/virtual_staging_categories/${category_id}`,
    data: product_data,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const notifyVirtualStagingCompleted = (project_id, virtual_staging_batch_id) => (
  $.ajax({
    url: '/notify_virtual_staging_complete',
    data: { id: project_id, virtual_staging_batch_id }
  })
)

// export const deleteProduct = (id) => (
//   $.ajax({
//     type: "DELETE",
//     beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
//     url: `/virtual_staging_products/${id}`,
//   })
// )

export const uploadPhotosVirtualStaging  = (formData) => (
  $.ajax({
    type: "POST",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: "/upload_photos_virtual_staging",
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const AddNoteToVirtualPhoto = (data) => (
  $.ajax({
    type: "PATCH",
    url: `/add_notes_to_virtual_photo`,
    data: data
  })
)

