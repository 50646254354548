import { connect } from "react-redux";
import CreateUserModal from "./create_user_modal";
import { withRouter } from "react-router-dom";
import { fetchFirms } from "../../../actions/settings_actions";
import { closeModal } from "../../../actions/modal_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  services: state.entities.services,
});

const mdp = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal())
  },
  fetchFirms: () => dispatch(fetchFirms())
});

export default withRouter(connect(msp, mdp)(CreateUserModal));