import React from "react"
import {withRouter} from "react-router-dom"
import PropertyTourMain from "./property_tour/property_tour_main_container"

class PropertyTour extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLargeScreen: true
    }

    this.updatePredicate = this.updatePredicate.bind(this)
  }

  componentDidMount() {
    this.updatePredicate()
    window.addEventListener("resize", this.updatePredicate)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate)
  }

  updatePredicate() {
    this.setState({isLargeScreen: window.innerWidth > 750})
  }

  render() {
    return (
      <div>
        <PropertyTourMain isLargeScreen={this.state.isLargeScreen} currentUser={this.props.currentUser}
                          branded={this.props.branded}/>
      </div>
    )
  }
}

export default withRouter(PropertyTour)