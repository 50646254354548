import React from 'react'
import {ProfessionalBundles} from "../ProfessionalBundles";
import {ExploreServices} from "../ExploreServices";
import {ScheduleShootButton} from "../../home_page/homeStyle";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';

const BundlesAndServices = (props) => {
  const {setModal} = props
  const bundlesTitle = props.bundlesTitle || <>Professional <span className="blue">Packages</span></>

  const onScheduleShoot = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  return <div className="bundles-and-services-wrapper">
    <div className="lead-page-title">{bundlesTitle}</div>

    <div className="lead-page-wrapper-inner">
      <ProfessionalBundles setModal={setModal}/>
    </div>

    <div className="lead-page-title">
      Explore Our <span className="blue">Services</span>
    </div>

    <div className="lead-page-wrapper-inner">
      <ExploreServices/>
    </div>

    <div className="text-center">
      <ScheduleShootButton onClick={onScheduleShoot} className="mt-0">
        Schedule Shoot
      </ScheduleShootButton>
    </div>
  </div>
}

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(BundlesAndServices));