import styled from 'styled-components';

import { GetStartedBtnLight } from '../../util/color_constant_util';
import { LANDING_PAGE } from '../../../../assets/images/landing';

export const HomeLandingPage = styled.div`
  background-color: #ffffff;

  @media screen and (min-width: 1600px){
    .container {
      width: 80%;
      max-width: 1320px;
    }
  }
  @media screen and (min-width: 1280px){
    .container {
      width: 80%;
      max-width: 1140px;
    }
    .contractMediaWrapper .container {
      width: 100%;
      max-width: 1090px;
    }
  }
`;

export const CreativeServicesContainer = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 90px;
  text-align: center;
  margin-bottom: 60px;
  width: 100%;
  @media only screen and (max-width: 991px) {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 20px;
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;

export const CreativeServices = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 85px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1b264f;
  margin-bottom: 0;

  @media only screen and (max-width: 991px) {
    font-size: 44px;
    line-height: 73px;
  }
  
  @media only screen and (max-width: 767px) {
    font-size: 33px;
    line-height: 50px;
  }

   @media only screen and (max-width: 450px) {
    font-size: 32px;
    line-height: 50px;
  }

  @media only screen and (max-width: 425px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const MoveReal = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 55px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #d2ad81;

  @media only screen and (max-width: 991px) {
    font-size: 44px;
    line-height: 44px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 26px;
    line-height: 24px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 24px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 24px;
    line-height: 24px;
  }

   @media only screen and (max-width: 425px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const Empower = styled.p`
  width: 50%;
  margin: 30px auto 0;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #1b264f;

  @media screen and (max-width: 1280px) {
    width: 80%;
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
  }

  @media screen and (max-width: 767px) {
    font-size: 21px;
    line-height: 20px;
    margin-top: 0;
  }

  @media only screen and (max-width: 450px) {
    font-size: 21px;
    line-height: 20px;
  }

   @media only screen and (max-width: 425px) {
    font-size: 20px;
    line-height: 21px;
  }
`;

export const ScheduleShootButton = styled.button`
  min-width: 196px;
  padding: 20px 40px;
  background-color: #2C5BFF;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 30px;
  border: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;

  &:hover {
    background-color: #2c7dff;
  }

  @media screen and (max-width: 991px) {
    line-height: 46px;
    padding: 10px 40px;
  }

  @media screen and (max-width: 767px) {
    line-height: 30px;
    margin-top: 16px;
    font-size: 16px;
  }
`;

export const ServiceLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  filter: invert(18%) sepia(26%) saturate(405%) hue-rotate(189deg) brightness(91%) contrast(85%);
  width: calc(100% / 6);
    
  img {
    width: 100%;
  }
  
  @media screen and (max-width: 1280px){
    width: 30%
  }
  
  @media screen and (max-width: 767px) {  
    width: 45%;
  }
`;

export const ServiceLogo = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const LineBreak = styled.hr`
  background-color: #E6E8EF;
  height: 2px !important;
  margin: 40px auto 80px;
  width: 1107px;

  @media screen and (max-width: 1280px){
    width: 700px;
  }
  
  @media screen and (max-width: 767px){
    width: 500px;
    margin-bottom: 30px;
  }
`;

export const SliderContainer = styled.div`
  margin-top: 50px;
  position: relative;
  height: 560px;
  width: 100vw;

  &::before {
    content: '';
    height: 135%;
    width: 45%;
    position: absolute;
    top: -49%;
    left: 29%;
    background-image: url(${LANDING_PAGE.MiddlePageWave});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
     @media screen and (max-width: 1999px) {
      top: -49%;
      left: 29%;
    }
    @media screen and (max-width: 1699px) {
      left: 29%;
    }
    @media screen and (max-width: 1399px) {
      width: 55%;
      top: -47%;
      left: 30%;
    }
    @media screen and (max-width: 1280px) {
      width: 66%;
      top: -49%;
      left: 9%;
    }
    @media screen and (max-width: 979px) {
      height: 125%;
      top: -38%;
    }
    @media screen and (max-width: 901px) {
      left: 3%;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .slick-slider {
    height: calc(100% + 20px);
    overflow-y: hidden;
  }

  .slick-active.slick-current, .slick-slide:not(.slick-cloned) + .slick-cloned.slick-center {
    .slideImage {
      margin-top: 0px !important;
      height: 560px !important;
  
      @media screen and (max-width: 1280px) {
        height: 474px !important;
      }
      @media screen and (max-width: 767px) {
        height: 280px !important;
      }
    }
  }

  .slick-track {
    width: 10000px !important;
  }

  .slick-slide:not(.slick-active) .fade .tooltip {
    display: none !important;
  }

  @media screen and (max-width: 1280px) {
    height: 474px;
  }

  @media screen and (max-width: 767px) {
    height: 280px;
    margin-top: 0px;
  }
`;

export const SliderImageContainer = styled.div`
  margin: 0 10px 0 10px;
  height: 560px;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1280px) {
    height: 396px;
  }

  @media screen and (max-width: 767px) {
    height: 235px;
  }
`;

export const SliderImage = styled.div`
  width: ${({ width, height }) => Math.ceil(width * 560 / height)}px;
  height: 468px;
  border-radius: 12px;
  background-image: url(${(props) => props.imageSource});
  background-position: center center;
  background-size: cover;
  transition: all .2s ease-out;
  margin-top: 91px;
  @media screen and (max-width: 1280px) {
    width: ${({ width, height }) => Math.ceil(width * 474 / height)}px;
    height: 396px;
    margin-top: 77px;
  }
  @media screen and (max-width: 767px) {
    width: ${({ width, height }) => Math.ceil(width * 280 / height)}px;
    height: 235px;
    margin-top: 44px;
  }
`;

export const ImageMarker = styled.img`
  position: absolute;
  top: 20px;
  right: 21px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  @media screen and (max-width: 1280px) {
    top: 22px;
    right: 17px;
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 767px) {
    top: 16px;
    right: 18px;
    width: 35px;
    height: 35px;
  }
`;

