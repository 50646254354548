import React from "react";

import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import { createNotification, updateNotification } from "../../../util/notification_utils";


export default class NotificationFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.notification?.id || "",
      title: this.props.notification?.title || "",
      message: this.props.notification?.message || "",
      link: this.props.notification?.link || "",
      link_text: this.props.notification?.link_text || "",
      icon_image: '',
      icon_image_src: this.props.notification?.icon_image?.url || '',
      loading: false,
      errors: [],
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onPictureChange = this.onPictureChange.bind(this)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onPictureChange(event) {
    const image = event.target.files[0];
    const image_src = URL.createObjectURL(image);

    this.setState({
      icon_image: image,
      icon_image_src: image_src
    });
  }

  onSave() {
    const { title, message, link, link_text, icon_image } = this.state

    const notificationParams = new FormData();
    notificationParams.append("notification[title]", title);
    notificationParams.append("notification[message", message);
    notificationParams.append("notification[link]", link);
    notificationParams.append("notification[link_text]", link_text);
    notificationParams.append("notification[global]", true);
    if (icon_image) notificationParams.append("notification[icon_image]", icon_image);

    const save = this.state.id ?
      updateNotification(notificationParams, this.state.id) :
      createNotification(notificationParams)

    this.setState({ loading: true })

    save.then(notification => {
      this.props.closeModal()
      this.props.onSave(notification)
      this.setState({ loading: false })
    }).catch(response => {
      this.setState({ loading: false })
      this.setState({ errors: response.responseJSON || [] })
    })
  }

  render() {
    return (
      <div className="addServiceModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Notification' : 'Create Notification'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel">Title</label>
              <input className="formInput" onChange={this.onChange} name="title" value={this.state.title} />
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Message</label>
              <input className="formInput" onChange={this.onChange} name="message" value={this.state.message} />
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Link</label>
              <input className="formInput" onChange={this.onChange} name="link" value={this.state.link} />
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Link Text</label>
              <input className="formInput" onChange={this.onChange} name="link_text" value={this.state.link_text} />
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Icon Image</label>
              <div className="galleryPhotoDiv">
                {this.state.icon_image_src &&
                  <img className="settingsAvatar settingsGalleryCover" src={this.state.icon_image_src} />}
                <label className="settingsChangePhotoButton coverUploadButton">
                  <input onChange={this.onPictureChange} type="file" accept="image/png, image/jpeg" />
                  {this.state.icon_image_src ? 'Change' : 'Add image'}
                </label>
              </div>
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">Save</button>
        </div>
      </div>
    );
  }
}