import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import checkmarkCreateProject from "../../../assets/images/checkmarkCreateProject";
// import { usePlacesWidget } from "react-google-autocomplete";
import Autocomplete from "react-google-autocomplete";
import { placements } from "@popperjs/core";

class LocationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minimize: this.props.minimize,
      autocomplete: null,
      input_text: "",
    }

    this.editPlace = this.editPlace.bind(this)
  }


  componentDidMount() {
  }


  // handleInput(field) {
  //   return e => {
  //     if (field === "zipcode" && e.currentTarget.value.length > 5){
  //       this.props.changeParentState({ [field]: e.currentTarget.value.slice(0, 5)})
  //     } else {
  //     this.props.changeParentState({ [field]: e.currentTarget.value })
  //     }
  //   }
  // }

  handleInput(field){
    return e => {
      if (field === "input_text"){
        if (this.props.city){
          this.props.changeParentState({ street: "", city: "", state: "", zip_code: "", latitude: "", longitude: "", formatted_address: "", county: ""})
          this.setState({ [field]: "" })
        } else {
        this.setState({[field]: e.currentTarget.value})
        }
      } else {
        this.props.changeParentState({ [field]: e.currentTarget.value })
      }
    }
  }

  editPlace(place){
    let street_number = "";
    let address = "";
    let city = "";
    let city2 = "";
    let city3 = "";
    let city4 = "";
    let state = "";
    let zip_code = "";
    let county = "";
    let latitude = place.geometry.location.lat();
    let longitude = place.geometry.location.lng();

    let formatted_address = place.formatted_address.split(",").slice(0, place.formatted_address.split(",").length - 1).join(",")

    place.address_components.forEach(component => {
      if (component.types[0] === "street_number"){
        street_number = component.long_name
      } else if (component.types[0] === "route"){
        address = component.long_name
      } else if (component.types[0] === "locality") {
        city = component.long_name
      } else if (component.types[0] === "sublocality_level_1") {
        city4 = component.long_name
      } else if (component.types[0] === "administrative_area_level_3"){
        city2 = component.long_name
      } else if (component.types[0] === "neighborhood") {
        city3 = component.long_name
      } else if (component.types[0] === "administrative_area_level_1") {
        state = component.short_name
      } else if (component.types[0] === "postal_code") {
        zip_code = component.long_name
      } else if (component.types[0] === "administrative_area_level_2"){
        county = component.long_name
      }
    })

    if (street_number === ""){
      street_number = this.state.input_text.split(" ")[0]
    }

    this.props.changeParentState({formatted_address: formatted_address, street: `${street_number} ${address}`, apartment: "", city: city4 || city3 || city || city2, state: state, zipcode: zip_code, latitude: latitude, longitude: longitude, county: county})
  }

  render() {

    let streetErrorClass = "";
    let apartmentErrorClass = "";
    let cityErrorClass = "";
    let stateErrorClass = "";
    let zipcodeErrorClass = "";

    let streetErrors = [];
    let apartmentErrors = [];
    let cityErrors = [];
    let stateErrors = [];
    let zipcodeErrors = [];

    (this.props.errors || []).forEach((error) => {

      if (error[0] === 1) {
        streetErrors.push(<li>{error[1]}</li>)
        streetErrorClass = "formInputError"
      }

      else if (error[0] === 2) {
        cityErrors.push(<li>{error[1]}</li>)
        cityErrorClass = "formInputError"
      }
      else if (error[0] === 3) {
        stateErrors.push(<li>{error[1]}</li>)
        stateErrorClass = "formInputError"
      }
      else if (error[0] === 4) {
        zipcodeErrors.push(<li>{error[1]}</li>)
        zipcodeErrorClass = "formInputError"
      }
      else if (error[0] === 0) {
        backendErrors.push(<li>{error[1]}</li>)
      }

    })


    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark =""

    if (this.state.minimize){
      icon = <div key="2"><i className="fas fa-plus"></i></div>
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 1){
        checkmark = "createProjectCheckmarkShow"
      }

    }

    if (this.props.inComponent){
      return (
      <div>
        {this.props.virtual_staging_homepage ? "" : <div className="settingsComponentHeading">{this.props.title || 'Property Location'}</div>}
          <div className={this.props.settings_location_prices ? "" : "inputGrouping topGrouping"} id={minimizeId}>
            <div className={this.props.settings_location_prices ? "" : "propertyLocationInput1"}>
              {!this.props.settings_location_prices && <label id="formLabel"> Property Address </label>}
              <Autocomplete className="formInput"
                onPlaceSelected={(place) => {
                  this.editPlace(place)
                }}
                id={streetErrorClass}
                options={{ types: "address" }}
                value={this.props.formatted_address ? this.props.formatted_address : this.state.input_text}
                onChange={this.handleInput("input_text")}
              />
              {/* <input className="formInput" id={streetErrorClass} onChange={this.handleInput("street")} type="string" placeholder="Street" value={this.props.street} /> */}
              <ul className="errorUl" id={minimizeId}>{streetErrors}</ul>
            </div>
            {/* <Autocomplete
            onPlaceSelected={(place) => {
              this.editPlace(place)
            }}
            options={{types: "address" }}
          />; */}
            {this.props.virtual_staging_homepage ? "" : <div className="propertyLocationInput2">
              <label id="formLabel"> Apartment </label>
              <input className="formInput" id={apartmentErrorClass} onChange={this.handleInput("apartment")} type="string" placeholder="Not Required" value={this.props.apartment} />
              <ul className="errorUl" id={minimizeId}>{apartmentErrors}</ul>
            </div>}
         </div>
       </div>
      )
    } else {
      return (
        <div className="projectLocationComponent realProjectLocationComponent" id={this.props.createProjectLoggedIn ? "createProjectLocationComponent" : ""}>
          <div className="projectLocationHeadingGrouping" id={minimized}>
            <div className="projectLocationHeadingMinimizedGrouping">
              <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject}/></div>
              <div className="settingsComponentHeading">{this.props.title || 'Property Location'}</div>
            </div>
            {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
          </div>
          {/* <div className="subServicesHeadingBottomDivider" id={minimizeId}/> */}
          <div className="inputGrouping topGrouping" id={minimizeId}>
            <div className="propertyLocationInput1">
              <label id="formLabel"> {this.props.homepageModal ? "Enter Address to View Services & Pricing" : "Address"} </label>
              <Autocomplete className="formInput"
                onPlaceSelected={(place) => {
                  this.editPlace(place)
                }}
                id={streetErrorClass}
                options={{ types: "address" }}
                value={this.props.formatted_address ? this.props.formatted_address : this.state.input_text}
                onChange={this.handleInput("input_text")}
              />
              {/* <input className="formInput" id={streetErrorClass} onChange={this.handleInput("street")} type="string" placeholder="Street" value={this.props.street} /> */}
              <ul className="errorUl" id={minimizeId}>{streetErrors}</ul>
            </div>
            <div className="propertyLocationInput2">
              <label id="formLabel"> Apartment </label>
              <input className="formInput" id={apartmentErrorClass} onChange={this.handleInput("apartment")} type="string" placeholder="Not Required" value={this.props.apartment} />
              <ul className="errorUl" id={minimizeId}>{apartmentErrors}</ul>
            </div>
          </div>
        </div>
      )
   }
 } 
}

export default withRouter(LocationComponent);