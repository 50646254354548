import React from "react";
import {fetchActiveTimeSlots} from "../../../util/settings_api_util";
import { fetchAllUsers } from "../../../util/users_api_util";


export default class SettingsTimeSlots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractors: [],
      user_id: this.props.currentUser.roles.admin ? this.props.user_id : this.props.currentUser.id,
      blockedTimeSlots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {}},
      activeTimeSlots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} },
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.updateBlockedTimes = this.updateBlockedTimes.bind(this)
    this.googleCalendarIntegration = this.googleCalendarIntegration.bind(this)
    this.changeContractor = this.changeContractor.bind(this)
  }

  componentDidMount() {
    if (this.state.user_id) {
      this.props.fetchBlockedTimeSlots(this.state.user_id).then(blockedTimes =>
        this.setState({ blockedTimeSlots: blockedTimes })).then(() => fetchActiveTimeSlots(false, true)).then(activeTimes => this.setState({ activeTimeSlots: activeTimes }))
    } else {
      fetchAllUsers("contractor", null, null, null, 100000).then(contractors => {
        this.setState({ contractors: contractors })
      })
      fetchActiveTimeSlots(false, true).then(activeTimes => this.setState({ activeTimeSlots: activeTimes }))
    }
  }

  changeContractor(e) {
    e.preventDefault()
    if (e.target.value === "") {
      this.setState({ user_id: null, blockedTimeSlots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} } })
    } else {
      this.props.fetchBlockedTimeSlots(e.target.value).then(blockedTimes =>
        this.setState({ blockedTimeSlots: blockedTimes }))
      this.setState({ user_id: e.target.value })
    }
  }
  
  handleCheckbox(day, slot){
    let blockedTimeSlots = this.state.blockedTimeSlots

    if (this.state.blockedTimeSlots[day][slot]){
      blockedTimeSlots[day][slot] = !this.state.blockedTimeSlots[day][slot] 
    } else {
      blockedTimeSlots[day][slot] = true
    }
    this.setState({blockedTimeSlots: blockedTimeSlots})
  }

  handleInput(field) {
    return e => this.setState({ [field]: !this.state[field] });
  }

  googleCalendarIntegration(){
    const newWindow = window.open("calendars/authorize_access", '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  updateBlockedTimes(){
    this.props.updateBlockedTimes(this.state.blockedTimeSlots, this.state.user_id).then(() => {
      this.props.showToast("Blocked time slots successfully updated")
    })
  }

  render() {
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];
    let d = new Date();
    // let offset = d.getTimezoneOffset() / 60
    // let slots = [9+offset, 11+offset, 13+offset, 15+offset, 17+offset, 18+offset]
    // let slots = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
    // let times = ["8:00 am", "9:00 am", "10:00 am","11:00 am", "12:00 pm", "1:00 pm", "2:00 pm", "3:00 pm", "4:00 pm", "5:00 pm", "6:00 pm", "7:00pm"];
    let slots = [8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20]
    let times = ["8:00 am", "8:30 am", "9:00 am", "9:30 am", "10:00 am", "10:30 am", "11:00 am", "11:30 am", "12:00 pm", "12:30 pm", "1:00 pm", "1:30 pm", "2:00 pm", "2:30 pm", "3:00 pm", "3:30 pm", "4:00 pm", "4:30 pm", "5:00 pm", "5:30pm", "6:00 pm","6:30pm","7:00pm","7:30pm", "8:00pm" ];
    let timeSlotsDiv = [];
    let timeSlots = [];
    let j = days.length;
    
    days.forEach((day, i)=> {
     timeSlotsDiv.push(<div className="settingsTimeSlotDay" key={i}>{day}</div>)
     timeSlots = []
     times.forEach((time, i) => {
       timeSlots.push(<label className="timeSlotLabel timeSlotLabel2" key={i}><input type="checkbox" className="formCheckBox timeSlotCheckbox" checked={this.state.blockedTimeSlots[day][slots[i]] || false} onChange={() => this.handleCheckbox(day, slots[i])} /> <span className="timeSlotText" id={this.state.activeTimeSlots[day][slots[i]] ? "activeTimeSlot" : ""}>{time}</span></label>)
     })
     timeSlotsDiv.push(<div className="timeSlotDiv" key={j}>{timeSlots}</div>)
     j += 1;
    })

    let connectText = <div className="connectCalendarText">Connect your google calendar </div>
    let connectButton = <button className="settingsButton connectNowButton" onClick={this.googleCalendarIntegration}>Connect now</button>

    if (this.props.currentUser.google_calendar){
      connectText = <div className="connectCalendarText">Your google calendar is connected </div>
      connectButton = <button className="settingsButton connectNowButton" onClick={this.googleCalendarIntegration}>Update</button>
    }

    if (this.state.user_id) {

    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className={this.props.currentUser.roles.admin ? "settingsPreferencesAdmin" : "settingsPreferences"}>
          <div className="settingsComponentHeading">Time Slots</div>
          <div className="settingsComponentSubHeading2">Select all time slots during the week that you are <b>unavailable</b>.</div>
          <div className="settingsComponentSubHeading2">If a timeslot is not bold then Modern Angles does not currently offer that time to clients but this can change in the future.</div>
          <div className="settingsPreferencesGroup1">
            {timeSlotsDiv}
          </div>

          <div className="notice mt-4">
            {this.props.currentUser.roles.admin ? "Times are mentioned in the contractors timezone" : `Times are mentioned in ${this.props.currentUser.timezone} timezone`}
          </div>

          <button onClick={this.updateBlockedTimes} className="settingsButton preferencesButton settingsTimeSlotsButton">Save changes</button>
          {this.props.currentUser.roles.admin ? "" : <div className="googleIntegration">
            {connectText}
            {connectButton}
          </div>}
        </div>
      </div>
    );
    }
    else {
      return (
        <div className="settingsPreferencesGroupingDiv">
          <div className="settingsPreferences">
            {this.props.currentUser.roles.admin ? <select className="formInput contractorSelect" value={this.state.user_id} onChange={this.changeContractor}>
              <option value="">Contractors</option>
              {
                Object.values(this.state.contractors).map(contractor => {
                  return <option value={contractor.id}>{`${contractor.firstName} ${contractor.lastName}`}</option>
                })
              }
            </select> : ""}
          </div>
        </div>
      );
    }
  }
}