import * as APIUtil from "../util/virtual_staging_api_util";


export const RECEIVE_ALL_CATEGORIES = "RECEIVE_ALL_CATEGORIES";
export const RECEIVE_CATEGORY = "RECEIVE_CATEGORY";
export const RECEIVE_ALL_PHOTOS = "RECEIVE_ALL_PHOTOS";
export const RECEIVE_ALL_PRODUCTS = "RECEIVE_ALL_PRODUCTS";
export const RECEIVE_PURCHASE = "RECEIVE_PURCHASE";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_INFO = "ADD INFO";

export const addInfo = info => {
  return {
    type: ADD_INFO,
    info
  }
}

export const receiveCategories = (categories) => ({
  type: RECEIVE_ALL_CATEGORIES,
  categories
});

export const receiveCategory = (category) => ({
  type: RECEIVE_CATEGORY,
  category
});

export const receivePhotos = (photos) => ({
  type: RECEIVE_ALL_PHOTOS,
  photos
})

export const receiveProducts = (products) => ({
  type: RECEIVE_ALL_PRODUCTS,
  products
})

export const receivePurchase = (purchase) => ({
  type: RECEIVE_PURCHASE,
  purchase
})

export const removeCategory = (id) => ({
  type: DELETE_CATEGORY,
  id
})

export const fetchVirtualStagingInfo = (project_id) => dispatch => {
  return APIUtil.fetchVirtualStagingInfo(project_id).then(
    (virutal_staging_info) => {
      dispatch(receiveCategories(virutal_staging_info.categories));
      return virutal_staging_info
    }).then((virtual_staging_info) => dispatch(receivePhotos(virtual_staging_info.photos)));
};

export const fetchVirtualStagingProducts = (category_id) => dispatch => {
  return APIUtil.fetchVirtualStagingProducts(category_id).then(
    products => dispatch(receiveProducts(products)))
};

export const createVirtualStagingPurchase = (orders) => dispatch => {
  return APIUtil.createVirtualStagingPurchase(orders).then(
    purchase => dispatch(receivePurchase(purchase))
  )
}

export const fetchCategories = () => dispatch => {
  return APIUtil.fetchCategories().then(
    categories => dispatch(receiveCategories(categories))
  )
}

export const fetchCategoriesSettings = () => dispatch => {
  return APIUtil.fetchCategoriesSettings().then(
    categories => dispatch(receiveCategories(categories))
  )
}

export const createCategory = (category_data) => dispatch => {
  return APIUtil.createCategory(category_data).then(
    category => dispatch(receiveCategory(category))
  )
}

export const updateCategory = (category_data, category_id) => dispatch => {
  return APIUtil.updateCategory(category_data, category_id).then(
    category => dispatch(receiveCategory(category))
  )
}

export const deleteCategory = (id) => dispatch => {
  return APIUtil.deleteCategory(id).then(
    category => dispatch(removeCategory(category.id))
  )
}


// export const createProduct = (product_data) => dispatch => {
//   return APIUtil.createProduct(product_data).then(
//     category => dispatch(receiveCategory(category))
//   )
// }

export const updateProducts = (product_data, category_id) => dispatch => {
  return APIUtil.updateProducts(product_data, category_id).then(
    category => {dispatch(receiveCategory(category)); dispatch(addInfo(category))}
  )
}

// export const deleteProduct = (id) => dispatch => {
//   return APIUtil.deleteProduct(id).then(
//     category => dispatch(receiveCategory(category))
//   )
// }

export const notifyVirtualStagingCompleted = (project_id, virtual_staging_batch_id) => dispatch => {
  return APIUtil.notifyVirtualStagingCompleted(project_id, virtual_staging_batch_id)
}
