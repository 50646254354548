import React from "react";
import { Route, Link } from "react-router-dom";


export default class SettingsPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentInfo: {},
      createPayment: false,
      errors: []
    }
    this.handleInput = this.handleInput.bind(this)
    // this.updatePayment = this.updatePayment.bind(this)
    this.createPayment = this.createPayment.bind(this)
  }

  componentDidMount(){
    this.props.fetchPaymentInfo(this.props.currentUser.id).then(paymentInfo =>
      this.setState({ paymentInfo: paymentInfo, createPayment: paymentInfo === {} })
      ).then(() => {
        let code = "XXXX-XXXX-XXXX-"
        let paymentInfo = this.state.paymentInfo
        if (paymentInfo["last_four"]){
        paymentInfo["last_four"] = code + paymentInfo["last_four"]
        }
        if (paymentInfo["cvc"]){
          paymentInfo["cvc"] = paymentInfo["cvc"]?.length === 3 ? "XXX" : "XXXX"
        }
        this.setState({ paymentInfo: paymentInfo })
      })
  }

  handleInput(field) {
    return e => {
      let paymentInfo = this.state.paymentInfo

      if ((field === "cvc" || field === "last_four") && paymentInfo[field]?.length > 0 && paymentInfo[field][0] === "X"){
        if (e.currentTarget.value.length === 2 || e.currentTarget.value.length === 18){
          paymentInfo[field] = ""
        } else {
          paymentInfo[field] = e.currentTarget.value[e.currentTarget.value.length - 1]
        }
      }
      else {
        if ((field === "cvc" && e.currentTarget.value.length > 4) || (field === "last_four" && e.currentTarget.value.length > 16)){

        } else {
          paymentInfo[field] = e.currentTarget.value
        }
      }
      this.setState({ paymentInfo: paymentInfo });
    }
  }

  createPayment(){
    let errors = []
    let paymentInfo = this.state.paymentInfo

    paymentInfo["user_id"] = this.props.currentUser.id


    if (!paymentInfo["cardholder_name"] || paymentInfo["cardholder_name"].length === 0) {
      errors.push([0, "Name can't be blank"])
    }

    if (!paymentInfo["cvc"] || (paymentInfo["cvc"].length != 3 && paymentInfo["cvc"].length != 4)) {
      errors.push([1, "CVC code must be 3 or 4 digits long"])
    }

    if (paymentInfo["cvc"] === "XXX" || paymentInfo["cvc"] === "XXXX") {
      errors.push([1, "CVC code required to make changes to payment info"])
    }
    
    if (!paymentInfo["last_four"] || (paymentInfo["last_four"].length != 16 && paymentInfo["last_four"].length != 15 && paymentInfo["last_four"].length != 19)) {
      errors.push([2, "Card number must be 15 or 16 digits long"])
    }

    if (paymentInfo["last_four"][0] === "X"){
      errors.push([2, "Full card number required to make changes to payment info"])
    }

    if (paymentInfo["exp_month"] === "Month") {
      errors.push([3, "Expiration month is required"])
    }

    if (paymentInfo["exp_year"] === "Year") {
      errors.push([4, "Expiration year is required"])
    }

    

    if (errors.length === 0){
      this.props.createPayment(paymentInfo).then(() => {
        if (this.props.errors.length === 0) {
          this.props.showToast("Payment successfully created")
          this.setState({ errors: [] })
        }
      })
    } else {
      this.setState({errors: errors})
    }
  }



  render() {

    let cardHolderNameErrorClass = "";
    let cardNumberErrorClass = "";
    let expMonthErrorClass = "";
    let expYearErrorClass = "";
    let cvcErrorClass = "";

    let cardNumberErrors = [];
    let cardHolderNameErrors = [];
    let expMonthErrors = [];
    let expYearErrors = [];
    let cvcErrors = [];

    const yearOptions = [];
    let d = new Date();
    let n = d.getFullYear();
    for (let i = n; i < n+20 ; i++) {
      yearOptions.push(<option key={i} value={i}>{i}</option>);
    }

    let button = <button onClick={this.createPayment} className="settingsButton paymentMethodButton">Update payment method</button>
    if (this.state.createPayment === true){
      button = <button onClick={this.createPayment} className="settingsButton paymentMethodButton">Create payment method</button>
    }

    this.state.errors.forEach((error) => {

      if (error[0] === 0) {
        cardHolderNameErrors.push(<li>{error[1]}</li>)
        cardHolderNameErrorClass = "formInputError"
      }

      else if (error[0] === 1) {
        cvcErrors.push(<li>{error[1]}</li>)
        cvcErrorClass = "formInputError"
      }

      else if (error[0] === 2) {
        cardNumberErrors.push(<li>{error[1]}</li>)
        cardNumberErrorClass = "formInputError"
      }

      else if (error[0] === 3) {
        expMonthErrors.push(<li>{error[1]}</li>)
        expMonthErrorClass = "formInputError"
      }

      else if (error[0] === 4) {
        expYearErrors.push(<li>{error[1]}</li>)
        expYearErrorClass = "formInputError"
      }
    })


    return (
      <div className="settingsComponentGroupingDiv">
        <div className="settingsCompany">
          <div className="settingsComponentHeading">Payment Information</div>
          <div className="refundDisclaimer">Refunds (-5% processing fee) and full account credits are available if the appointment is canceled. Rescheduling can be done from the project page.</div>
          <div className="settingsCategoryGroup1">
            <div className="inputGrouping">
              <div className="settingsCompanyInput">
                <label id="formLabel"> Cardholder Name </label>
                <input className="formInput" id={cardHolderNameErrorClass} onChange={this.handleInput("cardholder_name")} type="string" placeholder="Cardholder Name" value={this.state.paymentInfo["cardholder_name"] || ""} />
                <ul className="errorUl">{cardHolderNameErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
                <label id="formLabel"> Card Number </label>
                <input className="formInput" id={cardNumberErrorClass} onChange={this.handleInput("last_four")} type="string" placeholder="Card Number" value={this.state.paymentInfo["last_four"] || ""} />
                <ul className="errorUl">{cardNumberErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
                <label id="formLabel"> CVC </label>
                <input className="formInput" id={cvcErrorClass} onChange={this.handleInput("cvc")} type="string" placeholder="CVC" value={this.state.paymentInfo["cvc"] || ""} />
                <ul className="errorUl">{cvcErrors}</ul>
              </div>
            </div>
            <div className="inputGrouping">
              <div className="settingsCompanyInput">
                <label id="formLabel"> Expiration Month</label>
                <select className="formInput" id={expMonthErrorClass} onChange={this.handleInput("exp_month")} name="Month" value={this.state.paymentInfo["exp_month"] || "Month"} >
                  <option value="Month" disabled={true}>Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <ul className="errorUl">{expMonthErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
                <label id="formLabel"> Expiration Year </label>
                <select className="formInput" id={expYearErrorClass} onChange={this.handleInput("exp_year")} name="Year" value={this.state.paymentInfo["exp_year"] || "Year"} >
                  <option value="Year" disabled={true}>Year</option>
                  {yearOptions}
                </select>
                <ul className="errorUl">{expYearErrors}</ul>
              </div>
            </div>
          </div>
          {button}
        </div>
      </div>
    );
  }
}