import React, {useState} from 'react'
import {Footer} from "../footer";

export const GeneralInfo = (props) => {
  const [data, setData] = useState(props.data || {})

  const onChange = (fieldName) => {
    return (e) => {
      e.preventDefault()
      setData({...data, [fieldName]: e.target.value})
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    props.onNext(data)
  }

  const fields = [
    {
      field: 'firstName',
      label: 'First name',
      required: true
    },
    {
      field: 'lastName',
      label: 'Last name',
      required: true
    },
    {
      field: 'email',
      label: 'Email',
      type: 'email',
      required: true
    },
    {
      field: 'phone',
      label: 'Phone number',
      required: false
    }
  ]

  return <form onSubmit={onSubmit}>
    <div className="schedule-demo-title">
      Enter your information to get started.
    </div>

    <div className="schedule-demo-form-fields">
      {fields.map(field => (
        <div className="schedule-demo-form-field" key={field.field}>
          <label id="formLabel" className={`formLabel required`}>
            {field.label}
          </label>

          <input value={data[field.field]} onChange={onChange(field.field)} type={field.type}
                 className="formInput" placeholder="Content" required={field.required}/>
        </div>
      ))}
    </div>

    <Footer onBack={props.onBack}/>
  </form>
}