import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SettingsAds from "./settings_ads";
import {openModal} from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message)),
  openModal: (modal, props) => {
    dispatch(openModal(modal, props));
  },
});

export default withRouter(connect(msp, mdp)(SettingsAds));