import styled from 'styled-components';

export const FloatingInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const FloatingInputInput = styled.input`
  border: 2px solid #ebeaed;
  border-radius: 12px;
  margin: 20px 0px;
  height: 60px;
  padding: 17px 17px 17px 20px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #353844;

  &:focus {
    border: 2px solid #ebeaed;
    outline: none;
  }
  
  &:focus + label, &:not([value=""]) + label, &:not([value]) + label {
    transform: translate(0, 1px);
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1b264f;
  }
`;

export const FloatingInputLabel = styled.label`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #353844;
  background-color: #ffffff;
  padding: 3px 7px;

  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  left: 17px;
  top: 11px;
`;
