import React, {useEffect, useState} from "react";
import img from '../../../../../assets/images/blog/email-form-image.png'
import {AdModalForm} from "../../pages/adModal/AdModalForm";

export const EmailForm = ({block, showToast}) => {
  return <AdModalForm showToast={showToast} title={block.data.text}
                      button_text={'Submit'}
                      imageUrl={img}
                      isVertical={false}
                      adId={block.data.adId}
                      type={'horizontal'}
                      show={true}
                      ad_type={"slide_out"}
                      onSubscribed={() => {}}
  />
}