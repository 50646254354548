import TopSectionImage1 from './topSection/1.png';
import TopSectionImage2 from './topSection/2.png';
import TopSectionImage3 from './topSection/3.png';
import TopSectionImage4 from './topSection/4.png';
import TopSectionImage5 from './topSection/5.png';

import CyanCircle from './secondSection/cyan-circle.png';
import GreenCircle from './secondSection/green-circle.png';
import PinkCircle from './secondSection/pink-circle.png';

import ThirdSectionImage1 from './thirdSection/1.png';
import ThirdSectionImage2 from './thirdSection/2.png';
import ThirdSectionImage3 from './thirdSection/3.png';
import ThirdSectionImage4 from './thirdSection/4.png';
import ThirdSectionImage5 from './thirdSection/5.png';

import CIRCLE from '../aboutPage/circle.svg';
import SMALL_CIRCLE from '../aboutPage/small-circle.png';

import FAQ from './faqSection/faq.jpg';
import ArrowUp from './faqSection/up.png';
import ArrowDown from './faqSection/down.png';

const TOP_SECTION = {
  TopSectionImage1,
  TopSectionImage2,
  TopSectionImage3,
  TopSectionImage4,
  TopSectionImage5,
};

const SECOND_SECTION = {
  CyanCircle,
  GreenCircle,
  PinkCircle
}

const THIRD_SECTION = {
  ThirdSectionImage1,
  ThirdSectionImage2,
  ThirdSectionImage3,
  ThirdSectionImage4,
  ThirdSectionImage5,
}

const FAQ_SECTION = {
  FAQ, ArrowUp, ArrowDown
}

export {
  TOP_SECTION, SECOND_SECTION, THIRD_SECTION, CIRCLE, SMALL_CIRCLE, FAQ_SECTION
};