import { RECEIVE_CURRENT_USER, LOGOUT_CURRENT_USER } from "../actions/session_actions";
import {resetDefaultTimezone, setDefaultTimezone} from "../util/timezone_utils";

const sessionReducer = (state = { currentUser: null }, action) => {
  Object.freeze(state);
  switch (action.type) {
    case "RECEIVE_CURRENT_USER":
      if (action.user){
        setDefaultTimezone(action.user.timezone)
        return { currentUser: action.user }
      }
      else {
        return state;
      }
    case "LOGOUT_CURRENT_USER":
      resetDefaultTimezone()
      return { currentUser: null };
    default:
      return state;
  }
};

export default sessionReducer;