import React, { useMemo, useState, useEffect } from "react";
import { motion }  from "framer-motion";

const FadeInAnimation = ({
  children,
  duration = 0.4,
  yOffset = 0,
  easing = "easeInOut",
  delayOrder,
  inView,
  ...otherProps
}) => {
  const [delay, setDelay] = useState(0.25);

  useEffect(() => {
    if (delayOrder) return setDelay(delayOrder * 0.4);
  }, [delayOrder]);

  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease: easing
    }),
    [delay, easing]
  );

  const variants = {
    hidden: { 
      y: yOffset,
      opacity: 0,
      transition
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      variants={variants}
      {...otherProps}
    >
      {children}
    </motion.div>
  );
};

export default FadeInAnimation;
