import React from "react"
import {fetchInvoices} from "../util/invoices_api_utils"
import NavbarContainer from "./navbar/navbar_container"
import PageHeader from "./page_header"
import {withRouter} from "react-router-dom"
import {debounce} from "debounce"
import InvoiceItem from "./invoices/invoice_item"
import {fullAddress} from "../util/project_utils"
import {connect} from "react-redux"
import LogoSpinner from "../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { v4 as uuidv4 } from 'uuid';

class Invoices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoices: [],
      displayedInvoices: [],
      total_invoices: 0,
      searchAddress: '',
      searchClient: '',
      searchPaidStatus: '',
      searchFrom: '',
      searchTo: '',
      regular: true,
      page: 0,
      pageSize: 10,
      loading: false,
      requestId: null
    }
    this.onSearchChange = this.onSearchChange.bind(this)
    this.debouncedRecalculateDisplayedInvoices = debounce(this.recalculateDisplayedInvoices, 500)
    this.fetchInvoices = this.fetchInvoices.bind(this)
    this.debounceFetchInvoices = debounce(() => this.fetchInvoices(), 200)
  }

  componentDidMount() {
    this.debounceFetchInvoices()
  }

  componentDidUpdate(_, prevState){
    if (prevState.regular !== this.state.regular || prevState.page !== this.state.page) {
      this.debounceFetchInvoices()
    }
  }

  onSearchChange(field) {
    return (e) => {
      e.preventDefault()
      this.setState({[field]: e.target.value, page: 0}, () => {
        if ((field != "searchClient" && field != "searchAddress") || (field === "searchClient" && (e.target.value.length > 2 || e.target.value.length === 0)) || (field === "searchAddress" && (e.target.value.length > 2 || e.target.value.length === 0))) {
          this.debounceFetchInvoices()
        }
      })
   }
  }

  fetchInvoices() {
    const {
      regular,
      searchAddress,
      searchClient,
      searchFrom,
      searchTo,
      searchPaidStatus,
      page,
      pageSize
    } = this.state;

    // Make a request to your backend API to fetch invoices
    this.setState({loading: true})

    const requestId = uuidv4(); // Generate a unique ID for this request
    this.setState({ requestId }); // Store it in the state

    fetchInvoices(
      regular,
      searchAddress,
      searchClient,
      searchFrom,
      searchTo,
      searchPaidStatus,
      page,
      pageSize
    ).then((invoices) => {
      const invoicesWithFullAddress = invoices.map((invoice) => {
        return {
          ...invoice,
          fullAddress: invoice.address.formatted_address
            ? invoice.address.formatted_address
            : fullAddress(invoice.address),
          date: invoice.created_at.slice(0, 10),
        };
      });

      if (this.state.requestId === requestId) {
        this.setState({
          invoices: invoicesWithFullAddress,
          total_invoices: invoices.length > 0 ? invoices[0].total_invoices : 0,
          loading: false
        });
      }
    });
  }

  changePage(page){
    this.setState({page: page})
  }

  render() {
    const {currentUser} = this.props
    const filterColClass = currentUser.roles.admin ? 'col-md-4' : 'col-md-6'
    const {page} = this.state

    let pageLast = 1

    if (this.state.total_invoices > this.state.pageSize) {
      pageLast = parseInt(this.state.total_invoices % this.state.pageSize > 0 ? this.state.total_invoices / this.state.pageSize + 1 : this.state.total_invoices / this.state.pageSize)
    }

    let pages = [];
    const startPage = Math.max(page - 5, 0);
    const endPage = Math.min(startPage + 9, pageLast - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    let pagesDiv = pages.map((new_page) => (
      <div
        className={
          page === new_page ? "pageNumberDivSelected" : "pageNumberDiv"
        }
        onClick={() => this.changePage(new_page)}
        key={new_page}
      >
        {new_page + 1}
      </div>
    ));

    return <div className="dashboardFullPageDiv">
      <NavbarContainer/>
      <div className="dashboardMainDiv">
        <PageHeader title="Invoices"
                    subtitle="View and download your Modern Angles invoices."
                    showNotifications={true}/>
        <div className="sectionCard">
          <div className="sectionCardHeader">
            <h5 className="photographersCardTitle">
              Your Invoices
            </h5>

            <div className="searchBarDiv invoicesSearch row">
              <div className={`col-12 ${filterColClass}`}>
                <input className="formInput" onChange={this.onSearchChange('searchAddress')} type="text"
                       placeholder="Property Address" value={this.state.searchAddress}/>
              </div>

              {currentUser.roles.admin && <div className={`col-12 ${filterColClass}`}>
                <input className="formInput" onChange={this.onSearchChange('searchClient')} type="text"
                       placeholder="Client name" value={this.state.searchClient}/>
              </div>}
              <div className="col-6 col-md-4">
                <input className="formInput" type="date" onKeyDown={(e) => e.preventDefault()}
                       onChange={this.onSearchChange('searchFrom')}
                       value={this.state.searchFrom}/>
                <i className="fas fa-calendar calendarIcon"/>
              </div>
              <div className="col-6 col-md-4">
                <input className="formInput" type="date" onKeyDown={(e) => e.preventDefault()}
                       onChange={this.onSearchChange('searchTo')}
                       value={this.state.searchTo}/>
                <i className="fas fa-calendar calendarIcon"/>
              </div>
              <div className={`col-6 ${filterColClass}`}>
                <select className="formInput" onChange={this.onSearchChange('searchPaidStatus')}
                  value={this.state.searchPaidStatus}>
                  <option value="">All</option>
                  <option value="true">Paid</option>
                  <option value="false">Unpaid</option>
                </select>
              </div>
              <div className="col-6 col-md-4">
                <select
                  className="formInput"
                  onChange={this.onSearchChange('pageSize')}
                  value={this.state.pageSize}
                >
                  <option value={10}>10 per page</option>
                  <option value={20}>20 per page</option>
                  <option value={50}>50 per page</option>
                </select>
              </div>
            </div>
          </div>

          {this.state.loading ? <div className="invoiceLogoSpinner">
                    <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                  </div> : <div className="sectionCardBody">
            <div className="table-responsive">
              <table className="table">
                <thead>
                <tr>
                  {currentUser.roles.admin ? <th>Name</th> : ""}
                  <th>Address</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th className="sortableHeading" onClick={() => this.setState({regular: !this.state.regular})}>Date</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                {this.state.invoices.map(invoice => <InvoiceItem invoice={invoice} currentUser={currentUser} key={invoice.id}/>)}

                {this.state.invoices.length > 0 && (
                  <tr>
                    <td className="font-bold">Total</td>
                    <td className="font-bold" colSpan={4}>
                      ${this.state.invoices.reduce((res, i) => res + parseFloat(i.total), 0).toFixed(2)}
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
              <div className="pagesDiv">
                <div className="pages-container">
                    <div className={page > 0 ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={() => page > 0 ? this.setState({page: this.state.page - 1}) : ""}>Previous</div>
                  {pagesDiv}
                    <div className={page + 1 != pageLast ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={() => page + 1 != pageLast ? this.setState({ page: this.state.page + 1 }) : ""}>Next</div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  }
}

const msp = (state) => ({
  currentUser: state.entities.users[state.session.currentUser.id]
})

export default withRouter(connect(msp)(Invoices))
