import React from 'react';

import {
  FloatingInputWrapper,
  FloatingInputInput,
  FloatingInputLabel,
} from './styles';

const FloatingInput = (props) => {
  const { label, ...rest } = props;
  return (
    <FloatingInputWrapper>
      <FloatingInputInput {...rest} />
      <FloatingInputLabel>{label}</FloatingInputLabel>
    </FloatingInputWrapper>
  );
};

export default FloatingInput;
