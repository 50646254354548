import React from 'react';
import {LocationsDescription, LocationsHeaderInnerWrapper, LocationsHeaderWrapper, LocationsTitle} from "./styles";

const AboutHeader = () => {
  return (
    <LocationsHeaderWrapper>
      <LocationsHeaderInnerWrapper>
        <LocationsTitle>Our Service Area</LocationsTitle>
        <LocationsDescription>Modern Angles has team members all of over the United States ready to get working for
          you.</LocationsDescription>
      </LocationsHeaderInnerWrapper>
    </LocationsHeaderWrapper>
  );
};

export default AboutHeader;
