import React, {useState} from 'react'
import {Footer} from "../footer";
import avatarImg from "../../../../../assets/images/scheduleDemo/anthony1.png"
import leftImg from "../../../../../assets/images/scheduleDemo/left.svg"
import rightImg from "../../../../../assets/images/scheduleDemo/right.svg"
import {formatDateIso, formatDatetime} from "../../../util/date_util";
import dayjs from "dayjs";
import {times} from "../utils";

const DISPLAYED_DATES_COUNT = 6

export const Calendar = (props) => {
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(props.data?.date ? new Date(props.data.date) : new Date())
  const [time, setTime] = useState(props.data?.time || '')
  const [displayedDates, setDisplayedDates] = useState(calculateDisplayedDates(date))

  function calculateDisplayedDates(date) {
    const dates = [date]

    for (let i = 1; i < DISPLAYED_DATES_COUNT; i++) {
      dates.push(dayjs(date).add(i, 'days').toDate())
    }

    return dates
  }

  const onNext = (e) => {
    e.preventDefault()
    const nextDate = dayjs(date).add(DISPLAYED_DATES_COUNT, 'days').toDate()
    setDisplayedDates(calculateDisplayedDates(nextDate))
    setDate(nextDate)
    setTime('')
  }

  const onPrev = (e) => {
    e.preventDefault()
    const prevDate = dayjs(date).subtract(DISPLAYED_DATES_COUNT, 'days').toDate()
    setDisplayedDates(calculateDisplayedDates(prevDate))
    setDate(prevDate)
    setTime('')
  }

  const onSelectDate = (e, newDate) => {
    e.preventDefault()
    setDate(newDate)
    setTime('')
  }

  const onSelectTime = (e, time) => {
    e.preventDefault()
    setTime(time)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    const isoDate = formatDateIso(date)
    props.onNext({date: isoDate, time})
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return <form onSubmit={onSubmit}>
    <div className="schedule-demo-title">
      What date and time would you prefer for the consultation?
    </div>

    <div className="schedule-demo-calendar-photographer">
      <div className="schedule-demo-calendar-photographer-img">
        <img src={avatarImg}/>
      </div>

      <div className="schedule-demo-calendar-photographer-description">
        <div className="schedule-demo-calendar-photographer-name">Anthony Acocella</div>

        <div className="schedule-demo-calendar-photographer-service">Photography Specialist</div>
      </div>
    </div>

    <div className="schedule-demo-calendar-wrapper">
      <div className="schedule-demo-calendar-top">
        <div className="schedule-demo-calendar-date">
          {formatDatetime(date, 'dddd, MMMM D, YYYY')}
        </div>

        <div className="schedule-demo-calendar-navigation">
          <a href="#" onClick={onPrev}>
            <img src={leftImg} />
          </a>

          <a href="#" onClick={onNext}>
            <img src={rightImg} />
          </a>
        </div>
      </div>

      <div className="schedule-demo-calendar-dates-selection">
        {displayedDates.map((d, i) => (
          <div className={`schedule-demo-calendar-dates-selection-date-wrapper ${d === date ? 'active' : ''}`}
               key={`date-${i}`} onClick={e => onSelectDate(e, d)}>
            <div className="schedule-demo-calendar-dates-selection-date">
              {d.getDate()}
            </div>
            <div className="schedule-demo-calendar-dates-selection-date-weekday">
              {formatDatetime(d, 'ddd')}
            </div>
          </div>
        ))}
      </div>

      <div className="schedule-demo-calendar-times-selection">
        <div className="schedule-demo-calendar-times-selection-title">Select time (displayed in EST)</div>
        <div className="schedule-demo-calendar-times-list">
          {times.map((t, i) => (
            <div className={`schedule-demo-calendar-times-item ${t === time ? 'active' : ''}`} key={`times-${i}`}
                 onClick={e => onSelectTime(e, t)}>
              {t}
            </div>
          ))}
        </div>
      </div>
    </div>

    <Footer onBack={props.onBack} continueButtonText="Submit" nextDisabled={loading || !time}/>
  </form>
}