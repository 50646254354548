import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { fetchAllSubBrokers } from "../../util/sub_brokers_api_util";
import { fetchUsersNameAndIdOnly } from "../../util/users_api_util";
import Select from 'react-select'

class OrderSummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: {},
      new_email: "",
      emailError: "",
      loading: false,
      search: "",
    }
    this.changeClient = this.changeClient.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.fetchUsersForDropdown = this.fetchUsersForDropdown.bind(this)
    this.debounceFetchUsersForDropdown = debounce(() => this.fetchUsersForDropdown(), 500)
  }


  componentDidMount() {
    // if (this.props.currentUser?.roles?.admin) {
    //   fetchUsersNameAndIdOnly().then(users => {
    //     this.setState({ users: users })
    //   })
    // }
    // else if (this.props.currentUser?.roles?.broker) {
    //   fetchAllSubBrokers().then(subBrokers => {
    //     this.setState({ users: subBrokers })
    //   })
    // }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.search !== this.state.search && this.state.search != "") {
      this.debounceFetchUsersForDropdown()
    }
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  fetchUsersForDropdown() {
    if (this.props.currentUser?.roles?.admin && this.state.search != "" && this.state.search.length > 2) {
      this.setState({ loading: true })
      fetchUsersNameAndIdOnly(this.state.search).then(users => {
        this.setState({ users: users })
        this.setState({ loading: false })
      })
    }
    else if (this.props.currentUser?.roles?.broker) {
      this.setState({ loading: true })
      fetchAllSubBrokers().then(subBrokers => {
        this.setState({ users: subBrokers })
        this.setState({ loading: false })
      })
    }
  }

  changeClient(e) {
    if (e.value === "") {
      this.props.changeParentState({ sub_broker_client: "", client_role: "", account_credit: null, account_credit_id: null, firm: null })
    } else {
      if (this.state.users[e.value].firm?.broker_charge || this.props.currentUser.roles.broker) {
        this.props.changeParentState({ sub_broker_client: e.value, client_role: this.state.users[e.value].role, firm: this.state.users[e.value].firm, account_credit: null, account_credit_id: "" })
      } else {
        this.props.changeParentState({ sub_broker_client: e.value, client_role: this.state.users[e.value].role, firm: this.state.users[e.value].firm, account_credit: this.state.users[e.value].account_credit?.amount || null, account_credit_id: this.state.users[e.value].account_credit?.id || "" })
      }
    }
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  render() {

    let price = 0;
    let discount = 0;
    let account_credit = this.props.account_credit;
    let account_credit_component = "";

    let button = <div className="navStartProjectButton submitOrderButton" onClick={() => this.props.previewOrder(discount, promoPrice, promoName)}>Checkout</div>

    let orders = Object.values(this.props.orders).map(order => {
      price += parseInt(order.price);
      
      return <div></div>
    })
    if (orders.length === 0) {

      button = <button className="navStartProjectButton grayedoutButton">Submit Order</button>
    }
    

    if (this.props.account_credit && price > 0) {
      if (this.props.account_credit >= price) {
        account_credit = this.props.account_credit - price
        price = 0
        discount = this.props.account_credit - account_credit
      } else {
        price = price - this.props.account_credit
        discount = this.props.account_credit
        account_credit = 0
      }
    }

    if (discount > 0) {
      subServices.push(<div className="orderSummaryLi">
        <div>Account credit applied: </div>
        <div className="orderSummarySubservicePrice">-${discount.toFixed(2)}</div>
      </div>)
    }

    if (account_credit > 0) {
      account_credit_component = <div className="">
        <div className="totalOrderSummaryDiv">Account Credit:</div>
        <div className="totalOrderSummaryPrice">$ {account_credit.toFixed(2)}</div>
      </div>
    }

    let errors = []

    this.props.errors?.forEach((error) => {
      errors.push(<li>{error}</li>)
    })

    if (this.state.emailError) {
      errors.push(<li>{this.state.emailError}</li>)
    }

    let assign_to_sub_broker = ""

    const brokersOptions = [{ value: "", label: "Assign to Sub-Broker (optional)" }]
    const usersOptions = [{ value: "", label: "Assign to User (optional)" }]

    if (this.props.currentUser?.roles?.broker) {
      Object.values(this.state.users).forEach(subBroker => {
        brokersOptions.push({ value: subBroker.id, label: `${subBroker.firstName} ${subBroker.lastName}` })
      })

      assign_to_sub_broker = <Select className="ml-auto orderSummarySubBrokerSelect" isLoading={this.state.loading} defaultValue={{ value: "", label: "Assign to Sub-Broker (optional)" }} options={brokersOptions} onChange={this.changeClient} />
    }

    if (this.props.currentUser?.roles?.admin) {
      Object.values(this.state.users).forEach(user => {
        usersOptions.push({ value: user?.id, label: `${user?.email} - ${user?.role} - ${user?.account_credit?.amount || 0}` })
      })
      assign_to_sub_broker = <Select className="ml-auto orderSummarySubBrokerSelect" isLoading={this.state.loading} defaultValue={this.props.sub_broker_client ? { value: this.props.sub_broker_client, label: this.props.sub_broker_client_info?.email } : { value: "", label: "Assign to User (optional)" }} options={usersOptions} onChange={this.changeClient} onInputChange={(newValue) => this.onSearchChange(newValue)} />
    } 

   

    if (this.props.page != 5) {
      assign_to_sub_broker = "";
      button = "";
    }

    let checkout = ""

    if (this.props.checkout) {
      checkout = "checkoutStyle"
    }

    return (
      <div>
        <div id={checkout} className="orderSummaryComponent">
          <div className="propertyLocationHeading">Property Location</div>
          <div className="addressPreview"> {this.props.apartment} {this.props.formatted_address} {this.props.checkout ? <div className="itemIcon3" onClick={() => this.props.changeParentState({ page: 1, goingBackToAddress: true })}><i className="fas fa-edit settingsServiceIcon m-2"></i></div> : ""}</div>
          <div className="propertyLocationHeading">Orders</div>
          <div className="subServicesList">
            {orders}
            {this.props.checkout ? <div className="addMoreServicesText" onClick={() => this.props.changeParentState({ page: 2, errors: [], selected_service: -1, specific_service: -1 })}>Add More Services</div> : ""}
          </div>
          {this.props.checkout ? <div><div className="propertyLocationHeading">Notes</div>
            <textarea className="orderSummaryNotes" onChange={(e) => this.props.changeParentState({ note: e.currentTarget.value })} placeholder="Provide any neccesary information here..." value={this.props.note} />
          </div> : ""}
          <div className="orderSummaryPriceDiv orderSummarySideDiv">
            <div className="totalOrderSummaryDiv">Total </div>
            <div className="totalOrderSummaryPrice">$ {price.toFixed(2)}</div>
          </div>
          {account_credit_component}
          {assign_to_sub_broker}
          <ul className="backendErrorUl">{errors}</ul>
        </div>
        {button ? <div className="buttonDiv">
          {button}
        </div> : ""}
      </div>
    )
  }
}

export default withRouter(OrderSummaryComponent);