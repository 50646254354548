import otherPostImg from "../../../../assets/images/blog/other-post-bg.png";
import authorProfileImg from "../../../../assets/images/MA_Logo_New_2.svg";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showToast } from "../../actions/toast_actions";

const BlogPostCard = (props) => {
  const blog = props.blog
  return <div className="popular-post-card" onClick={() => props.history.push(`/blog/${blog.slug}`)}>
    <div className="popular-post-card-image">
      <img src={blog.header_image}/>
    </div>
    <div className="popular-post-card-description">
      <div className="popular-post-card-category">{blog.category}</div>
      <div className="popular-post-card-time">{blog.time_to_read} min read</div>
    </div>
    <div className="popular-post-card-title">{blog.title}</div>
  </div>
}

const msp = (state) => ({})

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(BlogPostCard));