import { RECEIVE_TIMES } from "../actions/project_actions";

const calendarReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_TIMES:
      return action.times
    default:
      return state;
  }
};

export default calendarReducer;