import styled from 'styled-components';

export const ContractMediaSlide2Part1 = styled.img`
  position: absolute;
  width: 40%;
  top: 25%;
  right: 3%;
`;

export const ContractMediaSlide2Part2 = styled.img`
  position: absolute;
  width: 75%;
  top: 0%;
  left: 8%;
  z-index: 2;
`;

export const ContractMediaSlide2Part3 = styled.img`
  position: absolute;
  width: 83%;
  top: 2%;
  left: -2%;
  z-index: 2;
`;

export const ContractMediaSlide2Part4 = styled.img`
  position: absolute;
  width: 62%;
  top: 45%;
  left: 19%;
  z-index: 2;
`;
