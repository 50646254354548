import React from "react";
import { Route, Link } from "react-router-dom";
import CreateProjectSignupFormPage1 from "./create_project_signup_form_page_1";
import Arrow from "../../../../assets/images/arrow-1"
import {fetchTermsOfService} from "../../util/terms_of_service_util";

export default class CreateProjectSignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      companyName: "",
      companyAddress: "",
      city: "",
      state: "",
      zipcode: "",
      agentId: "",
      errors: [],
      agreeToTerms: false,
      terms: null,
      page: 1
    };

    this.handleInput = this.handleInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.sendToLogin = this.sendToLogin.bind(this)
    this.handler = this.handler.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.loginUser = this.loginUser.bind(this)
    this.openTermsOfService = this.openTermsOfService.bind(this)

    fetchTermsOfService().then(terms => this.setState({terms}))
  }

  loginUser(){
    let user = { email: this.state.email, password: this.state.password, remember_me: true }
    this.props.login(user).then(() => {
      if (this.props.errors.length === 0) {

      } else {
        
      }
    })
  }

  handler(newState) {
    this.setState(newState)
  }

  handleCheckbox() {
    this.setState({ agreeToTerms: !this.state.agreeToTerms })
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  openTermsOfService(e) {
    e.preventDefault();
    this.props.openModal("viewTermsOfService", {terms: this.state.terms})
  }

  handleNext(e) {
    e.preventDefault()

    this.setState({ errors: [] })

    let errors = []
    if (this.state.page === 1) {
      if (this.state.firstName === "") {
        errors.push([1, "First name can't be blank."])
      }

      if (this.state.lastName === "") {
        errors.push([2, "Last name can't be blank"])
      }

      if (this.state.email === "") {
        errors.push([3, "Email can't be blank"])
      }

      if (this.state.password === "") {
        errors.push([4, "Password can't be blank"])
      }
    }

    if (errors.length > 0) {
      this.setState({ errors: errors })
    } else {
      this.submitForm()
    }
  }

  handlePrevious(e) {
    e.preventDefault()

    this.setState({ page: this.state.page - 1, errors: []})
  }

  sendToLogin() {
    this.props.clearErrors([])
    this.props.changeParentState({page: 1})
  }

  submitForm() {
    if (!this.state.agreeToTerms) {
      this.setState({ errors: [[0, "Must agree to terms of service in order to create account"]] })
    } else {
      let address = { city: this.state.city, region: this.state.state, zip_code: this.state.zipcode, street1: this.state.companyAddress }
      let company = { company_name: this.state.companyName, address_attributes: address }
      let user = { first_name: this.state.firstName, last_name: this.state.lastName, email: this.state.email, password: this.state.password, password_confirmation: this.state.password, phone_number: this.state.phoneNumber, agent_mls_id: this.state.agentId, company_attributes: company, agree_to_terms: this.state.agreeToTerms }
      localStorage.setItem('creatingProject', 'true')
      this.props.signup(user).then(() => {
        if (this.props.errors.length === 0) {
        this.setState({page: this.state.page + 1})
        }
      })
    }
  }

  render() {

    let passwordErrorClass = "";
    let emailErrorClass = "";

    let passwordErrors = [];
    let emailErrors = [];
    let backendErrors = [];

    let heading = <div className="signupHeading">
      <div onClick={this.sendToLogin}><img className="createAccountArrow" src={Arrow}></img></div>
      <h3 className="h3React signupCreateAccount">Create account</h3>
    </div>

    this.state.errors.forEach((error) => {

      if (error[0] === 1) {
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }

      else if (error[0] === 2) {
        passwordErrors.push(<li>{error[1]}</li>)
        passwordErrorClass = "formInputError"
      } 
      else if (error[0] === 0) {
        backendErrors.push(<li>{error[1]}</li>)
      }

    })

    this.props.errors.forEach((error) => {
      backendErrors.push(<li>{error}</li>)
    })

    let component = ""
    let buttons = ""

    if (this.state.page === 1) {
      let page1info = { firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email, password: this.state.password, agentId: this.state.agentId, phoneNumber: this.state.phoneNumber }
      component = <CreateProjectSignupFormPage1 changeParentState={this.handler} info={page1info} errors={this.state.errors} />
      buttons =
        <div className="signupFormButtons createProjectSignupFormButtons">
          {this.state.terms && <label className="agreeToTermsCheckbox createProjectTermsCheckbox"><input type="checkbox" className="formCheckBox" checked={this.state.agreeToTerms} onChange={() => this.handleCheckbox()} /> <span className="rememberMe">I agree to the <a onClick={this.openTermsOfService} href="">terms of service</a></span></label>}
          <div className="signupFormNext createProjectButton" onClick={this.handleNext}>Create Account</div>
        </div>
    }

    if (this.state.page === 2) {
      heading = ""

      component = 
      <div>
        <div className="confirmAccountProjectSignupForm">Confirm Account</div>
        <div className="confirmAccountProjectSubheading">You have been sent an email to confirm your account. </div>
        <div className="confirmAccountProjectSubheading"> After confirming your account click below to finish your order. </div>
      </div>
      buttons =
        <div className="signupFormButtons">
        <button className="signupFormNext createProjectButton" onClick={this.loginUser}>Continue to Order Preview</button>
        </div>
    }

    return (
      <div className="createProjectLoginFormContent">
          {heading}
          <ul className="backendErrorUl">{backendErrors}</ul>
          {component}
          {buttons}
        </div>
    )
  }
}