import React, {useEffect, useState} from "react";
import ZipCodesMap from "./zip-codes-map/zip_codes_map";
import {showToast} from "../../../actions/toast_actions";
import {connect} from "react-redux";
import {fetchUserServiceableZipCodes, updateUserServiceableZipCodes} from "../../../util/serviceable_zip_codes_utils";

const NY = {lat: 40.730610, lng: -73.935242}

const SettingsZipcodes = ({currentUser, showToast}) => {
  const [polygonsCoordinates, setPolygonsCoordinates] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [anythingChanged, setAnythingChanged] = useState(false)
  const [center, setCenter] = useState(null)

  useEffect(() => {
    fetchUserServiceableZipCodes(currentUser.id).then(response => {
      const polygons = response.serviceable_zip_code_coordinates
      const convertedGoogleMapPolygons = polygons.map(polygon => polygon.map(coord => new google.maps.LatLng(coord.lat, coord.lng)));
      setPolygonsCoordinates(convertedGoogleMapPolygons)
      setCenter(convertedGoogleMapPolygons[0]?.[0] || NY)
    })
  }, [])

  const onUpdate = () => {
    setError('')

    const params = polygonsCoordinates.map(polygon => {
      return polygon.map(p => ({lat: p.lat(), lng: p.lng()}))
    })

    setLoading(true)
    updateUserServiceableZipCodes(currentUser.id, params).then(user => {
      showToast("Service area has been updated")
      setAnythingChanged(false)
      setLoading(false)
    }).catch(response => {
      setLoading(false)
      setError(response.responseJSON?.error)
    })
  }

  return (
    <div className="settingsPreferencesGroupingDiv">
      <div className="settingsPreferences">
        <div className="settingsComponentHeading">Service Area</div>

        {center && <ZipCodesMap polygonsCoordinates={polygonsCoordinates}
                     setPolygonsCoordinates={setPolygonsCoordinates}
                                onChanged={setAnythingChanged} center={center}>
          {error && <div className="alert alert-danger mt-4">{error}</div>}

          <button onClick={onUpdate} disabled={loading || !anythingChanged}
                  className="settingsButton preferencesButton settingsTimeSlotsButton w-100">
            Save changes
          </button>
        </ZipCodesMap>}
      </div>
    </div>
  );
}

const msp = (state) => ({
  currentUser: state.entities.users[state.session.currentUser?.id]
});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(SettingsZipcodes);