import styled from 'styled-components';

export const PropertyContainer = styled.div`
  background-color: #ffffff;
  padding-top: 96px;
  padding-bottom: 80px;
  
  @media only screen and (max-width: 992px) {
    padding-bottom: 50px;
    padding-top: 50px;
    .property-content {
      padding: 10px;
    }
  }  
`;

export const SmallTitle = styled.small`
  color: #506bca;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
`;

export const PropertyDescr = styled.h2`
  font-family: Poppins;
  letter-spacing: -0.01em;
  font-weight: 700;
  margin: 20px auto 60px;
  font-size: 40px;
  line-height: 46px;
  color: #1b264f;
  text-transform: none;
  max-width: 700px;

  @media only screen and (max-width: 992px) {
    margin: 20px auto 30px;
  }
`;

export const PropertyIcon = styled.div`
  padding: 12px 7px;
`;

export const PropertyWrapper = styled.div``;

export const PropertyCount = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #1b264f;

  @media screen and (max-width: 992px) {
    font-size: 32px;
    line-height: 30px;
  }
`;

export const PropertyCountDescr = styled.span`
  display: block;
  font-size: 18px;
  line-height: 32px;
  color: #353844;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;

  @media screen and (max-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PropertySimpleIcon = styled.img``;
