import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { PhotographyWrapper } from './PhotographyStyles';
import {
  ServiceDropdown,
  ServicesGallery,
  BestProperty,
  ServicesButton,
  ImageSection,
  ContractMedia,
} from '../../pageComponents';
import {
  PHOTOGRAPHY,
} from '../../../../../assets/images/servicesPages';
import {PropertyComponent, ServiceLogoListComponent, TestimonialsComponent2} from "../../home_page/homeComponents";
import BundlesAndServices from "../../pageComponents/BundlesAndServices";
import {CONTRACT_MEDIA_DATA} from "../../home_page/homePageData";
import {CONTRACT} from "../../../../../assets/images/landing";
import FullService from "./FullService";

const { ContractMediaSlides } = CONTRACT;
const { ROW_ONE, ROW_TWO, SectionImage1, SectionImage2 } = PHOTOGRAPHY;


const PhotographyPage = (props) => {
  const { location } = props;

  return (
    <PhotographyWrapper>
      <Helmet>
        <title>Real Estate Photography Services | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ServiceDropdown
        pathName={location.pathname}
        content={
          'Market your listing with Industry-leading photos'
        }
        contentLink={null}
      />
      <ServicesButton setModal={props.setModal} />
      <ImageSection
        SectionImage1={SectionImage1}
        SectionImage2={SectionImage2}
      />

      <ServicesGallery
        heading={'Properties With The Right Media Sell More Often & for'}
        span={'Higher Prices.'}
        subHeading={
          'We’ve helped thousands of listings out-perform their market.'
        }
        ROW_ONE={ROW_ONE}
        ROW_TWO={ROW_TWO}
      />
      <FullService />

      <div className="lead-page-container">
        <ServiceLogoListComponent />
      </div>

      <BundlesAndServices setModal={props.setModal}/>

      <ContractMedia
        imagesList={ContractMediaSlides}
        data={CONTRACT_MEDIA_DATA}
        isVirtualStaging={false}
      />

      <TestimonialsComponent2 />

      <PropertyComponent />
      <BestProperty />
    </PhotographyWrapper>
  );
};

export default withRouter(PhotographyPage);
