import styled from 'styled-components';


export const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  background: #F6F8FF;
`

export const HeaderImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 540px;
  
  @media only screen and (max-width: 767px) {
    height: 230px;
  }
`

export const HeaderWrapperInner = styled.div`
  position: relative;
  width: 1060px;
  margin: -180px auto 0;

  @media only screen and (max-width: 1060px) {
    width: 100%;
    margin-top: 0;
  }
`

export const Header = styled.div`
  padding: 60px 110px;
  position: relative;
  z-index: 1;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 32px;
  box-shadow: 5px 5px 60px 0 rgba(0, 0, 0, 0.06);
  
  @media only screen and (max-width: 1060px) {
    padding: 50px 20px;
    border-radius: 0;
  }
`

export const ViewAllCities = styled.span`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #353844;
  
  &:hover {
    color: #506BCA;
  }
`

export const ViewAllCitiesIcon = styled.img`
  margin-right: 16px;
`

export const Heading = styled.h1`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1B264F;
  margin-top: 30px;
  width: 85%;
  margin-bottom: 0;
  
  @media only screen and (max-width: 900px) {
    width: 100%;
    font-size: 30px;
    line-height: 120%;
  }
`

export const Description = styled.div`
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-size: 22px;
  line-height: 170%;
  text-align: center;
  color: #353844;
  margin-top: 30px;
  width: 65%;
  
  @media only screen and (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    line-height: 160%;
    margin-top: 20px;
  }
`

export const ScheduleShootButtonWrapper = styled.div`
  margin-top: 30px;
  
  @media only screen and (max-width: 900px) {
    width: 100%;
    
    button {
      width: 100% !important;
    }
  }
`

export const FeaturesListContainer = styled.div`
  margin-top: 30px;
`