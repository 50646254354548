import React from "react";
import {serviceFields} from "../../../util/service_util";

const Equipment = ({equipmentByService, setEquipmentByService}) => {

  const value = (slug, field) => {
    return equipmentByService[slug].fields[field] || ''
  }

  const onChange = (e, slug, field) => {
    e.preventDefault()
    equipmentByService[slug].fields[field] = e.target.value
    setEquipmentByService({...equipmentByService})
  }

  return <>
    <h3 className="h3React">Please list your equipment.</h3>
    <div className="description">Applicants must have their own equipment to use on projects.</div>

    <div className="inputs-list">
      {Object.keys(equipmentByService).map(slug => {
        return serviceFields[slug] ? <div key={`service-${slug}`} className="inputs-groups">
          <div className="input-title">{equipmentByService[slug].service.title}</div>
          <div className="input-fields">
            {serviceFields[slug].map(({field, label, optional}) => {
              return <div key={`service-${slug}-field-${field}`} className="input-field">
                <label className={`formLabel ${optional ? '' : 'required'}`}>{label}</label>
                <input type="text" value={value(slug, field)} className="formInput" required={!optional}
                       onChange={e => onChange(e, slug, field)} placeholder="Content"/>
              </div>
            })}
          </div>
        </div> : <></>
      })}
    </div>
  </>
}

export default Equipment
