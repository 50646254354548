import { connect } from "react-redux";
import PropertyTourEdit from "./property_tour_edit";
import React from "react";
import { openModal, closeModal } from "../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { fetchServices } from "../actions/service_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  fetchServices: () => { dispatch(fetchServices()) },
});

export default withRouter(connect(msp, mdp)(PropertyTourEdit));