import React, {useRef, useState} from 'react'
import {ServicesList} from "./services_list";
import {sendBookConsultation} from "../../../../../util/book_consultation_api_utils";
import {CalendarStep} from "./steps/calendar_step";
import {UserDataStep} from "./steps/user_data_step";
import {formatDateIso} from "../../../../../util/date_util";
import {SERVICES} from "../../../../../../../assets/images/servicesPages";

const CALENDAR = 'CALENDAR'
const USER_DATA = 'USER_DATA'
const COMPLETED = 'COMPLETED'
const STEPS_WITH_PRE_GIVEN_USER_INFO = [CALENDAR, COMPLETED]
const STEPS_WO_PRE_GIVEN_USER_INFO = [CALENDAR, USER_DATA, COMPLETED]

const servicesListItems = [
  {
    title: 'Tailoring Services to Your Needs',
    description: 'We\'ll explore your specific needs and requirements. By understanding your goals, we\'ll customize our offerings to ensure your listings stand out.',
    icon: SERVICES.ListIcon3
  },
  {
    title: 'Photography & Content Overview',
    description: 'Discuss how our services can significantly enhance the presentation and marketability of your properties.',
    icon: SERVICES.ListIcon2
  },
  {
    title: 'Platform Demonstration',
    description: 'Experience a seamless platform walkthrough, showcasing the ease from booking your shoot to downloading your media.',
    icon: SERVICES.ListIcon1
  }
]

export const ScheduleConsultationSteps = (props) => {
  const [step, setStep] = useState(CALENDAR)
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState('')
  const [userData, setUserData] = useState(props.userData || {})
  const [loading, setLoading] = useState(false)
  const titleRef = useRef()

  const userDataPreGiven = !!props.userData
  const steps = userDataPreGiven ? STEPS_WITH_PRE_GIVEN_USER_INFO : STEPS_WO_PRE_GIVEN_USER_INFO

  const onSubmit = (e) => {
    e.preventDefault()

    const nextStep = steps[steps.indexOf(step) + 1]

    if (nextStep === COMPLETED) {
      setLoading(true)
      const payload = {...userData, date: formatDateIso(date), time}
      sendBookConsultation(payload).then(() => {
        setLoading(false)
        props.onSubmitted()
      }).catch(e => {
        setLoading(false)
      })
    } else {
      setStep(nextStep)
      titleRef.current.scrollIntoView()
    }
  }

  return <div className="apply-for-discount-book-consultation-sections">
    <div className="apply-for-discount-services">
      <div className="apply-for-discount-services-list-title">
        Here’s what we’ll cover
      </div>

      <ServicesList items={servicesListItems} />
    </div>
    <form className="apply-for-discount-book-consultation-form" onSubmit={onSubmit}>
      <div className="apply-for-discount-steps">
        {steps.map(s => (
          <div className={`apply-for-discount-step ${s === step ? 'apply-for-discount-step-active' : ''}`}
               key={`step-${s}`}/>))}
      </div>

      <h1 ref={titleRef} className="dateTimeHeading">
        {step === CALENDAR ? "Choose Date & Time" : "Submit your info"}
      </h1>
      {step === CALENDAR ?
        <CalendarStep date={date} setDate={setDate} time={time} setTime={setTime}/> :
        <UserDataStep data={userData} setData={setUserData}/>}

      <div className="lead-page-divider"/>

      <button className="projectEditButton projectEditButtonLarger projectEditButtonBlue w-100"
              disabled={!time || loading} type="submit">
        {step === CALENDAR ? "Continue" : "Submit"}
      </button>
    </form>
  </div>
}