import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import projectIcon from '../../../../assets/images/Foldericon'
import copyLink from "../../../../assets/images/icons/copyLink"
import { shareMediaToEmails } from "../../util/contacts_api_utils";

class ShareMediaModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: "",
    }
    this.copySnippet = this.copySnippet.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.shareMedia = this.shareMedia.bind(this)
  }


  componentDidMount() {

  }

  componentWillUnmount() {
   
  }

  copySnippet(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text)
    } else {
      navigator.clipboard.writeText(text
      )
    }

    this.props.showToast("Link copied")
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value })
  }

  validateEmail(email) {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  shareMedia(){
    let emails = this.state.emails.split(",").map(email => email.trim()).filter(email => email !== '')
    let error = ""

    if (emails.length === 0){
      error = "Email field can't be blank"
    }


    emails.forEach(email =>{
      if (!this.validateEmail(email)) {
        error = "One or more emails are invalid"
      }
    })

    if (error != ""){
      this.props.showToast(error)
    } else {
      shareMediaToEmails(emails, this.props.project.id)
      this.props.showToast("Emails have been sent")
      this.props.changeParentState({ shareMediaModal: false })
    }
  }

  render() {
    return (
      <div className="modal-background">
        <div className="orderpreviewModal addServiceModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="shareMediaTopSection">
              <div className="">
                <div className="shareMediaHeading"><img className="shareMediaProjectIcon" src={projectIcon} /> {`${this.props.project?.address?.apartment_number || ""} ${this.props.project?.address?.formatted_address}`}</div>
              </div>
              <div className="">
                <div className="shareThisMediaHeading">Share this media</div>
                <div className="shareThisMediaSubheading">Anyone invited can view or download</div>
                <div className="">
                  <label id="shareMediaFormLabel"> Invite multiple emails </label>
                  <input className="formInput shareMediaFormInput" onChange={this.handleInput("emails")} type="string" placeholder="Add emails seperated by commas" value={this.state.emails} />
                </div>
                <div className="shareMediaLink" onClick={() => this.copySnippet(`${window.location.origin}/projects_index/${this.props.project?.slug}/share/${this.props.project?.download_key}`)}><img className="copyLinkShare" src={copyLink} /> Copy share media link</div>
              </div>
            </div>
            <div className="modalFooter modalFooterShareMedia">
              <div className="modalFooterButtons">
                <button className="projectEditButton modalButtonSecondary" onClick = {() => this.props.changeParentState({shareMediaModal: false})}>
                  Cancel
                </button>
                <button className="projectEditButton projectEditButtonPrimary" onClick={this.shareMedia}>
                  Share Media
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ShareMediaModal);