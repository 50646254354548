import React from "react";
import {showToast} from "../../../actions/toast_actions";
import {connect} from "react-redux";


class CategoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.addInfoandOpenModal = this.addInfoandOpenModal.bind(this)
  }

  addInfoandOpenModal(modal) {
    this.props.addInfo(this.props.category);
    this.props.openModal(modal)
  }

  render() {
    return (
      <div className="settingsServicesItem">
        <div className="itemTitle">{this.props.category.name}</div>
        <div className="itemIcon" onClick={() => this.addInfoandOpenModal("updateCategory")}><i className="far fa-edit settingsServiceIcon m-2"></i></div>
        <div className="itemIcon" onClick={() => this.addInfoandOpenModal("products")}><i className="fas fa-hammer settingsServiceIcon m-2"></i></div>
        <div className="itemIcon" onClick={() => {
          if (window.confirm('Are you sure you want to delete this category?')) {
            this.props.deleteCategory(this.props.category.id)
            this.props.showToast("Virtual staging category has been removed")
          } }}>
          <i className="far fa-trash-alt settingsServiceIcon m-2" />
        </div>
      </div>
    );
  }
}

const msp = () => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(CategoryItem);