import { connect } from "react-redux";
import UpdateFirmModal from "./update_firm_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { createFirm } from "../../../actions/settings_actions";
import { closeModal } from "../../../actions/modal_actions";
import { updateFirm } from "../../../actions/settings_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
});

const mdp = (dispatch) => ({
  updateFirm: (firm, firm_id) => dispatch(updateFirm(firm, firm_id)),
  closeModal: () => { dispatch(closeModal()) },
});

export default withRouter(connect(msp, mdp)(UpdateFirmModal));