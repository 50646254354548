import React from "react";
import { createDiscountCode, updateDiscountCode } from "../../../util/discount_code_api_utils";

export default class DiscountFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(this.props.discount_code || {
        code: '',
        amount: null,
        expiration_date: null,
        percentage: false,
        one_time_use: false,
        first_time_user: false,
      }),
      errors: []
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  onChange(e) {
    if (e.target.name === "code"){
      this.setState({ [e.target.name]: e.target.value.toUpperCase() });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleCheckbox(name){
    this.setState({[name]: !this.state[name]})
  }

  onSave() {
    const { percentage, first_time_user, one_time_use, amount, expiration_date, code } = this.state
    let data = { percentage: percentage, amount: amount, first_time_user: first_time_user, one_time_use: one_time_use, expiration_date: expiration_date, code: code }

    const save = this.state.id ? updateDiscountCode(this.state.id, data) : createDiscountCode(data)

    this.setState({ loading: true })

    save.then(discount_code=> {
      this.props.closeModal()
      this.props.onSave(discount_code)
      this.setState({ loading: false })
    }).catch(response => {
      this.setState({ loading: false })
      this.setState({ errors: response.responseJSON || [] })
    })
  }

  render() {
    return (
      <div className="addFormModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Coupon' : 'Create Coupon'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput form-group">
              <label id="formLabel">Code</label>
              <input className="formInput" onChange={this.onChange} name="code" value={this.state.code} />
            </div>
            <div className="settingsServicesInput form-group">
              <label id="formLabel">Amount</label>
              <input className="formInput" onChange={this.onChange} type="string" name="amount" value={this.state.amount} />
            </div>
            <div className="settingsServicesInput form-group form-group-special">
              <label><input type="checkbox" className="formCheckBox" checked={this.state.percentage} onChange={() => this.handleCheckbox("percentage")} name="percentage" /> <span className="rememberMe">Percentage?</span></label>
            </div>
            <div className="settingsServicesInput form-group">
              <label id="formLabel">Expiration Date</label>
              <input className="formInput couponDateInput" onChange={this.onChange} name="expiration_date" type="date" placeholder="Expiration Date" value={this.state.expiration_date} />
            </div>
            <div className="settingsServicesInput form-group">
              <label><input type="checkbox" className="formCheckBox" checked={this.state.first_time_user} onChange={() => this.handleCheckbox("first_time_user")} name="first_time_user"/> <span className="rememberMe">First time user only?</span></label>
            </div>
            <div className="settingsServicesInput form-group">
              <label><input type="checkbox" className="formCheckBox" checked={this.state.one_time_use} onChange={() => this.handleCheckbox("one_time_use")} name="one_time_use"/> <span className="rememberMe">One time use?</span></label>
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">
            Save
          </button>
        </div>
      </div>
    );
  }
}