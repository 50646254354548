import styled from 'styled-components';

export const ContractMediaSlide3Part1 = styled.img`
  width: 90%;
  position: absolute;
  left: 3%;
  top: -5%;
`;

export const ContractMediaSlide3Part2 = styled.img`
  width: 50%;
  position: absolute;
  left: -3%;
  top: 8%;
`;

export const ContractMediaSlide3Part3 = styled.img`
  width: 42%;
  position: absolute;
  left: 51%;
  top: 49%;
`;

export const ContractMediaSlide3Part4 = styled.img`
  position: absolute;
  width: 69%;
  top: 19%;
  left: 13%;
  z-index: -2;
`;
