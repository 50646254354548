import React, { useState, useRef, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import { MapWrapper } from './styles.js';

import states from './states.json';
import topoJSON from './states-topo.json';
import { fetchLocations } from "../../../util/location_api_util.js";
import { geoAlbersUsa } from 'd3-geo'


const MapChart = () => {

  const geoUrl = topoJSON;

  const getStateAbbreviation = (stateName) => {
    const stateMappings = {
      'Alabama': 'AL',
      'Alaska': 'AK',
      'Arizona': 'AZ',
      'Arkansas': 'AR',
      'California': 'CA',
      'Colorado': 'CO',
      'Connecticut': 'CT',
      'Delaware': 'DE',
      'Florida': 'FL',
      'Georgia': 'GA',
      'Hawaii': 'HI',
      'Idaho': 'ID',
      'Illinois': 'IL',
      'Indiana': 'IN',
      'Iowa': 'IA',
      'Kansas': 'KS',
      'Kentucky': 'KY',
      'Louisiana': 'LA',
      'Maine': 'ME',
      'Maryland': 'MD',
      'Massachusetts': 'MA',
      'Michigan': 'MI',
      'Minnesota': 'MN',
      'Mississippi': 'MS',
      'Missouri': 'MO',
      'Montana': 'MT',
      'Nebraska': 'NE',
      'Nevada': 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      'Ohio': 'OH',
      'Oklahoma': 'OK',
      'Oregon': 'OR',
      'Pennsylvania': 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      'Tennessee': 'TN',
      'Texas': 'TX',
      'Utah': 'UT',
      'Vermont': 'VT',
      'Virginia': 'VA',
      'Washington': 'WA',
      'West Virginia': 'WV',
      'Wisconsin': 'WI',
      'Wyoming': 'WY',
    };

    // Convert the state name to title case for case-insensitive matching
    const formattedStateName = stateName.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

    // Look up the abbreviation for the given state name
    return stateMappings[formattedStateName] || null;
  }

  const [cities, setCities] = useState([])
  const [cityMarkers, setCityMarkers] = useState([])

  useEffect(() => {
    fetchLocations().then(cities => setCities(cities));
  }, []);

  useEffect(() => {
    const cityMarkerArray = cities.map(city => {
      return { name: getStateAbbreviation(city.city), coordinates: [city.longitude - 1, city.latitude + 2.75], slug: city.slug, city: city.city };
    });
    setCityMarkers(cityMarkerArray);
  }, [cities]);

  return (
    <MapWrapper>
      <ComposableMap
        width={800}
        height={560}
        // width={1200}
        // height={740}
        projection="geoAlbersUsa"
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map(geo => {
                return (
                  <Geography
                    key={geo.rsmKey}
                    stroke="#F2F7FA"
                    strokeWidth={4}
                    geography={geo}
                    fill={states.find((state) => state.val === geo.properties.STATEFP).color}
                  />
              )})}           
            </>
          )}
        </Geographies>
        {cityMarkers.map((city, index) => {
          return (
          <a key={`${city?.name}-${index}`} href={`/coverage/${city.slug}`} rel="noopener noreferrer" className="markerLink">
            <Marker 
              id={`Tooltip-${index}`} 
              key={`${city?.name}-${index}`} 
              coordinates={city?.coordinates}
            >
              <svg width="46" height="46" viewBox="0 0 119 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M88.6045 28.979C104.224 44.5987 104.654 69.7874 89.5766 85.9311L59.6255 118L29.6744 85.9311C14.5969 69.7874 15.0268 44.5987 30.6465 28.979C46.6512 12.9743 72.5998 12.9743 88.6045 28.979Z" fill="#1B264F"/>
                <path d="M67.2933 71.2283C66.2745 70.6864 65.3546 70.0452 64.5337 69.3045L63.7755 68.5625L63.7169 68.4998L58.7242 63.6038L58.7205 63.6148L48.8379 53.9421C48.5534 53.6465 48.6622 53.7578 48.4812 53.5971C47.3926 52.6304 45.7203 51.967 44.4091 51.991C42.9525 52.0176 41.6776 52.5227 40.5845 53.5063L34.6739 47.8292C35.2854 47.3457 35.9438 46.9093 36.649 46.52C38.9008 45.2973 41.4464 44.66 44.2859 44.6082C47.1631 44.5557 49.7285 45.0996 51.9819 46.2401C52.9785 46.7445 53.8842 47.3431 54.6987 48.0357L70.2719 63.2612L70.2972 63.2926L71.504 64.4755C72.4953 65.2349 73.634 65.6146 74.9202 65.6146C76.5774 65.6146 78.0086 64.9843 79.2138 63.7236C80.3814 62.5024 80.9652 60.8478 80.9652 58.7599C80.9652 56.672 80.3814 55.0174 79.2138 53.7962C78.0086 52.575 76.5774 51.9644 74.9202 51.9644C73.263 51.9644 71.8506 52.575 70.6831 53.7962C70.2563 54.2286 69.9051 54.7154 69.6295 55.2564L63.4943 49.3247C64.5537 48.1255 65.8201 47.1342 67.2933 46.3506C69.5155 45.1688 72.0578 44.5779 74.9202 44.5779C77.745 44.5779 80.2872 45.1688 82.5471 46.3506C84.7692 47.5325 86.5394 49.187 87.8576 51.3144C89.1382 53.4417 89.7784 55.9235 89.7784 58.7599C89.7784 61.6357 89.1382 64.1373 87.8576 66.2646C86.5394 68.3919 84.7692 70.0465 82.547 71.2283C80.2872 72.4101 77.745 73.0011 74.9202 73.0011C72.0578 73.0011 69.5155 72.4101 67.2933 71.2283Z" fill="white"/>
                <path d="M29.5955 58.0433L38.5062 57.6671C38.5724 57.0948 38.6922 56.5605 38.8657 56.0643L32.5157 49.9755C32.1095 50.479 31.7356 51.015 31.394 51.5833C30.2983 53.4607 29.6988 55.6141 29.5955 58.0433Z" fill="white"/>
                <path d="M49.7368 62.57C49.4973 62.9909 49.2084 63.3777 48.8702 63.7306C48.0055 64.6618 46.9971 65.2529 45.845 65.5038L46.1234 72.9461C48.4465 72.7424 50.5366 72.1274 52.3936 71.1013C53.7501 70.3518 54.9262 69.4283 55.9217 68.3308L49.7368 62.57Z" fill="white"/>
                <path d="M39.1621 62.2752L33.4141 68.7376C32.7561 68.0708 32.1647 67.3323 31.6398 66.5222C30.6425 64.9382 30.0086 63.1457 29.7379 61.1448L38.6521 60.7684C38.77 61.3105 38.94 61.8128 39.1621 62.2752Z" fill="white"/>
                <path d="M43.1444 72.9744L42.8611 65.4093C42.2579 65.2374 41.6897 64.9668 41.1564 64.5974L35.7717 70.6517C36.1837 70.9152 36.6132 71.1598 37.0604 71.3855C38.9096 72.3028 40.9376 72.8324 43.1444 72.9744Z" fill="white"/>
                <path d="M67.2933 71.2283C66.2745 70.6864 65.3546 70.0452 64.5337 69.3045L63.7755 68.5625L63.7169 68.4998L58.7242 63.6038L58.7205 63.6148L48.8379 53.9421C48.5534 53.6465 48.6622 53.7578 48.4812 53.5971C47.3926 52.6304 45.7203 51.967 44.4091 51.991C42.9525 52.0176 41.6776 52.5227 40.5845 53.5063L34.6739 47.8292C35.2854 47.3457 35.9438 46.9093 36.649 46.52C38.9008 45.2973 41.4464 44.66 44.2859 44.6082C47.1631 44.5557 49.7285 45.0996 51.9819 46.2401C52.9785 46.7445 53.8842 47.3431 54.6987 48.0357L70.2719 63.2612L70.2972 63.2926L71.504 64.4755C72.4953 65.2349 73.634 65.6146 74.9202 65.6146C76.5774 65.6146 78.0086 64.9843 79.2138 63.7236C80.3814 62.5024 80.9652 60.8478 80.9652 58.7599C80.9652 56.672 80.3814 55.0174 79.2138 53.7962C78.0086 52.575 76.5774 51.9644 74.9202 51.9644C73.263 51.9644 71.8506 52.575 70.6831 53.7962C70.2563 54.2286 69.9051 54.7154 69.6295 55.2564L63.4943 49.3247C64.5537 48.1255 65.8201 47.1342 67.2933 46.3506C69.5155 45.1688 72.0578 44.5779 74.9202 44.5779C77.745 44.5779 80.2872 45.1688 82.5471 46.3506C84.7692 47.5325 86.5394 49.187 87.8576 51.3144C89.1382 53.4417 89.7784 55.9235 89.7784 58.7599C89.7784 61.6357 89.1382 64.1373 87.8576 66.2646C86.5394 68.3919 84.7692 70.0465 82.547 71.2283C80.2872 72.4101 77.745 73.0011 74.9202 73.0011C72.0578 73.0011 69.5155 72.4101 67.2933 71.2283Z" stroke="#1B264F" strokeWidth="0.527059"/>
                <path d="M29.5955 58.0433L38.5062 57.6671C38.5724 57.0948 38.6922 56.5605 38.8657 56.0643L32.5157 49.9755C32.1095 50.479 31.7356 51.015 31.394 51.5833C30.2983 53.4607 29.6988 55.6141 29.5955 58.0433Z" stroke="#1B264F" strokeWidth="0.527059"/>
                <path d="M49.7368 62.57C49.4973 62.9909 49.2084 63.3777 48.8702 63.7306C48.0055 64.6618 46.9971 65.2529 45.845 65.5038L46.1234 72.9461C48.4465 72.7424 50.5366 72.1274 52.3936 71.1013C53.7501 70.3518 54.9262 69.4283 55.9217 68.3308L49.7368 62.57Z" stroke="#1B264F" strokeWidth="0.527059"/>
                <path d="M39.1621 62.2752L33.4141 68.7376C32.7561 68.0708 32.1647 67.3323 31.6398 66.5222C30.6425 64.9382 30.0086 63.1457 29.7379 61.1448L38.6521 60.7684C38.77 61.3105 38.94 61.8128 39.1621 62.2752Z" stroke="#1B264F" strokeWidth="0.527059"/>
                <path d="M43.1444 72.9744L42.8611 65.4093C42.2579 65.2374 41.6897 64.9668 41.1564 64.5974L35.7717 70.6517C36.1837 70.9152 36.6132 71.1598 37.0604 71.3855C38.9096 72.3028 40.9376 72.8324 43.1444 72.9744Z" stroke="#1B264F" strokeWidth="0.527059"/>
              </svg>
              <text x="0" y="0" fill="#1B264F" fontSize="12" fontWeight="bold" className="marker-tooltip">
                {city.city}
              </text>
            </Marker>
          </a>
          )
        })}
      </ComposableMap>
    </MapWrapper>
  );
};

export default MapChart;