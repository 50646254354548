import React from "react";
import { Route, Link } from "react-router-dom";

export default class CreateProjectLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: true,
      errors: []
    };

    this.handleInput = this.handleInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.sendToCreateAccount = this.sendToCreateAccount.bind(this)
  }


  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handleCheckbox() {
    this.setState({ rememberMe: !this.state.rememberMe })
  }

  // sendToConfirmation() {
  //   this.props.clearErrors()
  //   this.props.history.push("/confirmation")
  // }

  sendToCreateAccount() {
    this.props.clearErrors()
    this.props.changeParentState({page: 3})
  }

  // sendToForgotPassword() {
  //   this.props.clearErrors()
  //   this.props.history.push("/forgot")
  // }

  submitForm() {
    this.setState({ errors: [] })

    let errors = []

    if (this.state.email === "") {
      errors.push([1, "Email field can't be blank."])
    }

    if (this.state.password === "") {
      errors.push([2, "Password field can't be blank"])
    }

    if (errors.length > 0) {
      this.setState({ errors: errors })
    } else {
      let user = { email: this.state.email, password: this.state.password, remember_me: this.state.rememberMe }
      this.props.login(user).then(() => {
        if (this.props.errors.length === 0) {
          // this.props.history.push("/dashboard")
        }
      })
    }
  }

  render() {

    let passwordErrorClass = "";
    let emailErrorClass = "";

    let passwordErrors = [];
    let emailErrors = [];
    let backendErrors = [];
    let successErrors = [];


    this.state.errors.forEach((error) => {

      if (error[0] === 1) {
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }

      else if (error[0] === 2) {
        passwordErrors.push(<li>{error[1]}</li>)
        passwordErrorClass = "formInputError"
      }

    })

    this.props.errors.forEach((error) => {
      if (error === "Account has been confirmed.") {
        successErrors.push(<li>{error}</li>)
      } else {
        backendErrors.push(<li>{error}</li>)
      }
    })

    return (
        <div className="createProjectLoginFormContent">
          <ul className="backendErrorUl">{backendErrors}</ul>
          <ul className="successErrorUl">{successErrors}</ul>
          <label id="formLabel"> Email </label>
          <input className="formInput" id={emailErrorClass} onChange={this.handleInput("email")} type="email" placeholder="Email address" value={this.state.email} />
          <ul className="errorUl">{emailErrors}</ul>
          <label id="formLabel"> Password </label>
          <input className="formInput" id={passwordErrorClass} onChange={this.handleInput("password")} type="password" placeholder="Password" value={this.state.password} />
          <ul className="errorUl">{passwordErrors}</ul>
          <button className="formSubmitButton createProjectButton" onClick={this.submitForm}>Sign in</button>
          <button className="createAccountButton createProjectButton" onClick={this.sendToCreateAccount}>Create Account</button>
        </div>
    )
  }
}