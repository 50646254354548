import React from "react"
import {Route, Link} from "react-router-dom"
import VirtualStagingPhotoItem from "./virtual_staging_photo_item"
import VirtualStagingGalleryModal from "./virtual_staging_gallery_modal"


export default class VirtualStagingPhotos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      photosStream: 12,
    }
    this.toggleSelected = this.toggleSelected.bind(this)
    this.streamMorePhotos = this.streamMorePhotos.bind(this)
    this.handler = this.handler.bind(this)
  }

  toggleSelected(photo) {
    let newState = {}
    let newSelectedState = {}
    if (this.props.selectedPhotos[photo.id]) {
      newState = Object.assign({}, this.props.selectedPhotos)
      newSelectedState = Object.assign({}, this.props.selectedPhotosWithInfo)
      delete newState[photo.id]
      delete newSelectedState[photo.id]
      this.props.changeParentState({photos: newState, selectedPhotos: newSelectedState})
    } else {
      newState = Object.assign({}, this.props.selectedPhotos)
      newSelectedState = Object.assign({}, this.props.selectedPhotosWithInfo)
      newState[photo.id] = {media_id: photo.id}
      newSelectedState[photo.id] = photo
      this.props.changeParentState({photos: newState, selectedPhotos: newSelectedState})
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  streamMorePhotos() {
    this.setState({photosStream: this.state.photosStream + 12})
  }

  render() {
    let streamButton = ""

    if (this.state.photosStream < this.props.photos.length) {
      streamButton = <div onClick={this.streamMorePhotos} className="streamButton">
        <i className="fas fa-chevron-down"/>
      </div>
    }

    let photoCards = this.props.photos.slice(0, this.state.photosStream).map((photo, i) => {
      return <VirtualStagingPhotoItem key={i} changeParentState={this.props.changeParentState}
                                      selected={this.props.selectedPhotos[photo.id] != null}
                                      toggleSelected={this.toggleSelected} photo={photo}
                                      index={i}/>
    })

    if (this.props.photos.length === 0) {
      photoCards = <div>You have no processed photos. Please come back when your photos have been processed.</div>
    }

    return (
      <div>
        <div className="modalTitle">Category: {this.props.category?.name}</div>
        <div className="modalSubTitle2">Only choose photos for the selected category.</div>
        <div className="modalSubTitle2">You will have the option to add more categories before checkout.</div>
        <div className="modalDivider mt-4 mb-3"/>

        <div className="virtualStagingPhotosGrid virtualSuitePhotosGrid">
          {photoCards}
          {streamButton}
        </div>
      </div>
    )
  }
}