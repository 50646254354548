import React from "react";
import VirtualSuiteTypeItem from "./virtual_suite_type_item";
import { fetchVirtualSuiteTypes } from "../../util/virtual_staging_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";


export default class VirtualSuiteSelectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      minimize: this.props.minimize,
      virtual_suite_types: []
    }
    this.toggleSelected = this.toggleSelected.bind(this);
  }

  componentDidMount() {
    if (!this.props.minimize){
    this.setState({ loading: true })
    fetchVirtualSuiteTypes().then(virtual_suite_types => this.setState({ virtual_suite_types: virtual_suite_types, loading: false }))
    }
  }

  toggleSelected(virtual_suite_type) {
    if (this.props.virtual_suite_type?.id !== virtual_suite_type) {
      this.props.changeParentState({ virtual_suite_type: virtual_suite_type })
    } else {
      this.props.changeParentState({ virtual_suite_type: null })
    }
    this.props.changeParentState({ category: null, product: null })
  }

  render() {
    let virtualSuiteTypeCards = this.state.virtual_suite_types.map((type, i) => <VirtualSuiteTypeItem key={i} homepage={this.props.homepage} virtual_suite_type={type} selected={this.props.virtual_suite_type?.id === type.id} toggleSelected={this.toggleSelected} index={i} />)
    

    if (this.state.loading) {
      virtualSuiteTypeCards = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }


    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""

    if (this.state.minimize) {
      icon = <div key="2"><i className="fas fa-plus"></i></div>
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 1) {
        checkmark = "createProjectCheckmarkShow"
      }

    }

    return (
      <div className="projectLocationComponent">
        <div className="projectLocationHeadingGrouping" id={minimized}>
          <div className="projectLocationHeadingMinimizedGrouping">
            <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
            {this.state.minimize ? <div className="settingsComponentHeading chooseYourServiceText">Choose Service</div> :  <div className="settingsComponentHeading chooseYourServiceText">What kind of virtual improvements are you looking for?</div>}
          </div>
          {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
        </div>
        <div id={minimizeId} className="row mt-3 mx-0 virtualStagingCategoryGrid">
          {virtualSuiteTypeCards}
        </div>
      </div>
    );
  }
}