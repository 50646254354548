import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {closeModal} from "../../../actions/modal_actions";
import ViewTermsOfService from "./view_terms_of_service";

const msp = (state, ownProps) => ({
});

const mdp = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal())
  },
});

export default withRouter(connect(msp, mdp)(ViewTermsOfService));