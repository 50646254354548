import styled from 'styled-components';

export const ContractMediaSlide3Part1 = styled.img`
  width: 90%;
  position: absolute;
  right: 0%;
  top: 0%;
`;

export const ContractMediaSlide3Part2 = styled.img`
  width: 40%;
  position: absolute;
  left: -1%;
  top: 24%;
`;

export const ContractMediaSlide3Part3 = styled.img`
  width: 43%;
  position: absolute;
  right: 4%;
  bottom: 0%;
  @media only screen and (max-width: 767px) {
    bottom: 2%;
  }
`;
