import {ReactComponent as Photography} from "../../../assets/images/icons/service/small/photography.svg";
import {ReactComponent as FloorPlans} from "../../../assets/images/icons/service/small/floorplans.svg";
import {ReactComponent as Aerial} from "../../../assets/images/icons/service/small/aerial.svg";
import {ReactComponent as Video} from "../../../assets/images/icons/service/small/video.svg";
import {ReactComponent as Twilight} from "../../../assets/images/icons/service/small/twilight.svg";
import virtualStaging from "../../../assets/images/icons/service/StagingService.svg";
import virtualTour from "../../../assets/images/icons/service/TourService.svg";
import aerial from "../../../assets/images/icons/service/DroneService.svg";
import floorplans from "../../../assets/images/icons/service/FloorPlansService.svg";
import video from "../../../assets/images/icons/service/VideoServiceCreate.svg";
import photography from "../../../assets/images/icons/service/PhotographyService.svg";
import other from "../../../assets/images/icons/service/OtherService.svg";
import twilight from "../../../assets/images/icons/service/TwilightServiceCreate.svg"

export const serviceIcon = (slug) => {
  const icons = {
    photography: Photography,
    floorplans: FloorPlans,
    aerial: Aerial,
    video: Video,
    twilight: Twilight
  }
  return icons[slug] || Photography
}

export const largeServiceIconSrc = (slug) => {
  const icons = {
    aerial,
    floorplans,
    video,
    photography,
    twilight,
    'virtual-staging': virtualStaging,
    'virtual-tour': virtualTour,
  }
  return icons[slug] || other
}

export const serviceFields = {
  photography: [
    {field: 'camera', label: 'Camera'},
    {field: 'lens', label: 'Lens (with Focal Length)'}
  ],
  aerial: [
    {field: 'drone', label: 'Drone'},
    {field: 'lens', label: 'Lens (Optional)', optional: true}
  ],
  video: [
    {field: 'camera', label: 'Camera'},
    {field: 'gimbal', label: 'Gimbal'},
    {field: 'lens', label: 'Lens (with Focal Length)'}
  ],
  floorplans: [
    {field: 'software', label: 'Software / App Used'}
  ],
  matterport: [
    {field: 'camera', label: 'Camera'}
  ],
  'virtual-staging': [
    {field: 'software', label: 'Software / App Used'}
  ]
}