import React, {useState} from "react";
import {subscribeToAd} from "../../../util/ad_api_utils";
import {SlideOutAdModal} from "./SlideOutAdModal";
import {PopupAdModal} from "./PopupAdModal";

export const AdModalForm = (props) => {
  const [error, setError] = useState('')
  const [subscribing, setSubscribing] = useState(false)

  const {
    showToast, title, description, button_text, imageUrl, isVertical,
    adId, onClose, onSubscribed, show, type, ad_type
  } = props

  const onSubmit = (e, email) => {
    e.preventDefault()
    if (!email) {
      setError("Email should be present")
      return;
    } else {
      setError("")
    }

    setSubscribing(true)
    subscribeToAd(email, adId).then(() => {
      onSubscribed()
      showToast("You have subscribed successfully")
    }).catch(() => {
      setSubscribing(false)
    })
  }

  const Component = ad_type === 'slide_out' ? SlideOutAdModal : PopupAdModal

  return <Component error={error} title={title} description={description} subscribing={subscribing}
                    button_text={button_text} imageUrl={imageUrl} isVertical={isVertical} type={type}
                    onClose={onClose} show={show} onSubmit={onSubmit}/>
}