import React from 'react';
import { withRouter } from 'react-router-dom';

import { PolicyWrapper } from './PrivacyPolicyStyles';
import { 
  PolicyHeader,
  PrivacyNoticeSection
} from '../../policy_page_components';
import { SectionWithContactUsLink } from '../../pageComponents';

const PrivacyPolicyPage = () => {
  return (
    <PolicyWrapper>
      <PolicyHeader />
      <PrivacyNoticeSection />
      <SectionWithContactUsLink />
    </PolicyWrapper>
  );
};

export default withRouter(PrivacyPolicyPage);
