import styled from "styled-components";

export const ServiceContainer = styled.div`
  max-width: 1140px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 80px;

  @media screen and (max-width: 1280px){
    max-width: 700px;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
  }
  
  @media screen and (max-width: 767px){
    max-width: 320px;
    flex-wrap: wrap;
  }
`;

export const LineBreak = styled.div`
  background: #E6E8EF;
  height: 1px !important;
  margin: 20px auto 50px;
  width: 1107px;

  @media screen and (max-width: 1280px){
    width: 700px;
  }
  
  @media screen and (max-width: 767px){
    width: 500px;
    margin: 20px auto;
  }
`
