import React from "react"
import debounce from 'debounce'
import {withRouter} from "react-router-dom"
import NavbarContainer from "./navbar/navbar_container"
import PhotographerItem from "./photographers/photographer_item"
import PageHeader from "./page_header"
import Map from "./map"
import LogoSpinner from "../../../assets/images/landing/video-thumbnails/MA-logo-spinner-transparent.gif";
import { fetchPhotographersMap } from "../util/users_api_util"
import InviteModal from "./referrals/invite_modal"

class PhotographersIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLargeScreen: true,
      loading: false,
      zipcode: "",
      search: "",
      photographers: [],
      photographersMap: [],
      inviteModal: false,
    }
    this.updatePredicate = this.updatePredicate.bind(this)
    this.onZipCodeChange = this.onZipCodeChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.debounceSearch = debounce(this.search, 500)
    this.setInviteModal = this.setInviteModal.bind(this)
    this.debounceSearchText = debounce(this.searchText, 500)
  }

  componentDidMount() {
    this.updatePredicate()
    window.addEventListener("resize", this.updatePredicate)

    fetchPhotographersMap().then(photographers => this.setState({photographersMap: this.shuffle(photographers)}))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate)
  }

  onZipCodeChange(e){
    if (e.currentTarget.value.length === 5){
      this.setState({ "zipcode": e.currentTarget.value, loading: true })
    } else {
      this.setState({ "zipcode": e.currentTarget.value })
    }
    this.debounceSearch(e.currentTarget.value, this.state.search)
  }

  onSearchChange(e){
    this.setState({search: e.currentTarget.value})
    this.debounceSearchText(e.currentTarget.value)
  }

  setInviteModal() {
    this.setState({ inviteModal: !this.state.inviteModal })
  }

  updatePredicate() {
    this.setState({isLargeScreen: window.innerWidth > 750})
  }

  isAZipcode(zipcode){
    const zipRegex = /^\d{5}(-\d{4})?$/;
    return zipRegex.test(zipcode);
  }

  searchText(search){
    if (search != ""){
      let searchZipcode = null
      let searchText = null

      if ((search.length === 5 && this.isAZipcode(search))) {
        searchZipcode = search
      } else {
        searchText = search
      }

      this.setState({loading: true})

      this.props.fetchContractors(null, searchZipcode, searchText, true).then(photographers => this.setState({ loading: false, photographers: this.shuffle(Object.values(photographers.contractors)) }))
    }else{
      this.setState({ photographers: [] })
    }
  }

  search(zipcode, search) {
    if ((zipcode.length === 5 && this.isAZipcode(zipcode))){
      this.setState({ loading: true })
      this.props.fetchContractors(null, zipcode, search, true).then(photographers => this.setState({ loading: false, photographers: this.shuffle(Object.values(photographers.contractors)) }))
    } else if (zipcode.length === 0){
      if (search != ""){
        this.setState({ loading: true })
        this.props.fetchContractors(null, null, search, true).then(photographers => this.setState({ loading: false, photographers: this.shuffle(Object.values(photographers.contractors)) }))
      } else {
      this.setState({photographers: []})
      }
    }
  }

  shuffle = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array
  }

  render() {
    let modal = ""
    if (this.state.inviteModal){
      modal = <InviteModal setInviteModal={this.setInviteModal} photographerInvites={true} />
    }
    return (
      <div className="dashboardFullPageDiv">
        {modal}
        <NavbarContainer/>
        <div className="dashboardMainDiv">
          <PageHeader title="Photographers"
                      subtitle="View all of our photographers"
                      showNotifications={true}/>
          <div className="sectionCard">
            {this.props.currentUser.roles.admin && <div className="photographerInviteButton"><button onClick={this.setInviteModal} className="projectEditButton dashboardReferralButton">Invite Photographers</button></div>}
            <div className="sectionCardHeader">
              <h5 className="photographersCardTitle">
                Photographer Portfolio
              </h5>
              <div className="photographersCardDescription">
                Below is a list of our photographers, view their profile to see their work.
              </div>
              <div className="photographerSearchGroup">
                {/* <div className="searchBarDiv photographersSearch">
                  <input className="formInput" onChange={this.onZipCodeChange} type="text"
                        placeholder="Property Zip Code" value={this.state.zipcode}/>
                </div> */}
                <div className="searchBarDiv photographersSearch">
                  <input className="formInput" onChange={this.onSearchChange} type="text"
                    placeholder="Search zipcode or name" value={this.state.search} />
                </div>
              </div>
            </div>
            {this.state.loading ? <div className="sectionCardBody">
              <div className="createProjectLogoSpinner">
                <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
              </div>
            </div> : 
            <div className="sectionCardBody">
              <div className="photographersContainer">
                {this.state.photographers.map(photographer => {
                  return <PhotographerItem key={photographer.id} photographer={photographer}/>
                })}
                {this.state.photographers.length === 0 && this.state.zipcode === "" && <div className="photographerZipCodeDescription">Please enter a zipcode to view photographers in that area.</div>}
              </div>
              {Object.values(this.state.photographers).length === 0 && this.state.zipcode && this.state.zipcode.length === 5 && !this.state.loading && (
                <div className="photographersCardDescription mt-3">
                Oops, there aren't any photographers servicing the zip code your entered.
                Our team has been alerted! Please email <a href="mailto:info@modernangles.com">info@modernangles.com</a> for immediate assistance.
              </div>)}
           </div>
            }
            <div className="mapDashboardDiv mapDashboardDivPhotographer">
              <div className="mapDashboardcontents mapDashboardContentsPhotographer">
                <Map contractors={this.state.photographers.length > 0 ? this.state.photographers : this.state.photographersMap} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(PhotographersIndex)