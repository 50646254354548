import React from 'react';
import { Container } from 'reactstrap';

import { SectionSlideItemWrapperOdd, OddSectionLine } from './commonStyles';
import {
  ContractMediaSlide1Part1,
  ContractMediaSlide1Part2,
  ContractMediaSlide1Part3,
} from './slideOneStyles';
import { CONTRACT } from '../../../../../../assets/images/landing';

const SlideOne = (props) => {
  const { item, inViewport, activeIndex, handleDragStart } = props;

  return (
    <SectionSlideItemWrapperOdd onDragStart={handleDragStart}>
      <OddSectionLine
        src={CONTRACT.ContractBackground}
        className={`${
          inViewport && item.id == activeIndex
            ? 'fadeInZoomInRightAnimation'
            : ''
        }`}
      />
      <Container>
        <ContractMediaSlide1Part1
          className={`${
            inViewport && item.id == activeIndex
              ? 'fadeInZoomInRightAnimation'
              : ''
          }`}
          src={item.ContractMediaSlide1Part1}
        />
        <ContractMediaSlide1Part2
          className={`${
            inViewport && item.id == activeIndex
              ? 'fadeInZoomInRightAnimation'
              : ''
          }`}
          src={item.ContractMediaSlide1Part2}
        />
        <ContractMediaSlide1Part3
          className={`${
            inViewport && item.id == activeIndex
              ? 'fadeInZoomInRightAnimation'
              : ''
          }`}
          src={item.ContractMediaSlide1Part3}
        />
      </Container>
    </SectionSlideItemWrapperOdd>
  );
};

export default SlideOne;
