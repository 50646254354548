import React, {useState} from 'react'
import transitionPhoto from '../../../../../../assets/images/./leadPages/transition-photo.png'
import {ScheduleConsultationSteps} from "./book_consulation/schedule_consulation_steps";
import {Completed} from "./completed";

export const BookConsultation = (props) => {
  const [submitted, setSubmitted] = useState(false)
  const {alert} = props

  const onSubmitted = () => {
    setSubmitted(true)
    window.scrollTo({top: 0})
  }

  const title = submitted ?
    <h1>Are you ready to partner with real estate photographers who <span className="blue">consistently deliver quality & meet deadlines?</span></h1> :
    <h1>Book a time to chat with me about photography services for your listing!</h1>

  return <>
    {alert && !submitted && <div className="text-center">
      <div className="apply-for-discount-book-consultation-alert">{alert}</div></div>}

    <div className="apply-for-discount-book-consultation-container title-container apply-for-discount-book-consultation-container-top">
      {title}
    </div>

    <div className="content-wrapper">
      {/* <img className="content-wrapper-img" src={transitionPhoto}/> */}

      <div className="apply-for-discount-book-consultation-container content-container">
        {submitted ?
          <Completed /> :
          <ScheduleConsultationSteps {...props} onSubmitted={onSubmitted} /> }
      </div>
    </div>
  </>
}