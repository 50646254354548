import React, {useEffect, useState} from 'react'
import {
  AREA,
  AVAILABILITY,
  BANK_ACCOUNT,
  cacheData,
  cacheStep,
  COMPLETED,
  getInitialData,
  getInitialStep,
  GOOGLE_CALENDAR,
  INITIAL_INFO,
  PORTFOLIO,
  removeCache,
  SERVICES,
  STEPS
} from "./utils";
import bgImg from "../../../../assets/images/photographerOnboarding/bg.png"
import mobileBgImg from "../../../../assets/images/photographerOnboarding/mobile-bg.png"
import logoImg from "../../../../assets/images/photographerOnboarding/logo.svg"
import {InitialInfo} from "./steps/initial_info";
import {Portfolio} from "./steps/portfolio";
import {Services} from "./steps/services";
import {Area} from "./steps/area";
import {GoogleCalendar} from "./steps/google_calendar";
import {Completed} from "./steps/completed";
import {Availability} from "./steps/availability";
import {Bank} from "./steps/bank";
import {
  completePhotographerOnboarding,
  getPhotographerOnboardingUser,
  startPhotographerOnboarding
} from "../../util/photographer_onboarding_util";
import {useHistory} from "react-router-dom";

const componentMap = {
  [INITIAL_INFO]: InitialInfo,
  [PORTFOLIO]: Portfolio,
  [SERVICES]: Services,
  [AREA]: Area,
  [GOOGLE_CALENDAR]: GoogleCalendar,
  [AVAILABILITY]: Availability,
  [BANK_ACCOUNT]: Bank,
  [COMPLETED]: Completed
}

export const PhotographerOnboarding = () => {
  const history = useHistory()
  const [step, setStep] = useState(getInitialStep())
  const StepComponent = componentMap[step]
  const [allData, setAllData] = useState(getInitialData())
  const [userId, setUserId] = useState(null)

  const onNext = (data) => {
    setAllData({...allData, [step]: data})
    const stepIdx = STEPS.indexOf(step)
    setStep(STEPS[stepIdx + 1])
    scrollTop()
  }

  const onSaveData = (data) => {
    setAllData({...allData, [step]: data})
  }

  const onBack = () => {
    const stepIdx = STEPS.indexOf(step)
    setStep(STEPS[stepIdx - 1])
    scrollTop()
  }

  const scrollTop = () => {
    document.querySelector(".appMainDiv").scrollTo({top: 0})
  }

  const onComplete = () => {
    return completePhotographerOnboarding({
      experience: allData[PORTFOLIO]?.experience,
      portfolio_url: allData[PORTFOLIO]?.portfolioUrl,
    })
  }

  useEffect(() => {
    const start = Object.keys(allData).length === 0 ?
      startPhotographerOnboarding() :
      Promise.resolve()

    start.then(() => {
      getPhotographerOnboardingUser().then(user => {
        if (user.id) {
          setUserId(user.id)
          setAllData({...allData, [GOOGLE_CALENDAR]: user.googleCalendar})
        } else {
          reset()
        }
      }).catch(handleErrorResponse)
    }).catch(handleErrorResponse)
  }, []);

  useEffect(() => {
    cacheData(allData)
  }, [allData]);

  useEffect(() => {
    cacheStep(step)
  }, [step]);

  const reset = () => {
    removeCache()
    setStep(INITIAL_INFO)
    setAllData({})
  }

  const handleErrorResponse = (response) => {
    if (response.status === 404) {
      history.push('/')
    }
  }

  return <div className="photographer-onboarding">
    <div className="photographer-onboarding-mobile-bg">
      <img src={mobileBgImg}/>
    </div>

    <div className="photographer-onboarding-content">
      <div className="photographer-onboarding-logo">
        <img src={logoImg}/>
      </div>

      <div className="photographer-onboarding-title">
        Real estate marketing begins here.
      </div>

      <div className="photographer-onboarding-form">
        <div className="photographer-onboarding-steps">
          {STEPS.map(s => (
            <div className={`photographer-onboarding-step ${s === step ? 'photographer-onboarding-step-active' : ''}`}
                 key={`step-${s}`}/>))}
        </div>

        <StepComponent onNext={onNext} onBack={onBack} data={allData[step]} saveData={onSaveData}
                       userId={userId} setUserId={setUserId} onComplete={onComplete}/>
      </div>
    </div>

    <div className="photographer-onboarding-desktop-bg">
      <img src={bgImg}/>
    </div>
  </div>
}