import {CONTRACT} from "../../../../assets/images/landing";

export const CONTRACT_MEDIA_DATA = [
  {
    icon: CONTRACT.Feature1,
    heading: 'Book Photographer',
    content:
      'Schedule a photographer through our booking system in minutes.',
  },
  {
    icon: CONTRACT.Feature2,
    heading: 'Select Services',
    content:
      'Select all relevant services and drop us any special notes about your listing.',
  },
  {
    icon: CONTRACT.Feature3,
    heading: 'The Shoot',
    content: 'Your photographer shows up ready to go. Do a quick house tour to make a plan!',
  },
  {
    icon: CONTRACT.Feature4,
    heading: 'Market Ready',
    content:
      'Get instantly notified and easily view and download the photos from your shoot. ',
  },
];