import React from 'react';

const MilestoneProgressBar = ({ completedReferrals, pendingReferrals, milestones }) => {
  const sortedMilestones = milestones.sort((a, b) => a.project_amount - b.project_amount);
  const largestMilestone = sortedMilestones[sortedMilestones.length - 1];
  const progressPercentage = (completedReferrals / largestMilestone.project_amount) * 100;
  const totalReferrals = pendingReferrals;
  const pendingPercentage = (pendingReferrals / largestMilestone.project_amount) * 100;

  return (
    <div className="progressBarContainer">
      <div className="progressBar" style={{ width: `${progressPercentage}%` }}></div>
      {completedReferrals > 0 && <div className="extension" style={{ left: `${progressPercentage}%` }}></div>}
      <div
        className={`pendingBar ${completedReferrals === 0 ? 'noCompleted' : ''}`}
        style={{ width: `${pendingPercentage}%`, left: `${progressPercentage}%` }}
      ></div>
      {sortedMilestones.map((milestone, index) => (
        <div
          key={index}
          className={`milestone ${completedReferrals >= milestone.project_amount ? 'achieved' : ''}`}
          style={{
            left: index === sortedMilestones.length - 1
              ? `calc(${(milestone.project_amount / largestMilestone.project_amount) * 100}% - 20px)`
              : `${(milestone.project_amount / largestMilestone.project_amount) * 100}%`
          }}
        >
          <div className={`milestone-label reward-amount ${completedReferrals >= milestone.project_amount ? 'achieved' : ''}`}>
            ${milestone.reward_amount}
          </div>
          <div className={`milestone-label project-amount ${completedReferrals >= milestone.project_amount ? 'achieved' : ''}`}>
            {milestone.project_amount} Projects
          </div>
        </div>
      ))}
    </div>
  );
};

export default MilestoneProgressBar;