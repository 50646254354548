import React from 'react';

import {
  SecondSectionWrapper,
  SecondSectionContainer,
  Image,
  Description,
  DescriptionBold,
} from './styles';

const AboutSecondSection = () => {
  return (
    <SecondSectionWrapper>
      <SecondSectionContainer>
        <Description>
        Whether you are trying to gain a sellers trust, or attract buyers to your listing, 
          <DescriptionBold>
            {' '}we will work with you to create the assets you need for success.
          </DescriptionBold>
        </Description>
        <Image />
      </SecondSectionContainer>
    </SecondSectionWrapper>
  );
};

export default AboutSecondSection;
