import React from 'react'

export const Footer = (props) => {
  const continueButtonText = props.continueButtonText || "Next"
  const backButtonText = props.backButtonText || "Back"
  const nextDisabled = props.nextDisabled || false
  const skipDisabled = props.skipDisabled || false
  const onSkip = props.onSkip

  const onClickBack = (e) => {
    e.preventDefault()
    props.onBack()
  }

  const onClickDoLater = (e) => {
    e.preventDefault()
    onSkip()
  }

  return <>
    <div className="photographer-onboarding-divider"/>
    <div className="photographer-onboarding-footer-buttons">
      <button onClick={onClickBack} className="projectEditButton projectEditButtonLight">
        {backButtonText}
      </button>

      <button className="projectEditButton projectEditButtonPrimary" type="submit" disabled={nextDisabled}>
        {continueButtonText}
      </button>
    </div>

    {onSkip && <div className="photographer-onboarding-footer-do-later-wrapper">
      <div className="photographer-onboarding-divider"/>
      <a href="#" className={`photographer-onboarding-footer-do-later ${skipDisabled ? 'disabled' : ''}`} onClick={onClickDoLater}>
        I’ll do this later.
      </a>
    </div>}
  </>
}