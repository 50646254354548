import React, {useEffect, useMemo, useRef, useState} from "react";
import {mapOptions} from "../../../../util/map_utils";
import Polyline from "./polyline";
import Polygon from "./polygon";
import polygon from "./polygon";

const ZipCodesMap = ({center, polygonsCoordinates, setPolygonsCoordinates, onChanged, children}) => {
  const mapNode = useRef()
  const [map, setMap] = useState(null)
  const [unfinishedPolygonCoordinates, setUnfinishedPolygonCoordinates] = useState([])
  const [mouseCoordinate, setMouseCoordinate] = useState(null)

  useEffect(() => {
    initMap()
    window.addEventListener('keydown', onEscape)

    return () => {
      window.removeEventListener('keydown', onEscape)
    }
  }, []);

  const onEscape = (e) => {
    if (e.key === "Escape") {
      onReset()
    }
  }

  const initMap = () => {
    const map = new google.maps.Map(mapNode.current, {
      ...mapOptions,
      center,
      zoom: 10
    })
    setMap(map)
  }

  useEffect(() => {
    if (!map) return

    addEventListeners()
    return removeEventListeners
  }, [map, unfinishedPolygonCoordinates]);

  const addEventListeners = () => {
    removeEventListeners()

    const clickListener = map.addListener("click", addCoordinate);
    const mouseMoveListener = map.addListener("mousemove", drawTemporaryLine);
    map.listeners = [clickListener, mouseMoveListener]
  }

  const removeEventListeners = () => {
    if (map.listeners) map.listeners.forEach(listener => listener.remove())
  }

  const drawTemporaryLine = (event) => {
    if (unfinishedPolygonCoordinates.length === 0) return
    setMouseCoordinate(event.latLng)
  }

  const onReset = () => {
    setUnfinishedPolygonCoordinates([])
    setPolygonsCoordinates([])
    setMouseCoordinate(null)
    onChanged(true)
  }

  const allDrawnLines = useMemo(() => {
    if (mouseCoordinate) {
      return [...unfinishedPolygonCoordinates, mouseCoordinate]
    } else {
      return unfinishedPolygonCoordinates
    }
  }, [unfinishedPolygonCoordinates, mouseCoordinate])

  const onFinishConnecting = () => {
    setPolygonsCoordinates([...polygonsCoordinates, unfinishedPolygonCoordinates])
    setUnfinishedPolygonCoordinates([])
    setMouseCoordinate(null)
  }

  const addCoordinate = (event) => {
    setUnfinishedPolygonCoordinates(c => ([...c, event.latLng]))
    onChanged(true)
  }

  return <div className="zipCodesContainer">
    <div>
      <div className="settingsComponentSubHeading2">
        Click on a map to start outlining service area.
      </div>

      <div className="settingsComponentSubHeading2">
        Use "Escape" button in order to reset the area.
      </div>

      <button onClick={onReset} className="settingsButton w-100"
              disabled={!polygon && unfinishedPolygonCoordinates.length === 0}>
        Reset
      </button>

      {children}
    </div>

    <div className="zipCodesMap" ref={mapNode}>
      {map && allDrawnLines.length > 0 && <Polyline coords={allDrawnLines} map={map} onFinishConnecting={onFinishConnecting}/>}
      {map && polygonsCoordinates.map((polygon, i) => <Polygon coords={polygon} map={map} key={`polygon-${i}`}/>)}
    </div>
  </div>
}

export default ZipCodesMap