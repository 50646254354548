import TopSectionImage1 from './topSection/top-section-1.png';
import TopSectionImage2 from './topSection/top-section-2.png';
import TopSectionImage3 from './topSection/top-section-3.jpg';
import ImageSliderMarker from './image-slider-marker-dark.svg';
import ImageSliderMarkerDark from './image-slider-marker-full-dark.svg';
import ImageSliderMarkerLight from '../landing/image-slider-marker.svg'

import CIRCLE from './circle.svg';
import SMALL_CIRCLE from './small-circle.png';

import SECOND_SECTION_IMAGE from './second-section-image.png';

const TOP_SECTION = {
  TopSectionImage1,
  TopSectionImage2,
  TopSectionImage3,
  ImageSliderMarker,
};

export {
  TOP_SECTION,
  CIRCLE,
  SMALL_CIRCLE,
  SECOND_SECTION_IMAGE,
  ImageSliderMarkerDark,
  ImageSliderMarkerLight,
};