import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import NavbarContainer from "./navbar/navbar_container";
import mobileSettingsBackground from "../../../assets/images/mobile-settings-background";
import UserAvatar from "./photographers/photograher_avatar";

class Login extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {

    let settings = <Link className="linkSettingsMobile openSettingsMobile" to="/settings">
      Open Settings
    </Link>

    if (this.props.currentUser.roles.post_production){
      settings = ""
    }

    return (
      <div className="settingsFullPageDiv">
        <div className="settingsPageGroupings">
          <NavbarContainer />
          <img className="mobileSettingsBackground" src={mobileSettingsBackground} />
          <div className="settingsMobileContainer">
            <UserAvatar user={this.props.currentUser} />
            <div className="settingsMobileName">{this.props.currentUser.firstName} {this.props.currentUser.lastName} </div>
            <div className="linkSettingsMobileGroup">
              {settings}
              <div className="linkSettingsMobile signoutSettingsMobile" onClick={() => this.props.logout()}> Sign Out</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Login);