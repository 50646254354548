import React, { useState } from 'react';

import {
  VirtualStaggingGalleryWrapper,
  VirtualStaggingGalleryContent,
  VirtualStaggingGalleryHeading,
  VirtualStaggingGalleryHeadingEnd,
  VirtualStaggingGallerySubHeading,
  GalleryVirtualStagging,
  GalleryVirtualStaggingRowOne,
  GalleryVirtualStaggingRowTwo,
  GalleryImageWrapper,
  ImageMarker,
} from './styles';
import { SERVICES } from '../../../../../assets/images/servicesPages';

const ServicesGallery = (props) => {
  const { heading, span, subHeading, ROW_ONE, ROW_TWO } = props;
  return (
    <VirtualStaggingGalleryWrapper>
      <VirtualStaggingGalleryContent>
        <VirtualStaggingGalleryHeading>
          {heading}
          <VirtualStaggingGalleryHeadingEnd>
            {' '}
            {span}
          </VirtualStaggingGalleryHeadingEnd>
        </VirtualStaggingGalleryHeading>
        <VirtualStaggingGallerySubHeading>
          {subHeading}
        </VirtualStaggingGallerySubHeading>
      </VirtualStaggingGalleryContent>
      <GalleryVirtualStagging>
        <GalleryVirtualStaggingRowOne>
          {ROW_ONE.map((image, index) => {
            return (
              <GalleryImageItem key={index} index={index + 1} image={image} />
            );
          })}
        </GalleryVirtualStaggingRowOne>
        <GalleryVirtualStaggingRowTwo>
          {ROW_TWO.map((image, index) => {
            return (
              <GalleryImageItem key={index} index={index + 5} image={image} />
            );
          })}
        </GalleryVirtualStaggingRowTwo>
      </GalleryVirtualStagging>
    </VirtualStaggingGalleryWrapper>
  );
};

const GalleryImageItem = (props) => {
  const { index, image } = props;

  return (
    <GalleryImageWrapper
      sliderImage={image}
      className={`rowOneGalleryWrapper${index}`}
    >
      {index !== 4 && index !== 5 && index !== 8 && (
        <>
          <ImageMarker
            id={'Tooltip-' + index}
            src={SERVICES.ImageSliderMarker}
            alt={''}
          />
        </>
      )}
    </GalleryImageWrapper>
  );
};

export default ServicesGallery;
