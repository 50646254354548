import styled, { keyframes } from 'styled-components';
import { fadeInRight } from 'react-animations';

const fadeinZoomIn = {
  "0%": {
    opacity: 0,
    transform: "translate3d(100%, 0, 0) scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0)"
  },
  "10%": {
    transform: "scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0)"
  },
  "60%": {
    opacity: 1
  }
}

const fadeInZoomInRightAnimation = keyframes`${fadeinZoomIn}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

export const SectionSlideItemWrapperOdd = styled.div`
  width: 100%;
  height: 850px;
  border-bottom: 2px solid #e1ecf3;
  position: relative;
  .container {
    position: relative;
    width: 100%;
    height: 770px;
  }
  .fadeInZoomInRightAnimation {
    animation: ${fadeInZoomInRightAnimation} 1.5s cubic-bezier(0.11, 0.95, 0.22, 0.94);
  }
  .fadeInRightAnimation {
    animation: 1s ${fadeInRightAnimation};
  }
  @media only screen and (max-width: 1599px) {
    height: 775px;
    .container {
      height: 700px;
    }
  }
  @media only screen and (max-width: 1399px) {
    height: 800px;
    .container {
      height: 670px;
    }
  }
  @media only screen and (max-width: 1100px) {
    height: 650px;
    .container {
      height: 600px;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 490px;
    .container {
      height: 460px;
    }
  }
  @media only screen and (max-width: 600px) {
    height: 400px;
    .container {
      height: 380px;
    }
  }
  @media only screen and (max-width: 500px) {
    height: 350px;
    .container {
      height: 300px;
    }
  }
`;

export const OddSectionLine = styled.img`
  position: absolute;
  bottom: -1%;
  left: 0%;
  height: 45%;
  width: 35%;
  @media only screen and (max-width: 1399px) {
    bottom: 0%;
  }
  @media only screen and (max-width: 1199px) {
    bottom: -10%;
  }
`;

export const SectionSlideItemWrapperEven = styled.div`
  width: 100%;
  height: 850px;
  border-bottom: 2px solid #e1ecf3;
  overflow: hidden;
  position: relative;
  .container {
    position: relative;
    width: 100%;
    height: 770px;
  }
  .fadeInZoomInRightAnimation {
    animation: ${fadeInZoomInRightAnimation} 1.5s cubic-bezier(0.11, 0.95, 0.22, 0.94);
  }
  .fadeInRightAnimation {
    animation: 1s ${fadeInRightAnimation};
  }
  @media only screen and (max-width: 1599px) {
    height: 775px;
    .container {
      height: 700px;
    }
  }
  @media only screen and (max-width: 1399px) {
    height: 800px;
    .container {
      height: 670px;
    }
  }
  @media only screen and (max-width: 1100px) {
    height: 650px;
    .container {
      height: 600px;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 490px;
    .container {
      height: 460px;
    }
  }
  @media only screen and (max-width: 600px) {
    height: 400px;
    .container {
      height: 380px;
    }
  }
  @media only screen and (max-width: 500px) {
    height: 350px;
    .container {
      height: 300px;
    }
  }
`;

export const OddSectionEven = styled.img`
  position: absolute;
  bottom: 0%;
  right: -15%;
  height: 65%;
  width: 50%;
`;
