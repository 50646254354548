import styled from 'styled-components';
import { PROPERTY } from '../../../../../assets/images/landing';
const { PropertyImge01 } = PROPERTY;

export const SectionContainerColor = styled.div`
  width: 100vw;
  background: #EAF1F6;
`;

export const SectionContainer = styled.div`
  margin-left: calc((100vw - 988px)/2);
  height: 427px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1200px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 769px) {
    height: auto;
    margin: 0px;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 769px) {
    flex-direction: column-reverse;
  }
`;

export const FirstColumn = styled.div`
  width: 596px;
  height: 228px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
  @media screen and (max-width: 769px) {
    height: auto;
    margin: 30px 0px;
    padding: 0px 30px;
  }
`;

export const Heading = styled.p`
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #1B264F;
  @media screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 32px;
  }
  @media screen and (max-width: 769px) {
    font-size: 22px;
    line-height: 24px;
  }
`;

export const Content = styled.p`
  width: 503px;
  margin: 20px 0px 30px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
    /* width: 94%; */
  }
  @media screen and (max-width: 950px) {
    width: 400px;
  }
  @media screen and (max-width: 769px) {
    width: 94%;
    font-size: 18px;
    line-height: 26px;
  }
`;

export const SecondColumn = styled.div`
  position: relative;
  width: 634px;
  height: 427px;
  margin-left: 30px;
  background-image: url(${PropertyImge01});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  .fade .tooltip {
    top: -83px !important;
  }
  @media screen and (max-width: 769px) {
    width: 100%;
    height: 185px;
    margin-left: 0px;
  }
`;

export const ImageMarker = styled.img`
  width: 60px;
  height: 74px;
  position: absolute;
  bottom: 40px;
  right: 43px;
  @media screen and (max-width: 769px) {
    display: none;
  }
`;

export const Button = styled.button`
  width: 199px;
  height: 60px;
  background-color: #ffffff;
  border: 1.5px solid #D8E0FF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #353844;
  @media only screen and (max-width: 769px) {
    width: 168px;
    height: 51px;
    font-size: 16px;
    line-height: 16px;
  }
`;
