import React from 'react';
import {connect} from "react-redux";
import HomePageCreateModal from "../../components/home_page_create_modal/home_page_create_modal";
import CreateProjectHomePageModalContainer
  from "../../components/create_project_home_page/create_project_home_page_modal_container";
import CreateVirtualSuiteHomePageModalContainer
  from "../../components/create_virtual_suite_home_page/create_virtual_suite_home_page_modal_container";
import {
  closeStartModal,
  closeProjectModal,
  closeVsModal,
  openProjectModal,
  openVsModal,
  openStartModal,
} from "../../actions/landing_page_actions";

const Modals = (props) => {
  let modal = ""
  let {modalOpen, projectModalOpen, VSModalOpen} = props;

  if (modalOpen) {
    modal = <HomePageCreateModal onClose={props.closeModal} isLargeScreen={props.isLargeScreen}
                                 openProjectModal={props.openProjectModal} openVSModal={props.openVsModal}/>
  } else if (projectModalOpen) {
    modal = <CreateProjectHomePageModalContainer openStartModal={props.openStartModal} isLargeScreen={props.isLargeScreen} onClose={props.closeProjectModal}/>
  } else if (VSModalOpen) {
    modal = <CreateVirtualSuiteHomePageModalContainer isLargeScreen={props.isLargeScreen} openStartModal={props.openStartModal} onClose={props.closeVsModal}/>
  }

  return modal;
}

const msp = (state) => {
  return {
    modalOpen: state.entities.landingPage.modalOpen,
    projectModalOpen: state.entities.landingPage.projectModalOpen,
    VSModalOpen: state.entities.landingPage.VSModalOpen,
  };
};

const mdp = (dispatch) => ({
  closeModal: () => {
    dispatch(closeStartModal());
  },
  openStartModal: () => {
    dispatch(openStartModal());
  },
  openVsModal: () => {
    dispatch(openVsModal());
  },
  openProjectModal: () => {
    dispatch(openProjectModal());
  },
  closeProjectModal: () => {
    dispatch(closeProjectModal());
  },
  closeVsModal: () => {
    dispatch(closeVsModal());
  },
});

export default connect(msp, mdp)(Modals);