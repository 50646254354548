import React from "react"


export default class VirtualStagingNotes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {onAddMoreServices} = this.props

    return (
      <div>
        <div className="modalTitle">Special Instructions</div>
        <div className="modalSubTitle">
          <ul>
            <li>Please provide a detailed description of your Virtual Staging request.</li>
            <li>Click Next Step if not applicable.</li>
          </ul>
        </div>

        <label id="formLabel">
          Please add relevant information
        </label>

        <textarea className="virtualStagingSpecialNotes formInput"
                  onChange={(e) => this.props.changeParentState({specialNote: e.currentTarget.value})}
                  placeholder="Add your comments here. "
                  value={this.props.specialNote}/>

        <div className="modalDivider modalDividerWide my-4"/>

        <div className="modalTitle">Great work!</div>
        <div className="modalSubTitle">
          You have more images saved in this project. Do you want add another image?
        </div>

        <button className="projectEditButton projectEditButtonLight projectEditButtonLightBordered mt-4"
                onClick={onAddMoreServices}>
          Stage more photos
        </button>
      </div>
    )
  }
}