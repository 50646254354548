import React, {useState} from 'react'
import {Footer} from "../footer";

const roles =  [
  'Real Estate Broker',
  'Real Estate Agent',
  'Home Owner'
]

export const Role = (props) => {
  const [role, setRole] = useState(props.data?.role || roles[0])

  const onChange = (e) => {
    e.preventDefault()
    setRole(e.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    props.onNext({role})
  }

  return <form onSubmit={onSubmit}>
    <div className="schedule-demo-title">
      Are you a Real Estate Agent or Home Owner?
    </div>

    <div className="schedule-demo-form-fields">
      <div className="schedule-demo-form-field">
        <label id="formLabel" className="formLabel required">Please select from the drop down.</label>
        <select value={role} onChange={onChange} className="formInput">
          {roles.map(r => <option value={r} key={r}>{r}</option>)}
        </select>
      </div>
    </div>

    <Footer showBack={false} />
  </form>
}