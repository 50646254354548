export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const ADD_INFO = "ADD INFO";
export const REMOVE_INFO = "REMOVE_INFO";

export const openModal = (modal, props) => {
  return {
    type: OPEN_MODAL,
    modal,
    props
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL
  };
};

export const addInfo = info => {
  return {
    type: ADD_INFO,
    info
  }
}

export const removeInfo = () => {
  return {
    type: REMOVE_INFO
  }
}