import { connect } from "react-redux";
import CreateProjectMain2 from "./create_project_main_2";
import React from "react";
import { withRouter } from "react-router-dom"
import { fetchServices, fetchContractors } from "../../actions/service_actions";
import { submitOrder } from "../../actions/project_actions";
import { openModal } from "../../actions/modal_actions";
import { fetchCurrentUser } from "../../actions/session_actions";
import { showToast } from "../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
  services: state.entities.services,
  contractors: state.entities.contractors,
});

const mdp = (dispatch) => ({
  fetchServices: () => dispatch(fetchServices()),
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id)),
  fetchContractors: (service_id, zipcode, search, date, time, services, appointments, timezone) => dispatch(fetchContractors(service_id, zipcode, search, date, time, services, appointments, timezone)),
  submitOrder: (data) => dispatch(submitOrder(data)),
  showToast: (message) => dispatch(showToast(message)),
  openModal: (modal, props) => { dispatch(openModal(modal, props)); }
});

export default withRouter(connect(msp, mdp)(CreateProjectMain2));