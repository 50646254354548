import React from 'react'
import {TermsUserRoleSelect, TermsUserRoleSelectorWrapper} from "./styles";

const TermsUserRoleSelector = ({role, setRole}) => {
  const onChange = (e) => {
    e.preventDefault()
    setRole(e.target.value)
  }

  return <TermsUserRoleSelectorWrapper>
    <TermsUserRoleSelect onChange={onChange} value={role}>
      <option value="client">Client</option>
      <option value="contractor">Contractor</option>
    </TermsUserRoleSelect>
  </TermsUserRoleSelectorWrapper>
}

export default TermsUserRoleSelector