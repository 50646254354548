import React from "react"
import {withRouter} from "react-router-dom"
import {stars} from "../../util/rating_util"
import UserAvatar from "./photograher_avatar"
import {serviceIcon} from "../../util/service_util";


class PhotographerItem extends React.Component {
  render() {
    let photographer = this.props.photographer
    let star_rating = stars(photographer.average_rating)

    return (
      <div className="photographerCard">
        <div className="photographerCardHeader">
          <div className="d-flex justify-content-between">
            <UserAvatar user={photographer}/>
            <div className="photographerCardInfo">
              <div className="photographerCardName">{photographer.firstName} {photographer.lastName[0]}</div>
              <div>
                {photographer.ratings_amount > 0 && <div className="photographerCardRatingMobile">
                  <div>{star_rating}</div>
                </div>}
                <a className="photographerCardButton ms-0" href={`/photographers/${this.props.photographer.id}`}>
                  <i className="far fa-user-circle"/>
                  Profile
                </a>
                {/* <div className="photographerCardButton">
                  <i className="far fa-calendar"/>
                  Availability
                </div> */}
              </div>
            </div>
            {photographer.ratings_amount > 0 && <div className="photographerCardRating">
              <div>{star_rating}</div>
              <div className="photographerCardReview">{photographer.ratings_amount} Reviews</div>
            </div>}
          </div>
        </div>
        <div className="photographerCardBody">
          <div className="photographerCardServices">
            {photographer?.services?.map(service => {
              if (!service.reshoot){
                const ServiceIcon = serviceIcon(service.slug)
                return (
                  <div className="photographerCardService">
                    <ServiceIcon className="photographerCardServiceIcon"/>
                    <span className="photographerCardServiceName">{service.title}</span>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>

    )
  }
}

export default withRouter(PhotographerItem)