import styled from 'styled-components';

export const AboutContactSectionContainer = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 110px 0px;
  @media screen and (max-width: 991px) {
    padding: 75px 0px;
  }
  @media screen and (max-width: 550px) {
    padding: 75px 0px 10px;
  }
`;

export const AboutContactSectionWrapper = styled.div`
  width: 988px;
  @media screen and (max-width: 991px) {
    width: 95%;
  }
  @media screen and (max-width: 767px) {
    width: 92%;
  }
`;

export const AboutContactSectionHeading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #1b264f;
  text-transform: initial;
  width: 562px;
  margin-bottom: 48px;
  @media screen and (max-width: 991px) {
    width: 350px;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 30px;
  }
`;
