import React, {useMemo, useState} from 'react'
import {ALL_SLOTS_REQUEST, SLOTS, WEEKDAYS} from "./availability_utils";
import {Footer} from "../footer";
import {updatePhotographerAvailability} from "../../../util/photographer_onboarding_util";


const AVAILABLE = 'Available'
const NOT_AVAILABLE = 'Not Available'

export const Availability = (props) => {
  const [availability, setAvailability] = useState(props.data || defaultAvailability())
  const [loading, setLoading] = useState(false)

  const timeSlots = useMemo(() => {
    return Object.keys(availability).reduce((res, weekday) => {
      res[weekday] = availability[weekday] === AVAILABLE ? {} : ALL_SLOTS_REQUEST
      return res;
    }, {})
  }, [availability])

  const onChange = (weekday) => {
    return (e) => {
      e.preventDefault()
      setAvailability({...availability, [weekday]: e.target.value})
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    updatePhotographerAvailability({schedule: timeSlots}).then(() => {
      setLoading(false)
      props.onNext(availability)
    }).catch(() => {
      setLoading(false)
    })
  }

  function defaultAvailability() {
    return WEEKDAYS.reduce((res, w) => { res[w] = AVAILABLE; return res }, {})
  }

  return <form onSubmit={onSubmit}>
    <div className="photographer-onboarding-form-title">
      Set your <span className="blue">availability</span>
    </div>

    <table className="photographer-onboarding-availability-table">
      <tbody>
      {WEEKDAYS.map((weekday, i) => (
        <tr key={`ts-${weekday}`}>
          <td>{weekday}</td>

          <td>
            <select value={availability[weekday]}
                    onChange={onChange(weekday)}
                    className="formInput">
              <option value={AVAILABLE}>Available</option>
              <option value={NOT_AVAILABLE}>Not Available</option>
            </select>
          </td>
        </tr>
      ))}
      </tbody>
    </table>

    <Footer onBack={props.onBack} onSkip={() => props.onNext(props.data)} nextDisabled={loading}/>
  </form>
}