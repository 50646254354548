import React from "react";
import debounce from 'debounce'
import { Route, Link, withRouter } from "react-router-dom";
import LocationComponent from "../location_component";
import CategorySelectionComponent from "./category_selection_component";
import ProductSelectionComponent from "./product_selection_component";
import VirtualSuiteSelectionComponent from "./virtual_suite_selection_component";
import PhotoSelectionComponent from "./photo_selection_component";
import PhotoComponent from "./photo_component";
import OrderSummaryComponent from "./order_summary_component";
import { fetchUserPromos } from "../../util/settings_api_util";
import AddMorePhotosModal from "./add_more_photos_modal";
import CreatePurchaseModal from "./create_purchase_modal_container";
import OrderCreatedModal from "./order_created_modal";
import NotesComponent from "./notes_component";
import smallMobileLogo from "../../../../assets/images/smallMobileLogo";
import CheckoutComponent from "./checkout_component";
import { uploadPhotos } from "../../actions/project_actions";

class CreateVirtualSuiteMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      street: "",
      apartment: "",
      zipcode: "",
      state: "",
      city: "",
      latitude: "",
      longitude: "",
      formatted_address: "",
      note: "",
      client_id: "",
      project_id: "",
      project_address: "",
      project_address_full: {},
      category: null,
      virtual_suite_type: null,
      product: null,
      photos: [],
      photos_src: [],
      selectedPhotos: {},
      selectedPhotosWithInfo: {},
      upload: null,
      client_role: "",
      project_client_id: "",
      specialNote: "",
      errors: [],
      orders: [],
      detailed_orders: [],
      paymentModalOpen: false,
      galleryModalOpen: false,
      account_credit: this.props.currentUser?.account_credit?.amount,
      account_credit_id: "",
      productModalOpen: false,
      currentPhoto: 0,
      currentProduct: 0,
      discount: 0,
      goingBackToAddress: false,
      account_credit_id: "",
      firm: {},
      project: {},
      errors: [],
      propertyErrors: [],
      summaryErrors: [],
      client: "",
      page: 1
    }

    this.handler = this.handler.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
    this.addMoreServices = this.addMoreServices.bind(this)
    this.deleteOrderPhoto = this.deleteOrderPhoto.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.fetchCurrentUser(this.props.currentUser.id).then(user => {
      if (!user.user.firm?.broker_charge) {
        this.setState({ account_credit: user.user?.account_credit?.amount })
      } else {
        this.setState({ account_credit: null })
      }
    })
    
    window.scrollTo(0, 0)
  }

  addMoreServices() {
    this.addToCart()

    this.setState({
      page: 1
    })
  }

  deleteOrderPhoto(orderIndex, photoIndex) {
    if (!window.confirm('Are you sure you want to remove this photo?')) return
    let orders = this.state.orders
    let detailed_orders = this.state.detailed_orders

    const order = orders[orderIndex]
    const detailed_order = detailed_orders[orderIndex]

    if (order.virtual_staging_photos_attributes.length === 1) {
      orders.splice(orderIndex, 1)
      detailed_orders.splice(orderIndex, 1)
    } else {
      const [photo] = order.virtual_staging_photos_attributes.splice(photoIndex, 1)
      delete detailed_order.selected_photos[photo.media_id || photo.name]
    }

    this.setState({ orders: orders, detailed_orders: detailed_orders })
  }

  addToCart() {
    if (!this.state.product) return

    let orders = this.state.orders
    let detailed_orders = this.state.detailed_orders
    let virtual_staging_details = {
      project_id: this.state.project_id,
      virtual_staging_photos_attributes: Object.values(this.state.selectedPhotos),
      virtual_staging_product_id: this.state.product?.id,
      notes: this.state.specialNote
    }
    let order_details = {
      selected_photos: this.state.selectedPhotosWithInfo,
      product: this.state.product,
      category: this.state.category,
      note: this.state.specialNote
    }

    orders.push(virtual_staging_details)
    detailed_orders.push(order_details)

    this.setState({
      detailed_orders: detailed_orders,
      orders: orders,
      category: null,
      selectedPhotos: {},
      selectedPhotosWithInfo: {},
      product: null,
      specialNote: "",
    })
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  handleNext(e) {
    e.preventDefault()

    this.setState({ errors: [] })

    let errors = []
    if (this.state.page === 1) {
      if (this.state.virtual_suite_type === null){
        errors.push("Please select a virtual staging service before continuing.")
      }
    }
    if (this.state.page === 2) {
      if (this.state.category === null) {
        errors.push("Please select a virtual staging category before continuing.")
      }
    }

    if (this.state.page === 3) {
      if (Object.values(this.state.selectedPhotos).length === 0) {
        errors.push("Please select at least one photo before continuing.")
      }

      if (this.state.formatted_address === "" && this.state.upload){
        errors.push("Please insert an address before continuing.")
      }
    }

    if (errors.length > 0) {
      this.props.showToast(errors)
    }
    else {
      if (this.state.page === 1 && this.state.goingBackToAddress) {
        this.setState({ page: 5, errors: [], summaryErrors: [], propertyErrors: [] })
      } else {
        if (this.state.page === 6){
          this.addToCart()
        }
        this.setState({ page: this.state.page + 1, errors: [], summaryErrors: [], propertyErrors: [] })
        this.debouceScrollTo('#scrollToHere')
      }
    }
  }

  handlePrevious(e) {
    e.preventDefault()
    this.setState({ page: this.state.page - 1, errors: [], summaryErrors: [], propertyErrors: [] })
    this.debouceScrollTo('#scrollToHere')
  }

  handleSubmit() { 
    let orders = this.state.orders
    let coupon_info = null;
    let discount = 0;
    let account_credit = this.state.account_credit
    let price = 0;

    price = this.state.detailed_orders.reduce((res, order) => {
      return res + Object.values(order.selected_photos).length * order.category.price
    }, 0)

    if (this.state.account_credit && price > 0) {
      if (this.state.account_credit >= price) {
        account_credit = this.state.account_credit - price
        price = 0
        discount = this.state.account_credit - account_credit
      } else {
        price = price - this.state.account_credit
        discount = this.state.account_credit
        account_credit = 0
      }
    }

    if (discount > 0 && this.state.account_credit) {
      coupon_info = { coupon_id: this.state.client_id ? this.state.account_credit_id : this.props.currentUser?.account_credit?.id, coupon_amount: parseInt(this.state.account_credit) - discount, coupon_discount_amount: discount }
    }

    if (this.state.upload || (this.state.project_client_id && this.state.client_id && this.state.project_client_id != this.state.client_id )){
      let data = {}
      let address_attributes = {}

      if (this.state.formatted_address){
         address_attributes = { formatted_address: this.state.formatted_address, county: this.state.county, apartment_number: this.state.apartment, city: this.state.city, region: this.state.state, street1: this.state.street, zip_code: this.state.zipcode, longitude: this.state.longitude, latitude: this.state.latitude }
      } else {
        address_attributes = { formatted_address: this.state.project_address_full.formatted_address, county: this.state.county, apartment_number: this.state.project_address_full.apartment, city: this.state.project_address_full.city, region: this.state.project_address_full.region, street1: this.state.project_address_full.street1, zip_code: this.state.project_address_full.zip_code, longitude: this.state.project_address_full.longitude, latitude: this.state.project_address_full.latitude }
      }
      data = { client_id: this.state.client_id ? this.state.client_id : this.props.currentUser.id, address_attributes: address_attributes, packages_attributes: [], broker_booked: this.props.currentUser.roles.broker }

      this.props.submitOrder(data).then(({ project }) => {
        if (project.errors) {
          this.props.showToast(project.errors)
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
          let project_id = Object.values(project)[0].id
          let ordersWithProject = this.state.orders.map(order => {order["project_id"] = project_id; return order})
          let order_batches = { coupon_info: coupon_info, project_id: project_id, virtual_staging_purchases_attributes: ordersWithProject, broker_booked: this.props.currentUser.roles.broker }
          this.props.createVirtualStagingPurchase(order_batches).then((batch) => {
            if (this.props.errors.length === 0) {
              this.setState({ page: 8, project_id: project_id})
              if (discount > 0 && this.state.account_credit) {
                this.props.fetchCurrentUser(this.props.currentUser.id)
              }
            }
          })
        }
      })
    } else {
      let order_batch = { coupon_info: coupon_info, project_id: this.state.project_id, virtual_staging_purchases_attributes: orders, broker_booked: this.props.currentUser.roles.broker }

      this.props.createVirtualStagingPurchase(order_batch).then(() => {
        if (this.props.errors.length === 0) {
          this.setState({page: 8})
          if (discount > 0 && this.state.account_credit) {
            this.props.fetchCurrentUser(this.props.currentUser.id)
          }
        }
      })
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  modal() {
    if (this.state.paymentModalOpen) {
      let price = this.state.detailed_orders.reduce((res, order) => {
        return res + Object.values(order.selected_photos).length * order.category.price
      }, 0)

      if (this.state.account_credit && price > 0) {
        if (this.state.account_credit >= price) {
          price = 0
        } else {
          price = price - this.state.account_credit
        }
      }

      return <CreatePurchaseModal upload={this.state.upload} client_role={this.state.client_role} client_id={this.state.client_id} submitOrder={this.handleSubmit} project_id={this.props.project_id}
        changeParentState={this.handler} total={price} firm={this.state.firm}/>
    }
  }

  render() {

    let icon = ""

    // let icon = <div key="2" onClick={() => this.props.history.goBack()}><i className="fas fa-arrow-left mainIcon createProjectIcon"></i></div>


    // let modal = this.state.

    // if (this.state.modalOpen && this.props.currentUser) {
    //   modal = <CreateOrderModal changeParentState={this.handler} promo_price={this.state.promo_price} firm={this.state.firm} promo_name={this.state.promo_name} sub_broker_client={this.state.sub_broker_client} client_role={this.state.client_role} street={this.state.street} city={this.state.city} state={this.state.state} formatted_address={this.state.formatted_address} apartment={this.state.apartment} zipcode={this.state.zipcode} discount={this.state.discount} account_credit={this.state.account_credit} subservices={this.state.selectedSubservices} isLargeScreen={this.props.isLargeScreen} appointments={this.state.appointments} submitOrder={this.submitOrder} />
    // }

    let projectText = "Create Project"
    let component = "";
    let componentClosed1 = "";
    let componentClosed2 = "";
    let componentClosed3 = "";
    let componentClosed4 = "";
    let componentClosed5 = "";
    let componentClosed6 = "";
    let componentClosed7 = "";
    let componentClosed8 = "";
    let buttons = "";
    let button2 = "";

    let modal = this.modal()

    let order_summary = <CheckoutComponent currentUser={this.props.currentUser} changeParentState={this.handler} upload={this.state.upload} preview={true} category={this.state.category} product={this.state.product} selected_photos_with_info={this.state.selectedPhotosWithInfo} account_credit={this.state.account_credit} deleteOrderPhoto={this.deleteOrderPhoto}
      detailed_orders={this.state.detailed_orders} />

    if (this.state.page === 1) {
      component = <VirtualSuiteSelectionComponent changeParentState={this.handler} virtual_suite_type={this.state.virtual_suite_type} page={this.state.page} />
      componentClosed3 = <CategorySelectionComponent changeParentState={this.handler} minimize={true} page={this.state.page} selected={this.state.category} summaryErrors={this.state.summaryErrors} virtual_suite_type={this.state.virtual_suite_type} />
      componentClosed4 = <PhotoComponent deleteOrderPhoto={this.deleteOrderPhoto} project_client_id={this.state.project_client_id} project_address={this.state.project_address} project_id={this.state.project_id} detailed_orders={this.state.detailed_orders} showToast={this.props.showToast} currentUser={this.props.currentUser} changeParentState={this.handler} upload={this.state.upload} page={this.state.page} waiting={this.state.waiting} summaryErrors={this.state.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.state.selectedPhotos} photos={this.state.photos} photos_src={this.state.photos_src} selectedPhotosWithInfo={this.state.selectedPhotosWithInfo} category={this.state.category} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed5 = <ProductSelectionComponent changeParentState={this.handler} product={this.state.product}  page={this.state.page} category_id={this.state.category?.id} minimize={true} />
      componentClosed7 = <NotesComponent changeParentState={this.handler} page={this.state.page} minimize={true}/>
      buttons = <div className="signupFormButtons">
        {Object.values(this.state.orders).length > 0 ? <div className="signupFormPrevious orderSummaryButton" onClick={() => this.setState({ page: 7, summaryErrors: [] })}>Order Summary</div> : ""}
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 2) {
      componentClosed1 = <VirtualSuiteSelectionComponent changeParentState={this.handler} minimize={true} virtual_suite_type={this.state.virtual_suite_type} page={this.state.page}/>
      componentClosed2 = ""
      componentClosed4 = <PhotoComponent deleteOrderPhoto={this.deleteOrderPhoto} project_client_id={this.state.project_client_id} project_address={this.state.project_address} project_id={this.state.project_id} detailed_orders={this.state.detailed_orders} showToast={this.props.showToast} currentUser={this.props.currentUser} changeParentState={this.handler} upload={this.state.upload} page={this.state.page} photos_src={this.state.photos_src}  waiting={this.state.waiting} summaryErrors={this.state.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.state.selectedPhotos} photos={this.state.photos} selectedPhotosWithInfo={this.state.selectedPhotosWithInfo} category={this.state.category} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed5 = <ProductSelectionComponent changeParentState={this.handler} product={this.state.product}  page={this.state.page} category_id={this.state.category?.id} minimize={true} />
      componentClosed7 = <NotesComponent changeParentState={this.handler} page={this.state.page} minimize={true} />
      component = <CategorySelectionComponent changeParentState={this.handler} page={this.state.page} virtual_suite_type={this.state.virtual_suite_type} selected={this.state.category} summaryErrors={this.state.summaryErrors} />
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 3) {
      componentClosed1 = <VirtualSuiteSelectionComponent changeParentState={this.handler} page={this.state.page} minimize={true} virtual_suite_type={this.state.virtual_suite_type} />
      componentClosed2 = <CategorySelectionComponent changeParentState={this.handler} minimize={true} page={this.state.page} virtual_suite_type={this.state.virtual_suite_type} selected={this.state.category} summaryErrors={this.state.summaryErrors} />
      component = <PhotoComponent deleteOrderPhoto={this.deleteOrderPhoto} project_client_id={this.state.project_client_id} project_address={this.state.project_address} project_id={this.state.project_id} detailed_orders={this.state.detailed_orders} showToast={this.props.showToast} currentUser={this.props.currentUser} changeParentState={this.handler} page={this.state.page} upload={this.state.upload} waiting={this.state.waiting} photos_src={this.state.photos_src} summaryErrors={this.state.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.state.selectedPhotos} photos={this.state.photos} selectedPhotosWithInfo={this.state.selectedPhotosWithInfo} category={this.state.category} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed5 = <ProductSelectionComponent changeParentState={this.handler} product={this.state.product} page={this.state.page} category_id={this.state.category?.id} minimize={true} />
      componentClosed7 = <NotesComponent changeParentState={this.handler} page={this.state.page} minimize={true} />
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } 
    else if (this.state.page === 4){
      componentClosed1 = <VirtualSuiteSelectionComponent changeParentState={this.handler} page={this.state.page} minimize={true} virtual_suite_type={this.state.virtual_suite_type} />
      componentClosed2 = <CategorySelectionComponent changeParentState={this.handler} minimize={true} page={this.state.page} virtual_suite_type={this.state.virtual_suite_type} selected={this.state.category} summaryErrors={this.state.summaryErrors} />
      componentClosed6 = <PhotoComponent deleteOrderPhoto={this.deleteOrderPhoto} project_client_id={this.state.project_client_id} project_address={this.state.project_address} project_id={this.state.project_id} detailed_orders={this.state.detailed_orders} showToast={this.props.showToast} currentUser={this.props.currentUser} changeParentState={this.handler} page={this.state.page} upload={this.state.upload} waiting={this.state.waiting} photos_src={this.state.photos_src}  summaryErrors={this.state.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.state.selectedPhotos} photos={this.state.photos} selectedPhotosWithInfo={this.state.selectedPhotosWithInfo} category={this.state.category} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed7 = <NotesComponent changeParentState={this.handler} page={this.state.page} minimize={true} />
      component= <ProductSelectionComponent changeParentState={this.handler} page={this.state.page} category_id={this.state.category?.id} product={this.state.product}/>
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 5) {
      componentClosed1 = <VirtualSuiteSelectionComponent changeParentState={this.handler} page={this.state.page} minimize={true} virtual_suite_type={this.state.virtual_suite_type} />
      componentClosed2 = <CategorySelectionComponent changeParentState={this.handler} minimize={true} page={this.state.page} virtual_suite_type={this.state.virtual_suite_type} selected={this.state.category} summaryErrors={this.state.summaryErrors} />
      componentClosed6 = <PhotoComponent deleteOrderPhoto={this.deleteOrderPhoto} project_client_id={this.state.project_client_id} project_address={this.state.project_address} project_id={this.state.project_id} detailed_orders={this.state.detailed_orders} showToast={this.props.showToast} currentUser={this.props.currentUser} changeParentState={this.handler} page={this.state.page} upload={this.state.upload} waiting={this.state.waiting} photos_src={this.state.photos_src} summaryErrors={this.state.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.state.selectedPhotos} photos={this.state.photos} selectedPhotosWithInfo={this.state.selectedPhotosWithInfo} category={this.state.category} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed8 = <ProductSelectionComponent changeParentState={this.handler} page={this.state.page} category_id={this.state.category?.id} product={this.state.product} minimize={true} />
      component = <NotesComponent changeParentState={this.handler} page={this.state.page} />
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 6){
      componentClosed1 = <VirtualSuiteSelectionComponent changeParentState={this.handler} page={this.state.page} minimize={true} virtual_suite_type={this.state.virtual_suite_type} />
      componentClosed2 = <CategorySelectionComponent changeParentState={this.handler} minimize={true} page={this.state.page} virtual_suite_type={this.state.virtual_suite_type} selected={this.state.category} summaryErrors={this.state.summaryErrors} />
      componentClosed6 = <PhotoComponent deleteOrderPhoto={this.deleteOrderPhoto} project_client_id={this.state.project_client_id} project_address={this.state.project_address} project_id={this.state.project_id} detailed_orders={this.state.detailed_orders} showToast={this.props.showToast} currentUser={this.props.currentUser} changeParentState={this.handler} page={this.state.page} upload={this.state.upload} waiting={this.state.waiting} photos_src={this.state.photos_src} summaryErrors={this.state.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.state.selectedPhotos} photos={this.state.photos} selectedPhotosWithInfo={this.state.selectedPhotosWithInfo} category={this.state.category} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed8 = <ProductSelectionComponent changeParentState={this.handler} page={this.state.page} category_id={this.state.category?.id} product={this.state.product} minimize={true} />
      component = <NotesComponent changeParentState={this.handler} page={this.state.page} />
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
      modal = <AddMorePhotosModal onAddMoreServices={this.addMoreServices} handleNext={this.handleNext} changeParentState={this.handler} />
    }else if (this.state.page === 7) {
      order_summary = ""
      component = <CheckoutComponent currentUser={this.props.currentUser} changeParentState={this.handler} upload={this.state.upload} category={this.state.category} product={this.state.product} selected_photos_with_info={this.state.selectedPhotosWithInfo} account_credit={this.state.account_credit} deleteOrderPhoto={this.deleteOrderPhoto}
        detailed_orders={this.state.detailed_orders}
        specialNote={this.state.specialNote} checkout={true}/>
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={() => this.setState({paymentModalOpen: true})}>Pay Now</div>
      </div>
    } 
    else if (this.state.page === 8) {
      component = ""
      modal = <OrderCreatedModal orders={this.state.detailed_orders} project_id={this.state.project_id} changeParentState={this.handler} />
    }


    return (
      <div className="createProjectMainContainer">
        {modal}
        
        {/* <div className="settingsPageHeading createProjectPageHeading">
          {icon}
          <span className="dashboardSubHeading">{projectText}</span>
        </div> */}

        <div className="projectsIndexPageHeading createProjectsIndexPageHeading">
          <div className="dashboardSubHeading">Create Virtual Suite</div>
          <div className="mobileLogo"><img src={smallMobileLogo} /></div>
        </div>

        {this.state.errors.length > 0 && (
          <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
        )}

        <div className="createProjectGroupings">
          <div className="createProjectGroup1">
            {componentClosed1}
            {componentClosed2}
            {componentClosed6}
            {componentClosed8}
            <div>
              <div id="scrollToHere"></div>
            </div>
            {component}
            {buttons}
            {componentClosed3}
            {componentClosed4}
            {componentClosed5}
            {componentClosed7}
          </div>
          <div className="createProjectGroup2">
            {order_summary}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CreateVirtualSuiteMain);