import VirtualStagingFullService from './virtualStaging/virtualStagingFullService.png';

import ServiceDropDownArrow from './serviceDropDown/arrow.png';

import ListIcon1 from './listIcon1.png';
import ListIcon2 from './listIcon2.png';
import ListIcon3 from './listIcon3.png';

import Gallery11 from './virtualStaging/galleryImage1-1.png';
import Gallery1 from './virtualStaging/galleryImage1.jpeg';
import Gallery2 from './virtualStaging/galleryImage2.jpeg';
import Gallery3 from './virtualStaging/galleryImage3.jpeg';
import Gallery4 from './virtualStaging/galleryImage4.jpeg';

import Gallery5 from './virtualStaging/galleryImage5.jpeg';
import Gallery6 from './virtualStaging/galleryImage6.jpeg';
import Gallery7 from './virtualStaging/galleryImage7.jpeg';
import Gallery8 from './virtualStaging/galleryImage8.jpeg';

import Gallery9 from './photography/galleryImage9New.jpg';
import Gallery10 from './photography/galleryImage10New.jpg';

import ImageSliderMarker from '../landing/image-slider-marker.svg';

import PhotographyFullService from './photography/photographyFullService.png';

import VideoFullService from './video/videoFullService.png';
import PlayButton from './video/play-button.svg';

import InteractiveToursFullService from './interactiveTours/interactiveToursFullService.png';

import FloorPlansFullService from './floorPlans/floorPlansFullService.png';

import RenderingFullService from './rendering/renderingFullService.png';

import SectionImage1 from './photography/sectionImage1.png';
import SectionImage2 from './photography/sectionImage2.png';
import PhotographyFullService2 from './photography/photographyFullService2.png';
import SectionBackground from './photography/sectionBackground.png';

import RenderingSlide1 from './rendering/renderingSlide1.png';
import RenderingSlide2 from './rendering/renderingSlide2.png';
import RenderingSlide3 from './rendering/renderingSlide3.png';
import RenderingSlide4 from './rendering/renderingSlide4.png';
import RenderingSlide5 from './rendering/renderingSlide5.png';
import RenderingSlide6 from './rendering/renderingSlide6.png';

import FloorPlansBackground from './floorPlans/floorPlansBackground.png';
import FloorPlansBase from './floorPlans/floorPlansBase.png';
import FloorPlansBaseChange from './floorPlans/floorPlansBaseChange.png';
import FloorPlansCover from './floorPlans/floorPlansCover.png';

import VirtualStagingSlider1Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider1Post.jpeg';
import VirtualStagingSlider2Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider2Post.jpeg';
import VirtualStagingSlider3Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider3Post.jpeg';
import VirtualStagingSlider4Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider4Post.jpeg';
import VirtualStagingSlider5Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider5Post.jpeg';
import VirtualStagingSlider6Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider6Post.jpeg';
import VirtualStagingSlider7Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider7Post.jpeg';
import VirtualStagingSlider8Post from './virtualStaging/virtualStagingSlider/virtualStagingSlider8Post.jpeg';

import VirtualStagingSlider1Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider1Pre.jpeg';
import VirtualStagingSlider2Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider2Pre.jpeg';
import VirtualStagingSlider3Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider3Pre.jpeg';
import VirtualStagingSlider4Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider4Pre.jpeg';
import VirtualStagingSlider5Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider5Pre.jpeg';
import VirtualStagingSlider6Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider6Pre.jpeg';
import VirtualStagingSlider7Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider7Pre.jpeg';
import VirtualStagingSlider8Pre from './virtualStaging/virtualStagingSlider/virtualStagingSlider8Pre.jpeg';

import SliderBackground from './virtualStaging/virtualStagingSlider/sliderBackground.svg';
import RangeIcon from './virtualStaging/virtualStagingSlider/rangeIcon.svg';

import VirtualStagingMedia1 from './virtualStaging/virtualStagingContractMedia/virtualStagingMedia1.jpeg';
import VirtualStagingMedia2 from './virtualStaging/virtualStagingContractMedia/virtualStagingMedia2.jpeg';
import VirtualStagingMedia3 from './virtualStaging/virtualStagingContractMedia/virtualStagingMedia3.jpeg';
import VirtualStagingMedia4 from './virtualStaging/virtualStagingContractMedia/virtualStagingMedia4.jpeg';

import NewProjectIcon from './virtualStaging/virtualStagingContractMedia/newProjectIcon.svg';
import CustomizationIcon from './virtualStaging/virtualStagingContractMedia/customizationIcon.svg';
import VirtualStudioIcon from './virtualStaging/virtualStagingContractMedia/virtualStudioIcon.svg';
import MarketReadyIcon from './virtualStaging/virtualStagingContractMedia/marketReadyIcon.svg';

const SERVICE_DROPDOWN = {
  ServiceDropDownArrow,
};

const SERVICES = {
  ListIcon1,
  ListIcon2,
  ListIcon3,
  ImageSliderMarker,
};

const VIRTUAL_STAGING = {
  VirtualStagingFullService,
  ROW_ONE: [Gallery1, Gallery2, Gallery3, Gallery4],
  ROW_TWO: [Gallery5, Gallery6, Gallery7, Gallery8],
  VIRTUAL_STAGING_SLIDER: {
    SliderBackground,
    RangeIcon,
    Slides: [
      {
        Post: VirtualStagingSlider1Post,
        Pre: VirtualStagingSlider1Pre,
      },
      {
        Post: VirtualStagingSlider2Post,
        Pre: VirtualStagingSlider2Pre,
      },
      {
        Post: VirtualStagingSlider3Post,
        Pre: VirtualStagingSlider3Pre,
      },
      {
        Post: VirtualStagingSlider4Post,
        Pre: VirtualStagingSlider4Pre,
      },
      {
        Post: VirtualStagingSlider5Post,
        Pre: VirtualStagingSlider5Pre,
      },
      {
        Post: VirtualStagingSlider6Post,
        Pre: VirtualStagingSlider6Pre,
      },
      {
        Post: VirtualStagingSlider7Post,
        Pre: VirtualStagingSlider7Pre,
      },
      {
        Post: VirtualStagingSlider8Post,
        Pre: VirtualStagingSlider8Pre,
      },
    ],
  },
  VIRTUAL_STAGING_CONTRACT_MEDIA: [
    VirtualStagingMedia1,
    VirtualStagingMedia2,
    VirtualStagingMedia3,
    VirtualStagingMedia4,
  ],
  NewProjectIcon,
  CustomizationIcon,
  VirtualStudioIcon,
  MarketReadyIcon,
};

const PHOTOGRAPHY = {
  PhotographyFullService,
  PhotographyFullService2,
  ROW_ONE: [Gallery11, Gallery9, Gallery3, Gallery4],
  ROW_TWO: [Gallery5, Gallery6, Gallery7, Gallery10],
  SectionImage1,
  SectionImage2,
  SectionBackground,
};

const VIDEO = {
  VideoFullService,
  PlayButton,
};

const INTERACTIVE_TOURS = {
  InteractiveToursFullService,
};

const FLOOR_PLANS = {
  FloorPlansFullService,
  FloorPlansBackground,
  FloorPlansBase,
  FloorPlansBaseChange,
  FloorPlansCover,
};

const RENDERING = {
  RenderingFullService,
  RenderingSlide1,
  RenderingSlide2,
  RenderingSlide3,
  RenderingSlide4,
  RenderingSlide5,
  RenderingSlide6,
};

export {
  SERVICE_DROPDOWN,
  SERVICES,
  VIRTUAL_STAGING,
  PHOTOGRAPHY,
  VIDEO,
  INTERACTIVE_TOURS,
  FLOOR_PLANS,
  RENDERING,
};
