import React from "react";
import { Route, Link } from "react-router-dom";
import ProductItem from "./product_item";


export default class UpdateProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: this.props.category.products,
      name: "",
      cover: "",
      cover_src: "",
      failedMessage: ""
    }

    this.handleInput = this.handleInput.bind(this)
    this.createProduct = this.createProduct.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
  }

  componentWillUnmount() {
      URL.revokeObjectURL(this.state.cover_src)
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handlePictureSelected(event) {
    let cover = event.target.files[0];
    let cover_src = URL.createObjectURL(cover);

    this.setState({
      cover: cover,
      cover_src: cover_src
    });
  }

  // createSubService() {
  //   let sub_service_info = { title: this.state.title, description: this.state.description, price: this.state.price, service_id: this.props.service.id }
  //   this.props.createSubService(sub_service_info).then(() => {
  //     if (this.props.errors.length === 0) {
  //       this.setState({ title: "", description: "", price: "" })
  //     }
  //   })
  // }

  createProduct(){

    let errors = []

    if (this.state.name === ""){
      errors.push("Name is required")
    } else if (this.state.cover === ""){
      errors.push("Photo is required")
    }

    if (errors.length === 0){

    let formData = new FormData();

    formData.append("virtual_staging_category[virtual_staging_products_attributes][0][name]", this.state.name)
    formData.append("virtual_staging_category[virtual_staging_products_attributes][0][cover]", this.state.cover)
    
    this.props.updateProducts(formData, this.props.category.id).then(() => {
      this.setState({failedMessage: "", cover: "", name: "", cover_src: ""})
      this.props.showToast("Products have been updated")
    })
    } else {
      this.setState({failedMessage: errors[0]})
    }
  }


  render() {

    let companyNameErrorClass = "";
    let agentMlsIdErrorClass = "";
    let companyAddressErrorClass = "";
    let companyCityErrorClass = "";
    let companyStateErrorClass = "";
    let companyZipErrorClass = "";


    let companyNameErrors = [];
    let agentMlsIdErrors = [];
    let companyAddressErrors = [];
    let companyCityErrors = [];
    let companyStateErrors = [];
    let companyZipErrors = [];


    let products = Object.values(this.props.category.products).map((product, i) => <ProductItem key={i} product={product} updateProducts={this.props.updateProducts} category={this.props.category} deleteProduct={this.props.updateProducts} />)

    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading"> {this.props.category.name}'s Products</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>
          <div className="settingsCategoryGroup1">
            {products}
            <div>
              <div className="createNewSubService">Create New Product</div>
              <div className="failedMessage">{this.state.failedMessage}</div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Name </label>
                <input className="formInput" id={companyNameErrorClass} onChange={this.handleInput("name")} type="string" placeholder="Product Name" value={this.state.name} />
                <ul className="errorUl">{companyNameErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Cover </label>
                <div className="galleryPhotoDiv">
                  <img className="settingsAvatar settingsGalleryCover" src={this.state.cover_src} />
                  <label className="settingsChangePhotoButton coverUploadButton">
                    <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg" />
                  Add Photo
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button onClick={this.createProduct} className="settingsButton subServicesButton">Create Product</button>
          </div>
        </div>
      </div>
    );
  }
}