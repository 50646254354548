import styled, {css} from 'styled-components';

import { GetStartedBtnDark } from '../../../util/color_constant_util';
import { CREATIVE_MEDIA } from '../../../../../assets/images/landing';

export const CreativeMedia = styled.div`
  z-index: 1;
  padding: 80px 0px;
  background-color: ${props => props.color};
  position: relative;
  
  @media screen and (max-width: 767px) {
    padding: 80px 0px 0px;
    background: #f6f8ff;
  }
  
  ${props => props.mobilePadding && css`
    @media screen and (max-width: 767px) {
      padding: ${props => props.mobilePadding};
    }
  `}
  
  ${props => props.bgImage && css`
    &::before {
      content: '';
      position: absolute;
  
      height: 60%;
      width: 28%;
      top: 40%;
      right: 0%;
  
      background-image: url(${CREATIVE_MEDIA.CreativeMediaBackground});
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 0;
      @media screen and (max-width: 1799px) {
        width: 35%;
      }
      @media screen and (max-width: 1399px) {
        width: 40%;
        height: 50%;
        top: 45%;
      }
      @media screen and (max-width: 991px) {
        width: 50%;
        height: 50%;
        top: 75%;
        right: -14%;
      }
      @media screen and (max-width: 799px) {
        width: 65%;
        height: 50%;
        top: 75%;
        right: -20%;
      }
      @media screen and (max-width: 767px) {
        width: 83%;
        height: 26%;
        top: 33%;
        right: -28%;
      }
  
      @media screen and (max-width: 499px) {
        width: 124%;
        right: -45%;
      }
    }
  `}

  .row {
    max-width: 1372px;
    margin: 0 auto;
    @media screen and (max-width: 1390px) {
      max-width: 96%;
    }
    @media screen and (max-width: 1199px) {
      max-width: 800px;
    }
    .panel {
      margin-bottom: 30px;
      z-index: 2;
    }
    .panel:hover .rightArrow {
      display: block;
    }
  }
`;

export const CreativeHeadParagraph = styled.p`
  width: 730px;
  margin: 0 auto;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.headingSize ? props.headingSize : '40px')};
  line-height: 55px;
  align-items: center;
  text-align: center;
  color: #1b264f;
  margin-bottom: 70px;
  @media screen and (max-width: 1280px) {
    font-size: 36px;
    line-height: 44px;
    width: 530px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
    line-height: 24px;
    width: auto;
    margin-bottom: 35px;
  }
`;

export const CreativeHeadSpan = styled.span`
  color: #506bca !important;
`;

export const PanelBody = styled.div`
  height: 336px;
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  transition: transform 0.25s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    @media screen and (max-width: 991px) {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 1390px) {
    height: 370px;
  }

  @media screen and (max-width: 1199px) {
    height: 300px;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const CreativeImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 185px;
  min-width: 185px;
  border-radius: 8px 0px 0px 8px;
  height: 100%;
  @media screen and (max-width: 1199px) {
    width: 150px;
    min-width: 150px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: row;
    width: 100%;
    height: 120px;
  }
`;

export const CreativePanelImageFirst = styled.div`
  flex: 1;
  border-radius: 8px 0px 0px 0px;
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media screen and (max-width: 767px) {
    border-radius: 8px 0px 0px 0px;
  }
`;

export const CreativePanelImageSecond = styled.div`
  flex: 1;
  border-radius: 0px 0px 0px 8px;
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media screen and (max-width: 767px) {
    border-radius: 0px 8px 0px 0px;
  }
`;

export const CreativePanelImage = styled.div`
  flex: 1;
  border-radius: 8px 0px 0px 8px;
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (max-width: 767px) {
    border-radius: 8px 8px 0px 0px;
  }
`;

export const PanelInfo = styled.div`
  background-color: #ffffff;
  padding: 30px 30px 0px 30px;
  border-radius: 0px 8px 8px 0px;
  @media screen and (max-width: 1390px) {
    padding: 20px;
  }
  @media screen and (max-width: 1199px) {
    padding: 40px 15px 0px 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 30px 30px 30px;
    border-radius: 0px;
  }
`;

export const PanelTitle = styled.p`
  margin-bottom: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #353844;
  @media screen and (max-width: 1199px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const PanelList = styled.ul`
  margin-bottom: 12px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  color: #353844;
  list-style: none;
  padding: 0px 0px 0px 15px;
  @media screen and (max-width: 1199px) {
    font-size: 14px;
    line-height: 26px;
  }
  @media screen and (max-width: 991px) {
    line-height: 17px;
  }
`;

export const PanelLi = styled.li`
  /* padding-top: 5px; */
  &:before {
    content: '\\2022';
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    color: #d2ad81;
  }
`;

export const PanelDescription = styled.p`
  word-break: break-word;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #353844;

  @media screen and (max-width: 1199px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ArrowImage = styled.img`
  margin-top: 20px;
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

export const GetStartedContainer = styled.div`
  position: relative;
  z-index: 2;
  padding: 30px 0px;
  text-align: center;
  display: block;
  @media screen and (max-width: 767px) {
    padding: 0px 0px 20px;
  }
`;

export const GetStarted = styled.button`
  width: 202px;
  height: 60px;
  background: #1b264f;
  border: solid 1px #1b264f;
  border-radius: 8px;
  margin: 40px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 56px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: initial;
  color: #ffffff;
  &:hover {
    background-color: ${GetStartedBtnDark};
  }
  @media screen and (max-width: 767px) {
    margin: 20px 0px 40px;
    width: 144px;
    height: 43px;
    font-size: 14px;
    line-height: 14px;
  }
`;
