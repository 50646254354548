import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from "react-slick";

import {
  VideoSliderWrapper,
  SliderContainer,
  NavSliderContainer,
  PlayerWrapper,
  SliderNavItemWrapper,
  NavImage,
  NavDescription,
  NavDescriptionIcon,
  NavDescriptionWrapper,
} from './styles';


const VideoTopSlider = ({ videoLinks, imageLinks }) => {
  const [activeVideoSrc, setActiveVideoSrc] = useState(null);

  React.useEffect(() => {
    const video = videoLinks.find((el) => {
      return el.id === 1;
    });
    setActiveVideoSrc(video.src);
  }, []);

  const navSlides = imageLinks.map(({ src, id, text }) => {
    return (
      <NavItem
        key={id}
        src={src}
        text={text}
      />
    );
  });

  const beforeChange = (currentSlide, nextSlide) => {
    // console.log(currentSlide, nextSlide);
    const video = videoLinks.find((el) => {
      return el.id === nextSlide + 1;
    });
    setActiveVideoSrc(video.src);
  }

  return (
    <VideoSliderWrapper>
      <PlayerWrapper>
        <ReactPlayer
          className="player"
          url={activeVideoSrc}
          playing={true}
          controls
          loop
          muted
          width="100vw"
          height="100%"
          config={{
            vimeo: {
              playerOptions: {
                playsinline: true,
                controls: true,
              }
            },
          }}
        />
      </PlayerWrapper>
      <NavSliderContainer className='sliderNav'>
        <Slider
          slidesToShow={4}
          slidesToScroll={1}
          centerMode={true}
          focusOnSelect={true}
          variableWidth={true}
          arrows={false}
          dots={false}
          lazyLoad={"progressive"}
          beforeChange={beforeChange}
          responsive={[
            {
              breakpoint: 1080,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                centerMode: false,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
              }
            }
          ]}
          // responsive={[
          //   {
          //     breakpoint: 1100,
          //     settings: {
          //       slidesToShow: 3,
          //       slidesToScroll: 1,
          //     }
          //   },
          //   {
          //     breakpoint: 767,
          //     settings: {
          //       slidesToShow: 2,
          //       slidesToScroll: 1,
          //       centerMode: false,
          //     }
          //   }
          // ]}
        >
          {navSlides}
        </Slider>
      </NavSliderContainer>
    </VideoSliderWrapper>
  );
};

const NavItem = (props) => {
  const { src, text } = props;

  return (
    <SliderNavItemWrapper>
      <NavImage
        src={src}
      />
      <NavDescriptionWrapper>
        <NavDescription>{text}</NavDescription>
        <NavDescriptionIcon />
      </NavDescriptionWrapper>
    </SliderNavItemWrapper>
  );
}

export default VideoTopSlider;


// import React, { useState } from 'react';
// import ReactPlayer from 'react-player';
// import Slider from "react-slick";

// import {
//   VideoSliderWrapper,
//   SliderContainer,
//   NavSliderContainer,
//   PlayerWrapper,
//   SliderNavItemWrapper,
//   NavImage,
//   NavDescription,
//   NavDescriptionIcon,
//   NavDescriptionWrapper,
// } from './styles';


// const VideoTopSlider = ({ videoLinks, imageLinks }) => {
  
//   const [nav1, setNav1] = useState(null);
//   const [nav2, setNav2] = useState(null);
//   let slider1 = [];
//   let slider2 = [];

//   React.useEffect(() => {
//     setNav1(slider1)
//     setNav2(slider2)
//   }, [slider1, slider2]);

//   const videoSlides = videoLinks.map(({ src, id }) => {
//     return (
//       <VideoItem 
//         key={id}
//         src={src}
//       />
//     );
//   });

//   const navSlides = imageLinks.map(({ src, id, text }) => {
//     return (
//       <NavItem
//         key={id}
//         src={src}
//         text={text}
//       />
//     );
//   });

//   return (
//     <VideoSliderWrapper>
//       <SliderContainer className='sliderFor'>
//         <Slider
//           slidesToShow={1}
//           slidesToScroll={1}
//           fade={true}
//           centerMode={true}
//           // variableWidth and fade at the same time are breaking the layout
//           // variableWidth={true}
//           asNavFor={nav2}
//           ref={slider => (slider1 = slider)}
//         >
//           {videoSlides}
//         </Slider>
//       </SliderContainer>
//       <NavSliderContainer className='sliderNav'>
//         <Slider
//           slidesToShow={4}
//           slidesToScroll={1}
//           ref={slider => (slider2 = slider)}
//           centerMode={true}
//           focusOnSelect={true}
//           asNavFor={nav1}
//           variableWidth={true}
//         >
//           {navSlides}
//         </Slider>
//       </NavSliderContainer>
//     </VideoSliderWrapper>
//   );
// };

// const VideoItem = (props) => {
//   const { src } = props;

//   return (
//     <PlayerWrapper>
//       <ReactPlayer
//         className="player"
//         url={src}
//         playing={true}
//         loop
//         muted
//         width="100vw"
//         height="100%"
//         config={{
//           vimeo: {
//             playerOptions: {
//               playsinline: true,
//             }
//           },
//         }}
//       />
//     </PlayerWrapper>
//   );
// }

// const NavItem = (props) => {
//   const { src, text } = props;

//   return (
//     <SliderNavItemWrapper>
//       <NavImage
//         src={src}
//       />
//       <NavDescriptionWrapper>
//         <NavDescription>{text}</NavDescription>
//         <NavDescriptionIcon />
//       </NavDescriptionWrapper>
//     </SliderNavItemWrapper>
//   );
// }

// export default VideoTopSlider;
