import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Chart from 'chart.js/auto';
import { AdModalForm } from "../../pages/adModal/AdModalForm";
import img from '../../../../../assets/images/blog/email-form-image.png'

const RawWithChart = ({ block, showToast }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    // Find all canvas elements inside the container
    const canvasElements = container.querySelectorAll('canvas');

    // Iterate through each canvas and create the corresponding chart
    canvasElements.forEach(canvas => {
      const chartType = canvas.getAttribute('data-chart-type');
      const chartData = JSON.parse(canvas.getAttribute('data-chart-data'));
      const chartOptions = JSON.parse(canvas.getAttribute('data-chart-options'));

      new Chart(canvas, {
        type: chartType,
        data: chartData,
        options: chartOptions,
      });
    });

    const emailFormElements = container.querySelectorAll('.emailForm');

    // Iterate through each emailForm element and extract title and adId
    emailFormElements.forEach(emailForm => {
      const title = emailForm.getAttribute('data-title');
      const adId = emailForm.getAttribute('data-adId');

      const email_container = document.createElement('div');

      const emailFormComponent = (
        <AdModalForm
          showToast={showToast}
          title={title}
          button_text={'Submit'}
          imageUrl={img}
          isVertical={false}
          adId={adId}
          type={'horizontal'}
          ad_type={'slide_out'}
          show={true}
          onSubscribed={() => { }}
        />
      );

      ReactDOM.render(
        emailFormComponent,
        email_container
      );
    
      emailForm.parentNode.appendChild(email_container);
    });

  }, []);

  return (
    <div ref={containerRef} dangerouslySetInnerHTML={{ __html: block.data.html }} />
  );
};

export default RawWithChart;