import styled from 'styled-components';

export const PublicRouteContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: white;
  
  .navbarMenuImageWrapper {
    background: white;
  }
`;
