import React, {useEffect, useState} from "react";
import Services from "./form/services";
import Equipment from "./form/equipment";
import Examples from "./form/examples";
import {fetchServices} from "../../util/service_api_util";
import Location from "./form/location";
import PersonalDetails from "./form/personal_details";
import Congratulations from "./form/congratulations";
import {applyPhotographer} from "../../util/photographer_api_util";

const CareersApplyForm = () => {
  const [selectedServices, setSelectedServices] = useState([])
  const [equipmentByService, setEquipmentByService] = useState({})
  const [examplesByService, setExamplesByService] = useState({})
  const [personalDetails, setPersonalDetails] = useState({})
  const [services, setServices] = useState([])
  const [location, setLocation] = useState({travelDuration: '10 Miles'})
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    const permittedSlugs = ['photography', 'aerial', 'floorplans', 'video', 'twilight', 'virtual-tour']

    fetchServices().then(result => {
      const services = Object.values(result)
      setServices(services.filter(s => permittedSlugs.includes(s.slug) && !s.reshoot))
    })
  }, [])

  useEffect(() => {
    const newEquipmentByService = {}

    for (const service of selectedServices) {
      newEquipmentByService[service.slug] ||= {
        service, fields: {}
      }
    }

    setEquipmentByService(newEquipmentByService)
  }, [selectedServices])

  useEffect(() => {
    const newExamplesByService = {}

    for (const service of selectedServices) {
      newExamplesByService[service.slug] ||= {
        service, website: ''
      }
    }

    setExamplesByService(newExamplesByService)
  }, [selectedServices])

  const steps = [
    <Services selectedServices={selectedServices} services={services}
              setSelectedServices={setSelectedServices}/>,
    <Equipment equipmentByService={equipmentByService}
               setEquipmentByService={setEquipmentByService}/>,
    <Examples examplesByService={examplesByService}
              setExamplesByService={setExamplesByService}/>,
    <Location location={location} setLocation={setLocation}/>,
    <PersonalDetails personalDetails={personalDetails}
                     setPersonalDetails={setPersonalDetails}/>
  ]

  const [currentStep, setCurrentStep] = useState(0);

  const onPrevious = (e) => {
    e.preventDefault()
    setCurrentStep(Math.max(currentStep - 1, 0))
  }

  const onStep = (e, step) => {
    e.preventDefault()
    if (step < currentStep && step >= 0) {
      setCurrentStep(step)
    }
  }

  const onNext = (e) => {
    e.preventDefault()

    if (currentStep < steps.length - 1) {
      setCurrentStep(Math.min(currentStep + 1, steps.length - 1))
    } else {
      const services = selectedServices.map(service => {
        const {service: equipmentService, ...equipment} = equipmentByService[service.slug]
        const {service: exampleService, ...example} = examplesByService[service.slug]
        return {
          service: {id: service.id, title: service.title, slug: service.slug}, example, equipment,
        }
      })

      const payload = {services, location, personalDetails}
      setSubmitting(true)

      applyPhotographer(payload).then(() => {
        setSubmitted(true)
        setSubmitting(false)
      }).catch(() => {
        setSubmitting(false)
      })
    }
  }

  const canProceed = () => {
    if (currentStep === 0) {
      return selectedServices.length > 0
    }

    return true
  }

  return <div className="loginForm careers">
    <form onSubmit={onNext} className="careersFormContent">
      {submitted && <Congratulations/>}

      {!submitted && <>
        <div className="careers-steps-wrapper">
          {steps[currentStep]}
        </div>

        <div className="careers-steps-footer">
          <div className="careers-steps-footer-inner">
            <div className="steps">
              {steps.map((_, i) => {
                return <div
                  className={`step ${i < currentStep ? 'previous' : (i === currentStep ? 'current' : 'next')}`}
                  key={`step-${i}`} onClick={e => onStep(e, i)}>
                  {i + 1}
                </div>
              })}

            </div>
            <div className="careers-steps-footer-buttons">
              <button className="projectEditButton projectEditButtonWhite me-2" onClick={onPrevious}
                      disabled={currentStep === 0}>
                Previous
              </button>
              <button className="projectEditButton projectEditButtonPrimary" type="submit"
                      disabled={!canProceed() || submitting}>
                {currentStep < steps.length - 1 ? "Next Step" : "Apply"}
              </button>
            </div>
          </div>
        </div>
      </>}
    </form>
  </div>
}

export default CareersApplyForm
