import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import arrowRight3 from "../../../../assets/images/arrow_right_3"
import {formatDatetime} from "../../util/date_util";


class ProjectDashboardItemUpcoming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.sendToProject = this.sendToProject.bind(this)
  }

  sendToProject() {
    this.props.history.push(`/projects_index/${this.props.project.slug}`)
  }

  render() {
    let project = this.props.project;
    let address = project.address
    let upcoming_appointment = ""
    let servicesList = []

    if (this.props.currentUser.roles.contractor) {
      let contractor_services = Object.values(project?.packages)?.filter(current_package => current_package.contractor_id === this.props.currentUser.id) || []

      contractor_services.forEach(pack => {
        if (upcoming_appointment === "" || new Date(pack.appointment_time) < upcoming_appointment) {
          upcoming_appointment = new Date(pack.appointment_time)
        }
        servicesList.push(<li>{pack.service_title}</li>)
      })
    } else {
      Object.values(project?.packages).forEach(pack => {
        if (upcoming_appointment === "" || new Date(pack.appointment_time) < upcoming_appointment){
          upcoming_appointment = new Date(pack.appointment_time)
        }
      })

      servicesList = project.services.map(service => <li>{service.title}</li>)
    }
    return (
      <div className="projectDashboardItemUpcoming" onClick={this.sendToProject}>
        <div className="projectDashboardItemUpcomingFlex">
          {address.formatted_address ? <div className="projectDashboardItemSubheading"> {address.apartment_number || ""} {address.formatted_address}</div> : <div className="projectDashboardItemSubheading"> {address.street1}, {address.city}, {address.region} {address.zip_code}</div>}
          {address.formatted_address ? <div className="projectDashboardItemSubheadingMobile"> {address.apartment_number || ""} {address.formatted_address}</div> : <div className="projectDashboardItemSubheadingMobile"> {address.street1}, {address.region} {address.zip_code}</div>}
          <div className="projectDashboardItemSecondGrouping">
            <div className="projectDashboardDateString"><span className="hideAtSmallScreen">Created:</span> {new Date(project.address.created_at).toLocaleDateString("en-US")}</div>
          </div>
        </div>
        {upcoming_appointment > new Date() ? <div className="projectDashboardItemSubheading upcomingAppointmentSubheading">Upcoming: {formatDatetime(upcoming_appointment, null, project.timezone)}</div> : ""}
        {upcoming_appointment > new Date() ? <div className="projectDashboardItemSubheadingMobile upcomingAppointmentSubheading">Upcoming: {formatDatetime(upcoming_appointment, null, project.timezone)}</div> : ""}
        <ul className="projectDashboardItemServicesUl">
        {servicesList}
        </ul>
      </div>
    )
  }
}

export default withRouter(ProjectDashboardItemUpcoming)