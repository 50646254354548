import React from 'react';
import {Link} from 'react-router-dom';

import {ROUTES} from '../../../routes';
import {
  ApplyButton,
  CareersDescription,
  CareersHeaderInnerWrapper,
  CareersHeaderWrapper,
  CareersTitle
} from "./styles";

const AboutHeader = () => {
  return (
    <CareersHeaderWrapper>
      <CareersHeaderInnerWrapper>
        <CareersTitle>Real Estate Photography Jobs</CareersTitle>
        <CareersDescription>
          Grow your career by becoming an approved Modern Angles Real Estate Photographer. If you’re looking to expand your income, be part of a team and learn unique skills along the way, you’re in the right place.
        </CareersDescription>
        <Link to={ROUTES.CAREERS_APPLY}>
          <ApplyButton>Apply Now</ApplyButton>
        </Link>
      </CareersHeaderInnerWrapper>
    </CareersHeaderWrapper>
  );
};

export default AboutHeader;
