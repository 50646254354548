import { connect } from "react-redux";
import SettingsFirms from "./settings_firms";
import React from "react";
import { withRouter } from "react-router-dom";
import { updateFirm, createFirm, deleteFirm, fetchFirms} from "../../../actions/settings_actions";
import {openModal, addInfo } from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  fetchFirms: () => dispatch(fetchFirms()),
  updateFirm: (firm, firm_id) => dispatch(updateFirm(firm, firm_id)),
  deleteFirm: (firm_id) => dispatch(deleteFirm(firm_id)),
  createFirm: (firm) => dispatch(createFirm(firm)),
  showToast: (message) => dispatch(showToast(message)),
  openModal: (modal, props) => {
    dispatch(openModal(modal, props));
  }
});

export default withRouter(connect(msp, mdp)(SettingsFirms));