import React from "react";
import VirtualStagingCategoryItem from "./virtual_staging_category_item"
import {fetchCategories} from "../../util/virtual_staging_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";


export default class VirtualStagingCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: false
    }
    this.toggleSelected = this.toggleSelected.bind(this);

    
  }

  componentDidMount(){
    this.setState({loading: true})
    fetchCategories(this.props.virtual_suite_type?.id).then(categories => this.setState({ categories: Object.values(categories).sort(function (a, b) { return a.sort_order - b.sort_order }), loading: false }))
  }

  toggleSelected(category) {
    if (this.props.selected !== category){
      this.props.changeParentState({category: category})
    } else {
      this.props.changeParentState({ category: null })
    }
    this.props.changeParentState({ product: null })
  }

  render() {
    if (this.state.loading) {
      return <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }

    let categoryCards = this.state.categories.map((category, i) => <VirtualStagingCategoryItem key={i} category={category} selected={this.props.selected?.id === category.id} toggleSelected={this.toggleSelected} index={i}/>)
    if (categoryCards.length === 0){
      categoryCards = <div style={{ fontWeight: "bold" }}>
        Sorry, virtual staging is not available at the moment. Please contact support for more information.
      </div>
    }
    return (
      <div>
        <div className="modalTitle">Choose Category</div>
        <div className="modalSubTitle">This will determine what furniture is available for the room.</div>
        <div className="row mt-3 mx-0 virtualStagingCategoryGrid">
          {categoryCards}
        </div>
      </div>
    );
  }
}