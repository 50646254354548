import { connect } from "react-redux";
import Settings from "./settings";
import React from "react";
import { openModal, closeModal } from "../actions/modal_actions";
import { fetchServices } from "../actions/service_actions";
import { logout } from "../actions/session_actions";
import { withRouter } from "react-router-dom"

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  projects: Object.values(state.entities.projects)
});

const mdp = (dispatch) => ({
  fetchServices: () => { dispatch(fetchServices()) },
  logout: () => dispatch(logout()),
});

export default withRouter(connect(msp, mdp)(Settings));