import React from "react";
import { createBlogCategory, updateBlogCategory } from "../../../util/blog_api_utils";


export default class BlogCategoryFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(this.props.blog_category || {
        title: '',
        sort_order: '',
      }),
      loading: false,
      errors: []
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSave() {
    const { title, sort_order } = this.state
    let data = {title: title, sort_order: sort_order}

    const save = this.state.id ? updateBlogCategory(this.state.id, data) : createBlogCategory(data)

    this.setState({ loading: true })

    save.then(blog_category => {
      this.props.closeModal()
      this.props.onSave(blog_category)
      this.setState({ loading: false })
    }).catch(response => {
      this.setState({ loading: false })
      this.setState({ errors: response.responseJSON || [] })
    })
  }

  render() {
    return (
      <div className="addFormModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Category' : 'Create Category'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Title</label>
              <input className="formInput" onChange={this.onChange} name="title" value={this.state.title} />
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel"> Sort Order </label>
              <input className="formInput" onChange={this.onChange} type="number" name="sort_order" placeholder="Sort Order" value={this.state.sort_order} />
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">
            Save
          </button>
        </div>
      </div>
    );
  }
}