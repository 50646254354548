import styled from 'styled-components';

export const BlueContainer = styled.div`
  position: relative;
  background-color: #f6f8ff;
`

export const WhiteContainer = styled.div`
  background-color: #fff;
`

export const Heading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #1b264f;
  text-transform: none;
  text-align: left;
  margin-bottom: 30px;

  @media only screen and (max-width: 1280px) {
    font-size: 36px;
    line-height: 44px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const Wrapper = styled.div`
  width: 1060px;
  margin: auto;
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
  
  & > * {
    flex: 1;
  }

  @media only screen and (max-width: 1140px) {
    width: calc(100% - 40px);
    padding: 40px 0;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`

export const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const ParagraphTitle = styled.div`
  color: #1B264F;
  text-align: left;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 6px;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
  }
`

export const Paragraph = styled.div`
  color: #353844;
  font-family: Nunito Sans;
  font-weight: 600;
  font-size: 17px;
  line-height: 160%;
  text-align: left;

  @media only screen and (max-width: 1280px) {
    font-size: 16px;
  }
`

export const ImageWrapper = styled.div`
  margin: auto auto 60px;
  width: 470px;
  height: 350px;

  @media only screen and (max-width: 470px) {
    width: 100%;
    height: auto;
  }

  @media only screen and (max-width: 840px) {
    margin: auto auto 30px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
