import React from "react";
import { deleteLocation, fetchLocations } from "../../../util/location_api_util";


export default class SettingsLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: []
    }
  }

  componentDidMount() {
    fetchLocations().then(locations => {
      this.setState({ locations })
    })
  }

  onCreate() {
    this.props.openModal("locationForm", { onSave: this.onCreated.bind(this) })
  }

  onUpdate(city) {
    this.props.openModal("locationForm", { onSave: this.onUpdated.bind(this), city })
  }

  onDelete(location) {
    if (window.confirm(`Are you sure you want to delete "${location.city}"?`)) {
      deleteLocation(location.id).then(() => {
        this.setState({ locations: this.state.locations.filter(f => f.id !== location.id) })
        this.props.showToast("Location has been removed")
      }).catch(() => {
        alert('Could not delete this location')
      })
    }
  }

  onCreated(location) {
    this.setState({ locations: [...this.state.locations, location] })
    this.props.showToast("Location has been created")
  }

  onUpdated(updatedLocation) {
    this.setState({ locations: this.state.locations.map(location => location.id === updatedLocation.id ? updatedLocation : location) })
    this.props.showToast("Location has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Locations</div>
          <div className="settingsPreferencesGroup1">
            {this.state.locations.map(location => (
              <div className="settingsServicesItem" key={location.id}>
                <div className="itemTitle">{location.city}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdate(location)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDelete(location)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>

              </div>
            ))}
          </div>
          <button onClick={() => this.onCreate()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}