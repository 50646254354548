import React from 'react';
import {useInView} from 'react-intersection-observer';
import FadeInAnimation from '../../home_page/homeComponents/FadeInAnimation';

import {
  Container,
  ContractHeadingWrapper,
  ContractMediaHeading,
  ContractMediaHeadingSpan,
  ContractMediaHeadingWrapper,
} from './styles';

const ContractMediaHeader = (props) => {
  const {isVirtualStaging} = props
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <Container>
        <FadeInAnimation
          duration={0.8}
          delayOrder={1}
          yOffset={30}
          inView={inView}
        >
          {isVirtualStaging && (
            <ContractMediaHeadingWrapper>
              <ContractHeadingWrapper className='isVirtualStaging'>
                <ContractMediaHeading>
                  The Modern Angles app brings<br/>
                  full-service{' '}
                  <ContractMediaHeadingSpan>
                    Virtual Staging
                  </ContractMediaHeadingSpan>{' '}
                  to your property
                </ContractMediaHeading>
              </ContractHeadingWrapper>
            </ContractMediaHeadingWrapper>
          )}
          {!isVirtualStaging && (
            <ContractMediaHeadingWrapper>
              <ContractHeadingWrapper>
                <ContractMediaHeading>
                  Take a closer look<br/>at our <ContractMediaHeadingSpan>Booking Platform</ContractMediaHeadingSpan>
                </ContractMediaHeading>
              </ContractHeadingWrapper>
            </ContractMediaHeadingWrapper>
          )}
        </FadeInAnimation>
      </Container>
    </div>
  );
};

export default ContractMediaHeader;
