import { connect } from "react-redux";
import SignUpAndPaymentModal from "./signup_and_payment_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { updatePayment, createPayment, fetchPaymentInfo } from "../../actions/settings_actions";
import { signup, fetchCurrentUser, removeCurrentUser } from "../../actions/session_actions";
import { openModal } from "../../actions/modal_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  createPayment: (paymentInfo) => dispatch(createPayment(paymentInfo)),
  signup: (user, autoConfirm) => dispatch(signup(user, autoConfirm )),
  openModal: (modal, props) => { dispatch(openModal(modal, props)); },
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id)),
  removeCurrentUser: () => dispatch(removeCurrentUser()),
});

export default withRouter(connect(msp, mdp)(SignUpAndPaymentModal));