import styled from 'styled-components';

import { GetStartedBtnLight } from '../../../util/color_constant_util';

export const CareersHeaderWrapper = styled.div`
  @media only screen and (max-width: 769px) {
    display: flex;
    padding: 0 24px;
  }
`;

export const CareersHeaderInnerWrapper = styled.div`
  width: 997px;
  margin: 98px auto 0px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1280px) {
    width: 760px;
  }
  @media only screen and (max-width: 769px) {
    width: 98%;
    max-width: 350px;
    margin: 60px 0px 0px;
  }
`;

export const CareersTitle = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 54px;
  letter-spacing: -0.01em;
  color: #1b264f;
  width: 50%;
  @media screen and (max-width: 769px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const CareersDescription = styled.p`
  margin: 36px 0;
  width: 50%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media screen and (max-width: 769px) {
    /* width: 350px; */
    width: 96%;
    max-width: 350px;
    font-size: 16px;
    line-height: 22px;
    margin: 8px 0px 28px 0px;
  }
`;

export const ApplyButton = styled.button`
  width: 202px;
  height: 60px;
  background-color: #ffffff;
  border: 2px solid #353844;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #353844;
  cursor: pointer;
  &:hover {
    background: ${GetStartedBtnLight};
  }
  @media only screen and (max-width: 769px) {
    width: 144px;
    height: 43px;
    font-size: 14px;
    line-height: 14px;
  }
`;
