import React from "react";
import {Card, CardDescription, CardTitle, SecondSectionContainer, SecondSectionWrapper} from "./styles";

const CareersSecondSection = () => {
  return (
    <SecondSectionWrapper>
      <SecondSectionContainer>
        <Card>
          <CardTitle data-order="1">
            Set your own schedule
          </CardTitle>
          <CardDescription>
            You decide what hours you are bookable by integrating your calendar with our app and customizing live availability.
          </CardDescription>
        </Card>
        <Card>
          <CardTitle data-order="2">
            Fast & automatic payments
          </CardTitle>
          <CardDescription>
            Get paid automatically with weekly deposits, and track scheduled payouts in-app. All photographers earn around $40-80 an hour.
          </CardDescription>
        </Card>
        <Card>
          <CardTitle data-order="3">
            Grow your portfolio, grow your career
          </CardTitle>
          <CardDescription>
            Modern Angles photographers have everything they need, from one-on-one training to live support. We’re with you every step of the way.
          </CardDescription>
        </Card>
      </SecondSectionContainer>
    </SecondSectionWrapper>
  );
};

export default CareersSecondSection;
