import React, {useState, useEffect} from "react";
import {
  ButtonWrapper,
  Heading,
  List,
  ListItem,
  ListItemDescription,
  ListItemImg,
  ListItemText,
  ListItemTitle,
  Wrapper,
  WrapperInner
} from "./styles";
import popularCity1 from '../../../../../assets/images/locations/popular-city-1.png'
import popularCity2 from '../../../../../assets/images/locations/popular-city-2.png'
import {ROUTES} from "../../../routes";
import {ApplyButton} from "../MapSection/styles";
import {Link, withRouter} from "react-router-dom";
import {fetchLocations} from "../../../util/location_api_util";
import { connect } from "react-redux";

const PopularCities = (props) => {
  const [cities, setCities] = useState([])

  const onScheduleShoot = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  useEffect(() => {
    fetchLocations().then(cities => setCities(cities));
  }, []);

  const getStateAbbreviation = (stateName) => {
    const stateMappings = {
      'Alabama': 'AL',
      'Alaska': 'AK',
      'Arizona': 'AZ',
      'Arkansas': 'AR',
      'California': 'CA',
      'Colorado': 'CO',
      'Connecticut': 'CT',
      'Delaware': 'DE',
      'Florida': 'FL',
      'Georgia': 'GA',
      'Hawaii': 'HI',
      'Idaho': 'ID',
      'Illinois': 'IL',
      'Indiana': 'IN',
      'Iowa': 'IA',
      'Kansas': 'KS',
      'Kentucky': 'KY',
      'Louisiana': 'LA',
      'Maine': 'ME',
      'Maryland': 'MD',
      'Massachusetts': 'MA',
      'Michigan': 'MI',
      'Minnesota': 'MN',
      'Mississippi': 'MS',
      'Missouri': 'MO',
      'Montana': 'MT',
      'Nebraska': 'NE',
      'Nevada': 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      'Ohio': 'OH',
      'Oklahoma': 'OK',
      'Oregon': 'OR',
      'Pennsylvania': 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      'Tennessee': 'TN',
      'Texas': 'TX',
      'Utah': 'UT',
      'Vermont': 'VT',
      'Virginia': 'VA',
      'Washington': 'WA',
      'West Virginia': 'WV',
      'Wisconsin': 'WI',
      'Wyoming': 'WY',
    };

    // Convert the state name to title case for case-insensitive matching
    const formattedStateName = stateName.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

    // Look up the abbreviation for the given state name
    return stateMappings[formattedStateName] || null;
  }
  
  return <Wrapper>
    <WrapperInner>
      <Heading>
        Popular Locations
      </Heading>
      <List>
        {cities.map((city, i) => (
          <ListItem key={`city-${i}`}>
            <Link to={'/coverage/' + encodeURIComponent(city.slug)}>
              <ListItemImg src={city.image?.url || popularCity1}></ListItemImg>
              <ListItemText>
                <ListItemTitle className="city-list-item-title">{city.city}</ListItemTitle>
                <ListItemDescription>{getStateAbbreviation(city.state)}</ListItemDescription>
              </ListItemText>
            </Link>
          </ListItem>
        ))}
      </List>

      <ButtonWrapper>
        <div onClick={onScheduleShoot}>
          <ApplyButton>Get Started</ApplyButton>
        </div>
      </ButtonWrapper>
    </WrapperInner>
  </Wrapper>
}

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(PopularCities));

