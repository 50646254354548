import React from "react";
import { Route, Link } from "react-router-dom";
import VirtualStagingProductItem from "./virtual_staging_product_item"
import VirtualStagingGalleryModal from "./virtual_staging_gallery_modal";


export default class VirtualStagingProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsStream: 12,
    }
    this.toggleSelected = this.toggleSelected.bind(this);
    this.handler = this.handler.bind(this);
    this.streamMoreProducts = this.streamMoreProducts.bind(this)
  }

  componentDidMount(){
    if (this.props.category_id){
       this.props.fetchVirtualStagingProducts(this.props.category_id).then(products => {
        if (!this.props.product){
          if (products.products.length === 1){
            this.props.changeParentState({ product: products.products[0], page: this.props.page + 1})
          } else {
            this.props.changeParentState({ product: products.products[0] })
          }
        }
      })
    } else {
      this.props.resetVirtualStagingProducts();
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  streamMoreProducts() {
    this.setState({ productsStream: this.state.productsStream + 12 })
  }

  toggleSelected(product) {
    if (this.props.selected != product) {
      this.props.changeParentState({ product: product })
    } else {
      this.props.changeParentState({ product: null })
    }
  }

  render() {
    let streamButton = ""

    if (this.state.productsStream < this.props.products.length) {
      streamButton = <div onClick={this.streamMoreProducts} className="streamButton">
        <i className="fas fa-chevron-down" />
      </div>
    }

    return (
      <div>
        <div className="modalTitle">Choose Style</div>
        <div className="modalSubTitle">This is the style of furniture that will be added to the room.</div>
        <div className="virtualStagingPhotosGrid mt-3">
          {this.props.products.length === 0 && <div className="font-weight-bold">
            You must first select a Category in order to choose products.
          </div>}

          {this.props.products.length > 0 && <>
            {this.props.products.slice(0, this.state.productsStream).map((product, i) => {
              return <VirtualStagingProductItem key={i} changeParentState={this.props.changeParentState}
                                                product={product}
                                                selected={this.props.selected?.id === product.id}
                                                toggleSelected={this.toggleSelected} index={i}/>
            })}
          </>
          }
        {streamButton}
        </div>
      </div>
    );
  }
}