import React from 'react';
import { Container } from 'reactstrap';

import { SectionSlideItemWrapperEven, OddSectionEven } from './commonStyles';
import {
  ContractMediaSlide2Part1,
  ContractMediaSlide2Part2,
  ContractMediaSlide2Part3,
  ContractMediaSlide2Part4,
} from './slideTwoStyles';
import { CONTRACT } from '../../../../../../assets/images/landing';

const SlideTwo = (props) => {
  const { item, activeIndex, handleDragStart } = props;

  return (
    <SectionSlideItemWrapperEven onDragStart={handleDragStart}>
      <OddSectionEven
        src={CONTRACT.ContractBackground}
        className={`${
          item.id == activeIndex ? 'fadeInZoomInRightAnimation' : ''
        }`}
      />
      <Container>
        <ContractMediaSlide2Part1
          className={`${
            item.id === activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide2Part1}
        />
        <ContractMediaSlide2Part2
          className={`${
            item.id === activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide2Part2}
        />
        <ContractMediaSlide2Part3
          className={`${
            item.id === activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide2Part3}
        />
        <ContractMediaSlide2Part4
          className={`${
            item.id === activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide2Part4}
        />
      </Container>
    </SectionSlideItemWrapperEven>
  );
};

export default SlideTwo;
