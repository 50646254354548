import { connect } from "react-redux";
import SettingsPayment from "./settings_payment";
import React from "react";
import { withRouter } from "react-router-dom";
import { updatePayment, createPayment, fetchPaymentInfo } from "../../../actions/settings_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  updatePayment: (paymentInfo, paymentId) => dispatch(updatePayment(paymentInfo, paymentId)),
  createPayment: (paymentInfo) => dispatch(createPayment(paymentInfo)),
  fetchPaymentInfo: (user_id) => dispatch(fetchPaymentInfo(user_id)),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsPayment));