import { connect } from "react-redux";
import React from "react";
import { openModal, closeModal } from "../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { fetchServices } from "../actions/service_actions";
import PhotographerShow from "./photographer_show";
import { closeProjectModal, openProjectModal } from "../actions/landing_page_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  fetchServices: () => { dispatch(fetchServices()) },
  openNewModal: () => { dispatch(openProjectModal());},
  closeModal: () => {dispatch(closeProjectModal());},
});

export default withRouter(connect(msp, mdp)(PhotographerShow));