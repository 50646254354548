import React from "react";
import { Route, Link } from "react-router-dom";
import SettingsFirmsItem from "./settings_firms_item";


export default class SettingsFirms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firms: []
    }

    this.updateFirm = this.updateFirm.bind(this)
    this.createFirm = this.createFirm.bind(this)
    this.deleteFirm = this.deleteFirm.bind(this)
  }

  componentDidMount() {
    this.props.fetchFirms().then(firms => this.setState({firms: firms}))
  }

  updateFirm(firm) {
    this.props.openModal("updateFirm", { firm, onFirmUpdate: this.onFirmUpdate.bind(this) })
  }

  createFirm() {
    this.props.openModal("createFirm", { onFirmCreate: this.onFirmCreate.bind(this) })
  }

  onFirmUpdate(updatedFirm){
    this.setState({ firms: this.state.firms.map(firm => firm.id === updatedFirm.id ? updatedFirm : firm) })
    this.props.showToast("Firm has been updated")
  }

  onFirmCreate(firm){
    this.setState({ firms: [...this.state.firms, firm] })
    this.props.showToast("Firm has been created")
  }

  deleteFirm(firm) {
    this.props.deleteFirm(firm.id).then(() => {
      this.setState({ firms: this.state.firms?.filter(f => f.id !== firm.id) || [] })
      this.props.showToast("Firm has been removed")
    })
  }

  render() {
    let settingsFirmsItems = this.state.firms.map((firm, i) => <SettingsFirmsItem key={i} firm={firm} deleteFirm={this.deleteFirm} updateFirm={this.updateFirm} addInfo={this.props.addInfo} />)

    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Firms</div>
          <div className="settingsPreferencesGroup1">
            {settingsFirmsItems}
          </div>
          <button onClick={this.createFirm} className="settingsButton preferencesButton">Create Firm</button>
        </div>
      </div>
    );
  }
}