import React from 'react';

import {Image1, Image2, Image3, Image4, Image5, TopSectionInnerWrapper, TopSectionWrapper} from './styles';
import {TOP_SECTION} from "../../../../../assets/images/careersPage";

const CareersTopSection = () => {
  return (
    <TopSectionWrapper>
      <TopSectionInnerWrapper>
        <Image1>
          <img src={TOP_SECTION.TopSectionImage1}/>
        </Image1>
        <Image2>
          <img src={TOP_SECTION.TopSectionImage2}/>
        </Image2>
        <Image3>
          <img src={TOP_SECTION.TopSectionImage3}/>
        </Image3>
        <Image4>
          <img src={TOP_SECTION.TopSectionImage4}/>
        </Image4>
        <Image5>
          <img src={TOP_SECTION.TopSectionImage5}/>
        </Image5>
      </TopSectionInnerWrapper>
    </TopSectionWrapper>
  );
};

export default CareersTopSection;
