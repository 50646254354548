import React from "react"
import debounce from 'debounce'
import { withRouter } from "react-router-dom";
import ServicesSelectionComponent from "../create_project_2/services_selection_component_2"
import { fetchLocationSubServices, fetchServices } from "../../util/service_api_util"
import { fetchUserPromos } from "../../util/settings_api_util"
import ChangeServiceOrderSummaryComponent from "./change_service_order_summary_component"
import ProjectUpdatedModal from "../update_project/project_updated_modal"
import ChangeServiceCheckoutModal from "./change_service_checkout_modal_container";
import { deleteProject, updateProject } from "../../util/project_api_util"
import { fetchBundles } from "../../util/bundle_api_utils";

class ChangeBundleModal extends React.Component {
  constructor(props) {
    super(props)
    const { project } = props

    this.state = {
      page: 1,
      selectedContractor: null,
      summaryErrors: [],
      errors: [],
      selected_bundle: {},
      selected_service: -1,
      zipcode: '',
      street: '',
      cancelledPackages: [],
      selectedSubservices: [],
      previousSubServices: [],
      services: project.services,
      package_attributes: [],
      appointments: [],
      contractorSearch: '',
      contractorDateSearch: "",
      contractorTimeSearch: "",
      account_credit: project.client.account_credit?.amount,
      allBundles: [],
      bundleGroups: [],
      firm: {},
      previouslyChosen: {},
      subModal: null,
      cancelModal: false,
      chooseTimeModalOpen: false,
      loading: false,
      waiting: false,
      new_project: {},
      time_slots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} },
      location_pricing: {}
    }

    this.handler = this.handler.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.setAppointmentTime = this.setAppointmentTime.bind(this)
    this.previewOrder = this.previewOrder.bind(this)
    this.cancelProject = this.cancelProject.bind(this)
    this.createRefund = this.createRefund.bind(this)
    this.createAccountCredit = this.createAccountCredit.bind(this)
    this.deleteSubService = this.deleteSubService.bind(this)
    this.onContractorSelect = this.onContractorSelect.bind(this)
    this.update = this.update.bind(this)
    this.debounceFetchContractors = debounce((service, zip, search) => this.props.fetchContractors(service, zip, search), 500)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    const { project } = this.props
    this.setState({ loading: true })
    fetchBundles().then(bundles => this.setState({ allBundles: bundles, loading: false }))
    fetchBundleGroups().then(bundle_groups => {
      this.setState({ bundle_groups })
    })

    if (project.county != "") {
      fetchLocationSubServices(project.county).then((location_pricing => this.setState({ location_pricing: location_pricing })))
    }
    

    let address = project.address
    let total = 0
    let package_attributes = {}
    let selectedSubservices = {}
    let previousSubServices = {}
    let previous_packages = {}
    let cancelledPackages = {}
    let appointments = {}
    let main_service = {}
    let selected_bundle = -1

    Object.values(project.packages).forEach(current_package => {
      previous_packages[current_package.service_id] = {
        service_id: current_package.service_id,
        id: current_package.id,
        package_sub_services: current_package.package_sub_services
      }
      package_attributes[current_package.service_id] = {}
      package_attributes[current_package.service_id]["package_sub_services_attributes"] = {}
      if (current_package.contractor) {
        appointments[current_package.service_id] = {
          service_id: current_package.service_id,
          contractor: current_package.contractor,
          appointment_time: new Date(current_package.appointment_time)
        }
      }
      package_attributes[current_package.service_id]["appointment_time"] = new Date(current_package.appointment_time)
      package_attributes[current_package.service_id]["contractor_id"] = current_package.contractor_id
      if (!current_package.contractor_id) {
        package_attributes[current_package.service_id]["appointment_time"] = null
        cancelledPackages[current_package.service_id] = true
      }
      package_attributes[current_package.service_id]["service_id"] = current_package.service_id
      package_attributes[current_package.service_id]["id"] = current_package.id

      if (selected_service === -1) {
        selected_service = current_package.service_id
      }

      if (current_package.contractor_id && !main_service[current_package.contractor_id]) {
        main_service[current_package.contractor_id] = current_package.service_id
      }

      if (current_package.service_id === this.props.active_service) {
        current_package.sub_services.forEach(sub_service => {
          total += parseFloat(project.invoice_sub_services[sub_service.id].price)
          selectedSubservices[sub_service.id] = sub_service
          previousSubServices[sub_service.id] = sub_service
        })
      }

      current_package.package_sub_services.forEach(package_sub_service => {
        package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id] = {
          sub_service_id: package_sub_service.sub_service_id,
          package_id: current_package.id,
          id: package_sub_service.id
        }
      })
    })

    let previous_total = project.promo_price || total

    if (project.promo_price) {
      previous_total += project.paid_for_amount
    }

    this.setState({
      previous_packages: previous_packages,
      client: project.client_id,
      client_role: project.client.role.name,
      street: address.street1,
      firm: project.client.firm,
      apartment: address.apartment_number,
      zipcode: address.zip_code,
      state: address.region,
      city: address.city,
      formatted_address: address.formatted_address,
      package_attributes: package_attributes,
      selectedSubservices: selectedSubservices,
      previousSubServices: previousSubServices,
      cancelledPackages: cancelledPackages,
      appointments: appointments,
      main_service: main_service,
      previous_appointments: appointments,
      previous_total: previous_total,
      selected_bundle: project.discount_bundle,
      selected_service: project.discount_bundle.sub_services[0].service_id
    })

    if ((project.client?.firm?.broker_charge && this.state.account_credit) || this.props.currentUser.roles.broker) {
      this.setState({ account_credit: null })
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.state

    if (prevState.contractorSearch !== this.state.contractorSearch) {
      this.debounceFetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch)
    }

    if ((prevState.contractorDateSearch !== this.state.contractorDateSearch || prevState.contractorTimeSearch !== this.state.contractorTimeSearch)) {
      this.setState({ waiting: true })
      this.props.fetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch).then(() => { this.setState({ waiting: false }) })
    }
  }

  renderConfirmationModal() {
    const handler = (params) => {
      this.setState({ subModal: null })
      this.handler(params)
    }

    const subModal = <AddMoreServicesModal changeParentState={handler} />
    this.setState({ subModal })
  }

  cancelProject() {
    this.setState({ cancelModal: true })
  }

  createRefund() {
    if (this.state.cancelModal) {
      deleteProject(this.props.project.id, "Refund").then(() => {
        this.props.history.push("/projects_index")
      })
    } else {
      this.update(true)
    }
  }

  createAccountCredit() {
    if (this.state.cancelModal) {
      deleteProject(this.props.project.id, "Coupon").then(() => {
        this.props.history.push("/projects_index")
        this.props.fetchCurrentUser(this.props.currentUser.id)
      })
    } else {
      this.update()
    }
  }

  deleteSubService(sub_service) {
    let newState = Object.assign({}, this.state.package_attributes)
    let newStateSubservices = Object.assign({}, this.state.selectedSubservices)
    let newStateAppointments = Object.assign({}, this.state.appointments)

    delete newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id]
    delete newStateSubservices[sub_service.id]

    if (Object.values(newState[sub_service.service_id]["package_sub_services_attributes"]).length === 0) {
      delete newStateAppointments[sub_service.service_id]
      delete newState[sub_service.service_id]
    }

    this.setState({
      package_attributes: newState,
      selectedSubservices: newStateSubservices,
      appointments: newStateAppointments
    })
  }

  setAppointmentTime(date, contractor) {
    let newState = this.state.package_attributes
    let newAppointmentState = this.state.appointments
    let newMainService = this.state.main_service
    let main = true

    Object.values(this.state.appointments).forEach(appointment => {
      if (appointment.contractor === contractor && appointment.appointment_time.toString() === date.toString()) {
        main = false
      }
    })

    if (main === true && !this.state.allServices[this.state.selected_service].twilight) {
      newMainService[contractor.id] = this.state.selected_service
    }

    newAppointmentState[this.state.selected_service] = {
      service_id: this.state.selected_service,
      contractor: contractor,
      appointment_time: date
    }

    newState[this.state.selected_service]["appointment_time"] = date
    newState[this.state.selected_service]["contractor_id"] = contractor.id
    newState[this.state.selected_service]["service_id"] = this.state.selected_service

    this.setState({
      package_attributes: newState,
      appointments: newAppointmentState,
      main_service: newMainService,
      summaryErrors: []
    })
  }

  handleNext(e) {
    e.preventDefault()

    this.setState({ errors: [], subModal: null })

    let errors = []

    if (this.state.page === 2) {
      if (this.state.selected_bundle === -1 || Object.values(this.state.package_attributes).length === 0) {
        errors.push("Please select a bundle for your project before continuing")
      } else {

      }
    }


    if (this.state.page === 3) {
      let discount = 0;
      // let package_attributes_without_reshoot = Object.values(this.state.package_attributes).filter(pack => !this.state.allServices[pack.service_id].reshoot)
      let package_attributes = Object.values(this.state.package_attributes)

      if (this.state.promos.length > 0) {
        this.state.promos.forEach(promo => {
          if (this.state.package_attributes[promo.service.id]) {
            if (promo.services.length > 0) {
              promo.services.forEach(service => {
                if (this.state.package_attributes[service.id] && package_attributes.length === 2) {
                  promoPrice = parseInt(promo.price)
                  promoName = promo.name
                }
              })
            } else {
              if (package_attributes.length === 1) {
                promoPrice = parseInt(promo.price)
                promoName = promo.name
              }
            }
          }
        })
      }

      this.previewOrder(discount, promoPrice, promoName)
      return
    }

    if (errors.length > 0) {
      this.setState({ summaryErrors: errors })
    } else {
      this.setState({ page: this.state.page + 1, errors: [], summaryErrors: [] })
    }
  }

  handlePrevious(e) {
    e.preventDefault()

    this.setState({ errors: [], subModal: null })

    this.setState({ page: this.state.page - 1, summaryErrors: [] })
  }

  update(refund = false) {
    let data = {}
    let address_attributes = {}
    let package_attributes = Object.assign({}, this.state.package_attributes)
    let price = 0;
    let discount = 0;
    let account_credit = this.state.account_credit;

    Object.values(this.state.selectedSubservices).map(subservice => {
      price += parseFloat(subservice.price)
    })

    price -= this.state.previous_total

    if (this.state.promo_price) {
      price = this.state.promo_price
    }

    if (this.state.account_credit && price > 0) {
      if (this.state.account_credit >= price) {
        account_credit = this.state.account_credit - price
        price = 0
        discount = this.state.account_credit - account_credit
      } else {
        price = price - this.state.account_credit
        discount = this.state.account_credit
        account_credit = 0
      }
    }

    Object.values(this.state.previous_packages).forEach((current_package => {
      if (!package_attributes[current_package.service_id]) {
        package_attributes[current_package.service_id] = { id: current_package.id, _destroy: true }
      } else {
        if (!package_attributes[current_package.service_id]["id"]) {
          package_attributes[current_package.service_id]["id"] = current_package.id
        }
        current_package.package_sub_services.forEach((package_sub_service) => {
          if (!package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id]) {
            package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id] = {
              id: package_sub_service.id,
              _destroy: true
            }
          } else {
            package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id]["id"] = package_sub_service.id
          }
        })
      }
    }))

    address_attributes = {
      id: this.props.project.address.id,
      apartment_number: this.state.apartment,
      city: this.state.city,
      region: this.state.state,
      street1: this.state.street,
      zip_code: this.state.zipcode,
      formatted_address: this.state.formatted_address
    }

    if (discount > 0 && this.state.account_credit) {
      let coupon_info = { coupon_id: this.props.project?.client?.account_credit?.id, coupon_amount: parseInt(this.state.account_credit) - discount, coupon_discount_amount: discount }
      data = {
        coupon_info: coupon_info,
        refund: refund,
        discount: this.state.discount.toFixed(2),
        client_id: this.state.client,
        promo_price: this.state.promo_price,
        broker_booked: this.props.currentUser.roles.broker ? true : false,
        address_attributes: address_attributes,
        packages_attributes: Object.values(package_attributes),
        removed_bundle: true,
        add_bundle: true,
        new_bundle_id: this.state.selected_bundle,
        new_bundle_price: bundle_price
      }
    } else {
      data = {
        refund: refund,
        discount: this.state.discount.toFixed(2),
        client_id: this.state.client,
        promo_price: this.state.promo_price,
        broker_booked: this.props.currentUser.roles.broker ? true : false,
        address_attributes: address_attributes,
        packages_attributes: Object.values(package_attributes),
        removed_bundle: true,
        add_bundle: true,
        new_bundle_id: this.state.selected_bundle, 
        new_bundle_price: bundle_price
      }
    }

    this.setState({ errors: [] })

    updateProject(data, this.props.project.id).then(project => {
      if (project.errors) {
        this.setState({ summaryErrors: project.errors })
      } else {
        this.setState({ page: 4, subModal: null, new_project: Object.values(project)[0] })
        if (discount > 0 && this.state.account_credit) {
          this.props.fetchCurrentUser(this.props.currentUser.id)
        }
      }
    })
  }

  previewOrder(discount, promo_price, promo_name) {
    const subModal = <ChangeServiceCheckoutModal changeParentState={this.handler}
      location_pricing={this.state.location_pricing}
      promo_price={promo_price}
      project={this.props.project}
      client_role={this.state.client_role}
      promo_name={promo_name}
      previous_services={this.state.services}
      account_credit={this.state.account_credit}
      client={this.state.client}
      cancelModal={this.state.cancelModal}
      firm={this.state.firm}
      createRefund={this.createRefund}
      createAccountCredit={this.createAccountCredit}
      previous_total={this.state.previous_total}
      street={this.state.street}
      city={this.state.city}
      state={this.state.state}
      formatted_address={this.state.formatted_address}
      apartment={this.state.apartment}
      zipcode={this.state.zipcode}
      discount={discount}
      subservices={this.state.selectedSubservices}
      previous_sub_services={this.state.previousSubServices}
      appointments={this.state.appointments}
      updateProject={this.update} />
    this.setState({ promo_price: promo_price, promo_name: promo_name, discount: discount, subModal })
  }

  setAppointmentTime(date, contractor) {
    let newState = this.state.package_attributes
    let newAppointmentState = this.state.appointments
    let newMainService = this.state.main_service
    let main = true

    Object.values(this.state.appointments).forEach(appointment => {
      if (appointment.contractor === contractor && appointment.appointment_time.toString() === date.toString()) {
        main = false
      }
    })

    if (main === true && !this.state.allServices[this.state.selected_service].twilight) {
      newMainService[contractor.id] = this.state.selected_service
    }

    newAppointmentState[this.state.selected_service] = {
      service_id: this.state.selected_service,
      contractor: contractor,
      appointment_time: date
    }

    newState[this.state.selected_service]["appointment_time"] = date
    newState[this.state.selected_service]["contractor_id"] = contractor.id
    newState[this.state.selected_service]["service_id"] = this.state.selected_service

    this.setState({
      package_attributes: newState,
      appointments: newAppointmentState,
      main_service: newMainService,
      summaryErrors: []
    })
  }

  handler(newState) {
    const currentState = { ...this.state, ...newState }

    if (currentState.zipcode.length === 5 && currentState.selected_service !== -1 && newState.selected_service) {
      this.setState({ waiting: true })
      this.props.fetchContractors(currentState.selected_service, currentState.zipcode, currentState.contractorSearch).then(contractors => {
        this.setState({ waiting: false })
      })
    }
    this.setState(newState)
  }

  onContractorSelect(contractorId, photographer) {
    if (contractorId) {
      const subModal = <ContractorCalendar photographer={photographer}
        services={this.state.allServices}
        setAppointmentTime={this.setAppointmentTime}
        appointments={this.state.appointments}
        street={this.state.street}
        selectedService={this.state.selected_service}
        changeSelected={this.onContractorSelect}
        project={this.props.project}
        isLargeScreen={this.props.isLargeScreen} />
      this.setState({ selectedContractor: photographer, subModal })
    } else {
      this.setState({ selectedContractor: null, subModal: null })
    }
  }

  content() {
    const { page } = this.state
    const { project } = this.props

    if (page === 1) {
      return <ServicesSelectionComponent changeParentState={this.handler}
        bundleSelected={true}
        summaryErrors={this.state.summaryErrors}
        zipcode={this.state.zipcode}
        cancelledPackages={this.state.cancelledPackages}
        selected_bundle={this.state.selected_bundle}
        package_attributes={this.state.package_attributes}
        appointments={this.state.appointments}
        bundle_groups={this.state.bundle_groups}
        loading={this.state.loading}
        updateBundle={true}/>
    }

    if (page === 2){
      return <div></div>
    }

    if (page === 3) {
      return <>
        <ChangeServiceOrderSummaryComponent changeParentState={this.handler} checkout={true}
         location_pricing={this.state.location_pricing}
          page={this.state.page}
          services={this.state.allServices}
          previous_total={this.state.previous_total}
          errors={this.state.summaryErrors}
          promos={this.state.promos}
          package_attributes={this.state.package_attributes}
          deleteSubService={this.deleteSubService}
          account_credit={this.state.account_credit}
          subservices={this.state.selectedSubservices}
          previous_sub_services={this.state.previousSubServices}
          appointments={this.state.appointments}
          previous_services={this.state.services}
          previewOrder={this.previewOrder}
          currentUser={this.props.currentUser}
          cancelProject={this.cancelProject}
          street={this.state.street}
          city={this.state.city}
          formatted_address={this.state.formatted_address}
          state={this.state.state}
          apartment={this.state.apartment}
          zipcode={this.state.zipcode} /></>
    }

    if (page === 3) {
      return <ProjectUpdatedModal project={project} new_project={this.state.new_project} changeParentState={this.handler} />
    }
  }

  progress() {
    const { page } = this.state
    const step = page - 1

    return [1, 2].map(i => {
      return <div className={`modalFooterProgressItem ${step >= i ? 'completed' : ''}`} key={`step-${i}`}>
        {i}
      </div>
    })
  }

  isNextButtonDisabled() {
    if (this.state.page === 3) {
      if (Object.values(this.state.selectedSubservices).length === 0) return true
    }
    return false
  }

  onClose() {
    if (this.state.page === 4) {
      location.reload()
    } else {
      this.props.onClose()
    }
  }

  render() {
    const { page, subModal, chooseTimeModalOpen } = this.state

    if (subModal) {
      return subModal
    }

    let previous_services = {}

    this.state.services.forEach(service => previous_services[service.id] = true)

    let more_services_added = Object.values(this.state.appointments).filter(appointment => !previous_services[appointment.service_id]).length > 0

    return <div className="modal-background">
      <div className={`orderpreviewModal addServiceModal`}>
        <div className="orderPreviewModalComponent">
          <div className="addServiceModalContent" id={this.state.page === 4 ? "updateConfirmationModal" : ""}>
            {this.state.page === 4 ? "" : <button className="projectEditButton projectEditButtonLight modalCloseButton modalCloseButtonHomepage" onClick={() => this.onClose()}>
              Close
            </button>}
            {this.state.page === 4 ? "" : <div className="closeIcon modalCloseButtonHomepageMobile" onClick={() => this.onClose()}><i className="fas fa-times" /></div>}

            {this.content()}
          </div>

          {page != 4 && <div className="modalFooter">
            <div className="modalFooterProgress">
              {this.progress()}
            </div>
            <div className="modalFooterButtons">
              <button className={`projectEditButton projectEditButtonPrimary ${page === 2 && !more_services_added || (page === 2 && more_services_added && (Object.values(this.state.appointments).length - Object.values(this.state.previous_appointments).length) != (Object.values(this.state.package_attributes).length - Object.values(this.state.previous_packages).length)) ? 'grayedoutButton' : ''}`}
                onClick={page === 2 && !more_services_added || (page === 2 && more_services_added && (Object.values(this.state.appointments).length - Object.values(this.state.previous_appointments).length) != (Object.values(this.state.package_attributes).length - Object.values(this.state.previous_packages).length)) ? "" : this.handlePrevious}>
                {page === 2 && more_services_added ? "Order Summary" : "Previous"}
              </button>
              <button className="projectEditButton projectEditButtonPrimary" onClick={this.handleNext} disabled={this.isNextButtonDisabled()}>
                {page === 3 ? 'Checkout' : 'Next Step'}
              </button>
            </div>
          </div>}
        </div>
        <div className="modalMobilePushup"></div>
      </div>
    </div>
  }
}

export default ChangeBundleModal;