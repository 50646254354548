import React from "react";
import debounce from 'debounce'
import { Route, Link, withRouter } from "react-router-dom";
import LocationComponent from "../location_component";
import CategorySelectionComponent from "../create_virtual_suite/category_selection_component";
import ProductSelectionComponent from "../create_virtual_suite/product_selection_component";
import VirtualSuiteSelectionComponent from "../create_virtual_suite/virtual_suite_selection_component";
import PhotoComponent from "../create_virtual_suite/photo_component";
import SignupAndPaymentModalContainer from "../create_project_home_page/signup_and_payment_modal_container";
import { fetchUserPromos } from "../../util/settings_api_util";
import AddMorePhotosModal from "../create_virtual_suite/add_more_photos_modal";
import CreatePurchaseModal from "../create_virtual_suite/create_purchase_modal_container";
import OrderCreatedModal from "../create_virtual_suite/order_created_modal";
import NotesComponent from "../create_virtual_suite/notes_component";
import smallMobileLogo from "../../../../assets/images/smallMobileLogo";
import CheckoutComponent from "../create_virtual_suite/checkout_component";
import { uploadPhotos } from "../../actions/project_actions";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import VirtualStagingGalleryModal from "../virtual_staging/virtual_staging_gallery_modal";
import { detectTimezone } from "../../util/timezone_utils"

class CreateVirtualSuiteHomePageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      street: "",
      apartment: "",
      zipcode: "",
      state: "",
      city: "",
      latitude: "",
      longitude: "",
      formatted_address: "",
      detected_timezone: "",
      note: "",
      client_id: "",
      project_id: "",
      project_address: "",
      project_address_full: {},
      category: null,
      virtual_suite_type: null,
      product: null,
      photos: [],
      photos_src: [],
      selectedPhotos: {},
      selectedPhotosWithInfo: {},
      upload: true,
      client_role: "",
      project_client_id: "",
      specialNote: "",
      errors: [],
      backendErrors: [],
      orders: [],
      detailed_orders: [],
      modalOpen: false,
      galleryModalOpen: false,
      account_credit: null,
      account_credit_id: "",
      productModalOpen: false,
      currentPhoto: 0,
      currentProduct: 0,
      discount: 0,
      goingBackToAddress: false,
      account_credit_id: "",
      user: {},
      firm: {},
      project: {},
      errors: [],
      propertyErrors: [],
      summaryErrors: [],
      client: "",
      products: [],
      page: 1
    }

    this.handler = this.handler.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
    this.addMoreServices = this.addMoreServices.bind(this)
    this.deleteOrderPhoto = this.deleteOrderPhoto.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentDidUpdate(_, prevState){
    if (prevState.latitude !== this.state.latitude)
    detectTimezone(this.state.latitude, this.state.longitude).then(detected_timezone => { 
      this.setState({detected_timezone: detected_timezone})
    })
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  addMoreServices() {
    this.addToCart()

    this.setState({
      page: 1
    })
  }

  deleteOrderPhoto(orderIndex, photoIndex) {
    if (!window.confirm('Are you sure you want to remove this photo?')) return
    let orders = this.state.orders
    let detailed_orders = this.state.detailed_orders

    const order = orders[orderIndex]
    const detailed_order = detailed_orders[orderIndex]

    if (order.virtual_staging_photos_attributes.length === 1) {
      orders.splice(orderIndex, 1)
      detailed_orders.splice(orderIndex, 1)
    } else {
      const [photo] = order.virtual_staging_photos_attributes.splice(photoIndex, 1)
      delete detailed_order.selected_photos[photo.media_id || photo.name]
    }

    this.setState({ orders: orders, detailed_orders: detailed_orders })
  }

  addToCart() {
    if (!this.state.product) return

    let orders = this.state.orders
    let detailed_orders = this.state.detailed_orders
    let virtual_staging_details = {
      project_id: this.state.project_id,
      virtual_staging_photos_attributes: Object.values(this.state.selectedPhotos),
      virtual_staging_product_id: this.state.product?.id,
      notes: this.state.specialNote
    }
    let order_details = {
      selected_photos: this.state.selectedPhotosWithInfo,
      product: this.state.product,
      category: this.state.category,
      note: this.state.specialNote
    }

    orders.push(virtual_staging_details)
    detailed_orders.push(order_details)

    this.setState({
      detailed_orders: detailed_orders,
      orders: orders,
      category: null,
      selectedPhotos: {},
      selectedPhotosWithInfo: {},
      product: null,
      specialNote: "",
    })
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  handleNext(e) {
    e.preventDefault()

    this.setState({ errors: [] })

    let errors = []
    if (this.state.page === 1) {
      if (this.state.virtual_suite_type === null) {
        errors.push("Please select a virtual staging service before continuing.")
      }
    }
    if (this.state.page === 2) {
      if (this.state.category === null) {
        errors.push("Please select a virtual staging category before continuing.")
      }
    }

    if (this.state.page === 3) {
      if (Object.values(this.state.selectedPhotos).length === 0) {
        errors.push("Please select at least one photo before continuing.")
      }

      if (this.state.formatted_address === "" && this.state.upload) {
        errors.push("Please insert an address before continuing.")
      }
    }

    if (errors.length > 0) {
      this.props.showToast(errors)
    }
    else {
      if (this.state.page === 1 && this.state.goingBackToAddress) {
        this.setState({ page: 5, errors: [], summaryErrors: [], propertyErrors: [] })
      } else {
        if (this.state.page === 6) {
          this.addToCart()
        }
        this.setState({ page: this.state.page + 1, errors: [], summaryErrors: [], propertyErrors: [] })
        this.debouceScrollTo('#scrollToHere')
      }
    }
  }

  handlePrevious(e) {
    e.preventDefault()
    if (this.state.page === 1) {
      this.props.openStartModal()
    } else {
    this.setState({ page: this.state.page - 1, errors: [], summaryErrors: [], propertyErrors: [] })
    this.debouceScrollTo('#scrollToHere')
    }
  }

  handleSubmit() {
    let orders = this.state.orders
    let coupon_info = null;
    let discount = 0;
    let account_credit = this.state.account_credit
    let price = 0;

    price = this.state.detailed_orders.reduce((res, order) => {
      return res + Object.values(order.selected_photos).length * order.category.price
    }, 0)


    let data = {}
    let address_attributes = {}

    if (this.state.formatted_address) {
      address_attributes = { formatted_address: this.state.formatted_address, county: this.state.county, apartment_number: this.state.apartment, city: this.state.city, region: this.state.state, street1: this.state.street, zip_code: this.state.zipcode, longitude: this.state.longitude, latitude: this.state.latitude }
    } else {
      address_attributes = { formatted_address: this.state.project_address_full.formatted_address, county: this.state.county, apartment_number: this.state.project_address_full.apartment, city: this.state.project_address_full.city, region: this.state.project_address_full.region, street1: this.state.project_address_full.street1, zip_code: this.state.project_address_full.zip_code, longitude: this.state.project_address_full.longitude, latitude: this.state.project_address_full.latitude }
    }
    data = { client_id: this.state.user.id, address_attributes: address_attributes, packages_attributes: [], broker_booked: false }

    this.props.submitOrder(data).then(({ project }) => {
      if (project.errors) {
        this.props.removeCurrentUser()
        destroyUser(this.state.user.id, project.errors)
        this.setState({ backendErrors: project.errors })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        let project_id = Object.values(project)[0].id
        let ordersWithProject = this.state.orders.map(order => { order["project_id"] = project_id; return order })
        let order_batches = { coupon_info: coupon_info, project_id: project_id, virtual_staging_purchases_attributes: ordersWithProject, broker_booked: false }
        this.setState({page: 9})
        this.props.createVirtualStagingPurchase(order_batches).then((batch) => {
          if (this.props.errors.length === 0) {
            this.setState({ page: 8, project_id: project_id, modalOpen: false })
          } else {
            this.props.removeCurrentUser()
            destroyUser(this.state.user.id, project.errors)
            this.setState({ backendErrors: project.errors })
          }
        })
      }
    })
  }

  handler(newState) {
    this.setState(newState)
  }

  modal() {
    if (this.state.modalOpen) {
      let price = this.state.detailed_orders.reduce((res, order) => {
        return res + Object.values(order.selected_photos).length * order.category.price
      }, 0)

      if (this.state.account_credit && price > 0) {
        if (this.state.account_credit >= price) {
          price = 0
        } else {
          price = price - this.state.account_credit
        }
      }

      // return <CreatePurchaseModal upload={this.state.upload} client_role={this.state.client_role} client_id={this.state.client_id} submitOrder={this.handleSubmit} project_id={this.props.project_id}
      //   changeParentState={this.handler} total={price} />

      return <SignupAndPaymentModalContainer changeParentState={this.handler} backendErrors={this.state.backendErrors} detected_timezone={this.state.detected_timezone} price={price} submitOrder={this.handleSubmit} />
    }
  }

  content() {
    const { page } = this.state
    const { project } = this.props

    if (page === 1) {
      return <VirtualSuiteSelectionComponent homepage={true} changeParentState={this.handler} virtual_suite_type={this.state.virtual_suite_type} page={this.state.page} />
    }

    if (page === 2) {
      return <CategorySelectionComponent homepage={true} changeParentState={this.handler} page={this.state.page} virtual_suite_type={this.state.virtual_suite_type} selected={this.state.category} summaryErrors={this.state.summaryErrors} />
    }

    if (page === 3) {
      return <PhotoComponent homepage={true} upload={this.state.upload} deleteOrderPhoto={this.deleteOrderPhoto} project_client_id={this.state.project_client_id} project_address={this.state.project_address} project_id={this.state.project_id} detailed_orders={this.state.detailed_orders} showToast={this.props.showToast} currentUser={this.props.currentUser} changeParentState={this.handler} page={this.state.page} waiting={this.state.waiting} photos_src={this.state.photos_src} summaryErrors={this.state.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.state.selectedPhotos} photos={this.state.photos} selectedPhotosWithInfo={this.state.selectedPhotosWithInfo} category={this.state.category} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
    }

    if (page === 4) {
      return <ProductSelectionComponent homepage={true} changeParentState={this.handler} page={this.state.page} category_id={this.state.category?.id} product={this.state.product} />
    }

    if (page === 5) {
      return <NotesComponent changeParentState={this.handler} page={this.state.page} />
    }

    if (page === 6) {
      return <NotesComponent changeParentState={this.handler} page={this.state.page} />
    }

    if (page === 7){
      return <CheckoutComponent currentUser={this.props.currentUser} homepage={true} changeParentState={this.handler} upload={this.state.upload} category={this.state.category} product={this.state.product} selected_photos_with_info={this.state.selectedPhotosWithInfo} account_credit={this.state.account_credit} deleteOrderPhoto={this.deleteOrderPhoto} detailed_orders={this.state.detailed_orders} specialNote={this.state.specialNote} />
    }

    if (page === 8){
      return ""
    }

    if (page === 9){
      return ""
    }
  }

  progress() {
    const { page } = this.state
    const step = page

    return [1, 2, 3, 4, 5, 6].map(i => {
      return <div className={`modalFooterProgressItem ${step === i ? 'completed' : ''} ${step === 7 && i === 6 ? 'completed' : ''}`} key={`step-${i}`}>
        {i}
      </div>
    })
  }

  onClose() {
    this.props.onClose()
  }

  render() {

    let icon = ""

    // let icon = <div key="2" onClick={() => this.props.history.goBack()}><i className="fas fa-arrow-left mainIcon createProjectIcon"></i></div>

    // if (this.state.modalOpen && this.props.currentUser) {
    //   modal = <CreateOrderModal changeParentState={this.handler} promo_price={this.state.promo_price} firm={this.state.firm} promo_name={this.state.promo_name} sub_broker_client={this.state.sub_broker_client} client_role={this.state.client_role} street={this.state.street} city={this.state.city} state={this.state.state} formatted_address={this.state.formatted_address} apartment={this.state.apartment} zipcode={this.state.zipcode} discount={this.state.discount} account_credit={this.state.account_credit} subservices={this.state.selectedSubservices} isLargeScreen={this.props.isLargeScreen} appointments={this.state.appointments} submitOrder={this.submitOrder} />
    // }

    let buttons = "";
    let button2 = "";

    let modal = this.modal()

    if (this.state.page === 1) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        {Object.values(this.state.orders).length > 0 ? <div className="signupFormPrevious orderSummaryButton" onClick={() => this.setState({ page: 7, summaryErrors: [] })}>Order Summary</div> : ""}
        {/* <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div> */}
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 2) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 3) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    }
    else if (this.state.page === 4) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 5) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 6) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
      modal = <AddMorePhotosModal onAddMoreServices={this.addMoreServices} handleNext={this.handleNext} changeParentState={this.handler} />
    } else if (this.state.page === 7) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={() => this.setState({ modalOpen: true })}>Pay Now</div>
      </div>
    }
    else if (this.state.page === 8) {
      modal = <OrderCreatedModal orders={this.state.detailed_orders} project_id={this.state.project_id} changeParentState={this.handler} />
    } else if (this.state.page === 9){
      modal = <div className="modal-background">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading"> Checkout</div>
            </div>
            <div className="orderPreviewModalCategory">
              <div className="settingsComponentHeading">Submitting Order...</div>
              <div className="createProjectPaymentModal">
                <div className="loaderDiv">
                  <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } 

    if (this.state.galleryModalOpen) {
      modal = <VirtualStagingGalleryModal photos={this.state.photos} changeParentState={this.handler}
        currentPhoto={this.state.currentPhoto} />
    } else if (this.state.productModalOpen) {
      modal = <VirtualStagingGalleryModal photos={this.state.products} changeParentState={this.handler}
        currentPhoto={this.state.currentProduct} />
    }

    if (modal) {
      return modal
    }

    return (
      <div className="modal-background">
        <div className={`orderpreviewModal addServiceModal`}>
          <div className="orderPreviewModalComponent">
            <div className="addServiceModalContent">
              {this.state.page === 6 ? "" : <button className="projectEditButton projectEditButtonLight modalCloseButton modalCloseButtonHomepage" onClick={() => this.onClose()}>
                Close
              </button>}
              {this.state.page === 6 ? "" : <div className="closeIcon modalCloseButtonHomepageMobile" onClick={() => this.onClose()}><i className="fas fa-times" /></div>}
              <div>
                <div id="scrollToHere"></div>
              </div>
              {this.content()}
            </div>

            {this.state.page !== 6 && <div className="modalFooter">
              <div className="modalFooterProgress">
                {this.progress()}
              </div>
              {buttons}
            </div>}
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(CreateVirtualSuiteHomePageModal);