import styled from 'styled-components';

export const MatterportTourWrapper = styled.div`
  margin-top: 50px;
  background-color: #1E2023;
  /* height: 876px; */
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const IFrameWrapper = styled.iframe`
  width: 100vw;
  height: 876px;
  @media screen and (max-width: 1080px) {
    height: 680px;
  }
  @media screen and (max-width: 767px) {
    height: 450px;
  }
`;