import FadeInAnimation from "../../home_page/homeComponents/FadeInAnimation";
import {ServiceLogo, ServiceLogoWrapper} from "../../home_page/homeStyle";
import React from "react";
import {useInView} from "react-intersection-observer";
import {LOGOS} from "../../../../../assets/images/locations";
import {LineBreak, ServiceContainer} from "./styles";

export const LocationsLogos = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return <div ref={ref}>
    <FadeInAnimation
      duration={0.4}
      delayOrder={0}
      yOffset={-20}
      inView={inView}>
      <LineBreak/>
      <ServiceContainer>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.RemaxLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.DouglasLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.SothebyLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.CompassLogo} alt=''/>
        </ServiceLogoWrapper>
        <ServiceLogoWrapper>
          <ServiceLogo src={LOGOS.ColdWellLogo} alt=''/>
        </ServiceLogoWrapper>
      </ServiceContainer>
    </FadeInAnimation>
  </div>
}