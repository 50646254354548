import React from "react";
import { fetchBundleGroups, fetchBundles, deleteBundle, deleteBundleGroup } from '../../../util/bundle_api_utils';


export default class Bundles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bundle_groups: [],
      bundles: []
    }
  }

  componentDidMount() {
    fetchBundleGroups().then(bundle_groups => {
      this.setState({ bundle_groups })
    })
    fetchBundles().then(bundles => {
      this.setState({ bundles: bundles })
    })
  }

  onCreateBundleGroup() {
    this.props.openModal("bundleGroupForm", { onSave: this.onCreatedBundleGroup.bind(this) })
  }

  onCreateBundle() {
    this.props.openModal("bundleForm", { onSave: this.onCreatedBundle.bind(this) })
  }

  onUpdateBundleGroup(bundle_group) {
    this.props.openModal("bundleGroupForm", { onSave: this.onUpdatedBundleGroup.bind(this), bundle_group })
  }

  onUpdateBundle(bundle) {
    this.props.openModal("bundleForm", { onSave: this.onUpdatedBundle.bind(this), bundle })
  }

  onDeleteBundleGroup(bundle_group) {
    if (window.confirm(`Are you sure you want to delete "${bundle_group.name}"?`)) {
      deleteBundleGroup(bundle_group.id).then(() => {
        this.setState({ bundle_groups: this.state.bundle_groups.filter(f => f.id !== bundle_group.id) })
        this.props.showToast("Bundle Group has been removed")
      }).catch(() => {
        alert('Could not delete the bundle group')
      })
    }
  }

  onDeleteBundle(bundle) {
    if (window.confirm(`Are you sure you want to delete "${bundle.title}"?`)) {
      deleteBundle(bundle.id).then(() => {
        this.setState({ bundles: this.state.bundles.filter(f => f.id !== bundle.id) })
        this.props.showToast("Bundle has been removed")
      }).catch(() => {
        alert('Could not delete the bundle')
      })
    }
  }

  onCreatedBundleGroup(bundle_group) {
    this.setState({ bundle_groups: [...this.state.bundle_groups, bundle_group] })
    this.props.showToast("Bundle group has been created")
  }

  onUpdatedBundleGroup(updatedBundleGroup) {
    this.setState({ bundle_groups: this.state.bundle_groups.map(bundle_group => bundle_group.id === updatedBundleGroup.id ? updatedBundleGroup : bundle_group) })
    this.props.showToast("Bundle group has been updated")
  }

  onCreatedBundle(bundle) {
    this.setState({ bundles: [...this.state.bundles, bundle] })
    this.props.showToast("Bundle has been created")
  }

  onUpdatedBundle(updatedBundle) {
    this.setState({ bundles: this.state.bundles.map(bundle => bundle.id === updatedBundle.id ? updatedBundle : bundle) })
    this.props.showToast("Bundle has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Bundle Groups</div>
          <div className="settingsPreferencesGroup1">
            {this.state.bundle_groups.map(bundle_group => (
              <div className="settingsServicesItem" key={bundle_group.id}>
                <div className="itemTitle">{bundle_group.name}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdateBundleGroup(bundle_group)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDeleteBundleGroup(bundle_group)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>
              </div>
            ))}
          </div>
          <button onClick={() => this.onCreateBundleGroup()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Bundles</div>
          <div className="settingsPreferencesGroup1">
            {this.state.bundles.map(bundle => (
              <div className="settingsServicesItem" key={bundle.id}>
                <div className="itemTitle">{bundle.title}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdateBundle(bundle)}>
                  <i className="far fa-edit settingsServiceIcon m-2" />
                </div>
                {/* <div className="itemIcon" onClick={() => {
                  this.onDeleteBundle(bundle)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div> */}
              </div>
            ))}
          </div>
          <button onClick={() => this.onCreateBundle()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}