import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import ServiceItem from "../create_project/serviceItem";
import ServiceSubservices from "../create_project/service_subservices";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";
import debounce from 'debounce'
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import BundleGroup from "./bundle_group"

class AddonSubservicesSelectionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      minimize: this.props.minimize
    }
  }

  componentDidMount(){

  }


  render() {

    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""
    const allErrors = [...this.state.errors, ...this.props.summaryErrors]

    let addonServices = []

    let bundle_service_ids = []

    let selected_bundle = ""
    let bundle_group = {}
    if (!this.state.minimize) {
      bundle_group = this.props.bundle_groups.find(bundle_group => bundle_group.id === this.props.selected_bundle.bundle_group_id);
      selected_bundle = <BundleGroup changeParentState={this.handler} bundle_group={bundle_group} selected_bundle={this.props.selected_bundle} selectedSubservices={this.props.selectedSubservices} appointments={this.props.appointments} package_services={this.props.package_attributes} update_bundle={this.props.update_bundle} isLargeScreen={this.props.isLargeScreen} addon={true}/>
    }

    this.props.selected_bundle.sub_services.forEach(sub_service => {
      bundle_service_ids.push(sub_service.service_id)
    })



    Object.values(this.props.services || []).forEach(service => {
        service.subServices.forEach(subService => {
          if ((subService.addon && !bundle_service_ids.includes(service.id)) || (subService.addon && bundle_service_ids.includes(service.id) && subService.always_show_addon)){
            addonServices.push(subService)
          }
        })
    })

    if (addonServices.length === 0){
      this.props.changeParentState({page: this.props.page + 1, addonSubServices: false})
    } else if (!this.props.addonSubservices) {
      this.props.changeParentState({addonSubServices: true})
    }

    let component = <ServiceSubservices addonServices={true} location_pricing={this.props.location_pricing} selectedSubservices={this.props.selectedSubservices} appointments={this.props.appointments} package_services={this.props.package_attributes} subservices={addonServices} changeParentState={this.props.changeParentState} />

    if (this.state.minimize) {
      icon = this.props.page >= 2 ? <div key="2"><i className="fas fa-plus"></i></div> : ""
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 2) {
        checkmark = "createProjectCheckmarkShow"
      }
    }

      return (
        <div className="projectLocationComponent">
          <div className="projectLocationHeadingGrouping" id={minimized}>
            <div className="projectLocationHeadingMinimizedGrouping">
              <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
              <div className="settingsComponentHeading chooseYourServiceText">Want to add anything?</div>
            </div>
            {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
          </div>
          <div className="subServicesSubheading" id={minimizeId}>Select any addon you would like to include for additonal savings</div>
          {allErrors.length > 0 && <div className="errorDiv mt-3 serviceSelectionErrors" id={minimizeId}>{allErrors}</div>}
          <div className="addonBundleGrouping">
            {/* <div className="bundleAddon" id={minimizeId}>
              {selected_bundle}
            </div> */}
            <div className="subServiceAddon" id={minimizeId}>
              {component}
            </div>
          </div>
        </div>
      )
    }
}

export default withRouter(AddonSubservicesSelectionComponent);