import React from "react"
import cameraIcon from "../../../../assets/images/cameraIcon.png"
import GalleryModal from "../gallery/gallery_modal"
import Masonry from "react-masonry-css"
import ScrollableImage from "./scrollable_image";
import { Swipe } from 'react-swipe-component';
import leftIcon from "../../../../assets/images/propertyTour/chevron-left.svg"
import rightIcon from "../../../../assets/images/propertyTour/chevron-right.svg"

class Gallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentImage: 0
    }
    this.interval = null;
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
  }

  componentDidMount() {
    // Start autoplay when the component mounts
    this.startAutoplay();
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    this.stopAutoplay();
  }

  handleNext() {
    this.stopAutoplay()
    if (this.state.currentImage < this.props.images.length - 1) {
      this.setState({ currentImage: this.state.currentImage + 1 })
    } else {
      this.setState({ currentImage: 0 })
    }
  }

  handlePrevious() {
    this.stopAutoplay()
    if (this.state.currentImage > 0) {
      this.setState({ currentImage: this.state.currentImage - 1 })
    } else {
      this.setState({ currentImage: this.props.images.length - 1 })
    }
  }

  startAutoplay() {
    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        currentImage: (prevState.currentImage + 1) % this.props.images.length
      }));
    }, 7000); // Change image every 10 seconds (adjust as needed)
  }

  stopAutoplay() {
    clearInterval(this.interval);
  }

  clickPhoto(i) {
    this.stopAutoplay()

    this.setState({
      currentImage: i
    })

  }

  render() {
    const images = this.props.images

    return (
    <div className="galleryPropertyTourDiv">
      {this.props.images.length > 0 && <a className="prevGalleryImage" onClick={this.handlePrevious}>
        <img src={leftIcon} />
      </a>}
      <div className="propertyTourGallery">
          <Swipe
            nodeName="div"
            detectTouch={true}
            detectMouse={false}
            onSwipedLeft={this.handleNext}
            onSwipedRight={this.handlePrevious}>
          <ScrollableImage name="gallery" currentImage={this.state.currentImage} images={images}/>
          </Swipe>
        <div className="imagesSelection">
          <div className="imagesSelectionInner customScrollbar">
            {images.map((image, i) => {
              return <div className="image" key={`gallery-image-${image.id}`} onClick={() => this.clickPhoto(i)}>
                <img src={image.url} />
              </div>
            })}
          </div>
        </div>
      </div>
        {this.props.images.length > 0 && <a className="nextGalleryImage" onClick={this.handleNext}>
          <img src={rightIcon} />
        </a>}
     </div>
    )
  }
}

export default Gallery