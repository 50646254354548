import {DirectUpload} from "@rails/activestorage"

const withRetry = async (fn, retries = 3) => {
  let attempts = 0;
  while (attempts < retries) {
    try {
      return await fn();
    } catch (error) {
      if (attempts === retries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempts) * 1000));
      attempts++;
    }
  }
};

export const UPLOADING = 'uploading'
export const SUCCEEDED = 'succeeded'
export const FAILED = 'failed'
export const BACKEND_PROCESSING = 'BACKEND_PROCESSING'
export const ABORTED = 'aborted'

export class MultipleMediasUploader {
  static queue = [];
  static active = false;

  constructor(project, service, unprocessed, files, onProgress, callback) {
    this.project = project
    this.service = service
    this.files = files
    this.unprocessed = unprocessed
    this.onProgress = onProgress
    this.callback = callback
    this.aborted = false
    this.result = []
    this.pcts = Array(files.length).fill(0)
    this.currentIndex = null
    this.status = UPLOADING
  }

  state() {
    return {
      project: this.project,
      service: this.service,
      totalProgress: this.totalProgress(),
      uploadedFilesCount: this.uploadedFilesCount(),
      filesCount: this.files.length,
      status: this.status
    }
  }

  static enqueue(uploader) {
    this.queue.push(uploader);
    if (!this.active) {
      this.processQueue();
    }
  }

  static async processQueue() {
    if (this.queue.length === 0) {
      this.active = false;
      return;
    }
    this.active = true;
    const uploader = this.queue.shift();
    await uploader.start();
    this.processQueue();
  }

  totalProgress() {
    return Math.ceil(this.pcts.reduce((res, e) => res + e, 0) / this.pcts.length)
  }

  uploadedFilesCount() {
    return this.pcts.filter(p => p === 100).length
  }

  async start() {
    for (let i = 0; i < this.files.length; i++) {
      this.currentIndex = i;  // Update currentIndex to reflect the current file being uploaded
      const file = this.files[i];
      if (this.status !== UPLOADING) return;

      await withRetry(() => this.uploadFile(file), 3);
    }

    if (this.result.length === this.files.length) {
      this.status = BACKEND_PROCESSING;
      this.onProgress(this);
      this.callback(this.project, this.service, this.unprocessed, this.result.map(m => m.signed_id));
      this.status = SUCCEEDED;
      this.onProgress(this);
    }
  }

  async uploadFile(file) {
    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads', this);
    return new Promise((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) {
          this.status = FAILED;
          this.onProgress(this);
          reject(error);
        } else {
          this.result.push(blob);
          this.pcts[this.currentIndex] = 100;
          this.onProgress(this);
          resolve();
        }
      });
    });
  }

  // called by DirectUpload
  directUploadWillCreateBlobWithXHR(xhr) {
    this.xhr = xhr
    this.onProgress(this)
  }

  // called by DirectUpload
  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", event => {
      this.xhr = xhr
      this.directUploadDidProgress(event)
    })
  }

  directUploadDidProgress(event) {
    this.pcts[this.currentIndex] = Math.ceil(event.loaded * 100 / event.total)
    this.onProgress(this)
  }

  abort() {
    if (this.xhr) this.xhr.abort()
    this.status = ABORTED
    return this
  }
}