import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RenderingPage from './RenderingPage';

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({});

export default withRouter(connect(msp, mdp)(RenderingPage));
