import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Container } from 'reactstrap';
import { Range } from 'react-range';
import Slider from "react-slick";

import {
  VirtualStagingSliderWrapper,
  InputRangeWrapper,
  RangeBeforeAfter,
  InputRangeContainer,
  RangeWrapper,
  RangeIcon,
  SlideItemWrapper,
  SlideItemPostImage,
  SlideItemPreImage,
  ImageMarker,
} from './styles';
import ServicesButton from '../ServicesButton';
import {
  VIRTUAL_STAGING,
  SERVICES,
} from '../../../../../assets/images/servicesPages';

const { VIRTUAL_STAGING_SLIDER } = VIRTUAL_STAGING;
const { Slides } = VIRTUAL_STAGING_SLIDER;

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const VirtualStagingTopSlider = (props) => {
  const width = useWindowSize();
  const [imageWidth, setImageWidth] = useState([0]);
  const [preImageWidth, setPreImageWidth] = useState(1320);
  const [isFirst, setFirst] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      openPre();
    }, 1500);
  }, []);

  useEffect(() => {
    const carouselItem = document.getElementsByClassName(
      'slick-slide'
    );
    setPreImageWidth(carouselItem[0].style.width);
  }, [width]);

  const onSlideChange = (prev, next) => {
    setImageWidth([0]);
    setFirst(true);
  };

  const onSlideChanged = (event) => {
    openPre();
  };

  const openPre = () => {
    setTimeout(() => {
      setImageWidth([40]);
      setTimeout(() => {
        setFirst(false);
      }, 700);
    }, 1500);
  };

  const slideItems = Slides.map((item, index) => {
    return (
      <SlideItem
        index={index + 1}
        item={item}
        key={index}
        imageWidth={imageWidth}
        preImageWidth={preImageWidth}
        isFirst={isFirst}
      />
    );
  });

  return (
    <VirtualStagingSliderWrapper isFirst={isFirst}>
      <ServicesButton setModal={props.setModal} buttonText={props.buttonText} />
      <Container>
        <InputRangeWrapper>
          <RangeBeforeAfter>Before</RangeBeforeAfter>
          <InputRangeContainer>
            <Range
              step={0.001}
              min={0}
              max={100}
              values={imageWidth}
              isDragged={true}
              allowOverlap={false}
              onChange={(values) => setImageWidth(values)}
              renderTrack={({ props, children }) => (
                <RangeWrapper {...props}>{children}</RangeWrapper>
              )}
              renderThumb={({ props }) => <RangeIcon {...props} />}
            />
          </InputRangeContainer>
          <RangeBeforeAfter>After</RangeBeforeAfter>
        </InputRangeWrapper>
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          dots={true}
          lazyLoad={"progressive"}
          beforeChange={onSlideChange}
          afterChange={onSlideChanged}
        >
          {slideItems}
        </Slider>
      </Container>
    </VirtualStagingSliderWrapper>
  );
};

const SlideItem = (props) => {
  const { index, item, imageWidth, preImageWidth, isFirst } = props;
  const { Post, Pre } = item;

  return (
    <SlideItemWrapper className={`slideItemWrapper${index}`}>
      <SlideItemPostImage postImage={Pre}>
        <SlideItemPreImage
          preImage={Post}
          imageWidth={imageWidth[0]}
          preImageWidth={preImageWidth}
          isFirst={isFirst}
        />
      </SlideItemPostImage>
      <ImageMarker
        id={`Tooltip-${index}`}
        src={SERVICES.ImageSliderMarker}
        alt={''}
      />
    </SlideItemWrapper>
  );
};

export default VirtualStagingTopSlider;
