import React from 'react'
import completedImg from "../../../../../../assets/images/photographerOnboarding/completed.svg"
import {LINKS} from "../../../../../../assets/images/contactForm";

export const Completed = () => {
  const onClick = (e) => {
    e.preventDefault()
    location.href = LINKS.INSTAGRAM
  }

  return <div className="initial-info-container">
    <div className="apply-for-discount-center-image-wrapper">
      <img src={completedImg}/>
    </div>

    <h1 className="apply-for-discount-completed-title">
      You’re confirmed!
    </h1>

    <div className="apply-for-discount-completed-description">
      Great work! We’re looking forward to speaking with you. Invitation emails have been sent to your inbox.
    </div>

    <div className="lead-page-divider"/>

    <div className="mar-bottom-30">
      <button className="projectEditButton projectEditButtonPrimary projectEditButtonLarger w-100" onClick={onClick}>
        Explore Our Instagram
      </button>
    </div>
  </div>
}