import { RECEIVE_ALL_FAQS } from '../actions/faq_actions';

const faqReducer = (state = { faqs: [] }, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ALL_FAQS:
      return Object.assign({}, state, { faqs: action.faqs });
    default:
      return state;
  }
};

export default faqReducer;
