import { RECEIVE_ALL_PROJECTS, RECEIVE_PROJECT, REMOVE_PROJECT} from "../actions/project_actions";

const projectReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ALL_PROJECTS:
      return Object.assign({}, state, {projects: action.projects})
    case RECEIVE_PROJECT:
      return Object.assign({}, state, action.project)
    case REMOVE_PROJECT:
      let newState = Object.assign({}, state);
      delete newState[action.project_id]
      return newState
    default:
      return state;
  }
};

export default projectReducer;