import React, {createRef} from "react";
import {Chart} from "chart.js";

import {DoughnutController, ArcElement} from "chart.js";
Chart.register(DoughnutController, ArcElement)

export default class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.chartRef = createRef();
  }

  componentDidMount() {
    this.chart = new Chart(this.chartRef.current, this.chartOptions())
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.chart.data = this.chartOptions().data;
    this.chart.update();
  }

  chartOptions() {
    return {
      type: 'doughnut',
      options: {
        layout: {
          padding: 2
        },
        elements: {
          arc: {
            borderWidth: 0
          }
        }
      },
      data: this.props.data
    }
  }

  render() {
    return <canvas ref={this.chartRef}/>;
  }
}