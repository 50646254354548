export const fetchFAQs = () => (
  $.ajax({
    type: "GET",
    url: "/faqs"
  })
);

export const createFAQ = (title, body) => (
  $.ajax({type: "POST",
    url: "/faqs",
    data: {faq: {title, body}}
  })
)

export const updateFAQ = (id, title, body) => (
  $.ajax({
    type: "PATCH",
    url: `/faqs/${id}`,
    data: {faq: {title, body}}
  })
)

export const deleteFAQ = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/faqs/${id}`
  })
)