import React from "react";
import { fetchUser } from "../../../util/users_api_util";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { updateLocationPrices, deleteLocationPrice } from "../../../util/settings_api_util";
import { fetchSubServices, fetchLocationSubServices, fetchLocations } from "../../../util/service_api_util";
import Select from 'react-select'
import LocationComponent from "../../location_component";


export default class SettingsLocationPrices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_locations: false,
      original_location_prices: {},
      new_location_prices: {},
      sub_services: [],
      location: "",
      locationText: "",
      locations: [],
      addressAttributes: {
        city: "",
        state: "",
        zipcode: "",
        street: "",
        apartment: "",
        longitude: "",
        latitude: "",
        formatted_address: "",
        county: ""
      }
    }
    this.changeLocationPrice = this.changeLocationPrice.bind(this)
    this.saveLocationPrices = this.saveLocationPrices.bind(this)
    this.changeLocation = this.changeLocation.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onAddressChange = this.onAddressChange.bind(this)
    this.importLocationOptions = this.importLocationOptions.bind(this)
  }

  componentDidMount() {
    fetchSubServices().then(sub_services => this.setState({sub_services: Object.values(sub_services)}))
    fetchLocations().then(locations => this.setState({locations: locations}))
  }

  onAddressChange(change) {
    this.setState({ addressAttributes: { ...this.state.addressAttributes, ...change }, locationText: change.county })
  }

  onInputChange(field) {
    return (e) => {
      e.preventDefault()
      this.setState({ [field]: e.target.value })
    }
  }

  changeLocation(e) {
    if (e.value === "") {
      this.setState({ location: "" })
    } else {
      this.setState({ loading: true})
      fetchLocationSubServices(e.value).then(location_sub_services => this.setState({original_location_prices: location_sub_services, location: e.value, loading: false}))
    }
  }

  changeLocationPrice(e, sub_service) {
    let newState = Object.assign({}, this.state.new_location_prices);

    if (newState[sub_service.id]){
      newState[sub_service.id]["price"] = e.currentTarget.value || "0"
    } else{
      newState[sub_service.id] = {"location": this.state.location, "sub_service_id": sub_service.id, "price": e.currentTarget.value || "0"}
    }
    this.setState({ new_location_prices: newState })
  }

  importLocationOptions(e){
    fetchLocationSubServices(e.value).then(location_sub_services => {
      let new_location_prices = {} 

      this.state.sub_services.forEach(sub_service => {
        if (location_sub_services[sub_service.id]){
          new_location_prices[sub_service.id] = { "location": this.state.location, "sub_service_id": sub_service.id, "price": location_sub_services[sub_service.id].price}
        }
      })
      this.setState({new_location_prices: new_location_prices})
    })
  }

  findChangedSubServices(oldState, newState) {
    const changedSubServices = {};

    Object.keys(newState).forEach(key => {
      if (JSON.stringify(oldState[key]) !== JSON.stringify(newState[key])) {
        changedSubServices[key] = newState[key];
      }
    });

    return changedSubServices;
  }

  saveLocationPrices() {
    const changedSubServices = this.findChangedSubServices(this.state.original_location_prices, this.state.new_location_prices);
    const data = Object.values(changedSubServices)

    updateLocationPrices(data).then(data => {
      this.props.showToast("Prices have been updated")
      const locations = this.state.locations
      locations.push(this.state.location)
      this.setState({ original_location_prices: Object.assign(data, this.state.original_location_prices), new_location_prices: {}, locations: locations})
    })
  }

  render() {

    const locationOptions = [{ value: "", label: "Choose Location" }]

    Object.values(this.state.locations).forEach(location => {
      locationOptions.push({ value: location, label: location })
    })

    let locations = <Select className="contractorSelect" id="locationInputSettingsAdmin" isLoading = { this.state.loading_locations } defaultValue = {{ value: "", label: "Locations" }} options = { locationOptions } onChange = { this.changeLocation } />

    let import_locations = <Select className="contractorSelect" id="locationInputSettingsAdmin" isLoading={this.state.loading_locations} defaultValue={{ value: "", label: "Import Location Pricing" }} options={locationOptions} onChange={this.importLocationOptions} />

   
    let location_sub_services = Object.values(this.state.sub_services).sort((a, b) => {
        if (a.service.title < b.service.title) {
          return -1;
        }
        if (a.service.title > b.service.title) {
          return 1;
        }
        return 0;
      }).map(sub_service => {
        return <div className="contractorPayoutGroup">
          <div>{sub_service.service.title} - {sub_service.title}</div>
          <div className="locationTrashGroup">
            <input className="formInput"
              onChange={(e) => this.changeLocationPrice(e, sub_service)} type="number" placeholder="Price"
              onFocus={(e) => e.target.value === "0" && (e.target.value = "")}
              onBlur={(e) => e.target.value === "" && (e.target.value = "0")}
              value={parseInt(this.state.new_location_prices[sub_service.id]?.price || this.state.original_location_prices[sub_service.id]?.price || sub_service.price)} />
            {this.state.original_location_prices[sub_service.id]  && <div className="itemIcon" onClick={() => {
              if (window.confirm('Are you sure you want to delete this location sub service price?')) {
                deleteLocationPrice(this.state.original_location_prices[sub_service.id].id).then((response) => {
                  if (response.success){
                    this.props.showToast("Location sub service price has been removed")
                    let newState = Object.assign({}, this.state.new_location_prices)
                    let newOriginalState = Object.assign({}, this.state.original_location_prices)
                    delete newState[sub_service.id];
                    delete newOriginalState[sub_service.id];
                    this.setState({new_location_prices: newState, original_location_prices: newOriginalState})
                  } else {
                    this.props.showToast("Failed to delete location sub service price")
                  }
                })
              }
            }}>
              <i className="far fa-trash-alt settingsServiceIcon m-2" />
            </div>}
          </div>
        </div>
      });

      if (this.state.location === ""){
        location_sub_services = ""
      }

      return (
        <div className="settingsPreferencesGroupingDiv">
          <div className="settingsPreferences">
            <div className="settingsComponentHeading">Location Service Pricing</div>
            <div className="locationServicePricingSearchGroupings">
              {locations}
              {import_locations}
            </div>
            <div className="locationServicePricingSearchGroupings">
              <div className="settingsLocationPriceComponent">
                <LocationComponent changeParentState={this.onAddressChange}
                  virtual_staging_homepage={true}
                  settings_location_prices={true}
                  inComponent={true}
                  formatted_address={this.state.addressAttributes.formatted_address}
                  street={this.state.addressAttributes.street}
                  apartment={this.state.addressAttributes.apartment}
                  zipcode={this.state.addressAttributes.zipcode}
                  state={this.state.addressAttributes.state}
                  city={this.state.addressAttributes.city}
                  latitude={this.state.addressAttributes.latitude}
                  longitude={this.state.addressAttributes.longitude} />
              </div>
              <div className="settingsLocationPriceComponent">
                <div className="inputGroup locationTextInputGroup">
                  <div className="settingsLocationPriceComponent">
                  <input className="formInput" placeholder="Location" onChange={this.onInputChange("locationText")}
                    value={this.state.locationText} />
                  </div>
                  <button className="settingsButton" id="settingsButtonLocationPrice" onClick={() => this.setState({location: this.state.locationText, new_location_prices: {}, original_location_prices: {}})}>Create</button>
                </div>
              </div>
            </div>
            {this.state.location && <div className="locationPricingTitle">{this.state.location} Pricing</div>}
            <div className="settingsPreferencesGroup1">
              <div className="contractorPayoutGroupUl">
                {location_sub_services}
              </div>
            </div>
            <button onClick={this.saveLocationPrices} className="settingsButton preferencesButton settingsTimeSlotsButton">Save changes</button>
          </div>
        </div>
      );
    }
}