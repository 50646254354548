import React from "react";
import {simple_timezones} from "../../../util/timezone_utils";

const TimeZoneSelect = ({onChange, value}) => {
  return <select onChange={onChange} value={value} className="formInput">
    {simple_timezones.map(zone => (
      <option value={zone.label} key={zone.label}>{zone.label}</option>
    ))}
  </select>
}

export default TimeZoneSelect