import { connect } from "react-redux";
import CreateVirtualSuiteHomePageModal from "./create_virtual_suite_home_page_modal";
import React from "react";
import { withRouter } from "react-router-dom"
import { fetchServices, fetchContractors } from "../../actions/service_actions";
import { submitOrder } from "../../actions/project_actions";
import { openModal } from "../../actions/modal_actions";
import { showToast } from "../../actions/toast_actions";
import { createVirtualStagingPurchase } from "../../actions/virtual_staging_actions";
import { fetchCurrentUser, removeCurrentUser } from "../../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
  services: state.entities.services,
  contractors: state.entities.contractors,
});

const mdp = (dispatch) => ({
  fetchServices: () => dispatch(fetchServices()),
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id)),
  createVirtualStagingPurchase: (orders) => dispatch(createVirtualStagingPurchase(orders)),
  submitOrder: (data) => dispatch(submitOrder(data)),
  openModal: (modal, props) => { dispatch(openModal(modal, props)); },
  showToast: (message) => dispatch(showToast(message)),
  removeCurrentUser: () => dispatch(removeCurrentUser()),
});

export default withRouter(connect(msp, mdp)(CreateVirtualSuiteHomePageModal));