import React, { useEffect, useState } from "react";
import { closeModal } from "../actions/modal_actions";
import { connect } from 'react-redux';
import VirtualStagingMainContainer from './virtual_staging/virtual_staging_main_container';
import LoginFormContainer from './forms/login_form_container';
import CreateServiceContainer from './settings/services/create_service_container';
import UpdateServiceContainer from './settings/services/update_service_container';
import SubServicesContainer from './settings/services/sub_services_container';
import GalleryContainer from "./settings/gallery/gallery_container";
import UpdateUserContainer from "./settings/users/update_user_container";
import CreateCategoryContainer from "./settings/virtual-staging/create_category_container";
import UpdateCategoryContainer from "./settings/virtual-staging/update_category_container";
import UpdateProductsContainer from "./settings/virtual-staging/update_products_container";
import CreateVirtualSuiteTypeContainer from "./settings/virtual-staging/create_virtual_suite_type_container";
import UpdateVirtualSuiteTypeContainer from "./settings/virtual-staging/update_virtual_suite_type_container";
import CreateUserContainer from "./settings/users/create_user_modal_container";
import CreateFirmContainer from "./settings/firms/create_firm_modal_container";
import UpdateFirmContainer from "./settings/firms/update_firm_modal_container";
import CreateTermsOfServiceContainer from "./settings/terms-of-service/create_terms_of_service_container";
import ViewTermsOfServiceContainer from "./settings/terms-of-service/view_terms_of_service_container";
import CreateSubBrokerModalContainer from "./settings/sub-brokers/create_sub_broker_modal_container";
import UpdateSubBrokerModalContainer from "./settings/sub-brokers/update_sub_broker_modal_container";
import FaqFormModalContainer from "./settings/faq/faq_form_modal_container";
import AdFormModalContainer from "./settings/ads/ad_form_modal_container";
import BlogCategoryFormModalContainer from "./settings/blogs/blog_category_form_modal_container";
import UpdatePromoModalContainer from "./settings/promo/update_promo_modal_container";
import CreatePromoModalContainer from "./settings/promo/create_promo_modal_conatiner";
import BundleGroupFormModalContainer from "./settings/bundles/bundle_group_form_modal_container";
import BundleFormModalContainer from "./settings/bundles/bundle_form_modal_container";
import DiscountCodeFormModalContainer from "./settings/discount_codes/discount_code_form_modal_container";
import LocationFormModalContainer from "./settings/locations/location_form_modal_container";
import MilestoneFormModalContainer from "./settings/milestones/milestone_form_modal_container";
import NotificationFormModalContainer from "./settings/notifications/notification_form_modal_container";

function Modal({ modal, modalProps, closeModal, modalInfo }) {
  if (!modal) {
    return null;
  }

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   return () => document.body.style.overflow = 'unset';
  // }, []);

  let component;
  switch (modal) {
    case 'virtualStaging':
      component = <VirtualStagingMainContainer />
      break;
    case 'login':
      component = <LoginFormContainer />
      break;
    case 'createService':
      component = <CreateServiceContainer />
      break;
    case 'createCategory':
      component = <CreateCategoryContainer />
      break;
    case 'updateCategory':
      component = <UpdateCategoryContainer category={modalInfo} />
      break;
    case 'createVirtualSuiteType':
      component = <CreateVirtualSuiteTypeContainer {...modalProps} />
      break;
    case 'updateVirtualSuiteType':
      component = <UpdateVirtualSuiteTypeContainer virtual_suite_type={modalInfo} {...modalProps} />
      break;
    case 'createFirm':
      component = <CreateFirmContainer {...modalProps} />
      break;
    case 'updateFirm':
      component = <UpdateFirmContainer {...modalProps} />
      break;
    case 'updateService':
      component = <UpdateServiceContainer service={modalInfo}/>
      break;
    case 'subServices':
      component = <SubServicesContainer service={modalInfo} />
      break;
    case 'gallery':
      component = <GalleryContainer service={modalInfo} />
      break;
    case 'createUser':
      component = <CreateUserContainer {...modalProps} />
      break;
    case 'updateUser':
      component = <UpdateUserContainer user={modalInfo} {...modalProps} />
      break;
    case 'products':
      component = <UpdateProductsContainer category={modalInfo} />
      break;
    case 'createTermsOfService':
      component = <CreateTermsOfServiceContainer {...modalProps} />
      break
    case 'viewTermsOfService':
      component = <ViewTermsOfServiceContainer {...modalProps} />
      break
    case 'createSubBroker':
      component = <CreateSubBrokerModalContainer {...modalProps}/>
      break
    case 'updateSubBroker':
      component = <UpdateSubBrokerModalContainer {...modalProps}/>
      break
    case 'faqForm':
      component = <FaqFormModalContainer {...modalProps}/>
      break
    case 'adForm':
      component = <AdFormModalContainer {...modalProps}/>
      break
    case 'blogCategoryForm':
      component = <BlogCategoryFormModalContainer {...modalProps} />
      break
    case 'createPromo':
      component = <CreatePromoModalContainer {...modalProps}/>
      break
    case 'updatePromo':
      component = <UpdatePromoModalContainer {...modalProps} />
      break
    case 'bundleGroupForm':
      component = <BundleGroupFormModalContainer {...modalProps} />
      break
    case 'bundleForm':
      component = <BundleFormModalContainer {...modalProps} />
      break
    case 'discountCodeForm':
      component = <DiscountCodeFormModalContainer {...modalProps} />
      break
    case 'locationForm':
      component = <LocationFormModalContainer {...modalProps} />
      break
    case 'milestoneForm':
      component = <MilestoneFormModalContainer {...modalProps} />
      break
    case 'notificationForm':
      component = <NotificationFormModalContainer {...modalProps} />
      break
    default:
      return null;
  }
  return (
    <div className="modal-background">
      <div className="modal-child" onClick={e => e.stopPropagation()}>
        {component}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    modal: state.ui.modal?.component,
    modalProps: state.ui.modal?.props,
    modalInfo: state.entities.modalInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);