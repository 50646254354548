import React, {useState} from "react";
import {ReactComponent as CloseIcon} from "../../../../../assets/images/adModal/icon-close.svg";

export const SlideOutAdModal = (props) => {
  const [email, setEmail] = useState('')
  const {error, title, description, subscribing, button_text, imageUrl, isVertical, onClose, show, type, onSubmit} = props

  const onEmailChange = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  return <form className={`modal-subscription-slide-out modal-subscription-${type} ${show ? 'modal-subscription-show' : ''} ${imageUrl ? 'with-image' : ''}`}
               onSubmit={e => onSubmit(e, email)}>
    <div className="modal-subscription-inner">
      {imageUrl && <div className="modal-subscription-image">
        {onClose && <div className="modal-subscription-close" onClick={onClose}>
          <CloseIcon/>
        </div>}

        <img src={imageUrl}/>
      </div>}
      <div className="modal-subscription-title">
        <div>
          {title}
        </div>
        {onClose && <div className="modal-subscription-close" onClick={onClose}>
          <CloseIcon/>
        </div>}
      </div>

      {description && <div className="modal-subscription-description editorContent"
                           dangerouslySetInnerHTML={{__html: description}}/>}

      <div className="modal-subscription-email-and-button">
        <div className="modal-subscription-email">
          <input value={email} onChange={onEmailChange} type="email" placeholder="Email Address"/>
          {error && <div className="formInputError">{error}</div>}
        </div>

        <div className="modal-subscription-buttons">
          <button className="projectEditButton projectEditButtonPrimary" type="submit" disabled={subscribing}>
            {button_text}
          </button>
        </div>
      </div>

      {!isVertical && onClose &&
        <div className="modal-subscription-close" onClick={onClose}>
          <CloseIcon/>
        </div>}
    </div>
  </form>
}