import React from 'react'


const List = ({block}) => {
  const ListComponent = block.data.style === 'ordered' ? 'ol' : 'ul'

  const renderList = (items, listIndex = block.id) => {
    return items.map((item, index) => {
      const listItemIndex = `${listIndex}-${index}`

      if (item instanceof Array) {
        return (<ul key={listItemIndex}>
          {renderList(item, listItemIndex)}
        </ul>)
      } else {
        return <li key={listItemIndex} dangerouslySetInnerHTML={{__html: item}}/>
      }
    })
  }

  return (
    <ListComponent>
      {renderList(block.data.items || [])}
    </ListComponent>
  )
}

export default List
