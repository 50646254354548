import React from "react"
import Lightbox from "react-spring-lightbox"

class GalleryModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPhoto: this.props.currentPhoto,
    }
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.keyPress = this.keyPress.bind(this)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyPress, false)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress, false)
  }

  keyPress(event) {
    if (event.keyCode === 37 || event.keyCode === 39) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  handleNext() {
    if (this.state.currentPhoto < this.props.photos.length - 1) {
      this.setState({currentPhoto: this.state.currentPhoto + 1})
    } else {
      this.setState({currentPhoto: 0})
    }
  }

  handlePrevious() {
    if (this.state.currentPhoto > 0) {
      this.setState({currentPhoto: this.state.currentPhoto - 1})
    } else {
      this.setState({currentPhoto: this.props.photos.length - 1})
    }
  }

  handleClose() {
    if (this.props.onClose){
      this.props.onClose()
    } else {
      this.props.changeParentState({ galleryModal: false })
    }
  }

  render() {
    const images = this.props.photos.map(photo => ({src: photo.highend_url || photo, loading: 'lazy', alt: ''}))

    return <Lightbox
      isOpen={true}
      onPrev={this.handlePrevious}
      onNext={this.handleNext}
      images={images}
      currentIndex={this.state.currentPhoto}
      onClose={this.handleClose}
      renderFooter={() =>
        <div className="lightbox-footer">
          <div className="galleryModalArrow" onClick={this.handlePrevious}><i className="fas fa-chevron-left"/></div>
          {this.state.currentPhoto + 1} of {this.props.photos.length}
          <div className="galleryModalArrow" onClick={this.handleNext}><i className="fas fa-chevron-right"/></div>
        </div>}
    />
    // return (
    //   <div className="modal-background" onClick={() => this.props.changeParentState({galleryModal: false})}>
    //     <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
    //       <div className="orderPreviewModalComponent galleryModalComponent">
    //         <Swipe
    //           nodeName="div"
    //           detectTouch={true}
    //           detectMouse={false}
    //           onSwipedLeft={this.handleNext}
    //           onSwipedRight={this.handlePrevious}>
    //         <div className="galleryPhotoMainComponent">
    //           {this.props.current_service?.media_types === "video" ?
    //             <video className="galleryModalPhoto" controls>
    //               <source src={photoSrc} type="video/mp4"/>
    //             </video> :
    //             <img className="galleryModalPhoto" src={photoSrc}/>
    //           }
    //          </div>  
    //           </Swipe>
    //         <div className="galleryFormButtons">
    //           <div className="galleryModalArrow" onClick={this.handlePrevious}>
    //             <i className="fas fa-chevron-left"/>
    //           </div>
    //           <div className="numberOfPhotosDiv">
    //             {this.state.currentPhoto + 1} of {this.props.photos.length}
    //           </div>
    //           <div className="galleryModalArrow" onClick={this.handleNext}>
    //             <i className="fas fa-chevron-right"/>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // )
  }
}

export default GalleryModal