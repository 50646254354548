import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { fetchTermsOfService } from "../../util/terms_of_service_util";
import { createUser } from "../../util/settings_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { destroyUser } from "../../util/users_api_util";
import { checkFromList } from "../../util/ad_api_utils";
import { ReactComponent as CloseIcon } from '../../../../assets/images/close-icon.svg'

class SignUpAndPaymentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      confirmEmail: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      cardFirstName: "",
      cardLastName: "",
      password: "",
      confirmPassword: "",
      errors: [],
      agreeToTerms: false,
      terms: null,
      paymentInfo: {},
      user_id: "",
      page: 1,
      freezeEmail: false
    }

    this.handleInput = this.handleInput.bind(this)
    this.handler = this.handler.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.createPayment = this.createPayment.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.openTermsOfService = this.openTermsOfService.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }


  componentDidMount() {
    fetchTermsOfService("client").then(terms => this.setState({ terms }))
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  handler(newState) {
    this.setState(newState)
  }

  handleCheckbox() {
    this.setState({ agreeToTerms: !this.state.agreeToTerms })
  }

  validateEmail(email) {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  openTermsOfService(e) {
    e.preventDefault();
    this.props.openModal("viewTermsOfService", { terms: this.state.terms })
  }

  handleSubmit() {
    this.setState({ errors: [] })

    this.props.changeParentState({backendErrors: []})

    let paymentInfo = this.state.paymentInfo;
    let errors = []

    if (this.state.firstName === "") {
      errors.push([1, "First name can't be blank."])
    }

    if (this.state.lastName === "") {
      errors.push([2, "Last name can't be blank"])
    }

    // if (this.state.cardFirstName === ""){
    //   errors.push([12, "Card first name can't be blank."])
    // }

    // if (this.state.cardLastName === "") {
    //   errors.push([12, "Card last name can't be blank."])
    // }

    if (this.state.email === "") {
      errors.push([3, "Email can't be blank"])
    }

    if (!this.validateEmail(this.state.email)){
      errors.push([3, "Invalid Email"])
    }

    if (this.state.password === "") {
      errors.push([4, "Password can't be blank"])
    }

    if (this.state.email != this.state.confirmEmail){
      errors.push([5, "Emails must match"])
    }

    const phoneNumberPattern = /^(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/;

    if (!phoneNumberPattern.test(this.state.phoneNumber)) {
      errors.push([11, 'Please enter a valid 10-digit phone number.']);
    }

    if (!paymentInfo["cvc"] || (paymentInfo["cvc"].length != 3 && paymentInfo["cvc"].length != 4)) {
      errors.push([7, "CVC code must be 3 or 4 digits long"])
    }

    if (!paymentInfo["last_four"] || (paymentInfo["last_four"].length != 16 && paymentInfo["last_four"].length != 15 && paymentInfo["last_four"].length != 19)) {
      errors.push([8, "Card number must be 15 or 16 digits long"])
    }

    if (paymentInfo["exp_month"] === "Month") {
      errors.push([9, "Expiration month is required"])
    }

    if (paymentInfo["exp_year"] === "Year") {
      errors.push([10, "Expiration year is required"])
    }

    if (errors.length > 0) {
      this.setState({ errors: errors })
    } else {
      this.submitForm()
    }
  }

  submitForm() {
    if (!this.state.agreeToTerms) {
      this.setState({ errors: [[6, "Must agree to terms of service in order to create account"]] })
    } else {
      let user = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.password,
        phone_number: this.state.phoneNumber,
        agree_to_terms: this.state.agreeToTerms,
        timezone: this.props.detected_timezone
      }
      localStorage.setItem('creatingProject', 'true')
      this.setState({page: 2})
      createUser(user).then((user) => {
          this.props.fetchCurrentUser(user.id)
          this.props.changeParentState({ user: user })
          this.createPayment(user)
      }).catch(result => {
        this.setState({ errors: [[6, result.responseJSON]], page: 1 })
      }
      )
    }
  }

  createPayment(user) {
    let errors = []
    let paymentInfo = this.state.paymentInfo;

    paymentInfo["user_id"] = user.id
    paymentInfo["cardholder_name"] = this.state.firstName + " " + this.state.lastName

    if (errors.length === 0) {
        this.props.createPayment(paymentInfo).then((payment) => {
          if (payment.errors) {
            this.props.removeCurrentUser()
            destroyUser(user.id, payment.errors)
            errors.push([6, payment.errors])
            this.props.changeParentState({ user: {} })
            this.setState({ errors: errors, page: 1 })
          } else {
            this.props.submitOrder()
          }
        })
    } else {
      this.setState({ errors: errors, page: 1 })
    }
  }

  handleEmailChange(e){
    if (this.validateEmail(e.currentTarget.value) && !this.props.bundle?.id){
      checkFromList(e.currentTarget.value, "book_shoot_ad").then(response => {
        if (response.subscribed){
          this.props.switchToFreePackage()
          this.setState({freezeEmail: true})
        }
      })
    }

    if (!this.state.freezeEmail){
      this.setState({email: e.currentTarget.value})
    }
  }

  handleInput(field) {
    return e => {
      let paymentInfo = this.state.paymentInfo
      if ((field === "cvc" && e.currentTarget.value.length > 4) || (field === "last_four" && e.currentTarget.value.length > 16)) {

      } else {
        paymentInfo[field] = e.currentTarget.value
      }
      this.setState({ paymentInfo: paymentInfo });
    }
  }


  render() {
    let passwordErrorClass = "";
    let emailErrorClass = "";
    let firstNameErrorClass = "";
    let lastNameErrorClass = "";
    let phoneNumberErrorClass = "";
    let confirmEmailErrorClass = "";
    let cardFirstNameErrorClass = "";
    let cardLastNameErrorClass = "";

    let passwordErrors = [];
    let firstNameErrors = [];
    let lastNameErrors = [];
    let cardFirstNameErrors = [];
    let cardLastNameErrors = [];
    let phoneNumberErrors = [];
    let emailErrors = [];
    let otherErrors = [];
    let agentIdErrors = [];
    let confirmEmailErrors = [];
    let termsErrors = [];


    let cardHolderNameErrorClass = "";
    let cardNumberErrorClass = "";
    let expMonthErrorClass = "";
    let expYearErrorClass = "";
    let cvcErrorClass = "";

    let cardNumberErrors = [];
    let cardHolderNameErrors = [];
    let expMonthErrors = [];
    let expYearErrors = [];
    let cvcErrors = [];
    let backendErrors = [];

    this.state.errors.forEach((error) => {

      if (error[0] === 0) {
        termsErrors.push(<li>{error[1]}</li>)
      }
      else if (error[0] === 1) {
        firstNameErrors.push(<li>{error[1]}</li>)
        firstNameErrorClass = "formInputError"
      }
      else if (error[0] === 2) {
        lastNameErrors.push(<li>{error[1]}</li>)
        lastNameErrorClass = "formInputError"
      }
      else if (error[0] === 3) {
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }
      else if (error[0] === 4) {
        passwordErrors.push(<li>{error[1]}</li>)
        passwordErrorClass = "formInputError"
      } else if (error[0] === 5) {
        confirmEmailErrors.push(<li>{error[1]}</li>)
        confirmEmailErrorClass = "formInputError"
      }
      else if (error[0] === 6) {
        otherErrors.push(<li>{error[1]}</li>)
      }
      else if (error[0] === 7) {
        cvcErrors.push(<li>{error[1]}</li>)
        cvcErrorClass = "formInputError"
      }
      else if (error[0] === 8) {
        cardNumberErrors.push(<li>{error[1]}</li>)
        cardNumberErrorClass = "formInputError"
      }
      else if (error[0] === 9) {
        expMonthErrors.push(<li>{error[1]}</li>)
        expMonthErrorClass = "formInputError"
      }
      else if (error[0] === 10) {
        expYearErrors.push(<li>{error[1]}</li>)
        expYearErrorClass = "formInputError"
      }
      else if (error[0] === 11) {
        phoneNumberErrors.push(<li>{error[1]}</li>)
        phoneNumberErrorClass = "formInputError"
      } else if (error[0] === 12) {
        cardFirstNameErrors.push(<li>{error[1]}</li>)
        cardFirstNameErrorClass = "formInputError"
      } else if (error[0] === 13) {
        cardLastNameErrors.push(<li>{error[1]}</li>)
        cardLastNameErrorClass = "formInputError"
      }
    })
    
    if (this.props.backendErrors.length > 0){
      this.props.backendErrors.forEach((error) => {
        backendErrors.push(<li>{error}</li>)
      })
    }

    const yearOptions = [];
    let d = new Date();
    let n = d.getFullYear();
    for (let i = n; i < n + 20; i++) {
      yearOptions.push(<option key={i} value={i}>{i}</option>);
    }

    if (this.state.page === 1 || backendErrors.length > 0){
    return (
    <div className="modal-background" id="modal-background-homepage">
      <div className={`orderpreviewModal addServiceModal`} onClick={e => e.stopPropagation()}>
         <div className="orderPreviewModalComponent">
            <div className="addServiceModalContent">
              <div className="closeIcon modalCloseButtonMobileAlwaysShow" onClick={(e) => this.props.changeParentState({ modalOpen: false })}><CloseIcon /></div>
              <div className="projectLocationComponent">
                <div className="signupInputGroup">
                  <div className="inputGrouping">
                    <div className="signupLeftInput">
                      <label id="formLabel"> First name </label>
                      <input className="formInput" id={firstNameErrorClass} onChange={(e) => this.setState({ firstName: e.currentTarget.value })} type="string" placeholder="First Name" value={this.state.firstName} />
                      <ul className="errorUl">{firstNameErrors}</ul>
                    </div>
                    <div className="signupRightInput">
                      <label id="formLabel"> Last name </label>
                      <input className="formInput" id={lastNameErrorClass} onChange={(e) => this.setState({ lastName: e.currentTarget.value })} type="string" placeholder="Last Name" value={this.state.lastName} />
                      <ul className="errorUl">{lastNameErrors}</ul>
                    </div>
                  </div>
                  <div className="inputGrouping">
                    <div className="signupLeftInput">
                      <label id="formLabel"> Email</label>
                      <input className="formInput" id={emailErrorClass} onChange={this.handleEmailChange} type="email" placeholder="Email address" value={this.state.email} />
                      <ul className="errorUl">{emailErrors}</ul>
                    </div>
                    <div className="signupRightInput">
                      <label id="formLabel">Confirm Email</label>
                      <input className="formInput" id={confirmEmailErrorClass} onChange={(e) => this.setState({ confirmEmail: e.currentTarget.value })} type="email" placeholder="Email address" value={this.state.confirmEmail} />
                      <ul className="errorUl">{confirmEmailErrors}</ul>
                    </div>
                  </div>
                  <div className="inputGrouping">
                    <div className="signupLeftInput">
                      <label id="formLabel"> Create Password </label>
                      <input className="formInput" id={passwordErrorClass} onChange={(e) => this.setState({ password: e.currentTarget.value })} type="password" placeholder="Password" value={this.state.password} />
                      <ul className="errorUl">{passwordErrors}</ul>
                    </div>
                    <div className="signupRightInput">
                    <label id="formLabel"> Phone number </label>
                    <input className="formInput" id={phoneNumberErrorClass} onChange={(e) => this.setState({ phoneNumber: e.currentTarget.value })} type="tel" placeholder="Phone Number" value={this.state.phoneNumber} />
                    <ul className="errorUl">{phoneNumberErrors}</ul>
                  </div>
                  </div>
                  {/* <div className="inputGrouping">
                    <div className="signupLeftInput">
                      <label id="formLabel"> Card Holder First Name </label>
                      <input className="formInput" id={cardFirstNameErrorClass} onChange={(e) => this.setState({ cardFirstName: e.currentTarget.value })} type="string" placeholder="Card First Name" value={this.state.cardFirstName} />
                      <ul className="errorUl">{cardFirstNameErrors}</ul>
                    </div>
                    <div className="signupRightInput">
                      <label id="formLabel"> Card Holder Last name </label>
                      <input className="formInput" id={cardLastNameErrorClass} onChange={(e) => this.setState({ cardLastName: e.currentTarget.value })} type="string" placeholder="Card Last Name" value={this.state.cardLastName} />
                      <ul className="errorUl">{cardLastNameErrors}</ul>
                    </div>
                  </div> */}
                  <div className="inputGrouping">
                    <div className="signupLeftInput">
                      <label id="formLabel"> Card Number </label>
                      <input className="formInput" id={cardNumberErrorClass} onChange={this.handleInput("last_four")} type="string" placeholder="Card Number" value={this.state.paymentInfo["last_four"] || ""} />
                      <ul className="errorUl">{cardNumberErrors}</ul>
                    </div>
                    <div className="signupRightInput">
                      <label id="formLabel"> CVC </label>
                      <input className="formInput" id={cvcErrorClass} onChange={this.handleInput("cvc")} type="string" placeholder="CVC" value={this.state.paymentInfo["cvc"] || ""} />
                      <ul className="errorUl">{cvcErrors}</ul>
                    </div>
                  </div>
                  <div className="inputGrouping">
                    <div className="signupLeftInput">
                    <label id="formLabel"> Expiration Month</label>
                    <select className="formInput" id={expMonthErrorClass} onChange={this.handleInput("exp_month")} name="Month" value={this.state.paymentInfo["exp_month"] || "Month"} >
                      <option value="Month" disabled={true}>Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <ul className="errorUl">{expMonthErrors}</ul>
                    </div>
                    <div className="signupRightInput">
                      <label id="formLabel"> Expiration Year </label>
                      <select className="formInput" id={expYearErrorClass} onChange={this.handleInput("exp_year")} name="Year" value={this.state.paymentInfo["exp_year"] || "Year"} >
                        <option value="Year" disabled={true}>Year</option>
                        {yearOptions}
                      </select>
                      <ul className="errorUl">{expYearErrors}</ul>
                    </div>
                </div>
                </div>
                <div className="signupFormButtons createProjectSignupFormButtons createProjectHomePageSignupFormButtons">
                  {this.state.terms && <label className="agreeToTermsCheckbox createProjectTermsCheckbox"><input type="checkbox" className="formCheckBox" checked={this.state.agreeToTerms} onChange={() => this.handleCheckbox()} /> <span className="termsOfServiceText">I agree to the <a onClick={this.openTermsOfService} href="">terms of service</a></span></label>}
                  <div className="homepageCheckoutTotal">Total: ${this.props.price}</div>
                  <ul className="errorUl errorUlHomepagemModal">{otherErrors}</ul>
                  <ul className="errorUl errorUlHomepagemModal">{backendErrors}</ul>
                  <div className="signupFormNext createProjectButton" onClick={this.handleSubmit}>Pay Now</div>
                </div>
              </div>
            </div>
         </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    );
  }
 else {
  return(
    <div className="modal-background" id="modal-background-homepage">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading"> Checkout</div>
            </div>
            <div className="orderPreviewModalCategory">
              <div className="settingsComponentHeading">Submitting Order...</div>
              <div className="createProjectPaymentModal">
                <div className="loaderDiv">
                  {/* <Loader
                      type="Oval"
                      color="#4E57AA"
                      height={100}
                      width={100}
                      timeout={30000} //3 secs
                    /> */}
                  <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   )
 } 
}
}

export default withRouter(SignUpAndPaymentModal);