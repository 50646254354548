import React, {useState, useEffect} from "react"
import NavbarContainer from "../navbar/navbar_container"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {showToast} from "../../actions/toast_actions";
import ReferralsIndexAdmin from "./referrals_index_admin";
import ReferralsIndexClient from "./referrals_index_client";
import ReferralsIndexContractor from "./referrals_index_contractor";
import ReferralsIndexSalesRep from "./referrals_index_sales_rep";

const Referrals = (props) => {
  let component = ""

  if (props.currentUser.roles.admin){
    component = <ReferralsIndexAdmin />
  } else if (props.currentUser.roles.contractor)
  {
    component = <ReferralsIndexContractor />
  } else if (props.currentUser.roles.sales_rep) {
    component = <ReferralsIndexSalesRep />
  } else {
    component = <ReferralsIndexClient />
  }
  return <div className="dashboardFullPageDiv referrals">
    <NavbarContainer />
     {component}
  </div>
}

const msp = (state) => (
  {
    currentUser: state.entities.users[state.session.currentUser.id]
  }
)

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});


export default withRouter(connect(msp, mdp)(Referrals))
