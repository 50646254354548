import {combineReducers} from 'redux';
import virtualStagingReducer from './virtual_staging_reducer';
import projectReducer from './project_reducer';
import faqReducer from './faq_reducer';
import usersReducer from './users_reducer';
import serviceReducer from './service_reducer';
import modalInfoReducer from './modal_info_reducer';
import contractorsReducer from './contractor_reducer';
import calendarReducer from './calendar_reducer';
import toastsReducer from "./toasts_reducer";
import landingPageReducer from "./landing_page_reducer";
import reviewReducer from './review_reducer';
import mediaUploadReducer from "./media_upload_reducer";

const entitiesReducer = combineReducers({
  virtualStaging: virtualStagingReducer,
  projects: projectReducer,
  faqsQuestions: faqReducer,
  users: usersReducer,
  services: serviceReducer,
  modalInfo: modalInfoReducer,
  landingPage: landingPageReducer,
  contractors: contractorsReducer,
  calendar: calendarReducer,
  toasts: toastsReducer,
  reviews: reviewReducer,
  mediaUploads: mediaUploadReducer
});

export default entitiesReducer;
