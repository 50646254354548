import React, {useCallback, useEffect, useRef, useState} from 'react'
import {createPhotographer, updatePhotographer} from "../../../util/photographer_onboarding_util";
import {useDispatch} from "react-redux";
import {fetchCurrentUser} from "../../../actions/session_actions";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {CAPTCHA_SITEKEY} from "../../../util/recaptcha_utils";
import {simple_timezones} from "../../../util/timezone_utils"

const InitialInfoInsideCaptchaProvider = (props) => {
  const dispatch = useDispatch()
  const {executeRecaptcha} = useGoogleReCaptcha();

  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const form = useRef()
  const [data, setData] = useState({
    ...{
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      timezone: '',
    }, ...props.data
  })

  const onTimezoneChange = (e) => {
    e.preventDefault();
    setData({ ...data, timezone: e.target.value });
  };

  const onSubmitWithCaptcha = useCallback(async (e) => {
    if (!executeRecaptcha) {
      return;
    }

    e.preventDefault()
    const token = await executeRecaptcha('photographerOnboarding');
    onSubmit(e, token)
  }, [executeRecaptcha, data]);

  const onInput = (fieldName) => {
    return (e) => {
      e.preventDefault()
      setData({...data, [fieldName]: e.target.value})
    }
  }

  useEffect(() => {
    if (errors.length > 0) {
      form.current.scrollTo({top: 0, behavior: 'smooth'})
    }
  }, [errors]);

  const fields = [
    {label: 'First name', field: 'firstName', type: 'text'},
    {label: 'Last name', field: 'lastName', type: 'text'},
    {label: 'Email', field: 'email', type: 'email'},
    {label: 'Phone number', field: 'phoneNumber', type: 'text'},
    {label: 'Password', field: 'password', type: 'password'},
    {label: 'Confirm password', field: 'confirmPassword', type: 'password'},
  ]

  const onSubmit = (e, captcha) => {
    e.preventDefault()

    const payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phoneNumber,
      password: data.password,
      password_confirmation: data.confirmPassword,
      timezone: data.timezone,
      agree_to_terms: true,
      captcha: captcha
    }

    setLoading(true)

    const submitFn = props.userId ? updatePhotographer : createPhotographer

    submitFn({user: payload}).then(result => {
      setLoading(false)
      const {password, confirmPassword, ...savedData} = data

      props.setUserId(result.id)
      dispatch(fetchCurrentUser(result.id))
      props.onNext(savedData)
    }).catch(response => {
      setLoading(false)
      setErrors(response.responseJSON || [])
    })
  }

  return <form onSubmit={onSubmitWithCaptcha} ref={form}>
    {errors.length > 0 && <div className="alert alert-danger mb-4">
      {errors.map((err, i) => (<div key={`error-${i}`}>{err}</div>))}
    </div>}

    <div className="photographer-onboarding-form-title">
      Create your <span className="blue">invite-only</span> photographer account.
    </div>

    <div className="photographer-onboarding-form-fields">
      {fields.map((field, i) => (
        <div className="photographer-onboarding-form-field" key={`field-${i}`}>
          <label id="formLabel" className="formLabel required">{field.label}</label>
          <input className="formInput" placeholder="Content" value={data[field.field]}
                 onInput={onInput(field.field)} required={true} type={field.type}/>
        </div>
      ))}
      <div className="photographer-onboarding-form-field">
        <label id="formLabel" className="formLabel required">Timezone</label>
        <select className="formInput" value={data.timezone} onChange={onTimezoneChange} required>
          <option value="">Select Timezone</option>
          {simple_timezones.map((tz, i) => (
            <option key={`timezone-${i}`} value={tz.value}>{tz.label}</option>
          ))}
        </select>
      </div>
    </div>

    <div className="photographer-onboarding-divider mar-bottom-20"/>

    <div className="photographer-onboarding-footer-buttons">
      <div />

      <button className="projectEditButton projectEditButtonPrimary" type="submit" disabled={loading}>
        Get Started
      </button>
    </div>
  </form>
}

export const InitialInfo = (props) => {
  return <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_SITEKEY}>
    <InitialInfoInsideCaptchaProvider {...props} />
  </GoogleReCaptchaProvider>
}