import React from "react";
import { Route, Link } from "react-router-dom";
import ProductItem from "./product_item"
import { fetchVirtualStagingProducts } from "../../util/virtual_staging_api_util";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import VirtualStagingGalleryModal from "../virtual_staging/virtual_staging_gallery_modal";


export default class ProductSelectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsStream: 12,
      products: [],
      minimize: this.props.minimize,
      loading: false,
      currentProduct: 0,
      galleryModalOpen: false,
    }
    this.toggleSelected = this.toggleSelected.bind(this);
    this.handler = this.handler.bind(this);
    this.streamMoreProducts = this.streamMoreProducts.bind(this)
  }

  componentDidMount() {
    if (this.props.category_id && !this.props.minimize) {
      this.setState({loading: true})
      fetchVirtualStagingProducts(this.props.category_id).then(products => {
        this.setState({products: products, loading: false}); 
        if (!this.props.product){
          if (products.length === 1) {
            this.props.changeParentState({ product: products[0], products: products, page: this.props.page + 1 })
          } else {
          this.props.changeParentState({ product: products[0], products: products })
          }
        } else {
          this.props.changeParentState({ products: products})
        }
      })
    }
  }

  handler(newState) {
    this.setState(newState)

    if (newState.galleryModalOpen != null){
      this.props.changeParentState({productModalOpen: newState.galleryModalOpen})
    }
  }

  streamMoreProducts() {
    this.setState({ productsStream: this.state.productsStream + 12 })
  }

  toggleSelected(product) {
    if (this.props.product != product) {
      this.props.changeParentState({ product: product })
    } else {
      this.props.changeParentState({ product: null })
    }
  }

  render() {
    let streamButton = ""

    if (this.state.productsStream < this.state.products.length) {
      streamButton = <div onClick={this.streamMoreProducts} className="streamButton">
        <i className="fas fa-chevron-down" />
      </div>
    }


    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""

    if (this.state.minimize) {
      icon = <div key="2"><i className="fas fa-plus"></i></div>
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 4) {
        checkmark = "createProjectCheckmarkShow"
      }

    }

    let modal = ""

    if (this.state.galleryModalOpen) {
      modal = <VirtualStagingGalleryModal photos={this.state.products} changeParentState={this.handler}
        currentPhoto={this.state.currentProduct} />
    }

    return (
      <div className="projectLocationComponent">
        {modal}
        <div className="projectLocationHeadingGrouping" id={minimized}>
          <div className="projectLocationHeadingMinimizedGrouping">
            <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
            <div className="settingsComponentHeading chooseYourServiceText">Choose Style</div>
          </div>
          {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
        </div>
        <div id={minimizeId} className="virtualStagingPhotosGrid virtualSuiteProductsGrid">
          <div className="virtualSuiteSubTitle">This is the style of furniture that will be added to the room.</div>
          {this.state.loading ? <div className="createProjectLogoSpinner">
            <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
          </div> : <>
            {this.state.products.slice(0, this.state.productsStream).map((product, i) => {
              return <ProductItem key={i} changeParentState={this.handler}
                homepage={this.props.homepage}
                product={product}
                selected={this.props.product?.id === product.id}
                toggleSelected={this.toggleSelected} index={i} />
            })}
          </>
          }
          {streamButton}
        </div>
      </div>
    );
  }
}