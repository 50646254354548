import React from 'react'
import {
  Description,
  FeaturesListContainer,
  Header,
  HeaderImage,
  HeaderImageLoad,
  HeaderWrapper,
  HeaderWrapperInner,
  Heading,
  ScheduleShootButtonWrapper,
  ViewAllCities,
  ViewAllCitiesIcon
} from "./styles";
import headerImg from '../../../../../assets/images/location/header.jpg'
import iconLeft from '../../../../../assets/images/location/icon-left.svg'
import {BlueText} from "../../pages/locations/LocationsStyles";
import {ROUTES} from "../../../routes";
import {ApplyButton} from "../../locations_page_components/MapSection/styles";
import {Link, withRouter} from "react-router-dom";
import {FeaturesList} from "../../pageComponents/FeaturesList";
import { connect } from "react-redux";

const LocationHeader = (props) => {

  const city = props.city

  const onScheduleShoot = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  return <HeaderWrapper>
    {city?.city ? <HeaderImage src={city?.header_image?.url || headerImg} /> : <div className="headerFiller"></div>}
    <HeaderWrapperInner>
      <Header>
        <Link to={ROUTES.LOCATIONS}>
          <ViewAllCities>
            <ViewAllCitiesIcon src={iconLeft}/>
            View all Cities
          </ViewAllCities>
        </Link>
        <Heading>
          Book The Best Real Estate Photographer in <BlueText>{city.city}</BlueText>
        </Heading>
        <Description>
          Get Photography, Drone, Video, Floor Plans and Virtual Staging in 24h for Your {city.city} Listing
        </Description>
        <FeaturesListContainer>
          <FeaturesList/>
        </FeaturesListContainer>

        <ScheduleShootButtonWrapper>
          <ApplyButton onClick={onScheduleShoot}>
            Schedule your shoot now
          </ApplyButton>
        </ScheduleShootButtonWrapper>
      </Header>
    </HeaderWrapperInner>
  </HeaderWrapper>
}

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(LocationHeader));