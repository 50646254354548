import React from "react"
import {withRouter} from "react-router-dom"
import reviewIcon from "../../../../assets/images/reviewIcon.png"
import aerial from "../../../../assets/images/icons/service/aerial.svg"
import floorplans from "../../../../assets/images/icons/service/floorplans.svg"
import other from "../../../../assets/images/icons/service/other.svg"
import photography from "../../../../assets/images/icons/service/photography.svg"
import video from "../../../../assets/images/icons/service/VideoService.svg"
import virtualStaging from "../../../../assets/images/icons/service/virtual-staging.svg"
import virtualTour from "../../../../assets/images/icons/service/virtual-tour.svg"
import twilight from "../../../../assets/images/icons/service/TwilightService.svg"


class PhotographerServices extends React.Component {
  serviceIcon(slug) {
    const icons = {
      aerial,
      floorplans,
      video,
      photography,
      twilight,
      'virtual-staging': virtualStaging,
      'virtual-tour': virtualTour,
    }
    return icons[slug] || other
  }

  render() {
    let photographer = this.props.photographer
    let services = photographer?.services || []

    return <>
      {services.length > 0 && <div className="photographerShowServices">
        {services.map(service => {
          if (!service.reshoot){
            return <div className="photographerShowServiceCard" key={service.slug}>
              <img className="serviceIconImage" src={this.serviceIcon(service.slug)}/>
              <div className="photographerShowServiceCardTitle">
                {service.title}
              </div>
            </div>
          }
        })}
      </div>}

      {photographer && services.length === 0 && <div className="emptyPlaceholder">
        <img src={reviewIcon}/>
        <div className="emptyPlaceholderText">No services yet</div>
      </div>}
    </>
  }
}

export default withRouter(PhotographerServices)