import styled from 'styled-components';

export const ServicesNavContainer = styled.div`
  position: relative;
  height: 25px;
  top: 0px;
  background: #eaf1f6;
  z-index: 1;
`;

export const MainConainter = styled.div`
  height: 25px;
  @media screen and (max-width: 1399px) {
    max-width: 95%;
  }
  @media screen and (max-width: 1039px) {
    max-width: 100%;
  }
`;

export const Row = styled.div`
  height: 25px;
`;

export const Col6Left = styled.div`
  height: 25px;
`;

export const Col6Right = styled.div`
  height: 25px;
  float: right;
  margin-left: auto;
  margin-right: 0;
`;

export const ServiceActive = styled.div`
  position: absolute;
  width: 9px;
  height: 9px;
  top: 8px;
  background: #a0ecba;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
`;

export const ServicesOperational = styled.p`
  height: 25px;
  margin-left: 11px;
  top: 0px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: #353844;
`;

export const ServiceNavSelect = styled.select`
  float: right;
  background-color: transparent;
  border-color: transparent;
  height: 25px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #353844;
  &:focus-visible {
    border: solid 0px transparent;
    outline: none;
  }
`;

export const ServiceNavSelectOption = styled.option`
  color: #353844;
`;
