import React from "react";
import { Route, Link, withRouter } from "react-router-dom";

class ReviewModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      comment: this.props.review?.comment || "",
      value: this.props.review?.value || 5,
      page: 1
    }
   this.handleInput = this.handleInput.bind(this)
   this.submitReview = this.submitReview.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  submitReview() {
    let review = {value: this.state.value, comment: this.state.comment, user_id: this.props.currentUser.id, rateable_type: "User", rateable_id: this.props.reviewable_id}

    const action = this.props.review && this.props.review.id ?
      this.props.updateReview(review, this.props.review.id) :
      this.props.submitReview(review)

    action.then(() => {
      this.props.onClose()
      this.props.showToast("Your review has been submitted")
      this.props.onReloadProject()
    })
  }

  render() {

    if (this.state.page === 1){
      return (
        <div className="modal-background" onClick={() => this.props.onClose()}>
          <div className="orderpreviewModal reviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="reviewModalHeading">How was your experience with {this.props.photographer_first_name || "your photographer"}?</div>
                <div className="closeIcon" onClick={() => this.props.onClose()}><i className="fas fa-times"></i></div>
              </div>
              <div className="settingsCategoryGroup1">
              <div className="starRatingReviewInput">
                  {this.state.value >= 1 ? <div key="1" onClick={() => this.setState({ value: 1 })}><span className='fa fa-star starRating'></span></div> : <div key="6" onClick={() => this.setState({ value: 1 })}><span className='far fa-star starRating'></span></div>}
                  {this.state.value >= 2 ? <div key="2" onClick={() => this.setState({ value: 2 })}><span className='fa fa-star starRating'></span></div> : <div key="7" onClick={() => this.setState({ value: 2 })}><span className='far fa-star starRating'></span></div>}
                  {this.state.value >= 3 ? <div key="3" onClick={() => this.setState({ value: 3 })}><span className='fa fa-star starRating'></span></div> : <div key="8" onClick={() => this.setState({ value: 3 })}><span className='far fa-star starRating'></span></div>}
                  {this.state.value >= 4 ? <div key="4" onClick={() => this.setState({ value: 4 })}><span className='fa fa-star starRating'></span></div> : <div key="9" onClick={() => this.setState({ value: 4 })}><span className='far fa-star starRating'></span></div>}
                  {this.state.value >= 5 ? <div key="5" onClick={() => this.setState({ value: 5 })}><span className='fa fa-star starRating'></span></div> : <div key="10" onClick={() => this.setState({ value: 5 })}><span className='far fa-star starRating'></span></div>}
              </div>
                <div className="settingsServicesInput">
                  <label id="formLabel"></label>
                  <textarea className="formTextArea" onChange={this.handleInput("comment")} type="string" placeholder="Leave feedback here..." value={this.state.comment} />
                </div>
              </div>
              <div className="reviewModalSubmitButton">
                <button onClick={this.submitReview} className="settingsButton">Submit Review</button>
              </div>
            </div>
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="modal-background" onClick={() => this.props.onClose()}>
          <div className="orderpreviewModal reviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="reviewHeadingGroup">
                <div className="closeIcon" onClick={() => this.props.onClose()}><i className="fas fa-times" /></div>
              </div>
              <div className="settingsCategoryGroup1">
                <div className="reviewThanks">Thank you for leaving a review!</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(ReviewModal);