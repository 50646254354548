import React from "react";
import { withRouter } from "react-router-dom";
import markerImg from '../../../assets/images/map-marker.svg'
import photographerMarkerImg from '../../../assets/images/map_marker_photographer.svg'
import markerClusterer from '@google/markerclustererplus';
import Avatar from '../../../assets/images/avatar.png'
import {mapOptions} from "../util/map_utils";

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    this.markers = []

    this.initMapMarkers = this.initMapMarkers.bind(this)
    this.markerCluster = null;
    this.initials = this.initials.bind(this)
  }

  componentDidMount() {
    this.initMap();
    this.initMapMarkers();

    const mapElement = document.getElementById('googleMap');
    if (mapElement) {
      mapElement.style.borderRadius = '8px';
      mapElement.style.overflow = 'hidden';
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contractors !== this.props.contractors || prevProps.projects !== this.props.projects) {
      this.clearMarkers();
      this.initMapMarkers();
    }
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null); // Remove each marker from the map
    }
    if (this.markerCluster) {
      this.markerCluster.clearMarkers(); // Clear the clusters
      this.markerCluster = null;
    }
    this.markers = []; // Clear the markers array
  }

  initials(user) {
    return [
      (user.first_name || user.firstName).charAt(0),
      (user.last_name || user.lastName).charAt(0)
    ].join('')
  }

  initMap() {
    this.map = new google.maps.Map(this.mapNode, mapOptions);

    const mapElement = document.getElementById('googleMap');
    if (mapElement) {
      mapElement.style.borderRadius = '8px';
      mapElement.style.overflow = 'hidden';
    }
  }

  initMapMarkers() {

    const clusterOptions = {
      gridSize: 50, // Adjust as needed
      maxZoom: 10, // Adjust as needed
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // Path to cluster images
      // styles: [{
      //   url: this.props.projects ? MapMarker : MapMarker,
      //   textColor: '#ffffff',
      //   textSize: 16,
      //   anchorText: [13, 18]
      // }]
    };

    let bounds = new google.maps.LatLngBounds();

    this.props.projects?.forEach((project, index) => {
      let address = project?.address

      let contentDetails = `<div style="font-weight: 600; color:#313336; font-size: 16px">${address?.street1}</div><div style="font-weight: 400; color: #5B6B78; font-size: 12px; max-width: 245px; margin-top: 5px;"> ${address?.formatted_address ? `${address?.apartment_number || ""} ${address?.formatted_address}` : `${address?.city}, ${address?.region} ${address?.zip_code}`}</div><button style="
      background: #1B264F;
      color: white;
      text-align: center;
      border-radius: 40px;
      width: 100px;
      height: 25px;
      display: inline-block;
      line-height: 0px;
      cursor: pointer;
      border: none;
      margin-top: 10px;" onClick="window.location.href = 'projects_index/${project?.slug}'">View Project
      </button>`

      const infowindow = new google.maps.InfoWindow({
        content: contentDetails,
      });

      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(address?.latitude, address?.longitude),
        map: this.map,
        icon: markerImg
      });

      bounds.extend(marker.position);

      marker.addListener("click", () => {
        infowindow.open(this.map, marker);
      });

      this.markers.push(marker);

    })

    this.props.contractors?.forEach((contractor, index) => {
      let location = contractor.primary_zip_code

      let contentDetails = `<div style="text-align: center; max-height: 250px"><img style="width: 60px; height: 60px; min-width: 60px; border-radius: 50%; object-fit: cover; margin-bottom: 5px" src=${contractor?.avatar?.url || contractor?.avatar || Avatar}></img><div style="font-weight: 600; color:#313336; font-size: 16px">${contractor?.first_name} ${contractor?.last_name[0]}</div><div style="font-weight: 400; color: #5B6B78; font-size: 12px; max-width: 245px; margin-top: 5px;"> ${location}</div><div style="
      background: #2C5BFF;
      color: white;
      text-align: center;
      border-radius: 40px;
      width: 100px;
      height: 25px;
      display: inline-block;
      line-height: 25px;
      cursor: pointer;
      border: none;
      font-size: 10px;
      user-select: none;
      margin-top: 10px;" onClick="window.location.href = 'photographers/${contractor?.id}'">View Photographer
      </div></div>`

      const infowindow = new google.maps.InfoWindow({
        content: contentDetails,
      });

      infowindow.addListener('domready', () => {
        // Find the InfoWindow's container element
        const infowindowContainer = document.querySelector('.gm-style-iw');

        if (infowindowContainer) {
          infowindowContainer.classList.add('custom-infowindow');
        }
      });

      const latLngHash = (contractor.latitude * 100000 + contractor.longitude * 100000 + contractor.id).toString();
      const baseOffset = parseFloat(`0.000${latLngHash.slice(-3)}`);
      const offsetMultiplier = 20;
      const latOffset = baseOffset * offsetMultiplier;
      const lngOffset = baseOffset * offsetMultiplier;

      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(contractor?.latitude + latOffset, contractor?.longitude + lngOffset),
        map: this.map,
        icon: photographerMarkerImg
      });

      bounds.extend(marker.position);

      marker.addListener("click", () => {
        infowindow.open(this.map, marker);
      });

      this.markers.push(marker);
    })

    this.markerCluster = new markerClusterer(this.map, this.markers, clusterOptions);

    if (this.props.projects?.length > 1 || this.props.contractors?.length > 1) {
      this.map.fitBounds(bounds);
    }
    else if (this.props.projects?.length === 1) {
      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(16);
    } else if (this.props.contractors?.length === 1) {
      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(12);
    }

    if (this.props.full_map_results){
      const originalZoom = this.map.getZoom();
      this.map.setZoom(originalZoom - 1);
      setTimeout(() => {
        this.map.setZoom(originalZoom);
      }, 100);
    }
  }

  render() {
    return (
      <div className="googleMapDashboard" id="googleMap" ref={map => this.mapNode = map} />
    );
  }
}

export default withRouter(Map)