import React from "react";
import {updateSubBroker} from "../../../util/sub_brokers_api_util";


export default class UpdateSubBrokerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.user.email,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      phoneNumber: this.props.user.phoneNumber,
      avatar: "",
      src: this.props.user.avatar,
      errors: []
    }

    this.handleInput = this.handleInput.bind(this)
    this.updateUserProfile = this.updateUserProfile.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.src)
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value});
  }

  handlePictureSelected(event) {
    let avatar = event.target.files[0];
    let src = URL.createObjectURL(avatar);

    this.setState({
      avatar: avatar,
      src: src
    });
  }

  updateUserProfile() {
    const formData = new FormData()
    formData.append("user[first_name]", this.state.firstName);
    formData.append("user[last_name]", this.state.lastName);
    formData.append("user[phone_number]", this.state.phoneNumber);

    if (this.state.avatar !== "") {
      formData.append("user[avatar]", this.state.avatar);
    }

    if (this.state.email !== this.props.user.email) {
      formData.append("user[email]", this.state.email);
    }

    updateSubBroker(this.props.user.id, formData).then(user => {
      this.props.onUserUpdate(user)
      this.props.closeModal()
    }).catch(response => {
      this.setState({errors: response.responseJSON || []})
    })
  }

  render() {
    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">Update Sub-Broker</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel">First name</label>
              <input className="formInput" onChange={this.handleInput("firstName")} placeholder="First name"
                     value={this.state.firstName}/>
            </div>
            <div className="signupRightInput">
              <label id="formLabel">Last name</label>
              <input className="formInput" onChange={this.handleInput("lastName")} placeholder="Last name"
                     value={this.state.lastName}/>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel">Email</label>
              <input className="formInput" onChange={this.handleInput("email")} type="email"
                     placeholder="Email address" value={this.state.email}/>
            </div>
            <div className="signupRightInput">
              <label id="formLabel">Phone number </label>
              <input className="formInput" onChange={this.handleInput("phoneNumber")} type="tel"
                     placeholder="Phone Number" value={this.state.phoneNumber}/>
            </div>
          </div>

          <div className="settingsCategoryGroup2">
            <div className="settingsComponentHeading">Change photo</div>
            <div className="settingsComponentSubHeading">
              Allow others to see who they are working with. This enables a profile picture in the directory, as well as
              showing a small icon within workflow for other users to easily identify you.
            </div>
            <div className="settingsPhotoDiv settingsUsersEdit">
              <img className="settingsAvatar settingsGalleryCover" src={this.state.src}/>
              <label className="settingsChangePhotoButton coverUploadButton">
                <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg"/>
                Change Photo
              </label>
            </div>
          </div>
          <button onClick={this.updateUserProfile} className="settingsButton">Save changes</button>
        </div>
      </div>
    );
  }
}