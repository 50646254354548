import React from "react";
import { withRouter } from "react-router-dom";
import {ReactComponent as CheckMark} from "../../../../assets/images/icons/icon-check.svg";
import {ReactComponent as SmallCheckIcon} from '../../../../assets/images/landing/check-icon.svg'

class SubServiceItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    let subservice = this.props.subservice;

    return (
      <div onClick={() => this.props.toggleSelected(subservice)}
           className={`subserviceItem ${this.props.selected ? 'subserviceItemSelected' : ''}`}
           key={subservice.id}>
        <div className="subserviceItemPrice">${this.props.location_pricing && this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price}</div>
        <div className="subserviceItemTitle">{subservice.title}</div>
        <div className="subserviceItemDescriptionAndList">
          <div className="subserviceItemDescription">{subservice.description}</div>
          <div className="subserviceItemDescriptionList">
            {(subservice.content?.features || []).map((item, i) => (
              <div className="subserviceItemDescriptionListItem" key={`subservice-${i}`}>
                <SmallCheckIcon />
                <div>{item}</div>
              </div>
            ))}
          </div>
        </div>

        {this.props.selected && <div className="checkIcon2">
          <CheckMark/>
        </div>}
      </div>
    )
  }
}

export default withRouter(SubServiceItem);