export const fetchBundleGroups = () => (
  $.ajax({
    type: "GET",
    url: "/bundle_groups"
  })
);

export const createBundleGroup = (params) => (
  $.ajax({
    type: "POST",
    url: "/bundle_groups",
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const updateBundleGroup = (id, params) => (
  $.ajax({
    type: "PATCH",
    url: `/bundle_groups/${id}`,
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const deleteBundleGroup = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/bundle_groups/${id}`
  })
)

export const fetchBundles = () => (
  $.ajax({
    type: "GET",
    url: "/discount_bundles",
  })
);

export const fetchBundle = (id) => (
  $.ajax({
    type: "GET",
    url: `/discount_bundles/${id}`
  })
);

export const createBundle = (params) => (
  $.ajax({
    type: "POST",
    url: "/discount_bundles",
    data: { bundle: params }
  })
)

export const updateBundle = (id, params) => (
  $.ajax({
    type: "PATCH",
    url: `/discount_bundles/${id}`,
    data: { bundle: params }
  })
)

export const deleteBundle = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/discount_bundles/${id}`
  })
)