import React, {useState} from "react";

export const Features = (props) => {
  const {onChange} = props
  const [features, setFeatures] = useState(props.features)

  const handleFeature = (i) => {
    return e => {
      e.preventDefault()
      features[i] = e.currentTarget.value
      setFeatures(features)
      onChange([...features])
    }
  }

  const addFeature = (e) => {
    e.preventDefault()
    const newFeatures = [...features, ''];
    setFeatures(newFeatures)
    onChange(newFeatures)
  }

  const removeFeature = (i) => {
    return e => {
      e.preventDefault()
      const newFeatures = features.filter((_, idx) => idx !== i);
      setFeatures(newFeatures)
      onChange(newFeatures)
    }
  }

  return <div className="settingsServicesInput">
    <label id="formLabel">Features</label>

    <div className="d-flex flex-column gap-2">
      {features.map((feature, i) => (
        <div className="d-flex justify-content-between align-items-center" key={`feature-${i}`}>
          <input className="formInput me-4" onChange={handleFeature(i)} type="string"
                 placeholder={`Feature ${i + 1}`} value={features[i]}/>

          <div onClick={removeFeature(i)} className="cursor-pointer">
            <i className="far fa-trash-alt " />
          </div>
        </div>
      ))}
    </div>

    <button className="projectEditButton projectEditButtonSecondary projectEditButtonSmall mt-2"
            onClick={addFeature}>
      Add feature
    </button>
  </div>
}