import React from "react";
import { Route, Link } from "react-router-dom";
import CategoryItem from "./category_item";
import VirtualSuiteTypeItem from "./virtual_suite_type_item";
import {deleteVirtualSuiteType, fetchVirtualSuiteTypes} from "../../../util/virtual_staging_api_util";


export default class VirtualStagingEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtual_suite_types: [],
    }
    this.deleteVirtualSuiteType = this.deleteVirtualSuiteType.bind(this)
  }

  componentDidMount() {
    this.props.fetchCategoriesSettings()
    fetchVirtualSuiteTypes().then(types => this.setState({virtual_suite_types: types}))
  }

  onTypeCreate(type){
    this.setState({ virtual_suite_types: [...this.state.virtual_suite_types, type] })
  }

  onTypeUpdate(updatedType) {
    this.setState({ virtual_suite_types: this.state.virtual_suite_types.map(type => type.id === updatedType.id ? updatedType : type) })
  }

  deleteVirtualSuiteType(id){
    deleteVirtualSuiteType(id)
    this.setState({ virtual_suite_types: this.state.virtual_suite_types.filter(type => type.id != id) })
  }


  render() {
    let categoryItems = this.props.categories.map((category, i) => <CategoryItem key={i} category={category} deleteCategory={this.props.deleteCategory} openModal={this.props.openModal} addInfo={this.props.addInfo} />)
    let typeItems = this.state.virtual_suite_types.map((virtual_suite_type, i) => <VirtualSuiteTypeItem key={i} virtual_suite_type={virtual_suite_type} deleteVirtualSuiteType={this.deleteVirtualSuiteType} openModal={this.props.openModal} onTypeUpdate={this.onTypeUpdate.bind(this)} addInfo={this.props.addInfo} />)

    return (
      <div className="settingsPreferencesGroupingDiv virtualStagingSettingsGroupingDiv">
        <div className="settingsPreferences settingsPreferencesVirtualStaging">
          <div className="settingsComponentHeading">Types</div>
          <div className="settingsPreferencesGroup1">
            {typeItems}
          </div>
          <button onClick={() => this.props.openModal("createVirtualSuiteType", {onTypeCreate: this.onTypeCreate.bind(this)})} className="settingsButton preferencesButton">Add Type</button>
        </div>
        <div className="settingsPreferences">
          <div className="settingsComponentHeading">Categories</div>
          <div className="settingsPreferencesGroup1">
            {categoryItems}
          </div>
          <button onClick={() => this.props.openModal("createCategory")} className="settingsButton preferencesButton">Add Category</button>
        </div>
      </div>
    );
  }
}