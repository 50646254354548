import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  padding: 100px 0;

  @media only screen and (max-width: 1060px) {
    padding: 80px 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
  }
`

export const WrapperInner = styled.div`
  position: relative;
  width: 1060px;
  margin: auto;

  @media only screen and (max-width: 1060px) {
    width: 100%;
  }
`

export const Heading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #1b264f;
  text-transform: none;
  text-align: left;
  margin-bottom: 40px;

  @media only screen and (max-width: 1280px) {
    font-size: 36px;
    line-height: 44px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;

  @media only screen and (max-width: 900px) {
    gap: 40px;
  }
  
  @media only screen and (max-width: 767px) {
    gap: 30px; 
  }
`

export const Section = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  & > * {
    flex: 1;
    width: calc(50% - 30px);

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 767px) {
    flex-direction: column !important;
  }
`

export const SectionImage = styled.img`
  width: 100%;
  height: auto;

  @media only screen and (max-width: 767px) {
    width: 50%;
  }
`

export const ListWrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media only screen and (max-width: 767px) {
    gap: 20px; 
  }
`

export const ListHeading = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  
  img {
    margin-right: 16px;
  }
  
  span {
    color: #353844;
    font-feature-settings: 'salt' on, 'liga' off;
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
    } 
  }
`

export const ListParagraph = styled.div`
  color: #353844;
  font-feature-settings: 'salt' on, 'liga' off;
  font-family: Nunito Sans;
  font-size: 17px;
  font-weight: 600;
  line-height: 160%;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`