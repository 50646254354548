import React, { useState, useEffect, useCallback } from "react"
import NavbarContainer from "../navbar/navbar_container"
import PageHeader from "../page_header"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showToast } from "../../actions/toast_actions";
import { fetchReferralsAdmin } from "../../util/discount_code_api_utils";
import debounce from "debounce";

const ReferralsAdmin = (props) => {

  const [referrals, setReferrals] = useState([])

  const [fullResults, setFullResults] = useState(false)

  const [searchClient, setSearchClient] = useState("")
  const [searchUserType, setSearchUserType] = useState("")

  useEffect(() => {
    fetchReferralsAdmin(fullResults, searchClient, searchUserType).then(referrals => {
      setReferrals(referrals)
    })
  }, [])

  useEffect(() => {
    debounceFetchReferrals(fullResults, searchClient, searchUserType)
  }, [searchClient])

  useEffect(() => {
    debounceFetchReferrals(fullResults, searchClient, searchUserType)
  }, [searchUserType])

  const showFullResults = () => {
    if (props.currentUser.roles.admin) {
      fetchReferralsAdmin(true, searchClient, searchUserType).then(referrals => {
        setReferrals(referrals)
      })
    }
    setFullResults(true)
  }

  const onSearchClientChange = (e) => {
    e.preventDefault()
    setSearchClient(e.target.value)
  }

  const onSearchUserTypeChange = (e) => {
    e.preventDefault()
    setSearchUserType(e.target.value)
  }

  const debounceFetchReferrals = useCallback(
    debounce((fullResults, searchClient, searchUserType) => {
      fetchReferralsAdmin(fullResults, searchClient, searchUserType).then(referrals => {
        setReferrals(referrals)
    })
    }, 500),
    [])

  const options = []
  const userTypes = ["client", "broker", "sub-broker", "contractor"]
  const userTypeFormal = ["Clients", "Brokers", "Sub Brokers", "Contractors"]

  userTypes.forEach((type, i) => {
    options.push(<option value={type}>{userTypeFormal[i]}</option>)
  })

  return (<div className="dashboardMainDiv">
        <PageHeader title="Referrals"
          subtitle="Admin Referral Dashboard"
           showNotifications={true} />

        <div className="sectionCard">
          <div className="sectionCardHeader">
            <div className="titleWithButton">
              <h5 className="photographersCardTitle">
                Referrals
              </h5>
              <a className="viewAll" onClick={showFullResults}>
                View All
              </a>
            </div>
          <div className="searchBarDiv invoicesSearch row">
          <div className={`col-12 col-md-4`}>
            <input className="formInput" onChange={onSearchClientChange} type="text"
                placeholder="Client name" value={searchClient} />
           </div>
          <div className={`col-12 col-md-4`}>
            <select className="formInput" onChange={onSearchUserTypeChange}
                value={searchUserType}>
                <option value="">All</option>
                {options}
              </select>
            </div>
           </div>
          </div>

          <div className="sectionCardBody">
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>Referrer</th>
                    <th>New Client</th>
                    <th>Photoshoot</th>
                    <th>Order Total</th>
                    <th>Reward</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {referrals.map(referral => (
                    <tr key={`referral-${referral.id}`}>
                      <td>{referral.user_email}</td>
                      <td>{referral.client_email}</td>
                      {referral.project_address ? <td><div className="lookLikeLink" onClick={() => props.history.push(`/projects_index/${referral.project_id}`)}>{referral.project_address?.formatted_address}</div></td> : <td className="text-muted">{referral.back_up_address?.formatted_address}</td>}
                      <td>${referral.project_total}</td>
                      <td>${referral.amount}</td>
                      <td>{referral.status}</td>
                      <td>{new Date(referral.created_at).toLocaleDateString("en-US")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)
}

const msp = (state) => (
  {
    currentUser: state.entities.users[state.session.currentUser.id]
  }
)

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});


export default withRouter(connect(msp, mdp)(ReferralsAdmin))