import styled from "styled-components";

export const LocationWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const BestPropertyWrapper = styled.div`
  padding-top: 100px;

  @media only screen and (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`