import styled from 'styled-components';

import { CIRCLE } from '../../../../../assets/images/careersPage';

export const TopSectionWrapper = styled.div`
  width: 100vw;
  position: relative;
  
  &::before {
    content: '';
    width: 512px;
    height: 497px;
    position: absolute;
    bottom: -5%;
    right: -6rem;
    background-image: url(${CIRCLE});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
    @media screen and (max-width: 1050px) {
      bottom: -62%;
    }
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
  @media screen and (max-width: 769px) {
    padding-top: 23px;
  }
`;

export const TopSectionInnerWrapper = styled.div`
  width: 1100px;
  margin: auto;
  position: relative;
 
  @media only screen and (max-width: 1100px) {
    width: 900px;
  }
  
  @media only screen and (max-width: 769px) {
    width: 100vw;
  }
  
  margin-top: 50px;
  
  position: relative;
  display: flex;
  align-items: end;
  
  @media only screen and (max-width: 769px) {
    margin-top: calc(100% * 0.185 * 2.24 * 0.15);
  }
`;

export const Image1 = styled.div`
  width: 27%;
  margin-right: 20px;
  
  @media only screen and (max-width: 769px) {
    margin-right: 10px;
  }
  
  img {
    width: 100%;
    height: auto;
  }
`

export const Image2 = styled.div`
  width: 27%;
  margin-right: 20px;
   
  @media only screen and (max-width: 769px) {
    margin-right: 10px;
  }
  
  img {
    width: 100%;
    height: auto;
  }
`

export const Image3 = styled.div`
  width: 32%;
  margin-right: 20px;
  
  @media only screen and (max-width: 769px) {
    margin-right: 10px;
  }
  
  img {
    width: 100%;
    height: auto;
  }
`

export const Image4 = styled.div`
  width: 21%;
  position: absolute;
  bottom: 260px;
  left: calc(75% + 60px);
   
  @media only screen and (max-width: 1100px) {
    bottom: 213px;
  }
  
  @media only screen and (max-width: 769px) {
    bottom: calc(100vw * 0.32 / 1.47 + 10px);
    left: calc(75% + 30px);
  }
  
  img {
    width: 100%;
    height: auto;
  }
`

export const Image5 = styled.div`
  width: 21%;
  margin-right: 20px;
  position: absolute;
  bottom: 260px;
  left: calc(54% + 40px);
  
  @media only screen and (max-width: 1100px) {
    bottom: 213px;
  }
  
  @media only screen and (max-width: 769px) {
    margin-right: 10px;
    bottom: calc(100vw * 0.32 / 1.47 + 10px);
    left: calc(54% + 20px);
  }
  
  img {
    width: 100%;
    height: auto;
  }
`
