import React from "react"
import {withRouter} from "react-router-dom"
import Cloud from '../../../../assets/images/cloud'
import Dropzone from "../dropzone"
import trashIconSrc from '../../../../assets/images/icons/fi_trash.svg'
import {MultipleMediasUploader} from "../../util/media_uploader_util";
import {showToast} from "../../actions/toast_actions";
import {progressMediaUploading, startMediaUploading} from "../../actions/media_upload_actions";
import {connect} from "react-redux";

class UploadPhotosModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      images: [],
      images_src: [],
      photoGroup: 0,
      modelLink: ""
    }

    this.handlePicturesSelected = this.handlePicturesSelected.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.onSubmitMedia = this.onSubmitMedia.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  onSubmitMedia() {
    if (this.state.images.length === 0) {
      this.props.showToast("At least one photo must be uploaded in order to submit")
      return
    }

    const mediasUploader = new MultipleMediasUploader(
      this.props.project,
      this.props.current_service,
      this.props.unprocessed,
      this.state.images,
      this.props.progressMediaUploading,
      (...args) => this.props.submitPhotosFromUploadModal(...args)
    )

    MultipleMediasUploader.enqueue(mediasUploader);

    this.props.startMediaUploading(mediasUploader);
    
    this.props.onClose()
  }

  deletePhoto(index) {
    let images = this.state.images
    images.splice(index, 1)
    this.setState({images: images})
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value})
  }

  handlePicturesSelected(event) {
    let images = Object.values(event?.target?.files || event)

    this.setState({images: [...this.state.images, ...images]})
  }

  render() {
    if (this.props.current_service?.media_types === "matterport") {
      return (
        <div className="modal-background" onClick={() => this.props.onClose()}>
          <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
            <div className="modalContainer modalContainerUpload">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="uploadPhotoModalHeading"> Upload media</div>
                <div className="closeIcon" onClick={() => this.props.onClose()}><i
                  className="fas fa-times"></i></div>
              </div>
              <div>
                <div className="propertyTourEditInput">
                  <label id="formLabel">Matterport Link </label>
                  <input className="formInput" onChange={this.handleInput("modelLink")} type="string"
                         placeholder="Matterport link" value={this.state.modelLink}/>
                </div>
              </div>
            </div>
            <div className="modalFooter justify-content-end">
              <div className="d-flex">
                <button className="projectEditButton projectEditButtonWhite"
                        onClick={() => this.props.onClose()}>Cancel
                </button>
                <button className="projectEditButton projectEditButtonPrimary ms-2"
                        onClick={() => this.props.submitMatterport(this.state.modelLink)}> Submit Media
                </button>
              </div>
            </div>
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    } else {
      let images = this.state.images.map((image, i) => <div className="uploadImageDiv" key={i}>
        <div className="uploadFileName">{image.name}</div>
        <div className="cursor-pointer" onClick={() => this.deletePhoto(i + this.state.photoGroup)}>
          <img src={trashIconSrc} className="m-0 ms-2 d-block"/>
        </div>
      </div>)

      let cloudIcon = ""

      if (this.state.images.length === 0) {
        cloudIcon = <img className="uploadPhotoCloud mb-2" src={Cloud}/>
      }

      if (this.state.images.length % 2 === 1 && !this.props.isLargeScreen) {
        images.push(<div key="a" className="uploadImageDivDummy"/>)
      }

      return (
        <div className="modal-background">
          <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
            <div className="modalContainer modalContainerUpload">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="uploadPhotoModalHeading"> Upload media</div>
                <div className="closeIcon" onClick={() => this.props.onClose()}><i
                  className="fas fa-times"></i></div>
              </div>
              <div>
                <div className="uploadPhotoGrouping">
                  <Dropzone handleDrop={this.handlePicturesSelected}>
                    <div className="uploadPhotoMainComponent">
                      {cloudIcon}
                      <div className="addPhotosButton">
                        <label className="projectEditButton projectEditButtonPrimary">
                          {this.props.current_service?.media_types === "image" ?
                            <input onChange={this.handlePicturesSelected} type="file" multiple="multiple"
                                   accept="image/*, .NEF, .dng, .arw, .CR3"/> :
                            <input onChange={this.handlePicturesSelected} type="file" multiple="multiple"
                                   accept="video/mp4,video/x-m4v,video/*,audio/* "/>
                          }
                          Add Media
                        </label>
                      </div>
                    </div>

                    <div>{images}</div>
                  </Dropzone>
                </div>
              </div>
            </div>
            <div className="modalFooter justify-content-end">
              <div className="d-flex">
                <button className="projectEditButton projectEditButtonWhite" onClick={() => this.props.onClose()}>
                  Cancel
                </button>
                <button className="projectEditButton projectEditButtonPrimary ms-2"
                        onClick={this.onSubmitMedia}>
                  Submit Media
                </button>
              </div>
            </div>

            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    }
  }
}

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message)),
  startMediaUploading: (uploader) => dispatch(startMediaUploading(uploader)),
  progressMediaUploading: (uploader) => dispatch(progressMediaUploading(uploader))
});

export default withRouter(connect(msp, mdp)(UploadPhotosModal));
