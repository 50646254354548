import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { Swipe } from 'react-swipe-component';

class VirtualStagingGalleryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPhoto: this.props.currentPhoto,
    }

    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  goBack(e) {
    e.stopPropagation();
    this.props.changeParentState({ galleryModalOpen: false, productModalOpen: false })
  }

  handleNext() {
    if (this.state.currentPhoto < this.props.photos.length - 1) {
      this.setState({ currentPhoto: this.state.currentPhoto + 1 })
    } else {
      this.setState({ currentPhoto: 0 })
    }
  }

  handlePrevious() {
    if (this.state.currentPhoto > 0) {
      this.setState({ currentPhoto: this.state.currentPhoto - 1 })
    } else {
      this.setState({ currentPhoto: this.props.photos.length - 1 })
    }
  }


  render() {
    return (
      <div className="modal-background" onClick={this.goBack}>
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading"> Photo Gallery</div>
              <div className="closeIcon" onClick={() => this.props.changeParentState({ galleryModalOpen: false, productModalOpen: false })}><i className="fas fa-times"></i></div>
            </div>
            <Swipe
              nodeName="div"
              detectTouch={true}
              detectMouse={false}
              onSwipedLeft={this.handleNext}
              onSwipedRight={this.handlePrevious}>
            <div className="galleryPhotoMainComponent virtualStagingGalleryModalPhoto">
              <img className="galleryModalPhoto" src={this.props.photos[this.state.currentPhoto].highend_url}></img>
            </div>
            </Swipe>
            <div className="numberOfPhotosDiv">{this.state.currentPhoto + 1} of {this.props.photos.length}</div>
            <div className="galleryFormButtons">
              <div className="signupFormPrevious virtualStagingPrevious" onClick={this.handlePrevious}>Previous</div>
              <div className="signupFormNext" onClick={this.handleNext}>Next</div>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(VirtualStagingGalleryModal);