import styled from 'styled-components';

import { FLOOR_PLANS } from '../../../../../assets/images/servicesPages';

export const FloorPlansTopSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${FLOOR_PLANS.FloorPlansCover});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px;
  margin: 0px;
  margin-top: 170px;
  padding-bottom: 80px;
  .container {
    position: relative;
    height: 720px;
    cursor: pointer;
    @media screen and (max-width: 991px) {
      max-width: 95%;
      height: 520px;
    }
    @media screen and (max-width: 800px) {
      height: 480px;
    }
    @media screen and (max-width: 699px) {
      height: 400px;
    }
    @media screen and (max-width: 555px) {
      height: 300px;
    }
    @media screen and (max-width: 455px) {
      height: 250px;
    }
    @media screen and (max-width: 400px) {
      height: 200px;
    }
  }
  @media screen and (max-width: 699px) {
    padding-bottom: 30px;
    margin-top: 100px;
  }
  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    33% {
      opacity: 1;
    }
    53% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    33% {
      opacity: 1;
    }
    53% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const FloorPlansBaseImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: -18%;
  bottom: 0;
  left: 0;
  right: 0;
  animation: fade 5s linear;
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 10s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 10s;

  @media screen and (max-width: 991px) {
    left: 2%;
  }
`;

export const FloorPlansBaseChangeImage = styled.img`
  width: 97%;
  height: 97%;
  padding: 3%;
  position: absolute;
  top: -22%;
  bottom: 0;
  left: 0;
  right: 0;
  animation: fade 5s linear;
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 10s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-delay: -5s;
  -webkit-animation-delay: -5s;

  @media screen and (max-width: 991px) {
    left: 2%;
  }
`;

export const ServiceButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 555px) {
    bottom: -11%;
  }
`;
