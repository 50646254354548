import React from "react";
import { withRouter } from "react-router-dom";
import NavbarContainer from "./navbar/navbar_container";
import ProjectsIndexProjects from "./projects_index/projects_index_projects";
import {fetchUserProjects} from "../util/project_api_util";
import {fetchAllSubBrokers} from "../util/sub_brokers_api_util";
import { fetchUsersNameAndIdOnly } from "../util/users_api_util";
import modernAnglesLogo from "../../../assets/images/MA_Logo_New_2";
import greenDot from "../../../assets/images/green-dot";
import yellowDot from "../../../assets/images/yellow-dot";
import blueDot from "../../../assets/images/blue-dot";
import NotificationBellContainer from "./notification_bell_container";


class ProjectsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapOpen: true,
      isLargeScreen: window.innerWidth > 750,
      userIdOfProjects: this.props.currentUser.roles.admin ? "all-users" : this.props.currentUser.roles.broker ? "all-sub-brokers" : this.props.currentUser.id,
      subBrokers: [],
      users: [],
      projects: [],
    }
    this.updatePredicate = this.updatePredicate.bind(this);
    this.onUserOfProjectsChange = this.onUserOfProjectsChange.bind(this);
    this.handler = this.handler.bind(this)
    this.abortController = new AbortController();
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  onUserOfProjectsChange(userIdOfProjects) {
    return new Promise((resolve) => {
      userIdOfProjects = userIdOfProjects || this.props.currentUser.id;
      this.setState({ userIdOfProjects }, () => resolve(userIdOfProjects));
    });
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  handler(newState){
    this.setState(newState)
  }

  render() {
      return (
        <div className="dashboardFullPageDiv">
          <div className="projectsIndexPageGroupings">
            <NavbarContainer />
            <div className="dashboardMainDiv" id="projectsIndexMainDiv">
              <div className="projectsIndexPageHeading" id="projectsIndexPageHeadingFlex">
                <div>
                  <div className="dashboardSubHeading">Photoshoots</div>
                  <div className="projectIndexSubHeading">View all of your completed and in-progress photoshoots. </div>
                </div>
                <NotificationBellContainer />
              </div>
              <div className="projectIndexKey">
                <div className="projectIndexKeyItem"><img className="projectIndexKeyItemDot" src={yellowDot}></img>In Progress</div>
                <div className="projectIndexKeyItem"><img className="projectIndexKeyItemDot" src={greenDot}></img>Completed</div>
                <div className="projectIndexKeyItem"><img className="projectIndexKeyItemDot" src={blueDot}></img>Files in Post Processing</div>
              </div>
              <div className="projectsIndexGroups">
                <div className="projectsIndexGroup1">
                  <ProjectsIndexProjects projects={this.state.projects}
                                         isLargeScreen={this.state.isLargeScreen}
                                         currentUser={this.props.currentUser}
                                         subBrokers={this.state.subBrokers}
                                         userIdOfProjects={this.state.userIdOfProjects}
                                         users={this.state.users}
                                         changeParentState={this.handler}
                                         onUserChange={this.onUserOfProjectsChange}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

export default withRouter(ProjectsIndex);