import styled from 'styled-components';

import { PHOTOGRAPHY } from '../../../../../assets/images/servicesPages';

export const ImageSectionWrapper = styled.div`
  background-color: #ffffff;
  margin-top: 50px;
  padding-bottom: 60px;
  overflow: hidden;
  position: relative;
  width: 100%;
  &:after {
    content: '';
    height: 55%;
    width: 30%;
    position: absolute;
    bottom: 15%;
    right: -7%;
    background-image: url(${PHOTOGRAPHY.SectionBackground});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
  }
  .sectionTwoImageWrapper {
    z-index: 1;
  }
  @media screen and (max-width: 1300px) {
    &:after {
      bottom: 11%;
      right: 2%;
    }
  }
  @media screen and (max-width: 1100px) {
    &:after {
      bottom: 8%;
      right: 5%;
    }
  }
  @media screen and (max-width: 900px) {
    padding-bottom: 40px;
    &:after {
      bottom: 5%;
      right: 6%;
    }
  }
  @media screen and (max-width: 800px) {
    &:after {
      bottom: 8%;
      right: 6%;
    }
  }
  @media screen and (max-width: 700px) {
    &:after {
      bottom: 4%;
      right: 6%;
    }
  }
  @media screen and (max-width: 600px) {
    &:after {
      bottom: 8%;
      right: 6%;
    }
  }
  @media screen and (max-width: 500px) {
    &:after {
      bottom: 8%;
      right: 6%;
    }
  }
  @media screen and (max-width: 400px) {
    &:after {
      bottom: 13%;
      right: 6%;
    }
  }
`;

export const LeftImageWrapper = styled.div`
  position: relative;
`;

export const SectionImageLeft = styled.img`
  border-radius: 0px 8px 8px 0px;
  width: 100%;
  height: 760px;
  margin-top: 112px;
  object-fit: cover;
  @media screen and (max-width: 1300px) {
    height: 660px;
  }
  @media screen and (max-width: 1099px) {
    height: 560px;
  }
  @media screen and (max-width: 800px) {
    height: 460px;
  }
  @media screen and (max-width: 600px) {
    height: 360px;
    margin-top: 100px;
  }
  @media screen and (max-width: 500px) {
    height: 280px;
  }
  @media screen and (max-width: 400px) {
    height: 200px;
  }
`;

export const LeftInnerImageWrapper = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 991px) {
    margin-top: 17px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
`;

export const LeftInnerImage3 = styled.img`
  width: 95%;
  object-fit: cover;
  height: 360px;
  border-radius: 0px 8px 8px 0px;
  @media screen and (max-width: 1300px) {
    height: 320px;
  }
  @media screen and (max-width: 1099px) {
    height: 280px;
  }
  @media screen and (max-width: 800px) {
    height: 240px;
  }
  @media screen and (max-width: 600px) {
    height: 200px;
  }
  @media screen and (max-width: 400px) {
    height: 170px;
  }
`;

export const LeftInnerImage4 = styled.img`
  width: 100%;
  object-fit: cover;
  height: 360px;
  border-radius: 8px;
  @media screen and (max-width: 1300px) {
    height: 320px;
  }
  @media screen and (max-width: 1099px) {
    height: 280px;
  }
  @media screen and (max-width: 800px) {
    height: 240px;
  }
  @media screen and (max-width: 600px) {
    height: 200px;
  }
  @media screen and (max-width: 400px) {
    height: 170px;
  }
`;

export const RightImageWrapper = styled.div`
  position: relative;
`;

export const SectionImageRight = styled.img`
  border-radius: 8px 0px 0px 8px;
  width: 100%;
  height: 760px;
  object-fit: cover;
  @media screen and (max-width: 1300px) {
    height: 660px;
  }
  @media screen and (max-width: 1099px) {
    height: 560px;
  }
  @media screen and (max-width: 800px) {
    height: 460px;
  }
  @media screen and (max-width: 600px) {
    height: 360px;
  }
  @media screen and (max-width: 500px) {
    height: 280px;
  }
  @media screen and (max-width: 400px) {
    height: 200px;
  }
`;

export const RightInnerImageWrapper = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 991px) {
    margin-top: 17px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
`;

export const RightInnerImage5 = styled.img`
  width: 95%;
  object-fit: cover;
  height: 470px;
  border-radius: 8px;
  @media screen and (max-width: 1300px) {
    height: 430px;
  }
  @media screen and (max-width: 1099px) {
    height: 390px;
  }
  @media screen and (max-width: 800px) {
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    height: 300px;
  }
  @media screen and (max-width: 400px) {
    height: 270px;
  }
`;

export const RightInnerImage6 = styled.img`
  width: 100%;
  object-fit: cover;
  height: 470px;
  border-radius: 8px 0px 0px 8px;
  @media screen and (max-width: 1300px) {
    height: 430px;
  }
  @media screen and (max-width: 1099px) {
    height: 390px;
  }
  @media screen and (max-width: 800px) {
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    height: 300px;
  }
  @media screen and (max-width: 400px) {
    height: 270px;
  }
`;

export const ImageMarkerLeft = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  @media screen and (max-width: 991px) {
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

export const ImageMarkerRight = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  @media screen and (max-width: 991px) {
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;
