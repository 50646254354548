import React from "react";
import { Route, Link } from "react-router-dom";
import ModernAnglesLogo from "../../../../../assets/images/system-feature-4@2x.png"


export default class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.service?.gallery?.title || "",
      cover: "",
      cover_src: this.props.service?.gallery?.cover_src || ModernAnglesLogo,
      stored_images: this.props.service?.gallery?.images || [],
      stored_images_src: this.props.service?.gallery?.images_src || [],
      deleted_images: [],
      images:[],
      images_src: []
    }
   
    this.handleInput = this.handleInput.bind(this)
    this.createGallery = this.createGallery.bind(this)
    this.updateGallery = this.updateGallery.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
    this.handlePicturesSelected = this.handlePicturesSelected.bind(this)
    this.changePhotoBack = this.changePhotoBack.bind(this)
    this.deletePhoto = this.deletePhoto.bind(this)
    this.deleteGallery = this.deleteGallery.bind(this)
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.cover_src)

    this.state.images_src.forEach(image_src => {
      URL.revokeObjectURL(image_src)
    })
  }

  changePhotoBack() {
      this.setState({
        cover: "",
        cover_src: this.props.service?.gallery?.cover_src || ModernAnglesLogo
      })
  }

  deletePhoto(index){
    if (index >= this.state.stored_images.length){
      index = index - this.state.stored_images.length
      let images_src = this.state.images_src
      let images = this.state.images
      images_src.splice(index, 1)
      images.splice(index, 1)

      this.setState({ images: images, images_src: images_src })

    } else {
      let stored_images_src = this.state.stored_images_src
      let stored_images = this.state.stored_images
      let deleted_images = this.state.deleted_images
      stored_images_src.splice(index, 1)
      let removed = stored_images.splice(index, 1)
      deleted_images.push(removed[0])

      this.setState({ stored_images: stored_images, stored_images_src: stored_images_src, deleted_images: deleted_images })
    }
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handlePictureSelected(event) {
    let cover = event.target.files[0];
    let cover_src = URL.createObjectURL(cover);

    this.setState({
      cover: cover,
      cover_src: cover_src
    });
  }

  handlePicturesSelected(event) {
    let images = Object.values(event?.target?.files || event);

    images.forEach((file) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({
          images: [...this.state.images, file],
          images_src: [...this.state.images_src, reader.result]
        });
      }
      reader.readAsDataURL(file);
    });
  }

  createGallery() {
    let formData = new FormData();

    formData.append("gallery[title]", this.state.title);
    if (this.state.cover != ""){
    formData.append("gallery[cover]", this.state.cover);
    }
    formData.append("gallery[galleriable_id]", this.props.service.id);
    formData.append("gallery[galleriable_type]", "Service");

    for (let i = 0; i < this.state.images.length; i++) {
      formData.append(`gallery[images][]`, this.state.images[i])
    }

    this.props.createGallery(formData).then(() => {
      this.props.closeModal();
    })
  }

  deleteGallery(){
    this.props.deleteGallery(this.props.service.gallery.id).then(() => {
      this.props.closeModal();
      this.props.showToast("Gallery has been destroyed")
    })
  }

  updateGallery() {
    // let gallery_info = { title: this.state.title, cover: this.state.cover, galleriable_id: this.props.service.id, galleriable_type: "Service", images: this.state.images }
    // if (this.state.cover != "") {
    //   gallery_info["cover"] = this.state.cover
    // }
    // let formData = objectToFormData(gallery_info)


    let formData = new FormData();
    formData.append("gallery[title]", this.state.title);
    if (this.state.cover != "") {
      formData.append("gallery[cover]", this.state.cover);
    }
    for (let i = 0; i < this.state.images.length; i++) {
      formData.append("gallery[images][]", this.state.images[i])
    }
    for (let i = 0; i < this.state.deleted_images.length; i++)  {
      formData.append("deleted_images[]", this.state.deleted_images[i].id)
    }

    this.props.updateGallery(formData, this.props.service.gallery.id).then(() => {
      this.props.closeModal();
      this.props.showToast("Gallery has been updated")
    })
  }

  render(){
    let agentMlsIdErrorClass = "";
    let agentMlsIdErrors = [];
    let cancelPhotoChangeButton = ""

    let imagesArray = this.state.stored_images_src.concat(this.state.images_src)

    let images = imagesArray.map((image_src, i) => <div className="galleryImageDiv" key={i}><img className="galleryImage" src={image_src} /><div className="itemIcon" onClick={() => this.deletePhoto(i)}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div></div>)

    if (this.state.cover != "") {
      cancelPhotoChangeButton = <button className="changeBackButton galleryChangeBackButton" onClick={this.changePhotoBack}>Change back to original photo</button>
    }

    let heading = <div className="settingsComponentHeading">Add Gallery</div>
    let buttons =
      <div className="text-center">
        <button onClick={this.createGallery} className="settingsButton subServicesButton">Add Gallery</button>
      </div>
    if (this.props.service.gallery) {
      heading = <div className="settingsComponentHeading">Update Gallery</div>
      buttons =
        <div>
          <button onClick={this.deleteGallery} className="settingsButton subServicesButton deleteButton">Delete Gallery</button>
          <button onClick={this.updateGallery} className="settingsButton subServicesButton updateGalleryButton">Update Gallery</button>
        </div>
    }

  return(
      <div className = "settingsModalComponent" >
      <div className="settingsModal">
        <button className="projectEditButton projectEditButtonLight modalCloseButton modalCloseButtonHomepage" onClick={() => this.props.closeModal()}>
          Close
        </button>
        {heading}
        <div className="settingsServicesInput">
          <label id="formLabel"> Title </label>
          <input className="formInput" id={agentMlsIdErrorClass} onChange={this.handleInput("title")} type="string" placeholder="Title" value={this.state.title} />
          <ul className="errorUl">{agentMlsIdErrors}</ul>
        </div>
        <div className="settingsServicesInput">
          <label id="formLabel"> Cover </label>
          <div className="galleryPhotoDiv">
            <img className="settingsAvatar settingsGalleryCover" src={this.state.cover_src} />
            <label className="settingsChangePhotoButton coverUploadButton">
              <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg" />
              Change Photo
            </label>
            {cancelPhotoChangeButton}
          </div>
        </div>
        <div className="settingsServicesInput">
          <label id="formLabel"> Photos </label>
          <div className="galleryPhotoDiv galleryPhotoDiv2">
            <div className="galleryImagesGrouping">
              {images}
            </div>
            <label className="settingsChangePhotoButton uploadGalleryPhotosButton">
              <input onChange={this.handlePicturesSelected} type="file" multiple="multiple" accept="image/png, image/jpeg" />
              Upload Photos
            </label>
          </div>
        </div>
        {buttons}
      </div>
    </div>
    );
  }
}