import React from "react";
import { createBundleGroup, updateBundleGroup } from "../../../util/bundle_api_utils";


export default class BundleGroupFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.bundle_group?.id || "",
      name: props.bundle_group?.name || "",
      sort_order: props.bundle_group?.sort_order || "",
      header_image_src: props.bundle_group?.header_image || "",
      hidden: props.bundle_group?.hidden || false,
      loading: false,
      errors: []
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleCoverSelected = this.handleCoverSelected.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.header_image_src)
  }

  handleCheckbox(name) {
    this.setState({ [name]: !this.state[name] })
  }

  handleCoverSelected(event) {
    let cover = event.target.files[0]
    let cover_src = URL.createObjectURL(cover)

    this.setState({
      header_image: cover,
      header_image_src: cover_src
    })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSave() {
    const fields = ['name', 'sort_order', 'hidden']

    let formData = new FormData()

    for (const field of fields) {
      formData.append(`bundle_group[${field}]`, this.state[field])
    }

    if (this.state.header_image) {
      formData.append("bundle_group[header_image]", this.state.header_image)
    }

    const save = this.state.id ? updateBundleGroup(this.state.id, formData) : createBundleGroup(formData)

    this.setState({ loading: true })

    save.then(bundle_group => {
      this.props.closeModal()
      this.props.onSave(bundle_group)
      this.setState({ loading: false })
    }).catch(response => {
      this.setState({ loading: false })
      this.setState({ errors: response.responseJSON || [] })
    })
  }

  render() {
    return (
      <div className="createTermsServiceModalComponent addFormModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Bundle Group' : 'Create Bundle Group'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="propertyTourSectionBody">
            <h5 className="propertyTourSectionTitle">Header Image</h5>
            <div className="propertyTourEditMediaSection">
              <img className="propertyTourEditCoverImage" src={this.state.header_image_src} />
              <div className="propertyTourEditMediaSectionInput">
                <label htmlFor="coverImg" className="propertyTourEditButton">
                  Upload file
                  <input type="file" className="d-none" id="coverImg" accept="image/*"
                    onChange={this.handleCoverSelected} />
                </label>
              </div>
            </div>
          </div>

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Name</label>
              <input className="formInput" onChange={this.onChange} name="name" value={this.state.name} />
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel"> Sort Order </label>
              <input className="formInput" onChange={this.onChange} type="number" name="sort_order" placeholder="Sort Order" value={this.state.sort_order} />
            </div>

            <div className="settingsServicesInput form-group form-group-special">
              <label><input type="checkbox" className="formCheckBox" checked={this.state.hidden} onChange={() => this.handleCheckbox("hidden")} name="hidden" /> <span className="rememberMe">Hidden?</span></label>
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">
            Save
          </button>
        </div>
      </div>
    );
  }
}