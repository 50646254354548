export const sendBookConsultation = (data) => (
  $.ajax({
    type: 'POST',
    url: "/book-consultation",
    data: data
  })
);

export const sendBookConsultationDiscount = (data, captcha) => (
  $.ajax({
    type: 'POST',
    url: "/book-consultation/send_discount",
    data: {...data, captcha}
  })
);

export const sendBookConsultationCallback = (data, captcha) => (
  $.ajax({
    type: 'POST',
    url: "/book-consultation/send_callback",
    data: { ...data, captcha }
  })
);
