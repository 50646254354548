import React, { useState, useRef } from 'react';

import {
  TopSectionWrapper,
  TopSectionInnerWrapper,
  FirstColumn,
  SecondColumn,
  Image1,
  Image2,
  Image3,
  ImageMarker,
} from './styles';

import { TOP_SECTION } from '../../../../../assets/images/aboutPage';

const AboutTopSection = () => {
  return (
    <TopSectionWrapper>
      <TopSectionInnerWrapper>
        <FirstColumn>
          <Image1>
            <ImageMarker
              id={'Tooltip-1'}
              src={TOP_SECTION.ImageSliderMarker}
              alt={''}
            />
          </Image1>
          <Image2 />
        </FirstColumn>
        <SecondColumn>
          <Image3 />
        </SecondColumn>
      </TopSectionInnerWrapper>
    </TopSectionWrapper>
  );
};

export default AboutTopSection;
