import styled from "styled-components";

export const HeaderImage = styled.div`
  width: 100%;
  height: 490px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CareersFaqSectionWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;
  width: 100%;
  background: #1B264F;
`;

export const CareersFaqSectionInnerWrapper = styled.div`
  width: 997px;
  margin: auto;
  
  @media only screen and (max-width: 1280px) {
    width: 760px;
  }
  
  @media only screen and (max-width: 769px) {
    width: 90%;
  }
`;

export const Heading = styled.h2`
  width: 840px;
  margin: 0 auto 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #FFFFFF;
  text-transform: none;
  
  @media screen and (max-width: 799px) {
    font-size: 28px;
    line-height: 28px;
    width: 276px;
    margin: 0 auto 22px;
  }
`;

export const DescriptionText = styled.p`
  width: 730px;
  margin: 0 auto;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  @media screen and (max-width: 799px) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    width: 98%;
    max-width: 350px;
    margin-bottom: 15px;
  }
`;

export const Questions = styled.div`
  margin-top: 90px;
`;

export const Question = styled.div`
  padding-bottom: 35px;
  margin-top: 35px;
  
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);  
  }
`;

export const QuestionTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionBody = styled.div`
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-size: 19px;
  line-height: 140%;
  color: #F5F5F8;
  text-align: justify;
  
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
  
  max-height: 0;
  
  &.opened {
    transition: max-height 1s ease-in-out;
    max-height: 10000px;
  }
`;

export const QuestionBodyInternal = styled.div`
  padding-top: 30px;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
`;

export const QuestionArrow = styled.div`
  height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  cursor: pointer;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  a {
    width: 15px;
    height: 6px;
  }
`;