import {
  ABORT_MEDIA_UPLOADING,
  CLEAR_MEDIA_UPLOADING,
  PROGRESS_MEDIA_UPLOADING,
  START_MEDIA_UPLOADING
} from "../actions/media_upload_actions";

const defaultState = []

const mediaUploadReducer = (state = defaultState, action) => {
  Object.freeze(state);

  const {uploader} = action

  switch (action.type) {
    case START_MEDIA_UPLOADING:
      return [...state, {uploader, ...uploader.state()}]
    case PROGRESS_MEDIA_UPLOADING:
      return state.map(s => uploader === s.uploader ? {...s, ...s.uploader.state()} : s)
    case ABORT_MEDIA_UPLOADING:
      return state.map(s => action.uploader === s.uploader ? {...s, ...s.uploader.abort().state()} : s)
    case CLEAR_MEDIA_UPLOADING:
      return []
    default:
      return state
  }
};

export default mediaUploadReducer;
