import { connect } from "react-redux";
import CreateProjectModal from "./create_project_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { updatePayment, createPayment, fetchPaymentInfo } from "../../actions/settings_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  updatePayment: (paymentInfo, paymentId) => dispatch(updatePayment(paymentInfo, paymentId)),
  createPayment: (paymentInfo) => dispatch(createPayment(paymentInfo)),
  fetchPaymentInfo: (user_id) => dispatch(fetchPaymentInfo(user_id)),
});

export default withRouter(connect(msp, mdp)(CreateProjectModal));