import React from "react";
import Editor from 'react-medium-editor';
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import {createAd, updateAd} from "../../../util/ad_api_utils";


export default class AdFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(this.props.ad || {
        title: '',
        description: '',
        button_text: '',
        ad_type: 'slide_out',
        sendgrid_list_id: '',
        template_id: 'd-7d45a41c90754d5a9c6d54a3dfab13f3',
        coupon_code: '',
        book_shoot_page: false
      }),
      image: '',
      image_src: this.props.ad?.image?.url || '',
      loading: false,
      errors: []
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onPictureChange = this.onPictureChange.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleCheckbox(name) {
    this.setState({ [name]: !this.state[name] })
  }

  onDescriptionChange(text) {
    const emptyText = "<p><br></p>"
    if (text === emptyText) text = ""
    this.setState({description: text});
  }

  onPictureChange(event) {
    const image = event.target.files[0];
    const image_src = URL.createObjectURL(image);

    this.setState({
      image: image,
      image_src: image_src
    });
  }

  onSave() {
    const {title, description, button_text, sendgrid_list_id, ad_type, image, coupon_code, template_id, book_shoot_page} = this.state
    const adParams = new FormData();
    adParams.append("ad[title]", title);
    adParams.append("ad[description]", description);
    adParams.append("ad[button_text]", button_text);
    adParams.append("ad[sendgrid_list_id]", sendgrid_list_id);
    adParams.append("ad[coupon_code]", coupon_code);
    adParams.append("ad[template_id]", template_id);
    adParams.append("ad[ad_type]", ad_type);
    adParams.append("ad[book_shoot_page]", book_shoot_page)
    if (image) adParams.append("ad[image]", image);

    const save = this.state.id ?
      updateAd(this.state.id, adParams) :
      createAd(adParams)

    this.setState({loading: true})
    save.then(ad => {
      this.props.closeModal()
      this.props.onSave(ad)
      this.setState({loading: false})
    }).catch(response => {
      this.setState({loading: false})
      this.setState({errors: response.responseJSON || []})
    })
  }

  render() {
    const types = [
      {label: 'Slide out', value: 'slide_out'},
      {label: 'Popup', value: 'popup'}
    ]
    return (
      <div className="addFormModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Ad' : 'Create Ad'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Title</label>
              <input className="formInput" onChange={this.onChange} name="title" value={this.state.title}/>
            </div>

            <div className="settingsServicesInput form-group">
              <label id="formLabel">Description</label>
              <div className="editorContent">
                <Editor text={this.state.description} onChange={this.onDescriptionChange}/>
              </div>
            </div>

            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Button text</label>
              <input className="formInput" onChange={this.onChange} name="button_text" value={this.state.button_text}/>
            </div>

            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Sendgrid List ID</label>
              <input className="formInput" onChange={this.onChange} name="sendgrid_list_id"
                     value={this.state.sendgrid_list_id}/>
            </div>

            <div className="settingsServicesInput form-group">
              <label id="formLabel">Template ID</label>
              <input className="formInput" onChange={this.onChange} name="template_id" value={this.state.template_id}/>
            </div>

            <div className="settingsServicesInput form-group">
              <label id="formLabel">Coupon Code</label>
              <input className="formInput" onChange={this.onChange} name="coupon_code" value={this.state.coupon_code}/>
            </div>

            <div className="settingsServicesInput form-group form-group-special-ads">
              <label><input type="checkbox" className="formCheckBox" checked={this.state.book_shoot_page} onChange={() => this.handleCheckbox("book_shoot_page")} name="book_shoot_page" /> <span className="rememberMe">Book Shoot Page?</span></label>
            </div>

            <div className="settingsServicesInput form-group">
              <label id="formLabel">Type</label>
              <select className="formInput" onChange={this.onChange} name="ad_type" value={this.state.ad_type}>
                {types.map((type, i) => (<option value={type.value} key={`type-${i}`}>
                  {type.label}
                </option>))}
              </select>
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Image</label>
              <div className="galleryPhotoDiv">
                {this.state.image_src &&
                  <img className="settingsAvatar settingsGalleryCover" src={this.state.image_src}/>}
                <label className="settingsChangePhotoButton coverUploadButton">
                  <input onChange={this.onPictureChange} type="file" accept="image/png, image/jpeg"/>
                  {this.state.image_src ? 'Change' : 'Add image'}
                </label>
              </div>
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">
            Save
          </button>
        </div>
      </div>
    );
  }
}