import React from "react"
import Map from "../map"
import UserAvatar from "../photographers/photograher_avatar"

class EditProjectPostProductionDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = { }
  }

  navigate(address) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address.street1.split(" ").join("+")}%2C${address.city.split(" ").join("+")}%2C${address.region}`)
  }

  render() {
    const { project, currentUser } = this.props

    const packages = Object.values(project?.packages || []).filter(pack => pack.media_types === "video")

    return <div>
      <div className="">
        <div className="editProjectServicesHeaderPostProduction">
          <div className="editProjectServicesTitle">Project Services</div>
        </div>

        <table className="editProjectServicesTable">
          <thead>
            <tr>
              <th>Service</th>
              <th>Package</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pack, i) => {
              return pack.sub_services.map((subService, j) => {
                return <tr key={`sub-service-${i}-${subService.id}`}>
                  <td>{pack.service_title}</td>
                  <td>{subService.title}</td>
                  <td className="row-filler" />
                </tr>
              })
            })}
          </tbody>
        </table>
      </div>
    </div>
  }
}

export default EditProjectPostProductionDetails