import React from 'react'

export const UserDataStep = ({data, setData}) => {
  const onInput = (fieldName) => {
    return (e) => {
      e.preventDefault()
      setData({...data, [fieldName]: e.target.value})
    }
  }

  const fields = [
    {label: 'First name', field: 'firstName', type: 'text', required: true},
    {label: 'Last name', field: 'lastName', type: 'text', required: true},
    {label: 'Email', field: 'email', type: 'email', required: true},
    {label: 'Phone number', field: 'phoneNumber', type: 'text'}
  ]

  return  <div className="apply-for-discount-form">
    {fields.map((field, i) => (
      <div className="apply-for-discount-form-field" key={`field-${i}`}>
        <label id="formLabel" className={`formLabel ${field.required ? 'required' : ''}`}>{field.label}</label>
        <input className="formInput" placeholder="Content" value={data[field.field]}
               onInput={onInput(field.field)} required={field.required} type={field.type}/>
      </div>
    ))}
  </div>
}