import { connect } from "react-redux";
import ContractorCancelModal from "./contractor_cancel_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { cancelAppointments } from "../../actions/project_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  cancelAppointments: (project_id, cancelInfo) => dispatch(cancelAppointments(project_id, cancelInfo)),
});

export default withRouter(connect(msp, mdp)(ContractorCancelModal));