import { connect } from "react-redux";
import CreateService from "./create_service";
import React from "react";
import { withRouter } from "react-router-dom";
import {createService} from "../../../actions/service_actions";
import {closeModal} from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  createService: (service_data) => dispatch(createService(service_data)),
  closeModal: () => { dispatch(closeModal()) },
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(CreateService));