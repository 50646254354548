import React from "react";
import { Route, Link } from "react-router-dom";
import SettingsServiceItem from "./settings_service_item";


export default class SettingsService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
    }
    this.handleInput = this.handleInput.bind(this)
    this.googleCalendarIntegration = this.googleCalendarIntegration.bind(this)
  }

  componentDidMount(){
    this.props.fetchServicesSettings()
  }

  handleInput(field) {
    return e => this.setState({ [field]: !this.state[field] });
  }

  googleCalendarIntegration() {
    const newWindow = window.open("calendars/authorize_access", '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    let settingsServiceItems = []
    settingsServiceItems = this.props.services.map((service, i) => <SettingsServiceItem key={i} service={service} deleteService={this.props.deleteService} openModal={this.props.openModal} addInfo={this.props.addInfo}/>)

    let connectText = <div className="connectCalendarText">Connect your google calendar </div>
    let connectButton = <button className="settingsButton connectNowButton" onClick={this.googleCalendarIntegration}>Connect now</button>

    if (this.props.currentUser.google_calendar) {
      connectText = <div className="connectCalendarText">Your google calendar is connected </div>
      connectButton = <button className="settingsButton connectNowButton" onClick={this.googleCalendarIntegration}>Update</button>
    }

    if (this.props.currentUser.admin_calendar){
      connectText = <div className="connectCalendarText">Admin Calendar is connected </div>
      connectButton = <button className="settingsButton connectNowButton" onClick={this.googleCalendarIntegration}>Update</button>
    }

    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences">
          <div className="settingsComponentHeading">Services</div>
          <div className="settingsPreferencesGroup1">
           {settingsServiceItems}
          </div>
          <button onClick={() => this.props.openModal("createService")} className="settingsButton preferencesButton">Add Service</button>
          <div className="googleIntegration adminGoogleIntegration">
            {connectText}
            {connectButton}
          </div>
        </div>
      </div>
    );
  }
}