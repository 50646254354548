import React from "react";
import VirtualSuiteTypeItem from "./virtual_suite_type_item";
import { fetchVirtualSuiteTypes } from "../../util/virtual_staging_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";


export default class VirtualStagingCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      virtual_suite_types: []
    }
    this.toggleSelected = this.toggleSelected.bind(this);


  }

  componentDidMount() {
    this.setState({ loading: true })
    fetchVirtualSuiteTypes().then(virtual_suite_types => this.setState({ virtual_suite_types: virtual_suite_types, loading: false }))
  }

  toggleSelected(virtual_suite_type) {
    if (this.props.virtual_suite_type?.id !== virtual_suite_type) {
      this.props.changeParentState({ virtual_suite_type: virtual_suite_type })
    } else {
      this.props.changeParentState({ virtual_suite_type: null })
    }
    this.props.changeParentState({ category: null, product: null })
  }

  render() {

    if (this.state.loading) {
      return <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }

    let virtualSuiteTypeCards = this.state.virtual_suite_types.map((type, i) => <VirtualSuiteTypeItem key={i} virtual_suite_type={type} selected={this.props.virtual_suite_type?.id === type.id} toggleSelected={this.toggleSelected} index={i} />)
    
    return (
      <div>
        <div className="modalTitle">What kind of virtual improvements are you looking for?</div>
        <div className="row mt-3 mx-0 virtualStagingCategoryGrid">
          {virtualSuiteTypeCards}
        </div>
      </div>
    );
  }
}