import React, {useState} from 'react'
import {Footer} from "../footer";

export const BusinessDetails = (props) => {
  const [data, setData] = useState(props.data || {})

  const onChange = (fieldName) => {
    return (e) => {
      e.preventDefault()
      setData({...data, [fieldName]: e.target.value})
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    props.onNext(data)
  }

  const fields = [
    {
      field: 'periodInRealEstate',
      label: 'How long have you been in the real estate industry?',
      required: true
    },
    {
      field: 'listingsPerMonth',
      label: 'How many listings do you typically manage per month?',
      required: true
    },
    {
      field: 'specialization',
      label: 'Do you specialize in residential or commercial real estate?',
      required: true
    }
  ]

  return <form onSubmit={onSubmit}>
    <div className="schedule-demo-title">
      Details about your business.
    </div>

    <div className="schedule-demo-form-fields schedule-demo-business-details-form-fields">
      {fields.map(field => (
        <div className="schedule-demo-form-field" key={field.field}>
          <label className={`schedule-demo-business-info-form-field-label formLabel ${field.required ? 'required' : ''}`}>
            {field.label}
          </label>

          <input value={data[field.field]} onChange={onChange(field.field)}
                 className="formInput" placeholder="Content" required={field.required}/>
        </div>
      ))}
    </div>

    <Footer onBack={props.onBack}/>
  </form>
}