import { withRouter } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import notificationBellIcon from "../../../assets/images/notification-bell";
import notificationBellWithAlertIcon from "../../../assets/images/notification-bell-with-alert";
import NotificationsModal from "./dashboard/notifications_modal";
import { fetchNotificationCount } from "../util/session_api_util";

const NotificationBell = (props) => {

  if (props.currentUser && !props.currentUser.roles.post_production){

  useEffect(() => {
    const fetchNotifications = () => {
      props.fetchNotificationCount(props.currentUser.id); // Assuming you have the currentUser.id available
    };

    const intervalId = setInterval(fetchNotifications, 300000); // Fetch notifications every 5 minutes

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        fetchNotifications(); // Call the function to fetch notifications
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Fetch notifications when the component mounts
    fetchNotifications();

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [props.fetchNotificationCount, props.currentUser.id]);

  const [modalOpen, setModalOpen] = useState(false)
  const notificationBell = props.currentUser.unread_notifications > 0 ? notificationBellWithAlertIcon : notificationBellIcon
  const notificationAmount = props.currentUser.unread_notifications

  return (
      <div className="d-flex">
      <NotificationsModal currentUser={props.currentUser} fetchNotificationCount={props.fetchNotificationCount} unread_notification_count={props.currentUser.unread_notifications} modalOpen={modalOpen} closeModal={() => setModalOpen(false)}/>
      <div className="notificationBellGroup" onClick={() => setModalOpen(true)}>
          <img className="notificationBell" src={notificationBell}></img>
          <div className="notificationDescription">{notificationAmount} Notifications</div>
        </div>
        <div className="notificationBellGroupMobile" onClick={() => setModalOpen(true)}>
          <img className="notificationBell" src={notificationBell}></img>
        </div>
    </div>
  )
  } else {
    return (
      <div></div>
    )
  }
}

export default withRouter(NotificationBell);