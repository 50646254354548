import React from 'react';

import {
  FormSectionContainer,
  FormSectionWrapper,
  FormSectionHeading,
  FormSectionHeadingColor,
  LineBreak,
} from './styles';
import { ContactForm } from '../../pageComponents';

const FormSection = () => {
  return (
    <FormSectionContainer>
      <FormSectionWrapper>
        <FormSectionHeading>
          Contact Our Team 
        </FormSectionHeading>
        <LineBreak />
        <ContactForm />
      </FormSectionWrapper>
    </FormSectionContainer>
  );
};

export default FormSection;
