import React from 'react';
import { withRouter } from 'react-router-dom';
import NavbarLoggedIn from './navbar_logged_in';
import NavbarLoggedOut from './navbar_logged_out';
import NavbarLoggedInMobile from './navbar_logged_in_mobile';
import NavbarLoggedOutMobile from './navbar_logged_out_mobile';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLargeScreen: true,
      isLargeScreenWebsite: true,
      isLargeScreenHeight: true,
      isLargeScreenNavbarWidth: true
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isLargeScreenWebsite: window.outerWidth > 1100, isLargeScreen: window.innerWidth > 750, isLargeScreenHeight: window.innerHeight > 1000, isLargeScreenNavbarWidth: window.innerWidth > 1375 });
  }

  render() {
    const { currentUser } = this.props;

    if (currentUser && this.state.isLargeScreen && !this.props.nonAppPage) {
      return (
        <NavbarLoggedIn
          currentUser={this.props.currentUser}
          isLargeScreenHeight={this.state.isLargeScreenHeight}
          isLargeScreen={this.state.isLargeScreen}
          isLargeScreenNavbarWidth={this.state.isLargeScreenNavbarWidth}
          logout={this.props.logout}
        />
      );
    }
    if (currentUser && !this.state.isLargeScreen && !this.props.nonAppPage) {
      // if (this.props.location.pathname === '/settings') {
      //   return <div></div>;
      // } else {
        return (
          <NavbarLoggedInMobile
            currentUser={this.props.currentUser}
            logout={this.props.logout}
          />
        );
      // }
    }
    if ((!currentUser || this.props.nonAppPage) && !this.state.isLargeScreenWebsite) {
      return (
        <NavbarLoggedOutMobile
          currentUser={this.props.currentUser}
          login={this.props.login}
          setModal={this.props.setModal}
          nonAppPage={this.props.nonAppPage}
          removeNavbar={this.props.removeNavbar}
          disableWaveBackground={this.props.disableWaveBackground}
          navbarHideMiddleButtons={this.props.navbarHideMiddleButtons}
          
        />
      );
    }
    return <NavbarLoggedOut login={this.props.login} removeNavbar={this.props.removeNavbar}
                            nonAppPage={this.props.nonAppPage} setModal={this.props.setModal}
                            currentUser={this.props.currentUser}
                            navbarHideMiddleButtons={this.props.navbarHideMiddleButtons}
                            disableWaveBackground={this.props.disableWaveBackground}/>;
  }
}

export default withRouter(Navbar);
