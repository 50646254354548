import styled from 'styled-components';

export const ContractMediaSlide2Part1 = styled.img`
  position: absolute;
  width: 75.5%;
  top: 54%;
  left: 12%;
`;

export const ContractMediaSlide2Part2 = styled.img`
  position: absolute;
  width: 102%;
  top: 3%;
  left: -2%;
  z-index: 2;
`;

export const ContractMediaSlide2Part3 = styled.img`
  position: absolute;
  width: 33%;
  top: 47%;
  left: 53%;
  z-index: 2;
`;

export const ContractMediaSlide2Part4 = styled.img`
  position: absolute;
  width: 32%;
  top: 13%;
  left: 16%;
  z-index: 2;
`;

export const ContractMediaSlide2Part5 = styled.img`
  position: absolute;
  width: 76%;
  height: 85%;
  top: 3%;
  left: 12%;
  z-index: -2;
`;
