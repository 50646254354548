import { fetchAds } from "../../../util/ad_api_utils";


export default class EmailForm {
  static get DEFAULT_PLACEHOLDER() {
    return 'Text..';
  }

  constructor({data, config, api, readOnly}) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block
    };

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
    }

    this._placeholder = config.placeholder ? config.placeholder : EmailForm.DEFAULT_PLACEHOLDER;
    this._data = {};
    this._element = this.drawView();
    this._content = this._element.querySelector('[contenteditable]');
    this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

    this.data = {
      text: data.text || '', // Initialize 'text' property (use default if not provided)
      adId: data.adId || null, // Initialize 'adId' property (use null if not provided)
    };
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const {textContent} = this._content;

    if (textContent === '') {
      this._content.innerHTML = '';
    }
  }

  drawView() {
    // let bigger_wrapper = this.createElement('DIV', [])
    let wrapper = this.createElement('DIV', ['ce-email-form'])
    let container = this.createElement('DIV', ['ce-email-form-container'])
    let image = this.createElement('DIV', ['ce-email-form-block', 'ce-email-form-image'])
    let text = this.createElement('DIV', ['ce-email-form-block', 'ce-email-form-block-text'])
    let inputWrapper = this.createElement('DIV', ['ce-email-form-block']);
    let input = this.createElement('INPUT', []);
    let adDropdown = this.createAdDropdown(); // Create the ad dropdown element
    let button = this.createElement('BUTTON', ['ce-email-form-block', 'projectEditButton', 'projectEditButtonWhite'])

    button.innerText = 'Submit'
    inputWrapper.append(input)
    container.append(adDropdown, image, text, inputWrapper, button)
    wrapper.append(container)
    // bigger_wrapper.append(container, adDropdown)

    text.contentEditable = false;
    text.dataset.placeholder = this.api.i18n.t(this._placeholder);
    input.placeholder = 'Email address';

    if (!this.readOnly) {
      text.contentEditable = true;
      text.addEventListener('keyup', this.onKeyUp);
      // adDropdown.addEventListener('keyup', this.onKeyUp);
    }


    return wrapper;
  }

  createAdDropdown() {
    let select = this.createElement('SELECT', ['ce-email-form-block']);

    select.addEventListener('change', () => {
      this.data.adId = select.value;
    });

    let defaultOption = this.createElement('OPTION', []);
    defaultOption.text = 'Select an Ad'; // Set a default option
    defaultOption.disabled = true;
    defaultOption.selected = true;
    select.appendChild(defaultOption);

    fetchAds() // Use the fetchAds function to fetch ads
      .then(ads => {
        for (let ad of ads) {
          let option = this.createElement('OPTION', []);
          option.text = ad.title; // Assuming each ad has a 'name' property
          option.value = ad.id; // Assuming 'id' is a unique identifier for each ad
          select.appendChild(option);
        }
        return select
      })

    return select;
  }

  render() {
    return this._element;
  }

  merge(data) {
    let newData = {
      text: this.data.text + data.text
    };

    this.data = newData;
  }

  validate(savedData) {
    if (savedData.text.trim() === '' && !this._preserveBlank) {
      return false;
    }

    return true;
  }

  save(toolsContent) {
    return {
      text: toolsContent.querySelector('[contenteditable]').innerHTML,
      adId: this.data.adId
    };
  }

  onPaste(event) {
    const data = {
      text: event.detail.data.innerHTML
    };

    this.data = data;
  }

  static get conversionConfig() {
    return {
      export: 'text',
      import: 'text'
    };
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      }
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  get data() {
    let text = this._content.innerHTML;

    this._data.text = text;

    return this._data;
  }

  set data(data) {
    this._data = data || {};

    this._content.innerHTML = this._data.text || '';
  }

  static get pasteConfig() {
    return {
      tags: ['P']
    };
  }

  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <rect x="3" y="5" width="18" height="14" rx="2" />
  <polyline points="3 7 12 13 21 7" />
</svg>`,
      title: 'Email Form'
    };
  }

  createElement(nodeName, classes, attributes = {}) {
    const node = document.createElement(nodeName);
    for (let className of classes) node.classList.add(className)
    for (let attributeKey of Object.keys(attributes)) node.setAttribute(attributeKey, attributes[attributeKey])
    return node
  }
}