import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import EditProjectMain from "./edit_project/edit_project_main_container";
import NavbarContainer from "./navbar/navbar_container";

class EditProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLargeScreen: true
    }

    this.updatePredicate = this.updatePredicate.bind(this);
  }


  componentDidMount() {
    document.body.style.overflow = 'unset';
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  render() {
    return (
      <div className="dashboardFullPageDiv">
        <NavbarContainer />
        <EditProjectMain isLargeScreen={this.state.isLargeScreen} />
      </div>
    )
  }
}

export default withRouter(EditProject);