import React from "react";

class ScrollableImage extends React.Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef();
  }

  render() {
    const {currentImage, images, name} = this.props;

    const width = this.containerRef.current?.offsetWidth || 0

    return <div ref={this.containerRef} id={this.props.floorplans ? "" : "propertyTourScrollableImageNonFloorplanContainer"} className="propertyTourScrollableImageContainer">
      <div className="scrollableImage" style={{left: `${-currentImage * width}px`} }>
        {images.map((image) => <img src={image.highend_url} key={`${name}-scrollable-image-${image.id}`} />)}
      </div>
    </div>
  }
}

export default ScrollableImage