import React from "react";

const Examples = ({examplesByService, setExamplesByService}) => {

  const value = (slug) => {
    return examplesByService[slug].website || ''
  }

  const onChange = (e, slug) => {
    e.preventDefault()
    examplesByService[slug].website = e.target.value
    setExamplesByService({...examplesByService})
  }

  return <>
    <h3 className="h3React">Please provide some examples of your work.</h3>
    <div className="description">Applicants must have their own equipment to use on projects.</div>

    <div className="inputs-list">
      {Object.keys(examplesByService).map(slug => {
        return <div key={`service-${slug}`} className="inputs-groups">
          <div className="input-title">{examplesByService[slug].service.title}</div>
          <div key={`service-${slug}-website`} className="input-field">
            <label className="formLabel required">Website / Link</label>
            <input type="text" value={value(slug)} className="formInput" required={true}
                   onChange={e => onChange(e, slug)} placeholder="Content"/>
          </div>
        </div>
      })}
    </div>
  </>
}

export default Examples
