import * as APIUtil from "../util/review_api_util";


export const RECEIVE_ALL_REVIEWS = "RECEIVE_REVIEWS";


export const receiveReviews = (reviews) => {return {
  type: RECEIVE_ALL_REVIEWS,
  reviews
}};

export const fetchReviews = () => dispatch => {
  return APIUtil.fetchAllReviews().then(
    reviews => dispatch(receiveReviews(reviews)))
};