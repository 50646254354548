import React from "react";
import {withRouter} from "react-router-dom";
import aerial from "../../../../assets/images/icons/service/DroneService.svg"
import floorplans from "../../../../assets/images/icons/service/FloorPlansService.svg"
import other from "../../../../assets/images/icons/service/OtherService.svg"
import photography from "../../../../assets/images/icons/service/PhotographyService.svg"
import video from "../../../../assets/images/icons/service/VideoServiceCreate.svg"
import virtualStaging from "../../../../assets/images/icons/service/StagingService.svg"
import virtualTour from "../../../../assets/images/icons/service/TourService.svg"
import aerialSelected from "../../../../assets/images/icons/service/DroneIconActive.svg"
import floorplansSelected from "../../../../assets/images/icons/service/FloorPlanIconActive.svg"
import photographySelected from "../../../../assets/images/icons/service/PhotoIconActive.svg"
import videoSelected from "../../../../assets/images/icons/service/VideoIconActive.svg"
import virtualStagingSelected from "../../../../assets/images/icons/service/StagingIconActive.svg"
import virtualTourActive from "../../../../assets/images/icons/service/TourIconActive.svg"
import otherActive from "../../../../assets/images/icons/service/OtherIconActive.svg"
import twilightSelected from "../../../../assets/images/icons/service/TwilightServiceActive.svg"
import twilight from "../../../../assets/images/icons/service/TwilightServiceCreate.svg"
import {ReactComponent as CheckMark} from "../../../../assets/images/icons/icon-check.svg";


class ServiceItem extends React.Component {
  constructor(props) {
    super(props);
    this.updateServiceSelection = this.updateServiceSelection.bind(this)
  }

  serviceIcon(slug) {
    const icons = {
      aerial,
      floorplans,
      video,
      photography,
      twilight,
      'virtual-staging': virtualStaging,
      'virtual-tour': virtualTour,
    }
    return icons[slug] || other
  }

  selectedServiceIcon(slug) {
    const icons = {
      'aerial': aerialSelected,
      "floorplans": floorplansSelected,
      "video": videoSelected,
      "twilight": twilightSelected,
      "photography": photographySelected,
      'virtual-staging': virtualStagingSelected,
      "virtual-tour": virtualTourActive
    }
    return icons[slug] || otherActive
  }

  updateServiceSelection() {
    if (!this.props.selected) {
      if (this.props.zipcode.length === 5) {
        this.props.changeParentState({selected_service: this.props.service.id, currentlySelectedService: this.props.service})
        this.props.scrollTo('#scrollToHere')
      } else {
        this.props.changeParentState({ selected_service: this.props.service.id, currentlySelectedService: this.props.service })
        this.props.scrollTo('#scrollToHere')
        // this.props.changeParentState({errors: ["Zipcode is required before selecting a service"]})
      }
    }
  }

  subServices() {
    if (!this.props.selectedSubservices) {
      return ''
    }

    return this.props.selectedSubservices.map((subservice, i) => (
      <div className="serviceSubService">{subservice.title}: ${this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price || 0}</div>
    ))
  }

  render() {
    const service = this.props.service
    const selected = this.props.selected || this.props.previouslySelected
    if (!service.black_icon) service.black_icon = ""
    const icon = this.props.selected ? this.selectedServiceIcon(service.slug) : this.serviceIcon(service.slug)

    return (
      <div onClick={this.updateServiceSelection}
           className={`serviceItem ${this.props.cancelled ? 'serviceItemCancelled' : ''} ${selected ? 'serviceItemSelected' : ''}`} id={`service_${service.id}`} >
        {selected && <div className="checkIcon2">
          <CheckMark/>
        </div>}

        <img src={icon} />

        <div
          className={`serviceItemTitle ${(selected || this.props.cancelled) ? 'serviceItemTitleDark' : ''}`}>
          {service.title}
          <div className="serviceSubServices">{this.subServices()}</div>
        </div>
      </div>
    )
  }
}

export default withRouter(ServiceItem);