import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import debounce from 'debounce'

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: true,
      referrer: this.props.location?.state?.referrer && this.props.location?.state?.referrer != "/settings" ? this.props.location?.state?.referrer : "/dashboard",
      errors: []
    };

    this.handleInput = this.handleInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.sendToCreateAccount = this.sendToCreateAccount.bind(this)
    this.sendToForgotPassword = this.sendToForgotPassword.bind(this)
    this.sendToConfirmation = this.sendToConfirmation.bind(this)
    this.keyPress = this.keyPress.bind(this)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyPress, false);

    if (this.props.currentUser){
      this.props.history.push("/dashboard")
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress, false);
  }

  keyPress(event) {
    if (event.keyCode === 13) {
      this.submitForm()
    }
  }


  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handleCheckbox(){
    this.setState({rememberMe: !this.state.rememberMe})
  }

  sendToConfirmation(){
    this.props.clearErrors()
    this.props.history.push("/resend-confirmation")
  }

  sendToCreateAccount(){
    this.props.clearErrors()
    this.props.history.push("/signup")
  }

  sendToForgotPassword(){
    this.props.clearErrors()
    this.props.history.push("/forgot")
  }

  submitForm(){
    this.setState({ errors: [] })

    let errors = []

    if (this.state.email === ""){
      errors.push([1,"Email field can't be blank."])
    }

    if (this.state.password === ""){
      errors.push([2,"Password field can't be blank"])
    }

    if (errors.length > 0){
      this.setState({errors: errors})
    } else {
      let user = {email: this.state.email, password: this.state.password, remember_me: this.state.rememberMe}
      this.props.login(user).then(() => this.props.errors.length === 0 ? this.props.history.push(this.state.referrer) : ""
    )
   }
  }

  render() {
    let passwordErrorClass = "";
    let emailErrorClass = "";

    let passwordErrors = [];
    let emailErrors = [];
    let backendErrors = [];
    let successErrors = [];


    this.state.errors.forEach((error) => {

      if (error[0] === 1){
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }

      else if (error[0] === 2) {
        passwordErrors.push(<li>{error[1]}</li>)
        passwordErrorClass = "formInputError"
      }
      
    })

    this.props.errors.forEach((error) => {
      if (error === "Account has been confirmed." || error === "Password Reset email has been sent."){
        successErrors.push(<li>{error}</li>)
      } else {
      backendErrors.push(<li>{error}</li>)
      }
    })

    return (
      <div className="loginForm">
        <div className="loginFormContent">
          <h3 className="h3React">Sign In</h3>
          <ul className="backendErrorUl">{backendErrors}</ul>
          <ul className="successErrorUl">{successErrors}</ul>
          <label id="formLabel"> Email </label>
          <input className="formInput" id={emailErrorClass} onChange={this.handleInput("email")} type="email" placeholder="Email address" value={this.state.email} />
          <ul className="errorUl">{emailErrors}</ul>
          <label id="formLabel"> Password </label>
          <input className="formInput" id={passwordErrorClass} onChange={this.handleInput("password")} type="password" placeholder="Password" value={this.state.password} />
          <ul className="errorUl">{passwordErrors}</ul>
          <div className="extraFormElements">
            <label><input type="checkbox" className="formCheckBox" checked={this.state.rememberMe} onChange={this.handleCheckbox}/> <span className="rememberMe">Remember me </span></label>
            <div className="formLink float-right" onClick={this.sendToForgotPassword}>Forgot password</div>
          </div>
          <button className="formSubmitButton loginFormSubmitButton" onClick={this.submitForm}>Log in</button>
          {/* <button className="createAccountButton" onClick={this.sendToCreateAccount}>Create Account</button> */}
          {/* <div className="formSentence">Didn't get your verification email? <span className="formLink" onClick={this.sendToConfirmation}>Click here</span></div> */}
        </div>
      </div>
    )
  }
}

export default withRouter(LoginForm)