import React from "react";
import Toast from "./toast";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class Toasts extends React.PureComponent {
  render() {
    const {toasts} = this.props

    return <div className="toast-container">
      {(toasts || []).map(toast => {
        return <Toast toast={toast} key={toast.id}/>
      })}
    </div>
  }
}

const msp = (state) => ({
  toasts: state.entities.toasts
})

export default connect(msp)(Toasts);
