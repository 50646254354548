import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showToast } from "../../actions/toast_actions";
import { sendInvites, sendPhotographerInvites } from "../../util/contacts_api_utils";
import debounce from 'debounce'


const InviteModal = (props) => {
  const [emails, setEmails] = useState("")

  const validateEmail = (email) => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  const sendEmailInvites = () => {
    let email_array = emails.split(",").map(email => email.trim()).filter(email => email !== '')
    let error = ""

    if (email_array.length === 0) {
      error = "Email field can't be blank"
    }

    email_array.forEach(email => {
      if (!validateEmail(email)) {
        error = "One or more emails are invalid"
      }
    })

    if (error != "") {
      props.showToast(error)
    } else {
      if (props.photographerInvites){
        sendPhotographerInvites(email_array).then(emails => {
          props.showToast("Invites successfully sent")
          props.setInviteModal(false)
        })
      } else {
      sendInvites(email_array).then(emails => {
        props.showToast("Invites successfully sent")
        props.setInviteModal(false)
      })
     }
    }
  }

  return(
  <div className="modal-background" id="">
    <div className={`orderpreviewModal addServiceModal`}>
      <div className="orderPreviewModalComponent">
        <div className="addServiceModalContent inviteModalContent">
            <div className="projectLocationComponent">
              <div className="projectLocationHeadingGrouping">
                <div className="projectLocationHeadingMinimizedGrouping">
                  <div className="settingsComponentHeading chooseYourServiceText">{props.photographerInvites ? "Invite Photographers" : "Invite New Users and Get Rewarded"}</div>
                </div>
              </div>
            </div>
            <div className="emailInputsDiv">
              <div className="">
                <label id="shareMediaFormLabel"> Invite multiple emails </label>
                <input className="formInput shareMediaFormInput" onChange={(e) => setEmails(e.currentTarget.value)} type="string" placeholder="Add emails seperated by commas" value={emails} />
              </div>
            </div>
            <div className="modalFooter modalFooterShareMedia">
              <div className="modalFooterButtons">
                <button className="projectEditButton modalButtonSecondary" onClick={() => props.setInviteModal(false)}>
                  Cancel
                </button>
                <button className="projectEditButton projectEditButtonPrimary" onClick={sendEmailInvites}>
                  Send Invites
                </button>
              </div>
            </div>
            {/* <div className="inviteButtonDiv">
              <button className="signupFormNext modalButtonSpecial" onClick={sendEmailInvites}>
                Send Invites
              </button>
            </div> */}
        </div>
      </div>
      <div className="modalMobilePushup"></div>
    </div>
  </div>
  )
}

const msp = (state) => (
  {
    currentUser: state.entities.users[state.session.currentUser.id]
  }
)

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(InviteModal))