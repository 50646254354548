import React from "react"
import debounce from 'debounce'
import VirtualStagingModal from "../virtual_staging/virtual_staging_main_container"
import ReviewModal from "./review_modal_container"
import {fetchProject, updateProject} from "../../util/project_api_util"
import PageHeader from "../page_header"
import vsIconSrc from '../../../../assets/images/icons/vs-icon.svg'
import propertyTourIconSrc from '../../../../assets/images/icons/grid-three.svg'
import DownloadModal from "./download_modal_container"
import EditProjectClientDetails from "./edit_project_client_details"
import {withRouter} from "react-router-dom"
import AddServiceModal from "./add_service_modal_container"
import ContractorCancelModal from "./contractor_cancel_modal_container"
import EditProjectAddressModal from "./edit_project_address_modal"
import ContractorCalendar from "../create_project/contractor_calendar_component_container"
import EditProjectMedia from "./edit_project_media"
import DeleteProjectModal from "./delete_cancel_modal"
import EditProjectContractorDetails from "./edit_project_contractor_details"
import EditProjectAdminDetails from "./edit_project_admin_details"
import PhotographerSelectionComponent from "../create_project/photographer_selection_component"
import ChangeServiceModal from "./change_service_modal"
import ChangeBundleModal from "./change_bundle_modal"
import NoteModal from "./note_modal"
import VirtualStagingNoteModal from "./virtual_staging_note_modal"
import ChooseTimeModal from "../create_project/choose_time_modal";
import { fetchTimeSlots } from "../../util/settings_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { setReviewShown } from "../../util/project_api_util"
import EditProjectPostProductionDetails from "./edit_project_post_production_details"
import { detectTimezone } from "../../util/timezone_utils";

class EditProject extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      project: null,
      photographer: {},
      appointment_time: "",
      appointments: {},
      modal: null,
      subModal: null,
      waiting: false,
      contractorSearch: "",
      contractorTimeSearch: "",
      contractorDateSearch: "",
      selected_service: "",
      twilight_time: "",
      photographerModalOpen: false,
      chooseTimeModalOpen: false,
      loadingModalOpen: false,
      time_slots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} },
      zip_code: "",
      detected_timezone: "",
    }

    this.handler = this.handler.bind(this)
    this.reloadProject = this.reloadProject.bind(this)
    this.openReviewModal = this.openReviewModal.bind(this)
    this.openVirtualStagingModal = this.openVirtualStagingModal.bind(this)
    this.openDownloadModal = this.openDownloadModal.bind(this)
    this.openAddServiceModal = this.openAddServiceModal.bind(this)
    this.openChangeServiceModal = this.openChangeServiceModal.bind(this)
    this.openCancelModal = this.openCancelModal.bind(this)
    this.openContractorCancelModal = this.openContractorCancelModal.bind(this)
    this.openContractorCancelAdminModal = this.openContractorCancelAdminModal.bind(this)
    this.openEditAddressModal = this.openEditAddressModal.bind(this)
    this.openAppointmentEditModal = this.openAppointmentEditModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openNoteModal = this.openNoteModal.bind(this)
    this.openVirtualStagingNoteModal = this.openVirtualStagingNoteModal.bind(this)
    this.rescheduleAppointmentTime = this.rescheduleAppointmentTime.bind(this)
    this.debounceFetchContractors = debounce((service, zip, search, date, time, services, appointments, timezone) => this.props.fetchContractors(service, zip, search, date, time, services, appointments, timezone), 500)
  }

  componentDidMount() {
    this.reloadProject().then(project => {

      if (!project){
        this.props.history.push("/")
      }

      const photographers = {}
      project.photographers.forEach(photographer => photographers[photographer.id] = true)

      if (this.props.currentUser.id !== project.client_id && this.props.currentUser.roles.client) {
        this.props.history.push("/")
      } else if (this.props.currentUser.roles.contractor && !photographers[this.props.currentUser.id]) {
        this.props.history.push("/")
      }

      detectTimezone(project.address?.latitude, project.address?.longitude).then(detected_timezone => {
        this.setState({ detected_timezone: detected_timezone })
      })

      if (this.props.currentUser.id === project.client_id) {
        Object.values(project.packages).forEach(current_package => {
          if (current_package.contractor_id == null && current_package.appointment_time && new Date(current_package.appointment_time) > new Date()) {
            this.setState({rescheduleModal: true})
          }
        })
      }

      if (this.props.match.params.star_rating) {
        this.openReviewModal({ id: this.props.match.params.contractor_id, review_given: { value: parseInt(this.props.match.params.star_rating) } })
      }

      if (project.status === "complete" && !project.review_shown && this.props.currentUser.id === project.client_id && project.photographers.length > 0 && !project.photographers[0].review_given) {
        setTimeout(() => {
          this.openReviewModal(project.photographers[0]);

          let data = {
            id: project.id,
            project: { id: project.id, review_shown: true }
          };
          setReviewShown(data);
        }, 2000);
      }
    })

    if (this.props.match.params.star_rating){
      photographer = project.photographers.find(photographer => photographer.id = this.props.match.params.contractor_id)
      this.openReviewModal({id: photographer.id, first_name: photographer.first_name, review_given: photographer.review_given || {value: parseInt(this.props.match.params.star_rating)}})
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.contractorSearch !== this.state.contractorSearch) {
      this.debounceFetchContractors(this.state.selected_service, this.state.zip_code, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch)
    }

    if (((prevState.contractorDateSearch !== this.state.contractorDateSearch || prevState.contractorTimeSearch !== this.state.contractorTimeSearch) && !this.state.waiting)) {
      this.setState({ waiting: true })
      this.props.fetchContractors(this.state.selected_service, this.state.zip_code, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch, null, null, this.state.project?.client?.timezone).then(() => { this.setState({ waiting: false }) })
    }
  }

  openReviewModal(photographer) {
    const modal = <ReviewModal reviewable_id={photographer.id} photographer_first_name={photographer.first_name} review={photographer.review_given}
                               onClose={this.closeModal} onReloadProject={this.reloadProject}/>
    this.setState({modal})
  }

  openVirtualStagingModal(e) {
    e.preventDefault()
    const modal = <VirtualStagingModal isLargeScreen={this.props.isLargeScreen} project={this.state.project}
                                       onClose={this.closeModal} onReloadProject={this.reloadProject}/>
    this.setState({modal})
  }

  openDownloadModal() {
    // e.preventDefault()
    const modal = <DownloadModal project={this.state.project} onClose={this.closeModal}/>
    this.setState({modal})
  }

  openAddServiceModal(e) {
    e.preventDefault()
    const modal = <AddServiceModal project={this.state.project} onClose={this.closeModal}
                                   isLargeScreen={this.props.isLargeScreen}/>
    this.setState({modal})
  }

  openNoteModal(){
    const modal = <NoteModal project={this.state.project} changeParentState={this.handler} onClose={this.closeModal} onReloadProject={this.reloadProject}/>
    this.setState({modal})
  }

  openVirtualStagingNoteModal(virtual_staging_purchase, virtual_staging_photo){
    const modal = <VirtualStagingNoteModal virtual_staging_purchase={virtual_staging_purchase} virtual_staging_photo={virtual_staging_photo} changeParentState={this.handler} onClose={this.closeModal} onReloadProject={this.reloadProject} />
    this.setState({ modal })
  }

  openChangeServiceModal(active_service){
    const modal = <ChangeServiceModal currentUser={this.props.currentUser} active_service={active_service} project={this.state.project} onClose={this.closeModal}
      isLargeScreen={this.props.isLargeScreen} />

    this.setState({modal})
  }

  openChangeBundleModal(){
    const modal = <ChangeBundleModal currentUser={this.props.currentUser} project={this.state.project} onClose={this.closeModal}
      isLargeScreen={this.props.isLargeScreen} />

    this.setState({ modal })
  }

  openContractorCancelModal(e) {
    e.preventDefault()
    const modal = <ContractorCancelModal project={this.state.project}
                                         onClose={this.closeModal}/>
    this.setState({modal})
  }

  openContractorCancelAdminModal(contractor_id){
    const modal = <ContractorCancelModal project={this.state.project}
      onClose={this.closeModal} contractor_id={contractor_id}/>
    this.setState({ modal })
  }

  openCancelModal(e) {
    e.preventDefault()
    const modal = <DeleteProjectModal project={this.state.project} onClose={this.closeModal}
                                      showToast={this.props.showToast} currentUser={this.props.currentUser} fetchCurrentUser={this.props.fetchCurrentUser} onCancelled={() => this.props.history.push("/projects_index")}/>
    this.setState({modal})
  }

  openEditAddressModal(e) {
    e.preventDefault()

    const onSubmit = (project) => {
      this.onProjectChange(project).then(() => {
        this.closeModal()
        this.reloadProjectNewSlug()
      })
    }

    const modal = <EditProjectAddressModal project={this.state.project}
                                           onClose={this.closeModal}
                                           onSubmit={onSubmit}/>
    this.setState({modal})
  }

  openAppointmentEditModal(e, photographer, packages, packa) {
    e.preventDefault()
    const {project} = this.state
    const serviceId = packa.service_id
    const old_appointment = packa.appointment_time

    const onChangeAppointmentTime = (appointment_time, photographer) => {
      Object.values(project.packages).forEach(pack => {
        if (pack.contractor_id === photographer.id && old_appointment === pack.appointment_time){
          pack.appointment_time = appointment_time
        }
      })
      this.onProjectChange(project).then(this.closeModal)
    }

    const onChangeAppointmentTimeNoContractor = (appointment_time, photographer) => {
      if (photographer && appointment_time){
        let photographer_service_ids = {}

        photographer.services.forEach(service => {
          photographer_service_ids[service.id] = service
        })

        Object.values(project.packages).forEach(pack => {
          if (packa.id === pack.id || photographer_service_ids[pack.service_id] && photographer_service_ids[pack.service_id].twilight === photographer_service_ids[packa.service_id].twilight && pack.contractor_id === null) {
            pack.appointment_time = appointment_time
            pack.contractor_id = photographer.id
          }
        })
        this.setState({loadingModalOpen: true})
        this.onProjectChange(project).then(() => {
          this.reloadProject()
          this.setState({ loadingModalOpen: false, photographerModalOpen: false })
        })
      }
    }

    let modal = "";

    if (photographer){

    const appointments = packages.reduce((res, pack) => {
      res[pack.service_id] = { appointment_time: new Date(pack.appointment_time), contractor: photographer }
      return res
    }, {})

    modal = <ContractorCalendar photographer={photographer}
                                setAppointmentTime={onChangeAppointmentTime}
                                detected_timezone={this.state.detected_timezone}
                                appointments={appointments}
                                project={project}
                                selectedService={serviceId}
                                changeSelected={this.closeModal}
                                street={project?.address?.street1}
                                updating={true}
                                isLargeScreen={this.props.isLargeScreen}/>

    this.setState({modal: modal})
    }
    else{
      const appointments = packages.reduce((res, pack) => {
        res[pack.service_id] = { appointment_time: new Date(pack.appointment_time) }
        return res
      }, {})

      const included_services = project.services.reduce((res, service) => {
        res[service.id] = service
        return res
      }, {})

      fetchTimeSlots(true).then(time_slots => {
        this.setState({ twilight_time: time_slots })
      })

      this.props.fetchContractors(serviceId, project.address?.zip_code, this.state.contractorSearch).then(() => {
        modal = <PhotographerSelectionComponent
          changeParentState={this.handler}
          setAppointmentTime={onChangeAppointmentTimeNoContractor}
          appointments={appointments}
          selectedService={serviceId}
          changeSelected={this.closeModal}
          updating={true}
          project={project}
          waiting={this.state.waiting}
          included_services={included_services}
          zipcode={project.address?.zip_code}
          street={project.address?.street1}
          contractors={this.props.contractors}
          reschedulingContractorCancel={true}
          isLargeScreen={this.props.isLargeScreen}
          contractorTimeSearch={this.state.contractorTimeSearch}
          contractorDateSearch={this.state.contractorDateSearch}
          detected_timezone={this.state.detected_timezone} 
           />
      
        this.setState({ onChangeAppointmentTimeNoContractor: onChangeAppointmentTimeNoContractor, photographerModalOpen: true, appointments: appointments, included_services: included_services, zip_code: project.address?.zip_code, selected_service: serviceId})
     })
   }
  }

  closeModal() {
    this.setState({modal: null})
  }

  reloadProject(serviceId = null) {
    const projectId = this.props.match.params.id
    return fetchProject(projectId, serviceId).then((response) => {
      const project = Object.values(response)[0]
      this.setState({ project })
      return project
    })
  }

  reloadProjectNewSlug(){
    const projectSlug = this.state.project.slug
    this.props.history.push(`/projects_index/${projectSlug}`)
  }

  rescheduleAppointmentTime(appointment_time, contractor){
    this.setState({contractor: contractor, appointment_time: appointment_time})
  }

  handler(newState) {
    this.setState(newState)
  }

  onProjectChange(project) {
    const newProject = {...this.state.project, ...project}
    this.setState({project: newProject})

    const projectPayloadData = {
      ...newProject.project,
      address_attributes: newProject.address,
      packages_attributes: Object.values(newProject.packages).map(pack => ({
        ...pack,
        package_sub_services_attributes: pack.package_sub_services
      }))
    }

    const {client, cover, photographers, services, address, packages, ...payload} = projectPayloadData

    const projectId = this.props.match.params.id
    return updateProject(payload, this.state.project.id).then(response => {
      const project = Object.values(response)[0]
      this.setState({project})
    })
  }

  render() {
    const {project, modal} = this.state
    const {currentUser} = this.props


    // if (!project) {
    //   return <div/>
    // }

    const canManageProject = (!project && (currentUser.roles.broker || currentUser.roles.client)) || currentUser.roles.admin || currentUser.id === project?.client_id || (currentUser.roles.broker && currentUser.firm?.name === project?.client.firm?.name)
    const isProjectClient = currentUser.id === project?.client_id
    const isProjectContractor = currentUser.roles.contractor

    const projectAddress = [
      project?.address?.street1, project?.address?.city, project?.address?.region, project?.address?.zip_code
    ].filter(v => !!v).join(', ')

    let modal1 = ""
    let modal2 = ""

    if (this.state.photographerModalOpen){
      modal1 = <PhotographerSelectionComponent
        changeParentState={this.handler}
        setAppointmentTime={this.rescheduleAppointmentTime}
        onSubmit={this.state.onChangeAppointmentTimeNoContractor}
        appointments={this.state.appointments}
        selectedService={this.state.selected_service}
        changeSelected={() => this.setState({photographerModalOpen: false})}
        updating={true}
        project={project}
        waiting={this.state.waiting}
        included_services={this.state.included_services}
        zipcode={project.address?.zip_code}
        street={project.address?.street1}
        contractors={this.props.contractors}
        contractor={this.state.contractor}
        appointment_time={this.state.appointment_time}
        reschedulingContractorCancel={true}
        isLargeScreen={this.props.isLargeScreen}
        contractorTimeSearch={this.state.contractorTimeSearch}
        contractorDateSearch={this.state.contractorDateSearch}
        contractorSearch={this.state.contractorSearch}
        detected_timezone={this.state.detected_timezone} 
      />
    }

    if (this.state.chooseTimeModalOpen) {
      modal2 = <ChooseTimeModal changeParentState={this.handler} detected_timezone={project.client?.timezone} currentUser={this.props.currentUser} rescheduleModal={true} selectedService={this.state.selected_service} time_slots={this.props.services[this.state.selected_service]?.twilight ? this.state.twilight_time : this.state.time_slots} contractorDateSearch={this.state.contractorDateSearch} contractorTimeSearch={this.state.contractorTimeSearch} timezone={this.state.project?.client?.timezone} />
    }

    if (this.state.loadingModalOpen){
      modal2 = <div className="modal-background">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading"></div>
            </div>
            <div className="orderPreviewModalCategory">
              <div className="settingsComponentHeading">Submitting...</div>
              <div className="createProjectPaymentModal">
                <div className="loaderDiv">
                  <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    return (
      <div className="dashboardMainDiv">
        <PageHeader showNotifications={true} title={project?.address.formatted_address ? `${project?.address.apartment_number || ""} ${project?.address.formatted_address}` : projectAddress} back="Back to All Projects" backLink={"/projects_index"}/>
        {modal1}
        {modal2}
        {modal}
        <div className="editProjectSection">
          {canManageProject && <div className="editProjectSectionTop">
            <div className="">
              <div>
                {/* <img src={propertyTourIconSrc} className="me-3"/> */}
                  <button className="projectEditButton projectEditButtonPrimary listingButton" onClick={() => this.props.history.push(`/projects_index/${this.props.match.params.id}/listing-website`)}>Visit Listing Website</button>
              </div>
              <div className="mt-3">
                {/* <img src={vsIconSrc} className="me-3"/> */}
                {project && <button className="projectEditButton projectEditButtonPrimary listingButton" onClick={this.openVirtualStagingModal}>Order Virtual Staging</button>}
              </div>
            </div>
          </div>}

          {isProjectContractor && <div>
            <EditProjectContractorDetails project={project}
                                          currentUser={this.props.currentUser}
                                          onCancel={this.openContractorCancelModal}/>
          </div>}

          {currentUser.roles.admin && <>
            <EditProjectAdminDetails project={project}
                                     currentUser={this.props.currentUser}
                                     onAddService={this.openAddServiceModal}
                                     onChangeService={this.openChangeServiceModal}
                                     openNoteModal={this.openNoteModal}
                                     fetchCurrentUser={this.props.fetchCurrentUser}
                                     openEditAddressModal={this.openEditAddressModal}
                                     openAppointmentEditModal={this.openAppointmentEditModal}
                                     openCancelModal={this.openCancelModal}
                                     onChangeBundle={this.openChangeBundleModal}
                                     onCancel={this.openContractorCancelAdminModal}/>
          </>}

          {currentUser.roles.post_production && <>
            <EditProjectPostProductionDetails project={project}
              currentUser={this.props.currentUser}
              fetchCurrentUser={this.props.fetchCurrentUser}
             />
          </>}

          {!currentUser.roles.admin && canManageProject && <>
            <div className="divider"/>
            <EditProjectClientDetails project={project}
                                currentUser={this.props.currentUser}
                                fetchCurrentUser={this.props.fetchCurrentUser}
                                openNoteModal={this.openNoteModal}
                                openReviewModal={this.openReviewModal}
                                onAddService={this.openAddServiceModal}
                                onChangeService={this.openChangeServiceModal}
                                onChangeBundle={this.openChangeBundleModal}
                                openEditAddressModal={this.openEditAddressModal}
                                openAppointmentEditModal={this.openAppointmentEditModal}
                                openCancelModal={this.openCancelModal}/>
          </>}
        </div>

        <div className="editProjectSection editProjectPhotosSection mb-5">
          <EditProjectMedia isLargeScreen={this.props.isLargeScreen}
                            project={project}
                            showToast={this.props.showToast}
                            openNoteModal={this.openVirtualStagingNoteModal}
                            reloadProject={this.reloadProject}
                            currentUser={currentUser}
                            updatePropertyTour={this.props.updatePropertyTour}
                            openDownloadModal = {this.openDownloadModal}/>
        </div>
      </div>
    )
  }
}

export default withRouter(EditProject)