import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import ProjectIndexArrow from "../../../../assets/images/blueArrow"
import greenDot from "../../../../assets/images/green-dot";
import yellowDot from "../../../../assets/images/yellow-dot";
import blueDot from "../../../../assets/images/blue-dot";
import {updateProjectDetails} from "../../util/project_api_util";
import { showToast } from "../../actions/toast_actions";
import { connect } from "react-redux";
import {formatDatetime} from "../../util/date_util";


class ProjectIndexProjectsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swapStatus: "",
      currentProjectSwapStatus: this.props.project.production_downloaded
    }
    this.sendToProject = this.sendToProject.bind(this)
    this.swapProductionStatus = this.swapProductionStatus.bind(this)
  }

  sendToProject() {
    this.props.history.push(`/projects_index/${this.props.project.slug}`)
  }

  swapProductionStatus(event, production_downloaded) {
    event.stopPropagation()
    event.preventDefault()
    if (this.state.swapStatus === "") {
      let data = { id: this.props.project.id, project: { id: this.props.project.id, production_downloaded: production_downloaded } }
      this.setState({ swapStatus: production_downloaded })
      updateProjectDetails(data).then((project) => this.setState({ swapStatus: "", currentProjectSwapStatus: project[this.props.project.id].production_downloaded }))
    } else {
      this.props.showToast("Star change being processed")
    }
  }

  render() {
    let project = this.props.project;
    let address = project.address
    let services = []
    
    let additional_info = ""

    if (this.props.currentUser.roles.contractor){
      let contractor_services = project?.packages?.filter(current_package => current_package.contractor_id === this.props.currentUser.id) || []

      contractor_services.sort((a, b) => { return new Date(a.appointment_time).getTime() - new Date(b.appointment_time).getTime() }).forEach(current_package => {
        project.services.forEach(service => {
          if (service.id === current_package.service_id){
            let upcoming_appointment = ""
            let id = ""
            let dot = yellowDot
            if (current_package.complete) {
              // id = "completedService"
              dot = greenDot
            } else if (current_package.ready_for_download){
              dot = blueDot
            }

            if (current_package.appointment_time && new Date(current_package.appointment_time) > new Date()) {
              upcoming_appointment = new Date(current_package.appointment_time)
            }

            services.push(<div className="flexDisplaySorta"><div className="flexDisplay"><img src={dot} /><p className="serviceIconProjectIndex gridServiceIconProjectIndex" id={id}>{service.title}</p></div>{upcoming_appointment ? <div className="projectsIndexItemSubheading"> - {formatDatetime(upcoming_appointment, null, project?.timezone)}</div> : <div></div>}</div>)
          }
        })
      })
    } else {
      project.packages.sort((a, b) => { return new Date(a.appointment_time).getTime() - new Date(b.appointment_time).getTime() }).forEach(current_package => {
        project.services.forEach(service => {
          if (service.id === current_package.service_id && (!this.props.currentUser.roles.post_production || !current_package.cubicasa || (service.title != "Floor Plans" && service.media_types != "matterport"))){
            let upcoming_appointment = "";
            let id = ""
            let dot = yellowDot
            if (current_package.complete) {
              // id = "completedService"
              dot = greenDot
            } else if (current_package.ready_for_download) {
              dot = blueDot
            }

            if (current_package.appointment_time && new Date(current_package.appointment_time) > new Date()) {
              upcoming_appointment = new Date(current_package.appointment_time)
            }
            services.push(<div className="flexDisplaySorta"><div className="flexDisplay"><img src={dot} /><p className="serviceIconProjectIndex gridServiceIconProjectIndex" id={id}>{service.title}</p></div>{upcoming_appointment ? <div className="projectsIndexItemSubheading"> - {formatDatetime(upcoming_appointment, null, project?.timezone)}</div> : <div></div>}</div>)
          }
        }) 
      })
    }
 
      return (
        <Link className="projectsIndexProjectItemDiv" id={project.needs_attention && !this.props.currentUser.roles.contractor && !this.props.currentUser.roles.post_production ? "needsAttention" : ""} to={`/projects_index/${this.props.project.slug}`}>
        {/* <img className="projectsIndexProjectItemPicture" src={"." + project.cover}></img> */}
        <div className="projectsIndexProjectItemText">
          <div className="projectIndexItemHeadingGroup">
              <div className="projectsIndexItemHeading">{address?.apartment_number ? `${address.apartment_number } ` : ""}{address?.formatted_address ? address.formatted_address : `${address.street1}, ${address.city}, ${address.region}`}</div>
            <div className="projectIndexArrow"><img src={ProjectIndexArrow}></img></div>
          </div>
          {/* <div className="projectsIndexItemSubheading"> {address?.street1}, {address?.city}, {address?.region} {address?.zip_code}</div> */}
          <div>
            <div className="project-index-services">{services}</div>
              {project.needs_attention && !this.props.currentUser.roles.contractor && !this.props.currentUser.roles.post_production ? <div className="needsAttentionText">Urgent: New Appointment Required!</div> : ""}
          </div>
        </div>
          {this.props.currentUser.roles.admin || this.props.currentUser.roles.post_production ? <div onClick={(e) => this.swapProductionStatus(e, !this.state.currentProjectSwapStatus)} className={this.state.swapStatus === "" ? this.state.currentProjectSwapStatus ? "productionIconOn" : "productionIconOff" : this.state.swapStatus ? "productionIconOn" : "productionIconOff"}></div> : ""}
      </Link>

    )
  }
}

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(ProjectIndexProjectsItem));