import { connect } from "react-redux";
import ReviewModal from "./review_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { submitReview, updateReview } from "../../actions/project_actions";
import { showToast } from "../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  submitReview: (review) => dispatch(submitReview(review)),
  updateReview: (review, review_id) => dispatch(updateReview(review, review_id)),
  showToast: (message) => dispatch(showToast(message)),
});

export default withRouter(connect(msp, mdp)(ReviewModal));