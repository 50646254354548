import React from "react";
import { Route, Link } from "react-router-dom";
import baseImage from "../../../../assets/images/content-for-photography.jpg";
import questionIcon from "../../../../assets/images/icons/MA-Question-Icon";


export default class CategoryItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let category = this.props.category;
    let id = "";
    let textId = "";
    let imageId = "";
    let virtualClassName = "virtual-staging-modal-category-container"

    if (this.props.selected) {
      id = "virtualStagingCategorySelected"
      textId = "categoryItemTextSelected"
      imageId = "categoryItemImageSelected"
    }


    if (this.props.homepage){
      virtualClassName = "virtual-staging-modal-category-container virtual-staging-modal-category-container-homepage"
    }

    return (
      <div onClick={() => this.props.toggleSelected(category)} id={id} className={virtualClassName}>
        <img id={imageId} className="categoryItemImage" src={category.cover}></img>
        <div className="categoryItemDescriptionDiv noToolTip">
          <div className="categoryItemText" id={textId}>{category.name}</div>
          {/* <div className="toolTip"><img className="categoryQuestionIcon" src={questionIcon} />
            <span className="categoryDescription">{category.description}</span>
          </div> */}
        </div>
      </div>
    )
  }
}