import Slider from "react-slick";
import React, { useRef, useEffect, useState } from 'react';
import leftImg from "../../../../assets/images/blog/left.png";
import rightImg from "../../../../assets/images/blog/right.png";

export const BlogPostsSlider = ({slides}) => {
  const slider = useRef(null)

  const onNext = (e) => {
    e.preventDefault()
    slider.current.slickNext()
  }

  const onPrev = (e) => {
    e.preventDefault()
    slider.current.slickPrev()
  }

  useEffect(() => {
    const width = window.innerWidth;

    const handleSlider = () => {
      if (slider.current) {
        if (width <= 375) {
          slider.current.slickNext();
        } else if (width > 375 && width <= 767) {
          slider.current.slickNext();
          setTimeout(() => {
            if (slider.current) {
              slider.current.slickNext();
            }
          }, 500);
        }
      }
    };

    const timeoutId = setTimeout(handleSlider, 100); // Initial delay to ensure the slider is fully initialized

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, []);

  return <div className="popular-posts-content">
    <div className="popular-posts-content-inner">
      <Slider
        ref={slider}
        slidesToShow={3}
        slidesToScroll={1}
        dots={false}
        arrows={false}
        infinite={true}
        autoplay={false}
        responsive={[
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 375,
            settings: {
              slidesToShow: 1,
            }
          }
        ]}>
        {slides}
      </Slider>
    </div>
    <div className="popular-posts-navigation">
      <a href="#" className="popular-posts-navigation-button" onClick={onPrev}>
        <img src={leftImg} />
      </a>
      <a href="#" className="popular-posts-navigation-button" onClick={onNext}>
        <img src={rightImg} />
      </a>
    </div>
  </div>
}