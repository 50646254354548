import { RECEIVE_ALL_SERVICES, RECEIVE_SERVICE, REMOVE_SERVICE } from "../actions/service_actions";

const serviceReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ALL_SERVICES:
      return action.services
    case RECEIVE_SERVICE:
      return Object.assign({}, state, action.service)
    case REMOVE_SERVICE:
      let newState = Object.assign({}, state);
      delete newState[action.service_id]
      return newState
    default:
      return state;
  }
};

export default serviceReducer;