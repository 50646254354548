import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {openModal} from "../../../actions/modal_actions";
import SettingsSubBrokers from "./settings_sub_brokers";
import { showToast } from "../../../actions/toast_actions";

const msp = (state) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message)),
  openModal: (modal, props) => {
    dispatch(openModal(modal, props));
  }
});

export default withRouter(connect(msp, mdp)(SettingsSubBrokers));