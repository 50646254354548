import React, {useEffect, useState} from "react";
import {largeServiceIconSrc} from "../../../util/service_util";
import {Footer} from "../footer";
import {useDispatch, useSelector} from "react-redux";
import {fetchServices} from "../../../actions/service_actions";

export const Services = (props) => {
  const dispatch = useDispatch()
  const services = useSelector((state) => {
    return Object.values(state.entities.services)
  })
  const [selectedServices, setSelectedServices] = useState(props.data?.services || [])

  useEffect(() => {
    dispatch(fetchServices())
  }, []);

  const onSubmit = (e) => {
    e.preventDefault()
    props.onNext({services: selectedServices})
  }

  const onToggleSelect = (e, service) => {
    e.preventDefault()

    if (isSelected(service)) {
      setSelectedServices(selectedServices.filter(s => s.id !== service.id))
    } else {
      setSelectedServices([...selectedServices, {id: service.id, title: service.title, slug: service.slug}])
    }
  }

  const isSelected = (service) => {
    return selectedServices.map(s => s.id).includes(service.id)
  }

  return <form onSubmit={onSubmit}>
    <div className="schedule-demo-title mar-bottom-20">
      What specific real estate photography services are you interested in?
    </div>

    <div className="schedule-demo-paragraph">
      (Check all that apply)
    </div>

    <div className="servicesList">
      {services.length > 0 && services.map(service => {
        return <div className="serviceItem serviceItemOnboarding" key={`service-${service.id}`} onClick={e => onToggleSelect(e, service)}
                    id={isSelected(service) ? 'serviceItemSelected' : ''}>
          <div className="iconCreateProjectDiv">
            <img className="iconImageCreateProject" src={largeServiceIconSrc(service.slug)}/>
          </div>

          <div className="serviceItemTitle">
            {service.title}
          </div>
        </div>
      })}
    </div>

    <Footer onBack={props.onBack} nextDisabled={selectedServices.length === 0}/>
  </form>
}