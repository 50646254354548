import { connect } from "react-redux";
import CreateProjectSignupForm from "./create_project_signup_form";
import React from "react";
import { withRouter } from "react-router-dom"
import { signup, login, receiveSessionErrors } from "../../actions/session_actions";
import {openModal} from "../../actions/modal_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
});

const mdp = (dispatch) => ({
  signup: (user) => dispatch(signup(user)),
  login: (user) => dispatch(login(user)),
  clearErrors: () => dispatch(receiveSessionErrors([])),
  openModal: (modal, props) => { dispatch(openModal(modal, props)); }
});

export default withRouter(connect(msp, mdp)(CreateProjectSignupForm));