import React from 'react'
import {SERVICES} from "../../../../../assets/images/servicesPages";
import hdrImage from '../../../../../assets/images/location/hdrPhorography.png'
import videoImage from '../../../../../assets/images/location/videoTours.jpg'
import droneImage from '../../../../../assets/images/location/drone.png'
import {
  Heading,
  List,
  ListHeading,
  ListParagraph,
  ListWrapper,
  Section, SectionImage,
  Sections,
  Wrapper,
  WrapperInner
} from "./styles";

export const ServicesDescription = ({city}) => {
  const servicesListItems = [
    {
      title: `${city} HDR Real Estate Photography`,
      img: hdrImage,
      items: [
        {
          title: 'What is HDR Photography?',
          description: 'High Dynamic Range (HDR) involves taking multiple shots at different exposures to capture a wide range of details, ensuring perfectly balanced lighting inside and out.',
          icon: SERVICES.ListIcon2
        },
        {
          title: 'Crystal Clear Window Views',
          description: 'HDR technology achieves clear window views by capturing and merging multiple exposures of a scene, preserving intricate details in both bright and dark areas.',
          icon: SERVICES.ListIcon3
        },
        {
          title: 'True-Color Accuracy',
          description: 'By expanding the spectrum of tones and hues, HDR enhances color depth and accuracy, presenting a rich and vibrant representation of the property\'s visual palette, ensuring an immersive viewing experience.',
          icon: SERVICES.ListIcon1
        }
      ]
    },
    {
      title: 'Walkthrough Video Tours & Social Media',
      img: videoImage,
      items: [
        {
          title: 'Interactive Video Tours',
          description: `Video Tours offer an immersive preview of your ${city} property, letting potential buyers explore features and ambiance virtually, attracting serious interest.`,
          icon: SERVICES.ListIcon2
        },
        {
          title: 'Vertical Instagram Reel',
          description: 'Short video clips on social media can make a huge impact on the visibility and discovery of your listing. Let’s create a trendy vertical video for your social media real estate marketing.',
          icon: SERVICES.ListIcon3
        }
      ]
    },
    {
      title: `Drone Photography & Video in ${city}`,
      img: droneImage,
      items: [
        {
          title: 'Captivating Visual Narratives',
          description: `Engage your audience in ${city} with dynamic drone photos and videography, showcasing properties and landscapes with immersive, cinematic footage that brings your listings to life.`,
          icon: SERVICES.ListIcon2
        },
        {
          title: 'Showcase Your Listing From Above',
          description: 'Drone photography and video highlight property features and surroundings from breathtaking aerial viewpoints, captivating your audience\'s attention.',
          icon: SERVICES.ListIcon3
        }
      ]
    }
  ]

  return <Wrapper>
    <WrapperInner>
      <Sections>
        {servicesListItems.map((list, i) => (
          <Section key={`section-${i}`}>
            <div className="w-100 text-center"><SectionImage src={list.img}/></div>
            <ListWrapper>
              <Heading>{list.title}</Heading>
              <List>
                {list.items.map((item, j) => (
                  <div>
                    <ListHeading key={`list-item-${i}-${j}`}>
                      <img src={item.icon} /> <span>{item.title}</span>
                    </ListHeading>
                    <ListParagraph>{item.description}</ListParagraph>
                  </div>
                ))}
              </List>
            </ListWrapper>
          </Section>
        ))}
      </Sections>
    </WrapperInner>
  </Wrapper>
}