import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { RenderingWrapper } from './RenderingStyles';
import {
  ServiceDropdown,
  FullService,
  BestProperty,
  ServicesButton,
  ImageSection,
  ContractMedia,
} from '../../pageComponents';
import {
  RENDERING,
  SERVICES,
} from '../../../../../assets/images/servicesPages';
import {PropertyComponent, ServiceLogoListComponent, TestimonialsComponent2} from "../../home_page/homeComponents";
import BundlesAndServices from "../../pageComponents/BundlesAndServices";
import {CONTRACT} from "../../../../../assets/images/landing";
import {CONTRACT_MEDIA_DATA} from "../../home_page/homePageData";
import {FullServiceTitle, FullServiceTitleGold} from "../../pageComponents/FullService/styles";

const {
  RenderingSlide1,
  RenderingSlide2,
  RenderingSlide3,
  RenderingSlide4,
  RenderingSlide5,
  RenderingSlide6,
} = RENDERING;

const FULL_SERVICE_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'Pre-devlopment Visuals',
    content:
      'Realistic renderings help reveal the true potential of your space and will set your development project up for success.',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Market-ready Quality',
    content:
      'Our experience in real estate marketing gives Modern Angles the ability to can create photo realistic renderings at lower costs and quicker than other firms.',
  },
  {
    icon: SERVICES.ListIcon3,
    title: 'Architectural Renderings',
    content:
      'Architectural renderings can be used to help market in-development projects, or be used to get funding for new construction.',
  },
];

const RenderingPage = (props) => {
  const { location } = props;

  return (
    <RenderingWrapper>
      <Helmet>
        <title>Real Estate 3D Rendering Services | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ServiceDropdown
        pathName={location.pathname}
        content={
          'We create photo realistic renderings for use as marketing assets or in pre-development process for renovations or new construction.'
        }
        contentLink={null}
        isExtraPadding={true}
      />
      <ServicesButton setModal={props.setModal} />
      <ImageSection
        SectionImage1={RenderingSlide1}
        SectionImage2={RenderingSlide2}
        SectionImage3={RenderingSlide3}
        SectionImage4={RenderingSlide4}
        SectionImage5={RenderingSlide5}
        SectionImage6={RenderingSlide6}
      />
      <FullService
        image={RENDERING.RenderingFullService}
        data={FULL_SERVICE_DATA}>
        <FullServiceTitle>
          <FullServiceTitleGold>Realistic Renderings </FullServiceTitleGold> can help make a  damaged or vacant property marketable.
        </FullServiceTitle>
      </FullService>

      <div className="lead-page-container">
        <ServiceLogoListComponent/>
      </div>

      <BundlesAndServices setModal={props.setModal}/>

      <ContractMedia
        imagesList={CONTRACT.ContractMediaSlides}
        data={CONTRACT_MEDIA_DATA}
        isVirtualStaging={false}
      />

      <TestimonialsComponent2/>

      <PropertyComponent/>
      <BestProperty/>
    </RenderingWrapper>
  );
};

export default withRouter(RenderingPage);
