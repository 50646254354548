export const fetchUserServiceableZipCodes = (userId) => (
  $.ajax({
    type: "GET",
    url: `/users/${userId}/serviceable_zip_codes`
  })
);

export const updateUserServiceableZipCodes = (userId, polygons_coordinates) => (
  $.ajax({
    type: "PUT",
    url: `/users/${userId}/serviceable_zip_codes`,
    data: {polygons_coordinates}
  })
);
