export const SHOW_TOAST = "SHOW_TOAST";
export const CLOSE_TOAST = "CLOSE_TOAST";

export const showToast = (message) => ({
  type: SHOW_TOAST,
  message
});

export const closeToast = (toast) => ({
  type: CLOSE_TOAST,
  toast
});