import { ADD_INFO, REMOVE_INFO } from '../actions/modal_actions.js';

export default function modalInfoReducer(state = null, action) {
  switch (action.type) {
    case ADD_INFO:
      return action.info;
    case REMOVE_INFO:
      return null;
    default:
      return state;
  }
}