import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import CreateProjectMain from "./create_project_2/create_project_main_2_container";
import NavbarContainer from "./navbar/navbar_container";
import NavbarLoggedOutNonWebsite from "./navbar/navbar_logged_out_non_website"

class CreateProject2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLargeScreen: true
    }

    this.updatePredicate = this.updatePredicate.bind(this);
  }


  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  render() {
    let navbar = ""
    let component = <CreateProjectMain isLargeScreen={this.state.isLargeScreen} />

    if (this.props.currentUser) {
      navbar = <NavbarContainer />
    } else if (this.state.isLargeScreen) {
      navbar = <NavbarLoggedOutNonWebsite />
    }

    return (
      <div>
        <div className="dashboardFullPageDiv">
          {navbar}
          {component}
        </div>
      </div>
    )
  }
}

export default withRouter(CreateProject2);