import React from 'react';

import {
  HeaderWrapper,
  HeaderTitle,
  HeaderDescription,
  LineBreak,
} from './styles';

const FAQHeader = () => {
  return (
    <HeaderWrapper>
        <HeaderTitle>
          FAQ
        </HeaderTitle>
        <HeaderDescription>
          Modern Angles is a full service creative partner with lots of hardware and tech that keep everything moving efficiently. If you have a question about what we do, you can probably find it here.
        </HeaderDescription>
        <LineBreak />
    </HeaderWrapper>
  );
};

export default FAQHeader;
