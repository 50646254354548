import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { InteractiveToursWrapper } from './InteractiveToursStyles';
import {
  ServiceDropdown,
  FullService,
  BestProperty,
  MatterportTour, ContractMedia,
} from '../../pageComponents';
import {
  INTERACTIVE_TOURS,
  SERVICES,
} from '../../../../../assets/images/servicesPages';
import {PropertyComponent, ServiceLogoListComponent, TestimonialsComponent2} from "../../home_page/homeComponents";
import BundlesAndServices from "../../pageComponents/BundlesAndServices";
import {CONTRACT} from "../../../../../assets/images/landing";
import {CONTRACT_MEDIA_DATA} from "../../home_page/homePageData";
import {FullServiceTitle, FullServiceTitleGold} from "../../pageComponents/FullService/styles";

const FULL_SERVICE_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'Smooth Walkthrough',
    content:
      'Simple walkthroughs showing each room, and the connections between them can quickly help your audience feel the layout of your property.',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Matterport Interactive Tour',
    content:
      'Our specialized Matterport camera will take 3D photos of your space that enables your clients to explore your property without having to visit it in person.',
  },
  {
    icon: SERVICES.ListIcon3,
    title: 'Web-Ready Content',
    content:
      'Our interactive tours are easy to install as content on your website. They work well on computers and phones, exactly like the demo on this page.',
  },
];

const MATTERPORT_TOUR_LINK = "https://my.matterport.com/show/?m=vBLMrtULH5y";

const InteractiveToursPage = (props) => {
  const { location } = props;

  return (
    <InteractiveToursWrapper>
      <Helmet>
        <title>Real Estate Virtual Tour Services | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ServiceDropdown
        pathName={location.pathname}
        content={
          'Give your property the chance to speak for itself at a time when travel and in-person visits are harder than ever.'
        }
        contentLink={null}
        isExtraPadding={true}
      />
      <MatterportTour link={MATTERPORT_TOUR_LINK} />
      <FullService
        image={INTERACTIVE_TOURS.InteractiveToursFullService}
        data={FULL_SERVICE_DATA}>
        <FullServiceTitle>
          <FullServiceTitleGold>Interactive Tours</FullServiceTitleGold> are essential for long distance buyers looking to relocate or invest.
        </FullServiceTitle>
      </FullService>

      <div className="lead-page-container">
        <ServiceLogoListComponent/>
      </div>

      <BundlesAndServices setModal={props.setModal}/>

      <ContractMedia
        imagesList={CONTRACT.ContractMediaSlides}
        data={CONTRACT_MEDIA_DATA}
        isVirtualStaging={false}
      />

      <TestimonialsComponent2/>

      <PropertyComponent/>
      <BestProperty/>
    </InteractiveToursWrapper>
  );
};

export default withRouter(InteractiveToursPage);
