import React, {useEffect, useState} from 'react';
import {Route, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {PublicRouteContainerWrapper} from './styles';
import ServicesNav from '../../components/ServicesNav';
import NavbarContainer from '../../components/navbar/navbar_container';
import {FooterSection} from '../../components/pageComponents';
import Modals from "./modals";
import {closeProjectModal, openProjectModal} from "../../actions/landing_page_actions";

const PublicRouteContainer = (props) => {
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 750) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 750) {
        setIsLargeScreen(true);
      } else {
        setIsLargeScreen(false);
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const setModal = (val) => {
    if (val) {
      props.openNewModal()
    } else {
      props.closeModal()
    }
  }

  const msp = (state, ownProps) => ({
    currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
  });

  const mdp = (dispatch) => ({
    setModal: setModal,
  });

  return (
    <PublicRouteContainerWrapper>
      {window.outerWidth > 998 && <ServicesNav/>}
      <NavbarContainer nonAppPage={true} setModal={setModal} disableWaveBackground={props.navbarDisableWaveBackground} navbarHideMiddleButtons={props.navbarHideMiddleButtons} />
      <Modals isLargeScreen={isLargeScreen}/>
      <Route key={props.key} exact={props.exact} path={props.path} component={withRouter(connect(msp, mdp)(props.component))}/>
      <FooterSection setModal={setModal}/>
    </PublicRouteContainerWrapper>
  );
};

const msp = (state) => {
  return {};
};

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default connect(msp, mdp)(PublicRouteContainer);
