import React from "react"
import {withRouter} from "react-router-dom"
import PhotographerPhotosComponent from "./photographers_photos"
import PhotosMasonry from "../gallery/photos_masonry"
import PhotographerReviews from "./photographers_reviews"
import {stars} from "../../util/rating_util"
import PhotographerServices from "./photographer_services"
import PageHeader from "../page_header"
import PhotographerCalendarModal from "./photographer_calendar_modal_conatiner"
import {approveUser, rejectUser} from "../../util/users_api_util";

class PhotographerShowMain extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photographer: "",
      contractorCalendarOpen: false
    }

    this.handler = this.handler.bind(this)
    this.fetchPhotographer = this.fetchPhotographer.bind(this)
    this.onApprove = this.onApprove.bind(this)
    this.onReject = this.onReject.bind(this)
  }


  componentDidMount() {
    this.fetchPhotographer()
  }

  fetchPhotographer(){
    this.props.fetchPhotographer(this.props.match.params.id).then(photographer => this.setState({ photographer: photographer }))
  }

  handler(newState) {
    this.setState(newState)
  }

  onApprove() {
    const userId = this.props.match.params.id
    approveUser(userId).then(() => {
      this.setState({photographer: {...this.state.photographer, approved: true}})
      this.props.showToast("User has been approved")
    }).catch(() => {
      this.props.showToast("Something went wrong")
    })
  }

  onReject() {
    const userId = this.props.match.params.id
    if (!confirm("Reject user?")) return
    
    rejectUser(userId).then(() => {
      this.props.history.push('/settings/users')
      this.props.showToast("User has been rejected and removed")
    }).catch(() => {
      this.props.showToast("Something went wrong")
    })
  }

  render() {

    let photographer = this.state.photographer

    const star_rating = photographer ? stars(photographer.average_rating) : []

    // let photographerButton = <button onClick={() => this.props.history.push("/create-project")}>
    //   Hire Photographer
    // </button>

    let photographerButton = <button onClick={() => this.setState({contractorCalendarOpen: true})}>
      Availability
    </button>

    if (this.props.currentUser?.roles?.contractor || this.props.currentUser?.roles?.post_production) {
      photographerButton = <div />
    }

    if (this.props.currentUser?.id === photographer.id) {
      photographerButton =
        <button onClick={() => this.props.history.push("/settings")}>
          Edit Profile
        </button>
    }

    if (!photographer){
      return ""
    } else {
      let modal = ""
      if (this.state.contractorCalendarOpen){
        const service = photographer.services.filter(service => !service.twilight)[0]?.id
        modal = <PhotographerCalendarModal
                  photographer={photographer}
                  detected_timezone={photographer.timezone}
                  selectedService={service}
                  changeParentState={this.handler}/>
      }
      return (
        <div className={this.props.currentUser ? "dashboardMainDiv" : "dashboardMainLoggedOutDiv"} id="photographerShowMainDiv">
        {modal}
        {this.props.currentUser && <PageHeader title="Photographer Profile" back="Back to All Photographers" backLink={"/photographers"} />}

          {photographer && photographer.images.length > 0 && <div className="photographerGallery" id={this.props.currentUser ? "" : "loggedOutGallery"}>
            <PhotographerPhotosComponent photographer={photographer} layout="line" hideScrollbar={true}/>
          </div>}

          <div className="sectionCard sectionCardSecondary" id={this.props.currentUser ? "" : "loggedOutSectionCard"}>
            <div className="sectionCardHeader">
              <div className="photographerShowDetails">
                <img className="photographerShowAvatar" src={photographer.avatar}/>
                <div className="photographerShowInfo">
                  <div className="photographerShowNameAndRating">
                    <h3 className="photographerShowName">{photographer.firstName} {photographer?.lastName[0]}</h3>
                    {photographer.ratings_amount > 0 && <div className="photographerShowRating">{star_rating}</div>}
                  </div>
                </div>
                <div className="photographerHireButton">
                  {this.props.currentUser?.roles?.admin && !photographer.approved && <>
                    <button onClick={this.onApprove} className="bg-success">Approve</button>
                    <button onClick={this.onReject} className="bg-danger">Reject</button>
                  </>}

                  {photographerButton}
                </div>
              </div>
            </div>
            <div className="sectionCardBody">
              <div className="photographerShowBio">
                {photographer.bio || ""}
              </div>

              <div className="photographerSectionCardTitle">
                Services Offered
              </div>

              <PhotographerServices photographer={photographer}/>

              <div className="photographerSectionCardTitle">
                Best Photos
              </div>

              {photographer && <PhotosMasonry images={photographer.images || []} />}
            </div>
          </div>

          {photographer.ratings_amount > 0 && <div className="sectionCard sectionCardSecondary" id={this.props.currentUser ? "" : "loggedOutSectionCard"}>
            <PhotographerReviews currentUser={this.props.currentUser} photographer={photographer} />
          </div>}
        </div>
      )
    }
  }
}

export default withRouter(PhotographerShowMain)