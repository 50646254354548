import React from "react";
import CategoryItem from "./category_item"
import { fetchCategories } from "../../util/virtual_staging_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";


export default class NotesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimize: this.props.minimize,
    }
  }

  componentDidMount() {
   
  }

  render() {
    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""

    if (this.state.minimize) {
      icon = <div key="2"><i className="fas fa-plus"></i></div>
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 5) {
        checkmark = "createProjectCheckmarkShow"
      }

    }

    return (
      <div className="projectLocationComponent">
        <div className="projectLocationHeadingGrouping" id={minimized}>
          <div className="projectLocationHeadingMinimizedGrouping">
            <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
            <div className="settingsComponentHeading chooseYourServiceText">Special Instructions</div>
          </div>
          {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
        </div>
        <div id={minimizeId} className="row mt-3 mx-0 virtualStagingCategoryGrid">
          <div>
              <ul>
                <li>Please provide a detailed description of your Virtual Staging request.</li>
                <li>Click Next Step if not applicable.</li>
              </ul>
            </div>

            <label id="formLabel">
              Please add relevant information
            </label>

            <textarea className="virtualStagingSpecialNotes formInput"
              onChange={(e) => this.props.changeParentState({ specialNote: e.currentTarget.value })}
              placeholder="Add your comments here. "
              value={this.props.specialNote} />
        </div>
      </div>
    );
  }
}