import { connect } from "react-redux";
import VirtualStagingMain from "./virtual_staging_main";
import React from "react";
import { openModal, closeModal } from "../../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { fetchVirtualStagingInfo, createVirtualStagingPurchase } from "../../actions/virtual_staging_actions";
import { fetchCurrentUser } from "../../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  categories: Object.values(state.entities.virtualStaging.categories),
  photos: Object.values(state.entities.virtualStaging.photos),
  products: Object.values(state.entities.virtualStaging.products),
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  fetchVirtualStagingInfo: (project_id) => {dispatch(fetchVirtualStagingInfo(project_id))},
  createVirtualStagingPurchase: (orders) => dispatch(createVirtualStagingPurchase(orders)),
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id)),
});

export default withRouter(connect(msp, mdp)(VirtualStagingMain));