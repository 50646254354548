import * as APIUtil from "../util/project_api_util";


export const RECEIVE_PROJECT = "RECEIVE_PROJECT";
export const RECEIVE_ALL_PROJECTS = "RECEIVE_ALL_PROJECTS";
export const RECEIVE_TIMES = "RECEVIE_TIMES";
export const REMOVE_PROJECT = "REMOVE_PROJECT"

export const receiveProjects = (projects) => ({
  type: RECEIVE_ALL_PROJECTS,
  projects
});

export const receiveProject = (project) => ({
  type: RECEIVE_PROJECT,
  project
});

export const receiveTimes = (times) => ({
  type: RECEIVE_TIMES,
  times
})

export const fetchUserProjects = (user_id) => dispatch => {
  return APIUtil.fetchUserProjects(user_id).then(
    projects => dispatch(receiveProjects(projects)))
};

export const fetchProject = (project_id, service_id = null) => dispatch => {
  return APIUtil.fetchProject(project_id, service_id).then(
    project => dispatch(receiveProject(project)))
};

export const fetchTimes = (time_info) => dispatch => {
  return APIUtil.fetchTimes(time_info).then(
    times => dispatch(receiveTimes(times)))
};

export const submitOrder = (project_info) => dispatch => {
  return APIUtil.submitOrder(project_info).then(
    project => dispatch(receiveProject(project))
  )
}

export const updateProject = (project_info, project_id) => dispatch => {
  return APIUtil.updateProject(project_info, project_id).then(
    project => dispatch(receiveProject(project))
  )
}

export const deleteProject = (project_id, refund_type) => dispatch => {
  return APIUtil.deleteProject(project_id, refund_type)
}

export const uploadPhotos = (formData) => dispatch => {
  return APIUtil.uploadPhotos(formData)
}

export const deleteMedia = (media) => dispatch => {
  return APIUtil.deleteMedia(media)
}

export const cancelAppointments = (project_id, cancelInfo) => dispatch => {
  return APIUtil.cancelAppointments(project_id, cancelInfo)
}

export const updatePropertyTour = (project_info) => dispatch => {
  return APIUtil.updatePropertyTour(project_info).then(
    project => dispatch(receiveProject(project))
  )
}

export const sortMedia = (medias) => dispatch => {
  return APIUtil.sortMedia(medias)
}

export const submitReview = (review) => dispatch => {
  return APIUtil.submitReview(review)
}

export const updateReview = (review, review_id) => dispatch => {
  return APIUtil.updateReview(review, review_id)
}

export const toggleHiddenMedia = (medias) => dispatch => {
  return APIUtil.toggleHiddenMedia(medias)
}

export const notifyProjectUpdated = (project_id) => dispatch => {
  return APIUtil.notifyProjectUpdated(project_id)
}

export const completeProject = (project_id) => dispatch => {
  return APIUtil.completeProject(project_id)
}

