import React from "react";
import Editor from 'react-medium-editor';

import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import {createFAQ, updateFAQ} from "../../../util/faq_api_utils";


export default class FaqFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(this.props.faq || {
        title: '',
        body: ''
      }),
      errors: []
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onBodyChange(text) {
    this.setState({body: text});
  }

  onSave() {
    const save = this.state.id ?
      updateFAQ(this.state.id, this.state.title, this.state.body) :
      createFAQ(this.state.title, this.state.body)

    save.then(faq => {
      this.props.closeModal()
      this.props.onSave(faq)
    }).catch(response => {
      this.setState({errors: response.responseJSON || []})
    })
  }

  render() {
    return (
      <div className="createTermsServiceModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update FAQ' : 'Create FAQ'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel">Title</label>
              <input className="formInput" onChange={this.onChange} name="title" value={this.state.title}/>
            </div>

            <div className="settingsServicesInput">
              <label id="formLabel">Content</label>
              <Editor text={this.state.body} onChange={this.onBodyChange}/>
            </div>
          </div>

          <button onClick={this.onSave} className="settingsButton companyButton">Save</button>
        </div>
      </div>
    );
  }
}