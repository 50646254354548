import React from "react";
import {sendContactsMessage} from "../../util/contacts_api_utils";

class PropertyTourContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      sent: false,
      loading: false
    }

    this.onInputChange = this.onInputChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onInputChange(field) {
    return (e) => {
      this.setState({[field]: e.target.value})
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const {loading, sent, ...payload} = this.state
    this.setState({sent: false, loading: true})

    sendContactsMessage({...payload, project_id: this.props.project?.id}).then(() => {
      this.setState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
        sent: true,
        loading: false
      })
    })
  }

  render() {
    return <form onSubmit={this.onSubmit}>
      {this.state.sent && <div className="alert alert-success mb-4">Your message has been sent</div>}

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="inputGroup">
            <label className="required">First name</label>
            <input className="formInput" placeholder="Content" onChange={this.onInputChange('first_name')}
                   value={this.state.first_name} required/>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="inputGroup">
            <label className="required">Last name</label>
            <input className="formInput" placeholder="Content" onChange={this.onInputChange('last_name')}
                   value={this.state.last_name} required/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="inputGroup">
            <label className="required">Email address</label>
            <input className="formInput" placeholder="email@domain.com" onChange={this.onInputChange('email')}
                   value={this.state.email} required type="email"/>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="inputGroup">
            <label>Phone number</label>
            <input className="formInput" placeholder="+1 333 555-5555" onChange={this.onInputChange('phone')}
                   value={this.state.phone}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="inputGroup">
            <label>Your Message</label>
            <textarea className="formInput" placeholder="Content" onChange={this.onInputChange('message')}
                      value={this.state.message} style={{minHeight: '85px'}} required/>
          </div>
        </div>
      </div>
      <div>
        <button className="contactsSubmitBtn" type="submit" disabled={this.state.loading}>
          Submit
        </button>
      </div>
    </form>
  }
}

export default PropertyTourContactForm