import React from "react"
import leftIcon from "../../../../assets/images/propertyTour/chevron-left.svg"
import rightIcon from "../../../../assets/images/propertyTour/chevron-right.svg"
import ScrollableImage from "./scrollable_image";

class FloorPlans extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentImage: 0
    }

    this.onPrev = this.onPrev.bind(this)
    this.onNext = this.onNext.bind(this)
  }

  clickPhoto(e, i) {
    e.preventDefault();

    this.setState({
      currentImage: i
    })
  }

  onNext(e) {
    e.preventDefault()
    this.setState((state) => ({
      currentImage: Math.min(this.props.images.length - 1, state.currentImage + 1)
    }))
  }

  onPrev(e) {
    e.preventDefault()
    this.setState((state) => ({
      currentImage: Math.max(0, state.currentImage - 1)
    }))
  }
  
  canNext() {
    return this.state.currentImage < this.props.images.length - 1
  }

  canPrev() {
    return this.state.currentImage > 0
  }

  render() {
    const images = this.props.images.sort((i1, i2) => {
      return i1.sort_order > i2.sort_order ? 1 : -1
    })

    return (
      <div className="propertyTourFloorPlans">
        <div className="mainImageContainer">
          {this.props.images.length > 0 && <a className={`prev ${this.canPrev() ? '' : 'disabled'}`} onClick={this.onPrev}>
            <img src={leftIcon} />
          </a>}

          <ScrollableImage name="gallery" currentImage={this.state.currentImage} images={images} floorplans={true}/>

          {this.props.images.length > 0 && <a className={`next ${this.canNext() ? '' : 'disabled'}`} onClick={this.onNext}>
            <img src={rightIcon} />
          </a>}
        </div>

        <div className="selectionButtons">
          {images.map((image, i) => {
            return <a className={i === this.state.currentImage ? 'selected' : ''}
                      key={`floor-plan-image-${image.id}`}
                      onClick={(e) => this.clickPhoto(e, i)} />
          })}
        </div>
      </div>
    )
  }
}

export default FloorPlans