import React, {useEffect, useState} from 'react'
import Logo from "../../../../assets/images/logo.svg";
import {Link, useHistory} from "react-router-dom";
import {
  BUSINESS_DETAILS_STEP,
  CALENDAR_STEP,
  COMPLETED_STEP,
  GENERAL_INFO_STEP,
  ROLE_STEP,
  SERVICES_STEP,
  STEPS
} from "./utils";
import {Role} from "./steps/role";
import {GeneralInfo} from "./steps/general_info";
import {BusinessDetails} from "./steps/business_details";
import {Services} from "./steps/services";
import {Calendar} from "./steps/calendar";
import {Completed} from "./steps/completed";
import NavbarContainer from "../navbar/navbar_container";
import {sendBookConsultation} from "../../util/book_consultation_api_utils";

const componentMap = {
  [ROLE_STEP]: Role,
  [GENERAL_INFO_STEP]: GeneralInfo,
  [BUSINESS_DETAILS_STEP]: BusinessDetails,
  [SERVICES_STEP]: Services,
  [CALENDAR_STEP]: Calendar,
  [COMPLETED_STEP]: Completed
}

export const ScheduleDemo = () => {
  const history = useHistory()
  const [step, setStep] = useState(ROLE_STEP)
  const [data, setData] = useState({})
  const StepComponent = componentMap[step]

  useEffect(() => {
    document.body.classList.add('position-relative')
    return () => {
      document.body.classList.remove('position-relative')
    }
  }, []);

  const onNext = (d) => {
    const newData = {...data, [step]: d}
    setData(newData)

    if (step === CALENDAR_STEP) {
      const payload = Object.values(newData).reduce((res, fields) => {
        return {...res, ...fields}
      }, {})

      return sendBookConsultation(payload).then(() => {
        const stepIdx = STEPS.indexOf(step)
        setStep(STEPS[stepIdx + 1])
      })
    } else {
      const stepIdx = STEPS.indexOf(step)
      setStep(STEPS[stepIdx + 1])
    }
  }

  const onBack = () => {
    const stepIdx = STEPS.indexOf(step)
    setStep(STEPS[stepIdx - 1])
  }

  return <>
    <div className="z-2">
      <NavbarContainer nonAppPage={true} removeNavbar={true} />
    </div>

    <div className="schedule-demo-bg"/>
    <div className="schedule-demo-container">
      {step === ROLE_STEP ? <div className="scheduleTextGroup"><div className="scheduleTopText"> * This is for Real Estate Agents, Brokers, Home Owners and anyone listing residential or commercial properties *</div><div className="scheduleSecondText">Enter your info below to schedule a strategy session with me and discover the methods we’ve used to help thousands of listings stand out.</div></div>: <div className="schedule-demo-logo">
        <Link to="/">
          <img className="modernAnglesLoginLogo" src={Logo} onClick={() => history.push("/")}/>
        </Link>
      </div>}

      <div className="schedule-demo-form">
        <div className="schedule-demo-steps">
          {STEPS.map(s => (
            <div className={`schedule-demo-step ${s === step ? 'schedule-demo-step-active' : ''}`}
                 key={`step-${s}`}/>))}
        </div>

        <StepComponent onNext={onNext} onBack={onBack} data={data[step]}/>
      </div>
    </div>
  </>
}