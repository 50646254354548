import { connect } from "react-redux";
import NotifcationBell from "./notification_bell";
import React from "react";
import { openModal, closeModal } from "../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { fetchCurrentUser, fetchNotificationCount } from "../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  closeModal: () => { dispatch(closeModal()) },
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id)),
  fetchNotificationCount: (user_id) => dispatch(fetchNotificationCount(user_id))
});

export default withRouter(connect(msp, mdp)(NotifcationBell));