import { connect } from "react-redux";
import CreateFirmModal from "./create_firm_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { closeModal } from "../../../actions/modal_actions";
import { createFirm } from "../../../actions/settings_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  services: state.entities.services,
});

const mdp = (dispatch) => ({
  createFirm: (firm) => dispatch(createFirm(firm)),
  closeModal: () => { dispatch(closeModal()) },
});

export default withRouter(connect(msp, mdp)(CreateFirmModal));