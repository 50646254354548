import styled from 'styled-components';

import { VIRTUAL_STAGING } from '../../../../../assets/images/servicesPages';
const { VIRTUAL_STAGING_SLIDER } = VIRTUAL_STAGING;

export const VirtualStagingSliderWrapper = styled.div`
  width: 100%;
  position: relative;
  .container {
    padding: 0px 0px 80px 0px;
    position: relative;
    z-index: 2;
    max-width: 1110px;
    .slick-slider {
      .slick-dots {
        bottom: -47px;
        li {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #d8e0ff;
          margin: 0px 20px;
          @media only screen and (max-width: 991px) {
            width: 15px;
            height: 15px;
            margin: 0px 18px;
          }
          @media only screen and (max-width: 767px) {
            width: 10px;
            height: 10px;
            margin: 0px 10px;
          }
        }
        .slick-active {
          background-color: #324489;
        }
        button:before {
          content: '';
        }
      }
    }

    @media only screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  .container > div:first-child {
    div {
      div {
        transition: ${(props) =>
          props.isFirst
            ? 'all 0.6s ease-out !important'
            : 'all 0s ease-out !important'};
        -webkit-transition: ${(props) =>
          props.isFirst
            ? 'all 0.6s ease-out !important'
            : 'all 0s ease-out !important'};
      }
    }
  }

  &:after {
    content: '';
    height: 100%;
    width: 43%;
    position: absolute;
    bottom: 1%;
    left: 7%;
    background-image: url(${VIRTUAL_STAGING_SLIDER.SliderBackground});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    @media only screen and (max-width: 1899px) {
      bottom: -10%;
      left: 6%;
    }
    @media only screen and (max-width: 1799px) {
      left: 3%;
    }
    @media only screen and (max-width: 1699px) {
      left: -1%;
    }
    @media only screen and (max-width: 1599px) {
      width: 35%;
      height: 85%;
      bottom: -2%;
      left: 2%;
    }
    @media only screen and (max-width: 1499px) {
      height: 75%;
      bottom: -3%;
      left: -1%;
    }
    @media only screen and (max-width: 1399px) {
      height: 70%;
      bottom: 1%;
      left: -2%;
    }
    @media only screen and (max-width: 1299px) {
      height: 65%;
    }
    @media only screen and (max-width: 1199px) {
      height: 60%;
    }
    @media only screen and (max-width: 1099px) {
      height: 55%;
      bottom: 4%;
    }
    @media only screen and (max-width: 991px) {
      width: 72%;
      height: 94%;
      bottom: 0%;
      left: -25%;
    }
    @media only screen and (max-width: 899px) {
      bottom: -7%;
      left: -27%;
    }
    @media only screen and (max-width: 799px) {
      bottom: -13%;
      left: -37%;
    }
    @media only screen and (max-width: 699px) {
      bottom: -13%;
      left: -45%;
      width: 85%;
    }
    @media only screen and (max-width: 600px) {
      bottom: 5%;
      left: -35%;
      width: 75%;
      height: 90%;
    }
    @media only screen and (max-width: 499px) {
      bottom: -2%;
      left: -25%;
    }
    @media only screen and (max-width: 425px) {
      width: 80%;
      bottom: 2%;
      left: -38%;
    }
    @media only screen and (max-width: 399px) {
      width: 88%;
      bottom: 2%;
      left: -38%;
    }
  }

  @media only screen and (max-width: 991px) {
  }
  @media only screen and (max-width: 767px) {
  }
`;

export const InputRangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 16px;
  @media only screen and (max-width: 991px) {
    padding: 0px 15px;
  }
`;

export const RangeBeforeAfter = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 52px;
  align-items: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #6a718a;

  @media only screen and (max-width: 991px) {
    font-size: 15px;
    line-height: 52px;
  }
`;

export const InputRangeContainer = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
`;

export const RangeWrapper = styled.div`
  width: 96%;
  height: 3px;
  background-color: #c4cce5;
  @media only screen and (max-width: 1199px) {
    width: 96%;
  }
  @media only screen and (max-width: 899px) {
    width: 95%;
  }
  @media only screen and (max-width: 767px) {
    width: 94%;
  }
  @media only screen and (max-width: 669px) {
    width: 93%;
  }
  @media only screen and (max-width: 599px) {
    width: 92%;
  }
  @media only screen and (max-width: 499px) {
    width: 94%;
  }
`;

export const RangeIcon = styled.div`
  width: 68px;
  height: 30px;
  border: 0;
  border-radius: 12px;
  background: url(${VIRTUAL_STAGING_SLIDER.RangeIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:focus {
    border: 0px;
    outline: none;
  }

  &:focus-visible {
    border: 0px;
    outline: none;
  }

  @media only screen and (max-width: 499px) {
    width: 45px;
    height: 22px;
    border-radius: 10px;
  }
`;

export const SlideItemWrapper = styled.div`
  height: 550px;
  position: relative;
  @media only screen and (max-width: 991px) {
    height: 460px;
  }

  @media only screen and (max-width: 600px) {
    height: 220px;
  }
`;

export const SlideItemPostImage = styled.div`
  border-radius: 12px;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(${(props) => props.postImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media only screen and (max-width: 991px) {
    border-radius: 0px;
  }
`;

export const SlideItemPreImage = styled.div.attrs((props) => ({
  style: {
    width: props.imageWidth + '%',
    borderRadius: props.imageWidth > 99 ? '12px' : '12px 0px 0px 12px',
  },
}))`
  position: absolute;
  height: 100%;
  overflow: hidden;

  background-image: url(${(props) => props.preImage});
  background-size: ${(props) => props.preImageWidth} 550px;
  transition: ${(props) =>
    props.isFirst
      ? 'all 0.6s ease-out !important'
      : 'all 0s ease-out !important'};
  -webkit-transition: ${(props) =>
    props.isFirst
      ? 'all 0.6s ease-out !important'
      : 'all 0s ease-out !important'};

  @media only screen and (max-width: 991px) {
    border-radius: 0px !important;
    background-size: ${(props) => props.preImageWidth} 460px;
  }

  @media only screen and (max-width: 600px) {
    background-size: ${(props) => props.preImageWidth} 220px;
  }
`;

export const ImageMarker = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 30px;
  right: 20px;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
