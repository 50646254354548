import React from "react";
import { Route, Link } from "react-router-dom";


export default class SettingsPreferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preference1: false,
      preference2: false,
      preference3: false,
      preference4: false
    }
    this.handleInput = this.handleInput.bind(this)
  }

  handleInput(field) {
    return e => this.setState({ [field]: !this.state[field] });
  }

  render() {
    let preference1 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #1</div> OFF <div key="1" onClick={this.handleInput("preference1")}><i className="fas fa-toggle-off offSwitchIcon"></i></div></div>
    let preference2 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #2</div> OFF <div key="2" onClick={this.handleInput("preference2")}><i className="fas fa-toggle-off offSwitchIcon"></i></div></div>
    let preference3 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #3</div> OFF <div key="3" onClick={this.handleInput("preference3")}><i className="fas fa-toggle-off offSwitchIcon"></i></div></div>
    let preference4 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #4</div> OFF <div key="4" onClick={this.handleInput("preference4")}><i className="fas fa-toggle-off offSwitchIcon"></i></div></div>

    if (this.state.preference1){
      preference1 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #1</div> ON <div key="5" onClick={this.handleInput("preference1")}><i className="fas fa-toggle-on onSwitchIcon"></i></div></div>
    }
    if (this.state.preference2) {
      preference2 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #2</div> ON <div key="6" onClick={this.handleInput("preference2")}><i className="fas fa-toggle-on onSwitchIcon"></i></div></div>
    }
    if (this.state.preference3) {
      preference3 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #3</div> ON <div key="7" onClick={this.handleInput("preference3")}><i className="fas fa-toggle-on onSwitchIcon"></i></div></div>
    }
    if (this.state.preference4) {
      preference4 = <div className="settingsPreferenceOption"><div className="settingsPreferenceOptionText">Preference #4</div> ON <div key="8" onClick={this.handleInput("preference4")}><i className="fas fa-toggle-on onSwitchIcon"></i></div></div>
    }




    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences">
          <div className="settingsComponentHeading">Your Preferences</div>
          <div className="settingsPreferencesGroup1">
            {preference1}
            {preference2}
            {preference3}
            {preference4}
          </div>
          <button onClick={this.updateCompany} className="settingsButton preferencesButton">Save changes</button>
        </div>
      </div>
    );
  }
}