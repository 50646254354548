import React from "react"
import trashIconSrc from "../../../../assets/images/icons/fi_trash.svg"


export default class VirtualStagingCheckout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  filepath(photo) {
    const urlParts = photo.url.split('/')
    let url = urlParts[urlParts.length - 1]
    return url.split('?')[0]
  }

  render() {
    let price = this.props.detailed_orders.reduce((res, order) => {
      return res + Object.values(order.selected_photos).length * order.category.price
    }, 0)
    let discount = 0;
    let account_credit = this.props.account_credit;
    let account_credit_component = "";

    if (this.props.account_credit && price > 0) {
      if (this.props.account_credit >= price) {
        account_credit = this.props.account_credit - price
        price = 0
        discount = this.props.account_credit - account_credit
      } else {
        price = price - this.props.account_credit
        discount = this.props.account_credit
        account_credit = 0
      }
    }

    if (discount > 0) {
      account_credit_component = <tr className="section-top">
        <td />
        <td colSpan={3}>Account credit applied: </td>
        <td colSpan={2}>-${discount.toFixed(2)}</td>
      </tr>
    }

    return (
      <div>
        <div className="modalTitle">Checkout</div>
        <div className="modalSubTitle">Below is a list of photos that would go to virtual staging</div>

        <table className="w-100 mt-4 editProjectServicesTable">
          <thead className="tableHeadVirtualStaging">
            <tr>
              <th></th>
              <th>Category</th>
              <th>Product</th>
              <th>Photo</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
          {this.props.detailed_orders.map((order, i) => {
            return Object.values(order.selected_photos).map((photo, j) => {
              const isLastRow = i === this.props.detailed_orders.length - 1 &&
                j === Object.values(order.selected_photos).length - 1

              return <tr key={`order-${i}-${j}`} className={isLastRow ? "section-bottom" : ""}>
                <td/>
                <td>{order.category.name}</td>
                <td>{order.product.name}</td>
                <td className="text-truncate tuncated-text">{this.filepath(photo)}</td>
                <td className="text-muted">${parseInt(order.category.price).toFixed(2)}</td>
                <td className="text-muted pe-0 ps-3 deleteIconColumn">
                  <img src={trashIconSrc} className="cursor-pointer"
                       onClick={() => this.props.deleteOrderPhoto(i, j)}/>
                </td>
              </tr>
            })
          })}

         {account_credit_component}

          <tr className="section-top">
            <td />
            <td colSpan={3}>Total</td>
            <td colSpan={2}>${price.toFixed(2)}</td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}