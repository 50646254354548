import React from "react";
import { Route, Link } from "react-router-dom";


export default class SettingsPromosItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    let promo = this.props.promo

    return (
      <tr>
        <td>{promo.name}</td>
        <td>{promo.firm?.name}</td>
        <td className="itemIcon" onClick={() => this.props.updatePromo(promo)}><i className="far fa-edit settingsServiceIcon m-2"></i></td>
        <td className="itemIcon itemIcon2" onClick={() => { if (window.confirm('Are you sure you want to delete this promotion?')) { this.props.deletePromo(promo) } }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></td>
      </tr>
    );
  }
}