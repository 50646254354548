import CloseHeaderMenu from './CloseHeaderMenu.svg';
import WebLogo from './web-logo.svg';
import HeaderMenu from './header-menu.svg';

import TopRightWave from './top-right-wave.png';
import MiddlePageWave from './middle-page-wave.png';
import ImageSliderMarker from './image-slider-marker.svg';

import Slider1 from './slider-1.jpg';
import Slider2 from './slider-2.jpeg';
import Slider3 from './slider-3.jpeg';
import Slider4 from './slider-4.jpeg';
import Slider5 from './slider-5.jpeg';
import Slider6 from './slider-6.jpeg';
import Slider7 from './slider-7.jpeg';

import DouglasLogo from './douglas-logo.svg';
import SothebyLogo from './sotheby-logo.svg';
import CompassLogo from './compass-logo.svg';
import ColdWellLogo from './coldwell-logo.svg';
import BerkshireLogo from './berkshire-logo.svg';
import GinnelLogo from './ginnel-logo.png';
import HLLogo from './hl-logo.png';

import Panel1 from './creativeMedia/panel-1.1.jpg';
import Panel1_1 from './creativeMedia/panel-1.2.jpg';
import Panel1Landscape from './creativeMedia/panel-1.1Landscape.png';
import Panel1_1Landscape from './creativeMedia/panel-1.2Landscape.png';
import Panel3 from './creativeMedia/panel03.png';
import Panel03Landscape from './creativeMedia/panel03Landscape.png';
import Panel4 from './creativeMedia/panel04.png';
import Panel04Landscape from './creativeMedia/panel04Landscape.png';
import Panel5 from './creativeMedia/panel05.png';
import Panel05Landscape from './creativeMedia/panel05Landscape.png';
import Panel6 from './creativeMedia/panel06.png';
import Panel06Landscape from './creativeMedia/panel06Landscape.png';
import Panel7 from './creativeMedia/panel07.png';
import Panel07Landscape from './creativeMedia/panel07Landscape.png';
import RightArrow from './creativeMedia/rightArrow.svg';
import CreativeMediaBackground from './creativeMedia/CreativeMediaBackground.svg';

import Contract1 from './contract/Contract-1.jpeg';
import Contract2 from './contract/Contract-2.jpeg';
import Contract3 from './contract/Contract-3.jpeg';
import Contract4 from './contract/Contract-4.jpeg';

import ContractMediaSlide1Part1 from './contract/slide1/contractMediaSlide1Part1.png';
import ContractMediaSlide1Part2 from './contract/slide1/contractMediaSlide1Part2.png';
import ContractMediaSlide1Part3 from './contract/slide1/contractMediaSlide1Part3.png';
import ContractMediaSlide1Part3vs from './contract/slide1/contractMediaSlide1Part3vs.png';

import ContractMediaSlide2Part1 from './contract/slide2/contractMediaSlide2Part1.png';
import ContractMediaSlide2Part2 from './contract/slide1/contractMediaSlide1Part1.png';
import ContractMediaSlide2Part3 from './contract/slide2/contractMediaSlide2Part3.png';
import ContractMediaSlide2Part4 from './contract/slide2/contractMediaSlide2Part4.png';
import ContractMediaSlide2Part1vs from './contract/slide2/contractMediaSlide2Part1vs.png';
import ContractMediaSlide2Part2vs from './contract/slide2/contractMediaSlide2Part2vs.png';
import ContractMediaSlide2Part3vs from './contract/slide2/contractMediaSlide2Part3vs.png';
import ContractMediaSlide2Part4vs from './contract/slide2/contractMediaSlide2Part4vs.png';

import ContractMediaSlide3Part1 from './contract/slide3/contractMediaSlide3Part1.png';
import ContractMediaSlide3Part2 from './contract/slide3/contractMediaSlide3Part2.png';
import ContractMediaSlide3Part3 from './contract/slide3/contractMediaSlide3Part3.png';
import ContractMediaSlide3Part1vs from './contract/slide3/contractMediaSlide3Part1vs.png';
import ContractMediaSlide3Part2vs from './contract/slide3/contractMediaSlide3Part2vs.png';
import ContractMediaSlide3Part3vs from './contract/slide3/contractMediaSlide3Part3vs.png';

import ContractMediaSlide4Part1 from './contract/slide4/contractMediaSlide4Part1.png';
import ContractMediaSlide4Part2 from './contract/slide4/contractMediaSlide4Part2.png';
import ContractMediaSlide4Part1vs from './contract/slide4/contractMediaSlide4Part1vs.png';
import ContractMediaSlide4Part2vs from './contract/slide4/contractMediaSlide4Part2vs.png';

import ContractBackground from './contract/ContractBackground.svg';
import LabelArrow from './contract/LabelArrow.png';
import Feature1 from './contract/Feature-1.svg';
import Feature2 from './contract/Feature-2.svg';
import Feature3 from './contract/Feature-3.svg';
import Feature4 from './contract/Feature-4.svg';
import DottedLine1 from './contract/dotted-line-1.png';
import DottedLine2 from './contract/dotted-line-2.png';
import DottedLine3 from './contract/dotted-line-3.png';
import MobileArrow from './contract/mobile_arrow.png';

import Quotes from './testimonials/quotes.svg';
import Customer1 from './testimonials/customer-1.png';
import Customer2 from './testimonials/customer-2.png';
import Customer3 from './testimonials/customer-3.png';
import Customer4 from './testimonials/customer-4.png';
import Customer5 from './testimonials/customer-5.png';
import Customer6 from './testimonials/customer-6.png';
import Customer7 from './testimonials/customer-7.png';
import Customer8 from './testimonials/customer-8.png';
import Property1 from './testimonials/property-1.jpg';
import Property2 from './testimonials/property-2.jpg';
import Property3 from './testimonials/property-3.jpg';
import Property4 from './testimonials/property-4.jpg';
import Property5 from './testimonials/property-5.jpg';
import Property6 from './testimonials/property-6.jpg';
import Property7 from './testimonials/property-7.jpg';
import Property8 from './testimonials/property-8.jpg';

import PropertyHome from './homeProperty/home.svg';
import Rectangle from './homeProperty/rectangle.svg';
import Happy from './homeProperty/happy.svg';
import Stock from './homeProperty/stock.svg';
import PropertyImge01 from './homeProperty/PropertyImge01.jpg';
import PropertyImage from './homeProperty/propertyImage.png'
import Union from './homeProperty/Union.jpg';
import Location from './homeProperty/Location.png';
import UnionIcon from './mediaProfessionals/union-icon.png';
import PinkCircleImage from './mediaProfessionals/pink-circle.png';
import PinkCircleSmallImage from './mediaProfessionals/pink-circle-small.png';
import BlockImage1 from './mediaProfessionals/block-image-1.png';
import BlockImage2 from './mediaProfessionals/block-image-2.png';
import BlockImage3 from './mediaProfessionals/block-image-3.png';
import BlockImage4 from './mediaProfessionals/block-image-4.png';
import BlockImage5 from './mediaProfessionals/block-image-5.png';
import BlockImage6 from './mediaProfessionals/block-image-6.png';
import MediaProfessionalIcon from './mediaProfessionals/icon.svg';
import CurvedLine from './mediaProfessionals/curved-line.png';
import ClosePopUp from './mediaProfessionals/close_popup.png';
import BlockImage7 from './mediaProfessionals/block-image-7.png';
import BlockImage8 from './mediaProfessionals/block-image-8.png';
import BlockImage9 from './mediaProfessionals/block-image-9.png';

import FooterWave from './footer/footerBackground.png';
import FooterRightWave from './footer/footerRightWave.png';
import Instagram from './footer/Instagram.png';
import Facebook from './footer/Facebook.png';
import Linkedin from './footer/LinkedIn.png';
import FooterLogo from './footerlogo.svg';

import PhotoIcon from './photo-icon.svg'
import ReceiptIcon from './receipt-check-icon.svg'
import ZapIcon from './zap-fast-icon.svg'
import BadgeIcon from "./badge-icon.png"

import MOBILE_LOGOOUT_BG from './navbar/mobile-logout-background.svg';
import MOBILE_LOGOUT_CROSS from './navbar/mobile-logout-cross.svg';
import MOBILE_LOGOUT_LOGO from './navbar/mobile-logout-logo.svg';
import ServiceDropdownUp from './navbar//serviceDropdownUp.svg';
import ServiceDropdownDown from './navbar//serviceDropdownDown.svg';

const NAV_IMAGES = {
  WebLogo,
  HeaderMenu,
  CloseHeaderMenu,
  MOBILE_LOGOOUT_BG,
  MOBILE_LOGOUT_CROSS,
  MOBILE_LOGOUT_LOGO,
  ServiceDropdownUp,
  ServiceDropdownDown,
};

const LANDING_PAGE = {
  TopRightWave,
  ImageSliderMarker,
  MiddlePageWave,
  DouglasLogo,
  SothebyLogo,
  CompassLogo,
  ColdWellLogo,
  BerkshireLogo,
  GinnelLogo,
  HLLogo
};

const DIFFERENCES = {
  PhotoIcon,
  ReceiptIcon,
  ZapIcon,
  BadgeIcon
}

const TOP_SLIDER = [
  { id: 1, src: Slider1, caption: 'Image-1', width: 1344, height: 1218 },
  { id: 2, src: Slider2, caption: 'Image-2', width: 1145, height: 1218 },
  { id: 3, src: Slider3, caption: 'Image-3', width: 828, height: 1218 },
  { id: 4, src: Slider4, caption: 'Image-4', width: 825, height: 1218 },
  { id: 5, src: Slider5, caption: 'Image-5', width: 1560, height: 1218 },
  { id: 6, src: Slider6, caption: 'Image-6', width: 1088, height: 1218 },
  { id: 7, src: Slider7, caption: 'Image-7', width: 865, height: 1218 },
];

const CREATIVE_MEDIA = {
  Panel1,
  Panel1_1,
  Panel1Landscape,
  Panel1_1Landscape,
  Panel3,
  Panel03Landscape,
  Panel4,
  Panel04Landscape,
  Panel5,
  Panel05Landscape,
  Panel6,
  Panel06Landscape,
  Panel7,
  Panel07Landscape,
  RightArrow,
  CreativeMediaBackground,
};

const CONTRACT = {
  ContractMedia: [Contract1, Contract2, Contract3, Contract4],
  ContractMediaSlides: [
    {
      id: 0,
      ContractMediaSlide1Part1,
      ContractMediaSlide1Part2,
      ContractMediaSlide1Part3,
    },
    {
      id: 1,
      ContractMediaSlide2Part1,
      ContractMediaSlide2Part2,
      ContractMediaSlide2Part3,
      ContractMediaSlide2Part4,
    },
    {
      id: 2,
      ContractMediaSlide3Part1,
      ContractMediaSlide3Part2,
      ContractMediaSlide3Part3,
    },
    {
      id: 3,
      ContractMediaSlide4Part1,
      ContractMediaSlide4Part2,
    },
  ],
  ContractMediaSlidesVS: [
    {
      id: 0,
      ContractMediaSlide1Part1,
      ContractMediaSlide1Part2,
      ContractMediaSlide1Part3: ContractMediaSlide1Part3vs,
    },
    {
      id: 1,
      ContractMediaSlide2Part1: ContractMediaSlide2Part1vs,
      ContractMediaSlide2Part2: ContractMediaSlide2Part2vs,
      ContractMediaSlide2Part3: ContractMediaSlide2Part3vs,
      ContractMediaSlide2Part4: ContractMediaSlide2Part4vs,
      ContractMediaSlide2Part5: ContractMediaSlide1Part1,
    },
    {
      id: 2,
      ContractMediaSlide3Part1: ContractMediaSlide3Part1vs,
      ContractMediaSlide3Part2: ContractMediaSlide3Part2vs,
      ContractMediaSlide3Part3: ContractMediaSlide3Part3vs,
      ContractMediaSlide3Part4: ContractMediaSlide1Part1,
    },
    {
      id: 3,
      ContractMediaSlide4Part1: ContractMediaSlide4Part1vs,
      ContractMediaSlide4Part2: ContractMediaSlide4Part2vs,
    },
  ],
  ContractBackground,
  LabelArrow,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  DottedLine1,
  DottedLine2,
  DottedLine3,
  MobileArrow,
};

const TESTIMONIALS = {
  Quotes,
  Customer1,
  Customer2,
  Customer3,
  Customer4,
  Customer5,
  Customer6,
  Customer7,
  Customer8,
  Property1,
  Property2,
  Property3,
  Property4,
  Property5,
  Property6,
  Property7,
  Property8
};

const PROPERTY = {
  PropertyHome,
  Rectangle,
  Happy,
  Stock,
  PropertyImge01,
  PropertyImage,
  Union,
  Location,
};
const MEDIA_PROFESSIONALS = {
  UnionIcon,
  PinkCircleImage,
  PinkCircleSmallImage,
  BlockImage1,
  BlockImage2,
  BlockImage3,
  BlockImage4,
  BlockImage5,
  BlockImage6,
  BlockImage7,
  BlockImage8,
  BlockImage9,
  MediaProfessionalIcon,
  CurvedLine,
  ClosePopUp,
};

const FOOTER_IMAGE = {
  FooterLogo,
  FooterWave,
  FooterRightWave,
  Instagram,
  Facebook,
  Linkedin
};

export {
  NAV_IMAGES,
  TOP_SLIDER,
  LANDING_PAGE,
  CREATIVE_MEDIA,
  CONTRACT,
  TESTIMONIALS,
  PROPERTY,
  MEDIA_PROFESSIONALS,
  FOOTER_IMAGE,
  DIFFERENCES
};
