import React from "react";
import {deleteTermsOfServiceVersion, fetchTermsOfServiceHistory} from "../../../util/terms_of_service_util";


export default class SettingsTermsOfService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsHistory: []
    }
  }

  componentDidMount() {
    fetchTermsOfServiceHistory().then(termsHistory => {
      this.setState({termsHistory})
    })
  }

  deleteVersion(termsVersion) {
    if (window.confirm('Are you sure you want to delete this version?')) {
      deleteTermsOfServiceVersion(termsVersion.id).then(() => {
        this.setState({termsHistory: this.state.termsHistory?.filter(version => version !== termsVersion)})
        this.props.showToast("Version of Term of Service has been removed")
      }).catch(() => {
        alert('Terms of service could not be deleted')
      })
    }
  }

  onTermsVersionAdded(termsVersion) {
    this.setState({termsHistory: [...this.state.termsHistory, termsVersion]})
    this.props.showToast("New version of Term of Service has been added")
  }

  onAddTermsVersion(e) {
    e.preventDefault()
    const props = {onTermsVersionAdded: this.onTermsVersionAdded.bind(this)};
    this.props.openModal("createTermsOfService", props)
  }

  onViewTermsOfService(e, terms) {
    e.preventDefault();
    this.props.openModal("viewTermsOfService", {terms})
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences">
          <div className="settingsComponentHeading">Terms of Service</div>
          <div className="settingsPreferencesGroup1">
            {this.state.termsHistory.map(terms => (
              <div className="settingsServicesItem" key={terms.id}>
                <div className="itemTitle">{terms.version}</div>
                <div className="itemTitle">{terms.user_role}</div>
                <div className="itemIcon" onClick={(e) => this.onViewTermsOfService(e, terms)}>
                  <i className="far fa-eye settingsServiceIcon m-2" />
                </div>
                <div className="itemIcon" onClick={() => {this.deleteVersion(terms)}}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2" />
                </div>
              </div>
            ))}
          </div>
          <button onClick={(e) => this.onAddTermsVersion(e)} className="settingsButton preferencesButton">Add New Version
          </button>
        </div>
      </div>
    );
  }
}