import { connect } from "react-redux";
import ConfirmationForm from "./confirmation_form";
import React from "react";
import { openModal, closeModal } from "../../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { submitConfirmationResend, receiveSessionErrors } from "../../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
});

const mdp = (dispatch) => ({
  clearErrors: () => dispatch(receiveSessionErrors([])),
  submitConfirmationResend: (user) => dispatch(submitConfirmationResend(user))
});

export default withRouter(connect(msp, mdp)(ConfirmationForm));