import { connect } from "react-redux";
import VirtualStagingPhotos from "./virtual_staging_photos";
import React from "react";
import { withRouter } from "react-router-dom"
import { fetchPhotos } from "../../actions/virtual_staging_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  photos: Object.values(state.entities.virtualStaging.photos)
});

const mdp = (dispatch) => ({

});

export default withRouter(connect(msp, mdp)(VirtualStagingPhotos));