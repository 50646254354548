import React from "react"
import { updateProject } from "../../util/project_api_util"
import { withRouter } from "react-router-dom";
import { destroyVirtualStagingBatch, destroyVirtualStagingPurchase, destroyAllBatches } from "../../util/virtual_staging_api_util";

class RemoveBatchModal extends React.Component {
  constructor(props) {
    super(props)
    this.createRefund = this.createRefund.bind(this)
    this.createAccountCredit = this.createAccountCredit.bind(this)

    this.state = {
      errors: [],
      loading: false
    }
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  createRefund() {
    this.removeBatchOrPurchase(true)
  }

  createAccountCredit() {
    this.removeBatchOrPurchase()
  }

  removeBatchOrPurchase(refund = false) {
    const {batch, onServiceRemoved, purchase, all_batches, project } = this.props

    this.setState({ errors: [], loading: true })

    if (all_batches){
      const data = {project_id: project.id, refund: refund}

      destroyAllBatches(data).then(batch => {
        if (batch.errors) {
          this.setState({ errors: batch.errors, loading: false })
        } else {
          onServiceRemoved()
        }
      })
    }
    else if (!purchase){
      const data = { id: batch.id, refund: refund }

      destroyVirtualStagingBatch(data).then(batch => {
        if (batch.errors) {
          this.setState({ errors: batch.errors, loading: false })
        } else {
          onServiceRemoved()
        }
      })
    } else {
      const data = { id: batch.id, refund: refund, virtual_staging_purchase_id: purchase.id }

      destroyVirtualStagingPurchase(data).then(purchase => {
        if (purchase.errors) {
          this.setState({ errors: purchase.errors, loading: false })
        } else {
          onServiceRemoved()
        }
      })
    }
  }

  render() {
    const { onClose, subService, batch, purchase, all_batches, project } = this.props
    let price = 0 

    if (batch){
      price = batch.price
    }

    if (purchase){
      price = purchase.price
    }

    let virtual_staging_batches = Object.values(project.virtual_staging_batches)

    if (all_batches){
      price = 0
      virtual_staging_batches.forEach(virtual_staging_batch => {
        price += virtual_staging_batch.price
      })
    }

    let invoice_total = 0

    if (batch) {
      invoice_total = batch.invoice.total
    }

    if (all_batches){
      invoice_total = 0 
      virtual_staging_batches.forEach(virtual_staging_batch => {
        invoice_total += parseInt(virtual_staging_batch.invoice?.total || 0)
      })
    }

    return <div className="modal-background">
      <div className={`orderpreviewModal refundModal`}>
        <div className="orderPreviewModalComponent">
          <div>
            <button className="projectEditButton projectEditButtonLight modalCloseButton" onClick={onClose}>
              Close
            </button>

            <div className="refundModalTitle">Refund Details</div>

            <div className="refundModalText">
              We're sorry to hear that you are planning to cancel your package(s).
              <br />
              Based on our refund policy we can offer you two options.
            </div>

            <div className="refundModalSubTitle">
              Direct Refund
            </div>

            <div className="refundModalText">
              If you would like a direct refund there will be a 5% fee based on processing costs.
            </div>

            <div className="refundModalSubTitle">Account Credit</div>

            <div className="refundModalText">
              Account credit can be chosen instead which will be automatically applied to a future purchase. With
              account credit there is no fees so you will receive the full refund amount in credit.
            </div>

            <div className="refundModalBox">
              <div className="refundModalSubTitle mb-3">Choose the option which works best for you:</div>
              <button className="projectEditButton projectEditButtonPrimary mb-3 ms-0 w-100"
                onClick={this.createAccountCredit} disabled={this.state.loading}>
                Account Credit: ${(price * 1).toFixed(2)}
              </button>
              <button className="projectEditButton projectEditButtonPrimary mb-3 w-100"
                onClick={this.createRefund} disabled={this.state.loading}>
                Refund: ${invoice_total >= price * .95 ? (price * 0.95).toFixed(2) : `${(invoice_total * .95).toFixed(2)} + $${(price - invoice_total).toFixed(2)} account credit`}
              </button>
              <button className="projectEditButton projectEditButtonWhite w-100" onClick={onClose}>
                I no longer wish to cancel this service
              </button>
            </div>
          </div>
        </div>
        <div className="modalMobilePushup"></div>
      </div>
    </div>
  }
}

export default withRouter(RemoveBatchModal)