export const sendContactsMessage = (data) => (
  $.ajax({
    type: "POST",
    url: "/contacts",
    data: data
  })
);

export const sendInvites = (emails) => (
  $.ajax({
    type: "POST",
    url: "/send_invites",
    data: {emails: emails}
  })
);


export const sendPhotographerInvites = (emails) => (
  $.ajax({
    type: "POST",
    url: "/send_photographer_invites",
    data: { emails: emails }
  })
);

export const shareMediaToEmails = (emails, project_id) => (
  $.ajax({
    type: "POST",
    url: "/share_media_to_emails",
    data: { emails, project_id }
  })
);