import { connect } from "react-redux";
import SubServices from "./sub_services";
import React from "react";
import { withRouter } from "react-router-dom";
import { updateService, updateSubService, createSubService, deleteSubService} from "../../../actions/service_actions";
import { closeModal } from "../../../actions/modal_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
});

const mdp = (dispatch) => ({
  updateSubService: (sub_service_data, sub_service_id) => dispatch(updateSubService(sub_service_data, sub_service_id)),
  createSubService: (sub_service_data) => dispatch(createSubService(sub_service_data)),
  deleteSubService: (sub_service_id) => dispatch(deleteSubService(sub_service_id)),
  closeModal: () => { dispatch(closeModal()) },
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SubServices));