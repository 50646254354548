import { connect } from "react-redux";
import ForgotPasswordForm from "./forgot_password_form";
import React from "react";
import { openModal, closeModal } from "../../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { submitForgotPassword, receiveSessionErrors} from "../../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
});

const mdp = (dispatch) => ({
  clearErrors: () => dispatch(receiveSessionErrors([])),
  successError: (message) => dispatch(receiveSessionErrors(message)),
  submitForgotPassword: (user) => dispatch(submitForgotPassword(user))
});

export default withRouter(connect(msp, mdp)(ForgotPasswordForm));