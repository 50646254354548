import { connect } from "react-redux";
import Dashboard from "./dashboard";
import React from "react";
import { openModal, closeModal } from "../actions/modal_actions";
import { withRouter } from "react-router-dom"
import { fetchUserProjects } from "../actions/project_actions";
import { logout, agreeToTerms } from "../actions/session_actions";
import { fetchCurrentUser } from "../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  projects: state.entities.projects["projects"]
});

const mdp = (dispatch) => ({
  closeModal: () => { dispatch(closeModal()) },
  fetchUserProjects: (user_id, dashboard) => { dispatch(fetchUserProjects(user_id, null, null, dashboard)) },
  agreeToTerms: (user_id) => dispatch(agreeToTerms(user_id)),
  logout: () => dispatch(logout()),
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id))
});

export default withRouter(connect(msp, mdp)(Dashboard));