import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import logoImage from '../../../../assets/images/MA_Logo_New_2'
import newProjectIcon from '../../../../assets/images/new-project-icon'
import homeIcon from '../../../../assets/images/homeIcon'
import projectIcon from '../../../../assets/images/Foldericon'
import invoiceIcon from '../../../../assets/images/invoiceIcon'
import referralsIcon from '../../../../assets/images/referralsIcon'
import photoIcon from '../../../../assets/images/photoIcon'
import settingsIcon from '../../../../assets/images/settingsIcon'
import supportIcon from '../../../../assets/images/supportIcon'
import UserAvatar from "../photographers/photograher_avatar"


class NavbarLoggedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarOpen: true,
    }
  }


  render() {
    const {currentUser} = this.props;

    if (this.state.navbarOpen && !this.props.isLargeScreenNavbarWidth) {
      this.setState({navbarOpen: false})
    } else if (!this.state.navbarOpen && this.props.isLargeScreenNavbarWidth) {
      this.setState({navbarOpen: true})
    }

    let navLinkNameClass = ""
    let navStartProjectButtonClass = ""
    let navStartProjectText = ""
    let navIconGroupClass = ""

    if (this.state.navbarOpen) {
      navLinkNameClass = "navLinkName"
      navStartProjectButtonClass = "navStartProjectButton"
      navStartProjectText = "Schedule Shoot"
      navIconGroupClass = "navIconGroup"
    } else {
      navLinkNameClass = "navLinkNameHidden"
      navStartProjectButtonClass = "navStartProjectButtonSmall"
      navStartProjectText = <img src={newProjectIcon}/>
      navIconGroupClass = "navIconGroupSmall"
    }

    let avatarNavbar = ""

    if (this.props.isLargeScreenHeight && this.state.navbarOpen) {
      avatarNavbar = <NavLink className="avatarNavbar" to="/settings" activeClassName="navActive">
        <UserAvatar user={currentUser}/>
        <div className="navbarAvatarName">{currentUser.firstName} {currentUser.lastName}</div>
        <div
          className="navbarAvatarRole">{currentUser.account_credit?.amount ? `Account Credit: $${currentUser?.account_credit?.amount}` : ""}</div>
      </NavLink>
    } else {
      avatarNavbar = ""
    }

    let user_role = ""

    if (currentUser.roles.admin) {
      user_role = "Admin"
    } else if (currentUser.roles.client) {
      user_role = "Client"
    } else if (currentUser.roles.broker || currentUser.roles.sub_broker) {
      user_role = "Broker"
    } else if (currentUser.roles.post_production) {
      user_role = "Post Production"
    } else if (currentUser.roles.contractor) {
      user_role = "Contractor"
    } else if (currentUser.roles.sales_rep) {
      user_role = "Sales Rep"
    }

    let startProject = <NavLink className={navStartProjectButtonClass} to="/create-project" activeClassName="navActive">
      {navStartProjectText}
    </NavLink>

    const links = [
      {
        label: 'Home',
        path: '/dashboard',
        icon: homeIcon,
        canShow: () => user_role !== 'Post Production' && user_role !== "Sales Rep"
      },
      {
        label: 'Photoshoots',
        path: '/projects_index',
        icon: projectIcon,
        canShow: () => user_role !== "Sales Rep"
      },
      {
        label: 'Photographers',
        path: '/photographers',
        icon: photoIcon,
        canShow: () => user_role !== 'Post Production' && user_role !== "Sales Rep" && user_role !== "Contractor",
        isActive: (_, location) => location.pathname.startsWith('/photographers') && location.pathname !== `/photographers/${currentUser.id}`
      },
      {
        label: 'My Profile',
        path: `/photographers/${currentUser.id}`,
        icon: photoIcon,
        canShow: () => user_role === 'Contractor'
      },
      {
        label: 'Referrals',
        path: '/referral',
        icon: referralsIcon,
        canShow: () => user_role !== 'Post Production'
      },
      {
        label: 'Invoices',
        path: '/invoices_index',
        icon: invoiceIcon,
        canShow: () => user_role !== 'Contractor' && user_role !== 'Post Production' && user_role !== "Sales Rep"
      },
      {
        label: 'Payouts',
        path: '/payouts',
        icon: invoiceIcon,
        canShow: () => user_role === 'Contractor' || user_role === 'Admin' || user_role === "Sales Rep"
      },
      {
        label: 'Settings',
        path: '/settings',
        icon: settingsIcon,
        canShow: () => true
      },
      {
        label: 'Support',
        path: '/support',
        icon: supportIcon,
        canShow: () => true
      }
    ]

    if (user_role === "Post Production" || user_role === "Contractor" || user_role === "Sales Rep") {
      startProject = ""
    }

    return (
      <nav className="react-navbar">
        {/* <img className="navbarHamMenu" src={hamIcon} onClick={() => this.setState({navbarOpen: !this.state.navbarOpen})}/> */}
        <NavLink to="/" activeClassName="navActive">
          <img className="reactNavLogoImage" src={logoImage}/>
        </NavLink>
        {avatarNavbar}
        <div className={navIconGroupClass}>

          {links.map(link => {
            if (link.canShow()) {
              return <NavLink className="navLink" activeClassName="navActive" to={link.path} isActive={link.isActive}>
                <img src={link.icon}/>
                <div className={navLinkNameClass}>{link.label}</div>
              </NavLink>
            }
          })}
        </div>
        {startProject}
      </nav>
    )
  }
}

export default withRouter(NavbarLoggedIn);