import React from "react";
import debounce from 'debounce'
import { Route, Link, withRouter } from "react-router-dom";
import LocationComponent from "../location_component";
import ServicesSelectionComponent from "../create_project_2/services_selection_component_2";
import PhotographerSelectionComponent from "../create_project/photographer_selection_component";
import OrderSummaryComponent from "../create_project/order_summary_component";
import ProjectCreatedModal from "../create_project/project_created_modal";
import ContractorCalendar from "../create_project/contractor_calendar_component_container"
import ChooseTimeModal from "../create_project/choose_time_modal";
import { fetchTimeSlots } from "../../util/settings_api_util";
import SignupAndPaymentModalContainer from "./signup_and_payment_modal_container";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import {ReactComponent as CloseIcon} from '../../../../assets/images/close-icon.svg'
import AddMoreServicesHomepageModal from "./add_more_services_homepage_modal";
import ServiceChoiceComponent from "../create_project_2/service_choice_component";
import { fetchBundleGroups } from "../../util/bundle_api_utils";
import {formatDatetime} from "../../util/date_util";
import { detectTimezone } from "../../util/timezone_utils"
import { removeFromList, subscribeToList } from "../../util/ad_api_utils";
import { applyCoupon } from "../../util/discount_code_api_utils";
import { fetchUnavailability } from "../../util/project_api_util";
import { destroyUser } from "../../util/users_api_util";
import AddonSubServicesSelectionComponent from "../create_project_2/addon_subservices_selection_component"
import { fetchLocationSubServices } from "../../util/service_api_util";

class CreateProjectHomePageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      county: "",
      street: "",
      apartment: "",
      zipcode: "",
      state: "",
      city: "",
      latitude: "",
      longitude: "",
      formatted_address: "",
      note: "",
      currentUser: {},
      user_emails: [],
      photographer: "",
      detected_timezone: "",
      emails: [],
      package_attributes: {},
      selectedSubservices: {},
      currentlySelectedService: null,
      cancelledPackages: {},
      appointments: {},
      selected_service: -1,
      specific_service: -1,
      main_service: {},
      previouslyChosen: {},
      selected_bundle: {},
      bundle_groups: [],
      discount: 0,
      waiting: false,
      loading: true,
      bundleLoading: true,
      modalOpen: false,
      contractorModalOpen: false,
      chooseTimeModalOpen: false,
      goingBackToAddress: false,
      alacartSelected: true,
      bundleSelected: false, 
      addonSubServices: false,
      account_credit: this.props.currentUser?.account_credit?.amount,
      account_credit_id: "",
      contractorSearch: "",
      contractorDateSearch: "",
      contractorTimeSearch: "",
      twilight_time: "",
      subModal: "",
      promos: [],
      user: {},
      firm: {},
      project: {},
      errors: [],
      propertyErrors: [],
      summaryErrors: [],
      backendErrors: [],
      time_slots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} },
      page: 1,
      coupon: {},
      location_pricing: {}
    }

    this.handler = this.handler.bind(this)
    this.setAppointmentTime = this.setAppointmentTime.bind(this)
    this.submitOrder = this.submitOrder.bind(this)
    this.previewOrder = this.previewOrder.bind(this)
    this.deleteSubService = this.deleteSubService.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.debounceFetchContractors = debounce((service, zip, search, date, time, services, appointments, timezone) => this.props.fetchContractors(service, zip, search, date, time, services, appointments, timezone), 500)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
    this.onContractorSelect = this.onContractorSelect.bind(this)
    this.onClose = this.onClose.bind(this)
    this.calculatePrice = this.calculatePrice.bind(this)
    this.calculateOriginalPrice = this.calculateOriginalPrice.bind(this)
    this.switchToFreePackage = this.switchToFreePackage.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';

    this.props.fetchServices().then(response => {
      this.setState({loading: false})
    })

    fetchBundleGroups().then(bundle_groups => this.setState({ bundle_groups: bundle_groups, bundleLoading: false }))

    if (this.props.location.pathname.split("/")[1] === "referral"){
      applyCoupon(this.props.location.pathname.split("/")[2]).then(coupon => {
        if (coupon?.id) {
          this.setState({coupon: coupon})
        }
      })
    }

    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  componentDidUpdate(_, prevState) {
    if (prevState.county !== this.state.county) {
      if (this.state.county != "") {
        fetchLocationSubServices(this.state.county).then(location_pricing => {
          this.setState({ location_pricing: location_pricing })
        })
      } else {
        this.setState({ location_pricing: {} })
      }
    }

    if (prevState.contractorSearch !== this.state.contractorSearch) {
      this.debounceFetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch, Object.keys(this.state.package_attributes), Object.keys(this.state.appointments), this.state.detected_timezone)
    }

    if (((prevState.contractorDateSearch !== this.state.contractorDateSearch || prevState.contractorTimeSearch !== this.state.contractorTimeSearch) && !this.state.waiting)) {
      this.setState({ waiting: true })
      this.props.fetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch, Object.keys(this.state.package_attributes), Object.keys(this.state.appointments), this.state.detected_timezone).then(() => { this.setState({ waiting: false }) })
    }
  }

  switchToFreePackage(){
    let newState = Object.assign({}, this.state.package_attributes);
    let newStateSubservices = Object.assign({}, this.state.selectedSubservices);

    let old_sub_service = "";
    let new_sub_service = null;

    outerLoop:
    for (const service of Object.values(this.props.services)) {
      for (const sub_service of service.subServices) {
        if (sub_service.promo) {
          new_sub_service = sub_service;
          break outerLoop;
        }
      }
    }

    if (new_sub_service && newState[new_sub_service.service_id] && newState[new_sub_service.service_id]["package_sub_services_attributes"] && Object.values(newState[new_sub_service.service_id]["package_sub_services_attributes"]).length > 0){

      //assumes only one sub-service attached to the main service
      const old_sub_service_id = Object.values(newState[new_sub_service.service_id]["package_sub_services_attributes"])[0].sub_service_id

      delete newState[new_sub_service.service_id]["package_sub_services_attributes"][old_sub_service_id]
      delete newStateSubservices[old_sub_service_id]

      newState[new_sub_service.service_id]["package_sub_services_attributes"][new_sub_service.id] = { sub_service_id: new_sub_service.id }
      newStateSubservices[new_sub_service.id] = new_sub_service

      this.setState({ package_attributes: newState, selectedSubservices: newStateSubservices})
    }
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  anyServiceSelected() {
    return Object.keys(this.state.selectedSubservices).length > 0
  }

  handleNext() {
    this.setState({ errors: [] })

    let errors = []

    if (this.state.page === 0){
      if (!this.state.alacartSelected && !this.state.bundleSelected){
        errors.push("Please select an option before continuing")
      }
    }
    if (this.state.page === 1) {
      if (this.state.street === "") {
        errors.push([1, "Address must be selected from dropdown"])
      }

      if (this.state.city === "") {
        errors.push([2, "City name can't be blank"])
      }

      if (this.state.state === "") {
        errors.push([3, "State can't be blank"])
      }

      if (this.state.zipcode === "") {
        errors.push([4, "Zipcode can't be blank"])
      }
    }

   if (this.state.page === 2) {
      if ((!this.anyServiceSelected() && this.state.alacartSelected) || Object.values(this.state.package_attributes).length === 0) {
        if (!this.anyServiceSelected() && this.state.alacartSelected){
          errors.push("Please select a service before continuing")
        } else {
          errors.push("Please select at least one package for your service before continuing")
        }
      }
    }

    if (this.state.page === 4) {
      if (!this.state.appointments[this.state.selected_service]) {
        errors.push("You must select an appointment time before continuing")
      }
    }

    if (errors.length > 0 && this.state.page === 1) {
      this.setState({ propertyErrors: errors })
    } else if (errors.length > 0) {
      this.setState({ summaryErrors: errors })
    } else {
      if (this.state.page === 1 && this.state.goingBackToAddress) {
        this.setState({ page: 6, errors: [], summaryErrors: [], propertyErrors: [] })
        this.debouceScrollTo('#scrollToHere')
      } else if(this.state.page === 1){
        detectTimezone(this.state.latitude, this.state.longitude).then(detected_timezone => { 
          fetchTimeSlots(true, detected_timezone).then(time_slots => {
            this.setState({ twilight_time: time_slots, detected_timezone: detected_timezone })
          })
        })
        this.setState({page: 2, errors: [], summaryErrors: [], propertyErrors: []})
      } else {
        if (this.state.page === 4 || this.state.page === 2) {
          if (Object.values(this.state.appointments).length === Object.values(this.state.package_attributes).length) {
            this.setState({ page: this.state.page === 2 ? this.state.page + 4 : this.state.page + 2, errors: [], summaryErrors: [], propertyErrors: [], currentlySelectedService: null, selected_service: -1 })
          }
          else {
            let selected_service = "";
            let appointment_time = "";
            if (Object.values(this.state.package_attributes).length - Object.values(this.state.appointments).length > 1) {
              selected_service = parseInt(Object.keys(this.state.package_attributes).find(n => !this.props.services[n].twilight && !Object.keys(this.state.appointments).includes(n)))
            } else {
              selected_service = parseInt(Object.keys(this.state.package_attributes).find(n => !Object.keys(this.state.appointments).includes(n)))
            }
            if (Object.values(this.state.appointments).length > 0) {
              appointment_time = Object.values(this.state.appointments).find(n => !this.props.services[n.service_id].twilight)?.appointment_time ? new Date(Object.values(this.state.appointments).find(n => !this.props.services[n.service_id].twilight)?.appointment_time) : ""
            }
            let contractorDateSearch = appointment_time || "";
            let contractorTimeSearch = appointment_time ? appointment_time.getHours() + (appointment_time.getMinutes() / 60) : ""
            if (this.props.services[selected_service].twilight && appointment_time) {
              const weekday = formatDatetime(appointment_time, 'dddd')
              contractorTimeSearch = Object.keys(this.state.twilight_time[weekday])[0]
            }
            this.setState({ selected_service: selected_service, waiting: true, errors: [], summaryErrors: [], propertyErrors: [], contractorDateSearch: contractorDateSearch, contractorTimeSearch: contractorTimeSearch })
            this.props.fetchContractors(selected_service, this.state.zipcode, this.state.contractorSearch, contractorDateSearch, contractorTimeSearch, Object.keys(this.state.package_attributes), Object.keys(this.state.appointments), this.state.detected_timezone).then((contractors) => {
              if (Object.values(contractors.contractors).length === 0) {
                this.setState({ contractorDateSearch: "", contractorTimeSearch: "", waiting: false })
              } else {
                this.setState({ waiting: false })
              }
            })

            if (this.state.page === 2) {
                this.setState({ page: 3 })
            }
          }
        } else {
          if (this.state.page < 6){
            this.setState({ page: this.state.page + 1, errors: [], summaryErrors: [], propertyErrors: [] })
          }
        }
        this.debouceScrollTo('#scrollToHere')
      }
    }
  }

  handlePrevious(e) {
    e.preventDefault()
    if (this.state.page === 6) {
      if (this.state.currentlySelectedService || this.state.bundleSelected){
        this.setState({ page: this.state.page - 2, errors: [], summaryErrors: [], propertyErrors: [] })
      } else {
        this.setState({ page: this.state.page - 4, errors: [], summaryErrors: [], propertyErrors: [] })
      }
    } else if (this.state.page === 0){
      this.props.openStartModal()
    } else if (this.state.page === 4 && !this.state.addonSubServices && this.state.bundleSelected) {
      this.setState({page: this.state.page - 2, errors: [], summaryErrors: [], propertyErrors: []})
    } else if (this.state.page === 4 && this.state.alacartSelected) {
      this.setState({ page: 2, errors: [], summaryErrors: [], propertyErrors: [], selected_service: -1, currentlySelectedService: null })
    } else if (this.state.page === 2 && this.state.currentlySelectedService) {
      this.setState({ errors: [], selected_service: -1, currentlySelectedService: null, summaryErrors: [], propertyErrors: [] })
    } else {
      this.setState({ page: this.state.page - 1, errors: [], summaryErrors: [], propertyErrors: [] })
    }
    this.debouceScrollTo('#scrollToHere')
  }

  deleteSubService(sub_service) {
    let newState = Object.assign({}, this.state.package_attributes);
    let newStateSubservices = Object.assign({}, this.state.selectedSubservices);
    let newStateAppointments = Object.assign({}, this.state.appointments);

    delete newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id]
    delete newStateSubservices[sub_service.id]

    if (Object.values(newState[sub_service.service_id]["package_sub_services_attributes"]).length === 0) {
      delete newStateAppointments[sub_service.service_id]
      delete newState[sub_service.service_id]
    }

    this.setState({ package_attributes: newState, selectedSubservices: newStateSubservices, appointments: newStateAppointments })
  }

  previewOrder(discount, promo_price, promo_name) {
    let errors = []

    if (this.state.street === "") {
      errors.push([1, "Street can't be blank."])
    }

    if (this.state.city === "") {
      errors.push([2, "City name can't be blank"])
    }

    if (this.state.state === "") {
      errors.push([3, "State can't be blank"])
    }

    if (this.state.zipcode === "") {
      errors.push([4, "Zipcode can't be blank"])
    } else if (this.state.zipcode.length != 5) {
      errors.push([4, "Zipcode must be 5 digits"])
    }

    if (errors.length === 0) {
      this.setState({ promo_price: promo_price, promo_name: promo_name, discount: discount, modalOpen: true })
    } else {
      this.setState({ propertyErrors: errors, summaryErrors: ["Please fill out required fields above."] })
    }
  }

  submitOrder() {
    fetchUnavailability(Object.values(this.state.appointments), this.state.detected_timezone, this.state.street).then((unavailable_appointments) => {
    if (unavailable_appointments.length > 0) {
      let newStateAppointments = this.state.appointments
      let newState = this.state.package_attributes
      unavailable_appointments.forEach((unavailable) => {
        delete newStateAppointments[unavailable]
        delete newState[unavailable]["appointment_time"]
        delete newState[unavailable]["contractor_id"]
      })
      this.props.removeCurrentUser()
      destroyUser(this.state.user.id, "Photographer is no longer available at this appointment time")
      this.props.showToast("Photographer is no longer available at this appointment time")
      this.setState({package_attributes: newState, appointments: newStateAppointments, page: 3, user: {}, modalOpen: false})
    } else {
      let data = {}
      let address_attributes = {}
      let invites = this.state.emails.toString()

 
    address_attributes = { formatted_address: this.state.formatted_address, county: this.state.county, apartment_number: this.state.apartment, city: this.state.city, region: this.state.state, street1: this.state.street, zip_code: this.state.zipcode, longitude: this.state.longitude, latitude: this.state.latitude }
    data = { client_id: this.state.user.id, address_attributes: address_attributes, packages_attributes: this.state.package_attributes, notes: this.state.note, invites: invites, broker_booked: false }

    if (this.state.selected_bundle?.id) {
      let bundle_price = 0
      let total_price = 0
      let bundleSubserviceIds = this.state.selected_bundle?.sub_services?.map(subservice => subservice.id) || [];

      Object.values(this.state.selectedSubservices).filter(sub_service => !bundleSubserviceIds.includes(sub_service.id)).forEach(subservice => {
        total_price += parseInt(this.state.location_pricing[subservice.id] ? this.state.location_pricing[subservice.id].price : subservice.price)
      })

      this.state.selected_bundle.sub_services.forEach(subservice => {
        bundle_price += parseFloat(this.state.location_pricing[subservice.id] ? this.state.location_pricing[subservice.id].price : subservice.price)
      })
      bundle_price = bundle_price * (1 - (this.state.selected_bundle.percent_discount / 100))

      total_price += Math.ceil(bundle_price)

      if (this.state.coupon?.id) {
        if (this.state.coupon.percentage) {
          total_price -= parseInt(total_price * this.state.coupon.amount / 100)
        } else {
          if (this.state.coupon.amount > price) {
            total_price = 0
          } else {
            total_price -= parseInt(this.state.coupon.amount)
          }
        }
      }

      data["discount_bundle_id"] = this.state.selected_bundle.id
      data["bundle_price"] = total_price
    }

    if (this.state.coupon?.id) {
      data["discount_coupon_code"] = this.state.coupon.code
    }
    
    this.setState({page: 9})
    this.props.submitOrder(data).then(({ project }) => {
      if (project.errors) {
        this.props.removeCurrentUser()
        destroyUser(this.state.user.id, project.errors)
        this.setState({backendErrors: project.errors})
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        subscribeToList(this.state.user?.email, "7070d09e-19b7-400b-8a58-5babcfdb2245")
        removeFromList(this.state.user?.email, "homepage_ad")
        // LinkedIn Lead Gen List
        removeFromList(this.state.user?.email, "11857f10-8231-42f8-858c-8259753c0c68")
        this.setState({ page: 7, project: Object.values(project)[0], modalOpen: false})
      }
    })
   }})
  }

  handler(newState) {
    return new Promise((resolve, reject) => {
    if (this.state.propertyErrors.length > 0) {
      let errors = []

      const currentState = { ...this.state, ...newState }

      if (currentState.street === "") {
        errors.push([1, "Street can't be blank."])
      }

      if (currentState.city === "") {
        errors.push([2, "City name can't be blank"])
      }

      if (currentState.state === "") {
        errors.push([3, "State can't be blank"])
      }

      if (currentState.zipcode === "") {
        errors.push([4, "Zipcode can't be blank"])
      }

      let combinedState = Object.assign(newState, { propertyErrors: errors, summaryErrors: errors.length > 0 ? ["Please fill out required fields above."] : [] })
      this.setState(combinedState, () => {
        resolve();
      })
    } else {
      const currentState = { ...this.state, ...newState }

      // if (currentState.zipcode.length === 5 && currentState.selected_service !== -1 && (newState.selected_service || newState.zipcode)) {
      //   this.setState({ waiting: true }, () => {
      //     resolve();
      //   })
      //   this.props.fetchContractors(currentState.selected_service, currentState.zipcode, currentState.contractorSearch, currentState.contractorDateSearch, currentState.contractorTimeSearch, Object.keys(currentState.package_attributes), Object.keys(currentState.appointments), this.state.detected_timezone).then(() => this.setState({ waiting: false }))
      // }

      this.setState(newState, () => {
        resolve(); 
      })
     }})
  }

  setAppointmentTime(date, contractor) {
    let newState = this.state.package_attributes
    let newAppointmentState = this.state.appointments
    let newMainService = this.state.main_service
    let main = true;
    

    let contractor_service_ids = {}

    contractor.services.forEach(service => {
      contractor_service_ids[service.id] = true
    })

    Object.values(this.state.appointments).forEach(appointment => {
      if (appointment.contractor.id === this.state.appointments[this.state.selected_service]?.contractor?.id && appointment.appointment_time === this.state.appointments[this.state.selected_service]?.appointment_time && appointment.service_id != this.state.selected_service) {
        newAppointmentState[appointment.service_id] = { service_id: appointment.service_id, contractor: contractor, appointment_time: date }
        newState[appointment.service_id]["appointment_time"] = date
        newState[appointment.service_id]["contractor_id"] = contractor.id
        newState[appointment.service_id]["service_id"] = appointment.service_id
      }
    })

    Object.keys(this.state.package_attributes).forEach(package_service_id => {
      if (contractor_service_ids[package_service_id] && !this.props.services[package_service_id].twilight && !this.props.services[this.state.selected_service].twilight) {
        if (!this.state.appointments[package_service_id]) {
          newAppointmentState[package_service_id] = { service_id: package_service_id, contractor: contractor, appointment_time: date }
          newState[package_service_id]["appointment_time"] = date
          newState[package_service_id]["contractor_id"] = contractor.id
          newState[package_service_id]["service_id"] = package_service_id
        }
      }
    })

    if (main === true && !this.props.services[this.state.selected_service].twilight) {
      newMainService[contractor.id] = this.state.selected_service
    }

    newAppointmentState[this.state.selected_service] = { service_id: this.state.selected_service, contractor: contractor, appointment_time: date }

    newState[this.state.selected_service]["appointment_time"] = date
    newState[this.state.selected_service]["contractor_id"] = contractor.id
    newState[this.state.selected_service]["service_id"] = this.state.selected_service

    this.setState({ package_attributes: newState, appointments: newAppointmentState, main_service: newMainService, summaryErrors: [] })
  }

  content() {
    const { page } = this.state

    if (page === 0){
      return <ServiceChoiceComponent handleNext={this.handleNext} changeParentState={this.handler} page={this.state.page} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} summaryErrors={this.state.summaryErrors} homepageModal={true}/>
    }

    if (page === 1) {
      return <>
        <LocationComponent homepageModal={true} changeParentState={this.handler} minimize={false} page={this.state.page} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
        </>
    }

    if (page === 2){
      return <ServicesSelectionComponent changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} location_pricing={this.state.location_pricing} bundleLoading={this.state.bundleLoading} loading={this.state.loading} bundle_groups={this.state.bundle_groups} selected_bundle={this.state.selected_bundle} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} currentlySelectedService={this.state.currentlySelectedService} homepage={true}/>
    }

    if (page === 3){
      if (this.state.alacartSelected){
        return <ServicesSelectionComponent changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} location_pricing={this.state.location_pricing} currentlySelectedService={this.state.currentlySelectedService} loading={this.state.loading} bundleLoading={this.state.bundleLoading} bundle_groups={this.state.bundle_groups} selected_bundle={this.state.selected_bundle} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} homepage={true}/>
      } else {
        return <AddonSubServicesSelectionComponent location_pricing={this.state.location_pricing} addonSubservices={this.state.addonSubServices} addonServices={true} changeParentState={this.handler} bundleLoading={this.state.bundleLoading} loading={this.state.loading} bundle_groups={this.state.bundle_groups} selected_bundle={this.state.selected_bundle} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} />
      }
    }

    if (page === 4) {
      return <PhotographerSelectionComponent handleNext={this.handleNext} detected_timezone={this.state.detected_timezone} contractorTimeSearch={this.state.contractorTimeSearch} createProjectHomepage={true} createProjectVersion2={true} contractorDateSearch={this.state.contractorDateSearch} changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} page={this.state.page} waiting={this.state.waiting} summaryErrors={this.state.summaryErrors} included_services={this.props.services} main_service={this.state.main_service} zipcode={this.state.zipcode} street={this.state.street} services={this.state.package_attributes} setAppointmentTime={this.setAppointmentTime} selectedService={this.state.selected_service} contractors={this.state.waiting ? {} : this.props.contractors} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} onContractorSelect={this.onContractorSelect} />
    }

    if (page === 5) {
      return ""
    }

    if (page === 6) {
      return <OrderSummaryComponent homepage={true} changeParentState={this.handler} location_pricing={this.state.location_pricing} user_emails={this.state.user_emails} coupon={this.state.coupon} modalCheckout={true} checkout={true} emails={this.state.emails} note={this.state.note} page={this.state.page} services={this.props.services} errors={this.state.summaryErrors} promos={this.state.promos} package_attributes={this.state.package_attributes} deleteSubService={this.deleteSubService} account_credit={this.state.account_credit} subservices={this.state.selectedSubservices} appointments={this.state.appointments} previewOrder={this.previewOrder} currentUser={this.props.currentUser} formatted_address={this.state.formatted_address} street={this.state.street} city={this.state.city} state={this.state.state} apartment={this.state.apartment} zipcode={this.state.zipcode} selected_bundle={this.state.selected_bundle} detected_timezone={this.state.detected_timezone} />
    }

    if (page === 7) {
      return ""
    }

    if (page === 10) {
      return ""
    }
  }

  progress() {
    const { page } = this.state
    const step = page

    return [1, 2, 3, 4].map(i => {
      return <div className={`modalFooterProgressItem ${step < 3 && step === i ? 'completed' : ''} ${step >= 3 && step < 6 && i === step - 1 ? 'completed' : ''} ${step === 6 && i === 4 ? 'completed' : ''}`} key={`step-${i}`}>
        {i}
      </div>
    })
  }

  onClose() {
    this.props.onClose()
  }

  onContractorSelect(contractorId, photographer) {
    if (contractorId) {
      const subModal = <ContractorCalendar photographer={photographer}
        setAppointmentTime={this.setAppointmentTime}
        appointments={this.state.appointments}
        street={this.state.street}
        selectedService={this.state.selected_service}
        changeSelected={this.onContractorSelect}
        handleNext={this.handleNext}
        isLargeScreen={this.props.isLargeScreen}
        homepageModal={true} 
        detected_timezone={this.state.detected_timezone} />
      this.setState({ subModal })
    } else {
      this.setState({ subModal: null })
    }
  }

  calculatePrice(){
    let price = 0

    let bundleSubserviceIds = this.state.selected_bundle?.sub_services?.map(subservice => subservice.id) || [];

    Object.values(this.state.selectedSubservices).filter(sub_service => !bundleSubserviceIds.includes(sub_service.id)).forEach(subservice => {
      price += parseInt(this.state.location_pricing[subservice.id] ? this.state.location_pricing[subservice.id].price : subservice.price)
    })

    if (this.state.selected_bundle?.id) {
      let bundle = this.state.selected_bundle
      let bundle_price = 0
      bundle.sub_services.forEach(sub_service => {
        bundle_price += parseFloat(this.state.location_pricing[sub_service.id] ? this.state.location_pricing[sub_service.id].price : sub_service.price)
      })

      bundle_price = bundle_price * (1 - (bundle.percent_discount / 100))

      price += Math.ceil(bundle_price)
    }

    // if (Object.values(this.state.selectedSubservices).length > 1){
    //   if (Object.values(this.state.selectedSubservices).length == 2){
    //     price -= parseInt(price * 5 / 100)
    //   } else if (Object.values(this.state.selectedSubservices).length == 3){
    //     price -= parseInt(price * 10 / 100)
    //   } else {
    //     price -= parseInt(price * 15 / 100)
    //   }
    // }

    if (this.state.coupon?.id) {
      if (this.state.coupon.percentage) {
        price -= parseInt(price * this.state.coupon.amount / 100)
      } else {
        price -= this.state.coupon.amount
      }
    }

    price -= this.state.discount

    return price
  }

  calculateOriginalPrice() {
    let price = 0

    let bundleSubserviceIds = this.state.selected_bundle?.sub_services?.map(subservice => subservice.id) || [];

    Object.values(this.state.selectedSubservices).filter(sub_service => !bundleSubserviceIds.includes(sub_service.id)).forEach(subservice => {
      price += parseInt(this.state.location_pricing[subservice.id] ? this.state.location_pricing[subservice.id].price : subservice.price)
    })

    if (this.state.selected_bundle?.id) {
      let bundle = this.state.selected_bundle
      let bundle_price = 0
      bundle.sub_services.forEach(sub_service => {
        bundle_price += parseFloat(this.state.location_pricing[sub_service.id] ? this.state.location_pricing[sub_service.id].price : sub_service.price)
      })

      bundle_price = bundle_price * (1 - (bundle.percent_discount / 100))

      price += Math.ceil(bundle_price)
    }

      if (this.state.coupon?.id) {
        if (this.state.coupon.percentage) {
          price -= parseInt(price * this.state.coupon.amount / 100)
        } else {
          price -= this.state.coupon.amount
        }
      }

    price -= this.state.discount

    return price
  }

  render() {

    let icon = ""

    document.body.style.overflow = 'hidden';

    // let icon = <div key="2" onClick={() => this.props.history.goBack()}><i className="fas fa-arrow-left mainIcon createProjectIcon"></i></div>


    let modal = ""
    let popoutModal = ""

    // if (this.state.modalOpen && this.props.currentUser) {
    //   modal = <CreateProjectModal changeParentState={this.handler} promo_price={this.state.promo_price} firm={this.state.firm} promo_name={this.state.promo_name} sub_broker_client={this.state.sub_broker_client} client_role={this.state.client_role} street={this.state.street} city={this.state.city} state={this.state.state} formatted_address={this.state.formatted_address} apartment={this.state.apartment} zipcode={this.state.zipcode} discount={this.state.discount} account_credit={this.state.account_credit} subservices={this.state.selectedSubservices} isLargeScreen={this.props.isLargeScreen} appointments={this.state.appointments} submitOrder={this.submitOrder} />
    // } else if

    if (this.state.modalOpen) {
      let price = this.calculatePrice()
      modal = <SignupAndPaymentModalContainer changeParentState={this.handler} location_pricing={this.state.location_pricing} backendErrors={this.state.backendErrors} detected_timezone={this.state.detected_timezone} bundle={this.state.selected_bundle} switchToFreePackage={this.switchToFreePackage} submitOrder={this.submitOrder} price={price}/>
    } else if (this.state.contractorModalOpen) {
      modal = <ContractorCalendar photographer={this.state.photographer}
        setAppointmentTime={this.setAppointmentTime}
        appointments={this.state.appointments}
        selectedService={this.state.selected_service}
        street={this.state.street}
        detected_timezone={this.state.detected_timezone}
        changeSelected={() => this.setState({ contractorModalOpen: false })}
        isLargeScreen={this.props.isLargeScreen}
        homepageModal={true} />
    } else if (this.state.chooseTimeModalOpen) {
      modal = <ChooseTimeModal changeParentState={this.handler} homepageModal={true} detected_timezone={this.state.detected_timezone} selectedService={this.state.selected_service} appointments={this.state.appointments} package_attributes={this.state.package_attributes} time_slots={this.props.services[this.state.selected_service]?.twilight ? this.state.twilight_time : this.state.time_slots} contractorDateSearch={this.state.contractorDateSearch} contractorTimeSearch={this.state.contractorTimeSearch} />
    } else if (this.state.page === 7){
      modal = <ProjectCreatedModal project={this.state.project} changeParentState={this.handler} detected_timezone={this.state.detected_timezone} />
    } else if (this.state.subModal){
      modal = this.state.subModal
    } else if (this.state.page === 10) {
      modal = <div className="modal-background">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading"> Checkout</div>
            </div>
            <div className="orderPreviewModalCategory">
              <div className="settingsComponentHeading">Submitting Order...</div>
              <div className="createProjectPaymentModal">
                <div className="loaderDiv">
                  {/* <Loader
                      type="Oval"
                      color="#4E57AA"
                      height={100}
                      width={100}
                      timeout={30000} //3 secs
                    /> */}
                  <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } else if (this.state.page === 3 && this.state.alacartSelected){
      popoutModal = <AddMoreServicesHomepageModal handleNext={this.handleNext} services={Object.values(this.props.services)} orderedPackages={this.state.package_attributes} changeParentState={this.handler}/>
    }

    if (modal) {
      return modal
    }

    let buttons = "";
    let button2 = "";

    if (this.state.page === 0){
      // buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
      //   <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
      //   {/* <div className="signupFormNext" onClick={this.handleNext}>Next Step</div> */}
      // </div>
      buttons = ""
    }

    if (this.state.page === 1) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        {/* <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div> */}
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>

      if (this.state.goingBackToAddress) {
        buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
          <div className="signupFormNext orderSummaryButton" onClick={this.handleNext}>Order Summary</div>
        </div>
      }
    } else if (this.state.page === 2) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        {Object.keys(this.state.package_attributes).length > 0 && Object.keys(this.state.package_attributes).length - Object.keys(this.state.appointments).length === 0 && !this.state.currentlySelectedService ? <div className="signupFormPrevious orderSummaryButton" onClick={() => this.setState({ page: 6, summaryErrors: [] })}>Order Summary</div> : <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>}
        <div className="signupFormNext" id="bundleScroll" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 3) {
  
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } 
      else if(this.state.page === 4) {

      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } 
    else if (this.state.page === 6) {
      buttons = <div className="signupFormButtons createProjectHomePageSignupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext checkoutModalButton" onClick={() => this.previewOrder(0, 0, "")}>Checkout</div>
      </div>
    }

    let price = this.calculateOriginalPrice()
    let new_price = this.calculatePrice()

    return (
      <div className="modal-background">
        <div className={`orderpreviewModal addServiceModal`} id="creatProjectHomepageModal">
          <div className="orderPreviewModalComponent">
            <div className="addServiceModalContent">
              {this.state.page != 2 && this.state.page != 3 && <div onClick={() => this.onClose()} className="modalCloseButton modalCloseButtonHomepage modalCloseButtonCreateProject">
                <CloseIcon />
              </div>}
              {popoutModal}
              <div>
                <div id="scrollToHere"></div>
              </div>
              {this.content()}
            </div>

            {this.state.page !== 7 && <div className="modalFooter modalFooterHomepageCreateProject">
              {(this.state.page === 2 || this.state.page === 3 || this.state.page === 4) && <div className="homepageModalTotalMobile">Total: {price != new_price ? <div className="d-flex"><div className="originalPriceOrderSummary newPriceMargin">${price.toFixed(0)}</div> <div className="newPriceMargin">${new_price.toFixed(0)}</div></div> : <div className= "newPriceMargin"> ${price}</div>}</div>}
              <div className="modalFooterProgress modalFooterProgressCreateProject">
                {this.progress()}
              </div>
              {(this.state.page === 2 || this.state.page === 3 || this.state.page === 4) && <div className="homepageModalTotal">Total: {price != new_price ? <div className="d-flex"><div className="originalPriceOrderSummary newPriceMargin">${price.toFixed(0)}</div> <div className="newPriceMargin">${new_price.toFixed(0)}</div></div> : <div className="newPriceMargin"> ${price}</div>}</div>}
              {buttons}
            </div>}
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(CreateProjectHomePageModal);