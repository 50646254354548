import {
  CLOSE_PROJECT_MODAL,
  CLOSE_VS_MODAL,
  OPEN_START_MODAL,
  OPEN_PROJECT_MODAL,
  OPEN_VS_MODAL,
  CLOSE_START_MODAL
} from "../actions/landing_page_actions";

export default function landingPageReducer(state = {}, action) {
  switch (action.type) {
    case OPEN_START_MODAL:
      return {...state, modalOpen: true};
    case CLOSE_START_MODAL:
      return {...state, modalOpen: false}
    case OPEN_PROJECT_MODAL:
      return {...state, projectModalOpen: true};
    case CLOSE_PROJECT_MODAL:
      return {...state, projectModalOpen: false};
    case OPEN_VS_MODAL:
      return {...state, VSModalOpen: true};
    case CLOSE_VS_MODAL:
      return {...state, VSModalOpen: false};
    default:
      return state;
  }
}