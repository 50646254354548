import React from "react";
import { Route, Link, withRouter } from "react-router-dom";

class ChangeServiceOrderSummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }
  
  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    let price = 0;
    let discount = 0;
    let button = <button className="projectEditButton projectEditButtonPrimary" onClick={() => this.props.previewOrder(discount, promoPrice, promoName)}>Submit Changes</button>
    let account_credit_component = "";
    let account_credit = this.props.account_credit;

    let promoPrice = null
    let promoName = ""
    let previous_services = {}
    let package_attributes = Object.values(this.props.package_attributes)

    this.props.previous_services.forEach(service => previous_services[service.id] = true)

    if (this.props.promos.length > 0) {
      this.props.promos.forEach(promo => {
        if (this.props.package_attributes[promo.service.id]) {
          if (promo.services.length > 0) {
            promo.services.forEach(service => {
              if (this.props.package_attributes[service.id] && package_attributes.length === 2) {
                promoPrice = parseInt(promo.price)
                promoName = promo.name
              }
            })
          } else {
            if (package_attributes.length === 1) {
              promoPrice = parseInt(promo.price)
              promoName = promo.name
            }
          }
        }
      })
    }


    let subServices = Object.values(this.props.subservices).map(subservice => {

      price += parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price)

      return (<div className="orderSummaryLi">
        <div className="bold">{this.props.services[subservice.service_id]?.title} - {subservice.title}</div>
        <div style={{ display: "flex" }}>
          <div className="orderSummarySubservicePrice">${parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price).toFixed(2)}</div>
          {this.props.checkout ? <div className="itemIcon3" onClick={() => this.props.changeParentState({ page: 2 })}><i className="fas fa-edit settingsServiceIcon m-2"></i></div> : ""}
          <div className="itemIcon" onClick={() => this.props.deleteSubService(subservice)}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
        </div>
      </div>)
    })

    price -= this.props.previous_total


    if (promoPrice) {
      let promo_discount = price - (promoPrice - this.props.previous_total)
      subServices.push(<div className="orderSummaryLi">
        <div>{promoName} Promo Applied: </div>
        <div className="orderSummarySubservicePrice promoApplied">{promo_discount > 0 ? "-" : ""}${Math.abs(promo_discount).toFixed(2)}</div>
      </div>)

      price = promoPrice - this.props.previous_total
    }

    if (this.props.account_credit && price > 0) {
      if (this.props.account_credit >= price) {
        account_credit = this.props.account_credit - price
        price = 0
        discount = this.props.account_credit - account_credit
      } else {
        price = price - this.props.account_credit
        discount = this.props.account_credit
        account_credit = 0
      }
    }

    Object.values(this.props.previous_sub_services).forEach(sub_service => {
      subServices.push(<div className="orderSummaryLi">
        <div className="bold">{this.props.services[sub_service.service_id]?.title} - {sub_service.title} {"(removed)"}</div>
        <div style={{ display: "flex" }}>
          <div className="orderSummarySubservicePrice promoApplied">-${parseFloat(this.props.location_pricing[sub_service] ? this.props.location_pricing[sub_service].price : sub_service.price).toFixed(2)}</div>
        </div>
      </div>)
    })

    if (discount > 0) {
      subServices.push(<div className="orderSummaryLi">
        <div>Account credit applied: </div>
        <div className="orderSummarySubservicePrice promoApplied">-${discount.toFixed(2)}</div>
      </div>)
    }

    if (account_credit > 0) {
      account_credit_component = <div className="">
        <div className="totalOrderSummaryDiv">Account Credit:</div>
        <div className="totalOrderSummaryPrice">$ {account_credit.toFixed(2)}</div>
      </div>
    }

    let errors = []

    this.props.errors?.forEach((error) => {
      errors.push(<li>{error}</li>)
    })

    let checkout = ""

    if (this.props.checkout) {
      checkout = "checkoutStyle"
    } else {
      assign_to_sub_broker = ""
    }

    return (
      <div id={checkout} className="orderSummaryComponent">
        <div className="settingsComponentHeader">
          <div className="settingsComponentHeading">Check Out</div>
          <div className="settingsComponentDescription">
            Below is a list of your added services
          </div>
        </div>
        <div className="subServicesList">
          {subServices}
        </div>
        <div className="orderSummaryPriceDiv">
          <div>Change in cost: </div>
          <div>${price.toFixed(2)}</div>
        </div>
        {/* {account_credit_component} */}
        {/* {assign_to_sub_broker} */}
        <ul className="backendErrorUl">{errors}</ul>
      </div>
    )
  }
}

export default withRouter(ChangeServiceOrderSummaryComponent);