import React from "react";
import { withRouter } from "react-router-dom";
import {deleteProject, updateProject} from "../../util/project_api_util"
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";

class DeleteProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      loading: false,
      confirmModal: false,
      refund: false,
    }

    this.cancelProject = this.cancelProject.bind(this)
    this.confirmChange = this.confirmChange.bind(this)
  }

  confirmChange(refund) {
    if (refund) {
      this.setState({ confirmModal: true, refund: true })
    } else {
      this.setState({ confirmModal: true, refund: false })
    }
  }

  cancelProject(e, refund){
    // e.preventDefault()
    // const {project, onCancelled, onClose} = this.props

    // this.setState({loading: true})

    // deleteProject(project.id, refund).then(() => {
    //   onClose()
    //   onCancelled()
    // }).catch(() => {
    //   this.setState({loading: false})
    // })

    const { project, onCancelled, onClose } = this.props

    if (Object.values(project.virtual_staging_purchases).length > 0) {
      this.props.showToast("Project with virtual staging can't be cancelled")
      onClose()
      return
    }

    const packages_attributes = Object.values(project.packages).filter(pack => pack.package_sub_services.length > 0).map(pack => {
      return {
        ...pack,
        _destroy: true,
        package_sub_services_attributes: pack.package_sub_services.map(pss => {
          return { id: pss.id, _destroy: true }
        })
      }
    })

    const data = {
      refund: refund,
      packages_attributes
    }

    this.setState({ errors: [], loading: true })

    updateProject(data, this.props.project.id, true).then(() => {
      this.props.fetchCurrentUser(this.props.currentUser.id)
      onClose()
      onCancelled()
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  render() {
    const {onClose, project} = this.props

    let price = project.promo_price ? project.promo_price : Object.values(project.packages).filter(p => p.appointment_time && new Date(p.appointment_time) > Date.now()).flatMap(p => {
      return p.sub_services
    }).reduce((res, s) => res + parseFloat(project.invoice_sub_services[s.id].price), 0)

    let invoice_amount = 0;
    let invoice_paid = 0;
    let invoice_account_credit = 0;

    Object.values(project.invoices).forEach(invoice => {
      if (invoice.invoiceable_type === "Project"){
        invoice_amount += parseInt(invoice.total);
        invoice_paid += parseInt(invoice.paid)
        invoice_account_credit += parseInt(invoice.account_credit)
      }
    })

    let modal = ""

    if (this.state.confirmModal) {
      modal = <div className="modal-background sub-modal-background" onClick={() => this.setState({ confirmModal: false })}>
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="contractorModalHeading">
              <div className="confirmationModalDescription">Are you sure you want to make this change?</div>
            </div>
            <div className="confirmationTimeSlotsButtons">
              <div onClick={() => this.setState({ confirmModal: false })} className="signupFormPrevious">Cancel</div>
              <div onClick={(e) => this.cancelProject(e, this.state.refund)} className="signupFormNext" id={this.state.selectedTime === "" ? 'disabled' : ''}>Apply</div>
            </div>
          </div>
        </div>
      </div>
    }

    if (this.state.loading){
      modal = <div className="modal-background sub-modal-background" onClick={() => this.setState({ confirmModal: false })}>
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="loaderDiv">
              <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
            </div>
          </div>
        </div>
      </div>
    }

    if ((invoice_paid === 0 && invoice_account_credit === 0) || Object.values(project.packages).length === 0){
      return <div className="modal-background">
        {modal}
        <div className="orderpreviewModal refundModal">
          <div className="orderPreviewModalComponent">
            <div>
              <button className="projectEditButton projectEditButtonLight modalCloseButton" onClick={onClose}>
                Close
              </button>
              <div className="refundModalTitle">Refund Details</div>
              <div className="refundModalText">
                We're sorry to hear about your cancellation. You can click 'Change' above the map to reschedule the appointment instead.  Feel free to reach out to <a href="mailto:info@modernangles.com">info@modernangles.com</a> if you have any questions.
              </div>
              <div className="refundModalBox">
                <button className="projectEditButton projectEditButtonPrimary mb-3 ms-0 w-100"
                  onClick={e => this.confirmChange(false)} disabled={this.state.loading}>
                  Cancel Photoshoot
                </button>
                <button className="projectEditButton projectEditButtonWhite w-100" onClick={onClose}>
                  I no longer wish to cancel this project
                </button>
              </div>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    } else {
      return <div className="modal-background">
        {modal}
        <div className="orderpreviewModal refundModal">
          <div className="orderPreviewModalComponent">
            <div>
              <button className="projectEditButton projectEditButtonLight modalCloseButton" onClick={onClose}>
                Close
              </button>

              <div className="refundModalTitle">Refund Details</div>

              <div className="refundModalText">
                We're sorry to hear about your cancellation. You can click 'Change' above the map to reschedule the appointment instead.  Feel free to reach out to <a href="mailto:info@modernangles.com">info@modernangles.com</a> if you have any questions.
                <br/>
                Based on our refund policy we can offer you two options.
              </div>

              <div className="refundModalSubTitle">
                Direct Refund
              </div>

              <div className="refundModalText">
                If you would like a direct refund there will be a 5% fee based on processing costs.
              </div>

              <div className="refundModalSubTitle">Account Credit</div>

              <div className="refundModalText">
                Account credit can be chosen instead which will be automatically applied to a future purchase. With
                account credit there is no fees so you will receive the full refund amount in credit.
              </div>

              <div className="refundModalBox">
                <div className="refundModalSubTitle mb-3">Choose the option which works best for you:</div>
                <button className="projectEditButton projectEditButtonPrimary mb-3 ms-0 w-100"
                        onClick={e => this.confirmChange(false)} disabled={this.state.loading}>
                  Account Credit: ${(price * 1).toFixed(2)}
                </button>
                <button className="projectEditButton projectEditButtonPrimary mb-3 w-100"
                        onClick={e => this.confirmChange(true)} disabled={this.state.loading}>
                  Project Refund: ${invoice_amount >= price * .95 ? (price * 0.95).toFixed(2) : `${(invoice_amount * .95).toFixed(2)} + $${(price - invoice_amount).toFixed(2)} account credit`}
                </button>
                <button className="projectEditButton projectEditButtonWhite w-100" onClick={onClose}>
                  I no longer wish to cancel this project
                </button>
              </div>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
   }
 }
}

export default withRouter(DeleteProjectModal);