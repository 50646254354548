import React from "react"
import NavbarContainer from "./navbar/navbar_container"
import PageHeader from "./page_header"
import {withRouter} from "react-router-dom"
import {fetchUserProjects} from "../util/project_api_util"
import {fullAddress} from "../util/project_utils"
import {sendContactsMessage} from "../util/contacts_api_utils"
import FAQContainer from './pages/faq/FAQContainer';

class Contacts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      is_urgent: false,
      is_bug: false,
      project_id: '',
      projects: [],
      sent: false,
      loading: false
    }
    this.onInputChange = this.onInputChange.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onProjectSelect = this.onProjectSelect.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    fetchUserProjects(this.props.currentUser.id).then(projects => {
      this.setState({projects})
    })
  }

  onInputChange(field) {
    return (e) => {
      e.preventDefault()
      this.setState({[field]: e.target.value})
    }
  }

  onCheckboxChange(field) {
    return (e) => {
      this.setState({[field]: e.target.checked})
    }
  }

  onProjectSelect(e) {
    e.preventDefault()
    this.setState({project_id: e.target.value})
  }

  onSubmit(e) {
    e.preventDefault()
    const {projects, loading, sent, ...payload} = this.state
    this.setState({sent: false, loading: true})
    sendContactsMessage(payload).then(() => {
      this.setState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
        is_urgent: false,
        is_bug: false,
        project_id: '',
        sent: true,
        loading: false
      })
    })
  }

  render() {
    return <div className="dashboardFullPageDiv">
      <NavbarContainer/>
      <div className="dashboardMainDiv">
        <PageHeader title="Get In Touch With Us"
                    subtitle="At Modern Angles, we enjoy turning strangers into loyal customers. Contact us to schedule one of our talented photographers or just to get some more info. We look forward to hearing from you."/>
        <div className="sectionCard">
          <div className="sectionCardHeader">
            <h5 className="contactsCardTitle">
              <span>Reach out, we will reply!</span>
              <div>
                <div className="email">
                  <i className="fas fa-envelope"/>
                  <a href="mailto:info@modernangles.com">info@modernangles.com</a>
                </div>
                <div className="phone">
                  <i className="fas fa-phone" />
                  <a href="tel:8555027772">(855) 502-7772</a>
                </div>
              </div>
            </h5>
          </div>

          <form onSubmit={this.onSubmit}>
            <div className="sectionCardBody floatingLabelsForm">
              {this.state.sent && <div className="alert alert-success mb-4">Your message has been sent</div>}

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="inputGroup">
                    <label className="required">First name</label>
                    <input className="formInput" placeholder="Content" onChange={this.onInputChange('first_name')}
                           value={this.state.first_name} required/>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="inputGroup">
                    <label className="required">Last name</label>
                    <input className="formInput" placeholder="Content" onChange={this.onInputChange('last_name')}
                           value={this.state.last_name} required/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="inputGroup">
                    <label className="required">Email</label>
                    <input className="formInput" type="email" placeholder="Content"
                           onChange={this.onInputChange('email')}
                           value={this.state.email} required/>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="inputGroup">
                    <label>Phone</label>
                    <input className="formInput" placeholder="Content" onChange={this.onInputChange('phone')}
                           value={this.state.phone}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="inputGroup messageInput">
                    <label className="required">Your Message</label>
                    <textarea className="formInput" placeholder="Content" onChange={this.onInputChange('message')}
                              value={this.state.message} required/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <select className="formInput" onChange={this.onProjectSelect} value={this.state.project_id}>
                    <option value="">Select a project</option>
                    {this.state.projects.map(project => {
                      return <option key={project.id} value={project.id}>{fullAddress(project.address)}</option>
                    })}
                  </select>
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <div className="checkBox">
                    <input type="checkbox" onChange={this.onCheckboxChange('is_urgent')}
                           checked={this.state.is_urgent} id="isUrgent"/>
                    <label htmlFor="isUrgent">Is this topic urgent?</label>
                  </div>
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <div className="checkBox">
                    <input type="checkbox" onChange={this.onCheckboxChange('is_bug')}
                           checked={this.state.is_bug} id="isBug"/>
                    <label htmlFor="isBug">Is this a bug?</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="sectionCardFooter">
              <button className="contactsSubmitBtn" type="submit" disabled={this.state.loading}>
                Submit
              </button>
            </div>
          </form>
        </div>
        <FAQContainer loggedIn={true}/>
      </div>
    </div>
  }
}

export default withRouter(Contacts)