import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    document.body.classList.remove('hideAllOverFlow');
    document.getElementById('root').classList.remove('hideAllOverFlow');
  }, [pathname]);

  return null;
};


export default ScrollToTop;
