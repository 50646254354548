import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

import {TermsWrapper} from './TermsStyles';
import {TermsHeader, TermsSection} from '../../terms_page_components';
import {SectionWithContactUsLink} from '../../pageComponents';
import {fetchTermsOfService} from "../../../util/terms_of_service_util";

const TermsPage = () => {
  const [role, setRole] = useState("client");
  const [tos, setTos] = useState({})

  useEffect(() => {
    fetchTermsOfService(role).then(setTos)
  }, [role])

  return (
    <TermsWrapper>
      <TermsHeader date={tos.created_at} role={role} setRole={setRole}/>
      <TermsSection tos={tos}/>
      <SectionWithContactUsLink/>
    </TermsWrapper>
  );
};

export default withRouter(TermsPage);
