import React from "react";
import { Route, Link } from "react-router-dom";
import SettingsPromosItem from "./settings_promos_item";


export default class SettingsPromos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promos: []
    }

    this.updatePromo = this.updatePromo.bind(this)
    this.createPromo = this.createPromo.bind(this)
    this.deletePromo = this.deletePromo.bind(this)
  }

  componentDidMount() {
    this.props.fetchPromos().then(promos => this.setState({ promos: Object.values(promos) }))
    this.props.fetchServicesSettings()
  }

  updatePromo(promo) {
    this.props.openModal("updatePromo", { promo, onPromoUpdate: this.onPromoUpdate.bind(this) })
  }

  createPromo() {
    this.props.openModal("createPromo", { onPromoCreate: this.onPromoCreate.bind(this) })
  }

  onPromoUpdate(updatedPromo) {
    this.setState({ promos: this.state.promos.map(promo => promo.id === updatedPromo.id ? updatedPromo : promo) })
    this.props.showToast("Promo has been updated")
  }

  onPromoCreate(promo) {
    this.setState({ promos: [...this.state.promos, promo] })
    this.props.showToast("Promo has been created")
  }

  deletePromo(promo) {
    this.props.deletePromo(promo.id).then(() => {
      this.setState({ promos: this.state.promos?.filter(p => p.id !== promo.id) || [] })
      this.props.showToast("Promo has been removed")
    })
  }

  render() {
    let settingsPromosItems = this.state.promos.map((promo, i) => <SettingsPromosItem key={i} promo={promo} deletePromo={this.deletePromo} updatePromo={this.updatePromo} addInfo={this.props.addInfo} />)

    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Promotions</div>
          <div className="settingsPreferencesGroup1">
            <table className="w-100">
              <tbody>
                {settingsPromosItems}
              </tbody>
            </table>
          </div>
          <button onClick={this.createPromo} className="settingsButton preferencesButton">Create Promo</button>
        </div>
      </div>
    );
  }
}