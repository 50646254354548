import React from "react";
import { createBundle, fetchBundleGroups, updateBundle } from "../../../util/bundle_api_utils";
import { fetchServicesSettings, fetchSubServices } from "../../../util/service_api_util";
import buttonTrashIconSrc from '../../../../../assets/images/icons/fi_trash.svg'


export default class BundleFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.bundle?.id || "",
      title: props.bundle?.title || "",
      sub_title: props.bundle?.sub_title || "",
      percent_discount: props.bundle?.percent_discount || 0,
      services_description: props.bundle?.services_description || "",
      bundle_group_id: props.bundle?.bundle_group_id || "",
      sort_order: props.bundle?.sort_order || "",
      hidden: props.bundle?.hidden || false,
      extra_descriptions: props.bundle?.extra_descriptions || [],
      additional_descriptions: 0,
      loading: false,
      errors: [],
      bundle_groups: [],
      services: [],
      sub_services: props.bundle?.sub_services || [],
      all_sub_services: [],
      bundle_sub_services: props.bundle?.bundle_sub_services || [],
      new_sub_services: [],
      deleted_bundle_sub_services: [],
      new_sub_service: "",
      current_service: "",
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.deleteNewSubService = this.deleteNewSubService.bind(this)
    this.deleteBundleSubService = this.deleteBundleSubService.bind(this)
    this.addSubService = this.addSubService.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.addDescription = this.addDescription.bind(this)
    this.onExtraDescriptionChange = this.onExtraDescriptionChange.bind(this)
    this.removeDescription = this.removeDescription.bind(this)
  }

  componentDidMount(){
    fetchBundleGroups().then(bundle_groups => this.setState({bundle_groups: bundle_groups}))
    fetchServicesSettings().then(services => this.setState({services: services}))
    fetchSubServices().then(sub_services => this.setState({all_sub_services: sub_services}))
  }

  handleCheckbox(name) {
    this.setState({ [name]: !this.state[name] })
  }

  onExtraDescriptionChange(e, i) {
    let extra_descriptions = [...this.state.extra_descriptions]; // spread to create a new array
    extra_descriptions[i] = e.target.value;
    this.setState({ extra_descriptions });
  }

  addDescription = () => {
    this.setState(prevState => ({
      extra_descriptions: [...prevState.extra_descriptions, '']
    }));
  }

  removeDescription(index) {
    const extra_descriptions = [...this.state.extra_descriptions];
    extra_descriptions.splice(index, 1);
    this.setState({ extra_descriptions });
  }

  addSubService() {
    if (this.state.new_sub_service !== "") {
      let new_sub_services = this.state.new_sub_services;
      if (this.props.bundle?.id){
      new_sub_services.push({ discount_bundle_id: this.props.bundle?.id, sub_service_id: this.state.new_sub_service })
      } else {
        new_sub_services.push({sub_service_id: this.state.new_sub_service })
      }
      this.setState({ new_sub_services: new_sub_services, new_sub_service: "", current_service: "" })
    }
  }

  deleteNewSubService(index) {
    let new_sub_services = this.state.new_sub_services
    new_sub_services.splice(index, 1)
    this.setState({ new_sub_services: new_sub_services })
  }

  deleteBundleSubService(sub_service, index) {
    let deleted_bundle_sub_services = this.state.deleted_bundle_sub_services
    let sub_services = this.state.sub_services
    let bundle_sub_services = this.state.bundle_sub_services
    let bundle_sub_service = ""
    bundle_sub_services.forEach((new_bundle_sub_service) => {
      if (new_bundle_sub_service.sub_service_id === sub_service.id) {
        bundle_sub_service = new_bundle_sub_service
      }
    })

    deleted_bundle_sub_services.push({ _destroy: true, id: bundle_sub_service.id })

    sub_services.splice(index, 1)

    this.setState({ deleted_bundle_sub_services: deleted_bundle_sub_services, sub_services: sub_services, bundle_sub_services: bundle_sub_services })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSave() {
    const { title, sub_title, percent_discount, services_description, bundle_group_id, sort_order, hidden, extra_descriptions } = this.state

    let bundle_sub_services_attributes = this.state.deleted_bundle_sub_services.concat(this.state.new_sub_services)

    let data = { title: title, sub_title: sub_title, percent_discount: percent_discount, services_description: services_description, extra_descriptions: extra_descriptions.filter(description => description.trim() !== ""), bundle_group_id: bundle_group_id, bundle_sub_services_attributes: bundle_sub_services_attributes, sort_order: sort_order, hidden: hidden}
   
    const save = this.state.id ? updateBundle(this.state.id, data) : createBundle(data)

    this.setState({ loading: true })

    save.then(bundle => {
      this.props.closeModal()
      this.props.onSave(bundle)
      this.setState({ loading: false })
    }).catch(response => {
      this.setState({ loading: false })
      this.setState({ errors: response.responseJSON || [] })
    })
  }

  render() {

    let currentSubServices = {}
    let currentServices = {}
    let sub_services = {}

    this.state.sub_services.forEach(sub_service => sub_services[sub_service.id] = sub_service)

    let bundleSubServicesLi = this.state.bundle_sub_services.map((bundle_sub_service, i) => {
      currentSubServices[bundle_sub_service.sub_service_id] = true
      currentServices[sub_services[bundle_sub_service.sub_service_id].service_id] = true
      return <div className="contractorServiceItem" >
        <div className="itemTitle" key={i}>{sub_services[bundle_sub_service.sub_service_id].title}</div>
        <div className="itemIcon" onClick={() => { this.deleteBundleSubService(sub_services[bundle_sub_service.sub_service_id], i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
      </div>
    })

    this.state.new_sub_services.forEach((new_sub_service, i) => {
      currentSubServices[new_sub_service.sub_service_id] = true
      currentServices[this.state.all_sub_services[new_sub_service.sub_service_id].service_id] = true
      bundleSubServicesLi.push(<div className="contractorServiceItem">
        <div className="itemTitle" key={new_sub_service.sub_service_id}>{this.state.all_sub_services[new_sub_service.sub_service_id].title}</div>
        <div className="itemIcon" onClick={() => { this.deleteNewSubService(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
      </div>)
    })

    let serviceOptions = []
    let subServiceOptions = []

    serviceOptions = Object.values(this.state.services || []).filter((service) => !currentServices[service.id] && !service.hidden).map((service) => <option key={service.id} value={service.id}>{service.title}</option>)

    if (this.state.current_service != ""){
      subServiceOptions = Object.values(this.state.services[this.state.current_service].subServices || []).filter((sub_service) => !currentSubServices[sub_service.id]).map((sub_service) => <option key={sub_service.id} value={sub_service.id}>{sub_service.title}</option>)
    }

    return (
      <div className="addFormModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Bundle' : 'Create Bundle'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Title</label>
              <input className="formInput" onChange={this.onChange} name="title" value={this.state.title} />
            </div>
            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Sub-Title</label>
              <input className="formInput" onChange={this.onChange} name="sub_title" value={this.state.sub_title} />
            </div>
            <div className="settingsServicesInput form-group required">
              <label id="formLabel">Services Description</label>
              <input className="formInput" onChange={this.onChange} name="services_description" value={this.state.services_description} />
            </div>
            <div className="settingsServicesInput form-group required">
              <label id="formLabel">% discount</label>
              <input className="formInput" onChange={this.onChange} type="number" name="percent_discount" value={this.state.percent_discount} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Sort Order </label>
              <input className="formInput" onChange={this.onChange} type="number" name="sort_order" placeholder="Sort Order" value={this.state.sort_order} />
            </div>
            <div className="settingsServicesInput">
              {this.state.extra_descriptions.length > 0 && <label id="formLabel"> Extra Descriptions </label>}
              {this.state.extra_descriptions.map((description, i) =>
                <div className="d-flex" style={{"marginBottom": "10px"}} key={i}>
                  <input
                    className="formInput"
                    onChange={(e) => this.onExtraDescriptionChange(e, i)}
                    type="text"
                    name={`extra_description_${i}`}
                    placeholder="Description"
                    value={description}
                  />
                  <div className="removeDescriptionDiv" onClick={() => this.removeDescription(i)}><img src={buttonTrashIconSrc} className="" style={{ "height": "20px" }} /></div>
                </div>
              )}
              <button className="settingsButton companyButton" id="newDescriptionButton" onClick={this.addDescription}>New Description</button>
            </div>
            <div className="settingsServicesInput form-group form-group-special">
              <label><input type="checkbox" className="formCheckBox" checked={this.state.hidden} onChange={() => this.handleCheckbox("hidden")} name="hidden" /> <span className="rememberMe">Hidden?</span></label>
            </div>
          </div>
          <div className="settingsCompanyInput roleInput">
            <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Bundle Group</label>
            <select className="formInput" onChange={this.onChange} name="bundle_group_id" value={this.state.bundle_group_id} >
              <option value="">Choose Bundle Group</option>
              {this.state.bundle_groups.map(bundle_group => <option value={bundle_group.id}>{bundle_group.name}</option>)}
            </select>
          </div>
          <div className="contractorServiceList">
            <div>Bundle Sub Services</div>
            {bundleSubServicesLi}
            <div className="settingsCompanyInput roleInput">
              <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}> New Sub Service</label>
              <div className="updateUserDropdownAndButton">
                <select className="formInput newServiceDropdown" onChange={this.onChange} name="current_service" value={this.state.current_service} >
                  <option value="" disabled={true}>Service</option>
                  {serviceOptions}
                </select>
              </div>
              {this.state.current_service && <div className="updateUserDropdownAndButton updateBundleSubServiceDropdown">
                <select className="formInput newServiceDropdown" onChange={this.onChange} name="new_sub_service" value={this.state.new_sub_service} >
                  <option value="" disabled={true}>Sub Service</option>
                  {subServiceOptions}
                </select>
                <button onClick={this.addSubService} className="addContractorServiceButton">Add Sub Service</button>
              </div>
             }
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">
            Save
          </button>
        </div>
      </div>
    );
  }
}