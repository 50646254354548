import React from "react";
import { Route, Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../../assets/images/icons/search.svg";
import { ReactComponent as CheckMark } from "../../../../assets/images/icons/blue-checkmark-icon.svg";
import questionIcon from "../../../../assets/images/icons/MA-Question-Icon";


export default class ProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    this.onClickZoom = this.onClickZoom.bind(this)
  }

  onClickZoom = (e) => {
    e.stopPropagation()
      this.props.changeParentState({ galleryModalOpen: true, currentPhoto: this.props.index })
  }

  render() {
    let product = this.props.product;
    let imageId = ""
    let textId= ""
    let id = ""
    let icon = <div className="zoomIcon" onClick={this.onClickZoom}><SearchIcon /></div>
    let virtualClassName = "virtual-staging-modal-category-container virtual-staging-modal-product-container"


    if (this.props.selected) {
      id = "virtualStagingCategorySelected"
      imageId = "categoryItemImageSelected"
      textId = "categoryItemTextSelected"
      icon = <div className="checkIcon"><CheckMark /></div>
    }

    if (this.props.homepage){
      virtualClassName = "virtual-staging-modal-category-container virtual-staging-modal-product-container virtual-staging-modal-category-container-homepage"
    }

    return (
      <div onClick={() => this.props.toggleSelected(product)} id={id} className={virtualClassName}>
        {/* <img id={imageId} className="categoryItemImage" src={product.url}></img> */}
        <img className="categoryItemImage" id={imageId} src={product.url} />
        {icon}
        <div className="categoryItemDescriptionDiv noToolTip">
          <div className="categoryItemText" id={textId}>{product.name}</div>
          {/* <div className="toolTip"><img className="categoryQuestionIcon" src={questionIcon} />
            <span className="categoryDescription">{category.description}</span>
          </div> */}
        </div>
      </div>
    )
  }
}