import { connect } from "react-redux";
import SettingsGallery from "./settings_gallery";
import React from "react";
import { withRouter } from "react-router-dom";
import { updateGallery, createGallery, deleteGallery, fetchPhotographer } from "../../../actions/service_actions";
import { sortMedia } from "../../../actions/project_actions";
import { showToast } from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
});

const mdp = (dispatch) => ({
  updateGallery: (gallery_data, gallery_id) => dispatch(updateGallery(gallery_data, gallery_id)),
  createGallery: (gallery_data) => dispatch(createGallery(gallery_data)),
  deleteGallery: (gallery_id) => dispatch(deleteGallery(gallery_id)),
  fetchPhotographer: (id) => dispatch(fetchPhotographer(id)),
  sortMedia: (medias) => dispatch(sortMedia(medias)),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsGallery));