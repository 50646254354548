import React from 'react';

import {
  AboutContactSectionContainer,
  AboutContactSectionWrapper,
  AboutContactSectionHeading,
} from './styles';
import { ContactForm } from '../../pageComponents';

const AboutContactSection = () => {
  return (
    <AboutContactSectionContainer>
      <AboutContactSectionWrapper>
        <AboutContactSectionHeading>
          We would love to hear about your project.
        </AboutContactSectionHeading>
        <ContactForm />
      </AboutContactSectionWrapper>
    </AboutContactSectionContainer>
  );
};

export default AboutContactSection;
