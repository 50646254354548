import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import VirtualStagingPage from './VirtualStagingPage';
import { closeVsModal, openVsModal } from "../../../actions/landing_page_actions";

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({
  setVSModal: (val) => {
    if (val) {
      dispatch(openVsModal());
    } else {
      dispatch(closeVsModal());
    }
  }
});

export default withRouter(connect(msp, mdp)(VirtualStagingPage));
