import styled from 'styled-components';

export const TermsWrapper = styled.div`
  margin: 0px auto;
  padding: 0px 30px 100px;
  width: 80%;
  max-width: 820px;
  text-align: justify;
  @media only screen and (max-width: 769px) {
    width: 82%;
    padding: 0px 0px 30px;
  }
  
  font-family: Nunito Sans;
  font-style: normal;
  color: #5B6B78;
  
  ul li {
    color: #5B6B78;
  }
  
  h2 {
    font-size: 24px;
    line-height: 37px;
    color: #353844;
    font-weight: bold;
  }
  
  h3 {
    color: #353844;
    font-weight: bold;
    text-decoration: underline;
    font-size: 20px;
    line-height: 24px;
  }
`;
