import { connect } from "react-redux";
import SettingsProfile from "./settings_profile";
import React from "react";
import { withRouter } from "react-router-dom";
import {updateProfile} from "../../../actions/settings_actions";
import { logout, removeCurrentUser } from "../../../actions/session_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  updateProfile: (user, user_id) =>  dispatch(updateProfile(user, user_id)),
  removeCurrentUser: () => dispatch(removeCurrentUser()),
  logout: () => dispatch(logout()),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsProfile));