import React from "react";
import {connect} from "react-redux";
import {showToast} from "../../../actions/toast_actions";


class SettingsServiceItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.handleInput = this.handleInput.bind(this)
    this.addInfoandOpenModal = this.addInfoandOpenModal.bind(this)
  }

  addInfoandOpenModal(modal) {
    this.props.addInfo(this.props.service);
    this.props.openModal(modal)
  }

  handleInput(field) {
    return e => this.setState({[field]: !this.state[field]});
  }

  render() {
    return (
      <div className="settingsServicesItem">
        <div className="itemTitle">{this.props.service.title}</div>
        <div className="itemIcon" onClick={() => this.addInfoandOpenModal("updateService")}><i
          className="far fa-edit settingsServiceIcon m-2"></i></div>
        <div className="itemIcon" onClick={() => this.addInfoandOpenModal("subServices")}><i
          className="fas fa-hammer settingsServiceIcon m-2"></i></div>
        <div className="itemIcon" onClick={() => this.addInfoandOpenModal("gallery")}><i
          className="far fa-images settingsServiceIcon m-2"></i></div>
        {/* <div className="itemIcon" onClick={() => {
          if (window.confirm('Are you sure you want to delete this service?')) {
            this.props.deleteService(this.props.service.id)
            this.props.showToast("Service has been removed")
          }
        }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div> */}
      </div>
    );
  }
}

const msp = (state, ownProps) => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(SettingsServiceItem);