import React, { useState, useEffect } from 'react';
import { Link, withRouter} from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import { useInView } from 'react-intersection-observer';
import FadeInAnimation from '../../home_page/homeComponents/FadeInAnimation';
import { connect } from "react-redux";

import {
  CreativeMedia,
  CreativeHeadParagraph,
  CreativeHeadSpan,
  PanelBody,
  CreativeImage,
  CreativePanelImageFirst,
  CreativePanelImageSecond,
  CreativePanelImage,
  PanelInfo,
  PanelTitle,
  PanelList,
  PanelLi,
  PanelDescription,
  GetStartedContainer,
  GetStarted,
  ArrowImage,
} from './styles';
import { CREATIVE_MEDIA } from '../../../../../assets/images/landing';

const DATA = [
  {
    id: 1,
    type: 2,
    image1: CREATIVE_MEDIA.Panel1,
    image2: CREATIVE_MEDIA.Panel1_1,
    image1Landscape: CREATIVE_MEDIA.Panel1Landscape,
    image2Landscape: CREATIVE_MEDIA.Panel1_1Landscape,
    title: 'Photography',
    items: ['Drone Photography', 'Interiors', 'Exteriors / Architectural'],
    description:
      'Every photograph we produce finds the beauty in your property while serving as a profitable asset.',
    hash: '/photography',
  },
  {
    id: 2,
    type: 1,
    image1: CREATIVE_MEDIA.Panel3,
    image2: null,
    image1Landscape: CREATIVE_MEDIA.Panel03Landscape,
    image2Landscape: null,
    title: 'Virtual Staging',
    items: [],
    description:
      'Virtual staging is cost effective and high impact, we will help you sell the potential of your space. We can work off of our own photography or photos you provide.',
    hash: '/virtual-staging',
  },
  {
    id: 3,
    type: 1,
    image1: CREATIVE_MEDIA.Panel4,
    image2: null,
    image1Landscape: CREATIVE_MEDIA.Panel04Landscape,
    image2Landscape: null,
    title: 'Renderings',
    items: ['Interiors', 'Exteriors'],
    description:
      'Renderings are the gold standard in pre-construction marketing.',
    hash: '/rendering',
  },
  {
    id: 4,
    type: 1,
    image1: CREATIVE_MEDIA.Panel5,
    image2: null,
    image1Landscape: CREATIVE_MEDIA.Panel05Landscape,
    image2Landscape: null,
    title: 'Interactive Tours',
    items: ['Matterport', 'Video Walkthroughs'],
    description:
      'Give your audience the ability to explore your property without requiring in-person travel.',
    hash: '/interactive-tours',
  },
  {
    id: 5,
    type: 1,
    image1: CREATIVE_MEDIA.Panel6,
    image2: null,
    image1Landscape: CREATIVE_MEDIA.Panel06Landscape,
    image2Landscape: null,
    title: 'Floor Plans',
    items: ['On-site Measure', '2D Floor Plans', '3D Floor Plans'],
    description:
      'We produce laser-precise floor plans faster than any other service.',
    hash: '/floor-plans',
  },
  {
    id: 6,
    type: 1,
    image1: CREATIVE_MEDIA.Panel7,
    image2: null,
    image1Landscape: CREATIVE_MEDIA.Panel07Landscape,
    image2Landscape: null,
    title: 'Video',
    items: ['Drone Cinematography', 'Interiors', 'Exteriors / Architectural'],
    description:
      'Video content has higher engagement and will help your property stand out online.',
    hash: '/video',
  },
];

const CreativeMediaComponent = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const { heading, subHeading, headingSize, buttonText } = props;
  const [imageLandscape, setImageLandscape] = useState(false);

  useEffect(() => {
    const onResize = () => {
      if (window.outerWidth < 768) {
        setImageLandscape(true);
      } else {
        setImageLandscape(false);
      }
    }

    onResize()

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  return (
    <div ref={ref}>
      <CreativeMedia color={props.bgColor || '#f6f8ff'} bgImage={props.bgImage ?? true} mobilePadding={props.mobilePadding}>
        <FadeInAnimation
          duration={0.5}
          yOffset={20} 
          inView={inView}
        >
          <Container>
            <CreativeHeadParagraph headingSize={headingSize}>
              {heading || props.children}
              {subHeading && <CreativeHeadSpan>{subHeading}</CreativeHeadSpan>}
            </CreativeHeadParagraph>
          </Container>
        </FadeInAnimation>

        <Row>
          {DATA.map((frame, index) => {
            const description = frame.description;
            const image01 =
              imageLandscape == false ? frame.image1 : frame.image1Landscape;
            const image02 =
              imageLandscape == false && frame.type === 2
                ? frame.image2
                : frame.image2Landscape;
            const item = frame.items;
            return (
              <Col key={frame.id} xs='12' sm='12' md='6' xl='4' className='panel'>
                <FadeInAnimation
                  duration={0.8} 
                  delayOrder={index < 3 ? 2 : 3 } 
                  yOffset={0} 
                  inView={inView}
                >
                  <Link to={frame.hash} >
                  <PanelBody>
                    <CreativeImage>
                      {frame.type === 1 ? (
                        <CreativePanelImage imageSrc={image01} alt='' />
                      ) : (
                        <>
                          <CreativePanelImageFirst imageSrc={image01} alt='' />
                          <CreativePanelImageSecond imageSrc={image02} alt='' />
                        </>
                      )}
                    </CreativeImage>
                    <PanelInfo>
                      <PanelTitle>{frame.title}</PanelTitle>
                      {item?.length > 0 && (
                        <PanelList>
                          {frame.items.map((item, index) => {
                            return <PanelLi key={index}>{item}</PanelLi>;
                          })}
                        </PanelList>
                      )}
                      <PanelDescription>{description}</PanelDescription>
                      <ArrowImage
                        className='rightArrow'
                        src={CREATIVE_MEDIA.RightArrow}
                        alt=''
                      />
                    </PanelInfo>
                  </PanelBody>
                  </Link>
                </FadeInAnimation>
              </Col>
            );
          })}
        </Row>
        <GetStartedContainer ref={ref1}>
          <FadeInAnimation
            duration={0.6}
            yOffset={0}
            inView={inView1}
          >
            <div onClick={onClick}>
              <GetStarted>{buttonText || 'Order Now'}</GetStarted>
            </div>
          </FadeInAnimation>
        </GetStartedContainer>
      </CreativeMedia>
    </div>
  );
};

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(CreativeMediaComponent));
