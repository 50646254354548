import {Link} from "react-router-dom";
import {ROUTES} from "../../../routes";
import AboutMapSection from '../../about_page_components/MapSection/index'
import React from "react";
import {ApplyButton, ButtonWrapper} from "./styles";

const MapSection = () => {
  return <AboutMapSection>
    <ButtonWrapper>
      <Link to={ROUTES.CAREERS_APPLY}>
        <ApplyButton>Apply Now</ApplyButton>
      </Link>
    </ButtonWrapper>
  </AboutMapSection>
}

export default MapSection