import React from 'react';
import {withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

import {VideoWrapper} from './VideoStyles';
import {
  BestProperty,
  ContractMedia,
  FullService,
  ServiceDropdown,
  ServicesButton,
  VideoTopSlider,
} from '../../pageComponents';
import {SERVICES, VIDEO} from '../../../../../assets/images/servicesPages';
import videoThumbnail1 from '../../../../../assets/images/landing/video-thumbnails/1.gif'
import videoThumbnail2 from '../../../../../assets/images/landing/video-thumbnails/2.gif'
import videoThumbnail3 from '../../../../../assets/images/landing/video-thumbnails/3.gif'
import videoThumbnail4 from '../../../../../assets/images/landing/video-thumbnails/4.gif'
import {PropertyComponent, ServiceLogoListComponent, TestimonialsComponent2} from "../../home_page/homeComponents";
import BundlesAndServices from "../../pageComponents/BundlesAndServices";
import {CONTRACT_MEDIA_DATA} from "../../home_page/homePageData";
import {CONTRACT} from "../../../../../assets/images/landing";
import {FullServiceTitle, FullServiceTitleGold} from "../../pageComponents/FullService/styles";

const FULL_SERVICE_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'Video Walkthrough Tours',
    content:
      'Video Tours offer an immersive preview of a property, letting potential buyers explore features and ambiance virtually, attracting serious interest.',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Instagram Reels',
    content:
      'Short video clips on social media can make a huge impact on the visibility and discovery of your listing. We’ll produce a trendy vertical video for your socials.',
  },
  {
    icon: SERVICES.ListIcon3,
    title: 'Free Video Hosting',
    content:
      'Easily view your property footage online and find web-friendly files ready for download in one location.  No more temporary transfers that are lost and expired in your inbox.',
  },
];

const VIDEO_LINKS = [
  {
    src: "https://player.vimeo.com/video/890027824",
    id: 1,
  },
  {
    src: "https://player.vimeo.com/video/890027887",
    id: 2,
  },
  {
    src: "https://player.vimeo.com/video/890027965",
    id: 3,
  },
  {
    src: "https://player.vimeo.com/video/890028079",
    id: 4,
  }
];

const IMAGE_LINKS = [
  {
    id: 1,
    text: "Stabilized Walkthroughs",
    src: videoThumbnail1,
  },
  {
    id: 2,
    text: "Stabilized Walkthroughs",
    src: videoThumbnail2,
  },
  {
    id: 3,
    text: "Property Tour",
    src: videoThumbnail3,
  },
  {
    id: 4,
    text: "Stabilized Walkthroughs",
    src: videoThumbnail4,
  }
];

const VideoPage = (props) => {
  const {location} = props;

  return (
    <VideoWrapper>
      <Helmet>
        <title>Real Estate Video Services | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Videographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href}/>
      </Helmet>
      <ServiceDropdown
        pathName={location.pathname}
        content={
          "Showcase your property with high-end real estate videography that includes stabilized walkthroughs, drone perspectives, and preview footage online before you go live."
        }
        contentLink={null}
      />
      <ServicesButton setModal={props.setModal} />
      <VideoTopSlider videoLinks={VIDEO_LINKS} imageLinks={IMAGE_LINKS}/>
      <FullService
        image={VIDEO.VideoFullService}
        data={FULL_SERVICE_DATA}>
        <FullServiceTitle>
          <FullServiceTitleGold>Video Walkthroughs</FullServiceTitleGold> increase views of real estate listings by up to 400%
        </FullServiceTitle>
      </FullService>

      <div className="lead-page-container">
        <ServiceLogoListComponent/>
      </div>

      <BundlesAndServices setModal={props.setModal}/>

      <ContractMedia
        imagesList={CONTRACT.ContractMediaSlides}
        data={CONTRACT_MEDIA_DATA}
        isVirtualStaging={false}
      />

      <TestimonialsComponent2/>

      <PropertyComponent/>
      <BestProperty/>
    </VideoWrapper>
  );
};

export default withRouter(VideoPage);
