import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import ProjectCreationLoginFormContainer from "./create_project_login_form_container";
import ProjectCreationSignupFormContainer from "./create_project_signup_form_container";
import Fireworks from "./fireworks";
import {formatDatetime} from "../../util/date_util";

class ProjectCreatedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: []
    }
  }

  render() {
    const project = this.props.project
    const servicesByAppointment = Object.values(project?.packages || []).reduce((res, pack) => {
      if (res[pack.appointment_time]) {
        res[pack.appointment_time].packages.push(pack)
      } else {
        if (pack.contractor) {
          res[pack.appointment_time] = { packages: [pack] }
        }
      }
      return res
    }, {})
           
    return (
      <div className="modal-background">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent congratsModalComponent">
            <div className="congratsModalHeading" id="congratsId"> Congrats your project is booked!</div>
            {/* <Fireworks /> */}
            <div className="congratsProjectInfo">
                <div className="congratsSubInfo">{this.props.project.address?.formatted_address}</div>
                <div className="congratsSubInfo">
                {
                  Object.values(servicesByAppointment).map(({ packages }) => {
                    return(
                      <div className="congratsSubInfoHighMargin">
                        <div className="congratsSubInfo">{packages.map(p => p.service_title).join(', ')}</div>
                        <div className="d-flex flex-column justify-content-between position-rel">
                          <div className="ms-2">
                            {formatDatetime(packages[0].appointment_time, "MM/DD/YYYY, hh:mm A", this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone)}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
            </div>
            </div>
            <div className="congratsButtons">
              <button className="congratsViewListingButton" onClick={() => this.props.history.push(`/projects_index/${project.slug}`)}>
                View Listing
              </button>
              <button className="congratsViewDashboardButton" onClick={() => this.props.history.push("/dashboard")}>
                Visit Dashboard
              </button>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(ProjectCreatedModal);