import React from "react";
import { Route, Link } from "react-router-dom";
import PhotoSelectionComponent from "./photo_selection_component";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";
import ModernAnglesUploadLogo from "../../../../assets/images/MAUploadIconLight";
import ModernAnglesUploadLogoSelected from "../../../../assets/images/MAUploadIcon";
import ModernAnglesLogoSelected from "../../../../assets/images/modern-angles-logo-selected"
import ModernAnglesLogo from "../../../../assets/images/MALogoLight";
import Cloud from '../../../../assets/images/cloud'
import Dropzone from "../dropzone"
import trashIconSrc from '../../../../assets/images/icons/fi_trash.svg'
import LocationComponent from "../location_component";
import { ThemeConsumer } from "styled-components";
import { fetchUserProjectsMapNonAbort, fetchProjectPhotos } from "../../util/project_api_util";
import Select from 'react-select'
import { DirectUploadProvider } from "react-activestorage-provider";
import {uploadPhotosVirtualStaging} from "../../util/virtual_staging_api_util";



export default class PhotoComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      minimize: this.props.minimize,
      projects: [],
      images: [],
      loading: false,
      disabled: false,
    }

    this.handler = this.handler.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.handlePicturesSelected = this.handlePicturesSelected.bind(this)
    this.submitPhotos = this.submitPhotos.bind(this)
    this.deletePhoto = this.deletePhoto.bind(this)
  }

  componentDidMount(){
    if (this.props.currentUser && !this.props.minimize){
     fetchUserProjectsMapNonAbort(this.props.currentUser.id).then(projects => this.setState({projects: projects}))
    }
  }

  onProjectChange(e){
    if (this.props.detailed_orders.length > 0){
      this.props.showToast("Please create a new order if you want to use photos from an additional project ")
    } else {
    this.props.changeParentState({project_id: e.value.project_id, project_address: e.label, project_address_full: e.value.address, project_client_id: e.value.client_id })
    fetchProjectPhotos(e.value.project_id).then(photos => this.props.changeParentState({photos: photos}))
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  changeUploadState(state){
    if (this.props.detailed_orders.length > 0 && this.props.upload && state === false){
      this.props.showToast("Please create a new order if you want to use photos from an existing project ")
    } else if (this.props.detailed_orders.length > 0 && !this.props.upload && state === true){
      this.props.showToast("Please create a new order if you want to use uploaded photos ")
    } else {
      this.props.changeParentState({upload: state})
    }
  }


  submitPhotos(medias) {
    let formData = new FormData()

    this.setState({loading: true})

    for (let i = 0; i < this.state.images.length; i++) {
      formData.append("media_upload[medias][]", medias[i])
    }

    uploadPhotosVirtualStaging(formData).then((photos) => {
      let newState = Object.assign({}, this.props.selectedPhotos)
      let newSelectedState = Object.assign({}, this.props.selectedPhotosWithInfo)

      photos.forEach(photo => {
        this.props.changeParentState({
          photos: [...this.props.photos, photo],
      })
      newState[photo.id] = { media_id: photo.id }
      newSelectedState[photo.id] = photo
    }
    )
    this.setState({loading: false, disabled: false})
    this.props.changeParentState({selectedPhotos: newState, selectedPhotosWithInfo: newSelectedState})
    })
  }

  handlePicturesSelected(event) {
    let images = Object.values(event?.target?.files || event)

    this.setState({ images: [...this.state.images, ...images] })
  }

  deletePhoto(index) {
    let images = this.props.photos
    images.splice(index, 1)
    this.setState({ images: images} )
  }

  render() {

    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""
    let modalContainerClass = "modalContainer"

    if (this.state.minimize) {
      icon = <div key="2"><i className="fas fa-plus"></i></div>
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 3) {
        checkmark = "createProjectCheckmarkShow"
      }

    }

    if (this.props.homepage){
      modalContainerClass = "modalContainerHomepage"
    }

    let component = ""
    let maIcon = ModernAnglesLogo
    let uploadIcon = ModernAnglesUploadLogo

    if (this.props.upload){
      uploadIcon = ModernAnglesUploadLogoSelected
      component = 
      <div>
          <LocationComponent changeParentState={this.props.changeParentState} inComponent={true} virtual_staging_homepage={true} title="" formatted_address={this.props.formatted_address} street={this.props.street} errors={this.props.propertyErrors} apartment={this.props.apartment} zipcode={this.props.zipcode} state={this.props.state} city={this.props.city} />
          <div className="">
            <DirectUploadProvider
              multiple
              headers={{ "ContentDisposition": "inline" }}
              onSuccess={medias => this.submitPhotos(medias)}
              render={({ handleUpload, uploads, ready, uploadProgress = 0 }) => (
                <>
                  <div className={modalContainerClass}>
                    <div className="uploadPhotoModalHeadingGroup">
                      <div className="uploadPhotoModalHeading"> Upload media</div>
                    </div>
                    <div className="importantInstructions">
                      <div>1. The progress bar can take some time to start loading for larger media uploads. </div> 
                      <div>2. Make sure the images you upload are high quality.</div>
                    </div>
                    <div>
                      <div className="">
                        <Dropzone handleDrop={(event) => {this.setState({images: event?.target?.files || event }); handleUpload(event?.target?.files || event)}}>
                          {<div className="uploadPhotoMainComponent">
                            <img className="uploadPhotoCloud mb-2" src={ModernAnglesUploadLogoSelected} />
                            <div className="addPhotosButton">
                              <label className="projectEditButton projectEditButtonPrimary">
                                <input onChange={(event) => {this.setState({images: event?.target?.files || event }); handleUpload(event?.target?.files || event) }} type="file" multiple="multiple"
                                  accept=".pdf, .cad, image/*, .NEF, .dng, .arw" />
                                Add Media
                              </label>
                            </div>
                          </div>}
                          <div className="">
                            <div className="">
                              {uploads.map(upload => {
                                switch (upload.state) {
                                  case 'waiting':
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Waiting to upload {upload.file.name}</div>
                                    </div>
                                  case 'uploading':
                                    uploadProgress += Math.round(upload.progress)
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Uploading {upload.file.name}: {Math.round(upload.progress)}%
                                      </div>
                                    </div>
                                  case 'error':
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Error uploading {upload.file.name}: {upload.error}
                                      </div>
                                    </div>
                                  case 'finished':
                                    uploadProgress += 100
                                    return <div className="uploadImageDiv" key={upload.id}>
                                      <div className="uploadFileName">
                                        Finished uploading {upload.file.name}
                                      </div>
                                    </div>
                                }
                              })}
                            </div>
                          </div>
                        </Dropzone>
                      </div>
                      <div className="uploadDiv">
                        <div className="uploadHeading">Upload Progress</div>
                        <div className="progress mb-3">
                          <div className="uploadProgress" style={{ width: `${uploadProgress / uploads.length}%` }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} />
          </div>
      </div>
    } else if (this.props.upload != null){
      maIcon= ModernAnglesLogoSelected
      const projectsOptions = [{ value: "", label: "Choose your project" }]

      this.state.projects.forEach(project => {
        projectsOptions.push({
          value: {project_id: project.id, client_id: project.client_id, address: project.address}, label: project.address.formatted_address || [
            project.address.street1,
            project.address.apartment_number,
            project.address.city,
            project.address.region,
            project.address.zip_code
          ].filter(v => !!v).join(', ') })
      })
      component = <div>
        <Select className="ml-auto virtualSuiteSelect" isDisabled={this.props.detailed_orders.length > 0 ? true : false} defaultValue={this.props.project_id ? { value: {project_id: this.props.project_id, user_id: this.props.project_user_id}, label: this.props.project_address } : { value: "", label: "Choose your project" }} options={projectsOptions} onChange={this.onProjectChange} />
      </div>
    }

    return (
      <div className="projectLocationComponent" id={this.props.homepage ? "photoComponentHomepage" : ""}>
        <div className="projectLocationHeadingGrouping" id={minimized}>
          <div className="projectLocationHeadingMinimizedGrouping">
            <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
            {this.state.minimize || this.props.homepage ? this.props.homepage ? <div className="settingsComponentHeading chooseYourServiceText">Upload Photos</div>  : <div className="settingsComponentHeading chooseYourServiceText">Choose Photos</div> : <div className="settingsComponentHeading chooseYourServiceText">Do you want to work on Modern Angles photos, or upload your own photos?</div>}
          </div>
          {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
        </div>
        <div id={minimizeId} className="row mt-3 mx-0 virtualSuiteCategoryGrid">
          <div>
            <div>
              {/* <div className="componentUploadHeading">Do you want to work on Modern Angles photos, or upload your own photos?</div> */}
              {/* <div>Make sure the images you upload are high quality</div> */}
            </div>
            {!this.props.homepage && <div className="photoUploadButtonsDiv">
             <div id={!this.props.upload && this.props.upload != null ? "virtualStagingUploadButtonSelected" : ""} className="virtualStagingUploadButton" onClick={() => this.changeUploadState(false)}>
                <img className="modernAnglesLogoUpload" src={maIcon}></img>
                <div>Modern Angles Project</div>
              </div>
              <div id={this.props.upload ? "virtualStagingUploadButtonSelected" : ""} className="virtualStagingUploadButton" onClick={() => this.changeUploadState(true)} >
                <img src={uploadIcon}></img>
                <div>Use Your Own Photos</div>
              </div>
            </div>}
            {component}
            <PhotoSelectionComponent deleteOrderPhoto={this.props.deleteOrderPhoto} homepage={this.props.homepage} detailed_orders={this.props.detailed_orders} loading={this.state.loading} upload={this.props.upload} photos={this.props.photos} category={this.props.category} changeGrandParentState={this.props.changeGrandParentState} changeParentState={this.props.changeParentState} page={this.props.page} waiting={this.props.waiting} summaryErrors={this.props.summaryErrors} isLargeScreen={this.props.isLargeScreen} selectedPhotos={this.props.selectedPhotos} selectedPhotosWithInfo={this.props.selectedPhotosWithInfo} />
          </div>
        </div>
      </div>
    )
  }
}