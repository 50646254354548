import React from "react";
import { Link, withRouter } from "react-router-dom";
import logoImage from '../../../../assets/images/MA_Logo_New_2'

class NavbarLoggedOutNonWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    return (
      <nav className="react-navbar">
        <Link to="/">
          <img className="reactNavLogoImage" src={logoImage} />
        </Link>
        <div className="navIconGroup">
          {/* <Link className="navLink" to="/">
            <i className="fas fa-home navIcon"></i>
          </Link> */}
          {/* <Link className="navLink" to="/blog">
            <i className="far fa-folder navIcon"></i>
          </Link>
          <Link className="navLink" to="/photographers" id={photographerClass}>
            <i className="fas fa-camera navIcon"></i>
          </Link>
          <Link className="navLink" to="/contact">
            <i className="fas fa-cog navIcon"></i>
          </Link> */}
        </div>
        <Link className="navAvatar" to="/login">
          <div className="navbarAvatar">Log In</div>
        </Link>
      </nav>
    )
  }
}

export default withRouter(NavbarLoggedOutNonWebsite)