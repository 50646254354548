import React from "react"
import VirtualStagingCategoriesContainer from "./virtual_staging_categories_container"
import VirtualStagingPhotosContainer from "./virtual_staging_photos_container"
import VirtualStagingProductsContainer from "./virtual_staging_products_container"
import VirtualStagingNotes from "./virtual_staging_notes"
import VirtualStagingCheckout from "./virtual_staging_checkout"
import CreatePurchaseModal from "./create_purchase_modal_container"
import VirtualStagingGalleryModal from "./virtual_staging_gallery_modal"
import VirtualSuiteComponent from "./virtual_suite_component"

export default class VirtualStagingMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      category: null,
      photos: {},
      selectedPhotos: {},
      product: null,
      specialNote: "",
      errors: [],
      orders: [],
      detailed_orders: [],
      paymentModalOpen: false,
      galleryModalOpen: false,
      account_credit: (this.props.project.client?.firm?.broker_charge && this.props.project.client?.account_credit ) || this.props.currentUser.roles.broker ? null : this.props.project.client?.account_credit?.amount,
      productModalOpen: false,
      currentPhoto: 0,
      currentProduct: 0,
      virtual_suite_type: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handler = this.handler.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.addMoreServices = this.addMoreServices.bind(this)
    this.deleteOrderPhoto = this.deleteOrderPhoto.bind(this)
    this.openPayment = this.openPayment.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    let project_id = this.props.project.id
    this.props.fetchVirtualStagingInfo(project_id)
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  addToCart() {
    if (!this.state.product) return

    let orders = this.state.orders
    let detailed_orders = this.state.detailed_orders
    let virtual_staging_details = {
      project_id: this.props.project.id,
      virtual_staging_photos_attributes: Object.values(this.state.photos),
      virtual_staging_product_id: this.state.product?.id,
      notes: this.state.specialNote
    }
    let order_details = {
      selected_photos: this.state.selectedPhotos,
      product: this.state.product,
      category: this.state.category,
      note: this.state.specialNote
    }

    orders.push(virtual_staging_details)
    detailed_orders.push(order_details)

    this.setState({
      detailed_orders: detailed_orders,
      orders: orders,
      category: null,
      photos: {},
      selectedPhotos: {},
      product: null,
      specialNote: "",
    })
  }

  addMoreServices() {
    this.addToCart()

    this.setState({
      page: 0
    })
  }

  deleteOrderPhoto(orderIndex, photoIndex) {
    if (!window.confirm('Are you sure you want to remove this photo?')) return
    let orders = this.state.orders
    let detailed_orders = this.state.detailed_orders

    const order = orders[orderIndex]
    const detailed_order = detailed_orders[orderIndex]

    if (order.virtual_staging_photos_attributes.length === 1) {
      orders.splice(orderIndex, 1)
      detailed_orders.splice(orderIndex, 1)
    } else {
      const [photo] = order.virtual_staging_photos_attributes.splice(photoIndex, 1)
      delete detailed_order.selected_photos[photo.media_id]
    }

    this.setState({orders: orders, detailed_orders: detailed_orders})
  }

  openPayment() {
    this.setState({paymentModalOpen: true})
  }

  handleSubmit() {
    let orders = this.state.orders
    let coupon_info = null;
    let discount = 0;
    let account_credit = this.state.account_credit
    let price = 0;

    price = this.state.detailed_orders.reduce((res, order) => {
      return res + Object.values(order.selected_photos).length * order.category.price
    }, 0)

    if (this.state.account_credit && price > 0) {
      if (this.state.account_credit >= price) {
        account_credit = this.state.account_credit - price
        price = 0
        discount = this.state.account_credit - account_credit
      } else {
        price = price - this.state.account_credit
        discount = this.state.account_credit
        account_credit = 0
      }
    }

    if (discount > 0 && this.state.account_credit) {
      coupon_info = { coupon_id: this.props.project?.client?.account_credit?.id, coupon_amount: parseInt(this.state.account_credit) - discount, coupon_discount_amount: discount }
    }
    let order_batch = {  coupon_info: coupon_info, project_id: this.props.project.id, virtual_staging_purchases_attributes: orders, broker_booked: this.props.currentUser.roles.broker}

    this.props.createVirtualStagingPurchase(order_batch).then(() => {
      if (this.props.errors.length === 0) {
        this.props.onClose()
        this.props.onReloadProject()
        if (discount > 0 && this.state.account_credit) {
          this.props.fetchCurrentUser(this.props.currentUser.id)
        }
      }
    })
  }

  handleNext(e) {
    e.preventDefault()
    const nextPage = this.state.page + 1

    let errors = []

    if (this.state.page === 0 && !this.state.virtual_suite_type){
     errors.push("Please select a type")
    }

    if (this.state.page === 1 && !this.state.category) {
      errors.push("Please select a category.")
    }

    if (this.state.page === 2 && Object.keys(this.state.photos).length === 0) {
      errors.push("Please select at least one photo.")
    }

    if (this.state.page === 3 && !this.state.product) {
      errors.push("Please select a product.")
    }

    this.setState({errors: errors})

    if (errors.length === 0) {
      this.setState({page: nextPage})

      if (nextPage === 5) {
        this.addToCart()
      }
    }
  }

  handleBack(e) {
    e.preventDefault()
    if (this.state.page === 0){
     this.setState({ page: 5, errors: [] })
    } else {
     this.setState({page: this.state.page - 1, errors: []})
    }
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value})
  }

  handler(newState) {
    this.setState(newState)
  }

  progress() {
    const {page} = this.state

    return [0, 1, 2, 3, 4, 5].map(i => {
      return <div className={`modalFooterProgressItem ${page >= i ? 'completed' : ''}`} key={`step-${i + 1}`}>
        {i + 1}
      </div>
    })
  }

  content() {
    switch (this.state.page) {
      case 0:
        return <VirtualSuiteComponent changeParentState={this.handler} virtual_suite_type={this.state.virtual_suite_type} />
      case 1:
        return <VirtualStagingCategoriesContainer selected={this.state.category} virtual_suite_type={this.state.virtual_suite_type} changeParentState={this.handler}/>
      case 2:
        return <VirtualStagingPhotosContainer selectedPhotos={this.state.photos}
                                              selectedPhotosWithInfo={this.state.selectedPhotos}
                                              changeParentState={this.handler}
                                              category={this.state.category}/>
      case 3:
        return <VirtualStagingProductsContainer selected={this.state.product} changeParentState={this.handler} page={this.state.page} product={this.state.product}
                                                category_id={this.state.category?.id}/>
      case 4:
        return <VirtualStagingNotes changeParentState={this.handler} specialNote={this.state.specialNote}
                                    onAddMoreServices={this.addMoreServices}/>
      case 5:
        return <VirtualStagingCheckout changeParentState={this.handler} account_credit={this.state.account_credit} deleteOrderPhoto={this.deleteOrderPhoto}
                                       detailed_orders={this.state.detailed_orders}
                                       specialNote={this.state.specialNote}/>
    }
  }

  buttons() {

    let orderingMore = this.state.orders.length > 0;

    if (this.state.page === 0 && orderingMore){
      return <>
        <div className="projectEditButton projectEditButtonWhite me-2" onClick={this.handleBack}>Order Summary</div>
        <div className="projectEditButton projectEditButtonPrimary" onClick={this.handleNext}>Next Step</div>
      </>
    }
    else if (this.state.page === 0) {
      return <button className="projectEditButton projectEditButtonPrimary" onClick={this.handleNext}>
        Next Step
      </button>
    } else if (this.state.page < 5) {
      return <>
        <div className="projectEditButton projectEditButtonWhite me-2" onClick={this.handleBack}>Previous</div>
        <div className="projectEditButton projectEditButtonPrimary" onClick={this.handleNext}>Next Step</div>
      </>
    } else {
      return <>
        <div className="projectEditButton projectEditButtonWhite me-2" onClick={this.handleBack}>Previous</div>
        {this.props.project.client.role.name === "admin" || this.props.project.client.role.name === "broker" || this.props.currentUser.roles.broker || (this.props.project.client.role.name === "sub_broker" && this.props.project.client.firm.broker_charge_virtual_staging) ? <div className="projectEditButton projectEditButtonBlue" onClick={this.openPayment}>Submit</div> : <div className="projectEditButton projectEditButtonBlue" onClick={this.openPayment}>Pay now</div>}
      </>
    }
  }

  modal() {
    if (this.state.paymentModalOpen) {
      let price = this.state.detailed_orders.reduce((res, order) => {
        return res + Object.values(order.selected_photos).length * order.category.price
      }, 0)

      if (this.state.account_credit && price > 0) {
        if (this.state.account_credit >= price) {
          price = 0
        } else {
          price = price - this.state.account_credit
        }
      }

      return <CreatePurchaseModal submitOrder={this.handleSubmit} project={this.props.project}
                                  changeParentState={this.handler} total={price}/>
    }

    if (this.state.galleryModalOpen) {
      return <VirtualStagingGalleryModal photos={this.props.photos} changeParentState={this.handler}
                                         currentPhoto={this.state.currentPhoto}/>
    }

    if (this.state.productModalOpen) {
      return <VirtualStagingGalleryModal photos={this.props.products} changeParentState={this.handler}
                                         currentPhoto={this.state.currentProduct}/>
    }
  }

  render() {
    let virtualStagingId = (this.state.paymentModalOpen || this.state.galleryModalOpen || this.state.productModalOpen) ?
      "hideVirtualStaging" : ""

    return (
      <div className="modal-background">
        {this.modal()}
        <div className="orderpreviewModal virtualStagingModal" id={virtualStagingId} onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="modalContainer modalContainerUpload">
              <button className="projectEditButton projectEditButtonLight modalCloseButton modalCloseButtonHomepage"
                      onClick={() => this.props.onClose()}>
                Cancel
              </button>
              <div className="closeIcon modalCloseButtonHomepageMobile" onClick={() => this.props.onClose()}><i className="fas fa-times" /></div>

              {this.content()}

              {this.state.errors.length > 0 && <ul className="virtualStagingErrors errors mt-3 mb-0">
                {this.state.errors.map((error, index) => {
                  return <li key={index}> {error} </li>
                })}
              </ul>}
            </div>
            <div className="modalFooter">
              <div className="modalFooterProgress">
                {this.progress()}
              </div>
              <div className="modalFooterButtons">
                {this.buttons()}
              </div>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}