import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import ServiceItem from "./serviceItem";
import ServiceSubservices from "./service_subservices"
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject";
import debounce from 'debounce'
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";

class ServicesSelectionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      updated_service: false,
      minimize: this.props.minimize
    }

    this.handler = this.handler.bind(this)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
  }

  // componentDidUpdate(){
  //   if (this.props.updated_selected_service && this.props.updated_selected_service != -1 && !this.state.updated_service) {
  //     this.setState({updated_service: true})
  //     this.props.changeParentState({ selected_service: this.props.updated_selected_service })
  //   }
  // }

  handler(newState) {
    if (newState.errors){
      this.setState(newState)
    } else {

      this.setState({errors: []})

      if (newState.selected_service){
        if (this.props.package_attributes[this.props.selected_service] && Object.values(this.props.package_attributes[this.props.selected_service]["package_sub_services_attributes"]).length > 0 && !this.props.appointments[this.props.selected_service]){
          this.setState({errors: ["You must continue to the next step for this selected service or deselect the associated packages before changing services."]})
          this.debouceScrollTo('.chooseYourServiceText')
        } else {
          this.debouceScrollTo('#subServicesScroll')
          this.props.changeParentState(newState)
        }
      } else {
      this.props.changeParentState(newState)
      }
    }
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  render() {
    let servicesLis = []
    if (this.props.services){
      if (this.props.specific_service != -1){
        servicesLis = Object.values(this.props.services || []).filter(service => service.id === this.props.specific_service).sort(function (a, b) { return a.sort_order - b.sort_order}).map((service) => <ServiceItem location_pricing={this.props.location_pricing} key={service.id} scrollTo={this.debouceScrollTo} zipcode={this.props.zipcode} service={service} changeParentState={this.handler} selected={this.props.selected_service === service.id} cancelled={this.props.cancelledPackages[service.id] && this.props.package_attributes[service.id] && !this.props.package_attributes[service.id]["appointment_time"]} previouslySelected={this.props.package_attributes[service.id] && Object.values(this.props.package_attributes[service.id]["package_sub_services_attributes"] || {}).length > 0} />)
      } else {
        servicesLis = Object.values(this.props.services || []).filter(service => service.subServices.filter(sub_service => this.props.addonServices ? sub_service.addon : !sub_service.addon).length > 0 && (!this.props.package_attributes[service.id] || this.props.selected_service === service.id)).sort(function (a, b) { return a.sort_order - b.sort_order }).map((service) => <ServiceItem location_pricing={this.props.location_pricing} key={service.id} scrollTo={this.debouceScrollTo} zipcode={this.props.zipcode} service={service} changeParentState={this.handler} selected={this.props.selected_service === service.id} cancelled={this.props.cancelledPackages[service.id] && this.props.package_attributes[service.id] && !this.props.package_attributes[service.id]["appointment_time"]} previouslySelected={this.props.package_attributes[service.id] && Object.values(this.props.package_attributes[service.id]["package_sub_services_attributes"] || {}).length > 0} />)
      }
    }
    let component = ""
    let heading_component = ""

    if (this.props.appointments[this.props.selected_service] && this.state.errors.length > 0){
      this.setState({errors: []})
    }

    if (this.props.selected_service != -1){
      component = <ServiceSubservices scrollTo={this.debouceScrollTo}
upgradingPackage={this.props.upgradingPackage} location_pricing={this.props.location_pricing} addonServices={this.props.addonServices} selectedSubservices={this.props.selectedSubservices} appointments={this.props.appointments} package_services={this.props.package_attributes} active_service={this.props.services[this.props.selected_service]} subservices={this.props.services[this.props.selected_service]?.subServices} changeParentState={this.handler}/>
      if (this.props.services != [] && this.props.services[this.props.selected_service]?.title){
        heading_component = this.props.upgradingPackage ? <div className="headingComponentSubServices">Change Your {this.props.services[this.props.selected_service]?.title} Package</div> : <div className="headingComponentSubServices">{this.props.services[this.props.selected_service]?.title} Packages</div>
      }
    }

    let icon = <div key="1"><i className="fas fa-window-minimize"></i></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""

    if (this.state.minimize) {
      icon = this.props.page >= 2 ? <div key="2"><i className="fas fa-plus"></i></div> : ""
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 2) {
        checkmark = "createProjectCheckmarkShow"
      }
    }

    if (servicesLis.length === 0 && this.props.loading === false){
      servicesLis.push(<div className="unfortunateDiv"><div className="unfortunate">Unfortunately there are no more services to add for this project.</div></div>)
    } else if (this.props.loading === true){
      servicesLis = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
      if (this.props.upgradingPackage){
      component = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
      }
    }

    const allErrors = [...this.state.errors, ...this.props.summaryErrors]

    return (
      <div className="projectLocationComponent">
        <div className="projectLocationHeadingGrouping" id={minimized}>
          <div className="projectLocationHeadingMinimizedGrouping">
             <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
            {!this.props.upgradingPackage && <div className="settingsComponentHeading chooseYourServiceText">{Object.values(this.props.appointments).length > 0 ? "Choose Your Service" : "Choose Your First Service"}</div>}
          </div>
          <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div>
         </div>
        {!this.props.upgradingPackage && <div className="subServicesSubheading" id={minimizeId}>You will be able to add additional services later in the ordering process.</div>}
        {allErrors.length > 0 && <div className="errorDiv mt-3 serviceSelectionErrors" id={minimizeId}>{allErrors}</div>}
          {!this.props.upgradingPackage && <div className="servicesList" id={minimizeId}>
              {servicesLis}
          </div>}
        <div id="subServicesScroll"></div>
        {heading_component}
        <div id={minimizeId}>{component}</div>
      </div>
    )
  }
}

export default withRouter(ServicesSelectionComponent);