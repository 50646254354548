import React from 'react';

import {
  HeaderWrapper,
  HeaderTitle,
  HeaderDescription,
  LineBreak,
} from './styles';

const PolicyHeader = () => {
  return (
    <HeaderWrapper>
        <HeaderTitle>
          Privacy Policy
        </HeaderTitle>
        <HeaderDescription>
          Last Updated: August 8th, 2021
        </HeaderDescription>
        <LineBreak />
    </HeaderWrapper>
  );
};

export default PolicyHeader;
