import React, {useEffect, useState} from 'react'

const Polyline = ({coords, map, onFinishConnecting}) => {
  const [polyline] = useState(() =>
    new google.maps.Polyline({
      path: coords,
      strokeColor: "#1B264F",
      strokeOpacity: 1.0,
      strokeWeight: 3,
      clickable: false,
      zIndex: 1,
      map
    })
  )

  const [firstCoordinateMarker] = useState(() => {
    return new google.maps.Marker({
      position: coords[0],
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        fillColor: '#506bca',
        strokeOpacity: 1.0,
        strokeColor: '#1B264F',
        strokeWeight: 3.0,
        scale: 5
      },
      map,
      zIndex: 2,
      clickable: true,
    });
  })

  useEffect(() => {
    polyline.setPath(coords)
    firstCoordinateMarker.addListener('click', onFinishConnecting)

    return () => {
      google.maps.event.clearInstanceListeners(firstCoordinateMarker);
    }
  }, [coords])

  useEffect(() => {
    return () => {
      polyline.setMap(null)
      firstCoordinateMarker.setMap(null)
    }
  }, [])

  return <></>
}

export default Polyline