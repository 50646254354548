import styled from "styled-components";

export const CareersThirdSectionWrapper = styled.div`
  padding: 104px 0px 109px 0px;
  background-color: #F2F7FA;
`;

export const Heading = styled.h2`
  width: 840px;
  margin: 0 auto 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #1B264F;
  text-transform: none;
  @media screen and (max-width: 799px) {
    font-size: 28px;
    line-height: 28px;
    width: 276px;
    margin: 0 auto 22px;
  }
`;

export const DescriptionText = styled.p`
  width: 730px;
  margin: 0 auto;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #18191F;
  @media screen and (max-width: 799px) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    width: 98%;
    max-width: 350px;
    margin-bottom: 15px;
  }
`;

export const Images = styled.div`
  position: relative;
  display: flex;
  width: 997px;
  margin: auto;
  
  @media only screen and (max-width: 1280px) {
    width: 760px;
  }
  
  @media only screen and (max-width: 769px) {
    width: 98%;
    
    &:not(:first-child) {
      margin-top: calc((100vw * -0.98 * 0.54 / 2 / 3));
    }
  }
  
  &:not(:first-child) {
    margin-top: -60px;
  }
  
  &:first-child {
    margin-top: 50px;
  }
`;

export const Image = styled.div`
  width: calc(50% + 40px);
  height: auto;
  
  &:nth-child(2) {
    margin-top: 135px;
  }
  
  @media only screen and (max-width: 769px) {
    &:nth-child(2) {
      margin-top: calc(100vw * 0.98 * 0.54 / 2 * 0.66);
    }
  }
  
  display: inline-block;
  
  img {
    width: 100%;
    object-fit: contain;
    box-shadow: 5px 5px 150px rgba(0, 0, 0, 0.15);
  }
  
  &:nth-child(odd) {
    z-index: 2;
  }
  
  &:nth-child(even) {
    z-index: 1;
    margin-left: -80px;
  }
`;
