import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { PhotographyWrapper } from './DroneStyles';
import {
  ServiceDropdown,
  ServicesGallery,
  FullService,
  BestProperty,
  ServicesButton,
  ImageSection,
  ContractMedia,
} from '../../pageComponents';
import {
  PHOTOGRAPHY,
  SERVICES,
} from '../../../../../assets/images/servicesPages';
import {PropertyComponent, ServiceLogoListComponent, TestimonialsComponent2} from "../../home_page/homeComponents";
import BundlesAndServices from "../../pageComponents/BundlesAndServices";
import {CONTRACT} from "../../../../../assets/images/landing";
import {CONTRACT_MEDIA_DATA} from "../../home_page/homePageData";
import {FullServiceTitle, FullServiceTitleGold} from "../../pageComponents/FullService/styles";

const { ROW_ONE, ROW_TWO, SectionImage1, SectionImage2 } = PHOTOGRAPHY;

const FULL_SERVICE_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'Drone Photography',
    content:
      'Drones can be the best tool to capture a unique perspective of your property.',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Double Exposure',
    content:
      'We use advanced photography techniques to get the room, or facade AND the view from the space’s windows captured in one perfect photo.',
  },
  {
    icon: SERVICES.ListIcon3,
    title: 'Architecural Approach',
    content:
      'We have the right equipment and training to emphasize the architectural elements of your property. This will mean taking photos without warping, where walls appear straight and true to their in-person color.',
  },
];

const DronePage = (props) => {
  const { location } = props;

  return (
    <PhotographyWrapper>
      <Helmet>
        <title>Real Estate Drone Photography Services | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ServiceDropdown
        pathName={location.pathname}
        content={
          'Our photographers will capture the highlights and significant features of your property in a constant effort to maximise its potential from behind the screen.'
        }
        contentLink={null}
      />
      <ServicesButton setModal={props.setModal} />
      <ImageSection
        SectionImage1={SectionImage1}
        SectionImage2={SectionImage2}
      />
      <ServicesGallery
        heading={'Properties with the right media sell more often and for'}
        span={'higher prices.'}
        subHeading={
          'We have helped hundreds of real estate projects out-perform the market.'
        }
        ROW_ONE={ROW_ONE}
        ROW_TWO={ROW_TWO}
      />
      <FullService
        image={PHOTOGRAPHY.PhotographyFullService}
        data={FULL_SERVICE_DATA}>
        <FullServiceTitle>
          Full service <FullServiceTitleGold>photography & image editing</FullServiceTitleGold>
        </FullServiceTitle>

      </FullService>
      <div className="lead-page-container">
        <ServiceLogoListComponent/>
      </div>

      <BundlesAndServices setModal={props.setModal}/>

      <ContractMedia
        imagesList={CONTRACT.ContractMediaSlides}
        data={CONTRACT_MEDIA_DATA}
        isVirtualStaging={false}
      />

      <TestimonialsComponent2/>

      <PropertyComponent/>
      <BestProperty/>
    </PhotographyWrapper>
  );
};

export default withRouter(DronePage);
