import React from 'react';
import {Col, Container} from 'reactstrap';

import {BestBtn, BestIn, BestLocation, BestPropertyContent, BestPropertyWrapper, BestSectionDescr,} from './styles';
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../../routes";

const BestProperty = (props) => {
  const {skipBorderBottom} = props
  const title = props.title || <>Let’s find the best<br/>in your listing.</>
  const description = props.description || <>
    Contact us if you’re interested in booking a shoot, and we’ll make sure you have all the details before
    making a decision.
  </>
  const history = useHistory();

  const onSpeakToTeam = (e) => {
    e.preventDefault()
    history.push(ROUTES.SCHEDULE_CONSULTATION)
  }

  return (
    <BestPropertyWrapper skipBorderBottom={skipBorderBottom}>
      <Container>
        <BestPropertyContent>
          <Col xs='7' sm='7' md='7' lg='7' className='firstSection'>
            <BestIn>{title}</BestIn>
            <BestSectionDescr>{description}</BestSectionDescr>
            <BestBtn onClick={onSpeakToTeam}>Speak With Our Team</BestBtn>
          </Col>
          <Col
            xs='5'
            sm='5'
            md='5'
            lg='5'
            className='secondSection proppertyIcon1'
          >
            <BestLocation id={'Tooltip-1'}/>
          </Col>
        </BestPropertyContent>
      </Container>
    </BestPropertyWrapper>
  );
};

export default BestProperty;
