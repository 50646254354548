import React from 'react';
import { useInView } from 'react-intersection-observer';

import FadeInAnimation from './FadeInAnimation';
import remax from "../../../../../assets/images/landing/remax-logo.svg";
import douglas from "../../../../../assets/images/landing/douglas-logo.svg";
import sotheby from "../../../../../assets/images/landing/sotheby-logo.svg";
import compass from "../../../../../assets/images/landing/compass-logo.svg";
import coldwell from "../../../../../assets/images/landing/coldwell-logo.svg";

const ServiceLogoListComponent = ({title, hideDouglas}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div ref={ref}>
      <FadeInAnimation
        duration={0.4}
        delayOrder={0}
        yOffset={-20}
        inView={inView}>
        <div className="lead-page-logos-wrapper">
          <div className="lead-page-logos-title">{title || 'Trusted By:'}</div>
          <div className="lead-page-logos">
            <img src={remax}/>
            {!hideDouglas && <img src={douglas}/>}
            <img src={sotheby}/>
            <img src={compass}/>
            <img src={coldwell}/>
          </div>
        </div>
      </FadeInAnimation>
    </div>
  );
};

export default ServiceLogoListComponent;
