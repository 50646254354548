import React from "react";
import {withRouter} from "react-router-dom";
import NavbarContainer from "./navbar/navbar_container";
import MapDashboard from "./dashboard/map_dashboard";
import NewProjectsDashboard from "./dashboard/new_projects_dashboard";
import UpcomingProjectsDashboard from "./dashboard/upcoming_projects_dashboard";
import modernAnglesLogo from "../../../assets/images/MA_Logo_New_2";
import {fetchUserProjectsDashboard, fetchUserProjectsMap, fetchUserProjectsMapNonAbort} from "../util/project_api_util";
import {fetchTermsOfService} from "../util/terms_of_service_util";
import ReferralsDashboard from "./dashboard/referrals_dashboard";
import { Helmet } from 'react-helmet-async';
import NotificationsModal from "./dashboard/notifications_modal";
import NotificationBellContainer from "./notification_bell_container";


class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      all_projects: [],
      termsOfServiceModalOpen: false,
      notificationModalOpen: false,
      terms: null,
      full_map_results: false,
      map_loading: false
    }
    this.openTermsOfService = this.openTermsOfService.bind(this)
    this.acceptTermsOfService = this.acceptTermsOfService.bind(this)
    this.abortController = new AbortController();
    this.getFullResults = this.getFullResults.bind(this)
    this.handler = this.handler.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'unset';
    if (this.props.currentUser.acceptedToS == false) {
      this.setState({termsOfServiceModalOpen: true})
      fetchTermsOfService(this.props.currentUser.main_role).then(terms => this.setState({terms}))
    }

    if (this.props.currentUser.roles.post_production) {
      this.props.history.push("/projects_index")
    } else if(this.props.currentUser.roles.sales_rep){
      this.props.history.push("/referral")
    } else {
      const abortSignal = this.abortController.signal;

      fetchUserProjectsDashboard(this.props.currentUser.id, {
        signal: abortSignal,
      }).then((projects) => {
        if (!this.unmounted) {
          this.setState({projects: projects})
        }
      }).catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Dashboard request aborted by user or code.');
        } else {
          // Handle other errors
          this.setState({ error });
        }
      });

      fetchUserProjectsMapNonAbort(this.props.currentUser.id, this.state.full_map_results).then((projects) => {this.setState({all_projects: projects})})

      window.scrollTo(0, 0)
    }
  }

  componentWillUnmount() {
    if (!this.props.currentUser.roles.post_production && !this.props.currentUser.roles.sales_rep && this.props.currentUser.acceptedToS == false) {
      this.props.logout()
    }

    // Set a flag to indicate that the component is unmounted
    this.unmounted = true;

    // Cancel the ongoing request when the component unmounts
    this.abortController.abort();
  }

  openTermsOfService(e) {
    e.preventDefault();
    this.props.openModal("viewTermsOfService", {terms: this.state.terms})
  }

  getFullResults(){
    this.setState({map_loading: true, full_map_results: true})
    fetchUserProjectsMapNonAbort(this.props.currentUser.id, true).then((projects) => {
        this.setState({ all_projects: projects, map_loading: false })
    })
  }

  acceptTermsOfService(e) {
    e.preventDefault();
    this.props.agreeToTerms(this.props.currentUser.id).then(() => this.setState({termsOfServiceModalOpen: false}))
  }

  handler(newState) {
    this.setState(newState)
  }

  render() {
    const {currentUser} = this.props
    let upcomingProjects = this.state.projects?.filter(project => project.status === "booked") || [];

    let termsOfServiceModal = ""

    if (this.state.termsOfServiceModalOpen) {
      termsOfServiceModal = <div className="modal-background">
        <div className="orderpreviewModal contractorSchedulingModal termsOfServiceModalNew"
             onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="contractorModalHeading">
              <div className="confirmationModalDescription">Terms Of Serivce</div>
            </div>
            {this.state.terms && (
              <>
                {/* <small className="text-muted float-right">{terms.version}</small> */}

                <div className="termsOfServiceContent clearfix editorContent">
                  <div dangerouslySetInnerHTML={{__html: this.state.terms.content}}/>
                </div>
              </>
            )}
            <div className="termsOfServiceAcceptButtons">
              <div onClick={() => this.props.logout()} className="signupFormPrevious">Cancel</div>
              <div onClick={this.acceptTermsOfService} className="signupFormNext">Accept</div>
            </div>
          </div>
        </div>
      </div>
    }
    return (
      <div className="dashboardFullPageDiv">
        <Helmet>
          <title>Home | Modern Angles</title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="description"
            content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
          />
        </Helmet>
        {termsOfServiceModal}
        <NavbarContainer/>
        <div className="dashboardMainDiv" id="dashboardMainMainDiv">
          <div className="pageHeading" id="pageHeadingDashboard">
            <div>
              <div className="dashboardSubHeading">
                <div>Hello, {currentUser.firstName}</div>
                {/* <img className="logoDashboardImage" src={modernAnglesLogo}/> */}
              </div>
              <div className="welcomeBackSubHeading">
                Welcome back to Modern Angles
                {currentUser.account_credit?.amount && <div className="extra-small account_credit_dashboard_div">
                  Account Credit: ${currentUser?.account_credit?.amount}
                </div>}
              </div>
            </div>
            <NotificationBellContainer />
          </div>
        
          <div className="dashboardGroups">
            <NewProjectsDashboard currentUser={this.props.currentUser}/>
            <UpcomingProjectsDashboard currentUser={this.props.currentUser}
                                       upcomingProjects={upcomingProjects}
                                       previousProjects={this.state.projects?.filter(project => project.status != "booked") || []}/>
            {this.props.currentUser.roles.admin ? "" : <ReferralsDashboard />}
            <MapDashboard projects={this.state.all_projects} map_loading={this.state.map_loading} full_map_results={this.state.full_map_results} getFullResults={this.getFullResults} />
          </div>
          <div className="mobilePushMapAboveNavbar"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(Dashboard);