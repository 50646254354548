import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { FloorPlansWrapper } from './FloorPlansStyles';
import {
  ServiceDropdown,
  FullService,
  BestProperty,
  FloorPlansTopSection,
  ContractMedia,
} from '../../pageComponents';
import {
  FLOOR_PLANS,
  SERVICES,
} from '../../../../../assets/images/servicesPages';
import {PropertyComponent, ServiceLogoListComponent, TestimonialsComponent2} from "../../home_page/homeComponents";
import BundlesAndServices from "../../pageComponents/BundlesAndServices";
import {CONTRACT} from "../../../../../assets/images/landing";
import {CONTRACT_MEDIA_DATA} from "../../home_page/homePageData";
import {FullServiceTitle, FullServiceTitleGold} from "../../pageComponents/FullService/styles";

const FULL_SERVICE_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'Lightning Turn Around',
    content:
      'Creating floor plans requries a person on-site with the right equipment. We have finessed this process to require as little time as possible and have trained team members to efficeintly collect accurate measurements.',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Print & Web Ready',
    content:
      'We prepare files that are immediately ready for use in your marketing materials.',
  },
  {
    icon: SERVICES.ListIcon3,
    title: 'Graphic / 3D Floor Plan',
    content:
      'We can also create beautiful plans with 3D effects, material textures, and greenery. This can be an interesting way to showcase your property in the context of its landscaping.',
  },
];

const FloorPlansPage = (props) => {
  const { location } = props;

  return (
    <FloorPlansWrapper>
      <Helmet>
        <title>Real Estate Floor Plan Services | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ServiceDropdown
        pathName={location.pathname}
        content={
          "Expand your marketing kit by providing context and scale with detailed Floor Plans. We've equipped our photographers with advanced scanning systems that measure your space accurately, and in minutes."
        }
        contentLink={null}
        isExtraPadding={true}
      />
      <FloorPlansTopSection setModal={props.setModal} />
      <FullService
        image={FLOOR_PLANS.FloorPlansFullService}
        data={FULL_SERVICE_DATA}>
        <FullServiceTitle>
          On-site 2D & 3D <FullServiceTitleGold>Floor Plans</FullServiceTitleGold>
        </FullServiceTitle>
      </FullService>

      <div className="lead-page-container">
        <ServiceLogoListComponent/>
      </div>

      <BundlesAndServices setModal={props.setModal}/>

      <ContractMedia
        imagesList={CONTRACT.ContractMediaSlides}
        data={CONTRACT_MEDIA_DATA}
        isVirtualStaging={false}
      />

      <TestimonialsComponent2/>

      <PropertyComponent/>
      <BestProperty/>
    </FloorPlansWrapper>
  );
};

export default withRouter(FloorPlansPage);
