import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Col } from 'reactstrap';
import { ROUTES } from '../../../routes';
import { connect } from "react-redux";

import {
  FooterWrapper,
  FooterContent,
  FooterUpperContent,
  FooterLogo,
  FooterMenuWrapper,
  FooterMenuHeading,
  FooterMenuItem,
  FooterLowerContent,
  FooterLowerContentWrapper,
  ReservedText,
  FooterSocialIconsWrapper,
  FooterSocialIcon,
  CustomRow,
  SectionWithLogo,
  LinksSection,
  ButtonContainer,
  LoginButton,
  SignUpButton,
  FooterLowerContainer,
} from './styles';
import { FOOTER_IMAGE } from '../../../../../assets/images/landing';
import {LINKS} from "../../../../../assets/images/contactForm";
import { Footer } from '../../pages/subdomain/Book/Footer';

const FooterSection = (props) => {
  const {displayLoginButtons = true, displayOrderNow = true} = props


  const onScheduleShoot = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }
  
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterUpperContent>
          <CustomRow>
            <SectionWithLogo>
              <Link to={'/'}>
                <FooterLogo src={FOOTER_IMAGE.FooterLogo} />
              </Link>
              {displayLoginButtons && <ButtonContainer>
                <Link to={ROUTES.LOGIN}>
                  <LoginButton>Log In</LoginButton>
                </Link>
                {displayOrderNow ? <div onClick={onScheduleShoot}>
                  <LoginButton className="specialWidth">Schedule Shoot</LoginButton>
                </div> :
                ""
                }
              </ButtonContainer>}
            </SectionWithLogo>
            <LinksSection>
            <Col className='middleSection' xs='12' sm='4' md='4' lg='4'>
              <FooterMenuWrapper>
                <FooterMenuHeading>Service</FooterMenuHeading>
                {/* <Link to='#'>
                  <FooterMenuItem>What We Do</FooterMenuItem>
                </Link> */}
                <Link to={ROUTES.PHOTOGRAPHY}>
                  <FooterMenuItem>Services</FooterMenuItem>
                </Link>
                <Link to={ROUTES.FAQ}>
                  <FooterMenuItem>FAQ</FooterMenuItem>
                </Link>
                {/* <Link to={ROUTES.LOCATIONS}>
                  <FooterMenuItem>Our service areas</FooterMenuItem>
                </Link> */}
                {/* <Link to='#'>
                  <FooterMenuItem>Pricing</FooterMenuItem>
                </Link> */}
                {/* <Link to='#'>
                  <FooterMenuItem>Case Studies</FooterMenuItem>
                </Link> */}
              </FooterMenuWrapper>
            </Col>
            <Col className='middleSection' xs='12' sm='4' md='4' lg='4'>
              <FooterMenuWrapper>
                <FooterMenuHeading>Company</FooterMenuHeading>
                <Link to={ROUTES.ABOUT}>
                  <FooterMenuItem>About</FooterMenuItem>
                </Link>
                <Link to={ROUTES.CAREERS}>
                  <FooterMenuItem>Careers</FooterMenuItem>
                </Link>
                {/* <Link to='#'>
                  <FooterMenuItem>Press</FooterMenuItem>
                </Link> */}
                {/* <Link to='#'>
                  <FooterMenuItem>Careers</FooterMenuItem>
                </Link> */}
                <Link to={ROUTES.CONTACTS}>
                  <FooterMenuItem>Contact</FooterMenuItem>
                </Link>
                {/* <Link to='#'>
                  <FooterMenuItem>Partnerships</FooterMenuItem>
                </Link> */}
              </FooterMenuWrapper>
            </Col>
            <Col className='middleSection' xs='12' sm='4' md='4' lg='4'>
              <FooterMenuWrapper>
                <FooterMenuHeading>Support</FooterMenuHeading>
                {/* <Link to='#'>
                  <FooterMenuItem>Help Center</FooterMenuItem>
                </Link> */}
                <Link to={ROUTES.TERMS_OF_SERVICE}>
                  <FooterMenuItem>Terms of service</FooterMenuItem>
                </Link>
                <Link to={ROUTES.PRIVACY_POLICY}>
                  <FooterMenuItem>Privacy Policy</FooterMenuItem>
                </Link>
                {/* <Link to='#'>
                  <FooterMenuItem>Status</FooterMenuItem>
                </Link> */}
              </FooterMenuWrapper>
            </Col>
          </LinksSection>
        </CustomRow>
        </FooterUpperContent>
        <FooterLowerContent>
          <FooterLowerContainer>
            <FooterLowerContentWrapper>
              <ReservedText>
                © {new Date().getFullYear()} Modern Angles. All rights reserved
              </ReservedText>
              <FooterSocialIconsWrapper>
                <a href={LINKS.INSTAGRAM} target="_blank">
                  <FooterSocialIcon src={FOOTER_IMAGE.Instagram} />
                </a>
                <a href={LINKS.LINKEDIN} target="_blank">
                  <FooterSocialIcon src={FOOTER_IMAGE.Linkedin} />
                </a>
                <a href={LINKS.FACEBOOK} target="_blank">
                  <FooterSocialIcon src={FOOTER_IMAGE.Facebook} />
                </a>
              </FooterSocialIconsWrapper>
            </FooterLowerContentWrapper>
          </FooterLowerContainer>
        </FooterLowerContent>
      </FooterContent>
    </FooterWrapper>
  );
};

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(FooterSection));
