import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 104px 0px 109px 0px;
  background-color: #FAFBFF;
  border-top: 1px solid #E6E8EF;
  
  @media only screen and (max-width: 769px) {
    padding: 70px 0px;
  }
  
  border-bottom: 1px solid #E6E8EF;
`;

export const WrapperInner = styled.div`
  width: 1100px;
  margin: auto;
  
  @media only screen and (max-width: 1100px) {
    width: 900px;
  }
  
  @media only screen and (max-width: 769px) {
    width: calc(100vw - 80px);
    margin: auto;
  }
`;


export const Heading = styled.h2`
  width: 840px;
  margin: 0 auto 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #1B264F;
  text-transform: none;
  @media screen and (max-width: 799px) {
    font-size: 28px;
    line-height: 28px;
    width: 276px;
    margin: 0 auto 22px;
  }
`;

export const List = styled.div`
  display: flex;
  margin-right: -15px;
  flex-wrap: wrap;
  margin-bottom: 60px;
  margin-top: 70px;
  
  @media only screen and (max-width: 769px) {
    margin-top: 40px;
    margin-bottom: -36px;
  }
`

export const ListItem = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(25% - 15px);
  margin-bottom: 36px;
  
  @media only screen and (max-width: 990px) {
    width: calc(33.3% - 15px);
  }
  
  @media only screen and (max-width: 767px) {
    width: calc(50% - 15px);
  }
  
  a:hover {
    .city-list-item-title {
       color: #506BCA;
    }
  }
`

export const ListItemImg = styled.img`
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 155px;
  display: block;
  object-fit: cover;
  
  @media only screen and (max-width: 767px) {
    height: 89px;
  }
`

export const ListItemText = styled.div`
  padding: 20px;
  background: #fff;
  width: 100%;
  border-radius: 0 0 8px 8px;
  border-left: 1px solid #F5F5F8;
  border-right: 1px solid #F5F5F8;
  border-bottom: 1px solid #F5F5F8;
  
  @media only screen and (max-width: 767px) {
    padding: 8px 12px;
  }
`

export const ListItemTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1B264F;
`

export const ListItemDescription = styled.div`
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-size: 15px;
  line-height: 100%; 
  text-align: center;
  color: #506BCA;
  margin-top: 6px;
`

export const ButtonWrapper = styled.div`
  margin-top: 60px;
  text-align: center;
  
  @media only screen and (max-width: 950px) {
    margin-top: 40px; 
  }
`