import React from "react";
import { Route, Link, withRouter } from "react-router-dom";

class OrderCreatedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: []
    }
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    const orders = this.props.orders
    

    return (
      <div className="modal-background">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent congratsModalComponent">
            <div className="congratsModalHeading"> Congrats your order has been placed!</div>
            {/* <Fireworks /> */}
            <div className="congratsProjectInfo congratsProjectInfoVS">
              <div className="congratsSubInfo">
                <table className="virtualStagingCreatedTable">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="virtualStagingCongrats">Category</th>
                      <th class="virtualStagingCongrats">Product</th>
                      <th class="virtualStagingCongrats">Photos</th>
                      <th class="">Price</th>
                    </tr>
                  </thead>
                  {
                    Object.values(orders).map((order,i)=> {
                      return (
                        <tr key={`order-${i}`} className="">
                          <td />
                          <td className="virtualStagingCongrats">{order.category.name}</td>
                          <td className="virtualStagingCongrats">{order.product.name}</td>
                          <td className="">{Object.values(order.selected_photos).length}</td>
                          <td className="text-muted">${parseInt(order.category.price * Object.values(order.selected_photos).length).toFixed(2)}</td>
                        </tr>
                      )
                    })
                  }
                </table>
              </div>
            </div>
            <div className="congratsButtons">
              <button className="congratsViewListingButton" onClick={() => this.props.history.push(`/projects_index/${this.props.project_id}`)}>
                View Listing
              </button>
              <button className="congratsViewDashboardButton" onClick={() => this.props.history.push("/dashboard")}>
                Visit Dashboard
              </button>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(OrderCreatedModal);