import {CLOSE_TOAST, SHOW_TOAST} from "../actions/toast_actions";

const toastsReducer = (state = [], action) => {
  Object.freeze(state);
  switch (action.type) {
    case SHOW_TOAST:
      return state.concat({message: action.message, id: Math.random() * 1000})
    case CLOSE_TOAST:
      return state.filter(t => t.id !== action.toast.id)
    default:
      return state
  }
};

export default toastsReducer;