import React, {useCallback, useState} from 'react'
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {CAPTCHA_SITEKEY} from "../../../../util/recaptcha_utils";
import {sendBookConsultationDiscount, sendBookConsultationCallback} from "../../../../util/book_consultation_api_utils";

const InitialInfoInsideCaptchaProvider = (props) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const {executeRecaptcha} = useGoogleReCaptcha()

  const onInput = (fieldName) => {
    return (e) => {
      e.preventDefault()
      setData({...data, [fieldName]: e.target.value})
    }
  }

  const fields = [
    {label: 'First name', field: 'firstName', type: 'text', required: true},
    {label: 'Last name', field: 'lastName', type: 'text', required: true},
    {label: 'Email', field: 'email', type: 'email', required: true},
    {label: 'Phone number', field: 'phoneNumber', type: 'text'}
  ]

  const onSubmitWithRecaptcha = useCallback(async (e) => {
    if (!executeRecaptcha) {
      return;
    }

    e.preventDefault()
    const token = await executeRecaptcha('bookConsultationDiscount');
    onSubmit(e, token)
  }, [executeRecaptcha, data]);

  const onSubmit = (e, token) => {
    e.preventDefault()

    setLoading(true)
    if (props.callbackPage){
      sendBookConsultationCallback(data, token).then(() => {
        setLoading(false)
        props.onCompleted(data)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      sendBookConsultationDiscount(data, token).then(() => {
        setLoading(false)
        props.onCompleted(data)
      }).catch(() => {
        setLoading(false)
      })
    }
  }

  return <form onSubmit={onSubmitWithRecaptcha}>
    <div className="initial-info-container title-container">
      {/* <h1>Ready to <span className="blue">save 20%</span> on your first shoot?</h1> */}
      <h1>Transform Your Listing with Real Estate Photography</h1>
    </div>

    <div className="content-wrapper">
      <div className="initial-info-container content-container">
        {!props.callbackPage && <div className="apply-for-discount-initial-info-title">
          Don’t miss out on this limited time offer
        </div>}

        {props.callbackPage ?  <div className="apply-for-discount-initial-info-subtitle">
          Enter your info and a photography specialist will get in touch with you.
        </div> : <div className="apply-for-discount-initial-info-subtitle">
          Claim your <span className="blue-bg">20%</span> discount code
        </div>}

        <div className="lead-page-divider" />

        <div className="apply-for-discount-form">
          {fields.map((field, i) => (
            <div className="apply-for-discount-form-field" key={`field-${i}`}>
              <label id="formLabel" className={`formLabel ${field.required ? 'required' : ''}`}>{field.label}</label>
              <input className="formInput" placeholder="Content" value={data[field.field]}
                     onInput={onInput(field.field)} required={field.required} type={field.type}/>
            </div>
          ))}
        </div>

        <div className="lead-page-divider" />

        {props.callbackPage ? <button className="projectEditButton projectEditButtonBlue w-100" type="submit" disabled={loading}>
          Submit
        </button> : <button className="projectEditButton projectEditButtonBlue w-100" type="submit" disabled={loading}>
          Email Code
        </button>}

        <div className="apply-for-discount-terms">
          {props.callbackPage ? "By clicking Submit, I agree to the Privacy Policy and Terms of Service" : "By clicking Email Code, I agree to the Privacy Policy and Terms of Service"}
        </div>
      </div>
    </div>
  </form>
}

export const InitialInfo = (props) => {
  return <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_SITEKEY}>
    <InitialInfoInsideCaptchaProvider {...props} />
  </GoogleReCaptchaProvider>
}