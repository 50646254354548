import React from 'react';

import {
  MatterportTourWrapper,
  IFrameWrapper,
} from './styles';


const MatterportTour = ({ link }) => {
  return (
    <MatterportTourWrapper>
      <IFrameWrapper
        src={link}
        frameborder='0' 
        allowfullscreen 
        allow='xr-spatial-tracking'
      />
    </MatterportTourWrapper>
  );
};


export default MatterportTour;
