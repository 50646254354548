import styled from 'styled-components';

export const ContractMediaSlide4Part1 = styled.img`
  width: 75%;
  position: absolute;
  right: 15%;
  top: 15%;
  padding-bottom: 200px;
  @media only screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
`;

export const ContractMediaSlide4Part2 = styled.img`
  width: 75%;
  position: absolute;
  top: -3%;
  left: -2%;
  z-index: 2;
`;
