import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {BestPropertyWrapper, LocationWrapper} from "./LocationStyles";
import LocationHeader from "../../location_page_components/LocationHeader";
import {TopPhotographer} from "../../location_page_components/TopPhotographer";
import {LocationLogos} from "../../location_page_components/Logos";
import {fetchLocation} from '../../../util/location_api_util';
import {BestProperty, ContactFormGallery} from '../../pageComponents';
import {TestimonialsComponent2} from '../../home_page/homeComponents';
import BundlesAndServices from "../../pageComponents/BundlesAndServices";
import {ServicesDescription} from "../../location_page_components/ServicesDescription";

import galleryImage1 from '../../../../../assets/images/location/gallery/1.jpg'
import galleryImage2 from '../../../../../assets/images/location/gallery/2.jpg'
import galleryImage3 from '../../../../../assets/images/location/gallery/3.jpg'
import galleryImage4 from '../../../../../assets/images/location/gallery/4.jpg'
import {HowItWorks} from "../../pageComponents/HowItWorks";
import {BlueContainer, Wrapper} from "../../location_page_components/TopPhotographer/styles";


const LocationPage = (props) => {
  const [city, setCity] = useState({})

  useEffect(() => {
    fetchLocation(props.match.params.city).then(city => setCity(city));
  }, []);

  const images = [
    {src: galleryImage1},
    {src: galleryImage2},
    {src: galleryImage3},
    {src: galleryImage4},
  ]

  const howItWorksItems = [
    {
      title: 'Online Scheduling',
      description: `Book ${city?.city} photographers in just a few clicks with our easy-to-use online scheduling platform.`
    },
    {
      title: 'At The Shoot',
      description: 'Photographer arrives, prepared to create a winning game plan to capture your property at its best.'
    },
    {
      title: 'Media Delivery',
      description: 'Get immediate notification emails for your MLS-ready media, available for download within 24 hours.'
    }
  ]

  const bundlesTitle = <>Professional Media <br/> <span className="blue">Packages in {city?.city}</span></>;

  return <LocationWrapper>
    <Helmet>
      <title>{`Real Estate Photography Services in ${city?.city}, ${city?.state} | Modern Angles`}</title>
      <meta
        name="description"
        content={`Enhance your listings with Modern Angles, ${city?.city}'s premiere real estate photography & 3D virtual tour service. Schedule and order online, 24/7.`}
      />
      <link rel="canonical" href={window.location.href}/>
    </Helmet>
    <LocationHeader city={city} setModal={props.setModal}/>
    <TopPhotographer city={city.city}/>
    <BlueContainer>
      <Wrapper>
        <HowItWorks items={howItWorksItems}
                    subtitle={`Get more listing inquiries in ${city?.city} with Modern Angles premium real estate photography & video services.`}/>
      </Wrapper>
    </BlueContainer>

    <ServicesDescription city={city?.city}/>
    <BundlesAndServices setModal={props.setModal} bundlesTitle={bundlesTitle}/>

    <BestPropertyWrapper>
      <BestProperty skipBorderBottom={true}
                    title={<>Let’s find the best<br/>in your {city?.city} listing</>}
                    description={<>Contact us if you’re interested in booking a shoot, and we’ll make sure you have all
                      the details before making a decision.<br/><br/>We take pride in offering premier real estate
                      photography services across the vibrant {city?.city} area.</>}/>
    </BestPropertyWrapper>
    <ContactFormGallery isImageMarkerLight={true} images={images}/>
    <TestimonialsComponent2 needsMarginTopAndBottom={true}/>
    <LocationLogos/>
  </LocationWrapper>
}

export default withRouter(LocationPage);