import React from "react";
import SubServiceItem from "./sub_service_item";
import {Features} from "./features";


export default class SubServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      price: "",
      contractor_payout: "",
      hidden: false,
      reshoot: false,
      addon: false,
      always_show_addon: false,
      promo: false,
      bundle_description: "",
      features: ['']
    }
    this.handleInput = this.handleInput.bind(this)
    this.createSubService = this.createSubService.bind(this)
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value});
  }

  handleCheckbox(field) {
    return e => this.setState({[field]: !this.state[field]})
  }

  createSubService() {
    let sub_service_info = {
      always_show_addon: this.state.always_show_addon,
      promo: this.state.promo,
      addon: this.state.addon,
      hidden: this.state.hidden,
      title: this.state.title,
      description: this.state.description,
      price: this.state.price,
      service_id: this.props.service.id,
      contractor_payout: this.state.contractor_payout,
      bundle_description: this.state.bundle_description,
      reshoot: this.state.reshoot,
      features: this.state.features.filter(feature => !!feature)
    }
    this.props.createSubService(sub_service_info).then(() => {
      if (this.props.errors.length === 0) {
        this.setState({title: "", description: "", price: "", contractor_payout: "", hidden: false})
        this.props.showToast("Sub services have been added")
      }
    })
  }


  render() {

    let companyNameErrorClass = "";
    let agentMlsIdErrorClass = "";
    let companyAddressErrorClass = "";
    let companyCityErrorClass = "";
    let companyStateErrorClass = "";
    let companyZipErrorClass = "";


    let companyNameErrors = [];
    let agentMlsIdErrors = [];
    let companyAddressErrors = [];
    let companyCityErrors = [];
    let companyStateErrors = [];
    let companyZipErrors = [];


    let subServices = this.props.service.subServices.map((sub_service, i) => <SubServiceItem key={i}
                                                                                             subService={sub_service}
                                                                                             updateSubService={this.props.updateSubService}
                                                                                             deleteSubService={this.props.deleteSubService}/>)

    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.props.service.title}'s Sub Services</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>
          <div className="settingsCategoryGroup1">
            {subServices}
            <div>
              <div className="createNewSubService">Create New Sub Service</div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Title </label>
                <input className="formInput" id={companyNameErrorClass} onChange={this.handleInput("title")}
                       type="string" placeholder="Sub Service Title" value={this.state.title}/>
                <ul className="errorUl">{companyNameErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Description </label>
                <textarea className="formTextArea" id={agentMlsIdErrorClass} onChange={this.handleInput("description")}
                          type="string" placeholder="Description" value={this.state.description}/>
                <ul className="errorUl">{agentMlsIdErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel">Bundle Description</label>
                <textarea className="formTextArea" id={companyAddressErrorClass}
                          onChange={this.handleInput("bundle_description")} type="string"
                          placeholder="Bundle Description" value={this.state.bundle_description}/>
                <ul className="errorUl">{companyAddressErrors}</ul>
              </div>

              <Features features={this.state.features} onChange={features => this.setState({features})}/>

              <div className="settingsServicesInput">
                <label id="formLabel"> Price </label>
                <input className="formInput" id={companyAddressErrorClass} onChange={this.handleInput("price")}
                       type="number" placeholder="Price" value={this.state.price}/>
                <ul className="errorUl">{companyAddressErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Contractor Payout </label>
                <input className="formInput" id={companyAddressErrorClass}
                       onChange={this.handleInput("contractor_payout")} type="number" placeholder="Payout"
                       value={this.state.contractor_payout}/>
                <ul className="errorUl">{companyAddressErrors}</ul>
              </div>
              <div className="settingsCheckboxGroup">
                <label><input type="checkbox" className="formCheckBox" checked={this.state.hidden}
                              onChange={this.handleCheckbox("hidden")}/> <span
                  className="rememberMe">Hidden?</span></label>
                <label><input type="checkbox" className="formCheckBox" checked={this.state.reshoot}
                              onChange={this.handleCheckbox("reshoot")}/> <span
                  className="rememberMe">Reshoot?</span></label>
                <label><input type="checkbox" className="formCheckBox" checked={this.state.addon}
                              onChange={this.handleCheckbox("addon")}/> <span
                  className="rememberMe">Addon?</span></label>
                <label><input type="checkbox" className="formCheckBox" checked={this.state.always_show_addon}
                              onChange={this.handleCheckbox("always_show_addon")}/> <span className="rememberMe">Always Show Addon?</span></label>
                <label><input type="checkbox" className="formCheckBox" checked={this.state.promo}
                  onChange={this.handleCheckbox("promo")} /> <span className="rememberMe">Promo?</span></label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button onClick={this.createSubService} className="settingsButton subServicesButton">Create Sub Service
            </button>
          </div>
        </div>
      </div>
    );
  }
}