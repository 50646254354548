import React from "react";

class AddMorePhotosModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: []
    }
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    return (
      <div className="modal-background" onClick={() => this.props.changeParentState({ page: 5 })}>
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="settingsComponentHeading orderPreviewModalHeading"> Great work! </div>
            <div className="continueMessage">Please feel free to stage more photos or continue to the order summary.</div>
            <div className="modalButtonsAddService">
              <div className="signupFormNext modalButtonSpecial" onClick={this.props.onAddMoreServices}>Stage More Photos</div>
              <div className="signupFormNext modalButtonSpecial" onClick={this.props.handleNext}>Order Summary</div>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default AddMorePhotosModal;