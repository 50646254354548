import React from 'react'
import completedImg from "../../../../../assets/images/photographerOnboarding/completed.svg"
import {LINKS} from "../../../../../assets/images/contactForm";

export const Completed = () => {
  const onClick = (e) => {
    e.preventDefault()
    location.href = LINKS.INSTAGRAM
  }

  return <div>
    <div className="schedule-demo-title">
      Nice work! We’re looking forward to speaking with you.
    </div>

    <div className="schedule-demo-center-image-wrapper">
      <img src={completedImg}/>
    </div>

    <div className="schedule-demo-paragraph">
      Thank you for providing this information. We will contact you shortly to confirm the consultation details.
    </div>

    <div className="schedule-demo-divider"/>
    <div className="mar-top-20">
      <button className="projectEditButton projectEditButtonPrimary projectEditButtonLarger w-100" onClick={onClick}>
        Explore Our Instagram
      </button>
    </div>
  </div>
}