import { connect } from "react-redux";
import SettingsContractorInfo from "./settings_contractor_info";
import React from "react";
import { withRouter } from "react-router-dom";
import { showToast } from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsContractorInfo));