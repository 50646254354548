import React from "react";

export default class ViewTermsOfService extends React.Component {
  render() {
    const {terms} = this.props

    return (
      <div className="termsOfServiceModalComponent">
        <div className="termsOfServiceModal">
          {terms && (
            <>
              {/* <small className="text-muted float-right">{terms.version}</small> */}

              <div className="termsOfServiceContent clearfix editorContent">
                <div dangerouslySetInnerHTML={{__html: terms.content}}/>
              </div>
            </>
          )}

          <div className="text-center mt-3">
            <button onClick={() => this.props.closeModal()} className="settingsButton companyButton closeButton m-0">Close</button>
          </div>
        </div>
      </div>
    );
  }
}