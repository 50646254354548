import styled from 'styled-components';

import { LANDING_PAGE, NAV_IMAGES } from '../../../../../assets/images/landing';

export const NavBarContainer = styled.div`
  padding: 20px 24px;
  position: relative;
  .showNavMenu {
    left: 0%;
  }
  .showDropDown {
    height: 310px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const TopRightWave = styled.div`
  content: '';
  height: 400%;
  width: 100%;
  position: absolute;
  top: -12%;
  right: -50%;
  background-image: url(${LANDING_PAGE.TopRightWave});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
  @media screen and (max-width: 550px) {
    height: 250%;
    width: 60%;
    top: -1%;
    right: -13%;
  }
`

export const UpperNavRow = styled.div`
  flex-direction: row;
  display: flex;
  flex: 2;
`;

export const NavRow = styled.div`
  flex-direction: row;
  display: flex;
  flex: 2;
  &::before {
    content: '';
    height: 60%;
    width: 100%;
    position: absolute;
    top: 0%;
    right: -62%;
    background-image: url(${NAV_IMAGES.MOBILE_LOGOOUT_BG});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
    @media screen and (max-width: 799px) {
      height: 50%;
      width: 40%;
      top: -1%;
      right: -1%;
    }
    @media screen and (max-width: 499px) {
      height: 55%;
      width: 60%;
      top: -2%;
      right: -5%;
    }
  }
`;

export const NavColFirst = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
`;

export const NavColSecond = styled.div`
  z-index: 1;
  flex: 1;
  flex-direction: row;
  display: flex !important;
  justify-content: flex-end !important;
  align-content: center;
  align-items: center;
`;

export const NavBarImage = styled.img``;

export const NavbarMenuImageWrapper = styled.div`
  flex-direction: row;
  width: 50px;
  height: 44px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const NavMenuImage = styled.img``;

export const NavMenu = styled.div`
  background: #1b264f;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y:scroll;
  overflow-x:hidden;
  width: 100%;
  height: 100vh;
  text-align: center;
  transition: 0.2s ease;
  z-index: 9999;
  left: 100%;
  padding: 20px 24px;
`;

export const NavbarMenuCloseImageWrapper = styled.div`
  flex-direction: row;
  width: 50px;
  height: 44px;
  background: #1b264f;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const MenuOptions = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  .navbar-nav {
    .nav-item {
      padding-top: 8px;
      padding-bottom: 8px;
      .rightArrowWrapper {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        .arrowIcon {
          transform: rotate(180deg);
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }
      .nav-link {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 46px;
        display: inline-block;
        align-items: center;
        text-align: center;
        color: #ffffff;
        &:active {
          color: #ffffff !important;
        }
      }
    }
  }
  &.serviceOpen .navbar-nav .nav-link {
    color: #9BAEF1;
  }
  &.serviceOpen .navbar-nav .nav-link > img {
    filter: invert(70%) sepia(4%) saturate(3990%) hue-rotate(192deg) brightness(101%) contrast(89%);
  }
`;

export const ArrowIcon = styled.img`
  width: 14px;
  margin-left: 10px;
  transform: rotate(0deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
`;

export const BottomRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

export const LoginButton = styled.button`
  width: 190px;
  height: 53px;
  left: 0px;
  top: 0px;
  background: #2C5BFF;
  border-radius: 8px;
  border: solid 1px #2C5BFF;
  margin: 10px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

export const SignUpButton = styled.button`
  position: static;
  width: 190px;
  height: 53px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-radius: 8px;
  border: solid 1px #ffffff;
  margin: 10px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #1b264f;
`;

export const ServicesOptions = styled.div`
  height: 0px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
`;

export const ServicesOptionsList = styled.ul`
  padding-left: 0px !important;
  list-style: none;
`;

export const ServicesOptionsListItem = styled.li`
  margin-bottom: 14px;
  a {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 40px !important;
    align-items: center !important;
    text-align: center !important;
    color: #ffffff !important;
  }
`;
