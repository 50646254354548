import styled from 'styled-components';

import { SECOND_SECTION_IMAGE, SMALL_CIRCLE } from '../../../../../assets/images/aboutPage';

export const SecondSectionWrapper = styled.div`
  margin-bottom: 140px;
  margin-left: calc((100vw - 988px)/2);
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1050px) {
    margin-left: calc((100vw - 752px)/2);
  }
  @media screen and (max-width: 769px) {
    margin-bottom: 0px;
  }
`;

export const SecondSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 769px) {
    margin-bottom: 100px;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Description = styled.div`
  margin-left: 114px;
  position: relative;
  width: 576px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media screen and (max-width: 769px) {
    width: 297px;
    margin: 52px 32px 56px;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
  }

  &::before {
    content: '';
    width: 185px;
    height: 216px;
    position: absolute;
    top: -55%;
    left: -7rem;
    background-image: url(${SMALL_CIRCLE});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
    @media screen and (max-width: 769px) {
      top: -80%;
      left: -8rem;
    }
  }
`;

export const DescriptionBold = styled.span`
  font-weight: 800;
`;

export const Image = styled.div`
  width: 463px;
  height: 683px;
  margin-left: 30px;
  border-radius: 12px 0px 0px 12px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${SECOND_SECTION_IMAGE});
  @media screen and (max-width: 769px) {
    width: 246px;
    height: 362px;
    margin-right: 21px;
    border-radius: 6px;
  }
`;
