import React, {useLayoutEffect, useState} from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  ContactFormContainer,
  ContactFormContent,
  ContactFormDivider,
  ContactFormFields,
  ContactFormFindHereHeading,
  ContactFormInfo,
  ContactFormInnerContent,
  ContactFormRow,
  ContactFormSocialBlock,
  ContactFormSocialBlockRow,
  ContactFormSocialItem,
  ContactFormSocialItemIcon,
  ContactFormSocialItemLabel,
  ContactFormSubmitButton,
  FormInfoRow,
  FormInfoRowImage,
  FormInfoRowValue,
} from './styles';
import FloatingInput from '../FloatingInput';
import FloatingTextarea from '../FloatingTextarea';
import {CONTACT_FORM, LINKS} from '../../../../../assets/images/contactForm';
import {sendContactsMessage} from "../../../util/contacts_api_utils";
import { subscribeToList } from '../../../util/ad_api_utils';
import { showToast } from "../../../actions/toast_actions";

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const ContactForm = (props) => {
  const width = useWindowSize();
  const [formData, setFormData] = useState({})
  const [sent, setSent] = useState(false)

  const onFieldUpdate = (field) => {
    return (e) => {
      e.preventDefault()
      const value = e.target.value
      setFormData(formData => ({...formData, [field]: value}))
    }
  }

  const submitForm = (e) => {
    e.preventDefault()

    window.gtag_report_conversion('https://www.modernangles.com/contact');

    sendContactsMessage(formData).then(() => {
      props.showToast("Your message has been sent")
      setSent(true)
      setFormData({})
    })
    subscribeToList(formData.email, "11857f10-8231-42f8-858c-8259753c0c68")
  }

  const showInfo = (icon, value) => {
    return (
      <FormInfoRow>
        <FormInfoRowImage src={icon}/>
        <FormInfoRowValue>{value}</FormInfoRowValue>
      </FormInfoRow>
    );
  };

  const showSocialItem = (icon, value, href) => {
    return (
      <ContactFormSocialItem href={href} target="_blank">
        <ContactFormSocialItemIcon src={icon}/>
        <ContactFormSocialItemLabel>{value}</ContactFormSocialItemLabel>
      </ContactFormSocialItem>
    );
  };

  return (
    <ContactFormContainer onSubmit={submitForm}>
      <ContactFormContent>
        More agents trust Modern Angles to deliver outstanding images than any other full-service photography agency. From the moment you reach out, our customer service teams work to make sure you are provided with the best quality experience, and work within your brand guidelines to bring each unique property story to life.
      </ContactFormContent>
      <ContactFormRow>
        <ContactFormFields>
          <FloatingInput label='First Name' type='text' onChange={onFieldUpdate('first_name')}
                         value={formData.first_name || ''} required/>
          <FloatingInput label='Last Name' type='text' onChange={onFieldUpdate('last_name')}
                         value={formData.last_name || ''} required/>
          <FloatingInput label='Email Address' type='email' onChange={onFieldUpdate('email')}
                         value={formData.email || ''} required/>
          <FloatingInput label='Phone Number' type='text' onChange={onFieldUpdate('phone')}
            value={formData.phone || ''} />
          <FloatingTextarea label='Message' onChange={onFieldUpdate('message')}
                            value={formData.message || ''} required/>
          <ContactFormSubmitButton type='submit'>Send</ContactFormSubmitButton>
          {sent && <div>Your message has been sent</div>}
        </ContactFormFields>
        <ContactFormInfo>
          <ContactFormInnerContent>
            {width < 768 && <ContactFormDivider/>}
            {showInfo(CONTACT_FORM.PhoneIcon, '(855) 502-7772')}
            {showInfo(CONTACT_FORM.EmailIcon, 'info@modernangles.com')}
            {width > 767 && <ContactFormDivider/>}
            <ContactFormFindHereHeading>
              You can find us here
            </ContactFormFindHereHeading>
            <ContactFormSocialBlock>
              <ContactFormSocialBlockRow>
                {showSocialItem(CONTACT_FORM.FacebookIcon, 'Facebook', LINKS.FACEBOOK)}
                {showSocialItem(CONTACT_FORM.VimeoIcon, 'Vimeo', LINKS.VIMEO)}
              </ContactFormSocialBlockRow>
              <ContactFormSocialBlockRow>
                {showSocialItem(CONTACT_FORM.InstagramIcon, 'Instagram', LINKS.INSTAGRAM)}
                {showSocialItem(CONTACT_FORM.LinkedInIcon, 'LinkedIn', LINKS.LINKEDIN)}
              </ContactFormSocialBlockRow>
            </ContactFormSocialBlock>
          </ContactFormInnerContent>
        </ContactFormInfo>
      </ContactFormRow>
    </ContactFormContainer>
  );
};

const msp = (state) => ({})

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});


export default withRouter(connect(msp, mdp)(ContactForm));
