import { connect } from "react-redux"
import AddServiceModal from "./add_service_modal";
import React from "react"
import { withRouter } from "react-router-dom"
import { updatePropertyTour } from "../../actions/project_actions";
import { fetchContractors } from "../../actions/service_actions";
import { fetchCurrentUser } from "../../actions/session_actions";
import { showToast } from "../../actions/toast_actions";

const msp = (state) => (
  {
    currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
    contractors: state.entities.contractors
  }
)

const mdp = (dispatch) => ({
  fetchContractors: (service_id, zipcode, search, date, time, services, appointments, timezone) => dispatch(fetchContractors(service_id, zipcode, search, date, time, services, appointments, timezone)),
  fetchCurrentUser: (user_id) => dispatch(fetchCurrentUser(user_id)),
  showToast: (message) => dispatch(showToast(message))
})

export default withRouter(connect(msp, mdp)(AddServiceModal))