import { connect } from "react-redux";
import DownloadModal from "./download_modal";
import React from "react";
import { withRouter } from "react-router-dom";
// import { downloadPhotos } from "../../actions/project_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  // downloadPhotos: (downloadInfo) => dispatch(downloadPhotos(downloadInfo)),
});

export default withRouter(connect(msp, mdp)(DownloadModal));