import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  ServiceDropdownWrapper,
  ServiceDropdownContainer,
  ServiceDropdownSelectWrapper,
  ServiceDropdownSelectedWrapper,
  ServiceDropdownSelected,
  ServiceDropdownIcon,
  ServiceOptionsContainer,
  ServiceContentExtraPadding,
  ServiceContent,
  ServiceBackDrop,
} from './styles';
import { SERVICE_DROPDOWN } from '../../../../../assets/images/servicesPages';

const OPTIONS_HASH = {
  '/virtual-staging': 'Virtual Staging',
  '/photography': 'Photography',
  '/drone': 'Drone',
  '/rendering': 'Rendering',
  '/interactive-tours': 'Interactive Tours',
  '/video': 'Video',
  '/floor-plans': 'Floor Plans',
};

const OPTIONS_HASH_SELECTED = {
  '/virtual-staging': 'Virtual Staging for Real Estate',
  '/photography': 'Real Estate Photography',
  '/rendering': 'Real Estate 3D Renderings',
  '/interactive-tours': '3D Real Estate Tour',
  '/video': 'Real Estate Video',
  '/drone': 'Real Estate Drone Photography',
  '/floor-plans': 'Real Estate Floor Plans',
};

const ServiceDropdown = (props) => {
  const { pathName, content, contentLink, isExtraPadding } = props;

  const [isServiceDropDownOpen, setServiceDropDownOpen] = useState(false);

  const updateDropDown = () => {
    setServiceDropDownOpen(!isServiceDropDownOpen);
  };

  return (
    <ServiceDropdownWrapper>
      <Container>
        <ServiceDropdownContainer>
          <ServiceDropdownSelectWrapper onClick={updateDropDown}>
            <ServiceDropdownSelectedWrapper>
              <ServiceDropdownSelected>
                {OPTIONS_HASH_SELECTED[pathName]}
              </ServiceDropdownSelected>
              <ServiceDropdownIcon
                src={SERVICE_DROPDOWN.ServiceDropDownArrow}
                className={`${isServiceDropDownOpen ? 'arrowIcon' : ''}`}
              />
            </ServiceDropdownSelectedWrapper>
            <ServiceOptionsContainer
              className={`${
                isServiceDropDownOpen ? 'showOptionsContainer' : ''
              }`}
            >
              {Object.keys(OPTIONS_HASH).map((key, value) => {
                if (pathName !== key) {
                  return (
                    <Link key={value} to={key}>
                      {OPTIONS_HASH[key]}
                    </Link>
                  );
                }
              })}
            </ServiceOptionsContainer>
          </ServiceDropdownSelectWrapper>
          {isExtraPadding ? (
            <ServiceContentExtraPadding>
              {content} {contentLink && <Link to={'#'}>{contentLink}</Link>}
            </ServiceContentExtraPadding>
          ) : (
            <ServiceContent>{content}</ServiceContent>
          )}
        </ServiceDropdownContainer>
      </Container>
      {isServiceDropDownOpen && <ServiceBackDrop />}
    </ServiceDropdownWrapper>
  );
};

export default ServiceDropdown;
