import {connect} from "react-redux"
import React from "react"
import {withRouter} from "react-router-dom"
import Contact from "./contacts"

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
})

const mdp = () => ({})

export default withRouter(connect(msp, mdp)(Contact))