import React, {useState} from "react";
import {FAQ_SECTION} from "../../../../../assets/images/careersPage";
import {
  CareersFaqSectionInnerWrapper,
  CareersFaqSectionWrapper,
  DescriptionText,
  HeaderImage,
  Heading,
  Question, QuestionArrow,
  QuestionBody, QuestionBodyInternal,
  Questions,
  QuestionTitle
} from "./styles";

const questions = [
  {
    id: 1,
    title: 'What are the equipment requirements?',
    body: <div>Photography: <ul><li>Full-frame camera body or crop sensor</li><li>Wide angle lens focal length of 14-35mm</li><li>Tripod with geared head</li><li>External Flash/Speedlight</li></ul>Video: <ul><li>Full frame camera body 60fps</li><li>Drone equivalent to DJI</li><li>Mechanical Gimbal</li></ul>Floor Plans: <ul><li>iPhone</li><li>iPad</li></ul> Drone: <ul><li>DJI Mavic or equivalent</li></ul>3D Tours: <ul><li>Matterport Pro</li><li>Ricoh Theta Z1</li></ul></div>
  },
  {
    id: 2,
    title: 'Who will be editing the media?',
    body: 'Modern Angles photographers capture the content and our in-house post production team takes care of the rest. You will upload the raw files to our server at the end of each work day.'
  },
  {
    id: 3,
    title: "How will I know when I'm booked for a job?",
    body: "After being successfully onboarded to the Modern Angles app, you'll be able to customize your schedule and receive incoming job request notifications for shoots in your local area."
  },
  {
    id: 4,
    title: 'Does Modern Angles offer training?',
    body: "Yes, we offer training for all new team members. This includes one-on-one sessions with experienced lead photographers, and in-depth guides teaching you the best practices for composing shots and adjusting camera settings to meet the shooting strategy requirements."
  }
]

const CareersFaqSection = () => {
  const [openedQuestion, setOpenedQuestion] = useState(questions[0])

  const onClick = (e, question) => {
    e.preventDefault()
    if (question === openedQuestion) {
      setOpenedQuestion(null)
    } else {
      setOpenedQuestion(question)
    }
  }

  return (
    <>
      <HeaderImage>
        <img src={FAQ_SECTION.FAQ}/>
      </HeaderImage>
      <CareersFaqSectionWrapper>
        <CareersFaqSectionInnerWrapper>
          <Heading>
            Frequently Asked Questions
          </Heading>

          <Questions>
            {questions.map(question => (
              <Question key={`faq-${question.id}`}>
                <QuestionTitle>
                  {question.title}
                  <QuestionArrow onClick={e => onClick(e, question)}>
                    <img src={question === openedQuestion ? FAQ_SECTION.ArrowUp : FAQ_SECTION.ArrowDown} />
                  </QuestionArrow>
                </QuestionTitle>
                <QuestionBody className={question === openedQuestion ? 'opened' : ''}>
                  <QuestionBodyInternal>
                    {question.body}
                  </QuestionBodyInternal>
                </QuestionBody>
              </Question>
            ))}
          </Questions>
        </CareersFaqSectionInnerWrapper>
      </CareersFaqSectionWrapper>
    </>
  );
};

export default CareersFaqSection;
