import React from "react";

import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import { createMilestone, updateMilestone } from "../../../util/settings_api_util";


export default class MilestoneFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(this.props.milestone || {
        name: '',
        project_amount: null,
        time_frame: "monthly",
        reward_amount: 0,
        loading: false,
        active: true
      }),
      errors: []
    }

    this.onSave = this.onSave.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckbox(name) {
    this.setState({ [name]: !this.state[name] })
  }

  onSave() {
    const { name, project_amount, reward_amount, time_frame, active } = this.state
    let data = { name: name, project_amount: project_amount, time_frame: time_frame, active: active, reward_amount: reward_amount }

    const save = this.state.id ? updateMilestone(this.state.id, data) : createMilestone(data)

    this.setState({ loading: true })

    save.then(milestone => {
      this.props.closeModal()
      this.props.onSave(milestone)
      this.setState({ loading: false })
    }).catch(response => {
      this.setState({ loading: false })
      this.setState({ errors: response.responseJSON || [] })
    })
  }

  render() {
    return (
      <div className="addFormModal">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">{this.state.id ? 'Update Milestone' : 'Create Milestone'}</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput form-group">
              <label id="formLabel">Name</label>
              <input className="formInput" onChange={this.onChange} name="name" value={this.state.name} />
            </div>
            <div className="settingsServicesInput form-group">
              <label id="formLabel">Project Amount</label>
              <input className="formInput" onChange={this.onChange} type="string" name="project_amount" value={this.state.project_amount} />
            </div>
            <div className="settingsServicesInput form-group">
              <label id="formLabel">Reward Amount</label>
              <input className="formInput" onChange={this.onChange} type="string" name="reward_amount" value={this.state.reward_amount} />
            </div>
            <div className="settingsServicesInput settingsDropdownInput">
              <label id="formLabel">Time Frame</label>
              <select className="formInput settingsDropdownFormInput" onChange={this.onChange} name="time_frame" value={this.state.time_frame} >
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <div className="settingsServicesInput form-group form-group-special">
              <label><input type="checkbox" className="formCheckBox" checked={this.state.active} onChange={() => this.handleCheckbox("active")} name="active" /> <span className="rememberMe">Active?</span></label>
            </div>
          </div>

          <button onClick={this.onSave} disabled={this.state.loading} className="settingsButton companyButton">
            Save
          </button>
        </div>
      </div>
    );
  }
}