import styled from 'styled-components';

import { VIDEO } from '../../../../../assets/images/servicesPages';

export const VideoSliderWrapper = styled.div`
  margin-top: 50px;
  background-color: #353844;
  height: 970px;

  .sliderFor .slick-track {
    width: 30000px !important;
  }

  .sliderNav .slick-track {
    width: 2000px !important;
  }

  .sliderFor .slick-list {
    padding: 0px !important;
  }

  .sliderFor .slick-slide {
    margin-top: 0px !important;
  }
  @media screen and (max-width: 1080px) {
    height: 840px;
  }
  @media screen and (max-width: 767px) {
    height: 720px;
  }
`;

export const SliderContainer = styled.div`
  width: 100vw;
`;

export const NavSliderContainer = styled.div`
  margin: 80px auto 0;
  width: 1062px;
  @media screen and (max-width: 1080px) {
    margin-top: 40px;
    width: 879px;
  }
  @media screen and (max-width: 1080px) {
    margin-top: 0px;
    .slick-list,
    .slick-track {
      height: 250px;
    }
  }
`;

export const PlayerWrapper = styled.div`
  width: 100vw;
  height: 620px;
  @media screen and (max-width: 1080px) {
    height: 520px;
  }
  @media screen and (max-width: 767px) {
    height: 450px;
  }
`;

// export const ControlsButtonWrapper = styled.div`
//   position: absolute;
//   top: 50%;
//   /* left: 50%; */
//   transform: translate(-50%, -50%) rotate(90deg);
//   filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.65));
// `;

// export const ControlsButton = styled.div`
//   &,
// 	&::before,
// 	&::after {
// 		width: 75px;
// 		height: 75px;
// 	}

//   position: relative;
// 	overflow: hidden;
//   border-radius: 10%;
// 	transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(0.866);
// 	pointer-events: none;
//   cursor: pointer;

//   &::before,
// 	&::after {
// 		content: '';
// 		position: absolute;
// 		background-color: #ffffff;
// 		pointer-events: auto;
// 	}

//   &::before {
//     border-radius: 10% 10% 10% 55%;
// 		transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
// 	}
//   &::after {
//     border-radius: 10% 10% 55% 10%;
// 		transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
// 	}
// `;

export const SliderNavItemWrapper = styled.div`
  width: 235px;
  height: 204px;
  margin-right: 40px;
  &::nth-last-child {
    margin-right: 0px;
  }
  @media screen and (max-width: 1080px) {
    margin-right: 20px;
    width: 200px;
    height: 228px;
  }
`;

export const NavImage = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 8px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const NavDescriptionWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

export const NavDescription = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #ffffff;
  @media screen and (max-width: 1080px) {
    font-size: 14px;
  }
`;

export const NavDescriptionIcon = styled.div`
  margin-left: 12px;
  width: 12px;
  height: 12px;
  background-image: url(${VIDEO.PlayButton});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media screen and (max-width: 1080px) {
    margin-left: 6px;
  }
`;