import React from "react"
import debounce from 'debounce'
import { fetchProject, updateProject } from "../../util/project_api_util"
import PageHeader from "../page_header"
import DownloadModal from "./download_modal_container"
import { withRouter } from "react-router-dom"
import EditProjectMedia from "./edit_project_media"
import NavbarContainer from "../navbar/navbar_container"
import FooterSection from "../pageComponents/FooterSection"

class MediaShare extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      project: null,
      modal: null,
      subModal: null,
      isLargeScreen: true,
      contractorSearch: "",
      selected_service: "",
      zip_code: "",
    }

    this.handler = this.handler.bind(this)
    this.reloadProject = this.reloadProject.bind(this)
    this.openDownloadModal = this.openDownloadModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.updatePredicate = this.updatePredicate.bind(this)
  }

  componentDidMount() {
    this.reloadProject().then(project => {
      if (!project || this.props.match.params.shareCode != project.download_key){
        this.props.history.push("/")
      }
    })
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  openDownloadModal() {
    // e.preventDefault()
    const modal = <DownloadModal project={this.state.project} onClose={this.closeModal} />
    this.setState({ modal })
  }

  closeModal() {
    this.setState({ modal: null })
  }

  reloadProject(serviceId = null) {
    const projectId = this.props.match.params.id
    const media_code = this.props.match.params?.shareCode
    return fetchProject(projectId, serviceId, media_code).then((response) => {
      const project = Object.values(response)[0]
      this.setState({ project })
      return project
    })
  }

  handler(newState) {
    this.setState(newState)
  }

  render() {
    const { project, modal } = this.state
    const { currentUser } = this.props

    const projectAddress = [
      project?.address?.street1, project?.address?.city, project?.address?.region, project?.address?.zip_code
    ].filter(v => !!v).join(', ')

    return (
    <div className={currentUser ? "" : "mediaShareNoUserView"}>
      <NavbarContainer />
      <div className={currentUser ? "dashboardMainDiv" : "mediaShareMainDiv"}>
          <PageHeader loggedOut={!currentUser} title={project?.address?.formatted_address ? `${project?.address?.apartment_number || ""} ${project?.address?.formatted_address}` : projectAddress} backLink={"/"} />
        <div className="editProjectSection editProjectPhotosSection mb-5">
          <EditProjectMedia isLargeScreen={this.state.isLargeScreen}
            project={project}
            reloadProject={this.reloadProject}
            currentUser={currentUser}
            openDownloadModal={this.openDownloadModal} />
        </div>
      </div>
        {currentUser ? "" : <FooterSection displayLoginButtons={!this.props.currentUser} displayOrderNow={false} />}
    </div>
    )
  }
}

export default withRouter(MediaShare)