import React, { useState } from 'react';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Dropdown,
} from 'reactstrap';
import { useInView } from 'react-intersection-observer';

import FadeInAnimation from '../home_page/homeComponents/FadeInAnimation';
import { NAV_IMAGES } from '../../../../assets/images/landing';

import {
  NavBarContainer,
  NavBarContainer2,
  ServicesDropDownIcon,
  LoginButton,
  SignUpButton,
  DashboardButton,
  NavMenuImage,
  NavBarImage,
} from './navbarStyle';
import { ROUTES } from '../../routes';

const NavbarLoggedOut = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true
  });
  const [isServiceDropDownOpen, setServiceDropDownOpen] = useState(false);
  const [menuLast, setMenuLast] = useState(false);
  const history = useHistory()

  if (props.nonAppPage){
  return (
    <div ref={ref}>
      <FadeInAnimation
        duration={0.4}
        yOffset={-20}
        inView={inView}
      >
        <NavBarContainer disableWaveBackground={props.disableWaveBackground}>
          <Container>
            <Navbar color='transparent' light expand='md' id={props.removeNavbar ? "navbarNoOptions" : ""}>
              <NavbarBrand href={ROUTES.HOME}>
                <NavBarImage src={NAV_IMAGES.WebLogo} alt='' />
              </NavbarBrand>
              {props.removeNavbar ? <Collapse className="collapseWithoutFlex" navbar><NavbarText>
                <Dropdown
                  onMouseOver={() => setMenuLast(true)}
                  onMouseLeave={() => setMenuLast(false)}
                  toggle={() => setMenuLast(!menuLast)}
                  isOpen={menuLast}
                  setActiveFromChild={true}
                >
                  <DropdownToggle nav>
                    <NavMenuImage
                      src={
                        menuLast
                          ? NAV_IMAGES.CloseHeaderMenu
                          : NAV_IMAGES.HeaderMenu
                      }
                      alt=''
                    />
                  </DropdownToggle>
                  <DropdownMenu right className={'navMenuDropDown'}>
                    <DropdownItem tag={Link} to={ROUTES.CONTACTS}>
                      Support
                    </DropdownItem>
                    <DropdownItem tag={Link} to={ROUTES.FAQ}>
                      FAQ
                    </DropdownItem>
                    {/* <DropdownItem tag={Link} to={ROUTES.LOCATIONS}>
                        Our service areas
                      </DropdownItem> */}
                    {/* <DropdownItem tag={Link} to={'#'}>
                        Careers
                      </DropdownItem>
                      <DropdownItem tag={Link} to={'#'}>
                        Talent Partnerships
                      </DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
              </NavbarText></Collapse> : <Collapse navbar>
                <Nav className='mr-auto' id={props.navbarHideMiddleButtons ? "hiddenMiddleButtons" : ""}navbar>
                  <Dropdown
                    nav
                    inNavbar
                    onMouseOver={() => setServiceDropDownOpen(true)}
                    onMouseLeave={() => setServiceDropDownOpen(false)}
                    toggle={() => setServiceDropDownOpen(!isServiceDropDownOpen)}
                    isOpen={isServiceDropDownOpen}
                    setActiveFromChild={true}
                  >
                    <DropdownToggle nav>
                      Services
                      <ServicesDropDownIcon
                        src={
                          isServiceDropDownOpen
                            ? NAV_IMAGES.ServiceDropdownUp
                            : NAV_IMAGES.ServiceDropdownDown
                        }
                        alt=''
                      />
                    </DropdownToggle>
                    <DropdownMenu right className={'servicesDropDown'}>
                      <DropdownItem tag={Link} to={ROUTES.PHOTOGRAPHY}>
                        Photography
                      </DropdownItem>
                      <DropdownItem tag={Link} to={ROUTES.VIRTUAL_STAGING}>
                        Virtual Staging
                      </DropdownItem>
                      <DropdownItem tag={Link} to={ROUTES.RENDERING}>
                        Rendering
                      </DropdownItem>
                      <DropdownItem tag={Link} to={ROUTES.DRONE}>
                        Drone Photography
                      </DropdownItem>
                      <DropdownItem tag={Link} to={ROUTES.INTERACTIVE_TOURS}>
                        Interactive Tours
                      </DropdownItem>
                      <DropdownItem tag={Link} to={ROUTES.VIDEO}>
                        Videos
                      </DropdownItem>
                      <DropdownItem tag={Link} to={ROUTES.FLOOR_PLANS}>
                        Floor Plans
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {!props.navbarHideMiddleButtons &&
                    <NavItem>
                      <NavLink tag={Link} to={ROUTES.ABOUT}>About Us</NavLink>
                    </NavItem>}
                  {!props.navbarHideMiddleButtons &&
                  <NavItem>
                    <NavLink tag={Link} to={ROUTES.BLOG_INDEX}>Blog</NavLink>
                  </NavItem>}
                  {!props.navbarHideMiddleButtons &&
                  <NavItem>
                    <NavLink tag={Link} to={ROUTES.LOCATIONS}>Coverage</NavLink>
                  </NavItem>}
                  {!props.navbarHideMiddleButtons &&
                  <NavItem>
                    <NavLink tag={Link} to={ROUTES.CAREERS}>Careers</NavLink>
                  </NavItem>}
                  {!props.navbarHideMiddleButtons &&
                  <NavItem>
                    <NavLink tag={Link} to={ROUTES.CONTACTS}>Contact</NavLink>
                    {/* <NavLink href={ROUTES.CONTACTS}>Contact</NavLink> */}
                  </NavItem>}
                  {props.navbarHideMiddleButtons ? "" : props.currentUser ? <NavItem>
                    <Link to={ROUTES.DASHBOARD}>
                      <DashboardButton>Dashboard</DashboardButton>
                    </Link>
                  </NavItem> : <NavItem>
                    <Link to={ROUTES.LOGIN} className="loggedInNAv">
                      <LoginButton>Log In</LoginButton>
                    </Link>
                  </NavItem>
                  }
                    {props.currentUser ? "" : <NavItem className="high-z-index">
                    <div
                       onClick={() => props.setModal(true)}>
                      <SignUpButton>Schedule Shoot</SignUpButton>
                    </div>
                  </NavItem>
                 }
                </Nav>
                <NavbarText>
                  <Dropdown
                    onMouseOver={() => setMenuLast(true)}
                    onMouseLeave={() => setMenuLast(false)}
                    toggle={() => setMenuLast(!menuLast)}
                    isOpen={menuLast}
                    setActiveFromChild={true}
                  >
                    <DropdownToggle nav>
                      <NavMenuImage
                        src={
                          menuLast
                            ? NAV_IMAGES.CloseHeaderMenu
                            : NAV_IMAGES.HeaderMenu
                        }
                        alt=''
                      />
                    </DropdownToggle>
                    <DropdownMenu right className={'navMenuDropDown'}>
                      <DropdownItem tag={Link} to={ROUTES.CONTACTS}>
                        Support
                      </DropdownItem>
                      <DropdownItem tag={Link} to={ROUTES.FAQ}>
                        FAQ
                      </DropdownItem>
                      {/* <DropdownItem tag={Link} to={ROUTES.LOCATIONS}>
                        Our service areas
                      </DropdownItem> */}
                      {/* <DropdownItem tag={Link} to={'#'}>
                        Careers
                      </DropdownItem>
                      <DropdownItem tag={Link} to={'#'}>
                        Talent Partnerships
                      </DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </NavbarText>
              </Collapse>
             }
            </Navbar>
          </Container>
        </NavBarContainer>
      </FadeInAnimation>
    </div>
  );
   } else{
    return (
      <div ref={ref}>
        <FadeInAnimation
          duration={0.4}
          yOffset={-20}
          inView={inView}
        >
          <NavBarContainer2>
            <Container>
              <Navbar color='transparent' light expand='md'>
                <NavbarBrand href={ROUTES.HOME}>
                  <NavBarImage src={NAV_IMAGES.WebLogo} alt='' />
                </NavbarBrand>
                <Collapse navbar>
                  <Nav className='mr-auto' navbar>
                    <Dropdown
                      nav
                      inNavbar
                      onMouseOver={() => setServiceDropDownOpen(true)}
                      onMouseLeave={() => setServiceDropDownOpen(false)}
                      toggle={() => setServiceDropDownOpen(!isServiceDropDownOpen)}
                      isOpen={isServiceDropDownOpen}
                      setActiveFromChild={true}
                    >
                      <DropdownToggle nav>
                        Services
                        <ServicesDropDownIcon
                          src={
                            isServiceDropDownOpen
                              ? NAV_IMAGES.ServiceDropdownUp
                              : NAV_IMAGES.ServiceDropdownDown
                          }
                          alt=''
                        />
                      </DropdownToggle>
                      <DropdownMenu right className={'servicesDropDown'}>
                        <DropdownItem tag={Link} to={ROUTES.PHOTOGRAPHY}>
                          Photography
                        </DropdownItem>
                        <DropdownItem tag={Link} to={ROUTES.VIRTUAL_STAGING}>
                          Virtual Staging
                        </DropdownItem>
                        <DropdownItem tag={Link} to={ROUTES.RENDERING}>
                          Rendering
                        </DropdownItem>
                        <DropdownItem tag={Link} to={ROUTES.DRONE_PHOTOGRAPHY}>
                          Drone Photography
                        </DropdownItem>
                        <DropdownItem tag={Link} to={ROUTES.INTERACTIVE_TOURS}>
                          Interactive Tours
                        </DropdownItem>
                        <DropdownItem tag={Link} to={ROUTES.VIDEO}>
                          Videos
                        </DropdownItem>
                        <DropdownItem tag={Link} to={ROUTES.FLOOR_PLANS}>
                          Floor Plans
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                      <NavLink tag={Link} to={ROUTES.ABOUT}>About Us</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} to={ROUTES.BLOG_INDEX}>Blog</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} to={ROUTES.CAREERS}>Careers</NavLink>
                    </NavItem>
                    {/* <NavItem>
                    <NavLink href='#'>Case Studies</NavLink>
                  </NavItem> */}
                    <NavItem>
                      <NavLink tag={Link} to={ROUTES.CONTACTS}>Contact</NavLink>
                      {/* <NavLink href={ROUTES.CONTACTS}>Contact</NavLink> */}
                    </NavItem>
                    {props.currentUser ? <NavItem>
                      <Link to={ROUTES.DASHBOARD}>
                        <DashboardButton>Dashboard</DashboardButton>
                      </Link>
                    </NavItem> : <NavItem>
                      <Link to={ROUTES.LOGIN} className="loggedInNAv">
                        <LoginButton>Log In</LoginButton>
                      </Link>
                    </NavItem>
                    }
                    {props.currentUser ? "" : <NavItem className="high-z-index">
                      <div
                        onClick={() => props.setModal(true)}>
                        <SignUpButton>Schedule Shoot</SignUpButton>
                      </div>
                    </NavItem>
                    }
                  </Nav>
                  <NavbarText>
                    <Dropdown
                      onMouseOver={() => setMenuLast(true)}
                      onMouseLeave={() => setMenuLast(false)}
                      toggle={() => setMenuLast(!menuLast)}
                      isOpen={menuLast}
                      setActiveFromChild={true}
                    >
                      <DropdownToggle nav>
                        <NavMenuImage
                          src={
                            menuLast
                              ? NAV_IMAGES.CloseHeaderMenu
                              : NAV_IMAGES.HeaderMenu
                          }
                          alt=''
                        />
                      </DropdownToggle>
                      <DropdownMenu right className={'navMenuDropDown'}>
                        <DropdownItem tag={Link} to={ROUTES.CONTACTS}>
                          Support
                        </DropdownItem>
                        <DropdownItem tag={Link} to={ROUTES.FAQ}>
                          FAQ
                        </DropdownItem>
                        {/* <DropdownItem tag={Link} to={'#'}>
                        Careers
                      </DropdownItem>
                      <DropdownItem tag={Link} to={'#'}>
                        Talent Partnerships
                      </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </NavbarText>
                </Collapse>
              </Navbar>
            </Container>
          </NavBarContainer2>
        </FadeInAnimation>
      </div>
    );
    }
};

export default withRouter(NavbarLoggedOut);
