import React from 'react'

export const Footer = (props) => {
  const continueButtonText = props.continueButtonText || "Next"
  const nextDisabled = props.nextDisabled || false
  const showBack = props.showBack ?? true

  const onClickBack = (e) => {
    e.preventDefault()
    props.onBack()
  }

  return <>
    <div className="schedule-demo-divider"/>
    <div className="schedule-demo-footer-buttons">
      {showBack ? <button onClick={onClickBack} className="projectEditButton projectEditButtonLight projectEditButtonLarger"
                          type="button">
        Back
      </button> : <div/>}

      <button className="projectEditButton projectEditButtonPrimary projectEditButtonLarger" type="submit"
              disabled={nextDisabled}>
        {continueButtonText}
      </button>
    </div>
  </>
}