import {Link, withRouter} from "react-router-dom";
import {ROUTES} from "../../../routes";
import React from "react";
import {ApplyButton, ButtonWrapper, LocationIconText, LocationsIconsWrapper, LocationsIconWrapper} from "./styles";
import MapChart from "../../about_page_components/MapChart";
import locations1 from "../../../../../assets/images/locations/locations-1.svg"
import locations2 from "../../../../../assets/images/locations/locations-2.svg"
import locations3 from "../../../../../assets/images/locations/locations-3.svg"
import { connect } from "react-redux";

const MapSection = (props) => {

  const onScheduleShoot = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  return <>
    <ButtonWrapper>
      <div onClick={onScheduleShoot}>
        <ApplyButton>Get Started</ApplyButton>
      </div>
      <LocationsIconsWrapper>
        <LocationsIconWrapper>
          <img src={locations1}/>
          <LocationIconText>Active Locations</LocationIconText>
        </LocationsIconWrapper>
        {/* <LocationsIconWrapper>
          <img src={locations2}/>
          <LocationIconText>A Category</LocationIconText>
        </LocationsIconWrapper>
        <LocationsIconWrapper>
          <img src={locations3}/>
          <LocationIconText>A Category</LocationIconText>
        </LocationsIconWrapper> */}
      </LocationsIconsWrapper>
    </ButtonWrapper>
    <MapChart/>
  </>
}

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(MapSection));