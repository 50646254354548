import {connect} from "react-redux";
import {ReactComponent as ChevronDown} from '../../../../assets/images/icons/chevron-down.svg'
import {ReactComponent as ChevronUp} from '../../../../assets/images/icons/chevron-up.svg'
import React, {useEffect, useMemo, useState} from "react";
import {MediaUploadModalProjectRow} from "./media_upload_modal_row";
import {abortMediaUploading, clearMediaUploading} from "../../actions/media_upload_actions";
import {Collapse} from "reactstrap";
import {showToast} from "../../actions/toast_actions";
import {SUCCEEDED} from "../../util/media_uploader_util";

const MediaUploadModal = ({mediaUploads, abortFilesUploading, clearFilesUploading, showToast}) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (mediaUploads.length > 0 && mediaUploads.every(u => u.status === SUCCEEDED)) {
      clearFilesUploading()

      if (show) {
        showToast('All files have been uploaded successfully')
      }
    }
  }, [mediaUploads])

  const projectsById = useMemo(() => {
    return mediaUploads.reduce((res, u) => {
      res[u.project.id] = u.project
      return res
    }, {})
  }, [mediaUploads])

  const groupBy = (items, keySelector) => {
    return items.reduce((accumulator, item) => {
      const key = keySelector(item);
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(item);
      return accumulator;
    }, {});
  };

  const mediaUploadsByProject = useMemo(() => {
    return groupBy(mediaUploads, item => item.project.id);
  }, [mediaUploads])

  const total = mediaUploads.reduce((res, u) => res + u.filesCount, 0)
  const uploadedTotal = mediaUploads.flatMap(u => u.uploadedFilesCount).reduce((res, c) => res + c, 0)

  const toggleModal = (e) => {
    e.preventDefault()
    setShow(!show)
  }

  if (mediaUploads.length === 0) {
    return <></>
  }

  return <div className="media-upload-modal">
    <div className="media-upload-modal-header">
      <div className="media-upload-modal-title">
        Uploads ({uploadedTotal}/{total})
      </div>

      <div className="media-upload-modal-close" onClick={toggleModal}>
        {show ? <ChevronDown/> : <ChevronUp/>}
      </div>
    </div>

    <Collapse isOpen={show}>
      <table className="media-upload-table">
        <tbody>
        {Object.keys(mediaUploadsByProject).map((projectId, i) => (
          <MediaUploadModalProjectRow project={projectsById[projectId]} key={`upload-${i}`}
                                      mediaUploads={mediaUploadsByProject[projectId]}
                                      onAbort={abortFilesUploading}/>)
        )}
        </tbody>
      </table>
    </Collapse>
  </div>
}

const msp = (state) => ({
  mediaUploads: state.entities.mediaUploads
})

const mdp = (dispatch) => ({
  abortFilesUploading: (uploader) => dispatch(abortMediaUploading(uploader)),
  clearFilesUploading: () => dispatch(clearMediaUploading()),
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(MediaUploadModal);