import React from "react";
import { Route, Link } from "react-router-dom";
import {updateProfile, updateProfileWithoutPhoto} from "../../../util/settings_api_util";
import {postUserToPaymentService, confirmUser} from "../../../util/users_api_util";
import LocationComponent from "../../location_component";
import UpdateUserPassword from "./update_user_password";
import TimeZoneSelect from "../profile/time_zone_select";
import { fetchServicesSettings } from "../../../util/service_api_util";


export default class UpdateUser extends React.Component {
  constructor(props) {
    super(props);

    let user = this.props.user

    let user_role = ""
    if (user.roles.admin) {
      user_role = "admin"
    } else if (user.roles.post_production) {
      user_role = "post_production"
    } else if (user.roles.contractor) {
      user_role = "contractor"
    } else if (user.roles.broker) {
      user_role = "broker"
    } else if (user.roles.sub_broker){
      user_role = "sub_broker"
    } else if (user.roles.client){
      user_role = "client"
    } else if (user.roles.sales_rep){
      user_role = "sales_rep"
    }

    this.state = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      avatar: "",
      referral_payout: user.referral_payout ? parseInt(user.referral_payout) : null,
      timezone: user.timezone,
      bio: user.bio,
      src: user.avatar,
      previous_role: user_role,
      role: user_role,
      services: user.services,
      contractor_services: user.contractor_services,
      deleted_contractor_services: [],
      new_services: [],
      newService: "",
      zipcodes: user.zipcodes,
      new_zipcode: "",
      new_zipcodes: [],
      query: "",
      primary_zipcode: user.primary_zip_code,
      servicable_radius: user.service_radius || 10,
      deleted_zipcodes: [],
      deleted_coupons: [],
      coupons: user.coupons || [],
      new_coupons: [],
      new_coupon_amount: "",
      new_coupon_percentage: false,
      new_coupon_expiration_date: "",
      firms: [],
      firm: user.firm?.id || "",
      previous_firm: user.firm?.id || "",
      addressAttributes: {
        city: user.address?.city || "",
        state: user.address?.region || "",
        zipcode: user.address?.zip_code || "",
        street: user.address?.street1 || "",
        apartment: user.address?.apartment_number || "",
        longitude: user.address?.longitude || "",
        latitude: user.addresss?.latitude || "",
        formatted_address: user.address?.formatted_address || ""
      },
      companyName: user.companyName || "",
      agentMlsId: user.agentMlsId || "",
      companyAddress: user.companyAddress?.street1 || "",
      companyCity: user.companyAddress?.city || "",
      companyState: user.companyAddress?.region || "",
      companyZip: user.companyAddress?.zip_code || "",
      errors: []
    }

    this.addCoupon = this.addCoupon.bind(this)
    this.addService = this.addService.bind(this)
    this.addZipcode = this.addZipcode.bind(this)
    this.deleteNewCoupon = this.deleteNewCoupon.bind(this)
    this.deleteNewZipcode = this.deleteNewZipcode.bind(this)
    this.deleteNewService = this.deleteNewService.bind(this)
    this.deleteCoupon = this.deleteCoupon.bind(this)
    this.deleteContractorService = this.deleteContractorService.bind(this)
    this.deleteZipcode = this.deleteZipcode.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.updateUserProfile = this.updateUserProfile.bind(this)
    this.handlePictureSelected = this.handlePictureSelected.bind(this)
    this.postUserToPaymentService = this.postUserToPaymentService.bind(this)
    this.onAddressChange = this.onAddressChange.bind(this)
    this.confirmUser = this.confirmUser.bind(this)
  }

  componentDidMount() {
    this.props.fetchFirms().then(firms => this.setState({ firms: firms }))
    this.props.fetchServicesSettings()
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.src)
  }

  postUserToPaymentService() {
    postUserToPaymentService(this.props.user.id).then(result => {
      this.props.onUserUpdate(result)
      this.props.closeModal()
    })
  }

  confirmUser(){
    confirmUser(this.props.user.id).then(result => {
      this.props.onUserUpdate(result)
      this.props.closeModal()
    })
  }

  addService(){
    if (this.state.newService !== ""){
      let new_services = this.state.new_services;
      new_services.push({contractor_id: this.props.user.id, service_id: this.state.newService})
      this.setState({new_services: new_services, newService: ""})
    }
  }

  addZipcode(){
    if (this.state.new_zipcode.length === 5){
      let new_zipcodes = this.state.new_zipcodes;
      new_zipcodes.push({ contractor_id: this.props.user.id, zip_code: this.state.new_zipcode })
      this.setState({ new_zipcodes: new_zipcodes, new_zipcode: "" })
    }
  }

  addCoupon() {
    if (this.state.new_coupon_amount != "") {
      let new_coupons = this.state.new_coupons;
      let expiration_date = this.state.new_coupon_expiration_date

      if (this.state.new_coupon_expiration_date === ""){
        expiration_date = null
      }
      new_coupons.push({ client_id: this.props.user.id, amount: this.state.new_coupon_amount, percentage: this.state.new_coupon_percentage, expiration_date: expiration_date })
      this.setState({ new_coupons: new_coupons, new_coupon_amount: "", new_coupon_percentage: false, new_coupon_expiration_date: "" })
    }
  }

  deleteNewService(index){
    let new_services = this.state.new_services

    new_services.splice(index, 1)

    this.setState({new_services: new_services})
  }

  deleteNewZipcode(index) {
    let new_zipcodes = this.state.new_zipcodes

    new_zipcodes.splice(index, 1)

    this.setState({ new_zipcodes: new_zipcodes })
  }

  deleteNewCoupon(index) {
    let new_coupons = this.state.new_coupons

    new_coupons.splice(index, 1)

    this.setState({ new_coupons: new_coupons })
  }

  deleteContractorService(service, index){
    let deleted_contractor_services = this.state.deleted_contractor_services
    let services = this.state.services
    let contractor_services = this.state.contractor_services
    let contractor_service = ""
    contractor_services.forEach((new_contractor_service) => {
      if (new_contractor_service.service_id === service.id){
        contractor_service = new_contractor_service
      }
    })

    deleted_contractor_services.push({ _destroy: true, id: contractor_service.id, contractor_id: contractor_service.contractor_id, service_id: contractor_service.service_id })

    services.splice(index, 1)

    this.setState({deleted_contractor_services: deleted_contractor_services, services: services, contractor_services: contractor_services})
  }

  deleteZipcode(index) {
    let deleted_zipcodes = this.state.deleted_zipcodes
    let zipcodes = this.state.zipcodes
    let zipcode = this.state.zipcodes[index]

    deleted_zipcodes.push({ _destroy: true, id: zipcode.id, contractor_id: zipcode.contractor_id, zipcode: zipcode.zip_code })

    zipcodes.splice(index, 1)

    this.setState({ deleted_zipcodes: deleted_zipcodes, zipcodes: zipcodes })
  }

  deleteCoupon(index) {
    let deleted_coupons = this.state.deleted_coupons
    let coupons = this.state.coupons
    let coupon = this.state.coupons[index]

    deleted_coupons.push({ _destroy: true, id: coupon.id, client_id: coupon.client_id, amount: coupon.amount, percentage: coupon.percentage, expiration_date: coupon.expiration_date})

    coupons.splice(index, 1)

    this.setState({ deleted_coupons: deleted_coupons, coupons: coupons })
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handlePictureSelected(event) {
    let avatar = event.target.files[0];
    let src = URL.createObjectURL(avatar);

    this.setState({
      avatar: avatar,
      src: src
    });
  }

  handleCheckbox(){
    this.setState({new_coupon_percentage: !this.state.new_coupon_percentage})
  }

  displayAddress() {
    // return this.state.role === "contractor"
    return true
  }

  onAddressChange(change) {
    this.setState({addressAttributes: {...this.state.addressAttributes, ...change}})
  }

  updateUserProfile() {
    if (this.state.avatar != "") {
      let formData = new FormData();
      formData.append("user[avatar]", this.state.avatar);
      updateProfile(formData, this.props.user.id).then(user => {
        this.props.onUserUpdate(user)
        this.props.closeModal()
      }).catch(response => {
        this.setState({errors: response.responseJSON})
      })
    }

    let contractor_services_attributes = this.state.deleted_contractor_services.concat(this.state.new_services)
    let serviceable_zip_codes_attributes = this.state.deleted_zipcodes.concat(this.state.new_zipcodes)
    let coupons_attributes = this.state.deleted_coupons.concat(this.state.new_coupons)
    let user = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      bio: this.state.bio,
      referral_payout: this.state.referral_payout,
      role_name: (this.state.role === this.state.previous_role && this.state.firm === this.state.previous_firm) ? null : this.state.role,
      phone_number: this.state.phoneNumber,
      timezone: this.state.timezone,
      firm_id: this.state.role === "broker" || this.state.role === "sub_broker" ? this.state.firm : "",
      primary_zip_code: this.state.primary_zipcode,
      service_radius: this.state.servicable_radius,
      contractor_services_attributes: contractor_services_attributes,
      serviceable_zip_codes_attributes: serviceable_zip_codes_attributes,
      coupons_attributes: coupons_attributes,
      agentMlsId: this.state.agentMlsId,
    }
    
    if (this.state.email != this.props.user.email){
      user["email"] = this.state.email
    }

    if (this.displayAddress()) {
      user.address_attributes = {
        city: this.state.addressAttributes.city,
        region: this.state.addressAttributes.state,
        zip_code: this.state.addressAttributes.zipcode,
        street1: this.state.addressAttributes.street,
        apartment_number: this.state.addressAttributes.apartment,
        formatted_address: this.state.addressAttributes.formatted_address
      }
    }

    if (this.props.user.roles.client || this.props.user.roles.broker || this.props.user.roles.sub_broker){
      user.company_attributes = {
        id: this.props.user.companyId,
        company_name: this.state.companyName,
        address_attributes: {
          street1: this.state.companyAddress,
          city: this.state.companyCity,
          region: this.state.companyState,
          zip_code: this.state.companyZip
        }
      }
    }

    updateProfileWithoutPhoto(user, this.props.user.id).then(user => {
      this.props.onUserUpdate(user)
      this.props.closeModal();
    }).catch(response => {
      this.setState({errors: response.responseJSON || []})
    })
  }


  render() {


    let emailErrorClass = "";
    let firstNameErrorClass = "";
    let lastNameErrorClass = "";
    let phoneNumberErrorClass = "";
    let bioErrorClass = "";
    let firstNameErrors = [];
    let lastNameErrors = []; 
    let emailErrors = [];
    let phoneNumberErrors = [];
    let bioErrors = [];
    let contractorServicesComponent = ""
    let zipcodeComponent = ""
    let couponComponent = ""
    let companyComponent = ""
    let brokerageDropdown = ""
    let companyNameErrorClass = "";
    let agentMlsIdErrorClass = "";
    let companyAddressErrorClass = "";
    let companyCityErrorClass = "";
    let companyStateErrorClass = "";
    let companyZipErrorClass = "";


    let companyNameErrors = [];
    let agentMlsIdErrors = [];
    let companyAddressErrors = [];
    let companyCityErrors = [];
    let companyStateErrors = [];
    let companyZipErrors = [];

    if (this.props.user.roles.contractor){

      let serviceOptions = []

      let currentServices = {}


      let contractorServicesLi = this.state.services.map((service, i) => {
        currentServices[service.id] = true
        return <div className="contractorServiceItem" >
          <div className="itemTitle" key={i}>{service.title}</div>
          <div className="itemIcon" onClick={() => { this.deleteContractorService(service, i) } }><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
        </div>
      })

      
      this.state.new_services.forEach((new_service, i) => {
        currentServices[new_service.service_id] = true
        contractorServicesLi.push(<div className="contractorServiceItem">
          <div className="itemTitle" key={new_service.service_id}>{this.props.services[new_service.service_id].title}</div>
          <div className="itemIcon" onClick={() => { this.deleteNewService(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
        </div>)
      })

      serviceOptions = Object.values(this.props.services || []).filter((service) => !currentServices[service.id] && !service.hidden).map((service) => <option key={service.id} value={service.id}>{service.title}</option>)

      contractorServicesComponent = 
      <div className="contractorServiceList">
        <div>Contractor Services</div>
          {contractorServicesLi}
        <div className="settingsCompanyInput roleInput">
          <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}> New Service</label>
          <div className="updateUserDropdownAndButton">
            <select className="formInput newServiceDropdown" onChange={this.handleInput("newService")} name="Service" value={this.state.newService} >
              <option value="" disabled={true}>Service</option>
              {serviceOptions}
            </select>
            <button onClick={this.addService}className="addContractorServiceButton">Add Service</button>
          </div>
        </div>
      </div>


      let zipcodesLi = this.state.zipcodes.map((zipcode, i) => {
      if (this.state.query === "" || (this.state.query != "" && zipcode.zip_code.toLowerCase().includes(this.state.query.toLowerCase()))) {
        return <div className="contractorServiceItem">
          <div className="itemTitle" key={i}>{zipcode.zip_code}</div>
          <div className="itemIcon" onClick={() => { this.deleteZipcode(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
        </div>
      }
    })

    
      this.state.new_zipcodes.forEach((new_zipcode, i) => {
        if (this.state.query === "" || (this.state.query != "" && zipcode.zip_code.toLowerCase().includes(this.state.query.toLowerCase()))) {
          zipcodesLi.push(<div className="contractorServiceItem">
            <div className="itemTitle" key={new_zipcode.zip_code}>{new_zipcode.zip_code}</div>
            <div className="itemIcon" onClick={() => { this.deleteNewZipcode(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
          </div>)
        }
      }
      )

      zipcodeComponent =
        <div className="contractorServiceList">
          <div>Contractor Zipcodes</div>
          {/* <div className="settingsCompanyInput roleInput">
            <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}> Search</label>
            <div className="updateUserDropdownAndButton">
              <input className="formInput" id="smallerInput" onChange={this.handleInput("query")} type="string" placeholder="Zip Code" value={this.state.query} />
            </div>
          </div> */}
          <div className="zipcodeServiceList">
          {zipcodesLi}
          </div>
          <div className="settingsCompanyInput roleInput">
            <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}> New Zipcode</label>
            <div className="updateUserDropdownAndButton">
              <input className="formInput" id={firstNameErrorClass} onChange={this.handleInput("new_zipcode")} type="string" placeholder="Zip code" value={this.state.new_zipcode} />
              <button onClick={this.addZipcode} className="addContractorServiceButton">Add Zipcode</button>
            </div>
          </div>
          <div className="settingsCompanyInput roleInput">
            <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Primary Zipcode</label>
            <div className="updateUserDropdownAndButton">
              <input className="formInput" id={firstNameErrorClass} onChange={this.handleInput("primary_zipcode")} type="string" placeholder="Zip code" value={this.state.primary_zipcode} />
            </div>
          </div>
        <div className="settingsCompanyInput roleInput">
          <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Servicable Radius</label>
          <div className="updateUserDropdownAndButton">
            <input type="range" min="1" max="100" className="formInput" id={firstNameErrorClass} onChange={this.handleInput("servicable_radius")} value={this.state.servicable_radius} />
          </div>
          <div>Radius: {this.state.servicable_radius}</div>
        </div>
        </div>
     }

    if (this.props.user.roles.client || this.props.user.roles.broker || this.props.user.roles.sub_broker){

      let couponsLi = this.state.coupons.map((coupon, i) => (
        <div className="contractorServiceItem" key={i}>
          {coupon.percentage ? (
            <div className="itemTitle" >{coupon.amount}%</div>
          ) : (
              <div className="itemTitle" >${coupon.amount}</div>
            )}
          <div className="itemTitle" >{coupon.expiration_date || "No Expiration"}</div>
          <div className="itemIcon" onClick={() => { this.deleteCoupon(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
        </div>
      ))


      this.state.new_coupons.forEach((new_coupon, i) => (
        couponsLi.push(
          <div className="contractorServiceItem" key={i*200}>
            {new_coupon.percentage ? (
              <div className="itemTitle">{new_coupon.amount}%</div>
            ) : (
                <div className="itemTitle">${new_coupon.amount}</div>
              )}
            <div className="itemTitle">{new_coupon.expiration_date || "No Expiration"}</div>
            <div className="itemIcon" onClick={() => { this.deleteNewCoupon(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
          </div>)
      )
      )

      couponComponent =
        <div className="contractorServiceList">
          <div>Coupons</div>
          {couponsLi}
          <div className="settingsCompanyInput roleInput">
            <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}> New Coupon</label>
            {/* <div className="d-flex"> */}
              <input className="formInput" id={firstNameErrorClass} onChange={this.handleInput("new_coupon_amount")} type="string" placeholder="Coupon Amount" value={this.state.new_coupon_amount} />
              <input className="formInput couponDateInput" id={firstNameErrorClass} onChange={this.handleInput("new_coupon_expiration_date")} type="date" placeholder="Expiration Date" value={this.state.new_coupon_expiration_date} />
              <label><input type="checkbox" className="formCheckBox" checked={this.state.new_coupon_percentage} onChange={this.handleCheckbox} /> <span className="rememberMe">Percentage?</span></label>
              <button onClick={this.addCoupon} className="addContractorServiceButton addCouponButton">Add Coupon</button>
            {/* </div> */}
          </div>
        </div>

      companyComponent = <div className="contractorServiceList"><div className="settingsComponentHeading">Company Info</div>
          <div className="settingsCategoryGroup1">
            <div className="inputGrouping">
              <div className="settingsCompanyInput">
                <label id="formLabel"> Company name </label>
                <input className="formInput" id={companyNameErrorClass} onChange={this.handleInput("companyName")} type="string" placeholder="Company Name" value={this.state.companyName} />
                <ul className="errorUl">{companyNameErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
                <label id="formLabel"> Agent MLS ID </label>
                <input className="formInput" id={agentMlsIdErrorClass} onChange={this.handleInput("agentMlsId")} type="string" placeholder="Agent MLS Id" value={this.state.agentMlsId} />
                <ul className="errorUl">{agentMlsIdErrors}</ul>
              </div>
              <div className="settingsCompanyInput">
              <label id="formLabel"> Company address</label>
              <input className="formInput" id={companyAddressErrorClass} onChange={this.handleInput("companyAddress")} type="string" placeholder="Street Address" value={this.state.companyAddress} />
              <ul className="errorUl">{companyAddressErrors}</ul>
          </div>
          </div>
          <div className="inputGrouping">
              <div className="settingsCompanyInput">
              <label id="formLabel"> City </label>
              <input className="formInput" id={companyCityErrorClass} onChange={this.handleInput("companyCity")} type="string" placeholder="City" value={this.state.companyCity} />
              <ul className="errorUl">{companyCityErrors}</ul>
            </div>
              <div className="settingsCompanyInput">
              <label id="formLabel"> State </label>
              <input className="formInput" id={companyStateErrorClass} onChange={this.handleInput("companyState")} type="string" placeholder="State" value={this.state.companyState} />
              <ul className="errorUl">{companyStateErrors}</ul>
            </div>
              <div className="settingsCompanyInput">
              <label id="formLabel"> Zipcode </label>
              <input className="formInput" id={companyZipErrorClass} onChange={this.handleInput("companyZip")} type="zipcode" placeholder="Zipcode" value={this.state.companyZip} />
              <ul className="errorUl">{companyZipErrors}</ul>
            </div>
          </div>
          </div>
      </div>
    }

    let firmOptions = this.state.firms.map(firm => <option value={firm.id}>{firm.name}</option>)

    if (this.state.role === "broker" || this.state.role === "sub_broker") {
      brokerageDropdown = <div className="settingsCompanyInput roleInput">
        <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Brokerage Firm</label>
        <select className="formInput" onChange={this.handleInput("firm")} name="firm" value={this.state.firm} >
          <option value="">No Firm</option>
          {firmOptions}
        </select>
      </div>
    }


    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="settingsComponentHeading">Update User</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.errors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
          )}

          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> First name </label>
              <input className="formInput" id={firstNameErrorClass} onChange={this.handleInput("firstName")} type="string" placeholder="First name" value={this.state.firstName} />
              <ul className="errorUl">{firstNameErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel"> Last name </label>
              <input className="formInput" id={lastNameErrorClass} onChange={this.handleInput("lastName")} type="string" placeholder="Last name" value={this.state.lastName} />
              <ul className="errorUl">{lastNameErrors}</ul>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> Email</label>
              <input className="formInput" id={emailErrorClass} onChange={this.handleInput("email")} type="email" placeholder="Email address" value={this.state.email} />
              <ul className="errorUl">{emailErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel"> Phone number </label>
              <input className="formInput" id={phoneNumberErrorClass} onChange={this.handleInput("phoneNumber")} type="tel" placeholder="Phone Number" value={this.state.phoneNumber} />
              <ul className="errorUl">{phoneNumberErrors}</ul>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="w-100">
              <label id="formLabel"> Time Zone </label>
              <TimeZoneSelect value={this.state.timezone} onChange={this.handleInput("timezone")} />
            </div>
          </div>
          <div className="bioGroup">
            <label id="formLabel">Bio</label>
            <textarea className="formTextArea bioTextArea" onChange={this.handleInput("bio")} type="string" placeholder="Short description about yourself" value={this.state.bio} />
          </div>

          {this.displayAddress() && (
            <div className="settingsCategoryGroup2">
              <LocationComponent changeParentState={this.onAddressChange}
                                 title="User address"
                                 street={this.state.addressAttributes.street}
                                 apartment={this.state.addressAttributes.apartment}
                                 zipcode={this.state.addressAttributes.zipcode}
                                 state={this.state.addressAttributes.state}
                                 city={this.state.addressAttributes.city}
                                 latitude={this.state.addressAttributes.latitude}
                                 longitude={this.state.addressAttributes.longitude}
                                 formatted_address={this.state.addressAttributes.formatted_address} />
            </div>
          )}

          <div className="settingsCategoryGroup2">
            <div className="settingsComponentHeading">Change photo</div>
            <div className="settingsComponentSubHeading">Allow others to see who they are working with. This enables a profile picture in the directory, as well as showing a small icon within workflow for other users to easily identify you.</div>
            <div className="settingsPhotoDiv settingsUsersEdit">
              <img className="settingsAvatar settingsGalleryCover" src={this.state.src} />
              <label className="settingsChangePhotoButton coverUploadButton">
                <input onChange={this.handlePictureSelected} type="file" accept="image/png, image/jpeg" />
                  Change Photo
                </label>
            </div>
            <div className="settingsCompanyInput roleInput">
              <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Referral Code</label>
              <div className="updateUserDropdownAndButton">
                <input className="formInput" id={firstNameErrorClass} type="string" value={this.props.user.referral_code} />
              </div>
            </div>
            <div className="settingsCompanyInput roleInput">
              <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Referral Payout (%)</label>
              <div className="updateUserDropdownAndButton">
                <input className="formInput" id={firstNameErrorClass} onChange={this.handleInput("referral_payout")} type="string" placeholder="Referral Payout" value={this.state.referral_payout} />
              </div>
            </div>
            <div className="settingsCompanyInput roleInput">
              <label id="formLabel" style={{fontSize: "20px", fontWeight: "bold"}}> Role</label>
              <select className="formInput" onChange={this.handleInput("role")} name="Role" value={this.state.role} >
                <option value="" disabled={true}>None</option>
                <option value="admin">Admin</option>
                <option value="broker">Broker</option>
                <option value="client">Client</option>
                <option value="contractor">Contractor</option>
                <option value="post_production">Post Production</option>
                <option value="sales_rep">Sales Rep</option>
                <option value="sub_broker">Sub Broker</option>
              </select>
            </div>
            {brokerageDropdown}
            {contractorServicesComponent}
            {zipcodeComponent}
            {couponComponent}
            {companyComponent}
          </div>
          <button onClick={this.updateUserProfile} className="settingsButton mt-4">Save changes</button>

          <UpdateUserPassword user={this.props.user} onChange={this.props.onUserUpdate}/>

          {!this.props.user.has_payment_service && (
            <div className="paymentServiceButton inputGrouping">
              <div className="signupLeftInput">
                <button className="settingsButton w-auto px-3 mt-2" onClick={this.postUserToPaymentService} type="button" >
                  Post user to payment service
                </button>
              </div>
            </div>
          )}

          {!this.props.user.confirmed && (
            <div className="paymentServiceButton inputGrouping">
              <div className="signupLeftInput">
                <button className="settingsButton w-auto px-3 mt-2" onClick={this.confirmUser} type="button" >
                  Confirm User
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}