import styled from 'styled-components';

export const FormSectionContainer = styled.div`
  background-color: white;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  @media screen and (max-width: 991px) {
    padding: 75px 0px;
  }
  @media screen and (max-width: 550px) {
    padding: 75px 0px 10px;
  }
`;

export const FormSectionWrapper = styled.div`
  width: 988px;
  @media screen and (max-width: 991px) {
    width: 95%;
  }
  @media screen and (max-width: 767px) {
    width: 92%;
  }
`;

export const FormSectionHeading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 54px;
  letter-spacing: -0.01em;
  color: #1b264f;
  text-transform: initial;
  text-align: center;
  width: 673px;
  margin: 0px auto;
  @media screen and (max-width: 769px) {
    width: 350px;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 30px;
  }
`;

export const FormSectionHeadingColor = styled.span`
  color: #d2ad81;
`;

export const LineBreak = styled.hr`
  background-color: #ECF0FF;
  height: 3px !important;
  margin: 76px auto 49px;
  width: 988px;
  opacity: 1;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    margin: 50px auto;
  }
`;