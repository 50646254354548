import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin: 99px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 769px) {
    margin: 50px 0px;
    padding: 0px 30px;
  }
`;

export const HeaderTitle = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 54px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #1b264f;
  @media screen and (max-width: 769px) {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const HeaderDescription = styled.p`
  margin: 37px 0px 80px;
  width: 769px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  font-feature-settings: 'salt' on, 'liga' off;
  text-align: center;
  color: #353844;
  @media screen and (max-width: 769px) {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    margin: 8px 0px 28px 0px;
  }
`;

export const LineBreak = styled.hr`
  background-color: #ECF0FF;
  height: 3px !important;
  width: 988px;
  opacity: 1;

  @media screen and (max-width: 991px) {
    width: 94%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
