import { connect } from "react-redux";
import SettingsTimes from "./settings_times";
import React from "react";
import { withRouter } from "react-router-dom";
import { updateTimeSlots, fetchTimeSlots} from "../../../actions/settings_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  services: state.entities.services
});

const mdp = (dispatch) => ({
  fetchTimeSlots: (twilight) => dispatch(fetchTimeSlots(twilight)),
  updateTimeSlots: (time_slots, twilight) => dispatch(updateTimeSlots(time_slots, twilight)),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsTimes));