import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import {updateProjectDetails} from "../../util/project_api_util"

class NoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      note: this.props.project?.notes || "",
    }
    this.handleInput = this.handleInput.bind(this)
    this.submitNote = this.submitNote.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  submitNote() {
    let data = { id: this.props.project.id, project: {id: this.props.project.id, notes: this.state.note}}

    updateProjectDetails(data).then(() => {
      this.props.onClose()
      this.props.onReloadProject()
    })
  }

  render() {
      return (
        <div className="modal-background" onClick={() => this.props.onClose()}>
          <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="uploadPhotoModalHeading">Project Notes</div>
                <div className="closeIcon" onClick={() => this.props.onClose()}><i className="fas fa-times"></i></div>
              </div>
              <div className="settingsCategoryGroup1">
                <div className="settingsServicesInput">
                  <label id="formLabel"></label>
                  <textarea className="formTextArea" onChange={this.handleInput("note")} type="string" placeholder="Leave note here..." value={this.state.note} />
                </div>
              </div>
              <div className="reviewModalSubmitButton">
                <button onClick={this.submitNote} className="settingsButton">Submit</button>
              </div>
            </div>
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    }
}

export default withRouter(NoteModal);