import { connect } from "react-redux";
import SettingsPreferences from "./settings_preferences";
import React from "react";
import { withRouter } from "react-router-dom";
import { updateProfile } from "../../../actions/settings_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  updateProfile: (user, user_id) => dispatch(updateProfile(user, user_id))
});

export default withRouter(connect(msp, mdp)(SettingsPreferences));