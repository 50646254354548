import React from "react";
import {withRouter} from "react-router-dom";
import LoginBackgroundDesign from "../../../assets/images/background_design_login";
import Logo from "../../../assets/images/logo"
import ConfirmationFormContainer from "./forms/confirmation_form_container";

class ConfirmationPath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmedWithinProjectCreation: false
    }
  }

  componentDidMount() {
    this.props.fetchConfirmation({confirmation_token: this.props.match.params.confirmationToken}).then(() => {
      if (this.props.errors.length === 0) {
        this.props.successError(["Account has been confirmed."])
      }
      if (localStorage.getItem('creatingProject')) {
        localStorage.removeItem('creatingProject')
        this.setState({confirmedWithinProjectCreation: true})
      } else {
        this.props.history.push("/login")
      }
    })
  }

  render() {

    return (
      <div className="loginContainer">
        <img className="loginBackgroundDesign" src={LoginBackgroundDesign} />
        <div className="modernAnglesLoginAndLogo">
          <img className="modernAnglesLoginLogo" src={Logo} onClick={() => this.props.history.push("/")}/>
          {this.state.confirmedWithinProjectCreation ? (
            <div className="loginForm">
              <div className="loginFormContent p-4">
                Your account has been confirmed. You can now return to the previous window and continue ordering your project.
              </div>
            </div>
          ) : <ConfirmationFormContainer/>}
        </div>
      </div>
    )
  }
}

export default withRouter(ConfirmationPath);