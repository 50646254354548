import React from "react";
import {deleteAd, fetchAds} from "../../../util/ad_api_utils";


export default class SettingsAds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ads: []
    }
  }

  componentDidMount() {
    fetchAds().then(ads => {
      this.setState({ads})
    })
  }

  onCreate() {
    this.props.openModal("adForm", {onSave: this.onCreated.bind(this)})
  }

  onUpdate(ad) {
    this.props.openModal("adForm", {onSave: this.onUpdated.bind(this), ad})
  }

  onDelete(ad) {
    if (window.confirm(`Are you sure you want to delete "${ad.title}"?`)) {
      deleteAd(ad.id).then(() => {
        this.setState({ads: this.state.ads.filter(f => f.id !== ad.id)})
        this.props.showToast("Ad has been removed")
      }).catch(() => {
        alert('Could not delete this ad')
      })
    }
  }

  onCreated(ad) {
    this.setState({ads: [...this.state.ads, ad]})
    this.props.showToast("Ad has been created")
  }

  onUpdated(updatedAd) {
    this.setState({ads: this.state.ads.map(ad => ad.id === updatedAd.id ? updatedAd : ad)})
    this.props.showToast("Ad has been updated")
  }

  render() {
    return (
      <div className="settingsPreferencesGroupingDiv">
        <div className="settingsPreferences settingsUsersList">
          <div className="settingsComponentHeading">Ads</div>
          <div className="settingsPreferencesGroup1">
            {this.state.ads.map(ad => (
              <div className="settingsServicesItem" key={ad.id}>
                <div className="itemTitle">{ad.title}</div>
                <div className="itemIcon" onClick={(e) => this.onUpdate(ad)}>
                  <i className="far fa-edit settingsServiceIcon m-2"/>
                </div>
                <div className="itemIcon" onClick={() => {
                  this.onDelete(ad)
                }}>
                  <i className="far fa-trash-alt settingsServiceIcon m-2"/>
                </div>

              </div>
            ))}
          </div>
          <button onClick={() => this.onCreate()} className="settingsButton preferencesButton">
            Create
          </button>
        </div>
      </div>
    );
  }
}