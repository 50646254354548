import styled from 'styled-components';

import { FOOTER_IMAGE } from '../../../../../assets/images/landing';

export const FooterWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

export const FooterLowerContainer = styled.div`
  width: 1090px;
  margin: auto;
  @media screen and (max-width: 1280px){
    width: 600px;
  }
  @media screen and (max-width: 767px){
    width: 272px;
  }
`;

export const CustomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 1090px;
  margin: auto;

  @media screen and (max-width: 1280px){
    flex-direction: column;
    align-items: center;
    width: 600px;
  }
  
  @media screen and (max-width: 767px){
    width: 294px;
  }
`;

export const LinksSection = styled.div`
  width: 540px;
  display: flex;
  @media screen and (max-width: 1280px){
    width: 600px;
    margin-top: 50px;
  }

  @media screen and (max-width: 767px){
    width: 294px;
    margin-top: 50px;
    flex-direction: column;
  }
`;

export const SectionWithLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  @media screen and (max-width: 1280px){
    align-items: center;
  }

`;

export const ButtonContainer = styled.div`
  margin-top: 175px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  gap: 20px;

  @media screen and (max-width: 1280px){
    margin-top: 48px;
  }
`;

export const LoginButton = styled.button`
  width: 122px;
  height: 45px;
  padding: 16px 38px 15px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  background: #1A1D25;
  border: 2px solid #7C8193;
  border-radius: 8px;
`;

export const SignUpButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px 18px 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  background: #1A1D25;
  width: 152px;
  height: 45px;
  border: 2px solid #7C8193;
  border-radius: 8px;
`;

export const FooterContent = styled.div`
  width: 100%;
  background-color: #1A1D25;
`;

export const FooterUpperContent = styled.div`
  padding: 100px 0px;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    width: 38%;
    height: 85%;
    position: absolute;
    bottom: -10%;
    left: 5%;
    background-image: url(${FOOTER_IMAGE.FooterWave});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
  }
  .upperSection {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
      justify-content: center;
      padding-bottom: 70px;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      padding-bottom: 20px;
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .middleSection {
    z-index: 1;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-top: 30px;
      padding-right: 40px;
      padding-left: 0px;
    }
  }
  @media screen and (max-width: 1700px) {
    &:before {
      width: 40%;
      bottom: -15%;
      left: 0%;
    }
  }
  @media screen and (max-width: 1550px) {
    &:before {
      width: 40%;
      bottom: -18%;
      left: -2%;
    }
  }
  @media screen and (max-width: 1480px) {
    &:before {
      width: 33%;
      bottom: -33%;
      left: -1%;
    }
  }
  @media screen and (max-width: 1399px) {
    &:before {
      bottom: -38%;
    }
  }
  @media screen and (max-width: 1250px) {
    padding: 100px 0px 50px;
    &:before {
      bottom: -49%;
    }
  }
  @media screen and (max-width: 991px) {
    &:before {
      width: 50%;
      bottom: -40%;
      left: -27%;
    }
  }
  @media screen and (max-width: 800px) {
    &:before {
      width: 50%;
      bottom: -45%;
      left: -27%;
    }
  }
  @media screen and (max-width: 767px) {
    &:before {
      background-image: url(${FOOTER_IMAGE.FooterRightWave});
      width: 250px;
      height: 670px;
      bottom: -15%;
      right: 0;
      left: initial;
    }
  }
`;

export const FooterLogo = styled.img`
  width: 100%;
`;

export const FooterMenuWrapper = styled.div``;

export const FooterMenuHeading = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #eeeff4;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

export const FooterMenuItem = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #eeeff4;
`;

export const FooterLowerContent = styled.div`
  padding: 20px;
  border-top: 2px solid #30333c;
`;

export const FooterLowerContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  @media screen and (max-width: 767px) {
    padding-left: 0px;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const ReservedText = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #bdc1cf;
  margin-top: 15px;
`;

export const FooterSocialIconsWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

export const FooterSocialIcon = styled.img`
  width: 40px;
  height: 40px;
  margin: 8px;
`;
