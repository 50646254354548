import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  CreativeServicesComponent,
  HomeTopSlider,
  ServiceLogoListComponent,
  TestimonialsComponent,
  PropertyComponent,
} from './homeComponents';
import { HomeLandingPage } from './homeStyle';
import {
  BestProperty,
  ContractMedia,
} from '../pageComponents';
import { CONTRACT } from '../../../../assets/images/landing';
import BundlesAndServices from "../pageComponents/BundlesAndServices";
import {BlogPosts} from "../pageComponents/PopularPosts";
import {CONTRACT_MEDIA_DATA} from "./homePageData";
import {Difference} from "../pageComponents/Difference";
import {TestimonialsComponent2} from './homeComponents';

const { ContractMediaSlides } = CONTRACT;

const Homepage = (props) => {
  if (props.currentUser && !props.modalOpen && !props.projectModalOpen && !props.VSModalOpen) {
    props.history.push('/dashboard');
    return '';
  } else {
    useEffect(() => {
      if(props.match.params.referral_code){
        props.setModal(true)
      }
    }, []);
    return (
      <HomeLandingPage className='landingPage'>
        <Helmet>
          <title>Book a Real Estate Photographer Near You | Modern Angles</title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="description"
            content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
          />
        </Helmet>
        <CreativeServicesComponent setModal={props.setModal}/>

        <HomeTopSlider />

        <div className="lead-page-container lead-page-container-homepage">
          <ServiceLogoListComponent />
        </div>

        <Difference />
        <BundlesAndServices setModal={props.setModal}/>

        <ContractMedia
          imagesList={ContractMediaSlides}
          data={CONTRACT_MEDIA_DATA}
          isVirtualStaging={false}
        />

        <TestimonialsComponent2 />
        

        <PropertyComponent />

        <BestProperty />

        <BlogPosts />
      </HomeLandingPage>
    );
  }
};

export default withRouter(Homepage);
