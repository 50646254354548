export const OPEN_START_MODAL = 'OPEN_START_MODAL';
export const CLOSE_START_MODAL = 'CLOSE_START_MODAL';
export const OPEN_PROJECT_MODAL = "OPEN_PROJECT_MODAL";
export const CLOSE_PROJECT_MODAL = "CLOSE_PROJECT_MODAL";
export const OPEN_VS_MODAL = "OPEN_VS_MODAL";
export const CLOSE_VS_MODAL = "CLOSE_VS_MODAL";

export const openStartModal = () => {
  return {
    type: OPEN_START_MODAL
  };
};

export const closeStartModal = () => {
  return {
    type: CLOSE_START_MODAL
  };
};

export const openProjectModal = () => {
  return {
    type: OPEN_PROJECT_MODAL
  }
}

export const closeProjectModal = () => {
  return {
    type: CLOSE_PROJECT_MODAL
  }
}

export const openVsModal = () => {
  return {
    type: OPEN_VS_MODAL
  }
}

export const closeVsModal = () => {
  return {
    type: CLOSE_VS_MODAL
  }
}