export const updateProfile = (user, user_id) => (
  $.ajax({
    type: "PATCH",
    url: `/users/${user_id}`,
    data: user,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const updateProfileWithoutPhoto = (user, user_id) => (
  $.ajax({
    type: "PATCH",
    url: `/users/${user_id}`,
    data: {user: user}
  })
);



export const fetchBlockedTimeSlots = (user_id) => (
  $.ajax({
    type: "GET",
    url: `/block_out_times`,
    data: {user_id}
  })
);

export const updateBlockedTimes = (blockedTimes, user_id) => (
  $.ajax({
    type: "POST",
    url: `/submit_times`,
    data: {schedule: blockedTimes, user_id}
  })
);


export const googleCalendarIntegration = () => (
  $.ajax({
    type: "GET",
    url: `/calendars/authorize_access`,
  })
);

export const updatePayment = (paymentInfo, paymentId) => (
  $.ajax({
    type: "PATCH",
    url: `/payment_methods/${paymentId}`,
    data: { payment_method: paymentInfo }
  })
);

export const createPayment = (paymentInfo) => (
  $.ajax({
    type: "POST",
    url: `/payment_methods`,
    data: { payment_method: paymentInfo }
  })
);

export const fetchPaymentInfo = (user_id) => (
  $.ajax({
    type: "GET",
    url: `/fetch_payment_info`,
    data: { payment_method: {user_id: user_id }}
  })
);

export const createUser = (user) => (
  $.ajax({
    type: "POST",
    url: "/users",
    data: { user: user }
  })
);

export const updateUserPassword = (user_id, password, password_confirmation) => (
  $.ajax({
    type: "PUT",
    url: `/users/${user_id}/change_password`,
    data: {user: {password, password_confirmation}},
  })
);

export const fetchFirms = () => (
  $.ajax({
    type: "GET",
    url: `/brokerage_firms`,
  })
);

export const createFirm = (firm) => (
  $.ajax({
    type: "POST",
    url: `/brokerage_firms`,
    data: {brokerage_firm: firm}
  })
);

export const updateFirm = (firm, firm_id) => (
  $.ajax({
    type: "PATCH",
    url: `/brokerage_firms/${firm_id}`,
    data: {brokerage_firm: firm}
  })
);

export const deleteFirm = (firm_id) => (
  $.ajax({
    type: "DELETE",
    url: `/brokerage_firms/${firm_id}`,
  })
);

export const fetchPayouts = (regular = true, search_address = null, search_user = null, search_from = null, search_to = null, page = 0, pageAmount = 10) => (
  $.ajax({
    type: "GET",
    url: "/fetch_payouts",
    data: { regular: regular, search_address, search_user, search_from, search_to, page: page, pageAmount: pageAmount }
  })
)

export const fetchEnablePayoutLink = (redirect_url = null) => (
  $.ajax({
    type: "POST",
    url: `/account_link_urls`,
    data: {redirect_url: redirect_url}
  })
);

export const createExternalAccount = (external_account) => (
  $.ajax({
    type: "POST",
    url: `/external_accounts`,
    data: { external_account: external_account}
  })
);

export const fetchPromos = () => (
  $.ajax({
    type: "GET",
    url: `/broker_promotions`,
  })
);

export const fetchUserPromos = (user_id) => (
  $.ajax({
    type: "GET",
    url: `/fetch_user_promos`,
    data: {user_id: user_id}
  })
);

export const createPromo = (promo) => (
  $.ajax({
    type: "POST",
    url: `/broker_promotions`,
    data: { broker_promotion: promo }
  })
);

export const updatePromo = (promo, promo_id) => (
  $.ajax({
    type: "PATCH",
    url: `/broker_promotions/${promo_id}`,
    data: { broker_promotion: promo}
  })
);

export const deletePromo = (promo_id) => (
  $.ajax({
    type: "DELETE",
    url: `/broker_promotions/${promo_id}`,
  })
);

export const fetchTimeSlots = (twilight, timezone = null) => (
  $.ajax({
    type: "GET",
    url: `/fetch_time_slots`,
    data: {twilight: twilight, timezone: timezone}
  })
);

export const fetchActiveTimeSlots = () => (
  $.ajax({
    type: "GET",
    url: `/fetch_active_time_slots`,
  })
);

export const updateTimeSlots = (time_slots, twilight) => (
  $.ajax({
    type: "POST",
    url: `/submit_time_slots`,
    data: { time_slots: time_slots, twilight: twilight }
  })
);

export const deleteGalleryMedia = (medias, gallery_id) => (
  $.ajax({
    type: "DELETE",
    beforeSend: function (xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
    url: '/batch_destroy_gallery',
    data: { media_ids: medias, id: gallery_id }
  })
);


export const fetchMilestones = () => (
  $.ajax({
    type: "GET",
    url: `/sale_milestones`,
  })
);

export const createMilestone = (milestone) => (
  $.ajax({
    type: "POST",
    url: `/sale_milestones`,
    data: { milestone: milestone }
  })
);

export const updateMilestone = (milestone_id, milestone) => (
  $.ajax({
    type: "PATCH",
    url: `/sale_milestones/${milestone_id}`,
    data: { milestone: milestone }
  })
);

export const deleteMilestone = (milestone_id) => (
  $.ajax({
    type: "DELETE",
    url: `/sale_milestones/${milestone_id}`,
  })
);

export const updateContractorSubServices = (data) => (
  $.ajax({
    type: "PATCH",
    url: '/update_contractor_sub_services',
    contentType: 'application/json', // Set the content type to JSON
    data: JSON.stringify({ contractor_sub_services: data })
  })
);

export const updateLocationPrices = (data) => (
  $.ajax({
    type: "PATCH",
    url: '/update_location_sub_service_prices',
    contentType: 'application/json', // Set the content type to JSON
    data: JSON.stringify({ location_sub_service_prices: data })
  })
);

export const deleteLocationPrice = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/location_sub_service_prices/${id}`,
  })
)

export const fetchSalesData = (start_date, end_date) => (
  $.ajax({
    type: "GET",
    url: `/fetch_sales_data`,
    data: { start_date: start_date, end_date: end_date }
  })
)