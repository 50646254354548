export const fetchTermsOfService = (userRole) => (
  $.ajax({
    url: '/terms_of_service/current',
    data: { user_role: userRole }
  })
);

export const fetchTermsOfServiceHistory = () => (
  $.ajax({
    url: '/terms_of_service/history',
  })
);

export const deleteTermsOfServiceVersion = (id) => (
  $.ajax({
    method: 'DELETE',
    url: `/terms_of_service/${id}`,
  })
);

export const createTermsOfService = (termsOfService) => (
  $.ajax({
    url: '/terms_of_service',
    type: 'POST',
    data: termsOfService
  })
);
