import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import {formatTime} from "../../util/date_util";
import { railsToJsTimezoneMap } from "../../util/timezone_utils";

class ContractorTimeslotItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    let time = this.props.time;

    let id = "";
    let checkmark = "";
    let timeSelected = "";

    if (this.props.selected) {
      id = "timeslotSelected"
      timeSelected = "contractorTimeSelected"
      checkmark = <div className="timeslotCheckmark"><i className="fas fa-check-square"></i></div>
    }

    if (this.props.unavailable){
      id = "timeslotUnavailable"
      timeSelected = "contractorTimeUnavailable"
    }

    let timezone = this.props.detected_timezone|| this.props.client_timezone || this.props.project?.client.timezone || this.props.currentUser?.timezone

    return (
      <div className="contractorTimeSlotDiv" id={id} onClick={() => this.props.unavailable ? "" : this.props.handleTimeSelect(time)}>
        <div className="contractorTime" id={timeSelected}>{formatTime(time, railsToJsTimezoneMap[timezone])}</div>
        {checkmark}
      </div>
    )
  }
}

export default withRouter(ContractorTimeslotItem);