import React from "react"
import NavbarContainer from "./navbar/navbar_container"
import PageHeader from "./page_header"
import {withRouter, NavLink} from "react-router-dom"
import {debounce} from "debounce"
import {connect} from "react-redux"
import {fetchPayouts} from "../util/settings_api_util"
import LogoSpinner from "../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { v4 as uuidv4 } from 'uuid';

class Payouts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      total_payouts: 0,
      payouts: [],
      displayedPayouts: [],
      searchAddress: '',
      searchUser: '',
      searchFrom: '',
      searchTo: '',
      isLargeScreen: true,
      regular: true,
      page: 0,
      pageSize: 10,
      loading: false,
      requestId: null
    }
    this.onSearchChange = this.onSearchChange.bind(this)
    this.debouncedRecalculateDisplayedPayouts = debounce(this.recalculateDisplayedPayouts, 500)
    this.updatePredicate = this.updatePredicate.bind(this);
    this.fetchPayouts = this.fetchPayouts.bind(this);
    this.debounceFetchPayouts = debounce(() => this.fetchPayouts(), 200)
  }

  componentDidMount() {
    document.body.style.overflow = 'unset';
    this.fetchPayouts()

    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  componentDidUpdate(_, prevState) {
    if (prevState.regular !== this.state.regular || prevState.page !== this.state.page) {
      this.fetchPayouts(this.state.regular)
    }
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  changePage(page) {
    this.setState({ page: page })
  }

  fetchPayouts() {
    const {
      regular,
      searchAddress,
      searchUser,
      searchFrom,
      searchTo,
      page,
      pageSize
    } = this.state;

    this.setState({loading: true})

    const requestId = uuidv4(); // Generate a unique ID for this request
    this.setState({ requestId }); // Store it in the state

    // Make a request to your backend API to fetch invoices
    fetchPayouts(
      regular,
      searchAddress,
      searchUser,
      searchFrom,
      searchTo,
      page,
      pageSize
    ).then((payouts) => {
        if (this.state.requestId === requestId) {
          this.setState({
            payouts: payouts,
            total_payouts: payouts?.length > 0 ? payouts[0].total_payouts : 0,
            loading: false
          });
        }
    });
  }

  onSearchChange(field) {
    return (e) => {
      e.preventDefault()
      this.setState({ [field]: e.target.value, page: 0 }, () => {
        this.debounceFetchPayouts()
      })
    }
  }

  render() {
    const { page } = this.state

    let pageLast = 1

    if (this.state.total_payouts > this.state.pageSize) {
      pageLast = parseInt(this.state.total_payouts % this.state.pageSize > 0 ? this.state.total_payouts / this.state.pageSize + 1 : this.state.total_payouts / this.state.pageSize)
    }

    let pages = [];
    const startPage = Math.max(page - 5, 0);
    const endPage = Math.min(startPage + 9, pageLast - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    let pagesDiv = pages.map((new_page) => (
      <div
        className={
          page === new_page ? "pageNumberDivSelected" : "pageNumberDiv"
        }
        onClick={() => this.changePage(new_page)}
        key={new_page}
      >
        {new_page + 1}
      </div>
    ))

    return <div className="dashboardFullPageDiv">
      <NavbarContainer/>
      <div className="dashboardMainDiv">
        <PageHeader title="Payouts"
                    subtitle="View your Modern Angles payouts."
                    showNotifications={true}/>
        <div className="sectionCard">
          <div className="sectionCardHeader">
            <h5 className="photographersCardTitle">
              Your Payouts
            </h5>

            <div className="searchBarDiv invoicesSearch row">
              {this.props.currentUser.roles.admin && <div className="col-6 col-md-3">
                <input className="formInput" onChange={this.onSearchChange('searchUser')} type="text"
                       placeholder="User" value={this.state.searchUser}/>
              </div>}
              <div className="col-6 col-md-3">
                <input className="formInput" type="date" onKeyDown={(e) => e.preventDefault()}
                  onChange={this.onSearchChange('searchFrom')}
                  value={this.state.searchFrom} />
                <i className="fas fa-calendar calendarIcon" />
              </div>
              <div className="col-6 col-md-3">
                <input className="formInput" type="date" onKeyDown={(e) => e.preventDefault()}
                  onChange={this.onSearchChange('searchTo')}
                  value={this.state.searchTo} />
                <i className="fas fa-calendar calendarIcon" />
              </div>
              <div className={`col-12 col-md-3`}>
                <select
                  className="formInput"
                  onChange={this.onSearchChange('pageSize')}
                  value={this.state.pageSize}
                >
                  <option value={10}>10 per page</option>
                  <option value={20}>20 per page</option>
                  <option value={50}>50 per page</option>
                </select>
              </div>
            </div>
          </div>

          {this.state.loading ? <div className="invoiceLogoSpinner">
            <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
          </div> : <div className="sectionCardBody">
            <div className="table-responsive">
              <table className="table">
                <thead>
                <tr>
                    {this.props.currentUser.roles.contractor || this.props.currentUser.roles.sales_rep ? "" : <th>User</th>}
                    {this.props.currentUser.roles.sales_rep ? <th>Type</th> : <th>Photoshoots</th>}
                  <th>Amount</th>
                  <th>Status</th>
                  <th className="sortableHeading" onClick={() => this.setState({ regular: !this.state.regular })}>Date</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                  {this.state.payouts.map(payout => {
                    let projects = {}
                    payout.projects.forEach(project => {
                      projects[project.id] = true
                    })
                  return <tr key={payout.id}>
                    {this.props.currentUser.roles.contractor || this.props.currentUser.roles.sales_rep ? "" : <td>{payout.client_full_name}</td>}
                    {payout.addresses.length === 0 ? this.props.currentUser.roles.sales_rep ? <td>
                      {(() => {
                        const payoutDate = new Date(payout.created_at);
                        payoutDate.setMonth(payoutDate.getMonth() - 1);
                        return `${payoutDate.toLocaleString('default', { month: 'long' })} Milestones`;
                      })()}
                    </td> : <td>Referral Payout</td> :
                      payout.addresses.length === payout.projects.length ? <td>
                        {payout.projects.map((p, index) => <NavLink className="lookLikeLink" to={`/projects_index/${p.id}`}>{this.state.isLargeScreen ? p.address : p.id}{index !== payout.projects.length - 1 && <span>, </span>}</NavLink>)}
                      </td> :
                    <td>
                          {payout.addresses.map((a, index) => <NavLink className={projects[a.addressable_id] ? "lookLikeLink" : "lookLikeText"} to={projects[a.addressable_id] ? `/projects_index/${a.addressable_id}` : ""}>{this.state.isLargeScreen ? a.address : a.addressable_id}{index !== payout.addresses.length - 1 && <span>, </span>}</NavLink>)}
                    </td>
                    }
                    <td className="text-muted">${payout.amount}</td>
                    <td>{payout.payment_service_id ? "Paid" : "Unpaid"}</td>
                    <td>
                      {
                        this.props.currentUser.roles.sales_rep
                          ? (() => {
                            const payoutDate = new Date(payout.created_at);
                            payoutDate.setMonth(payoutDate.getMonth() - 1);
                            return `${payoutDate.getMonth() + 1}/${payoutDate.getFullYear().toString().slice(-2)}`;
                          })()
                          : new Date(payout.created_at).toLocaleDateString()
                      }
                    </td>
                  </tr>
                })}

                {this.state.payouts.length > 0 && (
                  <tr>
                    <td className="font-bold" colSpan={2}>Total</td>
                    <td className="font-bold">
                      ${this.state.payouts.reduce((res, i) => res + parseFloat(i.amount), 0).toFixed(2)}
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
              <div className="pagesDiv">
                <div className="pages-container">
                  <div className={page > 0 ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={() => page > 0 ? this.setState({ page: this.state.page - 1 }) : ""}>Previous</div>
                  {pagesDiv}
                    <div className={page + 1 != pageLast ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={() => page + 1 != pageLast ? this.setState({ page: this.state.page + 1 }) : ""}>Next</div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  }
}


const msp = (state) => ({
  currentUser: state.entities.users[state.session.currentUser.id]
})

export default withRouter(connect(msp)(Payouts))