import React from 'react';
import {TermsWrapper} from "./styles";

const TermsSection = ({tos}) => {
  return <TermsWrapper>
    <div dangerouslySetInnerHTML={{__html: tos.content}}/>
  </TermsWrapper>
};

export default TermsSection;
