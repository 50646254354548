import React, {useMemo, useState, useEffect} from 'react'
import dayjs from "dayjs";
import {formatDatetime} from "../../../../../util/date_util";
import leftImg from "../../../../../../../assets/images/scheduleDemo/left.svg";
import rightImg from "../../../../../../../assets/images/scheduleDemo/right.svg";
import {times} from "../../../../schedule_demo/utils";

export const CalendarForm = ({date, setDate, time, setTime}) => {
  const [displayedDatesCount, setDisplayedDatesCount] = useState(window.innerWidth <= 500 ? 5 : 6);
  const [displayedDates, setDisplayedDates] = useState(calculateDisplayedDates(date, displayedDatesCount))

  const currentTime = dayjs().add(1, 'hour');
  const currentDate = dayjs(date).format('YYYY-MM-DD');

  const checkIfTimePassed = (timeString) => {
    const scheduleTime = dayjs(`${currentDate} ${timeString}`, 'YYYY-MM-DD h:mm A');
    return currentTime.isAfter(scheduleTime);
  };

  useEffect(() => {
    const handleResize = () => {
      const newCount = window.innerWidth <= 400 ? 4 : window.innerWidth <= 1000 ? 5 : 6;
      if (newCount !== displayedDatesCount) {
        setDisplayedDatesCount(newCount);
        setDisplayedDates(calculateDisplayedDates(date, newCount));
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [date, displayedDatesCount]);

  function calculateDisplayedDates(date, count) {
    const dates = [date];

    for (let i = 1; i < count; i++) {
      dates.push(dayjs(date).add(i, 'days').toDate());
    }

    return dates;
  }

  const onSelectDate = (e, newDate) => {
    e.preventDefault()
    setDate(newDate)
    setTime('')
  }

  const onSelectTime = (e, time) => {
    e.preventDefault()
    setTime(time)
  }

  const onNext = (e) => {
    e.preventDefault()
    const nextDate = dayjs(date).add(displayedDatesCount, 'days').toDate()
    setDisplayedDates(calculateDisplayedDates(nextDate, displayedDatesCount))
    setDate(nextDate)
    setTime('')
  }

  const onPrev = (e) => {
    e.preventDefault();

    const today = dayjs().startOf('day');
    let prevDate = dayjs(date).subtract(displayedDatesCount, 'days');

    if (prevDate.isBefore(today)) {
      // If prevDate is before today, set to today's date
      prevDate = today.toDate();
    } else {
      // Otherwise, set to prevDate
      prevDate = prevDate.toDate();
    }

    setDisplayedDates(calculateDisplayedDates(prevDate, displayedDatesCount));
    setDate(prevDate);
    setTime('');
  }

  return <div className="apply-for-discount-calendar-wrapper">
    <div className="apply-for-discount-calendar-top">
      <div className="apply-for-discount-calendar-title">
        {formatDatetime(date, 'dddd, MMMM D, YYYY')}
      </div>

      <div className="apply-for-discount-calendar-navigation">
        <a href="#" onClick={onPrev}>
          <img src={leftImg}/>
        </a>

        <a href="#" onClick={onNext}>
          <img src={rightImg}/>
        </a>
      </div>
    </div>

    <div className="apply-for-discount-calendar-dates-selection">
      {displayedDates.map((d, i) => (
        <div className={`apply-for-discount-calendar-dates-selection-date-wrapper ${d === date ? 'active' : ''}`}
             key={`date-${i}`} onClick={e => onSelectDate(e, d)}>
          <div className="apply-for-discount-calendar-dates-selection-date">
            {d.getDate()}
          </div>
          <div className="apply-for-discount-calendar-dates-selection-date-weekday">
            {formatDatetime(d, 'ddd')}
          </div>
        </div>
      ))}
    </div>

    <div className="lead-page-divider"/>

    <div className="apply-for-discount-calendar-title apply-for-discount-calendar-times-title">
      Select time (shown in EST):
    </div>

    <div className="apply-for-discount-calendar-times-selection">
      {times.map((t, i) => (
        <div className={`apply-for-discount-calendar-time-item ${t === time ? 'active' : ''} ${checkIfTimePassed(t) ? 'time-passed' : ''}`}
          key={`times-${i}`}
          onClick={e => !checkIfTimePassed(t) && onSelectTime(e, t)}>
          {t}
        </div>
      ))}
    </div>
  </div>
}