import styled from 'styled-components';

export const Container = styled.div`
  width: 1090px;
  margin: 0 auto;
  @media only screen and (max-width: 1280px) {
    width: 100vw;
    margin-left: 0px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ContractMediaHeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ContractHeadingWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 1280px) {
    margin-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const ContractMediaHeading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #1b264f;
  text-transform: initial;
  @media only screen and (max-width: 1280px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const ContractMediaHeadingSpan = styled.span`
  color: #9baef1;
  display: inline-block;
  @media only screen and (max-width: 1280px) {
    display: contents;
  }
`;