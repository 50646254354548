import { connect } from "react-redux"
import MLSVideoShare from "./mls_video_share"
import React from "react"
import { withRouter } from "react-router-dom"


const msp = (state) => (
  {
    currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
  }
)

const mdp = (dispatch) => ({

})

export default withRouter(connect(msp, mdp)(MLSVideoShare))