import React, {useState} from "react";
import {ReactComponent as CloseIcon} from "../../../../../assets/images/adModal/icon-close.svg";
import {ReactComponent as LogoIcon} from '../../../../../assets/images/white-logo.svg'

export const PopupAdModal = (props) => {
  const [email, setEmail] = useState('')
  const {error, title, description, subscribing, button_text, imageUrl, onClose, show, onSubmit} = props

  const onEmailChange = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  return <>
    {show && <div className="modal-subscription-popup-background" />}

    <form
      className={`modal-subscription-popup ${show ? ' modal-subscription-show' : ''} ${imageUrl ? 'with-image' : ''}`}
      onSubmit={e => onSubmit(e, email)}>
      <div className="modal-subscription-inner">
        <div className="modal-subscription-image">
          {onClose && <div className="modal-subscription-close" onClick={onClose}>
            <CloseIcon/>
          </div>}

          <img src={imageUrl}/>
        </div>

        <div className="modal-subscription-content">
          {onClose && <div className="modal-subscription-close" onClick={onClose}>
            <CloseIcon/>
          </div>}

          <div className="modal-subscription-logo">
            <LogoIcon />
          </div>

          <div className="modal-subscription-title">
            {title}
          </div>

          {description && <div className="modal-subscription-description editorContent"
                               dangerouslySetInnerHTML={{__html: description}}/>}

          <div className="modal-subscription-email-and-button">
            <div className="modal-subscription-email">
              <label for="email">Email</label>
              <input value={email} onChange={onEmailChange} type="email" placeholder="Email Address"/>
              {error && <div className="formInputError">{error}</div>}
            </div>

            <div className="modal-subscription-buttons">
              <button className="projectEditButton" type="submit" disabled={subscribing}>
                {button_text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </>
}