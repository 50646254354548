import React from "react";
import { fetchUser } from "../../../util/users_api_util";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { updateContractorSubServices } from "../../../util/settings_api_util";


export default class SettingsServicePayouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.currentUser.roles.admin ? this.props.user_id : this.props.currentUser.id,
      user: null,
      loading: false,
      original_contractor_sub_services: {},
      new_contractor_sub_services: {},
    }
    this.changePayout = this.changePayout.bind(this)
    this.savePayouts = this.savePayouts.bind(this)
  }

  componentDidMount() {
    if (this.state.user_id) {
      this.setState({ loading: true })
      fetchUser(this.state.user_id).then(user => {
        let contractor_sub_services = user.contractor_sub_services.reduce((obj, subService) => {
          obj[subService.id] = subService;
          return obj;
        }, {})
        
        this.setState({
          user: user, loading: false, original_contractor_sub_services: JSON.parse(JSON.stringify(contractor_sub_services)), new_contractor_sub_services: contractor_sub_services })})
    }
  }

  changePayout(e, contractor_sub_service){
    let newState = Object.assign({}, this.state.new_contractor_sub_services);
    newState[contractor_sub_service.id]["payout"] = e.currentTarget.value
    this.setState({new_contractor_sub_services: newState})
  }

  findChangedSubServices(oldState, newState){
    const changedSubServices = {};

    Object.keys(newState).forEach(key => {
      if (JSON.stringify(oldState[key]) !== JSON.stringify(newState[key])) {
        changedSubServices[key] = newState[key];
      }
    });

    return changedSubServices;
  }

  savePayouts(){
    const changedSubServices = this.findChangedSubServices(this.state.original_contractor_sub_services, this.state.new_contractor_sub_services);
    const data = Object.values(changedSubServices)

    updateContractorSubServices(data).then(data => {
      this.props.showToast("Payouts have been updated")
    })
  }

  render() {

    const user = this.state.user

    if (this.state.loading || !user) {
      return (<div className="loaderDiv">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>)
    } else {
      let contractor_sub_services = Object.values(this.state.new_contractor_sub_services).sort((a, b) => {
          if (a.sub_service.service.name < b.sub_service.service.name) {
            return -1;
          }
          if (a.sub_service.service.name > b.sub_service.service.name) {
            return 1;
          }
          return 0;
      }).map(contractor_sub_service => {
          return <div className="contractorPayoutGroup">
            <div>{contractor_sub_service.sub_service.service.title} - {contractor_sub_service.sub_service.title}</div>
            <div className="">
              <input className="formInput"
                onChange={(e) => this.changePayout(e, contractor_sub_service)} type="number" placeholder="Payout"
                value={parseInt(contractor_sub_service.payout)} />
            </div>
          </div>
        });

      return (
        <div className="settingsPreferencesGroupingDiv">
          <div className="settingsPreferencesAdmin">
            <div className="settingsComponentHeading">Contractor Payouts</div>
            <div className="settingsPreferencesGroup1">
              <div className="contractorPayoutGroupUl">
                {contractor_sub_services}
              </div>
            </div>
            <button onClick={this.savePayouts} className="settingsButton preferencesButton settingsTimeSlotsButton">Save changes</button>
          </div>
        </div>
      );
    }
   }
  }