import React from 'react';
import {withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

import {BlueText, LocationsWrapper} from './LocationsStyles';
import MapSection from "../../locations_page_components/MapSection";
import LocationsHeader from "../../locations_page_components/LocationsHeader";
import {LocationsLogos} from "../../locations_page_components/LocationsLogos";
import PopularCities from "../../locations_page_components/PopularCities";
import CreativeMediaComponent from "../../pageComponents/CreativeMediaComponent";
import { TestimonialsComponent2, TestimonialsComponent } from '../../home_page/homeComponents';

const LocationsPage = (props) => {
  return (
    <LocationsWrapper>
      <Helmet>
        <title>Real Estate Photography Services Near You | Modern Angles</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <LocationsHeader/>
      <MapSection setModal={props.setModal} />
      <LocationsLogos/>
      <PopularCities setModal={props.setModal} />
      <CreativeMediaComponent buttonText="Schedule Shoot" setModal={props.setModal} bgColor={"#FFF"}
                              bgImage={false}
                              headingSize={'56px'}
                              mobilePadding="50px 25px">
        We make creative media that <BlueText> adds value </BlueText>
      </CreativeMediaComponent>
      {/* <Reviews/> */}
      <TestimonialsComponent2 needsMarginBottom={true}/>
    </LocationsWrapper>
  );
};

export default withRouter(LocationsPage);
