import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import arrowRight3 from "../../../../assets/images/dashboard/right.svg"


class ProjectDashboardItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.sendToProject = this.sendToProject.bind(this)
  }

  sendToProject(){
    this.props.history.push(`/projects_index/${this.props.project.slug}`)
  }

  render() {
    let project = this.props.project;
    let address = project.address
    return (
      <div className="projectDashboardItem" onClick={this.sendToProject}>
        {address.formatted_address ? <div className="projectDashboardItemSubheading"> {address.apartment_number || ""} {address.formatted_address}</div> : <div className="projectDashboardItemSubheading"> {address.street1}, {address.city}, {address.region} {address.zip_code}</div>}
        {address.formatted_address ? <div className="projectDashboardItemSubheadingMobile"> {address.apartment_number || ""} {address.formatted_address}</div> : <div className="projectDashboardItemSubheadingMobile"> {address.street1}, {address.region} {address.zip_code}</div>}
        <div className="projectDashboardItemSecondGrouping">
          <div className="projectDashboardDateString">{new Date(project.completed_at || project.created_at).toLocaleDateString("en-US")}</div>
          <img src={arrowRight3}></img>
        </div>
      </div>
    )
  }
}

export default withRouter(ProjectDashboardItem)