import { connect } from "react-redux";
import ForgotPassword from "./forgot_password";
import React from "react";
import { withRouter } from "react-router-dom"
import ConfirmationPath from "./confirmation_path";
import {fetchConfirmation, receiveSessionErrors} from "../actions/session_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
});

const mdp = (dispatch) => ({
  successError: (message) => dispatch(receiveSessionErrors(message)),
  fetchConfirmation: (user) => dispatch(fetchConfirmation(user))
});

export default withRouter(connect(msp, mdp)(ConfirmationPath));