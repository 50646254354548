import styled from 'styled-components';

export const PhotographyWrapper = styled.div`
  background-color: #ffffff;
  padding: 0px;
  margin: 0px;
  .fullServiceWrapper {
    @media screen and (max-width: 767px) {
      padding: 30px 0px;
    }
  }
`;
