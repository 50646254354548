import dayjs from "dayjs";

import { railsToJsTimezoneMap } from "./timezone_utils";

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const advanced = require("dayjs/plugin/advancedFormat")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export const formatDateIso = (date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const formatDatetime = (datetime, format = null, timezone = null) => {
  const finalFormat = format ?? "MMMM D, YYYY [at] h:mm A z";
  if (timezone){
    return dayjs(datetime).tz(railsToJsTimezoneMap[timezone]).format(finalFormat)
  } else {
    return dayjs(datetime).tz().format(finalFormat)
  }
}

export const formatTime = (time, timezone) => {
  return dayjs(time).tz(timezone).format("hh:mm A")
}
