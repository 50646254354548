import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { UpdateVirtualStagingBatch, AddNoteToVirtualPhoto } from "../../util/virtual_staging_api_util";


class VirtualStagingNoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      note: this.props.virtual_staging_photo?.notes || this.props.virtual_staging_purchase?.notes || "",
    }
    this.handleInput = this.handleInput.bind(this)
    this.submitNote = this.submitNote.bind(this)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  // submitNote() {
  //   let data = { id: this.props.virtual_staging_purchase.batch_id, virtual_staging_batch: { id: this.props.virtual_staging_purchase.batch_id, virtual_staging_purchases_attributes: {id: this.props.virtual_staging_purchase.id, notes: this.state.note} } }

  //   UpdateVirtualStagingBatch(this.props.virtual_staging_purchase.batch_id, data).then(() => {
  //     this.props.onClose()
  //     this.props.onReloadProject()
  //   })
  // }

  submitNote(){
    let data = {virtual_staging_photo_id: this.props.virtual_staging_photo.id, notes: this.state.note}

    AddNoteToVirtualPhoto(data).then(() =>{
      this.props.onClose()
      this.props.onReloadProject()
    })
  }

  render() {
    return (
      <div className="modal-background" onClick={() => this.props.onClose()}>
        <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading">Virtual Staging Note</div>
              <div className="closeIcon" onClick={() => this.props.onClose()}><i className="fas fa-times"></i></div>
            </div>
            <div className="settingsCategoryGroup1">
              <div className="settingsServicesInput">
                <label id="formLabel"></label>
                <textarea className="formTextArea" onChange={this.handleInput("note")} type="string" placeholder="Leave note here..." value={this.state.note} />
              </div>
            </div>
            <div className="reviewModalSubmitButton">
              <button onClick={this.submitNote} className="settingsButton">Submit</button>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(VirtualStagingNoteModal);