import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import debounce from 'debounce'


class BundleGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current_bundle: 0,
      errors: []
    }
    this.updateBundleSelection = this.updateBundleSelection.bind(this)
    this.increaseBundle = this.increaseBundle.bind(this)
    this.decreaseBundle = this.decreaseBundle.bind(this)
    this.selectBundleServices = this.selectBundleServices.bind(this)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
  }

  updateBundleSelection() {
    if (this.props.selected_bundle?.id != this.props.bundle_group.bundles[this.state.current_bundle].id) {
      let old_bundle = this.props.selected_bundle
      this.props.changeParentState({ selected_bundle: this.props.bundle_group.bundles[this.state.current_bundle], selected_service: this.props.bundle_group.bundles[this.state.current_bundle].sub_services[0].service_id })
      this.selectBundleServices(this.props.bundle_group.bundles[this.state.current_bundle].sub_services, old_bundle)
      this.debouceScrollTo('#bundleScroll')
    } else {
      this.props.changeParentState({ selected_bundle: {}, selected_service: -1, package_attributes: {}, selectedSubservices: {}, appointments: {}})
    }
  }

  scrollTo(selector) {
    const element = document.querySelector(selector);
    if (element) {
      const bounding = element.getBoundingClientRect();

      // Check if element is outside of visible viewport
      if (
        bounding.top < 0 ||
        bounding.left < 0 ||
        bounding.right > (window.innerWidth || document.documentElement.clientWidth) ||
        bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
      ) {
        element.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }

  selectBundleServices(sub_services, old_bundle) {
    let newState = {}
    let newStateSubservices = {}
    let newStateAppointments = {}

    if (this.props.updateBundle){
      newState = this.props.package_services
      newStateSubservices = this.props.selectedSubservices
      newStateAppointments = this.props.appointments

      old_bundle.sub_services.forEach(sub_service => {
        delete newState[sub_service.service_id]
        delete newStateSubservices[sub_service.id]
        delete newStateAppointments[sub_service.service_id]
      })

      sub_services.forEach(sub_service => {
        newState[sub_service.service_id] = { package_sub_services_attributes: { [sub_service.id]: { sub_service_id: sub_service.id } } }
        newStateSubservices[sub_service.id] = sub_service
      })

    } else {
      sub_services.forEach(sub_service => {
        newState[sub_service.service_id] = { package_sub_services_attributes: { [sub_service.id]: { sub_service_id: sub_service.id } } }
        newStateSubservices[sub_service.id] = sub_service
      })
      }
    this.props.changeParentState({ package_attributes: newState, selectedSubservices: newStateSubservices, appointments: newStateAppointments })
  }

  increaseBundle(e){
    e.stopPropagation()
    if (this.state.current_bundle === this.props.bundle_group.bundles.length - 1){
      this.setState({current_bundle: 0})
    } else {
      this.setState({ current_bundle: this.state.current_bundle + 1 })
    }
  }

  decreaseBundle(e){
    e.stopPropagation()
    if (this.state.current_bundle === 0) {
      this.setState({ current_bundle: this.props.bundle_group.bundles.length - 1 })
    } else {
      this.setState({ current_bundle: this.state.current_bundle - 1 })
    }
  }

  render() {
    const bundle_group = this.props.bundle_group

    let bundle = bundle_group.bundles[this.state.current_bundle]

    if (!bundle){
      return <div></div>
    }

    let selectedState = ""

    if (this.props.selected_bundle?.id === bundle.id) {
      selectedState = "bundleItemSelect"
    }

    let bundle_price = 0
    let original_price = 0

    bundle.sub_services.forEach(sub_service => {
      bundle_price += parseInt(this.props.location_pricing[sub_service.id] ? this.props.location_pricing[sub_service.id].price : sub_service.price)
      original_price += parseInt(this.props.location_pricing[sub_service.id] ? this.props.location_pricing[sub_service.id].price : sub_service.price)
    })

    bundle_price = bundle_price * (1 - (bundle.percent_discount / 100))
    let sub_services = {}

    bundle.sub_services.forEach(sub_service => sub_services[sub_service.id] = sub_service)

    let services = bundle.bundle_sub_services.map(bundle_sub_service => <li>{sub_services[bundle_sub_service.sub_service_id]?.bundle_description || sub_services[bundle_sub_service.sub_service_id]?.title}</li>)
   
    let extra_descriptions = bundle.extra_descriptions.map(extra_description => <li>{extra_description}</li>)


    return (
      <div onClick={this.updateBundleSelection} className="bundleItem" id={selectedState}>
        <img className="bundleImage" id={this.props.addon ? "addonBundleImage" : ""}src={bundle_group.header_image} />
        <div className="bundleTitle" id={this.props.addon ? "noPictureDiv" : ""}>{bundle.title}</div>
        <div className="bundleDescirption">{bundle.sub_title}</div>
        <div className={this.props.bundle_group.bundles.length > 1 ? "bundlePriceAndArrowGroup2" : "bundlePriceAndArrowGroup"}>
          {this.props.bundle_group.bundles.length > 1 && <div onClick={this.decreaseBundle} className="bundleArrow"><i className="fas fa-chevron-left"></i></div>}
          <div className="bundlePercentAndPriceGroup">
            <div className="bundlePrice">${Math.ceil(bundle_price)}</div>
            <div className="bundlePricesDiv">
              <div className="bundlePercentDiscount">{bundle.percent_discount}% OFF</div>
              <div className="oldBundlePrice">${original_price}</div>
            </div>
          </div>
          {this.props.bundle_group.bundles.length > 1 && <div onClick={this.increaseBundle} className="bundleArrow"><i className="fas fa-chevron-right"></i></div>}
        </div>
        {/* <div className="bundleListHeading">What's Included</div> */}
        <ul className="bundleUl">
          {services}
          {extra_descriptions}
        </ul>
        <div className="bundleServicesDescription">{bundle.services_description}</div>
      </div>
    )
  }
}

export default withRouter(BundleGroup);