import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import SubserviceItem from "./subserviceItem";

class ServiceSubServices extends React.Component {
  constructor(props) {
    super(props);
    this.toggleSelected = this.toggleSelected.bind(this);
  }

  toggleSelected(sub_service) {
    let newState = Object.assign({}, this.props.package_services);
    let newStateSubservices = Object.assign({}, this.props.selectedSubservices);
    let newStateAppointments = Object.assign({}, this.props.appointments);
    let newStatePreviouslyChosen = Object.assign({}, this.props.newStatePreviouslyChosen);
    let removed = false

    if (this.props.package_services[sub_service.service_id]) {
      if (newState[sub_service.service_id]["package_sub_services_attributes"]){
        if (newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id]){
          removed = true
          delete newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id]
          delete newStateSubservices[sub_service.id]
          if (Object.values(newState[sub_service.service_id]["package_sub_services_attributes"]).length === 0){
            delete newStateAppointments[sub_service.service_id]
            delete newState[sub_service.service_id]
            if (newStatePreviouslyChosen[sub_service.serivce_id]){
              delete newStatePreviouslyChosen[sub_service.service_id]
            }
          }
        } else {
          if (!this.props.addonServices && this.props.active_service.one_option_allowed){
            this.props.subservices.forEach((sub_service) => {
              if (this.props.selectedSubservices[sub_service.id]){ 
                delete newStateSubservices[sub_service.id]
                delete newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id]
              }
            })
          }
          newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id] = {sub_service_id: sub_service.id}
          newStateSubservices[sub_service.id] = sub_service
        }
      } else {
        newState[sub_service.service_id]["package_sub_services_attributes"] = {[sub_service.id]: {sub_service_id: sub_service.id}}
        newStateSubservices[sub_service.id] = sub_service
      }
    } else {
      newState[sub_service.service_id] = {package_sub_services_attributes: { [sub_service.id]: { sub_service_id: sub_service.id }}}
      newStateSubservices[sub_service.id] = sub_service
    }

    if (!this.props.addonServices && this.props.active_service.one_option_allowed && !removed && !this.props.upgradingPackage){
      this.props.changeParentState({
        package_attributes: newState, selectedSubservices: newStateSubservices, appointments: newStateAppointments, previouslyChosen: newStatePreviouslyChosen, selected_service: -1, currentlySelectedService: null, addMoreModal: null
      })
      setTimeout(() => {
        this.props.scrollTo(`#service_${this.props.active_service.id}`)
      }, 100);
    } else {
      this.props.changeParentState({
        package_attributes: newState, selectedSubservices: newStateSubservices, appointments: newStateAppointments, previouslyChosen: newStatePreviouslyChosen
      })
    }
  }

  render() {
    let subserviceLis = this.props.subservices?.filter(sub_service => !sub_service.promo) .filter(sub_service =>  this.props.addonServices ? sub_service.addon : !sub_service.addon).map((subservice) => <SubserviceItem key={subservice.id} location_pricing={this.props.location_pricing} subservice={subservice} selected={this.props.selectedSubservices[subservice.id] != null} toggleSelected={this.toggleSelected} /> ) || []

    return (
      <div className="subServicesComponent">
        <div className="subServiceGrouping">
          {subserviceLis}
        </div>
      </div>
    )
  }
}

export default withRouter(ServiceSubServices);
