import React from "react";
import {createUser} from "../../../util/settings_api_util";
import LocationComponent from "../../location_component";
import TimeZoneSelect from "../profile/time_zone_select";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";


export default class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      role: "client",
      password: "",
      phoneNumber: "",
      agentId: "",
      timezone: 'Eastern Time (US & Canada)',
      addressAttributes: {
        city: "",
        state: "",
        zipcode: "",
        street: "",
        apartment: "",
        longitude: "",
        latitude: "",
        formatted_address: ""
      },
      firms: [],
      firm: "",
      errors: [],
      backendErrors: [],
      loading: false,
    }

    this.handleInput = this.handleInput.bind(this)
    this.createNewUser = this.createNewUser.bind(this)
    this.onAddressChange = this.onAddressChange.bind(this)
  }

  componentDidMount() {
    this.props.fetchFirms().then(firms => this.setState({firms: firms}))
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value});
  }
  
  displayAddress() {
    return this.state.role === "contractor"
  }

  onAddressChange(change) {
    this.setState({addressAttributes: {...this.state.addressAttributes, ...change}})
  }

  createNewUser() {

    let errors = []

    if (this.state.firstName === "") {
      errors.push([1, "First name can't be blank."])
    }

    if (this.state.lastName === "") {
      errors.push([2, "Last name can't be blank"])
    }

    if (this.state.email === "") {
      errors.push([3, "Email can't be blank"])
    }

    if (this.state.password === "") {
      errors.push([4, "Password can't be blank"])
    }

    const phoneNumberPattern = /^(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/;

    if (!phoneNumberPattern.test(this.state.phoneNumber) && this.state.phoneNumber != "") {
      errors.push([5, 'Please enter a valid 10-digit phone number.']);
    }
    
    if (errors.length === 0) {
      const user = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.password,
        phone_number: this.state.phoneNumber,
        agent_mls_id: this.state.agentId,
        role_name: this.state.role,
        firm_id: this.state.firm,
        timezone: this.state.timezone
      }

      if (this.displayAddress()) {
        user.address_attributes = {
          city: this.state.addressAttributes.city,
          region: this.state.addressAttributes.state,
          zip_code: this.state.addressAttributes.zipcode,
          street1: this.state.addressAttributes.street,
          apartment_number: this.state.addressAttributes.apartment,
        }
      }
      this.setState({loading: true})
      createUser(user).then(user => {
        this.props.closeModal();
        this.props.onUserCreate(user)
      }).catch(result => {
        this.setState({backendErrors: [result.responseJSON], loading: false})
      })
    } else {
      this.setState({errors: errors})
    }
  }

  render() {
    let passwordErrorClass = "";
    let emailErrorClass = "";
    let firstNameErrorClass = "";
    let lastNameErrorClass = "";
    let phoneNumberErrorClass = "";
    let agentIdErrorClass = "";

    let passwordErrors = [];
    let firstNameErrors = [];
    let lastNameErrors = [];
    let phoneNumberErrors = [];
    let emailErrors = [];
    let otherErrors = [];
    let agentIdErrors = [];

    this.state.errors.forEach((error) => {
      if (error[0] === 1) {
        firstNameErrors.push(<li>{error[1]}</li>)
        firstNameErrorClass = "formInputError"
      }
      else if (error[0] === 2) {
        lastNameErrors.push(<li>{error[1]}</li>)
        lastNameErrorClass = "formInputError"
      }
      else if (error[0] === 3) {
        emailErrors.push(<li>{error[1]}</li>)
        emailErrorClass = "formInputError"
      }
      else if (error[0] === 4) {
        passwordErrors.push(<li>{error[1]}</li>)
        passwordErrorClass = "formInputError"
      }
      else if (error[0] === 5){
        phoneNumberErrors.push(<li>{error[1]}</li>)
        phoneNumberErrorClass = "formInputError"
      }
      else if (error[0] === 6) {
        otherErrors.push(<li>{error[1]}</li>)
      }
    })

    let brokerageDropdown = ""

    if (this.state.role === "broker" || this.state.role === "sub_broker" ){
      brokerageDropdown = <div className="settingsCompanyInput roleInput">
        <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Brokerage Firm</label>
        <select className="formInput" onChange={this.handleInput("firm")} name="firm" value={this.state.firm} >
          <option value="">No Firm</option>
          {this.state.firms.map(firm => <option value={firm.id}>{firm.name}</option>)}
        </select>
        <ul className="errorUl">{otherErrors}</ul>
      </div>
    }

    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">Create User</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>

          {this.state.backendErrors.length > 0 && (
            <div className="alert alert-danger mt-4">{this.state.backendErrors.join('. ')}</div>
          )}


          
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> First name </label>
              <input className="formInput" id={firstNameErrorClass} onChange={this.handleInput("firstName")} type="string" placeholder="First Name" value={this.state.firstName} />
              <ul className="errorUl">{firstNameErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel"> Last name </label>
              <input className="formInput" id={lastNameErrorClass} onChange={this.handleInput("lastName")} type="string" placeholder="Last Name" value={this.state.lastName} />
              <ul className="errorUl">{lastNameErrors}</ul>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> Email</label>
              <input className="formInput" id={emailErrorClass} onChange={this.handleInput("email")} type="email" placeholder="Email address" value={this.state.email} />
              <ul className="errorUl">{emailErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel"> Phone number </label>
              <input className="formInput" id={phoneNumberErrorClass} onChange={this.handleInput("phoneNumber")} type="tel" placeholder="Phone Number" value={this.state.phoneNumber} />
              <ul className="errorUl">{phoneNumberErrors}</ul>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="signupLeftInput">
              <label id="formLabel"> Password </label>
              <input className="formInput" id={passwordErrorClass} onChange={this.handleInput("password")} type="password" placeholder="Password" value={this.state.password} />
              <ul className="errorUl">{passwordErrors}</ul>
            </div>
            <div className="signupRightInput">
              <label id="formLabel"> Agent MLS ID (optional)</label>
              <input className="formInput" id={agentIdErrorClass} onChange={this.handleInput("agentId")} type="string" placeholder="Agent MLS ID" value={this.state.agentId} />
              <ul className="errorUl">{agentIdErrors}</ul>
            </div>
          </div>
          <div className="inputGrouping">
            <div className="w-100">
              <label id="formLabel"> Time Zone </label>
              <TimeZoneSelect value={this.state.timezone} onChange={this.handleInput("timezone")} />
            </div>
          </div>
          <div className="settingsCategoryGroup2">
            <div className="settingsCompanyInput roleInput">
              <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}> Role</label>
              <select className="formInput" onChange={this.handleInput("role")} name="Role" value={this.state.role} >
                <option value="" disabled={true}>Role</option>
                <option value="admin">Admin</option>
                <option value="broker">Broker</option>
                <option value="client">Client</option>
                <option value="contractor">Contractor</option>
                <option value="post_production">Post Production</option>
                <option value="sales_rep">Sales Rep</option>
                <option value="sub_broker">Sub Broker</option>
              </select>
            </div>
          {brokerageDropdown}
          </div>
          {this.displayAddress() && (
            <div className="settingsCategoryGroup2">
              <LocationComponent changeParentState={this.onAddressChange}
                                 title="User address"
                                 formatted_address={this.state.addressAttributes.formatted_address}
                                 street={this.state.addressAttributes.street}
                                 apartment={this.state.addressAttributes.apartment}
                                 zipcode={this.state.addressAttributes.zipcode}
                                 state={this.state.addressAttributes.state}
                                 city={this.state.addressAttributes.city}
                                 latitude={this.state.addressAttributes.latitude}
                                 longitude={this.state.addressAttributes.longitude} />
            </div>
          )}
          {this.state.loading ? <div className="">
            <img className="logoImageSinnerGifPayout" src={LogoSpinner}></img>
          </div> : <button onClick={this.createNewUser} className="settingsButton">Create User</button>}
        </div>
      </div>
    );
  }
}