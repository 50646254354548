import styled from 'styled-components';

export const ServiceDropdownWrapper = styled.div``;

export const ServiceDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 20px 50px;
  @media screen and (max-width: 990px) {
    padding: 10px 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 0px;
  }
`;

export const ServiceDropdownSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 70px 0px 30px 0px;
  cursor: pointer;
  position: relative;
  z-index: 550;
  .showOptionsContainer {
    height: 440px;
    @media screen and (max-width: 990px) {
      height: 370px;
    }
    @media screen and (max-width: 600px) {
      height: 250px;
    }
  }
  @media screen and (max-width: 990px) {
    min-width: 550px;
  }
  @media screen and (max-width: 767px) {
    padding: 60px 0px 0px 0px;
  }
  @media screen and (max-width: 600px) {
    min-width: 350px;
  }
`;

export const ServiceDropdownSelectedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  .arrowIcon {
    transform: rotate(180deg);
  }
`;

export const ServiceDropdownSelected = styled.h1`
  padding: 0px 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 58px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  margin-left: 38px;
  color: #2C5BFF;
  // Disable selection of text on clicking & opening dropdown
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media screen and (max-width: 990px) {
    font-size: 44px;
    line-height: 46px;
  }
  @media screen and (max-width: 600px) {
    font-size: 28px;
    line-height: 30px;
    padding: 0px 10px;
    margin-left: 22px;
  }
`;

export const ServiceDropdownIcon = styled.img`
  width: 38px;
  height: 11px;
  transform: rotate(0deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-top: -20px;
  @media screen and (max-width: 990px) {
    margin-top: -15px;
  }
  @media screen and (max-width: 600px) {
    min-width: 22px;
    width: 22px;
    height: 6px;
    margin-top: -15px;
  }
`;

export const ServiceOptionsContainer = styled.div`
  padding: 0px 100px;
  position: absolute;
  background-color: #ffffff;
  z-index: 10;
  border-radius: 0px 0px 12px 12px;
  right: 0;
  left: 0;
  top: 140px;
  text-align: end;
  height: 0px;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  align-content: flex-end;
  a {
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 77px;
    color: #526079;
    @media screen and (max-width: 990px) {
      font-size: 26px;
      line-height: 35px;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
  @media screen and (max-width: 990px) {
    padding: 0px 65px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 53px;
    top: 95px;
  }
`;

export const ServiceContentExtraPadding = styled.p`
  padding: 0px 240px 0px 240px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  text-align: center;
  color: #353844;
  cursor: pointer;
  a {
    color: #9baef1;
  }
  @media screen and (max-width: 1400px) {
    padding: 0px 110px 0px 110px;
  }
  @media screen and (max-width: 990px) {
    font-size: 20px;
    line-height: 24px;
    padding: 0px 30px 0px 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 20px;
    padding: 0px 0px 0px 0px;
    font-weight: 600;
  }
`;

export const ServiceContent = styled.p`
  padding: 0px 155px 0px 155px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  text-align: center;
  color: #353844;
  @media screen and (max-width: 1400px) {
    padding: 0px 90px 0px 90px;
  }
  @media screen and (max-width: 990px) {
    font-size: 20px;
    line-height: 24px;
    padding: 0px 30px 0px 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    padding: 0px 0px 0px 0px;
  }
`;

export const ServiceBackDrop = styled.div`
  position: fixed;
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
