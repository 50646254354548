import {connect} from "react-redux"
import PropertyTourMain from "./property_tour_main"
import React from "react"
import {withRouter} from "react-router-dom"
import {showToast} from "../../actions/toast_actions";

const msp = (state, ownProps) => (
  {
    currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
  }
)

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
})

export default withRouter(connect(msp, mdp)(PropertyTourMain))