import React from "react"
import { updateProject } from "../../util/project_api_util"
import { withRouter } from "react-router-dom";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";

class RemoveBundleModal extends React.Component {
  constructor(props) {
    super(props)
    // this.createRefund = this.createRefund.bind(this)
    // this.createAccountCredit = this.createAccountCredit.bind(this)
    this.confirmChange = this.confirmChange.bind(this)

    this.state = {
      errors: [],
      loading: false,
      confirmModal: false,
      refund: false,
    }
  }

  // createRefund() {
  //   this.removePackage(true)
  // }

  // createAccountCredit() {
  //   this.removePackage()
  // }

  confirmChange(refund) {
    if (refund) {
      this.setState({ confirmModal: true, refund: true })
    } else {
      this.setState({ confirmModal: true, refund: false })
    }
  }

  removeBundle(refund = false) {
    const { project, bundle, onServiceRemoved } = this.props

    const subServices = {}
    
    bundle.sub_services.forEach(sub_service => subServices[sub_service.id] = true)

    project.add_on_sub_services.forEach(sub_service => subServices[sub_service.id] = true)

    const packages = Object.values(project.packages).filter(pack => {
      return pack.package_sub_services.find(pss => subServices[pss.sub_service_id])
    })

    const packages_attributes = packages.map(pack => {
      return {
        ...pack,
        _destroy: pack.package_sub_services.length === 1,
        package_sub_services_attributes: pack.package_sub_services.reduce((res, pss) => {
          res[pss.sub_service_id] = subServices[pss.sub_service_id] ? { id: pss.id, _destroy: true } : pss
          return res
        }, {})
      }
    })

    const data = {
      refund: refund,
      removed_bundle: true,
      packages_attributes
    }

    this.setState({ errors: [], loading: true })

    updateProject(data, this.props.project.id).then(project => {
      if (project.errors) {
        this.setState({ errors: project.errors, loading: false })
      } else {
        onServiceRemoved()
        this.props.fetchCurrentUser(this.props.currentUser.id)
      }
    })
  }

  render() {
    const { onClose, project, bundle} = this.props

    // const bundleSubService = bundle.sub_services[0]

    // let invoice_array = Object.values(project.invoices).filter(invoice => invoice.sub_services.includes(bundleSubService.id))

    // let invoice = ""

    // if (invoice_array.length === 1) {
    //   invoice = invoice_array[0]
    // }
    // else {
    //   invoice = invoice_array[0]

    //   invoice_array.forEach(inv => {
    //     if (new Date(inv.created_at) > new Date(invoice.created_at)) {
    //       invoice = inv
    //     }
    //   })
    // }

    let invoice_amount = 0;
    let invoice_paid = 0;
    let invoice_account_credit = 0;

    Object.values(project.invoices).forEach(invoice => {
      if (invoice.invoiceable_type === "Project") {
        invoice_amount += parseInt(invoice.total);
        invoice_paid += parseInt(invoice.paid)
        invoice_account_credit += parseInt(invoice.account_credit)
      }
    })

    let modal = ""

    if (this.state.confirmModal) {
      modal = <div className="modal-background sub-modal-background" onClick={() => this.setState({ confirmModal: false })}>
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="contractorModalHeading">
              <div className="confirmationModalDescription">Are you sure you want to make this change?</div>
            </div>
            <div className="confirmationTimeSlotsButtons">
              <div onClick={() => this.setState({ confirmModal: false })} className="signupFormPrevious">Cancel</div>
              <div onClick={() => this.removeBundle(this.state.refund)} className="signupFormNext" id={this.state.selectedTime === "" ? 'disabled' : ''}>Apply</div>
            </div>
          </div>
        </div>
      </div>
    }

    if (this.state.loading) {
      modal = <div className="modal-background sub-modal-background" onClick={() => this.setState({ confirmModal: false })}>
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="loaderDiv">
              <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
            </div>
          </div>
        </div>
      </div>
    }

    if (parseInt(invoice_paid) === 0 && parseInt(invoice_account_credit) === 0) {
      return <div className="modal-background">
        {modal}
        <div className="orderpreviewModal refundModal">
          <div className="orderPreviewModalComponent">
            <div>
              <button className="projectEditButton projectEditButtonLight modalCloseButton" onClick={onClose}>
                Close
              </button>
              <div className="refundModalTitle">Refund Details</div>
              <div className="refundModalText">
                We're sorry to hear that you are planning to cancel your package. Feel free to reach out to <a href="mailto:info@modernangles.com">info@modernangles.com</a> if you have any questions.
              </div>
              <div className="refundModalBox">
                <button className="projectEditButton projectEditButtonPrimary mb-3 ms-0 w-100"
                  onClick={e => this.confirmChange(false)} disabled={this.state.loading}>
                  Cancel Bundle
                </button>
                <button className="projectEditButton projectEditButtonWhite w-100" onClick={onClose}>
                  I no longer wish to cancel this service
                </button>
              </div>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    } else {
      let bundle_price = this.props.project.bundle_price

      
      
      return <div className="modal-background">
        {modal}
        <div className={`orderpreviewModal refundModal`}>
          <div className="orderPreviewModalComponent">
            <div>
              <button className="projectEditButton projectEditButtonLight modalCloseButton" onClick={onClose}>
                Close
              </button>

              <div className="refundModalTitle">Refund Details</div>

              <div className="refundModalText">
                We're sorry to hear that you are planning to cancel your bundle.
                <br />
                Based on our refund policy we can offer you two options.
              </div>

              <div className="refundModalSubTitle">
                Direct Refund
              </div>

              <div className="refundModalText">
                If you would like a direct refund there will be a 5% fee based on processing costs.
              </div>

              <div className="refundModalSubTitle">Account Credit</div>

              <div className="refundModalText">
                Account credit can be chosen instead which will be automatically applied to a future purchase. With
                account credit there is no fees so you will receive the full refund amount in credit.
              </div>

              <div className="refundModalBox">
                <div className="refundModalSubTitle mb-3">Choose the option which works best for you:</div>
                <button className="projectEditButton projectEditButtonPrimary mb-3 ms-0 w-100"
                  onClick={() => this.confirmChange(false)} disabled={this.state.loading}>
                  Account Credit: ${(bundle_price * 1).toFixed(2)}
                </button>
                <button className="projectEditButton projectEditButtonPrimary mb-3 w-100"
                  onClick={() => this.confirmChange(true)} disabled={this.state.loading}>
                  Refund: ${invoice_paid >= bundle_price * .95 ? (bundle_price * 0.95).toFixed(2) : `${(invoice_paid * .95).toFixed(2)} + $${(bundle_price - invoice_paid).toFixed(2)} account credit`}
                </button>
                <button className="projectEditButton projectEditButtonWhite w-100" onClick={onClose}>
                  I no longer wish to cancel this bundle
                </button>
              </div>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    }
  }
}

export default withRouter(RemoveBundleModal)