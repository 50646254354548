import React from 'react';
import { Container } from 'reactstrap';
import { useInView } from 'react-intersection-observer';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import {
  CreativeServicesContainer,
  CreativeServices,
  MoveReal,
  Empower,
  ScheduleShootButton,
} from '../homeStyle';

import FadeInAnimation from './FadeInAnimation';
import {ROUTES} from "../../../routes";

const CreativeServicesComponent = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const animationConfig = {
    duration: 0.6,
    delayOrder: 1,
    yOffset: -20,
    inView
  };

  const onGetStarted = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  return (
    <div ref={ref}>
      <CreativeServicesContainer>
        <Container>
          <FadeInAnimation className="w-100"
            {...animationConfig}
          >
            <CreativeServices>Transform Your Listing</CreativeServices>
            <MoveReal>with Real Estate Photography</MoveReal>
          </FadeInAnimation>
          <FadeInAnimation className="w-100"
            {...animationConfig} 
            delayOrder={2}
          >
            <Empower>Get Photos, Videos & Floor Plans in 24 Hours</Empower>
          </FadeInAnimation>
          <FadeInAnimation className="w-100"
            {...animationConfig}
            delayOrder={3}
          >

            <div className="d-flex gap-3 justify-content-center">
              <div onClick={onGetStarted}>
                <ScheduleShootButton>Schedule Shoot</ScheduleShootButton>
              </div>
            </div>

            <Link to={ROUTES.CONTACTS} className="speak-to-our-team-link">Speak to our team.</Link>
          </FadeInAnimation>
        </Container>
      </CreativeServicesContainer>
    </div>
  );
};

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  openNewModal: () => {
    dispatch(openProjectModal());
  },
  closeModal: () => {
    dispatch(closeProjectModal());
  },
});

export default withRouter(connect(msp, mdp)(CreativeServicesComponent));
