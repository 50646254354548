import React from 'react'
import essentialImg from '../../../../../assets/images/landing/professionalBundles/essential.png'
import premiumImg from '../../../../../assets/images/landing/professionalBundles/premium.png'
import luxuryImg from '../../../../../assets/images/landing/professionalBundles/luxury.png'
import {useInView} from "react-intersection-observer";
import FadeInAnimation from "../../home_page/homeComponents/FadeInAnimation";

const bundles = [
  {
    title: 'Essential Package',
    description: 'Great photos and detailed floor plans are a perfect gateway to get buyers through the door.',
    list: [
      'Save 15%',
      '35 High-Quality Photos',
      'Complete 2D Floor Plan',
      'Dedicated Listing Website',
    ],
    img: essentialImg
  },
  {
    title: 'Luxury Package',
    description: 'Did you just land an awesome listing? If you’re going all out marketing, this bundle is for you.',
    list: [
      'Save 20%',
      '35 High-Quality Photos',
      'Complete 2D Floor Plan',
      'Drone/Aerial Capture (5-10 photos)',
      'Walkthrough Video (1-2 minutes)',
      'Dedicated Listing Website'
    ],
    img: luxuryImg
  },
  {
    title: 'Premium Package',
    description: 'Great photos, floor plans and drone photos allow you to capture all your listing visuals.',
    list: [
      'Save 15%',
      '35 High-Quality Photos',
      'Complete 2D Floor Plan',
      'Drone/Aerial Capture (5-10 photos)',
      'Dedicated Listing Website'
    ],
    img: premiumImg
  }
]

export const ProfessionalBundles = ({setModal}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const onOpenModal = (e) => {
    e.preventDefault()
    setModal(true)
  }

  return <div className="professional-bundles-wrapper" ref={ref}>
    <FadeInAnimation
      duration={0.4}
      delayOrder={0}
      yOffset={-20}
      inView={inView}>
      <div className="professional-bundles-wrapper-list">
        {bundles.map((bundle, i) => (
          <div className="professional-bundles-wrapper-card"
               key={`professional-bundle-${i}`}>
            <div className="professional-bundles-wrapper-card-container">
              <div className="professional-bundles-wrapper-card-image">
                <img src={bundle.img}/>
              </div>

              <div className="professional-bundles-wrapper-card-title">
                {bundle.title}
              </div>

              <div className="professional-bundles-wrapper-card-subtitle">
                {bundle.description}
              </div>
            </div>

            <div className="professional-bundles-wrapper-card-divider"/>

            <div
              className="professional-bundles-wrapper-card-container justify-content-between d-flex flex-column flex-fill">
              <div className="professional-bundles-wrapper-card-list-container">
                <ul className="professional-bundles-wrapper-card-list">
                  {bundle.list.map((item, j) => (
                    <li key={`professional-bundle-${i}-list-${j}`} className={j === 0 ? 'bold' : ''}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              <button className="projectEditButton professional-bundles-wrapper-card-button" onClick={onOpenModal}>
                Order Now
              </button>
            </div>
          </div>))}
      </div>
    </FadeInAnimation>
  </div>
}