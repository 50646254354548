import React from "react"
import { ThemeConsumer } from "styled-components"
import { debounce } from "debounce";
import trashIconSrc from "../../../../assets/images/icons/fi_trash.svg"
import { fetchAllSubBrokers } from "../../util/sub_brokers_api_util";
import { fetchUsersNameAndIdOnly } from "../../util/users_api_util";
import Select from 'react-select'


export default class VirtualStagingCheckout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      users: {},
      search: "",
      loading: false,
    }
    this.changeClient = this.changeClient.bind(this)
    this.fetchUsersForDropdown = this.fetchUsersForDropdown.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.debounceFetchUsersForDropdown = debounce(() => this.fetchUsersForDropdown(), 500)
  }

  componentDidMount() {
  }

  componentDidUpdate(_, prevState) {
    if (prevState.search !== this.state.search && this.state.search != "" && this.state.search.length > 2) {
      this.debounceFetchUsersForDropdown()
    }
  }

  onSearchChange(newValue) {
    this.setState({ search: newValue })
  }

  fetchUsersForDropdown() {
    if (this.props.currentUser?.roles?.admin && this.state.search != "") {
      this.setState({ loading: true })
      fetchUsersNameAndIdOnly(this.state.search).then(users => {
        this.setState({ users: users })
        this.setState({ loading: false })
      })
    }
    else if (this.props.currentUser?.roles?.broker) {
      this.setState({ loading: true })
      fetchAllSubBrokers().then(subBrokers => {
        this.setState({ users: subBrokers })
        this.setState({ loading: false })
      })
    }
  }

  changeClient(e) {
    if (e.value === "") {
      this.props.changeParentState({ client_id: "", client_role: "", account_credit: null, account_credit_id: null, firm: null })
    } else {
      if (this.state.users[e.value].firm?.broker_charge || this.props.currentUser.roles.broker) {
        this.props.changeParentState({ client_id: e.value, client_role: this.state.users[e.value].role, firm: this.state.users[e.value].firm, account_credit: null, account_credit_id: "" })
      } else {
        this.props.changeParentState({ client_id: e.value, client_role: this.state.users[e.value].role, firm: this.state.users[e.value].firm, account_credit: this.state.users[e.value].account_credit?.amount || null, account_credit_id: this.state.users[e.value].account_credit?.id || "" })
      }
    }
  }

  filepath(photo) {
    const urlParts = photo.url.split('/')
    let url = urlParts[urlParts.length - 1]
    return url.split('?')[0]
  }

  render() {
    let price = this.props.detailed_orders.reduce((res, order) => {
      return res + Object.values(order.selected_photos).length * order.category.price
    }, 0)

    price += ((this.props.category?.price || 0) * Object.values(this.props.selected_photos_with_info).length)

    let discount = 0;
    let account_credit = this.props.account_credit;
    let account_credit_component = "";



    if (this.props.account_credit && price > 0) {
      if (this.props.account_credit >= price) {
        account_credit = this.props.account_credit - price
        price = 0
        discount = this.props.account_credit - account_credit
      } else {
        price = price - this.props.account_credit
        discount = this.props.account_credit
        account_credit = 0
      }
    }

    if (discount > 0) {
      account_credit_component = <tr className="section-top">
        <td />
        <td colSpan={3}>Account credit applied: </td>
        <td colSpan={2}>-${discount.toFixed(2)}</td>
      </tr>
    }


    let checkout = ""

    if (this.props.checkout) {
      checkout = "checkoutStyle"
    }

    let currentOrder = ""
    let previousOrders = ""
    if(this.props.preview){
      previousOrders = this.props.detailed_orders.map((order, i) => {
          return <tr key={`order-${i}`} className="">
            <td className="shortenedName">{order.category.name}</td>
            <td className="shortenedName">{order.product.name}</td>
            <td className="">{Object.values(order.selected_photos).length}</td>
            <td className="text-muted">${parseInt(order.category.price * Object.values(order.selected_photos).length).toFixed(2)}</td>
          </tr>
        })
    } else {
      previousOrders = this.props.detailed_orders.map((order, i) => {
        return Object.values(order.selected_photos).map((photo, j) => {
          const isLastRow = i === this.props.detailed_orders.length - 1 &&
            j === Object.values(order.selected_photos).length - 1

          return <tr key={`order-${i}-${j}`} className={isLastRow ? "section-bottom" : ""}>
            <td className="">{order.category.name}</td>
            <td className="">{order.product.name}</td>
            <td className="text-truncate tuncated-text">{this.filepath(photo)}</td>
            <td className="text-muted d-flex">${parseInt(order.category.price).toFixed(2)}  <img src={trashIconSrc} className="trashIcon"
              onClick={() => this.props.deleteOrderPhoto(i, j)} /></td>
          </tr>
        })
      })
    }

    if (Object.values(this.props.selected_photos_with_info).length === 0){
    currentOrder = <tr key={`order-new`} className={""}>
      <td className="shortenedName">{this.props.category?.name}</td>
      <td className="shortenedName">{this.props.product?.name}</td>
      <td className=""></td>
      <td className="text-muted"></td>
    </tr>
    } else {
      currentOrder = 
       <tr key={'current_order'} className={""}>
          <td className="shortenedName">{this.props.category?.name}</td>
          <td className="shortenedName">{this.props.product?.name}</td>
          <td className="">{Object.values(this.props.selected_photos_with_info).length}</td>
          <td className="text-muted">${parseInt(((this.props.category?.price || 0) * Object.values(this.props.selected_photos_with_info).length)).toFixed(2)}</td>
        </tr>
    }

    let assign_to_client = ""

    const brokersOptions = [{ value: "", label: "Assign to different sub-broker (optional)" }]
    const usersOptions = [{ value: "", label: "Assign to different user (optional)" }]

    if (this.props.currentUser?.roles?.broker) {
      Object.values(this.state.users).forEach(subBroker => {
        brokersOptions.push({ value: subBroker.id, label: `${subBroker.firstName} ${subBroker.lastName}` })
      })

      assign_to_client = <Select className="ml-auto orderSummarySubBrokerSelect" isLoading={this.state.loading} defaultValue={{ value: "", label: "Assign to Sub-Broker (optional)" }} options={brokersOptions} onChange={this.changeClient} />
    }

    if (this.props.currentUser?.roles?.admin) {
      Object.values(this.state.users).forEach(user => {
        usersOptions.push({ value: user?.id, label: `${user?.email} - ${user?.role} - ${user?.account_credit?.amount || 0}` })
      })
      assign_to_client = <Select className="ml-auto orderSummarySubBrokerSelect" isLoading={this.state.loading} defaultValue={this.props.sub_broker_client ? { value: this.props.sub_broker_client, label: this.props.sub_broker_client_info?.email } : { value: "", label: "Assign to User (optional)" }} options={usersOptions} onChange={this.changeClient} onInputChange={(newValue) => this.onSearchChange(newValue)} />
    }

    if (this.props.preview){
      assign_to_client = ""
    }

    let orderSummaryClassName = "orderSummaryComponent"

    if (this.props.homepage){
      orderSummaryClassName = "orderSummaryComponent orderSummaryComponentHomepage"
    }

    return (
      <div className={this.props.checkout ? "" : "stickySometimes"}>
        <div id={checkout} className={orderSummaryClassName}>
        <div className="modalTitle">{this.props.preview ? "Summary" : "Checkout"}</div>
        {this.props.preview ? "" : <div className="modalSubTitle">Below is a list of photos that would go to virtual staging</div>}

        <table className="w-100 mt-4 editProjectServicesTable" id={this.props.preview ? "previewView" : ""}>
          <thead className="tableHeadVirtualStaging">
            <tr>
              <th>Category</th>
              <th>Product</th>
              {this.props.preview ? <th>Photos</th> : <th>Photo</th>}
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {previousOrders}
            {currentOrder}
            {account_credit_component}

            <tr className="section-top">
              <td />
              <td colSpan={2}>Total</td>
              <td colSpan={1}>${price.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        {assign_to_client}
      </div>
      <div>
        
      </div>
      </div>
    )
  }
}