import { RECEIVE_CONTRACTORS } from "../actions/service_actions";

const contractorsReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_CONTRACTORS:
      return action.contractors
    default:
      return state;
  }
};

export default contractorsReducer;