import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { fetchBlogCategories, fetchBlogs } from "../../util/blog_api_utils";
import authorProfileImg from "../../../../assets/images/MA_Logo_New_2.svg";
import { showToast } from "../../actions/toast_actions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import BlogCategories from "../settings/blogs/blog_categories";
import { Helmet } from 'react-helmet-async';


const BlogIndex = (props) => {
  const [blogs, setBlogs] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState({})
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(0)
  const [shown_blogs, setShownBlogs] = useState([])
  const [largeScreen, setLargeScreen] = useState(true)
  const breakpoint = 700;
  const [collapsed, setCollapsed] = useState(true)
  const [amountPerPage, setAmountPerPage] = useState(true)


  useEffect(() => {
    window.addEventListener("resize", () => setLargeScreen(window.innerWidth > breakpoint));
    setLargeScreen(window.innerWidth > breakpoint)
  }, [])

  useEffect(() => {
    const amount = largeScreen ? 11 : 10
    setAmountPerPage(amount)
  }, [largeScreen])

  useEffect(() => {
    fetchBlogs().then(blogs => {
      setBlogs(blogs)
      setShownBlogs(blogs)
    })
  }, [])

  useEffect(() => {
    fetchBlogCategories().then(categories => setCategories(categories))
  }, [])

  useEffect(() => {
    if (blogs.length > 0){
      let new_shown_blogs = search === "" ? blogs : blogs.filter(blogs => blogs.title.toLowerCase().includes(search.toLowerCase()))
      if (Object.values(selectedCategories).length > 0) {
        new_shown_blogs = new_shown_blogs.filter(blog => selectedCategories[blog.blog_category_id])
      }
      setShownBlogs(new_shown_blogs)
    }
  }, [selectedCategories])

  useEffect(() => {
    displaySearchBlogs(blogs, search)
  }, [search])

  useEffect(() => {
    setPage(0)
  }, [search, selectedCategories])

  const increasePage = () => {
    setPage(page + 1)
  }

  const decreasePage = () => {
    setPage(page - 1)
  }

  const changePage = (selectedPage) => {
    if (selectedPage === "..."){
      setPage(page + 3)
    }else {
      setPage(selectedPage - 1)
    }
  }

  const displaySearchBlogs = useCallback(
      debounce((blogs, search) => {
        const newShownBlogs = search === "" ? blogs : blogs.filter(blogs => blogs.title.toLowerCase().includes(search.toLowerCase()))
        setShownBlogs(newShownBlogs)
        setSelectedCategories({})
      }, 500),
  [])
 

  const onCategoryClick = (category_id) => {
    if (category_id === "View All"){
      setSelectedCategories({})
    } else if (selectedCategories[category_id]){
      let newSelectedCategories = Object.assign({}, selectedCategories)
      delete newSelectedCategories[category_id]
      setSelectedCategories(newSelectedCategories)
    } else {
      let newSelectedCategories = Object.assign({}, selectedCategories)
      newSelectedCategories[category_id] = true
      setSelectedCategories(newSelectedCategories)
    }
  }


  const onSearchChange = (e) => {
      e.preventDefault()
      setSearch(e.target.value)
  }

  let shown_blogs_divs = shown_blogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(page*amountPerPage, page*amountPerPage+amountPerPage).map(blog => {
    let blog_date = new Date(blog.created_at)
    const author = {
      name: 'Modern Angles',
      description: `${ blog_date.toLocaleString('default', { month: 'short' }) } ${ blog_date.getDate()}, ${ blog_date.getFullYear() }`,
      avatar: authorProfileImg
    }

    let blog_author = <div className="blog-author blog-author-index">
      <div className="blog-author-avatar">
        <img src={author.avatar} />
      </div>
      <div className="blog-author-name-container">
        <div className="blog-author-name">{author.name}</div>
        <div className="blog-author-description">{author.description}</div>
      </div>
    </div>

    if (shown_blogs[page*amountPerPage].id === blog.id && largeScreen){
      return <div className="blog-main-thumbnail" onClick={() => props.history.push(`/blog/${blog.slug}`)}>
        <div className="blog-main-thumbnail-header-image-div">
          <img className="blog-main-thumbnail-header-image" src={blog.header_image} />
        </div>
        <div className="blog-main-thumbnail-header-title-container">
          <div className="blog-main-thumbnail-category-read-length-div">
            <div className="blog-main-thumbnail-header-category">{blog.category}</div>
            <div className="blog-main-thumbnail-header-time"> {blog.time_to_read} min read </div>
          </div>
          <div>
            <div className="blog-main-thumbnail-header-title">{blog.title}</div>
            <div className="blog-main-thumbnail-header-headline">{blog.description}</div>
          </div>
          {blog_author}
        </div>
      </div>
    } else {
      return <div className="blog-thumbnail" onClick={() => props.history.push(`/blog/${blog.slug}`)}>
        <div className="blog-thumbnail-header-image">
          <img className="blog-thumbnail-header-image" src={blog.header_image} />
        </div>
        <div className="blog-index-blog-header-title-container">
          <div>
            <div className="blog-main-thumbnail-category-read-length-div">
              <div className="blog-main-thumbnail-header-category">{blog.category}</div>
              <div className="blog-main-thumbnail-header-time"> {blog.time_to_read} min read </div>
            </div>
            <div className="blog-main-thumbnail-header-title">{blog.title}</div>
            <div className="blog-main-thumbnail-header-headline">{blog.description}</div>
          </div>
          {blog_author}
        </div>
      </div>
    }
  })

  let pageLast = 1

  if (shown_blogs.length > amountPerPage){
    pageLast = shown_blogs.length % amountPerPage > 0 ? parseInt(shown_blogs.length / amountPerPage) + 1 : parseInt(shown_blogs.length) / amountPerPage
  }

  let pages = [];
  const startPage = Math.max(page - 5, 0);
  const endPage = Math.min(startPage + 9, pageLast - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i+1);
  }

  let pagesDiv = pages.map(new_page =>{
    return <div className={page + 1 === new_page ? "pageNumberDivSelected" : "pageNumberDiv"} onClick={() => changePage(new_page)}>{new_page}</div>
  })

  let blog_categories_fields = <fieldset className="blog-index-blog-category-fieldset">
    <legend className="blog-index-blog-category-legend">Blog Categories</legend>

    <div className="blog-index-checkbox" id={Object.values(selectedCategories).length === 0 ? "blog-index-checkbox-selected" : ""}>
      <input className="blog-index-checkbox-input" type="checkbox" name="" onChange={() => onCategoryClick("View All")} checked={Object.values(selectedCategories).length === 0} />
      <label className="blog-index-checkbox-label">View All</label>
    </div>

    {categories.map((category, i) => {
      return <div className="blog-index-checkbox" id={selectedCategories[category.id] ? "blog-index-checkbox-selected" : ""}>
        <input className="blog-index-checkbox-input" type="checkbox" name={category.title} onChange={() => onCategoryClick(category.id)} checked={selectedCategories[category.id]} />
        <label className="blog-index-checkbox-label">{category.title}</label>
      </div>
    })}
  </fieldset>

  let categoryDropdown = ""

  if (!largeScreen && collapsed){
    categoryDropdown = <div className="categoryDropdown">
      <div onClick={() => setCollapsed(false)} className="categoryDropdownText categoryDropdownTextUnopened">Categories</div>
    </div>
  } else if (!largeScreen && !collapsed){
    categoryDropdown = <div className="categoryDropdown">
      <div onClick={() => setCollapsed(true)} className="categoryDropdownText categoryDropdownTextOpened">Categories</div>
      {blog_categories_fields}
    </div>
  }



  return(
    <div className="blog-index">
      <Helmet>
        <title>Explore Insights and Tips for Real Estate Photography & Marketing | Modern Angles Blog</title>
        <meta
          name="description"
          content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
        <div className="blog-index-header blog-border-bottom">
          <div className="blog-index-header-title-container">
            <div className="blog-index-header-title">Our Blog</div>
            <div className="blog-index-header-subtitle">Resources and insights</div>
            <div className="blog-index-header-headline">The latest industry news, interviews, technologies,</div>
            <div className="blog-index-header-headline">and resources.</div>
            <div className="blog-index-header-headline-mobile">The latest industry news, interviews, technologies and resources.</div>
          </div>
        </div>
        <div className="blog-index-grouping">
          <div className="blog-index-navigation">
            <input className="blog-index-searchbar" name="searchBlogs" placeholder="Search" value={search} onChange={onSearchChange} />
            {largeScreen ? "" : categoryDropdown}
            {largeScreen ? blog_categories_fields : ""}
          </div>
            <div className="blog-content-container">
              <div className="blog-index-content blog-border-bottom">
                <div className="blogs">
                  {shown_blogs_divs}
                </div>
              </div>
              <div className="pages-container">
            <div className={page > 0 ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={page > 0 ? decreasePage : ""}>Previous</div>
                <div className="d-flex">
                  {pagesDiv}
                </div>
            <div className={page + 1 != pageLast ? "blog-index-page-link" : "blog-index-page-link-disabled"} onClick={page + 1 != pageLast ? increasePage : ""}>Next</div>
              </div>
          </div>
      </div>
    </div>
  )
}

const msp = (state) => ({})

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(BlogIndex));