import styled from 'styled-components';

export const ContactFormGalleryContainer = styled.div`
  margin: 0;
  height: 468px;
  @media only screen and (max-width: 769px) {
    height: 174px;
    margin: 28px 0px 6px;
  }
`;

export const ContactFormGalleryWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 28px;

  @media only screen and (max-width: 769px) {
    gap: 6px;
  }
`;

export const GalleryImageWrapper = styled.div`
  position: relative;
  height: 468px;
  
  .fade .tooltip {
    top: -76px !important;
  }
  
  @media only screen and (max-width: 769px) {
    height: 174px;
  }
`;

export const ContactFormGalleryImage = styled.img`
  height: 100%;
  width: auto;
  border-radius: 8px;
`;

export const ImageMarker = styled.img`
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 30px;
  right: 36px;
  @media only screen and (max-width: 769px) {
    height: 13px;
    width: 13px;
    bottom: 11px;
    right: 8px;
    &.tooltip-0 {
      display: none;
    }
  }
`;
