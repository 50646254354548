import styled from 'styled-components';

export const MapSectionWrapper = styled.div`
  /* height: 1002px; */
  padding: 123px 0px 78px 0px;
  background-color: #F2F7FA;

  .fade .tooltip {
    top: -76px !important;
  }
  @media screen and (max-width: 799px) {
    padding: 51px 0px 48px 0px;
  }
`;

export const Heading = styled.h2`
  width: 840px;
  margin: 0 auto 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #1B264F;
  text-transform: none;
  @media screen and (max-width: 799px) {
    font-size: 28px;
    line-height: 28px;
    width: 276px;
    margin: 0 auto 22px;
  }
`;

export const DescriptionText = styled.p`
  width: 730px;
  margin: 0 auto;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #18191F;
  @media screen and (max-width: 799px) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    width: 98%;
    max-width: 350px;
    margin-bottom: 15px;
  }
`;
