import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";

class CreatePurchaseModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      paymentInfo: {},
      formerPaymentInfo: {},
      createPayment: false,
      errors: [],
      current_cvc: ""
    }

    this.handleInput = this.handleInput.bind(this)
    this.createPayment = this.createPayment.bind(this)
    this.goBack = this.goBack.bind(this)
  }


  componentDidMount() {
    document.body.style.overflow = 'hidden';
    if (this.props.project.client.role.name === "admin" || this.props.project.client.role.name === "broker" || this.props.currentUser.roles.broker || (this.props.project.client.role.name === "sub_broker" && this.props.project.client.firm?.broker_charge_virtual_staging)){
      this.setState({ page: 2 })
      this.props.submitOrder()
    } else {
    this.props.fetchPaymentInfo(this.props.project.client_id).then(paymentInfo =>
      this.setState({ paymentInfo: paymentInfo, formerPaymentInfo: JSON.parse(JSON.stringify(paymentInfo)), createPayment: Object.values(paymentInfo).length === 0 })
    ).then(() => {
      let code = "XXXX-XXXX-XXXX-"
      let paymentInfo = this.state.paymentInfo
      let current_cvc = ""
      if (paymentInfo["last_four"]) {
        paymentInfo["last_four"] = code + paymentInfo["last_four"]
      }
      if (paymentInfo["cvc"]) {
        current_cvc = paymentInfo["cvc"]
        paymentInfo["cvc"] = paymentInfo["cvc"]?.length === 3 ? "XXX" : "XXXX"
      }
      if (paymentInfo.payment_service_id) {
        this.setState({ paymentInfo: paymentInfo, current_cvc: current_cvc })
      } else {
        this.setState({ paymentInfo: {} })
      }
    })
   }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }


  goBack(e){
    e.stopPropagation();
    this.props.changeParentState({ paymentModalOpen: false })
  }


  createPayment() {
    let errors = []
    let paymentInfo = this.state.paymentInfo;
    let formerPaymentInfo = this.state.formerPaymentInfo;

    paymentInfo["user_id"] = this.props.project.client_id

    if (!paymentInfo["cardholder_name"] || paymentInfo["cardholder_name"].length === 0) {
      errors.push([0, "Name can't be blank"])
    }

    if (!paymentInfo["cvc"] || (paymentInfo["cvc"].length != 3 && paymentInfo["cvc"].length != 4)) {
      errors.push([1, "CVC code must be 3 or 4 digits long"])
    }

    if (!this.state.createPayment && (paymentInfo["cvc"] === "XXX" || paymentInfo["cvc"] === "XXXX" || paymentInfo["cvc"] === this.state.current_cvc)) {
      paymentInfo["cvc"] = this.state.current_cvc
    }

    if (!paymentInfo["last_four"] || (paymentInfo["last_four"].length != 16 && paymentInfo["last_four"].length != 15 && paymentInfo["last_four"].length != 19)) {
      errors.push([2, "Card number must be 15 or 16 digits long"])
    }

    if (!this.state.createPayment && paymentInfo["last_four"].length > 0 && paymentInfo["last_four"][0] === "X") {
      delete paymentInfo["last_four"]
      delete formerPaymentInfo["last_four"]
    }

    if (paymentInfo["exp_month"] === "Month") {
      errors.push([3, "Expiration month is required"])
    }

    if (paymentInfo["exp_year"] === "Year") {
      errors.push([4, "Expiration year is required"])
    }

    if (errors.length === 0 && !this.state.createPayment && JSON.stringify(paymentInfo) === JSON.stringify(formerPaymentInfo)) {
      this.setState({page: 2})
      this.props.submitOrder()
    }
    else if (errors.length === 0) {
      // if (!paymentInfo["cvc"]) {
      //   errors.push([1, "CVC code required to make changes to payment info"])
      // }

      if (!paymentInfo["last_four"]) {
        errors.push([2, "Full card number required to make changes to payment info"])
      }

      if (errors.length === 0) {
        this.setState({ page: 2 })
        this.props.createPayment(paymentInfo).then((value) => this.props.submitOrder())
      } else {
        this.setState({ errors: errors })
      }
    } else {
      this.setState({ errors: errors })
    }
  }

  handleInput(field) {
    return e => {
      let paymentInfo = this.state.paymentInfo

      if ((field === "cvc" || field === "last_four") && paymentInfo[field]?.length > 0 && paymentInfo[field][0] === "X") {
        if (e.currentTarget.value.length === 2 || e.currentTarget.value.length === 18) {
          paymentInfo[field] = ""
        } else {
          paymentInfo[field] = e.currentTarget.value[e.currentTarget.value.length - 1]
        }
      }
      else {
        if ((field === "cvc" && e.currentTarget.value.length > 4) || (field === "last_four" && e.currentTarget.value.length > 16)) {

        } else {
          paymentInfo[field] = e.currentTarget.value
        }
      }
      this.setState({ paymentInfo: paymentInfo });
    }
  }


  render() {

    if (this.state.page === 1) {

      let cardHolderNameErrorClass = "";
      let cardNumberErrorClass = "";
      let expMonthErrorClass = "";
      let expYearErrorClass = "";
      let cvcErrorClass = "";

      let cardNumberErrors = [];
      let cardHolderNameErrors = [];
      let expMonthErrors = [];
      let expYearErrors = [];
      let cvcErrors = [];

      const yearOptions = [];
      let d = new Date();
      let n = d.getFullYear();
      for (let i = n; i < n + 20; i++) {
        yearOptions.push(<option key={i} value={i}>{i}</option>);
      }

      this.state.errors.forEach((error) => {

        if (error[0] === 0) {
          cardHolderNameErrors.push(<li>{error[1]}</li>)
          cardHolderNameErrorClass = "formInputError"
        }

        else if (error[0] === 1) {
          cvcErrors.push(<li>{error[1]}</li>)
          cvcErrorClass = "formInputError"
        }

        else if (error[0] === 2) {
          cardNumberErrors.push(<li>{error[1]}</li>)
          cardNumberErrorClass = "formInputError"
        }

        else if (error[0] === 3) {
          expMonthErrors.push(<li>{error[1]}</li>)
          expMonthErrorClass = "formInputError"
        }

        else if (error[0] === 4) {
          expYearErrors.push(<li>{error[1]}</li>)
          expYearErrorClass = "formInputError"
        }
      })
      return (
        <div className="modal-background" onClick={this.goBack}>
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="settingsComponentHeading orderPreviewModalHeading"> Checkout </div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Payment Details</div>
                <div className="createProjectPaymentModal">
                  <div className="createProjectPaymentGroupings">
                    <div className="settingsCategoryGroup1">
                      <div className="inputGrouping paymentModalGrouping">
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Cardholder Name </label>
                          <input className="formInput" id={cardHolderNameErrorClass} onChange={this.handleInput("cardholder_name")} type="string" placeholder="Cardholder Name" value={this.state.paymentInfo["cardholder_name"] || ""} />
                          <ul className="errorUl">{cardHolderNameErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Card Number </label>
                          <input className="formInput" id={cardNumberErrorClass} onChange={this.handleInput("last_four")} type="string" placeholder="Card Number" value={this.state.paymentInfo["last_four"] || ""} />
                          <ul className="errorUl">{cardNumberErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> CVC </label>
                          <input className="formInput" id={cvcErrorClass} onChange={this.handleInput("cvc")} type="string" placeholder="CVC" value={this.state.paymentInfo["cvc"] || ""} />
                          <ul className="errorUl">{cvcErrors}</ul>
                        </div>
                      </div>
                      <div className="inputGrouping paymentModalGrouping">
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Expiration Month</label>
                          <select className="formInput" id={expMonthErrorClass} onChange={this.handleInput("exp_month")} name="Month" value={this.state.paymentInfo["exp_month"] || "Month"} >
                            <option value="Month" disabled={true}>Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                          <ul className="errorUl">{expMonthErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Expiration Year </label>
                          <select className="formInput" id={expYearErrorClass} onChange={this.handleInput("exp_year")} name="Year" value={this.state.paymentInfo["exp_year"] || "Year"} >
                            <option value="Year" disabled={true}>Year</option>
                            {yearOptions}
                          </select>
                          <ul className="errorUl">{expYearErrors}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="orderSummaryPriceDiv">
                <div>Total: </div>
                <div>${parseInt(this.props.total).toFixed(2)}</div>
              </div>

              <div className="d-flex justify-content-end">
                <button className="projectEditButton projectEditButtonPrimary" onClick={this.createPayment}>
                  Submit Order
                </button>
              </div>
            </div>
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    }
   else {
    return(
        <div className="modal-background">
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="uploadPhotoModalHeading"> Checkout</div>
              </div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Submitting Order...</div>
                <div className="createProjectPaymentModal">
                <div className="loaderDiv">
                  {/* <Loader
                      type="Oval"
                      color="#4E57AA"
                      height={100}
                      width={100}
                      timeout={30000} //3 secs
                    /> */}
                  <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
 }
}

export default withRouter(CreatePurchaseModal);