import React, { useState, useRef } from 'react';

import {
  ContactFormGalleryWrapper,
  ContactFormGalleryContainer,
  ContactFormGalleryImage,
  GalleryImageWrapper,
  ImageMarker,
} from './styles';
import { TOP_SLIDER } from '../../../../../assets/images/landing';
import { PHOTOGRAPHY } from '../../../../../assets/images/servicesPages';

import { 
  ImageSliderMarkerDark,
  ImageSliderMarkerLight, 
} from '../../../../../assets/images/aboutPage';

const imagesSrc = [
  {
    src: TOP_SLIDER[0].src,
  },
  {
    src: PHOTOGRAPHY.ROW_ONE[1],
  },
  {
    src: PHOTOGRAPHY.ROW_ONE[2],
  },
  {
    src: TOP_SLIDER[3].src
  },
];

const ContactFormGallery = (props) => {
  const {isImageMarkerLight} = props
  const images = (props.images || imagesSrc).map(({ src }, index) => {
    return (
      <GalleryImage 
        src={src} 
        index={index} 
        key={index} 
        isImageMarkerLight={isImageMarkerLight} 
      />
    )
  })

  return (
    <ContactFormGalleryContainer>
      <ContactFormGalleryWrapper>
        {images}
      </ContactFormGalleryWrapper>
    </ContactFormGalleryContainer>
  );
};

const GalleryImage = ({ src, index, isImageMarkerLight }) => {
  return (
    <GalleryImageWrapper>
      <ContactFormGalleryImage src={src}  />
      <ImageMarker
        id={`Tooltip-${index}`}
        className={`tooltip-${index}`}
        src={isImageMarkerLight ? ImageSliderMarkerLight : ImageSliderMarkerDark}
        alt={''}
      />
    </GalleryImageWrapper>
  );
}

export default ContactFormGallery;
