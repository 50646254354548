import React from "react";
import { fetchUser } from "../../../util/users_api_util";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";


export default class SettingsContractorInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.currentUser.roles.admin ? this.props.user_id : this.props.currentUser.id,
      user: null,
      loading: false
    }
  }

  componentDidMount() {
    if (this.state.user_id) {
      this.setState({loading: true})
      fetchUser(this.state.user_id).then(user =>
        this.setState({ user: user, loading: false }))
    }
  }

  render() {

    const user = this.state.user

    if (this.state.loading || !user) {
      return(<div className="loaderDiv">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>)
    } else{
        return (
            <div className="settingsPreferencesGroupingDiv">
              <div className="settingsPreferencesAdmin">
                <div className="settingsComponentHeading">Contractor Payouts</div>
                <div className="settingsPreferencesGroup1">
                <div className="">
                  <div>Name: {user.firstName} {user.lastName}</div>
                  <div>Email: {user.email}</div>
                  <div>Bio: {user.bio}</div>
                  <div className="zipcodeServiceList">
                    <div>
                      Has bank account connected?
                      {user.external_account_name ? (
                        <span className="checkmark">&#10004;</span> // Using HTML entity for checkmark
                      ) : (
                        <span className="crossmark">&#10008;</span> // Using HTML entity for crossmark
                      )}
                    </div>
                    <div>
                      Has stripe account connected?
                      {user.account_link_url ? (
                        <span className="checkmark">&#10004;</span>
                      ) : (
                        <span className="crossmark">&#10008;</span>
                      )}
                    </div>
                    <div>
                      Has google calendar connected?
                      {user.google_calendar ? (
                        <span className="checkmark">&#10004;</span>
                      ) : (
                        <span className="crossmark">&#10008;</span>
                      )}
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
        );
    }
  }
}