import React from "react";
import { Route, Link } from "react-router-dom";
import { fetchUser } from "../../../util/users_api_util";


export default class SettingsUsersItem extends React.Component {
  openUpdateUserModal() {
    fetchUser(this.props.user.id).then(user => this.props.updateUser(user))
  }

  render() {
    let user = this.props.user

    let user_role = "N/A"

    if (user.main_role === "admin"){
      user_role = "Admin"
    } else if (user.main_role === "post_production"){
      user_role = "Post Production"
    } else if (user.main_role === "contractor"){
      user_role = "Contractor"
    } else if (user.main_role === "broker"){
      user_role = "Broker"
    } else if (user.main_role === "sub_broker"){
      user_role = "Sub Broker"
    } else if (user.main_role === "client"){
      user_role = "Client"
    } else if (user.main_role === "sales_rep"){
      user_role = "Sales Rep"
    }

    let destroy = <div className="itemIcon" onClick={() => { if (window.confirm('Are you sure you want to deactivate this user?')) { this.props.deleteUser(user) } }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
    if (user.deactivated){
      destroy = <div className="itemIcon" onClick={() => { if (window.confirm('Are you sure you want to reactivate this user?')) { this.props.deleteUser(user) } }}><i className="fas fa-plus settingsServiceIcon m-2"></i></div>
    }

    return (
      <div className="settingsServicesItem">
        <div className="itemIcon" onClick={() => this.openUpdateUserModal()}><i className="far fa-edit settingsServiceIcon m-2"></i></div>
        {destroy}
        {/* <div className={`itemIcon itemIcon2 ${user.has_payment_service ? '' : 'disabled'}`}>
          <i className="far fa-credit-card settingsServiceIcon m-2" />
        </div> */}

        <div className="usersColumn nameColumn">{user.firstName}</div>
        <div className="usersColumn nameColumn">{user.lastName}</div>
        <div className="usersColumn">{user.email}</div>
        <div className="usersColumn">{user_role}</div>
        <div className="usersColumn">{user.firm?.name || ""}</div>
        <div className="usersColumn">{user.account_credit ? user.account_credit.amount : ""}</div>
        <div className="usersColumn">{user.bookedProjects}</div>
        <div className="usersColumn">{user.completedProjects}</div>
        <div className="usersColumn">{user.confirmed ? "Yes" : "No"}</div>
      </div>
    );
  }
}