import { updateNotification } from "../util/notification_utils";
import * as APIUtil from "../util/session_api_util";
import {clearMediaUploading} from "./media_upload_actions";

export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const RECEIVE_SESSION_ERRORS = "RECEIVE_SESSION_ERRORS";
export const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT"



export const receiveCurrentUser = (user) => ({
  type: RECEIVE_CURRENT_USER,
  user
});

export const logoutCurrentUser = () => ({
  type: LOGOUT_CURRENT_USER
});

export const receiveSessionErrors = (errors) => ({
  type: RECEIVE_SESSION_ERRORS,
  errors
});

export const updateNotificationCount = (user) => ({
  type: UPDATE_NOTIFICATION_COUNT,
  user
})

export const login = userInfo => dispatch => {
  return APIUtil.login(userInfo).then(
    info => dispatch(fetchCurrentUser(info.user_id)),
    errors => dispatch(receiveSessionErrors([errors.responseText]))
    )
};

export const logout = () => dispatch => {
  dispatch(clearMediaUploading())
  return APIUtil.logout().then((info) => {
    if (info.token){
      dispatch(logoutCurrentUser())
    }
  }
  )
};

export const removeCurrentUser = () => dispatch => {
  return dispatch(logoutCurrentUser())
}

export const signup = (userInfo, autoConfirm) => dispatch => {
  return APIUtil.signup(userInfo, autoConfirm).then(
    user => dispatch(receiveCurrentUser(null)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  );
};

export const submitForgotPassword = (user) => dispatch => {
  return APIUtil.submitForgotPassword(user).then(
    user => dispatch(receiveCurrentUser(null)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  )
}

export const submitUpdatedPassword = (user) => dispatch => {
  return APIUtil.submitUpdatedPassword(user).then(
    user => dispatch(receiveCurrentUser(null)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  )
}

export const submitConfirmationResend = (user) => dispatch => {
  return APIUtil.submitConfirmationResend(user).then(
    user => dispatch(receiveCurrentUser(null)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  )
}

export const fetchConfirmation = (user) => dispatch => {
  return APIUtil.fetchConfirmation(user).then(
    user => dispatch(receiveCurrentUser(null)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  )
}

export const fetchCurrentUser = (user_id) => dispatch => {
  return APIUtil.fetchCurrentUser(user_id).then(
    user => dispatch(receiveCurrentUser(user)),
    errors => dispatch(receiveSessionErrors(errors.responseJSON))
  )
}

export const agreeToTerms = (user_id) => dispatch => {
  return APIUtil.agreeToTerms(user_id).then(
    user => dispatch(receiveCurrentUser(user))
  )
}

export const fetchNotificationCount = (user_id) => dispatch => {
  return APIUtil.fetchNotificationCount(user_id).then(
    data => dispatch(updateNotificationCount({count: data.count, user_id: user_id}))
  )
};