import React from "react";
import { withRouter } from "react-router-dom";
import PropertyTourEditMain from "./property_tour/property_tour_edit_main_container";
import NavbarContainer from "./navbar/navbar_container";

class PropertyTourEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLargeScreen: true
    }

    this.updatePredicate = this.updatePredicate.bind(this);
  }


  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  render() {
    return <div className="dashboardFullPageDiv">
      <NavbarContainer />
      <PropertyTourEditMain isLargeScreen={this.state.isLargeScreen} />
    </div>
  }
}

export default withRouter(PropertyTourEdit);