import { connect } from "react-redux";
import Navbar from './navbar';
import { openModal } from "../../actions/modal_actions";
import { receiveSessionErrors, logout } from "../../actions/session_actions";

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  openModal: modal => { dispatch(openModal(modal)); dispatch(receiveSessionErrors([])); }
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);