export const signup = (user, autoConfirm = false) => (
  $.ajax({
    type: "POST",
    url: "/",
    data: { user: user, autoConfirm }
  })
);

export const login = (user) => (
  $.ajax({
    type: "POST",
    url: "/sign_in",
    data: {user: user}
  })
);

export const logout = () => (
  $.ajax({
    type: "DELETE",
    url: "/sign_out",
  })
);

export const submitForgotPassword = (user) => (
  $.ajax({
    type: "POST",
    url: "/password",
    data: { user: user }
  })
)

export const submitUpdatedPassword = (user) => (
  $.ajax({
    type: "PUT",
    url: "/password",
    data: { user: user }
  })
)

export const submitConfirmationResend = (user) => (
  $.ajax({
    type: "POST",
    url: "/confirmation",
    data: { user: user }
  })
)

export const agreeToTerms = () => (
  $.ajax({
    type: "POST",
    url: "/agree_to_terms"
  })
);

export const fetchConfirmation = (user) => (
  $.ajax({
    type: "GET",
    url: "/confirmation",
    data: user
  })
)

export const fetchCurrentUser = (user_id) => (
  $.ajax({
    type: "GET",
    url: "/fetch_user",
    data: {id: user_id, user_id: user_id}
  })
)

export const fetchNotificationCount = (user_id) => (
  $.ajax({
    type: "GET",
    url: "/fetch_notification_count",
    data: { id: user_id, user_id: user_id }
  })
)