import styled from 'styled-components';
import { TESTIMONIALS } from '../../../../../../assets/images/landing';

export const TestimonialWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  background: #1b264f;

  @media only screen and (max-width: 991px) {
    padding-top: 60px;
  }
    
  .container {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background-image: url(${TESTIMONIALS.Quotes});
      background-size: cover;
      background-repeat: no-repeat;

      opacity: 0.2;
      height: 150px;
      width: 177px;
      top: 15%;
      right: -7%;
      z-index: 0;

      @media only screen and (max-width: 1399px) {
        height: 127px;
        width: 150px;
        right: -5%;
      }
      @media only screen and (max-width: 991px) {
        height: 121px;
        width: 142px;
        right: -8%;
      }
    }

    @media only screen and (max-width: 991px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 767px) {
      max-width: 100%;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
`;

export const TestimonialHeading = styled.h5`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

export const TestimonialSliderContainer = styled.div`
  margin-top: 70px;
`;

export const TestimonialSlideItem = styled.div`
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    width: 350px;
    margin: 0 auto; 
  }
  @media only screen and (max-width: 400px) {
    width: 90%;
  }
  @media only screen and (max-width: 342px) {
    .testimonialSlideCard {
      height: 232px !important;
    }
  }
`;

export const TestimonialSlideCard = styled.div`
  width: 100%;
  height: 230px;
  background: #ffffff;
  border-radius: 10px;
  padding: 29px 32px 32px 32px;
  position: relative;
`;

export const Triangle = styled.span`
  &.triangle {
    display: block;
    position: absolute;
    bottom: -14px;
    left: calc(50% - 24px);
    border-style: solid;
    border-width: 24px 24px 0;
    border-color: #ffffff transparent;
    display: block;
    width: 0;
    z-index: 1;
  }

  @supports (clip-path: inset(50%)) {
    &.triangle {
      display: block;
      height: 26px;
      width: 26px;
      background-color: #ffffff;
      border: inherit;
      position: absolute;
      bottom: -7px;
      left: calc(50% - 13px);
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      transform: rotate(-45deg) skew(-10deg, -10deg);
      border-radius: 0 0 0 0.3em;
    }
  }
`;

export const TestimonialSlideHeading = styled.h5`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;

  @media only screen and (max-width: 1280px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TestimonialSlideDescription = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
  @media only screen and (max-width: 1280px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CustomerWarpper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  @media only screen and (max-width: 830px) {
    padding: 0px;
  }
`;

export const CustomerImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-left: 16px;
  margin-right: 16px;
`;

export const CustomerDetails = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const CustomerName = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #ffffff;
  margin-bottom: 3px;
`;

export const CustomerOccupation = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #ffffff;
`;
