import { DELETE_CATEGORY, RECEIVE_CATEGORY, RECEIVE_ALL_CATEGORIES, RECEIVE_ALL_PHOTOS, RECEIVE_ALL_PRODUCTS, RECEIVE_PURCHASE } from "../actions/virtual_staging_actions";

const virtualStagingReducer = (state = { categories: {}, photos: {}, products: {} }, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ALL_CATEGORIES:
      if (action.categories){
      return Object.assign({}, state, { categories: action.categories })
      } else {
        return state
      }
    case RECEIVE_CATEGORY:
      if (action.category) {
        let newState = Object.assign({}, state);
        newState["categories"][action.category.id] = action.category
        return newState
      } else {
        return state
      }
    case DELETE_CATEGORY:
      let newState = Object.assign({}, state);
      delete newState["categories"][action.id]
      return newState
    case RECEIVE_ALL_PHOTOS:
      if (action.photos){
        return Object.assign({}, state, { photos: action.photos })
      } else {
        return state;
      }
    case RECEIVE_ALL_PRODUCTS:
      return Object.assign({}, state, { products: action.products })
    case RECEIVE_PURCHASE:
      return Object.assign({},state, {purchase: action.purchase})
    default:
      return state;
  }
};

export default virtualStagingReducer;