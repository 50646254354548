import React from "react";
import { Route, Link } from "react-router-dom";


export default class SettingsFirmsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    let firm = this.props.firm

    return (
      <div className="settingsServicesItem">
        <div className="usersColumn nameColumn">{firm.name}</div>
        <div className="itemIcon" onClick={() => this.props.updateFirm(this.props.firm)}><i className="far fa-edit settingsServiceIcon m-2"></i></div>
        <div className="itemIcon itemIcon2" onClick={() => { if (window.confirm('Are you sure you want to delete this firm?')) { this.props.deleteFirm(this.props.firm) } }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
      </div>
    );
  }
}