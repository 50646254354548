import React from "react";
import { Route, Link } from "react-router-dom";


export default class createFirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      price: "",
      firms: [],
      firm: "",
      primary_service: "",
      secondary_services: [],
      new_secondary_services: [],
      deactivate: false,
      newService: "",
      
    }
    this.handleInput = this.handleInput.bind(this)
    this.createPromo = this.createPromo.bind(this)
    this.addService = this.addService.bind(this)
    this.deleteNewService = this.deleteNewService.bind(this)
  }

  componentDidMount() {
    this.props.fetchFirms().then(firms => this.setState({ firms: firms }))
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handleCheckbox(field) {
    return e => this.setState({ [field]: !this.state[field] })
  }

  addService() {
    if (this.state.newService !== "") {
      let new_services = this.state.new_secondary_services;
      new_services.push({ service_id: this.state.newService })
      this.setState({ new_services: new_services, newService: "" })
    }
  }

  deleteNewService(index) {
    let new_services = this.state.new_secondary_services
    new_services.splice(index, 1)
    this.setState({ new__secondary_services: new_services })
  }


  createPromo() {
    let secondary_services_attributes = this.state.new_secondary_services

    let promo_info = { name: this.state.name, price: this.state.price, brokerage_firm_id: this.state.firm, deactivate: this.state.deactivate, service_id: this.state.primary_service, secondary_service_promotions_attributes: secondary_services_attributes }

    this.props.createPromo(promo_info).then((promo) => {
      if (this.props.errors.length === 0) {
        this.props.closeModal();
        this.props.onPromoCreate(promo)
      }
    })
  }


  render() {

    let firmOptions = this.state.firms.map(firm => <option value={firm.id}>{firm.name}</option>)
    let primaryServiceOptions = Object.values(this.props.services || []).filter(service => !service.hidden).map((service) => <option key={service.id} value={service.id}>{service.title}</option>)

    let firmDropdown = <div className="settingsCompanyInput promoInput">
      <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Brokerage Firm</label>
      <select className="formInput" onChange={this.handleInput("firm")} name="firm" value={this.state.firm} >
        <option value="" disabled={true}>Choose Brokerage Firm</option>
        {firmOptions}
      </select>
    </div>

    let primaryServiceDropdown = <div className="settingsCompanyInput promoInput">
      <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}>Primary Service</label>
      <select className="formInput" onChange={this.handleInput("primary_service")} name="primary_service" value={this.state.primary_service} >
        <option value="" disabled={true}>Choose Primary Service</option>
        {primaryServiceOptions}
      </select>
    </div>

    let serviceOptions = []
    let currentServices = {}
    let secondaryServicesComponent = ""
    let secondaryServicesLi = []

    this.state.new_secondary_services.forEach((new_service, i) => {
      currentServices[new_service.service_id] = true
      secondaryServicesLi.push(<div className="contractorServiceItem">
        <div className="itemTitle" key={new_service.service_id}>{this.props.services[new_service.service_id].title}</div>
        <div className="itemIcon" onClick={() => { this.deleteNewService(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
      </div>)
    })

    serviceOptions = Object.values(this.props.services || []).filter((service) => !currentServices[service.id] && service.id != this.state.primary_service && !service.hidden).map((service) => <option key={service.id} value={service.id}>{service.title}</option>)

    secondaryServicesComponent =
      <div className="contractorServiceList">
        <div>Secondary Services</div>
        {secondaryServicesLi}
        <div className="settingsCompanyInput promoInput">
          <label id="formLabel" style={{ fontSize: "20px", fontWeight: "bold" }}> New Secondary Service</label>
          <div className="updateUserDropdownAndButton">
            <select className="formInput newServiceDropdown" onChange={this.handleInput("newService")} name="Service" value={this.state.newService} >
              <option value="" disabled={true}>Secondary Service</option>
              {serviceOptions}
            </select>
            <button onClick={this.addService} className="addContractorServiceButton">Add Service</button>
          </div>
        </div>
      </div>

    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading"> Add Promotion</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>
          <div className="settingsCategoryGroup1">
            <div className="settingsServicesInput">
              <label id="formLabel"> Name </label>
              <input className="formInput" onChange={this.handleInput("name")} type="string" placeholder="Name" value={this.state.name} />
            </div>
            <div className="settingsServicesInput">
              <label id="formLabel"> Price </label>
              <input className="formInput" onChange={this.handleInput("price")} type="string" placeholder="Price" value={this.state.price} />
            </div>
            <div className="settingsCheckboxGroup settingsCheckboxGroupPromo">
              {/* <label><input type="checkbox" className="formCheckBox" checked={this.state.hasQuantity} onChange={this.handleCheckbox("hasQuantity")} /> <span className="rememberMe">Has Quantity </span></label> */}
              <label><input type="checkbox" className="formCheckBox" checked={this.state.deactivate} onChange={this.handleCheckbox("deactivate")} /> <span className="rememberMe">Deactivate?</span></label>
            </div>
            {firmDropdown}
            {primaryServiceDropdown}
            {secondaryServicesComponent}
          </div>
          <button onClick={this.createPromo} className="settingsButton companyButton">Create Promo</button>
        </div>
      </div>
    );
  }
}