import React from "react";
import { Route, Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../../assets/images/icons/search.svg";
import { ReactComponent as CheckMark } from "../../../../assets/images/icons/blue-checkmark-icon.svg";


export default class VirtualStagingProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    let product = this.props.product;
    let id = this.props.selected ? "virtualStagingPhotoSelected" : "";
    let icon = this.props.selected ? <div className="checkIcon"><CheckMark /></div> : <div className="zoomIcon" onClick={(e) => { e.stopPropagation(); this.props.changeParentState({ productModalOpen: true, currentProduct: this.props.index }) }}><SearchIcon /></div>

    return (
      <div onClick={() => this.props.toggleSelected(product)} className="virtualStagingPhotoItem">
        <img className="virtualStagingPhotoImage" id={id} src={product.url} />
        {icon}
      </div>
    )
  }
}