import { connect } from "react-redux";
import UpdatePromoModal from "./update_promo_modal";
import React from "react";
import { withRouter } from "react-router-dom";
import { fetchFirms, updatePromo } from "../../../actions/settings_actions";
import { closeModal } from "../../../actions/modal_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  services: state.entities.services
});

const mdp = (dispatch) => ({
  updatePromo: (promo, promo_id) => dispatch(updatePromo(promo, promo_id)),
  closeModal: () => { dispatch(closeModal()) },
  fetchFirms: () => dispatch(fetchFirms())
});

export default withRouter(connect(msp, mdp)(UpdatePromoModal));