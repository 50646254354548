export const fetchNotifications = (limit = 10) => (
  $.ajax({
    type: "GET",
    url: "/notifications",
    data: {limit: limit}
  })
);

export const fetchNotificationsSettings = () => (
  $.ajax({
    type: "GET",
    url: "/fetch_notifications_settings",
  })
);

export const createNotification = (params) => (
  $.ajax({
    type: "POST",
    url: "/notifications",
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const updateNotificationsToRead = () => (
  $.ajax({
    type: "PATCH",
    url: `/update_notifications_to_read`,
  })
);

export const updateNotification = (params, notification_id) => (
  $.ajax({
    type: "PATCH",
    url: `/notifications/${notification_id}`,
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const deleteNotification = (notification_id) => (
  $.ajax({
    type: "DELETE",
    url: `/notifications/${notification_id}`,
  })
);