import React from 'react'
import footerImg from '../../../../../../assets/images/bookPage/footerWaves.svg'
import photographyImg from '../../../../../../assets/images/bookPage/services/photography.svg'
import floorplansImg from '../../../../../../assets/images/bookPage/services/floorplans.svg'
import renderingImg from '../../../../../../assets/images/bookPage/services/rendering.svg'
import videoImg from "../../../../../../assets/images/bookPage/services/video.svg"
import virtualStagingImg from '../../../../../../assets/images/bookPage/services/virtualStaging.svg'
import droneImg from '../../../../../../assets/images/bookPage/services/drone.svg'
import logoImg from '../../../../../../assets/images/landing/web-logo.svg'
import {ROUTES} from "../../../../routes";
import {Link} from "react-router-dom";

export const Footer = () => {

  return <div className="book-page-footer">
    <img src={footerImg} className="book-page-footer-bg"/>

    <div className="book-page-content book-page-container-inner">
      <div className="book-page-logo">
        <img src={logoImg} />
        <div className="book-page-credits">© 2024 Modern Angles. All rights reserved</div>
      </div>
      <div className="book-page-services">
        <Link to={ROUTES.PHOTOGRAPHY}>
          <div className="book-page-service">
            <img src={photographyImg}/>
            <div className="book-page-service-title">Photography</div>
          </div>
        </Link>
        <Link to={ROUTES.FLOOR_PLANS}>
          <div className="book-page-service">
            <img src={floorplansImg}/>
            <div className="book-page-service-title">Floorplan<br/>Creation</div>
          </div>
        </Link>
        <Link to={ROUTES.VIDEO}>
          <div className="book-page-service">
            <img src={videoImg}/>
            <div className="book-page-service-title">Video</div>
          </div>
        </Link>
        <Link to={ROUTES.DRONE}>
          <div className="book-page-service">
            <img src={droneImg}/>
            <div className="book-page-service-title">Drone<br/>Photography</div>
          </div>
        </Link>
        <Link to={ROUTES.VIRTUAL_STAGING}>
          <div className="book-page-service">
            <img src={virtualStagingImg}/>
            <div className="book-page-service-title">Virtual<br/>Staging</div>
          </div>
        </Link>
      </div>
    </div>
  </div>
}