import styled from 'styled-components';

import { PROPERTY } from '../../../../../assets/images/landing';

export const BestPropertyWrapper = styled.div`
  border-bottom: ${props => props.skipBorderBottom ? 'none' : '1px solid #E6E8EF'};
  @media only screen and (max-width: 991px) {
    .container {
      padding: 0px;
      margin: 0px;
      max-width: 100%;
    }
  }
`;

export const BestPropertyContent = styled.div`
  border-radius: 16px;
  margin-bottom: 90px;
  min-height: 396px;
  flex-direction: row;
  display: flex;
  .firstSection {
    background-color: #f6f8ff;
    padding: 60px;
    border-radius: 16px 0px 0px 16px;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .secondSection {
    background-image: url(${PROPERTY.PropertyImage});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #f6f8ff;
    min-height: 100%;
    justify-content: end;
    align-items: end;
    display: flex;
    position: relative;
    border-radius: 0px 16px 16px 0px;
  }
  @media only screen and (max-width: 991px) {
    border-radius: 0;
    margin-bottom: 0;
    min-height: 388px;
    width: 100%;
    .firstSection {
      border-radius: 0;
      padding: 40px;
    }
    .secondSection {
      border-radius: 0;
      background-size: cover;
    }
  }
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    
    .firstSection {
      width: 100%;
      background-color: #EAF1F6;
    }
    .secondSection {
      width: 100%;
    }
  }
`;

export const BestIn = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #1b264f;
  margin-bottom: 20px;
  text-transform: none;
  @media only screen and (max-width: 1280px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 22px;
    line-height: 24px;
  }
`;

export const BestSectionDescr = styled.small`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: #353844;
`;

export const BestBtn = styled.a`
  min-width: 202px;
  width: fit-content;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  background-color: #ffffff;
  border: 2px solid #d8e0ff;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #353844;

  margin-top: 30px;
  padding: 20px 50px;
  @media only screen and (max-width: 767px) {
    height: 50px;
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
  }
`;

export const BestLocation = styled.img`
  position: absolute;
  right: 33px;
  top: 22px;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
