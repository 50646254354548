import React from 'react'
import avatarImg from "../../../../../../../../assets/images/scheduleDemo/anthony1.png";
import {CalendarForm} from "../calendar_form";

export const CalendarStep = ({date, setDate, time, setTime}) => {
  return <>
    <div className="apply-for-discount-photographer">
      <div className="apply-for-discount-photographer-img">
        <img src={avatarImg}/>
      </div>
      <div className="apply-for-discount-photographer-name-description">
        <div className="apply-for-discount-photographer-name">Anthony Acocella</div>
        <div className="apply-for-discount-photographer-description">Photography<br/>Specialist</div>
      </div>
    </div>

    <div className="lead-page-divider"/>

    <CalendarForm date={date} setDate={setDate} time={time} setTime={setTime}/>
  </>
}