import React from 'react'
import {BookConsultation} from "./steps/book_consultation";
import footerImg from '../../../../../assets/images/leadPages/footer-img-2.png'
import {ServicesList} from "./steps/book_consulation/services_list";
import {SERVICES} from "../../../../../assets/images/servicesPages";
import ServiceLogoListComponent from "../../home_page/homeComponents/ServiceLogoListComponent";
import { Helmet } from 'react-helmet-async';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

const hdrListItems = [
  {
    title: 'What is HDR Photography?',
    description: 'High Dynamic Range (HDR) involves taking multiple shots at different exposures to capture a wide range of details, ensuring perfectly balanced lighting inside and out.',
    icon: SERVICES.ListIcon3
  },
  {
    title: 'Crystal Clear Window Views',
    description: 'HDR technology achieves clear window views by capturing and merging multiple exposures of a scene, preserving intricate details in both bright and dark areas.',
    icon: SERVICES.ListIcon2
  },
  {
    title: 'True-Color Accuracy',
    description: 'By expanding the spectrum of tones and hues, HDR enhances color depth and accuracy, presenting a rich and vibrant representation of the property\'s visual palette, ensuring an immersive viewing experience.',
    icon: SERVICES.ListIcon1
  }
]

export const ScheduleConsultationPage = (props) => {

  const onGetStarted = (e) => {
    e.preventDefault()
    if (props.currentUser) {
      props.history.push("/create-project")
    } else {
      props.setModal(true);
    }
  }

  return <div className="apply-for-discount">
    <Helmet>
      <title>Book a Real Estate Photographer Near You | Modern Angles</title>
      <link rel="canonical" href={window.location.href} />
      <meta
        name="description"
        content="Book Top-Rated Photographers & Get Stunning Photos, Drone Shots, Videos, Floor Plans & Virtual Staging – Delivered within 24 Hours."
      />
    </Helmet>
    <BookConsultation/>

    <div className="footer-container apply-for-discount-book-consultation-container">
      <ServiceLogoListComponent />

      <div className="lead-page-divider" />

      <div className="apply-for-discount-book-consultation-sections apply-for-discount-footer-sections">
        <div className="apply-for-discount-footer-side-image">
          <img src={footerImg} />
        </div>

        <div>
          <h1>HDR Real Estate Photography</h1>

          <ServicesList items={hdrListItems}/>

          <button className="projectEditButton projectEditButtonBlue projectEditButtonLarger"
                  onClick={onGetStarted}>
            Schedule Shoot
          </button>
        </div>
      </div>
    </div>
  </div>
}