import { combineReducers } from 'redux';
// import sessionReducer from "./session_reducer";
// import errorsReducer from "./errors_reducer";
import entitiesReducer from "./entities_reducer";
import uiReducer from "./ui_reducer";
import sessionReducer from './session_reducer';
import errorsReducer from './errors_reducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  entities: entitiesReducer,
  errors: errorsReducer,
  session: sessionReducer
});

export default rootReducer;