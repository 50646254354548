import React from 'react';

import {
  FloatingTextareaWrapper,
  FloatingTextareaInput,
  FloatingTextareaLabel,
} from './styles';

const FloatingTextarea = (props) => {
  const { label, ...rest } = props;
  return (
    <FloatingTextareaWrapper>
      <FloatingTextareaInput {...rest}></FloatingTextareaInput>
      <FloatingTextareaLabel>{label}</FloatingTextareaLabel>
    </FloatingTextareaWrapper>
  );
};

export default FloatingTextarea;
