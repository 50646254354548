import React from 'react';
import { Container } from 'reactstrap';

import { SectionSlideItemWrapperEven, OddSectionEven4 } from './commonStyles';
import {
  ContractMediaSlide4Part1,
  ContractMediaSlide4Part2,
} from './slideFourStyles';
import { CONTRACT } from '../../../../../../assets/images/landing';

const SlideFourVS = (props) => {
  const { item, activeIndex, handleDragStart } = props;

  return (
    <SectionSlideItemWrapperEven onDragStart={handleDragStart}>
      <OddSectionEven4
        src={CONTRACT.ContractBackground}
        className={`${
          item.id == activeIndex ? 'fadeInZoomInRightAnimation' : ''
        }`}
      />
      <Container>
        <ContractMediaSlide4Part1
          className={`${
            item.id == activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide4Part1}
        />
        <ContractMediaSlide4Part2
          className={`${
            item.id == activeIndex ? 'fadeInZoomInRightAnimation' : ''
          }`}
          src={item.ContractMediaSlide4Part2}
        />
      </Container>
    </SectionSlideItemWrapperEven>
  );
};

export default SlideFourVS;
