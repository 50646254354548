export const startPhotographerOnboarding = () => (
  $.ajax({
    type: "POST",
    url: "/photographer_onboarding/start"
  })
);

export const createPhotographer = (data) => (
  $.ajax({
    type: "POST",
    url: "/photographer_onboarding",
    data: data
  })
);

export const updatePhotographer = (data) => (
  $.ajax({
    type: "PATCH",
    url: "/photographer_onboarding",
    data: data
  })
);

export const updatePhotographerPortfolio = (data) => (
  $.ajax({
    type: "PATCH",
    url: "/photographer_onboarding/portfolio",
    data: data,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const updatePhotographerServices = (data) => (
  $.ajax({
    type: "PATCH",
    url: "/photographer_onboarding/services",
    data: data
  })
);

export const updatePhotographerArea = (data) => (
  $.ajax({
    type: "PATCH",
    url: "/photographer_onboarding/area",
    data: data
  })
);

export const updatePhotographerAvailability = (data) => (
  $.ajax({
    type: "PATCH",
    contentType: "application/json",
    url: "/photographer_onboarding/availability",
    data: JSON.stringify(data)
  })
);

export const addPhotographerPortfolioImages = (data) => (
  $.ajax({
    type: "PATCH",
    url: "/photographer_onboarding/add_portfolio_images",
    data: data,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const removePhotographerPortfolioImage = (imageId) => (
  $.ajax({
    type: "PATCH",
    url: "/photographer_onboarding/delete_portfolio_image",
    data: {image_id: imageId}
  })
);

export const completePhotographerOnboarding = (data) => (
  $.ajax({
    type: "PATCH",
    url: "/photographer_onboarding/done",
    data: data
  })
);

export const getPhotographerOnboardingUser = () => (
  $.ajax({
    type: "GET",
    url: "/photographer_onboarding"
  })
);