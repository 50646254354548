import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router-dom"
import { fetchPhotographer } from "../../actions/service_actions";
import PhotographerShowMain from "./photographer_show_main";
import { showToast } from "../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null,
});

const mdp = (dispatch) => ({
  fetchPhotographer: (id) => dispatch(fetchPhotographer(id)),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(PhotographerShowMain));