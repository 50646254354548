import React from "react"
import {withRouter} from "react-router-dom"
import PageHeader from "../page_header"
import {createBlog, fetchBlogCategories} from '../../util/blog_api_utils';
import NavbarContainer from "../navbar/navbar_container";

import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import ImageTool from '@editorjs/image'
import RawTool from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Delimiter from '@editorjs/delimiter'

import {createReactEditorJS} from "react-editor-js";
import Introduction from "./editorjs-blocks/Introduction";
import Conclusion from "./editorjs-blocks/Conclusion";
import EmailForm from "./editorjs-blocks/EmailForm";


const ReactEditorJS = createReactEditorJS();

class BlogForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.blog?.title || "",
      description: props.blog?.description || "",
      time_to_read: props.blog?.time_to_read || 5,
      blog_category_id: props.blog?.blog_category_id || "",
      blog_categories: [],
      header_image_src: props.blog?.header_image || "",
      content: props.blog?.content || [],
      loading: false
    }

    this.handler = this.handler.bind(this)
    this.handleCoverSelected = this.handleCoverSelected.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.editorInitialize = this.editorInitialize.bind(this)
    this.createBlog = this.createBlog.bind(this)
    this.editor = React.createRef()
  }

  componentDidMount() {
    // window.scrollTo(0, 0)
    fetchBlogCategories().then(blog_categories => {
      this.setState({blog_categories})
    })
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.header_image_src)
  }

  handleCoverSelected(event) {
    let cover = event.target.files[0]
    let cover_src = URL.createObjectURL(cover)

    this.setState({
      header_image: cover,
      header_image_src: cover_src
    })
  }

  handleInput(field) {
    return e => this.setState({[field]: e.currentTarget.value})
  }

  handler(newState) {
    this.setState(newState)
  }

  async createBlog() {
    let formData = new FormData()
    const content = await this.editor.current.dangerouslyLowLevelInstance?.save();
    const fields = ['description', 'title', 'blog_category_id', "time_to_read"]

    for (const field of fields) {
      formData.append(`blog[${field}]`, this.state[field])
    }

    formData.append('blog[content]', JSON.stringify(content.blocks))

    if (this.state.header_image) {
      formData.append("blog[header_image]", this.state.header_image)
    }

    this.setState({loading: true})

    this.props.onSubmit(formData).then(blog => {
      this.setState({loading: false})
      this.props.history.push(`/blog/${blog.slug}`)
    }).catch(() => {
      this.showSavingLabel()
      this.setState({loading: false})
    })
  }

  showSavingLabel() {
    // this.props.showToast("Blog could not be submitted")
  }

  editorInitialize(editor) {
    this.editor.current = editor;
  }

  render() {
    return (
      <div className="dashboardFullPageDiv">
        <NavbarContainer/>
        <div className="dashboardMainDiv">
          <PageHeader title={this.props.title} back="Back to Blogs" backLink={`/settings/blogs`}/>
          <div className="editProjectSection d-block">
            <div className="propertyTourSectionBody">
              <h5 className="propertyTourSectionTitle">Header Image</h5>
              <div className="propertyTourEditMediaSection">
                <img className="propertyTourEditCoverImage" src={this.state.header_image_src}/>
                <div className="propertyTourEditMediaSectionInput">
                  <label htmlFor="coverImg" className="propertyTourEditButton">
                    Upload file
                    <input type="file" className="d-none" id="coverImg" accept="image/*"
                           onChange={this.handleCoverSelected}/>
                  </label>
                </div>
              </div>
            </div>
            <div className="propertyTourSectionBody">
              <div className="floatingLabelsForm mt-2">
                <div className="settingsServicesInput">
                  <label id="formLabel"> Category </label>
                  <select className="formInput" onChange={this.handleInput("blog_category_id")} name="category"
                          value={this.state.blog_category_id}>
                    <option value="">Choose Category</option>
                    {this.state.blog_categories.map(blog_category => <option
                      value={blog_category.id}>{blog_category.title}</option>)}
                  </select>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="inputGroup">
                      <label className="required">Title</label>
                      <input className="formInput" placeholder="Title" onChange={this.handleInput("title")}
                             value={this.state.title}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="inputGroup">
                      <label className="required">Description</label>
                      <input className="formInput" placeholder="Description" onChange={this.handleInput("description")}
                             value={this.state.description}/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="inputGroup">
                      <label className="required">Time to read</label>
                      <input className="formInput" placeholder="Time to read" type="number" onChange={this.handleInput("time_to_read")}
                        value={this.state.time_to_read} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ReactEditorJS defaultValue={{blocks: this.state.content}}
                           onInitialize={this.editorInitialize}
                           tools={{
                             introduction: {
                               class: Introduction,
                               inlineToolbar: true
                             },
                             header: {
                               class: Header,
                               inlineToolbar: true
                             },
                             paragraph: {
                               class: Paragraph,
                               inlineToolbar: true
                             },
                             list: {
                               class: List,
                               inlineToolbar: true
                             },
                             quote: {
                               class: Quote,
                               inlineToolbar: true
                             },
                             email: EmailForm,
                             image: {
                               class: ImageTool,
                               inlineToolbar: true,
                               config: {
                                 endpoints: {
                                   byFile: '/images'
                                 }
                               }
                             },
                             delimiter: Delimiter,
                             rawChart: {
                               class: RawTool,
                               inlineToolbar: true
                             },
                             conclusion: {
                               class: Conclusion,
                               inlineToolbar: true
                             }
                           }}/>

            <div className="propertyTourSectionFooter">
              <div className="d-flex align-items-center">
                <button className="propertyTourEditButton" onClick={this.createBlog} disabled={this.state.loading}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(BlogForm)