import React, {useEffect, useRef, useState} from 'react'
import {fetchBlogs} from "../../../util/blog_api_utils";
import {BlogPost} from "../BlogPost";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import leftIcon from '../../../../../assets/images/blog/left.png'
import rightIcon from '../../../../../assets/images/blog/right.png'

export const BlogPosts = () => {
  const [blogs, setBlogs] = useState([])
  const slider = useRef(null)

  useEffect(() => {
    fetchBlogs().then(blogs => {
      const sortedBlogs = blogs.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by most recent
      setBlogs(sortedBlogs.slice(0, 10));
    });
  }, []);

  useEffect(() => {
    const width = window.innerWidth;

    const handleSlider = () => {
      if (slider.current) {
        if (width <= 800) {
          slider.current.slickNext();
        } else if (width > 800 && width <= 1100){
          slider.current.slickNext();
          setTimeout(() => {
            if (slider.current) {
              slider.current.slickNext();
            }
          }, 1000);
        } else if (width > 1100 && width <= 1300) {
          slider.current.slickNext();
          setTimeout(() => {
            if (slider.current) {
              slider.current.slickNext();
            }
          }, 1000);
          setTimeout(() => {
            if (slider.current) {
              slider.current.slickNext();
            }
          }, 2000);
        }
      }
    };

    const timeoutId = setTimeout(handleSlider, 1000); // Initial delay to ensure the slider is fully initialized

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, []);

  const onClickPrev = (e) => {
    e.preventDefault()
    slider.current.slickPrev()
  }

  const onClickNext = (e) => {
    e.preventDefault()
    slider.current.slickNext()
  }

  const slides = blogs.map(blog => <BlogPost blogPost={blog} key={`blog-post-${blog.id}`}/>)

  return <div className="popular-posts-wrapper">
    <div className="popular-posts-wrapper-inner">
      <div className="popular-posts-subtitle">Latest posts</div>
      <div className="popular-posts-title">Popular Posts</div>
      <div className="popular-posts-description-and-button">
        <div className="popular-posts-description">Tips, Guides, Industry Best Practices, and News.</div>
        <Link className="projectEditButton popular-posts-view-all" to="/blog">View all posts</Link>
      </div>
    </div>

    <div className="popular-posts-cards-wrapper-inner">
      <Slider
        slidesToShow={4}
        slidesToScroll={1}
        ref={slider}
        dots={false}
        arrows={false}
        centerMode={false}
        focusOnSelect={false}
        infinite={true}
        variableWidth={true}
        autoplay={false}
        draggable={false}
        lazyLoad={"progressive"}
        responsive={[
          {
            breakpoint: 1680,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]}>
        {slides}
      </Slider>
    </div>

    <div className="popular-posts-wrapper-inner">
      <div className="popular-posts-navigation">
        <a href="#" onClick={onClickPrev}><img src={leftIcon} /></a>
        <a href="#" onClick={onClickNext}><img src={rightIcon} /></a>
      </div>
    </div>
  </div>
}