import React from 'react'
import completedImg from "../../../../../assets/images/photographerOnboarding/completed.svg"
import {useHistory} from "react-router-dom";
import {removeCache} from "../utils";

export const Completed = () => {
  const history = useHistory()

  const onBackToHome = (e) => {
    e.preventDefault()
    removeCache()
    history.push('/')
  }

  return <div>
    <div className="photographer-onboarding-form-title">
      <span className="blue">Nice job!</span> We will review and get back to you.
    </div>

    <div className="photographer-onboarding-center-image-wrapper">
      <img src={completedImg}/>
    </div>

    <div className="photographer-onboarding-paragraph-text">
      Thank you for applying, we will try to get back to you on your application as soon as possible until then you can
      look around and get accumulated to the Modern Angles site.
    </div>

    <div className="photographer-onboarding-divider"/>
    <div className="mar-top-20">
      <button className="projectEditButton projectEditButtonPrimary w-100" type="button" onClick={onBackToHome}>
        Back to home
      </button>
    </div>
  </div>
}