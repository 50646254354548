import React from 'react';
import { Container } from 'reactstrap';

import {
  FloorPlansTopSectionWrapper,
  FloorPlansBaseImage,
  FloorPlansBaseChangeImage,
  ServiceButtonContainer,
} from './styles';
import ServicesButton from '../ServicesButton';
import { FLOOR_PLANS } from '../../../../../assets/images/servicesPages';

const FloorPlansTopSection = (props) => {
  return (
    <FloorPlansTopSectionWrapper>
      <Container>
        <FloorPlansBaseImage src={FLOOR_PLANS.FloorPlansBase} />
        <FloorPlansBaseChangeImage src={FLOOR_PLANS.FloorPlansBaseChange} />
        <ServiceButtonContainer>
          <ServicesButton setModal={props.setModal}/>
        </ServiceButtonContainer>
      </Container>
    </FloorPlansTopSectionWrapper>
  );
};

export default FloorPlansTopSection;
