import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";

class ChangeServiceCheckoutModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      paymentInfo: {},
      createPayment: false,
      errors: [],
      current_cvc: ""
    }

    this.handleInput = this.handleInput.bind(this)
    this.createPayment = this.createPayment.bind(this)
    this.createRefund = this.createRefund.bind(this)
    this.createAccountCredit = this.createAccountCredit.bind(this)
  }


  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.props.fetchPaymentInfo(this.props.client).then(paymentInfo =>
      this.setState({ paymentInfo: paymentInfo, formerPaymentInfo: JSON.parse(JSON.stringify(paymentInfo)), createPayment: Object.values(paymentInfo).length === 0 })
    ).then(() => {
      let code = "XXXX-XXXX-XXXX-"
      let paymentInfo = this.state.paymentInfo
      let current_cvc = ""
      if (paymentInfo["last_four"]) {
        paymentInfo["last_four"] = code + paymentInfo["last_four"]
      }
      if (paymentInfo["cvc"]) {
        current_cvc = paymentInfo["cvc"]
        paymentInfo["cvc"] = paymentInfo["cvc"]?.length === 3 ? "XXX" : "XXXX"
      }

      let page = 1

      if (this.props.cancelModal) {
        page = 3
      }
      if (paymentInfo.payment_service_id) {
        this.setState({ paymentInfo: paymentInfo, current_cvc: current_cvc, page: page })
      } else {
        this.setState({ paymentInfo: {}, page: page })
      }
    })
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  createAccountCredit() {
    if (this.props.cancelModal) {
      if (window.confirm('Are you sure you want to cancel this project?')) {
        this.setState({ page: 4 })
        this.props.createAccountCredit()
      }
    } else {
      this.setState({ page: 4 })
      this.props.createAccountCredit()
    }
  }

  createRefund() {
    if (this.props.cancelModal) {
      if (window.confirm('Are you sure you want to cancel this project?')) {
        this.setState({ page: 4 })
        this.props.createRefund()
      }
    } else {
      this.setState({ page: 4 })
      this.props.createRefund()
    }
  }

  createPayment() {
    let errors = []
    let paymentInfo = this.state.paymentInfo;
    let formerPaymentInfo = this.state.formerPaymentInfo;

    paymentInfo["user_id"] = this.props.client ? parseInt(this.props.client) : this.props.currentUser.id


    if (!paymentInfo["cardholder_name"] || paymentInfo["cardholder_name"].length === 0) {
      errors.push([0, "Name can't be blank"])
    }

    if (!paymentInfo["cvc"] || (paymentInfo["cvc"].length != 3 && paymentInfo["cvc"].length != 4)) {
      errors.push([1, "CVC code must be 3 or 4 digits long"])
    }

    if (paymentInfo["cvc"] === "XXX" || paymentInfo["cvc"] === "XXXX" || paymentInfo["cvc"] === this.state.current_cvc) {
      paymentInfo["cvc"] = this.state.current_cvc
    }

    if (!paymentInfo["last_four"] || (paymentInfo["last_four"].length != 16 && paymentInfo["last_four"].length != 15 && paymentInfo["last_four"].length != 19)) {
      errors.push([2, "Card number must be 15 or 16 digits long"])
    }

    if (paymentInfo["last_four"] && paymentInfo["last_four"][0] === "X") {
      delete paymentInfo["last_four"]
      delete formerPaymentInfo["last_four"]
    }

    if (paymentInfo["exp_month"] === "Month") {
      errors.push([3, "Expiration month is required"])
    }

    if (paymentInfo["exp_year"] === "Year") {
      errors.push([4, "Expiration year is required"])
    }

    if (errors.length === 0 && !this.state.createPayment && JSON.stringify(paymentInfo) === JSON.stringify(formerPaymentInfo)) {
      this.setState({ page: 4 })
      this.props.updateProject()
    }
    else if (errors.length === 0) {
      // if (!paymentInfo["cvc"]) {
      //   errors.push([1, "CVC code required to make changes to payment info"])
      // }

      if (!paymentInfo["last_four"]) {
        errors.push([2, "Full card number required to make changes to payment info"])
      }

      if (errors.length === 0) {
        this.props.createPayment(paymentInfo).then((payment) => {
          if (payment.errors) {
            errors.push([5, payment.errors])
            this.setState({ errors: errors })
          } else {
            this.props.updateProject()
            this.setState({ page: 4 })
          }
        })
      } else {
        this.setState({ errors: errors })
      }
    } else {
      this.setState({ errors: errors })
    }
  }

  handleInput(field) {
    return e => {
      let paymentInfo = this.state.paymentInfo

      if ((field === "cvc" || field === "last_four") && paymentInfo[field]?.length > 0 && paymentInfo[field][0] === "X") {
        if (e.currentTarget.value.length === 2 || e.currentTarget.value.length === 18) {
          paymentInfo[field] = ""
        } else {
          paymentInfo[field] = e.currentTarget.value[e.currentTarget.value.length - 1]
        }
      }
      else {
        if ((field === "cvc" && e.currentTarget.value.length > 4) || (field === "last_four" && e.currentTarget.value.length > 16)) {

        } else {
          paymentInfo[field] = e.currentTarget.value
        }
      }
      this.setState({ paymentInfo: paymentInfo });
    }
  }


  render() {
    if (this.state.page === 1) {
      let price = 0;
      let discount = 0;
      let account_credit_component = "";
      let account_credit = this.props.account_credit;
      let previous_services = {}

      this.props.previous_services.forEach(service => previous_services[service.id] = true)

      let subServices = Object.values(this.props.subservices).map(subservice => {
        price += parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price)
          return (<div className="orderSummaryLi">
            <div>{subservice.title}</div>
            <div className="orderSummarySubservicePrice">${parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price).toFixed(2)}</div>
          </div>)
      })

      price -= this.props.previous_total

      if (this.props.promo_price) {
        let promo_discount = price - (this.props.promo_price - this.props.previous_total)

        subServices.push(<div className="orderSummaryLi">
          <div>{this.props.promo_name} Promo Applied: </div>
          <div className="orderSummarySubservicePrice">{promo_discount > 0 ? "-" : ""}${Math.abs(promo_discount).toFixed(2)}</div>
        </div>)

        price = this.props.promo_price - this.props.previous_total
      }

      if (this.props.account_credit && price > 0) {
        if (this.props.account_credit >= price) {
          account_credit = this.props.account_credit - price
          price = 0
          discount = this.props.account_credit - account_credit
        } else {
          price = price - this.props.account_credit
          discount = this.props.account_credit
          account_credit = 0
        }
      }

      Object.values(this.props.previous_sub_services).forEach(sub_service => {
        subServices.push(<div className="orderSummaryLi">
          <div>{sub_service.title} (removed)</div>
          <div className="orderSummarySubservicePrice">-${parseFloat(this.props.location_pricing[sub_service] ? this.props.location_pricing[sub_service].price : sub_service.price).toFixed(2)}</div>
        </div>)
      })

      if (discount > 0) {
        subServices.push(<div className="orderSummaryLi">
          <div>Account credit applied: </div>
          <div className="orderSummarySubservicePrice">-${discount.toFixed(2)}</div>
        </div>)
      }

      if (account_credit > 0) {
        account_credit_component = <div className="">
          <div className="totalOrderSummaryDiv">Account Credit:</div>
          <div className="totalOrderSummaryPrice">$ {account_credit.toFixed(2)}</div>
        </div>
      }

      let submitButton = <button className="projectEditButton projectEditButtonPrimary" onClick={() => this.setState({ page: 2 })}>Continue to payment</button>

      if (price === 0) {
        submitButton = <button className="projectEditButton projectEditButtonPrimary" onClick={() => { this.setState({ page: 4 }); this.props.updateProject() }}>Submit Changes</button>
      } else if ((this.props.client_role === "admin") || this.props.client_role === "broker" || (this.props.client_role === "sub_broker" && this.props.firm.broker_charge) || (this.props.client_role === "sub_broker" && this.props.currentUser.roles.broker)) {
        submitButton = <button className="projectEditButton projectEditButtonPrimary" onClick={() => { this.setState({ page: 4 }); this.props.updateProject() }}>Submit Order</button>
      } else if (price < 0) {
        submitButton = <button className="projectEditButton projectEditButtonPrimary" onClick={() => this.setState({ page: 3 })}>Continue to refund page</button>
      }

      return (
        <div className="modal-background" onClick={() => this.props.changeParentState({ subModal: null })}>
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="settingsComponentHeading orderPreviewModalHeading">Order Preview</div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Property Details</div>
                <div className="addressPreviewModal">{this.props.formatted_address ? `${this.props.apartment} ${this.props.formatted_address}` : `${this.props.street}, ${this.props.apartment} ${this.props.city}, ${this.props.state} ${this.props.zipcode}`} </div>
              </div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Service Details</div>
                <div className="previewOrderListModal">
                  {subServices}
                </div>
              </div>
              <div className="orderSummaryPriceDiv">
                <div>Total: </div>
                <div>${price.toFixed(2)}</div>
              </div>
              {/* {account_credit_component} */}
              <div className="d-flex justify-content-end">
                {submitButton}
              </div>
            </div>
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    }
    else if (this.state.page === 2) {
      let price = 0;
      let discount = 0;
      let account_credit = this.props.account_credit;

      Object.values(this.props.subservices).map(subservice => {
        price += parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price)
      })

      price -= this.props.previous_total

      if (this.props.promo_price) {
        price = this.props.promo_price
      }

      if (this.props.account_credit && price > 0) {
        if (this.props.account_credit >= price) {
          account_credit = this.props.account_credit - price
          price = 0
          discount = this.props.account_credit - account_credit
        } else {
          price = price - this.props.account_credit
          discount = this.props.account_credit
          account_credit = 0
        }
      }

      let cardHolderNameErrorClass = "";
      let cardNumberErrorClass = "";
      let expMonthErrorClass = "";
      let expYearErrorClass = "";
      let cvcErrorClass = "";

      let cardNumberErrors = [];
      let cardHolderNameErrors = [];
      let expMonthErrors = [];
      let expYearErrors = [];
      let cvcErrors = [];
      let backendErrors = [];

      const yearOptions = [];
      let d = new Date();
      let n = d.getFullYear();
      for (let i = n; i < n + 20; i++) {
        yearOptions.push(<option key={i} value={i}>{i}</option>);
      }

      this.state.errors.forEach((error) => {

        if (error[0] === 0) {
          cardHolderNameErrors.push(<li>{error[1]}</li>)
          cardHolderNameErrorClass = "formInputError"
        }

        else if (error[0] === 1) {
          cvcErrors.push(<li>{error[1]}</li>)
          cvcErrorClass = "formInputError"
        }

        else if (error[0] === 2) {
          cardNumberErrors.push(<li>{error[1]}</li>)
          cardNumberErrorClass = "formInputError"
        }

        else if (error[0] === 3) {
          expMonthErrors.push(<li>{error[1]}</li>)
          expMonthErrorClass = "formInputError"
        }

        else if (error[0] === 4) {
          expYearErrors.push(<li>{error[1]}</li>)
          expYearErrorClass = "formInputError"
        }
        else if (error[0] === 5) {
          backendErrors.push(<li>{error[1]}</li>)
        }
      })
      return (
        <div className="modal-background" onClick={() => this.props.changeParentState({ subModal: null })}>
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="settingsComponentHeading orderPreviewModalHeading"> Checkout </div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Payment Details</div>
                <ul className="errorUl backendErrorsPayment">{backendErrors}</ul>
                <div className="createProjectPaymentModal">
                  <div className="createProjectPaymentGroupings">
                    <div className="settingsCategoryGroup1">
                      <div className="inputGrouping paymentModalGrouping">
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Cardholder Name </label>
                          <input className="formInput" id={cardHolderNameErrorClass} onChange={this.handleInput("cardholder_name")} type="string" placeholder="Cardholder Name" value={this.state.paymentInfo["cardholder_name"] || ""} />
                          <ul className="errorUl">{cardHolderNameErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Card Number </label>
                          <input className="formInput" id={cardNumberErrorClass} onChange={this.handleInput("last_four")} type="string" placeholder="Card Number" value={this.state.paymentInfo["last_four"] || ""} />
                          <ul className="errorUl">{cardNumberErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> CVC </label>
                          <input className="formInput" id={cvcErrorClass} onChange={this.handleInput("cvc")} type="string" placeholder="CVC" value={this.state.paymentInfo["cvc"] || ""} />
                          <ul className="errorUl">{cvcErrors}</ul>
                        </div>
                      </div>
                      <div className="inputGrouping paymentModalGrouping">
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Expiration Month</label>
                          <select className="formInput" id={expMonthErrorClass} onChange={this.handleInput("exp_month")} name="Month" value={this.state.paymentInfo["exp_month"] || "Month"} >
                            <option value="Month" disabled={true}>Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                          <ul className="errorUl">{expMonthErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Expiration Year </label>
                          <select className="formInput" id={expYearErrorClass} onChange={this.handleInput("exp_year")} name="Year" value={this.state.paymentInfo["exp_year"] || "Year"} >
                            <option value="Year" disabled={true}>Year</option>
                            {yearOptions}
                          </select>
                          <ul className="errorUl">{expYearErrors}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="orderSummaryPriceDiv">
                <div>Total: </div>
                <div>${price.toFixed(2)}</div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="projectEditButton projectEditButtonPrimary" onClick={this.createPayment}>Submit Order</button>
              </div>
            </div>
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )


    }
    else if (this.state.page === 3) {
      let price = 0;


      Object.values(this.props.subservices).map(subservice => {
        price += parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price)
      })

      if (this.props.promo_price) {
        price = this.props.promo_price
      }

      price -= this.props.previous_total

      price = price * -1

      if (this.props.cancelModal) {
        price = this.props.previous_total*-1;
      }

      let previousSubService_ids = []

      Object.values(this.props.previous_sub_services).forEach(sub_service => previousSubService_ids.push(sub_service.id))
  
      let invoice_array = Object.values(this.props.project.invoices).filter(invoice => invoice.sub_services.includes(previousSubService_ids[0]))

      let invoice = ""

      if (invoice_array.length === 1) {
        invoice = invoice_array[0]
      }
      else {
        invoice = invoice_array[0]

        invoice_array.forEach(inv => {
          if (new Date(inv.created_at) > new Date(invoice.created_at)) {
            invoice = inv
          }
        })
      }

      return (
        <div className="modal-background" onClick={() => this.props.changeParentState({ subModal: null })}>
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="settingsComponentHeading orderPreviewModalHeading"> Refund/Account Credit </div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Refund Details</div>
                <div className="createProjectPaymentModal">
                  <div className="createProjectPaymentGroupings">
                    <div className="settingsCategoryGroup1">
                      <div className="refundText">Sorry to hear that you are planning to cancel your package(s). Based on our refund policy we can offer you two options. If you would like a direct refund there will be a 5% fee based on processing costs. Account credit can be chosen instead which will be automiatically applied to a future purchase. With account credit there is no fees so you will receieve the full refund amount in credit.</div>
                      {/* <div style={{marginTop: "10px", marginBottom:"10px"}}>Please note there is an extra $75 fee for any appointments that are cancelled within 24 hours of the appointment time.</div> */}
                    </div>
                  </div>
                </div>
                <div className="orderSummaryPriceDiv">
                  <div>Total: </div>
                  <div>${price.toFixed(
                    2)}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <button className="projectEditButton projectEditButtonPrimary" onClick={this.createRefund}> Refund: ${invoice.total >= price * .95 ? (price * 0.95).toFixed(2) : `${(invoice.total *.95).toFixed(2)} + $${(price - invoice.total).toFixed(2)} account credit`}</button>
                  <button className="projectEditButton projectEditButtonLight ms-2" onClick={this.createAccountCredit}>Account Credit: ${(price).toFixed(2)}</button>
                </div>
              </div>
            </div>
            <div className="modalMobilePushup"></div>
          </div>
        </div>
      )
    }
    else if (this.state.page === 4) {
      return (
        <div className="modal-background">
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="uploadPhotoModalHeading"> Checkout</div>
              </div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Submitting Order...</div>
                <div className="createProjectPaymentModal">
                  <div className="loaderDiv">
                    {/* <Loader
                      type="Oval"
                      color="#4E57AA"
                      height={100}
                      width={100}
                      timeout={30000} //3 secs
                    /> */}
                    <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(ChangeServiceCheckoutModal);