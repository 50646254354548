import React from "react";
import { fetchSalesData } from "../../../util/settings_api_util";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";

export default class SettingsRevenue extends React.Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const startOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1);

    this.state = {
      loading: false,
      searchFrom: startOfCurrentYear.toISOString().split('T')[0],
      searchTo: currentDate.toISOString().split('T')[0],
      revenue: '',
      old_revenue: '',
      net_revenue: '',
      old_net_revenue: '',
      user_count: '',
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.searchRevenue = this.searchRevenue.bind(this);
  }

  onSearchChange(field) {
    return (e) => {
      e.preventDefault();
      this.setState({ [field]: e.target.value, page: 0 });
    };
  }

  searchRevenue() {
    this.setState({ loading: true });
    fetchSalesData(this.state.searchFrom, this.state.searchTo).then(sales_data => {
      this.setState({
        loading: false,
        revenue: Number(sales_data["revenue"]),
        old_revenue: Number(sales_data["old_revenue"]),
        net_revenue: Number(sales_data["net_revenue"]),
        old_net_revenue: Number(sales_data["old_net_revenue"]),
        user_count: sales_data["users_joined"]
      });
    });
  }

  formatCurrency(amount) {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  render() {
    return (
      <div className="settingsComponentGroupingDiv">
        <div className="settingsCompany">
          <div className="settingsPreferencesGroupingDiv">
            <div className="settingsPreferencesAdmin">
              <div className="settingsComponentHeading">Revenue Data</div>
              <div className="settingsContractorComponentDiv settingsRevenueDateDiv">
                <div className="col-6 col-md-4">
                  <input className="formInput" type="date" onKeyDown={(e) => e.preventDefault()}
                    onChange={this.onSearchChange('searchFrom')}
                    value={this.state.searchFrom} />
                  <i className="fas fa-calendar calendarIcon" />
                </div>
                <div className="col-6 col-md-4">
                  <input className="formInput" type="date" onKeyDown={(e) => e.preventDefault()}
                    onChange={this.onSearchChange('searchTo')}
                    value={this.state.searchTo} />
                  <i className="fas fa-calendar calendarIcon" />
                </div>
              </div>
              <button onClick={this.searchRevenue} className="settingsButton preferencesButton">Generate Report</button>
              <div className="settingsPreferencesGroup1">
                {this.state.loading ?
                  <div className="loaderDiv">
                    <img className="logoImageSpinnerGif" src={LogoSpinner} alt="Loading..."></img>
                  </div> :
                  this.state.revenue != '' && <div className="revenueInfo">
                    <div>Revenue: {this.formatCurrency(this.state.revenue)}</div>
                    <div>Net Revenue: {this.formatCurrency(this.state.net_revenue)}</div>
                    <div>Revenue 12 months prior: {this.formatCurrency(this.state.old_revenue)}</div>
                    <div>Net Revenue 12 months prior: {this.formatCurrency(this.state.old_net_revenue)}</div>
                    <div>New Clients: {this.state.user_count}</div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}