import styled, {css} from 'styled-components';

import { LANDING_PAGE } from '../../../../assets/images/landing';

export const NavBarContainer = styled.div`
  padding-top: 5px;
  position: relative;

  ${props => !props.disableWaveBackground && css`
    &::before {
      content: '';
      height: 560px;
      width: 45%;
      position: absolute;
      top: -75%;
      right: -4%;
      background-image: url(${LANDING_PAGE.TopRightWave});
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 0;
      @media screen and (max-width: 1399px) {
        top: -45%;
        right: -5%;
      }
      @media screen and (min-width: 2200px) {
        right: -12%;
      }
    }
  `}
  
  .container {
    .navbar {
      width: 100%;
      padding: 0px;
      ul {
        width: 100%;
        margin-left: 120px;
        align-items: center;
        li {
          padding: 0px 0px;
          a {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 46px;
            color: #353844 !important;
            text-align: center;
            padding-top: 13px;

            &:hover {
              color: #353844 !important;
            }
          }
        }
        li:nth-child(-n + 7) {
          padding: 0px 18px;
          @media screen and (max-width: 1323px) {
            padding: 0px 11px;
          }
          @media screen and (max-width: 1154px) {
            padding: 0px 7px;
          }
          @media screen and (max-width: 1081px) {
            padding: 0px 3px;
          }
        }
        @media screen and (max-width: 1200px) {
          margin-left: 50px;
        }
        /* @media screen and (max-width: 1154px) {
          margin-left: 20px;
        } */
      }
      .servicesDropDown {
        width: 160px;
        background-color: #ffffff;
        border-radius: 0px 0px 12px 12px;
        padding: 3px 18px;

        margin-top: -15px;
        margin-left: -10px;

        a {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 30px;
          text-align: left;
          color: #526079 !important;
          padding: 0px;
          background-color: #ffffff;
          margin-top: 2px;
          &:hover {
            background-color: #ffffff;
            color: #526079 !important;
          }
        }
      }
      .navMenuDropDown {
        width: 160px;
        background-color: #ffffff;
        border-radius: 0px 0px 12px 12px;
        padding: 3px 14px;
        right: 0;
        top: 2px !important;

        a {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 30px;
          text-align: right;
          color: #526079 !important;
          padding: 0px;
          background-color: #ffffff;
          margin-top: 2px;
          &:hover {
            background-color: #ffffff;
            color: #526079 !important;
          }
        }
      }
    }


    @media screen and (min-width: 768px) {
      max-width: 100vw;
    }
    @media screen and (min-width: 942px) {
      .navbar-collapse {
        padding-left: 0px;
      }
      .navbar ul {
        margin-left: 10px;
      }
    }
    @media screen and (min-width: 1200px) {
      max-width: 1320px;
      .navbar ul {
        // margin-left: 120px;
      }
    }
    @media screen and (max-width: 1300px) {
      .navbar ul li:nth-child(-n + 7) {
        padding: 0px 6px;
      }
    }
    @media screen and (max-width: 980px) {
      .navbar ul li:nth-child(-n + 7) {
        padding: 0px 3px;
      }
    }
  }
`;

export const NavBarContainer2 = styled.div`
  padding-top: 5px;
  position: relative;
  .container {
    .navbar {
      width: 100%;
      padding: 0px;
      ul {
        width: 100%;
        margin-left: 120px;
        align-items: center;
        li {
          padding: 0px 0px;
          white-space: nowrap;
          a {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 46px;
            color: #353844 !important;
            text-align: center;
            padding-top: 13px;

            &:hover {
              color: #353844 !important;
            }
          }
        }
        li:nth-child(-n + 7) {
          padding: 0px 18px;
          @media screen and (max-width: 1323px) {
            padding: 0px 11px;
          }
          @media screen and (max-width: 1154px) {
            padding: 0px 7px;
          }
          @media screen and (max-width: 1081px) {
            padding: 0px 3px;
          }
        }
        @media screen and (max-width: 1200px) {
          margin-left: 50px;
        }
        /* @media screen and (max-width: 1154px) {
          margin-left: 20px;
        } */
      }
      .servicesDropDown {
        width: 160px;
        background-color: #ffffff;
        border-radius: 0px 0px 12px 12px;
        padding: 3px 18px;

        margin-top: -15px;
        margin-left: -10px;

        a {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 30px;
          text-align: left;
          color: #526079 !important;
          padding: 0px;
          background-color: #ffffff;
          margin-top: 2px;
          &:hover {
            background-color: #ffffff;
            color: #526079 !important;
          }
        }
      }
      .navMenuDropDown {
        width: 160px;
        background-color: #ffffff;
        border-radius: 0px 0px 12px 12px;
        padding: 3px 14px;
        right: 0;
        top: 2px !important;

        a {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 30px;
          text-align: right;
          color: #526079 !important;
          padding: 0px;
          background-color: #ffffff;
          margin-top: 2px;
          &:hover {
            background-color: #ffffff;
            color: #526079 !important;
          }
        }
      }
    }


    @media screen and (min-width: 768px) {
      max-width: 1000px;
    }
    @media screen and (min-width: 942px) {
      .navbar-collapse {
        padding-left: 0px;
      }
      .navbar ul {
        margin-left: 10px;
      }
    }
    @media screen and (min-width: 1200px) {
      max-width: 1320px;
      .navbar ul {
        margin-left: 120px;
      }
    }
    @media screen and (max-width: 1300px) {
      .navbar ul li:nth-child(-n + 7) {
        padding: 0px 6px;
      }
    }
    @media screen and (max-width: 980px) {
      .navbar ul li:nth-child(-n + 7) {
        padding: 0px 3px;
      }
    }
  }
`;

export const NavBarImage = styled.img`
  @media only screen and (max-width: 1200px) {
    width: 159px;
  }
`;

export const LoginButton = styled.button`
  width: 118px;
  height: 45px;
  background: linear-gradient(0deg, #eaf1f6, #eaf1f6), #ffffff;
  border-radius: 8px;
  border: 0px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #1b264f;

  &:hover {
    background: #d8e0ff;
  }
`;

export const DashboardButton = styled.button`
  width: 134px;
  height: 45px;
  background-color: #1b264f;
  border-radius: 8px;
  border: 0px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;

  &:hover {
    background-color: #324489;
  }
`;


export const SignUpButton = styled.button`
  width: 160px;
  height: 45px;
  background-color: #1b264f;
  border-radius: 8px;
  border: 0px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;

  &:hover {
    background-color: #324489;
  }
`;

export const ServicesDropDownIcon = styled.img`
  margin-left: 3px;
`;

export const NavMenuImage = styled.img`
  width: 22px;
  height: 30px;
`;

export const NavbarContainer = styled.div`
  padding: 24px 20px 24px 121px;
  .logo-image {
    line-height: 46px;
    img {
      width: auto;
    }
  }
  .nav-pills {
    line-height: 30px;
  }
  ul li {
    padding: 0px 10px;
    a {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #353844;
    }
  }
  li .remove:after {
    display: none !important;
  }
  .row {
    margin: 0px;
  }
  .login-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1b264f;
    background: linear-gradient(0deg, #eaf1f6, #eaf1f6), #ffffff;
    border-radius: 8px;
    min-width: 118px;
    height: 46px;
  }
  .signup-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    width: 134px;
    height: 46px;
    background: #1b264f;
    border-radius: 8px;
    margin-left: 20px !important;
  }
  .menu-btn {
    background: none;
    border: none;
    padding-left: 42px;
  }
`;
