import { connect } from "react-redux";
import SettingsPayouts from "./settings_payouts";
import React from "react";
import { withRouter } from "react-router-dom";
import { fetchPayouts, createExternalAccount } from "../../../actions/settings_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id]
});

const mdp = (dispatch) => ({
  fetchPayouts: (user_id) => dispatch(fetchPayouts(user_id)),
  createExternalAccount: (external_account) => dispatch(createExternalAccount(external_account)),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsPayouts));