import React from "react";
import { Route, Link } from "react-router-dom";


export default class CreateService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      shortName: "",
      servicor: "",
      mediaTypes: "image",
      whiteServiceIcon: "",
      blackServiceIcon: "",
      serviceDescription: "",
      sortOrder: "",
      hasQuantity: false,
      reshoot: false,
      hidden: false,
      twilight: false,
      allowOnlyOneSelection: true,
      sub_services_attributes: {}
    }
    this.handleInput = this.handleInput.bind(this)
    this.createService = this.createService.bind(this)
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }
  
  handleCheckbox(field){
    return e => this.setState({[field]: !this.state[field]})
  }


  createService() {
    let service_info = {title: this.state.title, short_name: this.state.shortName || this.state.title, servicor: this.state.servicor, media_types: this.state.mediaTypes, white_icon: this.state.whiteServiceIcon, black_icon: this.state.blackServiceIcon, description: this.state.serviceDescription, sort_order: this.state.sortOrder, has_quantity: this.state.hasQuantity, reshoot: this.state.reshoot, twilight: this.state.twilight, one_option_allowed: this.state.allowOnlyOneSelection, hidden: this.state.hidden}
    this.props.createService(service_info).then(() => {
      if (this.props.errors.length === 0) {
        this.props.showToast("Service has been added")
        this.props.closeModal()
      }
    })
  }



  render() {


    let companyNameErrorClass = "";
    let agentMlsIdErrorClass = "";
    let companyAddressErrorClass = "";
    let companyCityErrorClass = "";
    let companyStateErrorClass = "";
    let companyZipErrorClass = "";


    let companyNameErrors = [];
    let agentMlsIdErrors = [];
    let companyAddressErrors = [];
    let companyCityErrors = [];
    let companyStateErrors = [];
    let companyZipErrors = [];



    return (
      <div className="settingsModalComponent">
        <div className="settingsModal">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading">Add Service</div>
            <div className="closeIcon" onClick={() => this.props.closeModal()}><i className="fas fa-times"></i></div>
          </div>
          <div className="settingsCategoryGroup1">
              <div className="settingsServicesInput">
                <label id="formLabel"> Service Title </label>
                <input className="formInput" id={companyNameErrorClass} onChange={this.handleInput("title")} type="string" placeholder="Service Title" value={this.state.title} />
                <ul className="errorUl">{companyNameErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Short Name </label>
                <input className="formInput" id={agentMlsIdErrorClass} onChange={this.handleInput("shortName")} type="string" placeholder="Short Name" value={this.state.shortName} />
                <ul className="errorUl">{agentMlsIdErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Servicor</label>
                <input className="formInput" id={companyAddressErrorClass} onChange={this.handleInput("servicor")} type="string" placeholder="Servicor" value={this.state.servicor} />
                <ul className="errorUl">{companyAddressErrors}</ul>
              </div>
              <div className="settingsServicesInput settingsDropdownInput">
                <label id="formLabel">Media Type</label>
                <select className="formInput settingsDropdownFormInput" onChange={this.handleInput("mediaTypes")} name="mediaTypes" value={this.state.mediaTypes} >
                  <option value="" disabled={true}>Media Type</option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                  <option value="matterport">Matterport</option>
                </select>
              </div>
              {/* <div className="settingsServicesInput">
                <label id="formLabel"> White Service Icon </label>
                <input className="formInput" id={companyStateErrorClass} onChange={this.handleInput("whiteServiceIcon")} type="string" placeholder="White Service Icon" value={this.state.whiteServiceIcon} />
                <ul className="errorUl">{companyStateErrors}</ul>
              </div> */}
              <div className="settingsServicesInput">
                <label id="formLabel"> Black Service Icon </label>
                <input className="formInput" id={companyZipErrorClass} onChange={this.handleInput("blackServiceIcon")} type="string" placeholder="Black Service Icon" value={this.state.blackServiceIcon} />
                <ul className="errorUl">{companyZipErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Service Description </label>
                <textarea className="formTextArea" id={companyZipErrorClass} onChange={this.handleInput("serviceDescription")} placeholder="Service Description" value={this.state.serviceDescription} />
                <ul className="errorUl">{companyZipErrors}</ul>
              </div>
              <div className="settingsServicesInput">
                <label id="formLabel"> Sort Order </label>
                <input className="formInput" id={companyZipErrorClass} onChange={this.handleInput("sortOrder")} type="number" placeholder="Sort Order" value={this.state.sortOrder} />
                <ul className="errorUl">{companyZipErrors}</ul>
              </div>
            <div className="settingsCheckboxGroup">
              {/* <label><input type="checkbox" className="formCheckBox" checked={this.state.hasQuantity} onChange={this.handleCheckbox("hasQuantity")} /> <span className="rememberMe">Has Quantity </span></label> */}
              <label><input type="checkbox" className="formCheckBox" checked={this.state.hidden} onChange={this.handleCheckbox("hidden")} /> <span className="rememberMe">Hidden</span></label>
              <label><input type="checkbox" className="formCheckBox" checked={this.state.reshoot} onChange={this.handleCheckbox("reshoot")} /> <span className="rememberMe">Reshoot</span></label>
              <label><input type="checkbox" className="formCheckBox" checked={this.state.twilight} onChange={this.handleCheckbox("twilight")} /> <span className="rememberMe">Twilight</span></label>
              <label><input type="checkbox" className="formCheckBox" checked={this.state.allowOnlyOneSelection} onChange={this.handleCheckbox("allowOnlyOneSelection")} /> <span className="rememberMe">Only Allow One Selection?</span></label>
            </div>
          </div>
          <button onClick={this.createService} className="settingsButton companyButton">Create Service</button>
        </div>
      </div>
    );
  }
}