export const ROLE_STEP = 'ROLE_STEP'
export const GENERAL_INFO_STEP = 'GENERAL_INFO_STEP'
export const BUSINESS_DETAILS_STEP = 'BUSINESS_DETAILS_STEP'
export const SERVICES_STEP = 'SERVICES_STEP'
export const CALENDAR_STEP = 'CALENDAR_STEP'
export const COMPLETED_STEP = 'COMPLETED_STEP'

export const STEPS = [
  ROLE_STEP, GENERAL_INFO_STEP, BUSINESS_DETAILS_STEP, SERVICES_STEP, CALENDAR_STEP, COMPLETED_STEP
]

export const times = [
  "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM",
  "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM"];
