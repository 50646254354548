import { connect } from "react-redux";
import SettingsTimeSlots from "./settings_time_slots";
import React from "react";
import { withRouter } from "react-router-dom";
import { updateBlockedTimes, fetchBlockedTimeSlots, googleCalendarIntegration} from "../../../actions/settings_actions";
import {showToast} from "../../../actions/toast_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  currentUser: state.entities.users[state.session.currentUser.id],
  services: state.entities.services
});

const mdp = (dispatch) => ({
  fetchBlockedTimeSlots: (user_id) => dispatch(fetchBlockedTimeSlots(user_id)),
  updateBlockedTimes: (blockedTimes, user_id) => dispatch(updateBlockedTimes(blockedTimes, user_id)),
  googleCalendarIntegration: () => dispatch(googleCalendarIntegration()),
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(SettingsTimeSlots));