import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import UserAvatar from "../photographers/photograher_avatar";
import {formatDatetime} from "../../util/date_util";

class CreateProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      paymentInfo: {},
      formerPaymentInfo: {},
      createPayment: false,
      errors: [],
      current_cvc: ""
    }

    this.handleInput = this.handleInput.bind(this)
    this.createPayment = this.createPayment.bind(this)
    this.submitOrderSkipPayment = this.submitOrderSkipPayment.bind(this)
  }


  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.props.fetchPaymentInfo(this.props.sub_broker_client ? this.props.sub_broker_client : this.props.currentUser.id).then(paymentInfo =>
      this.setState({ paymentInfo: paymentInfo, formerPaymentInfo: JSON.parse(JSON.stringify(paymentInfo)), createPayment: Object.values(paymentInfo).length === 0})
    ).then(() => {
      let code = "XXXX-XXXX-XXXX-"
      let paymentInfo = this.state.paymentInfo
      let current_cvc = ""
      if (paymentInfo["last_four"]) {
        paymentInfo["last_four"] = code + paymentInfo["last_four"]
      }
      if (paymentInfo["cvc"]) {
        current_cvc = paymentInfo["cvc"]
        paymentInfo["cvc"] = paymentInfo["cvc"]?.length === 3 ? "XXX" : "XXXX"
      }
      if (paymentInfo.payment_service_id){
        this.setState({ paymentInfo: paymentInfo, current_cvc: current_cvc })
      } else {
        this.setState({paymentInfo: {}})
      }
    })
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  createPayment() {
    let errors = []
    let paymentInfo = this.state.paymentInfo;
    let formerPaymentInfo = this.state.formerPaymentInfo;

    paymentInfo["user_id"] = this.props.sub_broker_client ? parseInt(this.props.sub_broker_client) : this.props.currentUser.id

    if (!paymentInfo["cardholder_name"] || paymentInfo["cardholder_name"].length === 0) {
      errors.push([0, "Name can't be blank"])
    }

    if (!paymentInfo["cvc"] || (paymentInfo["cvc"].length != 3 && paymentInfo["cvc"].length != 4)) {
      errors.push([1, "CVC code must be 3 or 4 digits long"])
    }

    if (paymentInfo["cvc"] === "XXX" || paymentInfo["cvc"] === "XXXX" || paymentInfo["cvc"] === this.state.current_cvc) {
      paymentInfo["cvc"] = this.state.current_cvc
    }

    if (!paymentInfo["last_four"] || (paymentInfo["last_four"].length != 16 && paymentInfo["last_four"].length != 15 && paymentInfo["last_four"].length != 19)) {
      errors.push([2, "Card number must be 15 or 16 digits long"])
    }

    if (paymentInfo["last_four"] && paymentInfo["last_four"].length > 0 && paymentInfo["last_four"][0] === "X") {
      delete paymentInfo["last_four"]
      delete formerPaymentInfo["last_four"]
    }

    if (paymentInfo["exp_month"] === "Month") {
      errors.push([3, "Expiration month is required"])
    }

    if (paymentInfo["exp_year"] === "Year") {
      errors.push([4, "Expiration year is required"])
    }

    if (errors.length === 0 && !this.state.createPayment && JSON.stringify(paymentInfo) === JSON.stringify(formerPaymentInfo)){
      this.setState({page: 3})
      this.props.submitOrder()
    }
    else if (errors.length === 0) {
      // if (!paymentInfo["cvc"]) {
      //   errors.push([1, "CVC code required to make changes to payment info"])
      // }

      if (!paymentInfo["last_four"]) {
        errors.push([2, "Full card number required to make changes to payment info"])
      }

      if (errors.length === 0){
        this.props.createPayment(paymentInfo).then((payment) => {
          if (payment.errors){
            errors.push([5, payment.errors])
            this.setState({errors: errors})
          } else {
            this.props.submitOrder()
            this.setState({ page: 3 })
          }
        })
      } else {
        this.setState({ errors: errors })
      }
    } else {
      this.setState({ errors: errors })
    }
  }

  submitOrderSkipPayment(){
    this.setState({ page: 3 })
    this.props.submitOrder()
  }

  handleInput(field) {
    return e => {
      let paymentInfo = this.state.paymentInfo

      if ((field === "cvc" || field === "last_four") && paymentInfo[field]?.length > 0 && paymentInfo[field][0] === "X") {
        if (e.currentTarget.value.length === 2 || e.currentTarget.value.length === 18) {
          paymentInfo[field] = ""
        } else {
          paymentInfo[field] = e.currentTarget.value[e.currentTarget.value.length - 1]
        }
      }
      else {
        if ((field === "cvc" && e.currentTarget.value.length > 4) || (field === "last_four" && e.currentTarget.value.length > 16)) {

        } else {
          paymentInfo[field] = e.currentTarget.value
        }
      }
      this.setState({ paymentInfo: paymentInfo });
    }
  }


  render() {
  if (this.state.page === 1){
    let price = 0;
    let account_credit_component = "";
    let subServices = []
    let bundleSubserviceIds = this.props.selected_bundle?.sub_services?.map(subservice => subservice.id) || [];

    subServices = Object.values(this.props.subservices).filter(sub_service => !bundleSubserviceIds.includes(sub_service.id)).map(subservice => {
      price += parseInt(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price)
      return (<div key={subservice.id} className="orderSummaryLi">
        <div>{subservice.title}</div>
        <div className="orderSummarySubservicePrice">${parseInt(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price).toFixed(0)}</div>
      </div>)
    })

    if (this.props.selected_bundle?.id) {
      let bundle = this.props.selected_bundle
      let bundle_price = 0
      bundle.sub_services.forEach(subservice => {
        bundle_price += parseFloat(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price)
      })

      bundle_price = bundle_price * (1 - (bundle.percent_discount / 100))

      price += Math.ceil(bundle_price)

      subServices.push(<div className="orderSummaryLi">
        <div>{bundle.title}</div>
        <div>
          <div className="orderSummarySubservicePrice">${Math.ceil(bundle_price).toFixed(0)}</div>
        </div>
      </div>)
    }
    

    let photographer_appointment_groupings = {}


    let photographersLis = Object.values(this.props.appointments).map(appointment => {
      if (!photographer_appointment_groupings[`${appointment.contractor.id}-${appointment.appointment_time}`]) {
      photographer_appointment_groupings[`${appointment.contractor.id}-${appointment.appointment_time}`] = true
      return <div className="orderSummaryPhotographerLi" key={appointment.id}>
        <UserAvatar user={appointment.contractor} />
        <div className="contractorNameAndAppointment">
          <div>{appointment.contractor.firstName} {appointment.contractor.lastName}</div>
          <div>{formatDatetime(appointment.appointment_time, null, this.props.detected_timezone || this.props.client_timezone)}</div>
        </div>
      </div>
      }})

    if (this.props.promo_price) {
      let promo_discount = price - this.props.promo_price

      subServices.push(<div className="orderSummaryLi">
        <div>{this.props.promo_name} Promo Applied: </div>
        <div className="orderSummarySubservicePrice">{promo_discount > 0 ? "-" : ""}${Math.abs(promo_discount).toFixed(0)}</div>
      </div>)

      price = this.props.promo_price
    }

    if (this.props.coupon?.id) {
      let discount_coupon_amount = 0
      if (this.props.coupon.percentage) {
        discount_coupon_amount = parseInt(price * this.props.coupon.amount / 100)
        price -= parseInt(price * this.props.coupon.amount / 100)
      } else {
        discount_coupon_amount = this.props.coupon.amount
        price -= this.props.coupon.amount
      }

      subServices.push(<div className="orderSummaryLi">
        <div>{this.props.coupon.code}</div>
        <div className="orderSummarySubservicePrice">-${parseFloat(discount_coupon_amount).toFixed(0)}</div>
      </div>)
    }


    if (this.props.discount > 0) {
      subServices.push(<div className="orderSummaryLi">
        <div>Account credit applied: </div>
        <div className="orderSummarySubservicePrice">-${this.props.discount.toFixed(0)}</div>
      </div>)
    }

    // if (this.props.account_credit >= 0) {
    //   account_credit_component = <div className="accountCredit">Remaining Account Credit: ${this.props.account_credit.toFixed(2)}</div>
    // }

    price -= this.props.discount

    let button = <div className="signupFormNext specialButtonNoWidth" onClick={() => this.setState({ page: 2 })}>Continue to Payment</div>
    if ((this.props.currentUser.roles.admin && this.props.sub_broker_client === "") || this.props.currentUser.roles.broker || (this.props.currentUser.roles.sub_broker && this.props.currentUser.firm.broker_charge) || this.props.client_role === "broker" || (this.props.client_role === "sub_broker" && this.props.firm?.broker_charge)){
      button = <div className="signupFormNext specialButtonNoWidth" onClick={this.submitOrderSkipPayment}>Submit Order</div>
    }

    return (
      <div className="modal-background">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
        <div className="orderPreviewModalComponent">
        <div className="uploadPhotoModalHeadingGroup">
          <div className="uploadPhotoModalHeading"> Order Preview</div>
          <div className="closeIcon" onClick={() => this.props.changeParentState({ modalOpen: false })}><i className="fas fa-times"></i></div>
        </div>
        <div className="orderPreviewModalCategory">
          <div className="settingsComponentHeading">Property Details</div>
              <div className="addressPreviewModal">{this.props.apartment} {this.props.formatted_address}</div>
        </div>
         <div className="orderPreviewModalCategory">
          <div className="settingsComponentHeading">Service Details</div>
            <div className="previewOrderListModal">
            {subServices}
            </div>
          </div>
          {photographersLis.length > 1 && !this.props.isLargeScreen ? "" : 
          <div className="orderPreviewModalCategory">
            <div className="settingsComponentHeading">Photographers</div>
              <div className="previewOrderListModal">
              {photographersLis}
            </div>
          </div>
          }
          <div className="orderSummaryPriceDiv orderSummaryModalDiv">
            <div className="createProjectModalTotal">Total: </div>
            <div>${price.toFixed(0)}</div>
          </div>
          {account_credit_component}
          <div className="buttonDivModal">
           {button}
          </div>
        </div>
      </div>
      </div>
    )
  }
  else if (this.state.page === 2) {
    let price = 0;
    let bundleSubserviceIds = this.props.selected_bundle?.sub_services?.map(subservice => subservice.id) || [];

    Object.values(this.props.subservices).filter(sub_service => !bundleSubserviceIds.includes(sub_service.id)).forEach(subservice => {
      price += parseInt(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price)
    })

    if (this.props.promo_price){
      price = this.props.promo_price
    }


    if (this.props.selected_bundle?.id){
      let bundle = this.props.selected_bundle
      let bundle_price = 0
      bundle.sub_services.forEach(subservice => {
        bundle_price += parseFloat(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price)
      })

      bundle_price = bundle_price * (1 - (bundle.percent_discount / 100))

      price += Math.ceil(bundle_price)
    }

    if (this.props.coupon?.id) {
      if (this.props.coupon.percentage) {
        price -= parseInt(price * this.props.coupon.amount / 100)
      } else {
        price -= this.props.coupon.amount
      }
    }

    price -= this.props.discount

    let cardHolderNameErrorClass = "";
    let cardNumberErrorClass = "";
    let expMonthErrorClass = "";
    let expYearErrorClass = "";
    let cvcErrorClass = "";

    let cardNumberErrors = [];
    let cardHolderNameErrors = [];
    let expMonthErrors = [];
    let expYearErrors = [];
    let cvcErrors = [];
    let backendErrors = [];

    const yearOptions = [];
    let d = new Date();
    let n = d.getFullYear();
    for (let i = n; i < n + 20; i++) {
      yearOptions.push(<option key={i} value={i}>{i}</option>);
    }

    this.state.errors.forEach((error) => {

      if (error[0] === 0) {
        cardHolderNameErrors.push(<li>{error[1]}</li>)
        cardHolderNameErrorClass = "formInputError"
      }

      else if (error[0] === 1) {
        cvcErrors.push(<li>{error[1]}</li>)
        cvcErrorClass = "formInputError"
      }

      else if (error[0] === 2) {
        cardNumberErrors.push(<li>{error[1]}</li>)
        cardNumberErrorClass = "formInputError"
      }

      else if (error[0] === 3) {
        expMonthErrors.push(<li>{error[1]}</li>)
        expMonthErrorClass = "formInputError"
      }

      else if (error[0] === 4) {
        expYearErrors.push(<li>{error[1]}</li>)
        expYearErrorClass = "formInputError"
      }
      else if (error[0] === 5) {
        backendErrors.push(<li>{error[1]}</li>)
      }
    })
    
    return (
      <div className="modal-background">
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
        <div className="orderPreviewModalComponent">
          <div className="uploadPhotoModalHeadingGroup">
            <div className="uploadPhotoModalHeading"> Checkout</div>
            <div className="closeIcon" onClick={() => this.props.changeParentState({ modalOpen: false })}><i className="fas fa-times"></i></div>
          </div>
          <div className="orderPreviewModalCategory">
            <div className="settingsComponentHeading">Payment Details</div>
              <div className="refundDisclaimer refundDisclaimerModal">Refunds (-5% processing fee) and full account credits are available if the appointment is canceled. Rescheduling can be done from the project page.</div>
              <ul className="errorUl backendErrorsPayment">{backendErrors}</ul>
                <div className="createProjectPaymentModal">
                  <div className="createProjectPaymentGroupings">
                    <div className="settingsCategoryGroup1">
                      <div className="inputGrouping paymentModalGrouping">
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Cardholder Name </label>
                          <input className="formInput" id={cardHolderNameErrorClass} onChange={this.handleInput("cardholder_name")} type="string" placeholder="Cardholder Name" value={this.state.paymentInfo["cardholder_name"] || ""} />
                          <ul className="errorUl">{cardHolderNameErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Card Number </label>
                          <input className="formInput" id={cardNumberErrorClass} onChange={this.handleInput("last_four")} type="string" placeholder="Card Number" value={this.state.paymentInfo["last_four"] || ""} />
                          <ul className="errorUl">{cardNumberErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> CVC </label>
                          <input className="formInput" id={cvcErrorClass} onChange={this.handleInput("cvc")} type="string" placeholder="CVC" value={this.state.paymentInfo["cvc"] || ""} />
                          <ul className="errorUl">{cvcErrors}</ul>
                        </div>
                      </div>
                      <div className="inputGrouping paymentModalGrouping">
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Expiration Month</label>
                          <select className="formInput" id={expMonthErrorClass} onChange={this.handleInput("exp_month")} name="Month" value={this.state.paymentInfo["exp_month"] || "Month"} >
                            <option value="Month" disabled={true}>Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                          <ul className="errorUl">{expMonthErrors}</ul>
                        </div>
                        <div className="settingsCompanyInput paymentModalInput">
                          <label id="formLabel"> Expiration Year </label>
                          <select className="formInput" id={expYearErrorClass} onChange={this.handleInput("exp_year")} name="Year" value={this.state.paymentInfo["exp_year"] || "Year"} >
                            <option value="Year" disabled={true}>Year</option>
                            {yearOptions}
                          </select>
                          <ul className="errorUl">{expYearErrors}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <div className="orderSummaryPriceDiv orderSummaryModalDiv">
            <div className="createProjectModalTotal">Total: </div>
            <div>${price.toFixed(0)}</div>
          </div>
            <div className="buttonDivModal">
              <button className="signupFormNext specialButtonNoWidth" onClick={this.createPayment}>Submit Order</button>
            </div>
          </div>
          <div className="modalMobilePushup"></div>
        </div>
      </div>
    )


  }
  else {
    return(
       <div className="modal-background">
          <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
            <div className="orderPreviewModalComponent">
              <div className="uploadPhotoModalHeadingGroup">
                <div className="uploadPhotoModalHeading"> Checkout</div>
              </div>
              <div className="orderPreviewModalCategory">
                <div className="settingsComponentHeading">Submitting Order...</div>
                <div className="createProjectPaymentModal">
                  <div className="loaderDiv">
                    {/* <Loader
                      type="Oval"
                      color="#4E57AA"
                      height={100}
                      width={100}
                      timeout={30000} //3 secs
                    /> */}
                    <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                  </div>
              </div>
              </div>
            </div>
           <div className="modalMobilePushup"></div>
          </div>
        </div>
    )
  }
  }
}

export default withRouter(CreateProjectModal);