import React, {useEffect, useState} from 'react'
import bankImg from "../../../../../assets/images/photographerOnboarding/bank.svg";
import {Footer} from "../footer";
import stripeImg from "../../../../../assets/images/photographerOnboarding/stripe.svg";
import lockImg from "../../../../../assets/images/photographerOnboarding/lock.svg";
import {createExternalAccount, fetchEnablePayoutLink} from "../../../util/settings_api_util";

export const Bank = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(props.data || {
    accountHolderName: '',
    accountNumber: '',
    routingNumber: '',
    firstFormSubmitted: false
  })
  const [errors, setErrors] = useState([])
  const firstFormSubmitted = data.firstFormSubmitted

  useEffect(() => {
    if (data.firstFormSubmitted) props.saveData(data)
  }, [data]);

  const onChange = (fieldName) => {
    return (e) => {
      e.preventDefault()
      setData({...data, [fieldName]: e.target.value})
    }
  }

  const onFirstFormSubmit = (e) => {
    e.preventDefault()

    const payload = {
      user_id: props.userId,
      currency: "usd",
      country: "US",
      account_holder_name: data.accountHolderName,
      account_number: data.accountNumber,
      routing_number: data.routingNumber
    }

    setErrors([])
    setLoading(true)

    createExternalAccount(payload).then(result => {
      setLoading(false)

      if (result.success) {
        setData({...data, firstFormSubmitted: true})
      } else {
        setErrors(result.errors)
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  const onConnectWithStripe = (e) => {
    e.preventDefault()

    setErrors([])
    fetchEnablePayoutLink(location.href).then(info => {
      if (info.url) {
        location.href = info.url
      } else {
        setErrors(['Unexpected error occurred'])
      }
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    onComplete(data)
  }

  const onSkip = () => {
    onComplete(props.data)
  }

  const onComplete = (data) => {
    setLoading(true)
    setErrors([])

    return props.onComplete().then(() => {
      setLoading(false)
      props.onNext(data)
    }).catch(() => {
      setLoading(false)
      setErrors(['Unexpected error occurred'])
    })
  }

  return <form onSubmit={onSubmit}>
    <div className="photographer-onboarding-form-title">
      Set up your <span className="blue">bank</span> info
    </div>

    <div className="photographer-onboarding-center-image-wrapper">
      <img src={bankImg}/>
    </div>

    <div>
      {errors.length > 0 && <div className="alert alert-danger mb-4">
        {errors.map((err, i) => (<div key={`error-${i}`}>{err}</div>))}
      </div>}

      {!firstFormSubmitted && <>
        <div className="photographer-onboarding-form-fields mar-bottom-10">
          <div className="photographer-onboarding-form-field">
            <label id="formLabel" className="formLabel required">Account Holder Name</label>
            <input className="formInput" placeholder="Content" value={data.accountHolderName}
                   onInput={onChange('accountHolderName')} required={true}/>
          </div>

          <div className="photographer-onboarding-form-field">
            <label id="formLabel" className="formLabel required">Account Number</label>
            <input className="formInput" placeholder="Content" value={data.accountNumber}
                   onInput={onChange('accountNumber')} required={true}/>
          </div>

          <div className="photographer-onboarding-form-field">
            <label id="formLabel" className="formLabel required">Routing Number</label>
            <input className="formInput" placeholder="Content" value={data.routingNumber}
                   onInput={onChange('routingNumber')} required={true}/>
          </div>
        </div>

        <button
          className="projectEditButton projectEditButtonPrimary photographer-onboarding-bank-submit-button mar-bottom-20 w-100"
          type="button" disabled={loading} onClick={onFirstFormSubmit}>
          Submit
        </button>
      </>}
    </div>

    {firstFormSubmitted && <>
      <button
        className="projectEditButton projectEditButtonLight photographer-onboarding-connect-button photographer-onboarding-connect-stripe-button mar-bottom-20"
        type="button" onClick={onConnectWithStripe}>
        <img src={stripeImg}/>
        <span>Connect with Stripe</span>
        <img src={lockImg}/>
      </button>

      <div className="photographer-onboarding-paragraph-text mar-bottom-20">
        Lastly, click the button above to set up your Stripe account which is required for us to make a deposit into your bank.
      </div>

      {/* <div className="photographer-onboarding-connect-stripe-fields">
        {['Legal Name', 'Account number', 'Routing number'].map(field => (
          <div className="photographer-onboarding-connect-stripe-field">{field}</div>
        ))}
      </div> */}
    </>}

    <div className="photographer-onboarding-paragraph-text mar-bottom-20">
      You can also complete this step later when you are ready to get paid for a shoot.
    </div>

    <Footer onBack={props.onBack}
            onSkip={onSkip}
            skipDisabled={loading}
            nextDisabled={!firstFormSubmitted || loading}/>
  </form>
}