import {SERVICES} from "../../../../../assets/images/servicesPages";

export const FULL_SERVICE_PHOTOGRAPHY_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'What is HDR Photography?',
    content:
      'High Dynamic Range (HDR) involves taking multiple shots at different exposures to capture a wide range of details, ensuring perfectly balanced lighting inside and out.',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Crystal Clear Window Views',
    content:
      'HDR technology achieves clear window views by capturing and merging multiple exposures of a scene, preserving intricate details in both bright and dark areas.',
  },
  {
    icon: SERVICES.ListIcon3,
    title: 'True-Color Accuracy',
    content:
      'By expanding the spectrum of tones and hues, HDR enhances color depth and accuracy, presenting a rich and vibrant representation of the property\'s visual palette, ensuring an immersive viewing experience.',
  },
];

export const FULL_SERVICE_DRONE_DATA = [
  {
    icon: SERVICES.ListIcon1,
    title: 'Captivating Visual Narratives',
    content:
      'Engage your audience with dynamic drone videography, showcasing properties and landscapes with immersive, cinematic footage that brings your listings to life.',
  },
  {
    icon: SERVICES.ListIcon2,
    title: 'Showcase Your Listing From Above',
    content:
      'Drone photography and video showcase property features and surroundings from stunning aerial perspectives, offering a vivid portrayal for your audience.',
  }
];