import React from "react"

export const stars = (rating) => {
  let star_rating = []

  for (let i = 0; i < Math.floor(rating); i++) {
    star_rating.push(<span key={`full-rating-${i}`} className="fa fa-star starRating" />)
  }

  if (rating % 1 > .3) {
    star_rating.push(<span key={'partial-rating'} className="fas fa-star-half-alt starRating" />)
  }

  let length = 5 - star_rating.length
  for (let i = 0; i < length; i++) {
    star_rating.push(<span key={`empty-rating-${i}`} className="far fa-star starRating" />)
  }

  return star_rating
}

export const stars2 = (rating) => {
  let star_rating = []

  for (let i = 0; i < Math.floor(rating); i++) {
    star_rating.push(<span key={`full-rating-${i}`} className="fa fa-star starRating2" />)
  }

  if (rating % 1 > .3) {
    star_rating.push(<span key={'partial-rating'} className="fas fa-star-half-alt starRating2" />)
  }

  let length = 5 - star_rating.length
  for (let i = 0; i < length; i++) {
    star_rating.push(<span key={`empty-rating-${i}`} className="far fa-star starRating2" />)
  }

  return star_rating
}
