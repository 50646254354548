import { connect } from "react-redux";
import VirtualStagingProducts from "./virtual_staging_products";
import React from "react";
import { withRouter } from "react-router-dom"
import { fetchVirtualStagingProducts, receiveProducts } from "../../actions/virtual_staging_actions";

const msp = (state, ownProps) => ({
  errors: Array.isArray(state.errors?.session) ? state.errors?.session : [],
  products: Object.values(state.entities.virtualStaging.products)
});

const mdp = (dispatch) => ({
  fetchVirtualStagingProducts: (category_id) => dispatch(fetchVirtualStagingProducts(category_id)),
  resetVirtualStagingProducts: () => { dispatch(receiveProducts({}))}
});

export default withRouter(connect(msp, mdp)(VirtualStagingProducts));