import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import Cloud from '../../../../assets/images/cloud';

class DownloadModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      images: [],
      images_src: [],
      photoGroup: 0,
      streamAmount: this.props.isLargeScreen ? 9 : 8
    }

    this.handlePicturesSelected = this.handlePicturesSelected.bind(this)
    this.downloadPhotos = this.downloadPhotos.bind(this)
    this.openInNewTab = this.openInNewTab.bind(this)
  }


  componentDidMount() {

  }

  componentWillUnmount() {
    this.state.images_src.forEach(image_src => {
      URL.revokeObjectURL(image_src)
    })
  }

  openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  
  downloadPhotos(download_type) {
    if (download_type === "processed"){
      if (this.props.project.processed_zip_worker_file){
        this.openInNewTab(this.props.project.processed_zip_worker_file)
      }
    } else {
      if (this.props.project.unprocessed_zip_worker_file){
        this.openInNewTab(this.props.project.unprocessed_zip_worker_file)
      }
    }
  }

  handlePicturesSelected(event) {
    let images = Object.values(event?.target?.files || event);

    images.forEach((file) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({
          images: [...this.state.images, file],
          images_src: [...this.state.images_src, reader.result]
        });
      }
      reader.readAsDataURL(file);
    });
  }


  render() {

    let media_exist = true

    let buttons = ""

    if (this.props.currentUser.roles.admin || this.props.currentUser.roles.post_production){
      buttons = <div className="accountRequiredButtons downloadPhotosModalButtons">
        <button className="uploadProjectPhotosButton" id={this.props.project.unprocessed_zip_worker_file ? "" : "disableButton"} onClick={() => this.downloadPhotos("unprocessed")}>Unprocessed Media</button>
        <button className="uploadProjectPhotosButton buttonWithRightMargin" id={this.props.project.processed_zip_worker_file ? "" : "disableButton"} onClick={() => this.downloadPhotos("processed")}>Processed Media</button>
      </div>

      media_exist = this.props.project.processed_zip_worker_file || this.props.project.unprocessed_zip_worker_file

    } else if (this.props.currentUser.roles.contractor) {
      buttons = <div className="accountRequiredButtons downloadPhotosModalButtons">
        <button className="uploadProjectPhotosButton" id={this.props.project.unprocessed_zip_worker_file ? "" : "disableButton"} onClick={() => this.downloadPhotos("unprocessed")}>Download Media</button>
      </div>

      media_exist = this.props.project.unprocessed_zip_worker_file
    } else {
      buttons = <div className="accountRequiredButtons downloadPhotosModalButtons">
        <button className="uploadProjectPhotosButton" id={this.props.project.processed_zip_worker_file ? "" : "disableButton"} onClick={() => this.downloadPhotos("processed")}>Download Medias</button>
      </div>

      media_exist = this.props.project.processed_zip_worker_file
    }


    return (
      <div className="modal-background" onClick={() => this.props.onClose()}>
        <div className="orderpreviewModal uploadPhotosModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="uploadPhotoModalHeadingGroup">
              <div className="uploadPhotoModalHeading"> Download Media</div>
              <div className="closeIcon" onClick={() => this.props.onClose()}><i className="fas fa-times"></i></div>
            </div>
            <div className="uploadPhotoMainComponent">
              <img className="uploadPhotoCloud" src={Cloud} />
              <div className="font-weight-bold">Download media for {this.props.current_service?.title || "every"} service</div>
              {media_exist ? "" : <div> We're sorry, the media download is not ready at this time.</div>}
            </div>
            {media_exist ? buttons : ""}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(DownloadModal);