import React from "react";
import { showToast } from "../../../actions/toast_actions";
import { connect } from "react-redux";


class VirtualSuiteTypeItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.addInfoandOpenModal = this.addInfoandOpenModal.bind(this)
  }

  addInfoandOpenModal(modal) {
    this.props.addInfo(this.props.virtual_suite_type);
    this.props.openModal(modal, {onTypeUpdate: this.props.onTypeUpdate})
  }

  render() {
    return (
      <div className="settingsServicesItem">
        <div className="itemTitle">{this.props.virtual_suite_type.name}</div>
        <div className="itemIcon" onClick={() => this.addInfoandOpenModal("updateVirtualSuiteType")}><i className="far fa-edit settingsServiceIcon m-2"></i></div>
        <div className="itemIcon" onClick={() => {
          if (window.confirm('Are you sure you want to delete this type?')) {
            this.props.deleteVirtualSuiteType(this.props.virtual_suite_type.id)
            this.props.showToast("Virtual Suite Type has been removed")
          }
        }}>
          <i className="far fa-trash-alt settingsServiceIcon m-2" />
        </div>
      </div>
    );
  }
}

const msp = () => ({});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(VirtualSuiteTypeItem);