import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import {
  ImageSectionWrapper,
  SectionImageLeft,
  LeftImageWrapper,
  LeftInnerImageWrapper,
  LeftInnerImage3,
  LeftInnerImage4,
  SectionImageRight,
  RightImageWrapper,
  RightInnerImageWrapper,
  RightInnerImage5,
  RightInnerImage6,
  ImageMarkerLeft,
  ImageMarkerRight,
} from './styles';
import { SERVICES } from '../../../../../assets/images/servicesPages';

const ImageSection = (props) => {
  const {
    SectionImage1,
    SectionImage2,
    SectionImage3,
    SectionImage4,
    SectionImage5,
    SectionImage6,
  } = props;

  const isValidImage = () => {
    return SectionImage3 && SectionImage4 && SectionImage5 && SectionImage6;
  };

  return (
    <ImageSectionWrapper>
      <Row>
        <Col lg='6' md='6' sm='6' xs='6'>
          <LeftImageWrapper className={'sectionImage1'}>
            <SectionImageLeft src={SectionImage1} />
            <ImageMarkerLeft
              id={'Tooltip-1'}
              src={SERVICES.ImageSliderMarker}
              alt={''}
            />
          </LeftImageWrapper>
          {isValidImage() && (
            <LeftInnerImageWrapper>
              <Col xs='8'>
                <LeftInnerImage3 src={SectionImage3} />
              </Col>
              <Col xs='4'>
                <LeftInnerImage4 src={SectionImage4} />
              </Col>
            </LeftInnerImageWrapper>
          )}
        </Col>
        <Col lg='6' md='6' sm='6' xs='6' className='sectionTwoImageWrapper'>
          <RightImageWrapper className={'sectionImage2'}>
            <SectionImageRight src={SectionImage2} />
            <ImageMarkerRight
              id={'Tooltip-2'}
              src={SERVICES.ImageSliderMarker}
              alt={''}
            />
          </RightImageWrapper>
          {isValidImage() && (
            <RightInnerImageWrapper>
              <Col xs='10'>
                <RightInnerImage5 src={SectionImage5} />
              </Col>
              <Col xs='2'>
                <RightInnerImage6 src={SectionImage6} />
              </Col>
            </RightInnerImageWrapper>
          )}
        </Col>
      </Row>
    </ImageSectionWrapper>
  );
};

export default ImageSection;
