import React from 'react';

import {
  HeaderWrapper,
  HeaderTitle,
  HeaderDescription,
  LineBreak,
} from './styles';
import {TermsUserRoleSelector} from "../index";

const TermsHeader = ({date, role, setRole}) => {
  return (
    <HeaderWrapper>
      <HeaderTitle>
        Terms of Service
      </HeaderTitle>

      {date && <HeaderDescription>
        Last Updated: {new Date(date).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}
      </HeaderDescription>}

      <TermsUserRoleSelector role={role} setRole={setRole}/>

      <LineBreak/>
    </HeaderWrapper>
  );
};

export default TermsHeader;
