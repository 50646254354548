export const fetchLocations = () => (
  $.ajax({
    type: "GET",
    url: "/locations"
  })
);

export const fetchLocation = (id) => (
  $.ajax({
    type: "GET",
    url: `/locations/${id}`
  })
);

export const createLocation = (params) => (
  $.ajax({
    type: "POST",
    url: "/locations",
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const updateLocation = (id, params) => (
  $.ajax({
    type: "PATCH",
    url: `/locations/${id}`,
    data: params,
    cache: false,
    contentType: false,
    processData: false,
  })
)

export const deleteLocation = (id) => (
  $.ajax({
    type: "DELETE",
    url: `/locations/${id}`
  })
)