import React from 'react';
import {
  ServicesNavContainer,
  MainConainter,
  Row,
  Col6Left,
  Col6Right,
  ServiceActive,
  ServicesOperational,
  ServiceNavSelect,
  ServiceNavSelectOption,
} from './servicesNavStyles';

const ServicesNav = () => {
  return (
    <ServicesNavContainer>
      <MainConainter className='container'>
        <Row className='row'>
          <Col6Left className='col-6'>
            <ServiceActive />
            <ServicesOperational>All Services Operational</ServicesOperational>
          </Col6Left>
          <Col6Right className='col-6'>
            <ServiceNavSelect>
              <ServiceNavSelectOption>English</ServiceNavSelectOption>
            </ServiceNavSelect>
          </Col6Right>
        </Row>
      </MainConainter>
    </ServicesNavContainer>
  );
};

export default ServicesNav;
