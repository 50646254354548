import * as APIUtil from "../util/service_api_util";


export const RECEIVE_SERVICE = "RECEIVE_SERVICE";
export const RECEIVE_ALL_SERVICES = "RECEIVE_ALL_SERVICES";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const ADD_INFO = "ADD INFO";
export const RECEIVE_CONTRACTORS = "RECEIVE_CONTRACTORS";

export const receiveServices = (services) => ({
  type: RECEIVE_ALL_SERVICES,
  services
});

export const receiveService = (service) => ({
  type: RECEIVE_SERVICE,
  service
});

export const removeService = (service_id) => ({
  type: REMOVE_SERVICE,
  service_id
});

export const receiveContractors = (contractors) => ({
  type: RECEIVE_CONTRACTORS,
  contractors
})

export const addInfo = info => {
  return {
    type: ADD_INFO,
    info
  }
}

export const fetchServices = () => dispatch => {
  return APIUtil.fetchServices().then(
    services => dispatch(receiveServices(services)))
};

export const fetchServicesSettings = () => dispatch => {
  return APIUtil.fetchServicesSettings().then(
    services => dispatch(receiveServices(services)))
};

export const fetchService = (service_id) => dispatch => {
  return APIUtil.fetchService(service_id).then(
    service => dispatch(receiveService(service)))
};

export const createService = (service_info) => dispatch => {
  return APIUtil.createService(service_info).then(
    service => dispatch(receiveService(service)))
};

export const updateService = (service_info, service_id) => dispatch => {
  return APIUtil.updateService(service_info, service_id).then(
    service => dispatch(receiveService(service)))
};

export const deleteService = (id) => dispatch => {
  return APIUtil.deleteService(id).then(
    service => dispatch(removeService(service.id)))
};

export const createSubService = (sub_service_info) => dispatch => {
  return APIUtil.createSubService(sub_service_info).then(
    service => {
      dispatch(receiveService(service));
      dispatch(addInfo(Object.values(service)[0]))
    }
  )
};

export const updateSubService = (sub_service_info, sub_service_id) => dispatch => {
  return APIUtil.updateSubService(sub_service_info, sub_service_id).then(
    service => {
      dispatch(receiveService(service));
      dispatch(addInfo(Object.values(service)[0]))
    }
    )
};

export const deleteSubService = (sub_service_id) => dispatch => {
  return APIUtil.deleteSubService(sub_service_id).then(
    service => {
      dispatch(receiveService(service));
      dispatch(addInfo(Object.values(service)[0]))
    }
  )
};

export const createGallery = (gallery_info) => dispatch => {
  return APIUtil.createGallery(gallery_info).then(
    service => {
      dispatch(receiveService(service));
      dispatch(addInfo(Object.values(service)[0]))
    }
  )
};

export const updateGallery = (gallery_info, gallery_id) => dispatch => {
  return APIUtil.updateGallery(gallery_info, gallery_id).then(
    service => {
      dispatch(receiveService(service));
      dispatch(addInfo(Object.values(service)[0]))
    }
  )
};

export const deleteGallery = (gallery_id) => dispatch => {
  return APIUtil.deleteGallery(gallery_id).then(
    service => {
      dispatch(receiveService(service));
      dispatch(addInfo(Object.values(service)[0]))
    }
  )
};

export const fetchContractors = (service_id, zipcode, search, date, time, services, appointments, timezone, photographer_index) => dispatch => {
  return APIUtil.fetchContractors(service_id, zipcode, search, date, time, services, appointments, timezone, photographer_index).then(
    contractors => dispatch(receiveContractors(contractors)))
};

export const fetchPhotographer = (id) => dispatch => {
  return APIUtil.fetchPhotographer(id)
}

