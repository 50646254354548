import React from "react";
import { Route, Link } from "react-router-dom";

export default class ForgotPasswordUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      errors: []
    };

    this.handleInput = this.handleInput.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.sendToLogin = this.sendToLogin.bind(this)
  }


  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  sendToLogin() {
    this.props.clearErrors()
    this.props.history.push("/login")
  }

  submitForm() {
    this.setState({ errors: [] })

    let errors = []

    if (this.state.password === "") {
      errors.push([1, "Password field can't be blank."])
    }

    if (this.state.confirmPassword === "") {
      errors.push([2, "Confirm password field can't be blank."])
    }

    if (this.state.password != this.state.confirmPassword){
      errors.push([3, "Passwords must match."])
    }

    if (this.state.password.length < 6) {
      errors.push([3, "Password must be at least 6 characters."])
    }

    if (errors.length > 0) {
      this.setState({ errors: errors })
    } else {
      let user = { password: this.state.password, confirm_password: this.state.confirmPassword, reset_password_token: this.props.match.params.tokenId }
      this.props.submitUpdatedPassword(user).then(() => {
        if (this.props.errors.length === 0) {
          this.props.showToast("Password has been updated.")
          this.sendToLogin()
        }
      })
    }
  }

  render() {

    let passwordErrorClass = "";
    let confirmPasswordErrorClass = "";

    let passwordErrors = [];
    let confirmPasswordErrors = [];
    let backendErrors = [];


    this.state.errors.forEach((error) => {

      if (error[0] === 1) {
        passwordErrors.push(<li>{error[1]}</li>)
        passwordErrorClass = "formInputError"
      }

      if (error[0] === 2) {
        confirmPasswordErrors.push(<li>{error[1]}</li>)
        confirmPasswordErrorClass = "formInputError"
      }
      if (error[0] === 3) {
        backendErrors.push(<li>{error[1]}</li>)
      }

    })

    this.props.errors.forEach((error) => {
      backendErrors.push(<li>{error}</li>)
    })

    return (
      <div className="loginForm">
        <div className="loginFormContent">
          <h3 className="h3React">Change Your Password</h3>
          <ul className="backendErrorUl">{backendErrors}</ul>
          <label id="formLabel"> New Password </label>
          <input className="formInput" id={passwordErrorClass} onChange={this.handleInput("password")} type="password" placeholder="Password" value={this.state.password} />
          <ul className="errorUl">{passwordErrors}</ul>
          <input className="formInput" id={confirmPasswordErrorClass} onChange={this.handleInput("confirmPassword")} type="password" placeholder="Confirm password" value={this.state.confirmPassword} />
          <ul className="errorUl">{confirmPasswordErrors}</ul>
          <button className="formSubmitButton forgotPassWordSubmitButton" onClick={this.submitForm}>Submit</button>
          {/* <button className="createAccountButton forgotPasswordCreateAccountButton" onClick={this.sendToLogin}>Sign In</button> */}
        </div>
      </div>
    )
  }
}