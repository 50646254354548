import React, { useState, useEffect } from "react"
import NavbarContainer from "../navbar/navbar_container"
import InviteModal from "./invite_modal"
import PageHeader from "../page_header"
import bgImage from "../../../../assets/images/referrals/bg1.png"
import copyIcon from "../../../../assets/images/referrals/copy.svg"
import rightIcon from "../../../../assets/images/referrals/right.svg"
import downIcon from "../../../../assets/images/referrals/down.svg"
import referralKitImage from "../../../../assets/images/referrals/referralKitNew.png"
import MATokenImage from "../../../../assets/images/referrals/MATokens.png"
import downloadIcon from "../../../../assets/images/icons/download.svg"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { showToast } from "../../actions/toast_actions";
import { fetchReferrals } from "../../util/discount_code_api_utils";
import {DownloadReferralsImagesZipButton} from "./download_referrals_images_zip_button";

const ReferralsContractor = (props) => {
  const code = props.currentUser.referral_code
  const questions = [
    {
      id: 1,
      question: 'How do referrals work?',
      answer: <div><p>Our referral program allows you to earn rewards by referring other real estate agents to <a href="https://www.modernangles.com">Modern Angles</a>. When you refer someone and they make a purchase using your unique referral link or code, both you and the referred person benefit.</p>
        <p>The referred person will receive a 20% discount on their first purchase, automatically applied at checkout, and as the referrer, you will earn a reward in the form of account credit. The credit amount is equal to 10% of the total price of the referred person's purchase. This credit will be added to your account when the referred person's photoshoot is marked as completed.</p>
        <p>You can use the account credit towards your future photoshoots with us, allowing you to enjoy a discount on our services.</p></div>
    },
    {
      id: 2,
      question: "What are the recommended methods to share my referral link?",
      answer: <div><p>There are three convenient ways to share your referral code and maximize its effectiveness: </p> <ol>
        <li>
          Copy and paste your code: Simply copy your referral code and share it directly with the person you want to refer. They will need to use this code during the checkout process to activate their discount.
        </li>
        <li>
          Share the referral link: Copy the provided "referral link" and share it with the person you want to refer. When they click on the link, it will redirect them to our website, and the referral code will be automatically applied to their order.
        </li>
        <li>
          Utilize the invite button: Click on the invite button within your client dashboard and enter up to five email addresses of the individuals you wish to share your referral link with. We will send an invitation email containing your unique referral link directly to them.
        </li>
      </ol>
        <p>Choose the method that is most convenient for you and the person you are referring, making it easier for them to access the discount and ensuring you receive the credit you deserve.</p>
      </div>
    },
    {
      id: 3,
      question: `How do I track referrals and the rewards I've earned?`,
      answer: <div><p>Tracking your referrals and rewards is a seamless process with our dedicated Referrals tab. Here's how you can effortlessly keep track of your progress:</p>
        <ol>
          <li>
            Access the Referrals tab: Visit the Referrals section, which is conveniently located on the page you are currently viewing.
          </li>
          <li>
            Overview of rewards: At the top of the page, you will find a clear display of your total rewards earned so far. This provides a quick snapshot of the benefits you have accumulated through successful referrals.
          </li>
          <li>
            Active referral details: In the Referrals section, you will find a comprehensive overview of your active referrals. Here, you can monitor the progress of each referral and stay informed about their status and rewards.
          </li>
          <li>
            Information provided: Within the Referrals section, you can track essential details such as the person you referred, the reward earned for their purchase, the current status of your reward, and the date when the referral code was used.
          </li>
        </ol>

        <p>By utilizing the Referrals tab, you can effortlessly stay updated on the progress of your referrals and keep track of the rewards you've earned along the way.</p>
      </div>
    }
  ]

  const [openedQuestionId, setOpenedQuestionId] = useState(questions[0].id)

  const [inviteModal, setInviteModal] = useState(false)

  const [referrals, setReferrals] = useState([])

  const [fullResults, setFullResults] = useState(false)

  useEffect(() => {
    fetchReferrals().then(referrals => {
      setReferrals(referrals)
    })
  }, [])

  const downloadLinks = [
    {
      title: 'Download Single Sided PDF (Coming Soon)',
      description: 'Single-sided PDFs with your personalized referral code for effortless marketing.',
      link: '#'
    },
    {
      title: 'Download Sales Presentation (Coming Soon)',
      description: 'Access engaging sales presentations to showcase our services and boost conversions.',
      link: '#'
    }
  ]

  const onToggleQuestion = (e, question) => {
    e.preventDefault()
    if (question.id === openedQuestionId) {
      setOpenedQuestionId(null)
    } else {
      setOpenedQuestionId(question.id)
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    props.showToast("Copied")
  }

  const showFullResults = () => {
    setFullResults(true)
  }

  let modal = ""

  if (inviteModal) {
    modal = <InviteModal setInviteModal={setInviteModal} />
  }

  let shown_referrals = referrals

  if (!fullResults) {
    shown_referrals = referrals.slice(0, 10)
  }

  let total_rewards = referrals.filter(referral => referral.status === "Completed").reduce((res, referral) => res + referral.amount, 0);

  return (<div className="dashboardMainDiv">
    {modal}
    <PageHeader title="Referrals"
      subtitle="Welcome back to Modern Angles"
      showNotifications={true} />

    <div className="sectionCard referralsSectionCard">
      <div className="referralsSectionCardBody">
        <div className="referralsSectionCardBodyInner">
          <div className="referralsSectionCardBodyContent">
            <h1 className="rewardAmountTitle">Total Rewards</h1>
            <h2 className="rewardAmount">
              <div className="currency">$</div>
              <div className="amount">{total_rewards}</div>
              {/* <img className="maToken" src={MATokenImage}></img> */}
            </h2>
            <div className="referralDescription">
              Invite professionals to give them 20% off their first shoot and earn from their photoshoots
            </div>
            <div className="referralSubDescription">
              Expand your rewards by inviting professionals to join our platform. Start maximizing your earnings today!
            </div>

            <div className="referralCodeInputWrapper">
              <div className="referralCodeInput">
                <div className="code">{code}</div>
                <div className="icon" onClick={() => copyToClipboard(code)}>
                  <img src={copyIcon} />
                </div>
              </div>
            </div>
            <div className="referralCodeInputWrapper">
              <div className="referralCodeInput" onClick={() => copyToClipboard(window.location.origin + `/referral/${code}`)}>
                <div className="linkCode">Referral Link</div>
                <div className="icon">
                  <img src={copyIcon} />
                </div>
              </div>
            </div>
            <button className="projectEditButton referralButton" onClick={() => setInviteModal(true)}>Invite</button>
          </div>
          <div className="referralsSectionCardBodyImage">
            <img src={bgImage} />
          </div>
        </div>
      </div>

      {questions.map(question => (
        <div className="referralsSectionCardBody" key={`question-${question.id}`}>
          <div className="faqQuestion" onClick={e => onToggleQuestion(e, question)}>
            {question.question}
            <img className="arrow" src={question.id === openedQuestionId ? downIcon : rightIcon} />
          </div>
          <div className={`faqAnswer ${question.id === openedQuestionId ? 'opened' : ''}`}>
            <div className="farAnswerInner">
              {question.answer}
            </div>
          </div>
        </div>
      ))}
    </div>

    <div className="sectionCard">
      <div className="sectionCardHeader">
        <div className="titleWithButton">
          <h5 className="photographersCardTitle">
            Referrals
          </h5>
          <a className="viewAll" onClick={showFullResults}>
            View All
          </a>
        </div>
        <div className="referralSubDescription2">
          Rewards are dispersed every Friday for completed referrals
        </div>
      </div>

      <div className="sectionCardBody">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Reward</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {shown_referrals.map(referral => (
                <tr key={`referral-${referral.id}`}>
                  <td>{referral.client_name}</td>
                  <td>${referral.amount}</td>
                  <td>{referral.status}</td>
                  <td>{new Date(referral.created_at).toLocaleDateString("en-US")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div className="sectionCard referralKitSectionCard">
      <div className="referralKitSectionCardInner">
        <div className="referralsSectionCardBody">
          <div className="d-flex align-content-center">
            <div className="flex-1">
              <h5 className="photographersCardTitle">
                Marketing Toolkit
              </h5>
              <div className="referralSubDescription">
                Streamline your marketing with personalized referral code downloads and pre-made materials.
              </div>
            </div>
            <div className="referralKitImageMobile flex-1">
              <img src={referralKitImage} />
            </div>
          </div>
          <DownloadReferralsImagesZipButton className="downloadIcon">
            <button className="projectEditButton referralButton referralKitButton">
              Download Social Media Content
            </button>
          </DownloadReferralsImagesZipButton>
        </div>
        <div>
          {downloadLinks.map(({ title, description, link }, i) => (
            <div className="referralsSectionCardBody" key={`download-${i}`}>
              <div className="downloadContainer">
                <div className="downloadIcon">
                  <img src={downloadIcon} />
                </div>
                <div className="downloadContent">
                  <div className="downloadTitle">{title}</div>
                  <div className="downloadDescription">{description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="referralKitImage">
        <img src={referralKitImage} />
      </div>
    </div>
  </div>)
}

const msp = (state) => (
  {
    currentUser: state.entities.users[state.session.currentUser.id]
  }
)

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});


export default withRouter(connect(msp, mdp)(ReferralsContractor))